import React from 'react';
import styles from "./FromComponent.module.css";
import { useAuth } from '../../context/Auth';

const InputRedio = ({ onClick, onChange, name, id, lableText, value, checked ,disabled , required}) => {
    const { mode } = useAuth();

    return (
        <div>
            <input 
                type="radio"
                name={name}
                id={id}
                className={styles.inputRedio}
                onClick={onClick}
                onChange={onChange} 
                value={value}
                checked={checked}
                disabled={disabled}
                required={required}
            />
            <label 
                htmlFor={id}
                className={styles.label}
                style={mode ? { color: "#ffff" } : { color: "#646B88" }}
            >
                {lableText}
            </label>
        </div>
    );
};

export default InputRedio;
