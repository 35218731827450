import React, { useEffect, useState } from 'react'
import styles from "./Camp.module.css"
import { useAuth } from '../../components/context/Auth'
import TopTabBar from '../../components/updatedComponents/Tanolet/TopTabBar'
import { useParams } from 'react-router-dom'
import { useToast } from '../../components/updatedComponents/Toaster/Toaster'
import TimeOutFetch from '../../APIsControll/TimeOutFechControll'
import { changeDateFormat, changeTimeFormat, truncateText } from '../../Utils/TextUtils'
import { Spinner } from '../../components/icons/Icon'
import Skeleton from '../../components/updatedComponents/Skeleton/Skeleton'
const CampaignReport = () => {
    const { mode, managerId } = useAuth();
    const { tempname, campaignName } = useParams();
    const { show } = useToast();
    const [data, setData] = useState();
    const [loading, setloading] = useState({
        loading1: false,
    })

    const getREport = async () => {
        const paload = {
            managerId,
            templateName: tempname,
            campaignName: campaignName
        }
        try {
            setloading((pre) => ({
                ...pre, loading1: true
            }))
            const res = await TimeOutFetch(`wallikonCampaign/campaignReportStatus`, paload);
            setloading((pre) => ({
                ...pre, loading1: false
            }))
            setData(res?.data);

        } catch (error) {
            show("Something went wrong.", " error");
            return
        }
    }

    useEffect(() => {
        getREport();
    }, [])

    // Memoized unique scheduled dates
    const uniqueScheduledDates = React.useMemo(() => {
        if (!data?.scheduledDates) return [];
        return [...new Set(data.scheduledDates.map(scheduled => scheduled.date))].sort();
    }, [data?.scheduledDates]);

    // Format date to a more readable format
    // const formatDate = (dateString) => {
    //     const date = new Date(dateString);
    //     return date.toLocaleString('en-US', {
    //         year: 'numeric',
    //         month: 'long',
    //         day: 'numeric',
    //         hour: '2-digit',
    //         minute: '2-digit',
    //         timeZone: 'UTC'
    //     });
    // };

    return (
        <div className={styles.mainCintner}>
            <div className={styles.Continer} style={{ backgroundColor: mode ? "#2C2E33" : "#F8F9FC" }} >
                <div className={styles.goBackCon}  >
                    <div className={styles.gobacFristChlid}>
                        {/* <TopTabBar text={`Campaigns/Report/${tempname}`} /> */}
                        <TopTabBar text={`Campaigns/Report`} />
                    </div>
                </div>
                <div className={styles.report_mainCon} style={{ backgroundColor: mode ? "#2C2E33" : "#F8F9FC", color: mode ? "#fff" : "#000" }} >
                    <div className={styles.templetReport} >
                        <span style={{ fontSize: "16px", color: "#4164E3", fontWeight: "500" }} > Status :  </span>
                        {loading.loading1 ? "Loading.." : <span style={{ fontSize: "16px", fontWeight: "400" }} > {data?.status.toUpperCase()}  </span>}

                    </div>
                    <div className={styles.templetReport} >
                        <span style={{ fontSize: "16px", color: "#4164E3", fontWeight: "500" }} > Template Name :  </span>
                        {loading.loading1 ? "Loading.." : <span style={{ fontSize: "16px", fontWeight: "400" }} > {tempname.toUpperCase()}  </span>}
                    </div>
                    <div className={styles.templetReport1} >
                        <div style={{ marginTop: "14px", color: mode ? "#9C9797" : "#646B88", }}>
                            <span style={{ fontSize: "16px", fontWeight: "500" }} > Campaign Timing </span>
                        </div>
                        {/* {
                            loading.loading1 ? <div style={{ width: "70%", display: "flex", gap: "6px" }}> {Array.from({ length: 3 }).map((_, i) => <Skeleton lines={1} thickness={4} key={`${i}g`} />)}   </div> :
                                <div className={styles.timing}>
                                    {[
                                        ...new Map(
                                            data?.scheduledDates.map((e) => [new Date(e.date).toDateString(), e])
                                        ).values(),
                                    ].map((e, i) => (
                                        <div
                                            className={styles.suduleTime}
                                            style={{ backgroundColor: mode ? "#000" : "#fff" }}
                                            key={i}
                                        >
                                            <div className={styles.suduleTime1}>
                                                <div>
                                                    {changeDateFormat(e.date, "-")}, {changeTimeFormat(e.date)}
                                                </div>
                                            </div>
                                            <div className={styles.suduleTime2}></div>
                                        </div>
                                    ))}
                                </div>
                        } */}

                        {loading.loading1 ? (
                            <div style={{ width: "70%", display: "flex", gap: "6px" }}>
                                {Array.from({ length: 3 }).map((_, i) => (
                                    <Skeleton lines={1} thickness={4} key={`${i}g`} />
                                ))}
                            </div>
                        ) : (
                            <div style={{ /* border:"1px solid", */ display: "flex", gap: "10px" }}>
                                {
                                    uniqueScheduledDates.map((date, index) => (
                                        // <div key={index} className="mb-1">
                                        //     {formatDate(date)}
                                        // </div>
                                        <div
                                            className={styles.suduleTime}
                                            style={{ backgroundColor: mode ? "#000" : "#fff" }}
                                            key={index}
                                        >
                                            <div className={styles.suduleTime1}>
                                                <div>
                                                    {/* {formatDate(date)} */}
                                                    {changeDateFormat(date, "-")} {changeTimeFormat(date)}
                                                </div>
                                            </div>
                                            <div className={styles.suduleTime2}></div>
                                        </div>
                                    ))
                                }
                            </div>
                        )}

                        <div className={styles.suduleTimeCart} style={{ backgroundColor: mode ? "#232529" : "#fff", color: mode ? "#fff" : "#000" }} >
                            <div className={styles.cartwidth} style={{ backgroundColor: mode ? "#1B1D21" : "#fff", border: mode ? "none" : "1px solid #E4E7EB" }} >
                                {
                                    loading.loading1 ? <Spinner size="30" /> :
                                        <div  >
                                            <div style={{ fontSize: "14px", fontWeight: "400" }} >Sent</div>
                                            <div style={{ fontSize: "18px", fontWeight: "500" }} >{data?.sentCount}</div>
                                        </div>
                                }
                            </div>
                            <div className={styles.cartwidth} style={{ backgroundColor: mode ? "#1B1D21" : "#fff", border: mode ? "none" : "1px solid #E4E7EB" }} >
                                {
                                    loading.loading1 ? <Spinner size="30" /> :
                                        <div  >
                                            <div style={{ fontSize: "14px", fontWeight: "400" }} >Total Cost</div>
                                            <div style={{ fontSize: "18px", fontWeight: "500" }} > ₹ {truncateText(`${data?.totalCost}`, 5)}</div>
                                        </div>
                                }
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default CampaignReport