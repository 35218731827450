import React from 'react'
import styles from "./progress.module.css"
import { useAuth } from '../../context/Auth'
const Progress = ({ progress }) => {
    const { mode } = useAuth()
    return (
        <div className={styles.progressContainer}>
            <div className={styles.progressBar}>
                <div className={styles.progress} style={{ width: `${progress}%` }}>
                    <div className={styles.pointer} />
                </div>
            </div>
            <span className={styles.progressText} style={{color:mode ?"#fff":"#000"}} >{progress}%</span>
        </div>
    )
}

export default Progress