import React, { useEffect, useRef, useState } from "react";
import styles from "../../../pages/ordermanager/Orders.module.css";
import ModalWrapper from "../layoutWrapper/ModalWrapper";
import AdduserModel from "../models/AdduserModel";
import Spinner from "../FormComponents/Spinner";

const SearchDealer = ({
  mode,
  results = [],
  loading,
  currentIndex,
  onDealerSelect,
}) => {
  const rowRefs = useRef([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (rowRefs.current[currentIndex]) {
      rowRefs.current[currentIndex].scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [currentIndex]);

  return (
    <>
      <ModalWrapper
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        noBtn
      >
        <AdduserModel close={() => setIsModalOpen(false)} />
      </ModalWrapper>
      <div
        className={`${styles.search_dealer} ${
          mode ? styles.search_dealer_dark : ""
        }`}
        onMouseDown={(e) => e.preventDefault()}
      >
        <div>
          <p style={{ color: mode ? "#fff" : "#0100E4" }}>Create New Dealer</p>
          <p onClick={() => setIsModalOpen(true)}>ALT + C</p>
        </div>
        <div className={styles.search_list}>
          {loading ? (
            <Spinner
              height={60}
              width={60}
              color={mode ? "#000000" : "#4164E3"}
            />
          ) : (
            <>
              {results &&
                results.map((r, index) => (
                  <div
                    className={`${
                      currentIndex === index
                        ? mode
                          ? styles.focused_row_dark
                          : styles.focused_row
                        : mode
                        ? styles.selected_row_dark
                        : styles.selected_row
                    } ${styles.search_list_item}`}
                    key={index}
                    ref={(el) => (rowRefs.current[index] = el)}
                    onClick={() => {
                      onDealerSelect(r);
                    }}
                  >
                    {r.storeName + " / " + r.name}
                  </div>
                ))}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default SearchDealer;
