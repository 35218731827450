import React from 'react'
import { useAuth } from '../../../context/Auth';
const MobileWrapper = ({children}) => {
    const { mode } = useAuth(); 
  return (
    <div style={{backgroundColor : mode ? '#2C2E33' : '#F0F2F5',height:'calc(100vh - 70px)'}}>
        {children}
    </div>
  )
}

export default MobileWrapper