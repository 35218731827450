
import React from 'react';
import styles from './pagnation.module.css';
import { useAuth } from '../../context/Auth';
import useWindowWidth from '../Hooks/WidthSize';

const PaginationComponent = ({ totalPages, currentPage, setCurrentPage }) => {
  const { mode } = useAuth();
  const width = useWindowWidth()
  const handlePageChange = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const range = width > 888 ? 2 : width > 794 ? 1 : 0; // Control how many numbers appear around the current page.

    if (totalPages > 1) {
      pageNumbers.push(
        <button
          key={1}
          onClick={() => handlePageChange(1)}
          className={`${mode ? styles.pageButton_dark : styles.pageButton} ${currentPage === 1 ? styles.activeButton : ''}`}
        >
          1
        </button>
      );
    }

    // Show ellipsis if there is a gap between the first and current page range
    if (currentPage > range + 2) {
      pageNumbers.push(
        <div key="ellipsis1" className={mode ? styles.ellipsis_dark : styles.ellipsis}>
          <span>...</span>
        </div>
      );
    }

    // Render page numbers around the current page
    for (let i = Math.max(2, currentPage - range); i <= Math.min(totalPages - 1, currentPage + range); i++) {
      pageNumbers.push(
        <button
          key={i}
          onClick={() => handlePageChange(i)}
          className={`${mode ? styles.pageButton_dark : styles.pageButton} ${currentPage === i ? styles.activeButton : ''}`}
        >
          {i}
        </button>
      );
    }

    // Show ellipsis if there is a gap after the current page range
    if (currentPage < totalPages - range - 1) {
      pageNumbers.push(
        <div key="ellipsis2" className={mode ? styles.ellipsis_dark : styles.ellipsis}>
          <span>...</span>
        </div>
      );
    }

    // Always show the last page
    if (totalPages > 1) {
      pageNumbers.push(
        <button
          key={totalPages}
          onClick={() => handlePageChange(totalPages)}
          className={`${mode ? styles.pageButton_dark : styles.pageButton} ${currentPage === totalPages ? styles.activeButton : ''}`}
        >
          {totalPages}
        </button>
      );
    }

    return pageNumbers;
  };

  return (
    <div>
      {/* Pagination controls */}
      <div className={styles.pagination}>
        {
          width > 1062 ?
            <button
              className={mode ? styles.pageButton_dark : styles.pageButton}
              onClick={() => handlePageChange(Math.max(1, currentPage - 5))}
              disabled={currentPage === 1}
            >
              {'<<'}
            </button> : null
        }

        <button
          className={mode ? styles.pageButton_dark : styles.pageButton}
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          {'<'}
        </button>

        {renderPageNumbers()}

        <button
          className={mode ? styles.pageButton_dark : styles.pageButton}
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          {'>'}
        </button>
        {
          width > 1062 ?
            <button
              className={mode ? styles.pageButton_dark : styles.pageButton}
              onClick={() => handlePageChange(Math.min(totalPages, currentPage + 5))}
              disabled={currentPage === totalPages}
            >
              {'>>'}
            </button> : null
        }
      </div>
    </div>
  );
};

export default PaginationComponent;
