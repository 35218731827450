import React, { useState } from "react";
import styles from "./globalmobilewhatsappchat.module.css";
import { useAuth } from "../../../context/Auth";
import { BackArrowIcon } from "../../../icons/Icon3";
import { useNavigate } from "react-router-dom";
import Modal from "../../../Modal";
import { GreenButton, RedOutButton } from "../../../Buttons";
import ProfileChat from "../chats/ProfileChat";
import { truncateText } from "../../../../Utils/TextUtils";
const MobileHeader = ({ dealerData, mobile }) => {
  const { managerId, setTotalUnreadChat, designation } = useAuth();
  const [showMarkRead, setShowMarkRead] = useState(false)
  const [reloadDealer, setReloadDealer] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectManager, setSelectManager] = useState('');
  const [selectDealers, setSelectDealers] = useState(false)
  const [markRead, setMarkRead] = useState()
  const [showAssignManagerModal, setShowAssignManagerModal] = useState(false)
  const [selectManagerChat, setSelectManagerChat] = useState(false)
  const [selectManagerData, setSelectManagerData] = useState([]);

  // const navigateToPage = (url) => {
  //   window.location.href = url;
  // };

  const navigateToPage = (url) => {
    if (url === "/whatsappchats") {
      window.history.back();
    } else {
      window.location.href = url;
    }
  };


  const navigate = useNavigate();
  const { mode } = useAuth();

  const borderColor = mode ? '2px solid #F1F1F1' : '2px solid #82889F';

  const handleMarkRead = () => {
    setReloadDealer(true)
    const myHeaders = new Headers();
    myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      "managerId": managerId,
      "whatsapp": selectedItems?.length !== 0 ? selectedItems.map((item) => item.whatsapp) : []
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };

    fetch(process.env.REACT_APP_URL + "wallikonChat/statusRead", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === 'success') {
          setShowMarkRead(false)
          setReloadDealer(false)
          setMarkRead(false)
          setSelectedItems([])
          setSelectDealers(false)
        } else {
          alert(result.msg)
          setShowMarkRead(false)
        }
      })
      .catch((error) => console.error(error));
  }
  return (
    <React.Fragment>
      <div className={`${styles.whatsapp_chats_header} ${mode ? styles.darkMode : styles.light
        }`}>
        <div
          className=""
          onClick={() => {
            navigateToPage("/whatsappchats");
          }}
        >
          <BackArrowIcon mode={mode} />
        </div>

        {!dealerData.profileImage ? (
          <img
            src="/assets/whatsapp_profile.png"
            alt="profile_Image"
            className={styles.whatsappMessage_image}
            style={{ border: borderColor }}

          />
        ) : (
          <img
            src={process.env.REACT_APP_S3URL + dealerData.profileImage}
            className={styles.whatsappMessage_image}
            alt="dealer_image"
            style={{ border: borderColor }}
          />
        )}
        <div className="w-100 ms-1">
          <div className="w-100 d-flex justify-content-between">
            <div className="d-flex flex-column">
              <div className={styles.header_storeName}>
                {" "}
                {dealerData.storeName
                  ? dealerData.storeName
                  : dealerData.whatsapp}
              </div>
              {dealerData.dealerName && (
                <div className={styles.header_dealerName}>
                  {" "}
                  {dealerData.dealerName}
                  {dealerData.dealerCity && (
                    <span>&nbsp;({dealerData.dealerCity})</span>
                  )}
                </div>
              )}
            </div>
          </div>
          <div
            style={{ color: mode ? '#749AFF' : '#0100E4' }}
            role="button"
            className={styles.header_mobileNo}
            onClick={() => navigate("/dealers/" + dealerData.whatsapp)}
          >
            <u>{dealerData.whatsapp}</u>
          </div>
          {dealerData.dealerStatus && (
            <span
              className={`${styles.header_status} ms-2 ${dealerData.dealerStatus === "Pending"
                  ? "text-warning"
                  : dealerData.dealerStatus === "Rejected"
                    ? "text-danger"
                    : dealerData.dealerStatus === "Verified"
                      ? "text-success"
                      : dealerData.dealerStatus === "Update"
                        ? "text-success"
                        : dealerData.dealerStatus === "New"
                          ? "text-info"
                          : ""
                }`}
            >
              {dealerData.dealerStatus}
            </span>
          )}
        </div>
        {mobile && (
          <div className={styles.managerName_div}>
            <label>Order Manager</label>
            {dealerData.managerName ? (
              <div className={styles.selectManager}>{truncateText(dealerData.managerName, 10)}</div>
            ) : (
              <select
                style={{ color: mode ? "#fff" : "#9196AA" }}
                className={styles.selectManager}
                value={selectManager}
                onChange={(e) => {
                  setSelectManager(e.target.value);
                  setShowAssignManagerModal(true);
                }}
                onClick={() => setSelectManagerChat(true)}
              >
                <option value={""}>Select Manager</option>
                {selectManagerData &&
                  selectManagerData.length !== 0 &&
                  selectManagerData.map((item, index) => (
                    <option key={index} value={item.managerId}>
                      {item.name}
                    </option>
                  ))}
              </select>
            )}
          </div>
        )}
      </div>

      <Modal
        show={showMarkRead}
        close={setShowMarkRead}
        closeBtn={true}
        heading="Mark As Read"
        content={
          <div className={styles.text_center}>
            <h3 className='text-center my-5'>Are you sure want to mark read</h3>
            <div className='d-flex justify-content-between align-items-center mx-auto w-100 mb-4'>
              <RedOutButton title="NO" handleSubmit={() => setShowMarkRead(false)} />
              <GreenButton title="YES"
                handleSubmit={() => {
                  handleMarkRead()
                }} />
            </div>
          </div>
        }
      />
    </React.Fragment>

  );


};



export default MobileHeader;
