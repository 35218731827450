
import React, { useEffect, useRef, useState } from "react";
import styles from "./progress.module.css";
import { AddBtnicon } from "../../icons/Icon2";
import { useAuth } from "../../context/Auth";

export const Accordion = ({ children, type = "single", collapsible = true  , className}) => {
  const [openItem, setOpenItem] = useState(null);


  const handleToggle = (value) => {
    if (collapsible && openItem === value) {
      setOpenItem(null); 
    } else if (type === "single") {
      setOpenItem(value); 
    } else {
      setOpenItem((prev) => (prev === value ? null : value)); 
    }
  };

  return (
    <div className={`${styles.accordion} ${className} `}>
      {React.Children.map(children, (child) =>
        React.cloneElement(child, {
          openItem,
          onToggle: handleToggle,
        })
      )}
    </div>
  );
};

export const AccordionItem = ({ value, children, openItem, onToggle, style , className }) => {
  const isOpen = openItem === value;
  return (
    <div className={`${styles.accordionItem} ${className} `} style={style}>
      {React.Children.map(children, (child) =>
        React.cloneElement(child, {
          isOpen, 
          value,
          onToggle,
          style,
        })
      )}
    </div>
  );
};


export const AccordionTrigger = ({ children, value, onToggle, isOpen, style , className}) => {
  const { mode } = useAuth();
  return (
    <div className={`${styles.accordionTrigger} ${className} `} onClick={() => onToggle(value)} style={style}>
      <div style={{position:"absolute", right:"30px", top:"38%"}}>  <AddBtnicon mode={mode} rotate={isOpen ? 180 : 0} />  </div>
      <div>  {children} </div>
    </div>
  );
};



// export const AccordionContent = ({ children, isOpen, style }) => {
//   const contentRef = useRef(null);
//   const [height, setHeight] = useState("0px");
//   useEffect(() => {
//     if (isOpen) {
//       setHeight(`${contentRef.current.scrollHeight}px`);
//     } else {
//       setHeight("0px");
//     }
//   }, [isOpen]);

//   return (
//     <div
//       ref={contentRef}
//       style={{ ...style, maxHeight: height }} 
//       className={`${styles.accordionContent} ${isOpen ? styles.active : ""}`}
//     >
//       <div>{children}</div>
//     </div>
//   );
// };


export const AccordionContent = ({ children, isOpen, style ,  className}) => {
  const contentRef = useRef(null);
  const [height, setHeight] = useState("0px");

  useEffect(() => {
    if (isOpen) {
      setHeight(`${contentRef.current.scrollHeight}px`);
    } else {
      setHeight("0px");
    }
  }, [isOpen]);

  return (
    <div
      ref={contentRef}
      style={{
        ...style,
        maxHeight: height,
        zIndex: isOpen ? 10 : 1,  // Higher z-index when open
      }}
      className={`${styles.accordionContent} ${className}  ${isOpen ? styles.active : ""}`}
    >
      <div>{children}</div>
    </div>
  );
};
