import React from "react";

export const Download = () => {
    return (
        <React.Fragment>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                <path d="M17 9.50391C19.175 9.51602 20.3529 9.61247 21.1213 10.3809C22 11.2596 22 12.6738 22 15.5022V16.5022C22 19.3306 22 20.7449 21.1213 21.6235C20.2426 22.5022 18.8284 22.5022 16 22.5022H8C5.17157 22.5022 3.75736 22.5022 2.87868 21.6235C2 20.7449 2 19.3306 2 16.5022V15.5022C2 12.6738 2 11.2596 2.87868 10.3808C3.64706 9.61247 4.82497 9.51602 7 9.50391" stroke="white" stroke-width="1.5" stroke-linecap="round" />
                <path d="M12 2.5V15.5M12 15.5L9 12M12 15.5L15 12" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
        </React.Fragment>
    );
};
