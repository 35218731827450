import React from 'react';
import styles from './FromComponent.module.css';

const InputTypeMedia = ({ name, id, onChange, accept, required, labelText = 'Upload Media', className }) => {
    return (
        <div className={`${styles.inputWrapper} ${className}`}>
            <label htmlFor={id} className={styles.labelfile}>
                {labelText}
            </label>
            <input
                type="file"
                name={name}
                id={id}
                accept={accept}
                onChange={onChange}
                className={styles.inputFile}
                required={required}
            />
        </div>
    );
};

export default InputTypeMedia;
