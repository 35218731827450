import React, { useCallback, useEffect, useRef, useState } from "react";
// import { useAuth } from "../../components/context/Auth"
import styles from "./Order.module.css";
import CryptoJS from "crypto-js";
const LiveSearch = ({
  results = [],
  value,
  onChange,
  onSelect,
  qtyFocus,
  setSearchItem,
  itemseacrhRef,
  handleRemoveClick,
  searchType,
  warehouse,
  setShowAddItem,
  cartType,
  itemFocus,
}) => {
  const [designation, setDesignation] = useState();
  // var designations = sessionStorage.getItem("designation");
  const getDesignation = sessionStorage.getItem("designation")
    ? sessionStorage.getItem("designation")
    : "";
  const decryptedDesignationBytes = CryptoJS.AES.decrypt(
    getDesignation,
    "secret key"
  );
  const designations = decryptedDesignationBytes.toString(CryptoJS.enc.Utf8);
  useEffect(() => {
    if (designations) {
      setDesignation(designations.split(",")[0]);
    }
  }, [setDesignation, designations]);
  //   console.log('designation', designation);
  // const { switchDisplay } = useAuth()
  const [focusedIndex, setFocusedIndex] = useState(-1);
  const resultContainer = useRef(null);
  const [showResults, setShowResults] = useState(false);
  const [defaultValue, setDefaultValue] = useState("");

  const handleSelection = (selectedIndex) => {
    const selectedItem = results[selectedIndex];

    if (!selectedItem) return resetSearchComplete();
    else if (
      selectedItem.totalQty <= 0 &&
      searchType !== "purchase" &&
      searchType !== "adjustment"
    ) {
      alert("Out of stock");
    }
    // else if (itemsdetails.find(obj => obj.itemNo === selectedItem.itemNo && obj.warehouse === selectedItem.warehouse) && (searchType !== "purchase" && searchType !== "adjustment")) {
    //     alert("Already Added!")
    // }
    else if (
      cartType &&
      selectedItem.cartType &&
      cartType !== selectedItem.cartType
    ) {
      alert("Please Choose Same Product Type");
    } else if (selectedItem.vm === null && selectedItem.weight === null) {
      alert("Weight Or Volume Is Not Added In This Item. Please Check!");
    } else {
      onSelect && onSelect(selectedItem);
      resetSearchComplete();
      qtyFocus.current.focus();
    }
  };

  const resetSearchComplete = useCallback(() => {
    setFocusedIndex(-1);
    setShowResults(false);
  }, []);

  const handleKeyDown = (e) => {
    const { key, ctrlKey, keyCode, altKey } = e;
    // console.log(keyCode)
    let nextIndexCount = 0;

    // move down
    if (key === "ArrowDown")
      nextIndexCount = (focusedIndex + 1) % results.length;

    // move up
    if (key === "ArrowUp")
      nextIndexCount = (focusedIndex + results.length - 1) % results.length;

    // hide search results
    if (key === "Escape") {
      resetSearchComplete();
    }

    // select the current item
    if (key === "Enter") {
      e.preventDefault();
      setSearchItem("");
      handleSelection(focusedIndex);
      setShowResults(false);
    }
    if (ctrlKey && keyCode === 32) {
      setShowResults(true);
    }
    if (
      (designation === "superadmin" || designation === "inventorymanager") &&
      altKey &&
      keyCode === 67
    ) {
      setShowAddItem(true);
    }

    setFocusedIndex(nextIndexCount);
  };

  const handleChange = (e) => {
    if (searchType === "adjustment" && !warehouse) {
      alert("Please Select Warehouse");
    } else {
      setDefaultValue(e.target.value);
      onChange && onChange(e);
      setShowResults(true);
    }
  };

  useEffect(() => {
    if (!resultContainer.current) return;

    resultContainer.current.scrollIntoView({
      block: "center",
    });
  }, [focusedIndex]);

  useEffect(() => {
    if (value) setDefaultValue(value);
  }, [value]);

  const sortData = (a, b) => {
    if (a.totalQty === 0 && b.totalQty === 0) {
      return a.warehouse.localeCompare(b.warehouse);
    } else if (a.totalQty === 0) {
      return 1;
    } else if (b.totalQty === 0) {
      return -1;
    } else {
      return a.totalQty - b.totalQty;
    }
  };
  const sortedData = results.sort(sortData);

  return (
    <React.Fragment>
      <div
        tabIndex={1}
        onBlur={() => {
          resetSearchComplete();
        }}
        onKeyDown={handleKeyDown}
        className={styles.inputDiv}
      >
        <input
          value={defaultValue}
          onChange={handleChange}
          type="text"
          className={styles.inputSeacrh}
          placeholder="Search your query..."
          onClick={() => {
            setShowResults(true);
          }}
          autoFocus={true}
          ref={itemFocus}
        />

        {/* Search Results Container */}

        {showResults && (
          <div>
            <div className={styles.suggetion}>
              <table border="0">
                <thead>
                  {(designation === "superadmin" ||
                    designation === "inventorymanager") && (
                    <tr colSpan={3}>
                      <td colSpan={3}>
                        <div
                          className={styles.dealerCreateNew}
                          onClick={() => {
                            setShowAddItem(true);
                            setShowResults(false);
                          }}
                        >
                          Create New Item{" "}
                          <span className={styles.text_right}>Alt + C</span>
                        </div>
                      </td>
                    </tr>
                  )}
                  <tr colSpan={3}>
                    <td colSpan={3}>
                      <div
                        className={styles.dealerCreateNew}
                        onClick={() => {
                          handleRemoveClick();
                          setShowResults(false);
                        }}
                      >
                        End of List{" "}
                        <span className={styles.text_right}>Ctrl + X</span>
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td>ItemNo</td>
                    <td>Qty</td>
                    <td>Warehouse</td>
                  </tr>
                </thead>
                <tbody onClick={() => setShowResults(true)}>
                  {sortedData &&
                    sortedData.map((item, index) => {
                      return (
                        <tr
                          key={index}
                          onMouseDown={() => {
                            handleSelection(index);
                            setShowResults(false);
                          }}
                          ref={
                            index === focusedIndex && item.totalQty <= 0
                              ? resultContainer
                              : null
                          }
                          className={`${
                            index === focusedIndex ? styles.foucsList : ""
                          } ${styles.suggetionList}`}
                        >
                          <td
                            className={
                              item.totalQty <= 0 &&
                              searchType !== "purchase" &&
                              searchType !== "adjustment"
                                ? styles.outqty
                                : ""
                            }
                          >
                            {item.itemNo}
                            {/* {renderItem(item)}  */}
                          </td>
                          <td
                            className={
                              item.totalQty <= 0 &&
                              searchType !== "purchase" &&
                              searchType !== "adjustment"
                                ? styles.outqty
                                : ""
                            }
                          >
                            {item.totalQty}
                          </td>
                          <td
                            className={
                              item.totalQty <= 0 &&
                              searchType !== "purchase" &&
                              searchType !== "adjustment"
                                ? styles.outqty
                                : ""
                            }
                          >
                            {item.warehouse}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default LiveSearch;
