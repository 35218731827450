import React from 'react';
import styles from '../Navbar/Navbar.module.css'; // Ensure this path is correct
import { useAuth } from '../../context/Auth';
import { useNavigate } from 'react-router-dom';

const MdNavLInks = ({ Icon, label, path , onClose}) => {
    const { mode } = useAuth();
    const navigate =  useNavigate()
    
    return (
        <div onClick={()=> {
            navigate(`${path}`)
            onClose()
            }}>
            <div className={mode ? styles.linkTahg : styles.linkTahg_dark}>
                {/* Render the icon if it is provided */}
                <div>
                    {Icon && <Icon />}
                </div>
                <div className={styles.md_drower_text}>
                    {label}
                </div>
            </div>
        </div>
    );
}

export default MdNavLInks;
