import React, { useState } from 'react'
import AvatarEditor from "react-avatar-editor";
import { GreenOutButton, RedOutButton } from '../../components/Buttons';
import styles from "./ImageCrop.module.css"
const ImageCrop = ({ displayModal, closeModal, dealerId, inputImg, hw, setInputImg, setProgressPercent, setAddData, setProfileImageBlob, setStoreImageBlob }) => {
    var editor = "";
    const [picture, setPicture] = useState({
        cropperOpen: false,
        img: null,
        zoom: 2,
        croppedImg: "",
        position: {
            x: 0,
            y: 0,
        },
    });
    const handleScale = e => {
        const scale = parseFloat(e.target.value)
        setPicture({
            ...picture,
            zoom: scale
        });
    }
    const handlePositionChange = position => {
        setPicture({
            ...picture,
            position
        });
    }

    const handleCancel = () => {
        setInputImg("")
        closeModal()
    };

    const setEditorRef = (ed) => {
        editor = ed;
    };

    const handleSave = (e) => {
        if (setEditorRef) {
            const canvasScaled = editor.getImageScaledToCanvas();
            canvasScaled.toBlob((blob) => {
                if (hw.imgName === "profile") {
                    uploadProfile(blob)
                }
                else if (hw.imgName === "store") {
                    uploadStore(blob)
                }
                else if (hw.imgName === "qrCode") {
                    uploadQR(blob)
                } else if (hw.imgName === "adharImg") {
                    uploadAddhar(blob)
                }
            });
        }
    };
  
    const uploadProfile = async (blob) => {
        // alert("h")
        // setProgressPercent(prevState => ({
        //     ...prevState,
        //     profileImage: true
        // }));
        closeModal();
        setAddData(prevState => ({ ...prevState, profileImage: "" }))
        setProfileImageBlob(blob)
        // var myHeaders = new Headers();
        // myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
        // var formdata = new FormData();
        // formdata.append("profileImage", blob);

        // var requestOptions = {
        //     method: 'POST',
        //     headers: myHeaders,
        //     body: formdata,
        //     redirect: 'follow'
        // };

        // fetch(process.env.REACT_APP_URL + "dealer/uploadprofile/" + dealerId, requestOptions)
        //     .then(response => response.json())
        //     .then(result => {
        //         if (result.status === "success") {
        //             setAddData(prevState => ({ ...prevState, profileImage: result.profileImage }))
        //             setInputImg("");
        //             setProgressPercent(prevState => ({
        //                 ...prevState,
        //                 profileImage: false
        //             }));
        //             closeModal();
        //         } else {
        //             closeModal();
        //             setProgressPercent(prevState => ({
        //                 ...prevState,
        //                 profileImage: result.msg
        //             }));
        //             setInputImg("");
        //         }
        //     })
        //     .catch(error => console.log('error', error));
    }
    const uploadStore = async (blob) => {
        // setProgressPercent(prevState => ({
        //     ...prevState,
        //     storeImg: true
        // }));
        closeModal();
        setAddData(prevState => ({ ...prevState, storeImg: "" }))
        setStoreImageBlob(blob)
        // var myHeaders = new Headers();
        // myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
        // var formdata = new FormData();
        // formdata.append("storeImg", blob);

        // var requestOptions = {
        //     method: 'POST',
        //     headers: myHeaders,
        //     body: formdata,
        //     redirect: 'follow'
        // };

        // fetch(process.env.REACT_APP_URL + "dealer/uploadstore/" + dealerId, requestOptions)
        //     .then(response => response.json())
        //     .then(result => {
        //         if (result.status === "success") {
        //             setAddData(prevState => ({ ...prevState, storeImg: result.storeImg }))
        //             closeModal();
        //             setInputImg("");
        //             setProgressPercent(prevState => ({
        //                 ...prevState,
        //                 storeImg: false
        //             }));
        //         } else {
        //             closeModal();
        //             setProgressPercent(prevState => ({
        //                 ...prevState,
        //                 storeImg: result.msg
        //             }));
        //             setInputImg("");
        //         }
        //     })
        //     .catch(error => console.log('error', error));
    }

    const uploadQR = async (blob) => {
        setProgressPercent(prevState => ({
            ...prevState,
            qrCode: true
        }));
        closeModal();
        setAddData(prevState => ({ ...prevState, qrCode: "" }))

        var formdata = new FormData();
        formdata.append("qrCode", blob);

        var requestOptions = {
            method: 'POST',
            body: formdata,
            redirect: 'follow'
        };

        fetch(process.env.REACT_APP_URL + "dealer/uploadQrCode/" + dealerId, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status === "success") {
                    setAddData(prevState => ({ ...prevState, qrCode: result.qrCode }))
                    closeModal();
                    setInputImg("");
                    setProgressPercent(prevState => ({
                        ...prevState,
                        qrCode: false
                    }));
                } else {
                    closeModal();
                    setProgressPercent(prevState => ({
                        ...prevState,
                        qrCode: result.msg
                    }));
                    setInputImg("");
                }
            })
            .catch(error => console.log('error', error));
    }

    const uploadAddhar = async (blob) => {
        // alert("h")
        setProgressPercent(prevState => ({
            ...prevState,
            adharImg: true
        }));
        closeModal();
        setAddData(prevState => ({ ...prevState, adharImg: "" }))

        var formdata = new FormData();
        formdata.append("adharImg", blob);

        var requestOptions = {
            method: 'POST',
            body: formdata,
            redirect: 'follow'
        };

        fetch(process.env.REACT_APP_URL + "dealer/uploadAdhar/" + dealerId, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status === "success") {
                    setAddData(prevState => ({ ...prevState, adharImg: result.adharImg }))
                    closeModal();
                    setInputImg("");
                    setProgressPercent(prevState => ({
                        ...prevState,
                        adharImg: false
                    }));
                } else {
                    // closeModal();
                    setProgressPercent(prevState => ({
                        ...prevState,
                        adharImg: result.msg
                    }));
                    setInputImg("");
                }
            })
            .catch(error => console.log('error', error));
    }

    const divStyle = {
        display: displayModal ? 'block' : 'none'
    };
    // function closeModal(e) {
    //     e.stopPropagation()
    //     closeModal()
    // }

    return (
        <React.Fragment>
            {/* <Modal show={show} centered>
                <Modal.Body>
                    <div display="block">
                        <AvatarEditor
                            ref={setEditorRef}
                            image={inputImg}
                            width={hw.width}
                            height={hw.height}
                            // border={50}
                            rotate={0}
                            scale={picture.zoom}
                            onPositionChange={handlePositionChange}
                            style={{ width: "100%", height: "100%", }}
                        />

                        <Row className='mt-2 text-center'>
                            <Col xs="12 mb-3">
                                <input type="range" min="1" max="10" step="0.1" value={picture.zoom} onChange={handleScale} />
                            </Col>
                            <Col>
                                <RedOutButton handleSubmit={handleCancel} title="Cancel" />
                            </Col>
                            <Col>
                                <GreenOutButton handleSubmit={handleSave} title="Save" />
                            </Col>
                        </Row>
                    </div>
                </Modal.Body>
            </Modal> */}

            <div
                className={styles.modal}
                onClick={closeModal}
                style={divStyle} >
                <div
                    className={styles.modal_content}
                    onClick={e => e.stopPropagation()} >
                    <span
                        className={styles.close}
                        onClick={closeModal}>&times;
                    </span>
                    <div className={styles.text_center}>
                        <AvatarEditor
                            ref={setEditorRef}
                            image={inputImg}
                            width={hw.width}
                            height={hw.height}
                            // border={50}
                            rotate={0}
                            scale={picture.zoom}
                            onPositionChange={handlePositionChange}
                            style={{ width: "80%", height: "100%", }}
                        />
                        <div className={styles.imputdiv}>
                            <input type="range" min="1" max="10" step="0.1" value={picture.zoom} onChange={handleScale} />
                        </div>
                        <div className={styles.imgcrpBtn}>
                            <div>
                                <GreenOutButton handleSubmit={handleSave} title="Save" />
                            </div>
                            &nbsp;&nbsp;&nbsp;
                            <div>
                                <RedOutButton handleSubmit={handleCancel} title="Cancel" />
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default ImageCrop