import styles from "./FromComponent.module.css";
import { useAuth } from "../../context/Auth";
import { NavbarSearchIcon } from "../../icons/Icon";
const SrarchPin = ({ onChange, value, placeholder, type ,maxLength }) => {
    const { mode } = useAuth();
  return (
    <div
    className={styles.navbar_Search_Bar}
    style={{
      padding: "10px 20px",
      backgroundColor: mode && "#1b1d21",
      borderColor: mode && "#1b1d21",
    }}
  >
    <NavbarSearchIcon color={mode ? "#ffffff" : "#646B88"} />
    <input
      type={type ? type : "text"}
      placeholder={placeholder}
      className={
        mode ? styles.search_Bar_Input_Dark : styles.search_Bar_Input
      }
      value={value}
      onChange={(e) => {
        const inputValue = e.target.value;
        if (/^\d*$/.test(inputValue)) { // Allow only numbers
            onChange(inputValue);
        }
    }}
    onInput={(e) => {
        if (e.target.value.length > maxLength) {
            e.target.value = e.target.value.slice(0, maxLength); // Truncate to 5 characters
        }
        onChange(e.target.value);
    }}
    />
  </div>
  )
}

export default SrarchPin





