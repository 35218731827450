export const formatPath = (path) => {
    // Split path by '/'
    const pathArray = path.split('/').filter(Boolean);

    // Check if path ends with a number, return it unmodified
    if (!isNaN(pathArray[pathArray.length - 1])) {
        return path; // e.g. /dealers/123 will return as it is
    }

    // Capitalize the first letter of each part and join them with ". "
    const formatted = pathArray
        .map(segment => segment.charAt(0).toUpperCase() + segment.slice(1))
        .join('. ');

    return formatted;
};
