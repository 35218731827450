import React, { useState, useEffect } from "react";
import styles from "./ProgressBar.module.css";
import { useAuth } from "../../context/Auth";

const ProgressBar = ({ currentStep = 1 }) => {
  const { mode } = useAuth();
  const [activeStep, setActiveStep] = useState(currentStep);

  const stepLabels = [
    {
      attendanceTitle: "Work Day Start",
      attendanceTime: "07.30",
      attendanceDate: "10th Sep, 2024",
    },
    {
      attendanceTitle: "Meeting",
      attendanceTime: "09.00",
      attendanceDate: "Sat, 10th Sep, 2024",
    },
    {
      attendanceTitle: "Break",
      attendanceTime: "12.00",
      attendanceDate: "Sat, 10th Sep, 2024",
    },
    {
      attendanceTitle: "Work Resumed",
      attendanceTime: "13.00",
      attendanceDate: "Sat, 10th Sep, 2024",
    },
    {
      attendanceTitle: "Work Day End",
      attendanceTime: "17.00",
      attendanceDate: "Sat, 10th Sep, 2024",
    },
  ];

  const totalSteps = stepLabels.length;

  useEffect(() => {
    if (currentStep <= totalSteps && currentStep >= 1) {
      setActiveStep(currentStep);
    }
  }, [currentStep, totalSteps]);

  return (
    <div className={styles.progress_bar_container}>
      <div className={styles.progressBar}>
        {stepLabels.map((label, index) => (
          <React.Fragment key={index}>
            <div className={styles.radioButtonContainer}>
              <div
                className={`${styles.customRadio} ${
                  index + 1 <= activeStep ? styles.activeRadio : ""
                }`}
              />
              <div className={styles.lineContainer}>
                {index < totalSteps - 1 && (
                  <div
                    style={mode ? { backgroundColor: "#9C9797" } : {}}
                    className={styles.line}
                  />
                )}
              </div>
            </div>
          </React.Fragment>
        ))}
      </div>
      <div className={styles.progress_bar_text_container}>
        {stepLabels.map((label, index) => (
          <div key={index}>
            <p
              className={styles.progress_bar_text_head}
              style={mode ? { color: "#9C9797" } : {}}
            >
              {label.attendanceTitle}
            </p>
            <p
              className={styles.progress_bar_text}
              style={mode ? { color: "#FFFFFF" } : {}}
            >
              {label.attendanceDate}, {label.attendanceTime}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProgressBar;
