import styles from "../../../pages/ordermanager/Orders.module.css";
import { RemoveIcon } from "../../icons/Icon";

const ItemList = ({
  index,
  d,
  mode,
  isNew,
  itemsdetailslength,
  removeItem,
}) => {
  return (
    <div>
      <li
        className={`${styles.so_data_list_row} ${
          mode ? styles.so_data_list_row_dark : ""
        }`}
      >
        <>
          <div className="col">{index + 1}</div>
          <div className="col">{d.desc}</div>
          <div className="col">{d.itemNo}</div>
          <div className="col">{d.warehouse}</div>
          <div className="col">{d.qty}</div>
          <div className="col">{d.price}</div>
          <div className="col">{d.unit}</div>
          <div className="col">{d.qty * d.price}</div>
        </>
      </li>
      <div
        style={{
          marginLeft: "5px",
          cursor: "pointer",
          visibility: itemsdetailslength === 1 && !isNew ? "hidden" : "visible",
        }}
        onClick={removeItem}
      >
        <RemoveIcon />
      </div>
    </div>
  );
};

export default ItemList;
