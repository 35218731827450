import { useNavigate } from "react-router-dom";
import {
  CrossIcon,
  CancelIcon2,
  SaveTaxRateIcon,
  TestTopAddDealerIcon,
  TestTopBackArrowIcon,
  TestTopDotIcon,
} from "../../components/icons/Icon";
import Button from "../../components/updatedComponents/Dealers/Button";
import SearchBar from "../../components/updatedComponents/FormComponents/SearchBar";
import styles from "./Inventory.module.css";
import { useAuth } from "../../components/context/Auth";
import { useEffect, useState } from "react";
import ModalWrapper from "../../components/updatedComponents/layoutWrapper/ModalWrapper";
import Spinner from "../../components/updatedComponents/FormComponents/Spinner";
import RadioButtonGroup from "../../components/updatedComponents/FormComponents/RadioButtonGroup";
import { useToast } from "../../components/updatedComponents/Toaster/Toaster";

const TaxRate = () => {
  const { mode, managerId } = useAuth();
  const navigate = useNavigate();
  const [taxRates, setTaxRates] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [modalContent, setModalContent] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [successCounter, setSuccessCounter] = useState(0);

  const handleOpenModal = (content) => {
    setIsModalOpen(true);
    setModalContent(content);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setModalContent(null);
  };

  const handleSuccess = () => {
    setSuccessCounter((prevCounter) => prevCounter + 1);
    console.log("Tax rate operation was successful!");
  };

  useEffect(() => {
    const myHeaders = new Headers();
    myHeaders.append(
      "wa-platform",
      "WAzxe01MBXU2werWrW2WAIMAdminWi342ASDADAShyIIoKvmYI"
    );
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      managerId: managerId,
      searchKeyword: searchKeyword,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    const getTaxData = async () => {
      try {
        setLoading(true);
        const res = await fetch(
          `${process.env.REACT_APP_URL}inventory/allTaxRate?page=1`,
          requestOptions
        );

        const data = await res.json();
        if (data.status === "success") {
          setLoading(false);
          setTaxRates(data.data);
        }
      } catch (error) {
        setLoading(false);
        console.error("Error fetching tax data:", error);
      }
    };

    getTaxData();
  }, [managerId, searchKeyword, successCounter]);

  return (
    <>
      <div
        className={styles.inventory}
        style={mode ? { backgroundColor: "#2C2E33", border: "none" } : {}}
      >
        <div className={styles.inventory_top}>
          <div className={styles.inventory_top_left}>
            <div
              className={styles.inventory_top_left_go_back}
              onClick={() => navigate(-1)}
            >
              <TestTopBackArrowIcon color={mode ? "#ffffff" : "#0100E4"} />
              <p
                style={{
                  fontSize: "16px",
                  fontWeight: "500",
                  lineHeight: "24px",
                  margin: 0,
                  paddingTop: "0.21rem",
                  color: mode ? "#FFFFFF" : "#0100E4",
                }}
              >
                Go Back
              </p>
            </div>
            <TestTopDotIcon color={mode ? "#E4E7EB" : "#646B88"} />
            <p
              className={styles.inventory_top_left_path_text}
              style={{
                color: mode ? "#9C9797" : "#646B88",
              }}
            >
              Product/Tax Rate
            </p>
          </div>

          <div className={styles.tax_rate_btn}>
            <Button
              buttonIcon={<TestTopAddDealerIcon />}
              buttonText={"Create Tax Rate"}
              buttonBgColor={"#4164E3"}
              buttonTextColor={"#ffffff"}
              buttonBorder={"none"}
              buttonClick={() => {
                handleOpenModal(
                  <AddTaxRateModal
                    handleCloseModal={handleCloseModal}
                    mode={mode}
                    managerId={managerId}
                    type={"create"}
                    onSuccess={handleSuccess}
                  />
                );
              }}
            />
          </div>
          <ModalWrapper isOpen={isModalOpen} onClose={handleCloseModal}>
            {modalContent}
          </ModalWrapper>
        </div>
        <div className={styles.inventory_search_bar}>
          <SearchBar
            placeholder={"Search by Tax ID"}
            value={searchKeyword}
            onChange={(e) => {
              setSearchKeyword(e.target.value);
            }}
          />
        </div>
        <div className={styles.inventory_table_container}>
          <ul className={styles.responsiveTable}>
            <li
              className={styles.tableHeader}
              style={
                mode
                  ? {
                      backgroundColor: "#232529",
                      color: "#fafafa",
                    }
                  : { backgroundColor: "#D9DCE5", color: "#646B88" }
              }
            >
              <div className="col">Status</div>
              <div className="col">Tax ID</div>
              <div className="col">Name</div>
              <div className="col">Rate</div>
            </li>

            {loading ? (
              <div className={styles.collection_container}>
                <Spinner />
              </div>
            ) : (
              <div className={styles.inventory_data_container}>
                {taxRates.map((t, index) => (
                  <li
                    key={index}
                    className={styles.tableHeader}
                    style={
                      mode
                        ? {
                            backgroundColor: "#1B1D21",
                            color: "#fafafa",
                            cursor: "pointer",
                          }
                        : { backgroundColor: "#FFFFFF", cursor: "pointer" }
                    }
                    onClick={() => {
                      handleOpenModal(
                        <AddTaxRateModal
                          handleCloseModal={handleCloseModal}
                          mode={mode}
                          managerId={managerId}
                          type={"edit"}
                          taxRate={t}
                          onSuccess={handleSuccess}
                        />
                      );
                    }}
                  >
                    <div
                      className="col"
                      style={{
                        color: t.status === "Active" ? "#34A853" : "#F93E3E",
                      }}
                    >
                      {t.status}
                    </div>
                    <div className="col">{t.taxRateId}</div>
                    <div className="col">{t.name}</div>
                    <div className="col">{t.value}%</div>
                  </li>
                ))}
              </div>
            )}
          </ul>
        </div>
      </div>
    </>
  );
};

const AddTaxRateModal = ({
  handleCloseModal,
  mode,
  managerId,
  type,
  taxRate,
  onSuccess,
}) => {
  const { show } = useToast();
  const [taxName, setTaxName] = useState("");
  const [taxValue, setTaxValue] = useState("");
  const [taxRateId, setTaxRateId] = useState("");
  const [status, setStatus] = useState("Disable");

  useEffect(() => {
    if (type === "edit" && taxRate) {
      setTaxName(taxRate.name || "");
      setTaxValue(taxRate.value || "");
      setTaxRateId(taxRate.taxRateId || "");
      setStatus(taxRate.status || "Disable");
    }
  }, [type, taxRate]);

  const handleAddTaxRate = async () => {
    const myHeaders = new Headers();
    myHeaders.append(
      "wa-platform",
      "WAzxe01MBXU2werWrW2WAIMAdminWi342ASDADAShyIIoKvmYI"
    );
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      managerId: managerId,
      taxRateId: type !== "create" && taxRateId,
      name: taxName,
      value: taxValue,
      status: status,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      await fetch(
        `${process.env.REACT_APP_URL}inventory/adduptax`,
        requestOptions
      );
      show(
        `Tax Rate ${type === "edit" ? "updated" : "added"} successfully`,
        "success"
      );
      handleCloseModal();
      onSuccess();
    } catch (error) {
      console.error("Error while adding tax rate:", error);
    }
  };

  return (
    <div
      className={styles.add_tax_rate}
      style={mode ? { backgroundColor: "#2c2e33", color: "#fff" } : {}}
    >
      <div className={styles.add_tax_rate_top}>
        <p style={mode ? { color: "#fff" } : {}}>ID: AIDI</p>
        <div onClick={handleCloseModal} style={{ cursor: "pointer" }}>
          <CrossIcon color={mode ? "#FFFFFF" : "#1B1D21"} />
        </div>
      </div>
      <div className={styles.add_tax_rate_form}>
        <div className={styles.add_tax_rate_form_element}>
          <label htmlFor="">
            Name<span style={{ color: "red" }}>*</span>
          </label>
          <input
            type="text"
            required
            value={taxName}
            placeholder="Enter Tax Name"
            onChange={(e) => setTaxName(e.target.value)}
            maxLength={10}
            disabled={type === "edit"}
            style={{
              cursor: type === "edit" && "not-allowed",
              ...(mode && {
                backgroundColor: "#1B1D21",
                border: "1px solid #646B88",
                color: "#FFFFFF",
              }),
            }}
          />
        </div>
        <div className={styles.add_tax_rate_form_element}>
          <label htmlFor="">
            Value<span style={{ color: "red" }}>*</span>
          </label>
          <input
            type="number"
            required
            value={taxValue}
            placeholder="Enter Tax Rate"
            onChange={(e) => setTaxValue(e.target.value)}
            maxLength={3}
            disabled={type === "edit"}
            style={{
              cursor: type === "edit" && "not-allowed",
              ...(mode && {
                backgroundColor: "#1B1D21",
                border: "1px solid #646B88",
                color: "#FFFFFF",
              }),
            }}
          />
        </div>
        {type !== "create" && (
          <div className={styles.add_tax_rate_form_element}>
            <label htmlFor="">
              Tax Rate ID<span style={{ color: "red" }}>*</span>
            </label>
            <input
              type="text"
              required
              value={taxRateId}
              placeholder="Enter Tax Rate ID"
              onChange={(e) => setTaxRateId(e.target.value)}
              maxLength={10}
              disabled={type === "edit"}
              style={{
                cursor: type === "edit" && "not-allowed",
                ...(mode && {
                  backgroundColor: "#1B1D21",
                  border: "1px solid #646B88",
                  color: "#FFFFFF",
                }),
              }}
            />
          </div>
        )}
        <div className={styles.add_tax_rate_form_element}>
          <label htmlFor="" style={{ marginBottom: "-1.2rem" }}>
            Status<span style={{ color: "red" }}>*</span>
          </label>
          <RadioButtonGroup
            options={[
              { value: "Active", label: "Active" },
              { value: "Disable", label: "Disable" },
            ]}
            selectedOption={status}
            onChange={(value) => setStatus(value)}
          />
        </div>

        <p>{taxName && `${taxName} ${taxValue}%`}</p>
        <div>
          <Button
            buttonIcon={<CancelIcon2 />}
            buttonText={"CANCEL"}
            buttonBgColor={"#000000"}
            buttonTextColor={"#ffffff"}
            buttonBorder={"none"}
            buttonClick={handleCloseModal}
          />
          <Button
            buttonIcon={<SaveTaxRateIcon />}
            buttonText={"SAVE"}
            buttonBgColor={"#4164E3"}
            buttonTextColor={"#ffffff"}
            buttonBorder={"none"}
            buttonClick={handleAddTaxRate}
          />
        </div>
      </div>
    </div>
  );
};

export default TaxRate;
