import { useCallback, useEffect, useRef, useState } from "react";
import styles from "../../../pages/ordermanager/Orders.module.css";
import { RemoveIcon } from "../../icons/Icon";
import SearchItem from "./SearchItem";
import fetchData from "../../../APIsControll/apiControll";
import ModalWrapper from "../layoutWrapper/ModalWrapper";
import AddNewItemModal from "./AddNewItemModal";
import CryptoJS from "crypto-js";

const NewItemList = ({
  mode,
  showItemSearch,
  setShowItemSearch,
  managerId,
  orderType,
  onSelectItem,
  index,
  removeItem,
  addItem,
  itemsdetailslength,
  newItemListRef,
  item,
  onShowDescriptionInputChange,
  onCompletion,
  quantityRefs,
}) => {
  const quantityInputRef = useRef(null);
  const descriptionInputRef = useRef(null);
  const [designation, setDesignation] = useState();
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState([]);
  const [searchItem, setSearchItem] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showDescriptionInput, setShowDescriptionInput] = useState(false);
  const [selectedItem, setSelectedItem] = useState({
    itemNo: "",
    product_name: "",
    collection_name: "",
    warehouse: "",
    qty: "",
    dp: "",
    unit: "",
    amount: "",
    totalQty: "",
    desc: "",
    descKey: "",
  });
  const getDesignation = sessionStorage.getItem("designation")
    ? sessionStorage.getItem("designation")
    : "";
  const decryptedDesignationBytes = CryptoJS.AES.decrypt(
    getDesignation,
    "secret key"
  );
  const designations = decryptedDesignationBytes.toString(CryptoJS.enc.Utf8);

  useEffect(() => {
    if (designations) {
      setDesignation(designations.split(",")[0]);
    }
  }, [setDesignation, designations]);

  useEffect(() => {
    quantityRefs[index] = quantityInputRef;
  }, [index, quantityRefs]);

  const fetchItems = useCallback(async () => {
    const apiData = {
      managerId: managerId,
      keyword: searchItem,
      orderType: orderType,
    };
    try {
      setLoading(true);
      const result = await fetchData("order/searchitems?page=", apiData);
      if (result.status === "success") {
        setResults(result.data || []);
        setCurrentIndex(0);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }, [searchItem, managerId, orderType]);

  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      if (searchItem) {
        fetchItems();
      } else {
        setResults([]);
        setCurrentIndex(0);
      }
    }, 700);

    return () => clearTimeout(debounceTimer);
  }, [searchItem, managerId, orderType, fetchItems]);

  const handleDescriptionInputToggle = (value) => {
    setShowDescriptionInput(value);
    if (onShowDescriptionInputChange) {
      onShowDescriptionInputChange(value);
    }
  };

  const handleFieldUpdate = (updatedField) => {
    const updatedItem = { ...selectedItem, ...updatedField };
    setSelectedItem(updatedItem);
    onSelectItem(updatedItem);
  };

  const openAddItemModal = (e) => {
    if (designation === "superadmin" || designation === "inventorymanager") {
      e.preventDefault();
      setIsModalOpen(true);
      setShowItemSearch(false);
      return;
    } else {
      alert("Only ADMIN and INVENTORY MANAGER can add new item!");
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "ArrowDown") {
      setCurrentIndex((prevIndex) =>
        prevIndex < results.length - 1 ? prevIndex + 1 : prevIndex
      );
      e.preventDefault();
      return;
    }

    if (e.key === "ArrowUp") {
      setCurrentIndex((prevIndex) =>
        prevIndex > 0 ? prevIndex - 1 : prevIndex
      );
      e.preventDefault();
      return;
    }

    if (e.ctrlKey && e.key === "Backspace") {
      e.preventDefault();
      removeItem();
      if (index > 0) {
        const prevQuantityRef = quantityRefs[index - 1]?.current;
        if (prevQuantityRef) {
          prevQuantityRef.focus();
        }
      }
      return;
    }

    if (e.altKey && e.key === "c") {
      setTimeout(() => {
        openAddItemModal(e);
      }, 0);
    }

    if (e.key === "Enter") {
      e.preventDefault();
      if (e.target === quantityInputRef.current) {
        const quantityValue = e.target.value;
        if (
          quantityValue &&
          !isNaN(quantityValue) &&
          parseFloat(quantityValue) > 0 &&
          quantityValue <= selectedItem.totalQty
        ) {
          const calculatedAmount = item.price * quantityValue;
          const updatedItem = {
            ...selectedItem,
            qty: quantityValue,
            amount: calculatedAmount,
          };
          setSelectedItem(updatedItem);
          handleFieldUpdate(updatedItem);
          handleDescriptionInputToggle(true);
          setTimeout(() => {
            descriptionInputRef.current?.focus();
          }, 0);
        } else if (quantityValue > selectedItem.totalQty) {
          alert(
            `Quantity exceeds available stock! Total available: ${selectedItem.totalQty}`
          );
        } else {
          alert("Please enter a valid quantity before proceeding.");
        }
      } else if (e.target === descriptionInputRef.current) {
        const updatedItem = { ...selectedItem, desc: e.target.value };
        setSelectedItem(updatedItem);
        handleFieldUpdate(updatedItem);
        addItem();
        handleDescriptionInputToggle(false);

        setTimeout(() => {
          newItemListRef.current?.focus();
        }, 0);
      } else {
        if (searchItem.length === 0) {
          if (itemsdetailslength === 1) {
            alert("YOU CANNOT ADD NEW ITEM BEFORE FILLING THE CURRENT ONE!");
            newItemListRef.current.focus();
          } else {
            onCompletion && onCompletion();
            removeItem();
          }
        } else {
          handleRowClick(currentIndex);
        }
      }
    }
  };

  const resetSearchComplete = () => {
    setCurrentIndex(0);
    setShowItemSearch(false);
  };

  const handleRowClick = (index) => {
    const selectedItem = results[index];
    if (!selectedItem) {
      resetSearchComplete();
      return;
    }
    if (selectedItem.vm === null && selectedItem.weight === null) {
      alert("Either weight or volume is not added to this item");
    } else if (selectedItem.totalQty <= 0) {
      alert("Item is out of stock");
    } else {
      setSelectedItem(selectedItem);
      onSelectItem && onSelectItem(selectedItem);
      resetSearchComplete();
      setTimeout(() => {
        quantityInputRef.current?.focus();
      }, 0);
    }
    setSearchItem("");
  };

  return (
    <>
      <ModalWrapper
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        noBtn
      >
        <AddNewItemModal
          handleCloseModal={() => setIsModalOpen(false)}
          managerId={managerId}
          mode={mode}
        />
      </ModalWrapper>
      <tbody key={index}>
        <tr>
          <td
            className={`${styles.table_cell} ${
              mode ? styles.table_cell_dark : ""
            }`}
          >
            {index + 1}
          </td>
          {selectedItem.itemNo !== "" ? (
            <>
              <td
                className={`${styles.table_cell} ${
                  mode ? styles.table_cell_dark : ""
                }`}
                onClick={() => {
                  setShowDescriptionInput(false);
                }}
              >
                {item.itemNo}
              </td>
              <td
                className={`${styles.table_cell} ${
                  mode ? styles.table_cell_dark : ""
                }`}
              >
                {item.product_name}
              </td>
              <td
                className={`${styles.table_cell} ${
                  mode ? styles.table_cell_dark : ""
                }`}
              >
                {item.warehouse}
              </td>
              <td
                className={`${styles.table_cell} ${
                  mode ? styles.table_cell_dark : ""
                }`}
              >
                <input
                  type="number"
                  placeholder="Enter Quantity"
                  ref={quantityInputRef}
                  className={`${styles.item_inp} ${
                    mode ? styles.item_inp_dark : ""
                  }`}
                  onKeyDown={handleKeyDown}
                  onChange={(e) => {
                    const quantityValue = parseFloat(e.target.value);
                    if (quantityValue <= selectedItem.totalQty) {
                      const calculatedAmount = item.price * quantityValue;
                      setSelectedItem((prevSelected) => ({
                        ...prevSelected,
                        qty: quantityValue,
                        amount: calculatedAmount,
                      }));
                    }
                  }}
                />
              </td>
            </>
          ) : (
            <td
              className={`${styles.table_cell} ${
                mode ? styles.table_cell_dark : ""
              }`}
              colSpan="4"
            >
              <div className={styles.inp_div}>
                <input
                  ref={newItemListRef}
                  type="text"
                  placeholder="Search your query"
                  className={`${styles.item_inp} ${
                    mode ? styles.item_inp_dark : ""
                  }`}
                  value={searchItem.toUpperCase()}
                  onChange={(e) => setSearchItem(e.target.value)}
                  onFocus={() => setShowItemSearch(true)}
                  // onBlur={() => setShowItemSearch(false)}
                  onBlur={() => {
                    setTimeout(() => {
                      setShowItemSearch(false);
                    }, 100);
                  }}
                  onKeyDown={handleKeyDown}
                />
                {showItemSearch && (
                  <SearchItem
                    key={index}
                    mode={mode}
                    results={results}
                    currentIndex={currentIndex}
                    loading={loading}
                    onItemSelect={(index) => handleRowClick(index)}
                    openModal={openAddItemModal}
                    // onItemSelect={() => console.log("VALUE")}
                  />
                )}
              </div>
            </td>
          )}

          <td
            className={`${styles.table_cell} ${
              mode ? styles.table_cell_dark : ""
            }`}
          >
            {item.unit}
          </td>
          <td
            className={`${styles.table_cell} ${
              mode ? styles.table_cell_dark : ""
            }`}
          >
            {item.price}
          </td>
          <td
            className={`${styles.table_cell} ${
              mode ? styles.table_cell_dark : ""
            }`}
          >
            {selectedItem.amount ? selectedItem.amount : ""}
          </td>
          {itemsdetailslength > 1 && (
            <td
              className={styles.table_cell}
              style={{ width: "0px", backgroundColor: "transparent" }}
            >
              <div
                style={{ cursor: "pointer", marginRight: "-0.5rem" }}
                onClick={removeItem}
              >
                <RemoveIcon />
              </div>
            </td>
          )}
        </tr>
      </tbody>

      {showDescriptionInput && (
        <input
          style={{ width: "496%", fontSize: "12px" }}
          type="text"
          placeholder="Enter description"
          className={`${styles.item_inp} ${mode ? styles.item_inp_dark : ""}`}
          ref={descriptionInputRef}
          onKeyDown={handleKeyDown}
        />
      )}
    </>
  );
};

export default NewItemList;
