import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { GreenButton, GreenOutButton, RedOutButton } from '../../components/Buttons'
import { useAuth } from '../../components/context/Auth'
import { DeleteIcon, Edit, Setting, View } from '../../components/icons/Icon'
import Modal from '../../components/Modal'
import AdvancedSettings from './AdvancedSettings'
import styles from './Product.module.css'

const Products = () => {
    const navigate = useNavigate()
    const { managerId } = useAuth()
    const [showModal, setShowModal] = useState(false)
    const [productData, setProductData] = useState([])
    const [loading, setLoading] = useState(false)
    const [showDeleteProductModal, setShowDeleteProductModal] = useState(false)
    const [deleteProductId, setDeleteProductId] = useState('')
    const [deleteProductTrue, setDeleteProductTrue] = useState(false)
    const [deleteProductName, setDeleteProductName] = useState('')
    const selectModal = () => {
        setShowModal(!showModal)
    }

    useEffect(() => {
        setLoading(true)
        var myHeaders = new Headers();
        myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);

        var raw = "";

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(process.env.REACT_APP_URL + "content/products", requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status === "success") {
                    setLoading(false)
                    setProductData(result.data)
                }
            })
            .catch(error => console.log('error', error));
    }, [deleteProductTrue])

    const handleDeleteProductItem = (deleteProductId) => {
        if (managerId) {
            setDeleteProductTrue(false)
            var myHeaders = new Headers();
            myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                "managerId": managerId,
                "_id": deleteProductId
            });

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            fetch(process.env.REACT_APP_URL + "content/deleteProduct", requestOptions)
                .then(response => response.json())
                .then(result => {
                    // alert(result.msg)
                    if (result.status === 'success') {
                        setDeleteProductTrue(true)
                    }
                })
                .catch(error => console.log('error', error));
        }
    }

    return (
        <React.Fragment>
            <AdvancedSettings
                displayModal={showModal}
                closeModal={selectModal}
            />

            <Modal
                show={showDeleteProductModal}
                close={setShowDeleteProductModal}
                closeBtn={true}
                heading={deleteProductName&&deleteProductName}
                content={
                    <div className={styles.text_center}>
                        <div className={styles.deleteModal_text}>Are You Sure Want to Delete<p>{deleteProductName&&deleteProductName}</p></div>
                        <div className={styles.massageModal_btn}>
                            <RedOutButton title="CANCEL" handleSubmit={() => setShowDeleteProductModal(false)} />
                            <GreenButton title={<div className={styles.deleteButton}><DeleteIcon />&nbsp;DELETE</div>} handleSubmit={() => { handleDeleteProductItem(deleteProductId); setShowDeleteProductModal(false) }} />
                        </div>
                    </div>
                }
            />

            <div className={styles.product_main_div}>
                <div className={styles.product_header}>
                    <h1 className={styles.heading}>Products</h1>
                    <div>
                        Sort By
                        <select className={styles.product_filter}>
                            <option className={styles.bg_dark}>View All</option>
                            <option className={styles.bg_dark}>Active</option>
                            <option className={styles.bg_dark}>Disabled</option>
                            <option className={styles.bg_dark}>Deleted</option>
                            <option className={styles.bg_dark}>Ascending</option>
                            <option className={styles.bg_dark}>Descending</option>
                        </select>

                        <GreenOutButton type="button" title="+ ADD PRODUCT" css="" handleSubmit={() => navigate('/addproduct?step=0')} />
                    </div>
                </div>
                <div className={styles.product_scroll_div}>

                    {
                        loading ? <div className={'loadingMainDealer'}>
                            <img src='/wallicon.gif' alt='walliconGIF' />
                        </div> :
                            productData && productData.map((item, index) =>
                                <div className={styles.productRow} key={index}>
                                    <div className={styles.w_100} onClick={() => navigate('/product/' + item.product_name.replace(/\s/g, "-").toUpperCase() + '?product_id=' + item._id)}>
                                        <input type="radio" defaultChecked={item.status === "Active" ? true : false} /> <span className={styles.muted_clr}>Active</span>
                                        <h1>
                                            {item.product_name}
                                        </h1>
                                    </div>
                                    <div className={styles.productBtns}>
                                        <div className={styles.productsEVS} onClick={() => { setShowDeleteProductModal(true); setDeleteProductId(item._id); setDeleteProductName(item.product_name)}}>
                                            <div><DeleteIcon /></div>
                                            <span>Delete</span>
                                        </div>
                                        <div className={styles.productsEVS} onClick={selectModal}>
                                            <div><Setting /></div>
                                            <span>Settings</span>
                                        </div>
                                        <div className={styles.productsEVS}
                                            onClick={(e) => { navigate('/addproduct?step=1&id=' + item._id); localStorage.setItem("viewEdit", 1) }}>
                                            <div><Edit color="#ffffff" /></div>
                                            Edit
                                        </div>
                                        <div className={styles.productsEVS} onClick={() => { navigate('/addproduct?step=2&id=' + item._id); localStorage.setItem("viewEdit", 0) }}>
                                            <div><View /></div>
                                            View
                                        </div>
                                    </div>
                                </div>
                            )}
                </div>
            </div>

        </React.Fragment>
    )
}

export default Products
