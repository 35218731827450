import React, { useEffect, useRef, useState } from 'react'
import MobOrdersTab from '../components/MobOrdersTab'
import styles from '../pages/MobOrders.module.css'
import { EyeTwo, Loadmore, OrdersIcon } from '../../components/icons/Icon'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../components/context/Auth'
import Modal from '../../components/Modal'
import { GreenButton, RedOutButton } from '../../components/Buttons'
import DispatchModal from '../components/DispatchModal'

const MobAllOrders = () => {

    const navigate = useNavigate();
    const { managerId } = useAuth();
    const [page, setPage] = useState(1)
    const [pages, setPages] = useState(1)
    const [showAcceptModal, setShowAcceptModal] = useState(false);
    const [showItemModal, setShowItemModal] = useState(false);
    const [orderData, setOrderData] = useState({})
    const [allData, setAllData] = useState([])
    const [daysTab, setDaysTab] = useState('today')
    const [orders, setOrders] = useState([])
    const [keyword, setKeyword] = useState('')
    const [sortKey, setSortKey] = useState('All')
    const [loading, setLoading] = useState(false)
    const [loading1, setLoading1] = useState(true)
    const [orderStatus, setOrderStatus] = useState('')
    const [warehouses, setWarehouses] = useState([])
    const [warehouseName, setWarehouseName] = useState('')
    const [managers, setManagers] = useState([])
    const [managerName, setManagerName] = useState('')
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [orderType, setOrderType] = useState('')
    const [msgShow, setMsgShow] = useState({
        status: "",
        msg: "",
    });
    const [showAcceptModal3, setShowAcceptModal3] = useState(false);


    const getCardData = async () => {
        setLoading(true)
        var myHeaders = new Headers();
        myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "managerId": parseInt(managerId),
            "keyword": keyword,
            "sortKey": sortKey,
            "orderType": orderType,
            "orderStatus": orderStatus,
            "cartType": "",
            "warehousename": warehouseName,
            "ordermanagerId": managerName,
            "day": daysTab,
            "startDate": startDate,
            "endDate": endDate,
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        fetch(`${process.env.REACT_APP_URL}order/allorders?page=${page}&limit=8&skip=${true}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status === "success") {
                    setLoading(false)
                    // setPages(result.pages)
                    setAllData(result.data)
                    setOrders(result)
                    if (result.warehouses) {
                        setWarehouses(result.warehouses)
                    }
                    if (result.managers) {
                        setManagers(result.managers)
                    }
                }
                if (result.pages === 1) {
                    setPage(1)
                }
                setPages(result.pages)
                setLoading1(false);
            })
            .catch(error => console.log('error', error));
    };

    useEffect(() => {
        /*eslint-disable*/
        getCardData();
    }, [page, managerId, sortKey, orderType, keyword, warehouseName, managerName, daysTab, endDate, startDate, orderStatus]);


    const handelInfiniteScroll = async () => {
        try {
            if (
                window.innerHeight + document.documentElement.scrollTop + 1 >=
                document.documentElement.scrollHeight

            ) {
                setLoading1(true);
                setPage((prev) => prev + 1);
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        if (pages > page) {
            window.addEventListener("scroll", handelInfiniteScroll);
            return () => window.removeEventListener("scroll", handelInfiniteScroll);
        }
    }, [pages, page]);


    function useOutsideAlerter(ref) {
        useEffect(() => {
            /**
             * Alert if clicked on outside of element
             */
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setShowAcceptModal3(false);
                }
            }
            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);


    const handleOrderAccept = (orderId) => {
        setMsgShow({
            status: "",
            msg: "",
        })
        var myHeaders = new Headers();
        myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "managerId": managerId,
            "orderId": orderId
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(process.env.REACT_APP_URL + "order/acceptorder", requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status === "success") {
                    setShowItemModal(false);
                }
                setMsgShow({
                    status: result.status,
                    msg: result.msg,
                });
                setShowAcceptModal(true);
            })
            .catch(error => console.log('error', error));
    }

    return (
        <React.Fragment>
            <MobOrdersTab
                styles={styles}
                orders={orders}
                sortKey={sortKey}
                setSortKey={setSortKey}
                loading={loading}
                orderType={orderType}
                setOrderType={setOrderType}
                keyword={keyword}
                setKeyword={setKeyword}
                warehouses={warehouses}
                warehouseName={warehouseName}
                setWarehouseName={setWarehouseName}
                managers={managers}
                setManagers={setManagers}
                managerName={managerName}
                setManagerName={setManagerName}
                daysTab={daysTab}
                setDaysTab={setDaysTab}
                endDate={endDate}
                setEndDate={setEndDate}
                startDate={startDate}
                setStartDate={setStartDate}
                setOrderStatus={setOrderStatus}
                orderStatus={orderStatus}
                setShowAcceptModal3={setShowAcceptModal3}
                showAcceptModal3={showAcceptModal3}
            />

            <h2 className={styles.orders_text}>Order's</h2>
            {
                loading ? <div className={'Mob_loading'}>
                    <img src='/wallicon.gif' alt='walliconGIF' />
                </div> :
                    <>
                        {allData.length !== 0 ?
                            <div className={styles.orders + " pb-5"} >

                                {!loading1 ?
                                    <>
                                        {allData && allData.map((item, index) =>
                                            <div role='button' key={index} className={styles.orders_div}
                                                onClick={() => {
                                                    if (item.status !== "New Order") {
                                                        navigate('/orders/saleorder?orderId=' + item.orderId);
                                                    } else {
                                                        setShowItemModal(true);
                                                        setOrderData(item)
                                                    }
                                                }
                                                }
                                            >
                                                <OrdersIcon className={styles.ordes_img} />
                                                <div className={styles.order_detail}>
                                                    <h2>{item.storeName}</h2>
                                                    <span>Order:<span>{item.orderId}</span></span>
                                                    <span>Order Date: <span>{item.orderDate}</span></span>
                                                    <span className={`${styles.order_card} 
                                                        ${item.status === 'Cancelled' ? styles.cancelled_status :
                                                            item.status === 'Payment Under Verification' || item.status === 'Request For Cancel'  ? styles.payment_status :
                                                                item.status === 'Packing In Progress' ? styles.inProgress_status :
                                                                    item.status === 'New Order' ? styles.new_status :
                                                                        item.status === 'In Progress' ? styles.inProgress_status :
                                                                            item.status === 'Payment Pending' ? styles.payment_status :
                                                                                item.status === 'Dispatched' ? styles.Dispatched_status :
                                                                                    ''}`}>
                                                        {item.status}
                                                    </span>
                                                </div>
                                                <div>
                                                    < EyeTwo />
                                                </div>
                                            </div>
                                        )}
                                    </>
                                    :
                                    <div className={styles.loadmore}>
                                        <Loadmore className={styles.loadmoreIocon} />
                                    </div>
                                }
                            </div>
                            : <div className={styles.noData_all_order}>
                                No Data Found !
                            </div>
                        }
                    </>
            }

            <DispatchModal
                show={showItemModal}
                close={setShowItemModal}
                closeBtn={true}
                heading={orderData.storeName}
                content={
                    <div className={styles.text_center}>
                        <div className={styles.product_date}>
                            <span><b>Dealer ID: {orderData.dealerId}</b></span>
                            <span><b>{orderData.orderDate}</b></span>
                        </div>
                        <div className={styles.product_date}><b>Order ID: {orderData.orderId}</b></div>

                        <div className={styles.itemCard_div}>
                            {orderData.items && orderData.items.map((item, index) =>
                                <div key={index} className={styles.itemCard}>
                                    <div className={styles.itemCard_left}>
                                        <img src={process.env.REACT_APP_S3URL + item.thumbnail} alt="item_Image" className={styles.itemImage} />
                                        <div className={styles.itemDetail_div}>
                                            <div className={styles.itemDetail}>
                                                <span className={styles.itemName_txt}>Product:</span>
                                                <span className={styles.itemName}>{item.product_name}</span>
                                            </div>
                                            <div className={styles.itemDetail}>
                                                <span className={styles.itemName_txt}>Item No:</span>
                                                <span className={styles.itemName}>{item.itemNo}</span>
                                            </div>
                                            <div className={styles.itemDetail}>
                                                <span className={styles.itemName_txt}>Price:</span>
                                                <span className={styles.itemName}>{parseFloat(item.price).toLocaleString("en-IN", { style: "currency", currency: "INR" })}/{item.unit}</span>
                                            </div>
                                            <div className={styles.itemDetail}>
                                                <span className={styles.itemName_txt}>Qty:</span>
                                                <span className={styles.itemName}>{item.qty} {item.unit}</span>
                                            </div>
                                            <div className={styles.itemDetail}>
                                                <span className={styles.itemName_txt}>Total:</span>
                                                <span className={styles.itemName}>{(parseFloat(item.price) * parseFloat(item.qty)).toLocaleString("en-IN", { style: "currency", currency: "INR" })}/-</span>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className={styles.itemCard_right}>
                                        <span className={styles.itemName_txt}>Total:</span>
                                        <span className={styles.itemName}>&nbsp;{(parseFloat(item.price) * parseFloat(item.qty)).toLocaleString("en-IN", { style: "currency", currency: "INR" })}/-</span>
                                    </div> */}
                                </div>
                            )}
                        </div>

                        <div className={styles.modalFooter_div}>
                            <div className={styles.modalFooter_GTotal}>
                                <div className={styles.borderBottom_div}>
                                    <div className={styles.total_div}>
                                        <span className={styles.itemName_txt}>Total</span>
                                        <span className={styles.total_rs}>{parseFloat(orderData.subtotal).toLocaleString("en-IN", { style: "currency", currency: "INR" })}</span>
                                    </div>
                                    <div className={styles.total_div}>
                                        <span className={styles.itemName_txt}>Shipping Charges</span>
                                        <span className={styles.total_rs}>{parseFloat(orderData.shippingInfo && orderData.shippingInfo.shippingCharge).toLocaleString("en-IN", { style: "currency", currency: "INR" })}</span>
                                    </div>
                                </div>
                                <div className={styles.grandTotal}>
                                    <span>Grand Total:</span>
                                    <span>{parseFloat(orderData.totalAmount).toLocaleString("en-IN", { style: "currency", currency: "INR" })}</span>
                                </div>
                            </div>
                            <div className={styles.modalFooter_btn}>
                                <RedOutButton css={'me-4'} title="CANCEL" handleSubmit={() => setShowItemModal(false)} />
                                <GreenButton title="ACCEPT" handleSubmit={() => handleOrderAccept(orderData.orderId)} />
                            </div>

                        </div>
                    </div>
                }
            />

            <Modal
                show={showAcceptModal}
                close={setShowAcceptModal}
                closeBtn={true}
                heading="Order Status"
                content={
                    <div className={styles.text_center}>
                        <p className={styles.message}>{msgShow.msg}</p>
                        <div className={styles.massageModal_btn}>
                            <RedOutButton css={'me-4'} title="CANCEL" handleSubmit={() => setShowAcceptModal(false)} />
                            <GreenButton title="OK" handleSubmit={() => {
                                if (msgShow.status === "success") {
                                    navigate('/orders/saleorder?orderId=' + orderData.orderId);
                                    setShowAcceptModal(false);
                                } else {
                                    setShowAcceptModal(false);
                                }
                            }} />
                        </div>
                    </div>
                }
            />

        </React.Fragment>
    )
}

export default MobAllOrders