import { NewManagerCancelIcon, TestTopAddDealerIcon } from "../../icons/Icon";
import { CloseIcon } from "../../icons/Icon2";
import Button from "../Dealers/Button";
import InputTag from "../FormComponents/InputTag";
import FileUpload from "./FileUpload";
import styles from "../../../pages/ordermanager/Orders.module.css";

const AddNewItemModal = ({ mode, handleCloseModal }) => {
  return (
    <div
      className={styles.add_new_item_modal}
      style={{ backgroundColor: mode && "#232529" }}
    >
      <div>
        <p style={{ color: mode && "#FFFFFF" }}> Add New Item</p>
        <div style={{ cursor: "pointer" }} onClick={handleCloseModal}>
          <CloseIcon mode={mode} />
        </div>
      </div>

      <div>
        <InputTag
          labelText={"Product Name"}
          placeholderText={"Enter Product name"}
        />
        <InputTag
          labelText={"Collection Name"}
          placeholderText={"Enter Collection"}
        />
      </div>
      <div>
        <InputTag
          labelText={"Item Number"}
          placeholderText={"Enter Item Number"}
        />
        <InputTag labelText={"Unit"} placeholderText={"Enter Unit"} />
      </div>
      <div>
        <InputTag labelText={"Price"} placeholderText={"Enter Price"} />
        <InputTag
          labelText={"Description"}
          placeholderText={"Enter Description"}
        />
      </div>
      <div>
        <InputTag labelText={"Weight"} placeholderText={"Enter Weight"} />
        <InputTag labelText={"Volume (CBM)"} placeholderText={"Enter Volume"} />
      </div>
      <FileUpload mode={mode} />
      <div style={{ justifyContent: "center" }}>
        <div>
          <Button
            buttonBgColor={"#000"}
            buttonText={"CANCEL"}
            buttonTextColor={"#fff"}
            buttonIcon={<NewManagerCancelIcon />}
            buttonClick={handleCloseModal}
          />
        </div>
        <div>
          <Button
            buttonBgColor={"#4164E3"}
            buttonText={"ADD ITEM"}
            buttonTextColor={"#fff"}
            buttonIcon={<TestTopAddDealerIcon />}
          />
        </div>
      </div>
    </div>
  );
};

export default AddNewItemModal;
