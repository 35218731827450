import React, { useEffect, useState } from 'react'
import styles from './PaymentModalMob.module.css'
import { Spinner } from '../../components/icons/Icon'
import { FileHandlerMob } from './DragAndDropMob'
// import AvatarEditor from 'react-avatar-editor'

const PaymentModalMob = ({ show, close, handleRecipt, reciptData, setReciptData, isImage, setisImage, totalAmount, accounts, setLoadingReceipt, loadingReceipt, closeBtn }) => {

    const [error, setError] = useState({
        receiptImage: '',
    })

    useEffect(() => {
        if (show) {
            document.body.style.overflow = 'hidden'
        } else {
            document.body.style.overflow = 'auto'
        }
    }, [show,])

    const handleSubmitRecipt = (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (((reciptData.paymentMethod === "Photo") && !isImage) || (reciptData.receiptId && reciptData.paymentMethod === 'POD' && !isImage)) {
            setError({ ...error, receiptImage: "Please upload Receipt image" })
            alert("Please upload Receipt image")
        }
        else if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }

        else {
            handleRecipt()
            setError({ ...error, receiptImage: "" })
        }
    };

    return show && (
        <>
            <div className={styles.modal_backdrop} onClick={() => { close(); }}>
                <div className={styles.modal_content} onClick={e => { e.stopPropagation(); }}>
                    <form onSubmit={handleSubmitRecipt}>
                        <div className={styles.reciptHeader_div} >
                            <h2 className={styles.reciptHeading}>Receipt</h2>
                            {(reciptData.paymentMethod === 'POD') && reciptData.receiptId &&
                                <div className='d-flex justify-content-end align-items-end mb-2'>
                                    <div className={styles.recipt_tableDiv}>
                                        <div className={styles.recipt_table_cell}><span>Date</span><span><b>{reciptData.receiptDate}</b></span></div>
                                        <div className={styles.recipt_table_cell}><span>Recipt Id</span><span><b>{reciptData.receiptId}</b></span></div>
                                        <div className={styles.recipt_table_cell}><span>Reciver</span><span><b>{reciptData.receiver && reciptData.receiver}</b></span></div>
                                        <div className={styles.recipt_table_cell}><span>Order Id</span><span><b>{reciptData.orderId}</b></span></div>
                                    </div>
                                </div>
                            }
                        </div>
                        <div className={styles.reciptContentDiv}>

                            <div className='d-flex'>
                                <div className='w-100 me-1 mb-2'>
                                    <span className={styles.method}>Method</span>
                                    <select required value={reciptData.paymentMethod} onChange={(e) => { setReciptData(''); setisImage(''); setReciptData(prevState => ({ ...prevState, paymentMethod: e.target.value })) }} className={styles.methodSelect}>
                                        <option value='' hidden>Select</option>
                                        <option value='Photo'>Photo</option>
                                        <option value='Cash'>Cash</option>
                                        <option value='POD'>Pay On Delivery</option>
                                    </select>
                                </div>

                                <div className='w-100 ms-1'>
                                    <span className={styles.method}>Total Amount</span>
                                    <input
                                        type='number'
                                        placeholder='Enter Amount'
                                        className={styles.amount_input}
                                        value={totalAmount}
                                        disabled
                                    />
                                </div>
                            </div>

                            <div className='d-flex mt-1'>
                                {
                                    reciptData.paymentMethod === 'POD' &&
                                    <div className={styles.subMethod_div}>
                                        <span className={styles.submethod_txt}>Sub Method</span>
                                        <select
                                            // required={reciptData.paymentMethod !== 'POD' ? true : false}
                                            required={reciptData.receiptId ? true : false}
                                            value={reciptData.paymentType} onChange={(e) => setReciptData(prevState => ({ ...prevState, paymentType: e.target.value }))} className={styles.subMethod_select}>
                                            <option value=''>Select</option>
                                            <option value='Cash'>Cash</option>
                                            <option value='Photo'>Photo</option>
                                        </select>
                                    </div>
                                }

                                {reciptData.paymentMethod === 'Cash' || reciptData.paymentType === 'Cash' ? '' :
                                    <div className='w-100'>
                                        <span className={styles.submethod_txt}>Account</span>
                                        <select
                                            required={reciptData.paymentMethod !== 'POD' ? true : false}
                                            value={reciptData.accountNumber}
                                            onChange={(e) => setReciptData(prevState => ({
                                                ...prevState, accountNumber: e.target.value
                                            }))}
                                            className={styles.reciptMethod_select}
                                        >
                                            <option value='' hidden>Select</option>
                                            {accounts && accounts.map((item, index) =>
                                                <option key={index} value={item.accountNumber}>
                                                    {item.acHolderName + " " + item.accountNumber}
                                                </option>)}
                                        </select>
                                    </div>
                                }

                            </div>

                            {((reciptData.paymentType !== 'Cash') && (reciptData.paymentMethod === 'Photo' || reciptData.paymentMethod === 'POD')) &&
                                <>
                                    {(isImage || reciptData.transactionScreenshot) &&
                                        <>
                                            <div className={styles.reciptImage_div}>
                                                <div className={styles.Image_div}>
                                                    <img
                                                        src={isImage ? URL.createObjectURL(isImage[0]) : process.env.REACT_APP_S3URL + reciptData.transactionScreenshot}
                                                        alt='Recipt'
                                                        onClick={() => window.open(isImage ? URL.createObjectURL(isImage[0]) : process.env.REACT_APP_S3URL + reciptData.transactionScreenshot, "mozillaWindow", "popup")}
                                                        className={styles.reciptImage}
                                                    />
                                                </div>
                                                <div className={styles.reciptImage_delete} onClick={() => { setisImage(); setReciptData(prevState => ({ ...prevState, transactionScreenshot: '' })) }}>
                                                    {/* <DeleteIcon /> */}

                                                </div>
                                            </div>
                                            <p role='button' className={styles.viewRecipt} onClick={() => window.open(isImage ? URL.createObjectURL(isImage[0]) : process.env.REACT_APP_S3URL + reciptData.transactionScreenshot, "mozillaWindow", "popup")}>*Click To View Screenshot</p>
                                        </>
                                    }
                                    {(!isImage && !reciptData.transactionScreenshot) &&
                                        <>
                                            <div className={styles.dragImage_div}>
                                                <FileHandlerMob
                                                    setisImage={setisImage}
                                                    title="Upload Image"
                                                />

                                            </div>
                                        </>
                                    }
                                    <input
                                        required={reciptData.paymentMethod !== 'POD' ? true : false}
                                        type='text'
                                        placeholder='Ref / UTR Number / Transaction Id'
                                        className={styles.recipt_inputField_ref}
                                        value={reciptData.transactionId}
                                        onChange={(e) => setReciptData(prevState => ({
                                            ...prevState, transactionId: e.target.value
                                        }))}
                                    />
                                </>
                            }

                            <input
                                required
                                type='number'
                                placeholder='Enter Transcation Amount (in Rs)'
                                className={styles.recipt_transactionAmount}
                                value={reciptData.paymentAmount}
                                onChange={(e) => setReciptData(prevState => ({
                                    ...prevState,
                                    paymentAmount: e.target.value
                                }))}
                            />

                            <div className={styles.recipt_btn}>
                                <button className={styles.order_btn} onClick={() => { close() }}>CANCEL</button>
                                <button
                                    className={styles.order_btn1}
                                    disabled={(reciptData.status === "Received" || reciptData.status === "Cancelled") ? true : false}
                                    type='submit'
                                >
                                    {loadingReceipt ? <Spinner size="15" /> : "SAVE"}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default PaymentModalMob
