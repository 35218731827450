// // ToastContext.js
// import React, { createContext, useState, useContext, useEffect } from 'react';
// import styles from './Toast.module.css'; 

// const ToastContext = createContext();

// export const useToast = () => {
//     return useContext(ToastContext);
// };

// export const ToastProvider = ({ children }) => {
//     const [toasts, setToasts] = useState([]); 
//     const show = (message, type = 'info') => {
//         const newToast = { id: Date.now(), message, type };

//         const existingToasts = toasts.filter(toast => toast.type === type);
//         if (existingToasts.length >= 3) {
//             return; 
//         }
//         setToasts((prev) => [...prev, newToast]);
//         setTimeout(() => {
//             setToasts((prev) => prev.filter((toast) => toast.id !== newToast.id));
//         }, 3000);
//     };

//     useEffect(() => {
//         if (toasts.length > 5) {
//             setToasts((prev) => prev.slice(toasts.length - 5)); 
//         }
//     }, [toasts]);

//     // success
//     // error
//     // info
//     // warning


//     return (
//         <ToastContext.Provider value={{ show }}>
//             {children}
//             <div className={styles.toastContainer}> 
//                 {toasts.map((toast) => (
//                     <div className={styles.toastRow} key={toast.id}>
//                         <div className={`${styles.toast} ${styles[toast.type]}`}
//                        style={{ maxWidth: toast.maxWidth || "300px" }}
//                         >
//                             {toast.message}
//                         </div>
//                     </div>
//                 ))}
//             </div>
//         </ToastContext.Provider>
//     );
// };




// ToastContext.js
import React, { createContext, useState, useContext, useEffect } from 'react';
import styles from './Toast.module.css';

const ToastContext = createContext();

export const useToast = () => {
    return useContext(ToastContext);
};

export const ToastProvider = ({ children }) => {
    const [toasts, setToasts] = useState([]);
    const show = (message, type = 'info') => {
        const newToast = { id: Date.now(), message, type };

        const existingToasts = toasts.filter(toast => toast.type === type);
        if (existingToasts.length >= 3) {
            return;
        }
        setToasts((prev) => [...prev, newToast]);
        setTimeout(() => {
            setToasts((prev) => prev.filter((toast) => toast.id !== newToast.id));
        }, 4000);
    };

    useEffect(() => {
        if (toasts.length > 5) {
            setToasts((prev) => prev.slice(toasts.length - 5));
        }
    }, [toasts]);

    // success
    // error
    // info
    // warning


    return (
        <ToastContext.Provider value={{ show }}>
            {children}
            <div className={styles.toastContainer}>
            {toasts.map((toast , index) => (
                <div
                    className={`${styles.toast} ${styles[toast.type]} ${toast.hide ? styles.hide : ''}`}
                    key={index}
                    style={{fontSize:"12px"}}
                >
                    {toast.message}
                </div>
            ))}
        </div>

        </ToastContext.Provider>
    );
};





