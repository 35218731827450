import {
  NavbarWhatsAppIcon,
  PaymentChatIcon,
  CopyLinkIcon,
  PaymentMailIcon,
  PaymentMessageIcon,
} from "../../icons/Icon";
import { CloseIcon } from "../../icons/Icon2";
import Button from "../Dealers/Button";
import styles from "../../../pages/ordermanager/Orders.module.css";
import { useCallback, useEffect, useRef, useState } from "react";
import { useToast } from "../Toaster/Toaster";
import fetchData from "../../../APIsControll/apiControll";

const ShareLinkModal = ({
  managerId,
  mode,
  handleCloseModal,
  generatedLink,
  isModalOpen = true,
  whatsappNo,
  mobileNo,
  email,
}) => {
  const { show } = useToast();
  const phoneInpRef = useRef(null);
  const [phoneNumber, setPhoneNumber] = useState("");

  const copyLinkToClipboard = useCallback(async () => {
    try {
      await navigator.clipboard.writeText(generatedLink);
      show("Payment link copied to clipboard!", "info");
    } catch (error) {
      show("Failed to copy the link. Please try again.", "error");
    }
  }, [generatedLink, show]);

  const openEmail = useCallback(
    (recipientEmail, subject, body) => {
      if (!recipientEmail) {
        show("Recipient email is missing!", "warning");
        return;
      }
      const mailtoLink = `mailto:${recipientEmail}?subject=${encodeURIComponent(
        subject
      )}&body=${encodeURIComponent(body)}`;

      window.location.href = mailtoLink;
    },
    [show]
  );

  const openSMS = useCallback(
    (recipientPhone, message) => {
      if (!recipientPhone) {
        show("Recipient phone number is missing!", "warning");
        return;
      }
      const smsLink = `sms:${recipientPhone}?body=${encodeURIComponent(
        message
      )}`;
      window.location.href = smsLink;
    },
    [show]
  );

  const sendPaymentLink = useCallback(
    async (phoneNumber) => {
      if (String(phoneNumber).length === 10) {
        try {
          const apiData = {
            managerId: managerId,
            phoneNumber: "+91" + phoneNumber,
            templateName: "order_payment",
            headerId: "https://dealer.wallicon.in/assets/paymentlink.jpg",
            buttonDataPayload: generatedLink && generatedLink,
          };
          const result = await fetchData(
            "wallikonChat/sentStatusTemp",
            apiData
          );
          if (result instanceof Error) {
            throw result;
          }
          if (result.data.messages[0].message_status === "accepted") {
            show("Payment Link Sent Successfully", "success");
            return;
          }
        } catch (error) {
          console.error("Error sending payment link:", error);
          show("Unable to send the Payment Link, Try again later", "error");
        }
      } else {
        show("Invalid Phone Number!", "warning");
      }
    },
    [generatedLink, managerId, show]
  );

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.ctrlKey && e.key.toLowerCase() === "c") {
        copyLinkToClipboard();
        return;
      }
      if (e.key === "Escape") {
        handleCloseModal();
      }
      if (e.key.toLowerCase() === "w") {
        setTimeout(() => {
          sendPaymentLink(whatsappNo && whatsappNo);
        }, 0);
      }
      if (e.key.toLowerCase() === "s") {
        setTimeout(() => {
          openSMS(mobileNo, `Here is your payment link: ${generatedLink}`);
        }, 0);
      }
      if (e.key.toLowerCase() === "e") {
        setTimeout(() => {
          openEmail(
            email,
            "Payment Link",
            `Here is your payment link: ${generatedLink}`
          );
        }, 0);
      }
      if (e.key.toLowerCase() === "c") {
        alert("Feature In Development 👩‍💻!");
      }
    };
    window.addEventListener("keydown", handleKeyPress);
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [
    email,
    generatedLink,
    handleCloseModal,
    whatsappNo,
    mobileNo,
    copyLinkToClipboard,
    openEmail,
    openSMS,
    sendPaymentLink,
  ]);

  useEffect(() => {
    if (isModalOpen && phoneInpRef.current) {
      phoneInpRef.current.focus();
    }
  }, [isModalOpen]);

  return (
    <div
      className={styles.share_link_modal}
      style={{ backgroundColor: mode && "#232529" }}
    >
      <div
        style={{ alignSelf: "flex-end", cursor: "pointer" }}
        onClick={handleCloseModal}
      >
        <CloseIcon mode={mode} />
      </div>
      <div className={styles.header_text}>
        <p style={{ color: mode && "#FFFFFF" }}>Share Payment Link</p>
        <p style={{ color: mode && "#FFFFFF" }}>
          Select a method to share the payment link.
        </p>
      </div>

      <div className={styles.copy_link}>
        <div className={styles.pay_link}>
          <input
            id="payment-link-input"
            style={{
              width: "100%",
              backgroundColor: mode && "#1B1D21",
              color: mode && "#FFFFFF",
              borderColor: mode && "#1B1D21",
            }}
            type="text"
            value={generatedLink}
            readOnly
          />
        </div>

        <div>
          <div
            className={styles.icon}
            style={{
              backgroundColor: mode ? "#1b1d21" : "#FFFFFF",
              borderRadius: "3px",
              cursor: "pointer",
            }}
            onClick={copyLinkToClipboard}
          >
            <CopyLinkIcon color={mode ? "#FFFFFF" : "#41416E"} />
          </div>
        </div>
      </div>

      <div className={styles.send_link}>
        <div>
          <input
            style={{
              backgroundColor: mode && "#1B1D21",
              color: mode && "#FFFFFF",
              borderColor: mode && "#1B1D21",
            }}
            value={"+91"}
            readOnly
          />
          <input
            style={{
              backgroundColor: mode && "#1B1D21",
              color: mode && "#FFFFFF",
              borderColor: mode && "#1B1D21",
            }}
            type="number"
            value={phoneNumber}
            onChange={(e) => {
              const value = e.target.value;
              if (/^\d{0,10}$/.test(value)) {
                setPhoneNumber(value);
              }
            }}
            maxLength={10}
            ref={phoneInpRef}
          />
        </div>

        <div>
          <Button
            buttonIcon={<NavbarWhatsAppIcon />}
            buttonText={"Send Link"}
            buttonBgColor={"#4164E3"}
            buttonTextColor={"#ffffff"}
            buttonBorder={"none"}
            buttonClick={() => {
              sendPaymentLink(phoneNumber);
            }}
          />
        </div>
      </div>

      <div className={styles.icons_tray}>
        <div>
          <div
            style={{ backgroundColor: mode && "#1b1d21" }}
            onClick={() => {
              setTimeout(() => {
                sendPaymentLink(whatsappNo && whatsappNo);
              }, 0);
            }}
          >
            <NavbarWhatsAppIcon />
          </div>
          <p style={{ color: mode && "#FFFFFF" }}>WhatsApp</p>
          <p className={styles.btn_text} style={{ color: mode && "#FFFFFF" }}>
            W
          </p>
        </div>
        <div>
          <div
            style={{ backgroundColor: mode && "#1b1d21" }}
            onClick={() => {
              openSMS(mobileNo, `Here is your payment link: ${generatedLink}`);
            }}
          >
            <PaymentMessageIcon color={mode ? "#FFFFFF" : "#292D32"} />
          </div>
          <p style={{ color: mode && "#FFFFFF" }}>SMS</p>
          <p className={styles.btn_text} style={{ color: mode && "#FFFFFF" }}>
            S
          </p>
        </div>
        <div>
          <div
            style={{ backgroundColor: mode && "#1b1d21" }}
            onClick={() => {
              openEmail(
                email,
                "Payment Link",
                `Here is your payment link: ${generatedLink}`
              );
            }}
          >
            <PaymentMailIcon color={mode ? "#1B1D21" : "#ffffff"} />
          </div>
          <p style={{ color: mode && "#FFFFFF" }}>Email</p>
          <p className={styles.btn_text} style={{ color: mode && "#FFFFFF" }}>
            E
          </p>
        </div>

        <div>
          <div style={{ backgroundColor: mode && "#1b1d21" }}>
            <PaymentChatIcon color={mode ? "#FFFFFF" : "#1B1D21"} />
          </div>
          <p style={{ color: mode && "#FFFFFF" }}>Chat</p>
          <p className={styles.btn_text} style={{ color: mode && "#FFFFFF" }}>
            C
          </p>
        </div>
      </div>
    </div>
  );
};

export default ShareLinkModal;

// eslint-disable-next-line no-lone-blocks
{
  /* <div className={styles.button_group}>
        <div>
          <Button
            buttonBgColor={"#000"}
            buttonText={"CANCEL"}
            buttonTextColor={"#fff"}
            buttonIcon={<CancelIcon2 />}
            buttonClick={handleCloseModal}
          />
        </div>
        <div>
          <Button
            buttonBgColor={"#4164E3"}
            buttonText={"SAVE"}
            buttonTextColor={"#fff"}
            buttonIcon={<SaveTaxRateIcon />}
          />
        </div>
      </div> */
}
