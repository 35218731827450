// import React, { createContext, useState } from "react";
// import { ToastProvider } from "../updatedComponents/Toaster/Toaster";
// const AuthContext = createContext({});

// const AuthProvider = (props) => {
//   const [switchDisplay, setSwitchDisplay] = useState(false);
//   const [marketingRefresh, setMarketingRefresh] = useState(false);
//   const [designation, setDesignation] = useState("");
//   const [managerId, setManagerId] = useState("");
//   const [managerName, setManagerName] = useState("");
//   const [loggedIn, setLoggedIn] = useState(false);
//   const [updateDelete, setUpdateDelete] = useState(0);
//   const [totalUnreadChat, setTotalUnreadChat] = useState(0);
//   const [mode, setMode] = useState(false);
//   const [ isLoading , setIsLoading]= useState(false);

//   const authContextValue = {
//     switchDisplay,
//     setSwitchDisplay,
//     marketingRefresh,
//     setMarketingRefresh,
//     designation,
//     setDesignation,
//     managerId,
//     setManagerId,
//     managerName,
//     setManagerName,
//     loggedIn,
//     setLoggedIn,
//     updateDelete,
//     setUpdateDelete,
//     totalUnreadChat,
//     setTotalUnreadChat,
//     mode,
//     setMode,
//     isLoading,
//     setIsLoading,
//   };
//   // return <AuthContext.Provider value={authContextValue} {...props} />;
//   return (
//     <ToastProvider>
//       <AuthContext.Provider value={authContextValue} {...props} />
//     </ToastProvider>
//   );
// };

// const useAuth = () => React.useContext(AuthContext);
// export { AuthProvider, useAuth };



// import React, { createContext, useState, useEffect } from "react";
// import { ToastProvider } from "../updatedComponents/Toaster/Toaster";

// const AuthContext = createContext({});

// const AuthProvider = (props) => {
//   const [switchDisplay, setSwitchDisplay] = useState(false);
//   const [marketingRefresh, setMarketingRefresh] = useState(false);
//   const [designation, setDesignation] = useState("");
//   const [managerId, setManagerId] = useState("");
//   const [managerName, setManagerName] = useState("");
//   const [loggedIn, setLoggedIn] = useState(false);
//   const [updateDelete, setUpdateDelete] = useState(0);
//   const [totalUnreadChat, setTotalUnreadChat] = useState(0);
//   const [isLoading, setIsLoading] = useState(false);
  
//   // Get initial mode from localStorage or default to light mode
//   const [mode, setMode] = useState(() => {
//     const savedMode = localStorage.getItem("mode");
//     return savedMode === "dark"; // returns true for dark mode, false for light mode
//   });

//   // Effect to update localStorage whenever mode changes
//   useEffect(() => {
//     localStorage.setItem("mode", mode ? "dark" : "light");
//   }, [mode]);

//   const authContextValue = {
//     switchDisplay,
//     setSwitchDisplay,
//     marketingRefresh,
//     setMarketingRefresh,
//     designation,
//     setDesignation,
//     managerId,
//     setManagerId,
//     managerName,
//     setManagerName,
//     loggedIn,
//     setLoggedIn,
//     updateDelete,
//     setUpdateDelete,
//     totalUnreadChat,
//     setTotalUnreadChat,
//     mode,
//     setMode,
//     isLoading,
//     setIsLoading,
//   };

//   return (
//     <ToastProvider>
//       <AuthContext.Provider value={authContextValue} {...props} />
//     </ToastProvider>
//   );
// };

// const useAuth = () => React.useContext(AuthContext);
// export { AuthProvider, useAuth };




/* ----------- by asmit--------------- */

// import React, { createContext, useState, useEffect } from "react";
// import { ToastProvider } from "../updatedComponents/Toaster/Toaster";

// // Enum definition
// export const THEME = Object.freeze({
//   SYSTEM: "SYSTEM",
//   LIGHT: "LIGHT",
//   DARK: "DARK",
// });

// const AuthContext = createContext({});

// const AuthProvider = (props) => {
//   const [switchDisplay, setSwitchDisplay] = useState(false);
//   const [marketingRefresh, setMarketingRefresh] = useState(false);
//   const [designation, setDesignation] = useState("");
//   const [managerId, setManagerId] = useState("");
//   const [managerName, setManagerName] = useState("");
//   const [loggedIn, setLoggedIn] = useState(false);
//   const [updateDelete, setUpdateDelete] = useState(0);
//   const [totalUnreadChat, setTotalUnreadChat] = useState(0);
//   const [isLoading, setIsLoading] = useState(false);
  
//   const [selectedTheme, setSelectedTheme] = useState(() => {
//     const savedTheme = localStorage.getItem("theme");
//     return savedTheme ? savedTheme : THEME.LIGHT; // Default to LIGHT if not set
//   });

//   // Get initial mode from localStorage or default to light mode
//   const [mode, setMode] = useState(() => {
//     const savedMode = localStorage.getItem("mode");
//     return savedMode === "dark"; // returns true for dark mode, false for light mode
//   });

//   // Effect to update localStorage whenever mode or theme changes
//   useEffect(() => {
//     localStorage.setItem("theme", selectedTheme);
//   }, [selectedTheme]);

//   // Effect to update localStorage whenever mode changes
//   useEffect(() => {
//     localStorage.setItem("mode", mode ? "dark" : "light");
//   }, [mode]);

//   // Function to handle theme change
//   const toggleTheme = (theme) => {
//     setSelectedTheme(theme);
//     if (theme === THEME.SYSTEM) {
//       const systemColorScheme = window.matchMedia('(prefers-color-scheme: dark)').matches ? THEME.DARK : THEME.LIGHT;
//       setMode(systemColorScheme === THEME.DARK); // Set mode based on system preference
//       // console.log("SystemTheme",systemColorScheme);
      

//     } else {
//       setMode(theme === THEME.DARK);
//     }
//   };

//   const authContextValue = {
//     switchDisplay,
//     setSwitchDisplay,
//     marketingRefresh,
//     setMarketingRefresh,
//     designation,
//     setDesignation,
//     managerId,
//     setManagerId,
//     managerName,
//     setManagerName,
//     loggedIn,
//     setLoggedIn,
//     updateDelete,
//     setUpdateDelete,
//     totalUnreadChat,
//     setTotalUnreadChat,
//     selectedTheme,
//     toggleTheme,
//     mode,
//     setMode,
//     isLoading,
//     setIsLoading,
//   };

//   return (
//     <ToastProvider>
//       <AuthContext.Provider value={authContextValue} {...props} />
//     </ToastProvider>
//   );
// };

// const useAuth = () => React.useContext(AuthContext);
// export { AuthProvider, useAuth };





/* --------------- by mohit---------------- */


import React, { createContext, useState, useEffect } from "react";
import { ToastProvider } from "../updatedComponents/Toaster/Toaster";

// Enum definition
export const THEME = Object.freeze({
  SYSTEM: "SYSTEM",
  LIGHT: "LIGHT",
  DARK: "DARK",
});

const AuthContext = createContext({});

const AuthProvider = (props) => {
  const [switchDisplay, setSwitchDisplay] = useState(false);
  const [marketingRefresh, setMarketingRefresh] = useState(false);
  const [designation, setDesignation] = useState("");
  const [managerId, setManagerId] = useState("");
  const [managerName, setManagerName] = useState("");
  const [loggedIn, setLoggedIn] = useState(false);
  const [updateDelete, setUpdateDelete] = useState(0);
  const [totalUnreadChat, setTotalUnreadChat] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const [selectedTheme, setSelectedTheme] = useState(() => {
    const savedTheme = localStorage.getItem("theme");
    return savedTheme || THEME.LIGHT; // Default to LIGHT if not set
  });

  // Get initial mode from localStorage or default to light mode
  const [mode, setMode] = useState(() => {
    const savedMode = localStorage.getItem("mode");
    return savedMode === "dark"; // returns true for dark mode, false for light mode
  });

  // Effect to update localStorage whenever mode or theme changes
  useEffect(() => {
    localStorage.setItem("theme", selectedTheme);
  }, [selectedTheme]);

  useEffect(() => {
    localStorage.setItem("mode", mode ? "dark" : "light");
  }, [mode]);

  // Effect to listen for system theme changes if the theme is set to SYSTEM
  useEffect(() => {
    const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");

    const handleSystemThemeChange = (e) => {
      if (selectedTheme === THEME.SYSTEM) {
        setMode(e.matches); // e.matches is true if system is dark mode, false if light mode
      }
    };

    if (selectedTheme === THEME.SYSTEM) {
      setMode(mediaQuery.matches); // Set initial mode based on system preference
      mediaQuery.addEventListener("change", handleSystemThemeChange);
    }

    return () => mediaQuery.removeEventListener("change", handleSystemThemeChange);
  }, [selectedTheme]);

  // Function to handle theme change
  const toggleTheme = (theme) => {
    setSelectedTheme(theme);
    if (theme === THEME.SYSTEM) {
      const systemColorScheme = window.matchMedia("(prefers-color-scheme: dark)").matches
        ? THEME.DARK
        : THEME.LIGHT;
      setMode(systemColorScheme === THEME.DARK);
    } else {
      setMode(theme === THEME.DARK);
    }
  };

  const authContextValue = {
    switchDisplay,
    setSwitchDisplay,
    marketingRefresh,
    setMarketingRefresh,
    designation,
    setDesignation,
    managerId,
    setManagerId,
    managerName,
    setManagerName,
    loggedIn,
    setLoggedIn,
    updateDelete,
    setUpdateDelete,
    totalUnreadChat,
    setTotalUnreadChat,
    selectedTheme,
    toggleTheme,
    mode,
    setMode,
    isLoading,
    setIsLoading,
  };

  return (
    <ToastProvider>
      <AuthContext.Provider value={authContextValue} {...props} />
    </ToastProvider>
  );
};

const useAuth = () => React.useContext(AuthContext);
export { AuthProvider, useAuth };
