


import React, { useEffect, useState } from "react";

// The main component
const TestLink = () => {
  const [url, setUrl] = useState("");
  const [metaData, setMetaData] = useState({
    title: "",
    description: "",
    favicon: "",
    thumbnail: "", // For YouTube thumbnail
  });
  const [error, setError] = useState("");
  const [translatedMetaData, setTranslatedMetaData] = useState({
    title: "",
    description: "",
  });

  // Function to fetch meta data
  const fetchMetaData = async () => {
    if (!url) {
      setError("Please enter a valid URL");
      return;
    }

    setError(""); // Clear previous errors

    if (isYouTubeLink(url)) {
      // Handle YouTube link
      const videoId = extractYouTubeVideoId(url);
      const thumbnailUrl = `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`;

      // Set YouTube metadata (custom handling for YouTube videos)
      setMetaData({
        title: "YouTube Video", // Could be fetched via YouTube API for a real title
        description: "YouTube video detected.",
        favicon: "https://www.youtube.com/favicon.ico", // Use YouTube's favicon
        thumbnail: thumbnailUrl,
      });
    } else {
      try {
        // Fetch metadata for non-YouTube URLs from the backend (Assuming a Node.js backend service)
        const response = await fetch(`http://localhost:5000/fetch-metadata?url=${encodeURIComponent(url)}`);
        const html = await response.text();

        // Parse the HTML using DOMParser
        const parser = new DOMParser();
        const doc = parser.parseFromString(html, "text/html");

        // Extract meta title
        const title = doc.querySelector("title") ? doc.querySelector("title").innerText : "No title found";

        // Extract meta description
        const description = doc.querySelector('meta[name="description"]')
          ? doc.querySelector('meta[name="description"]').getAttribute("content")
          : "No description found";

        // Extract favicon link
        const favicon = doc.querySelector('link[rel="icon"]')
          ? doc.querySelector('link[rel="icon"]').getAttribute("href")
          : doc.querySelector('link[rel="shortcut icon"]')
          ? doc.querySelector('link[rel="shortcut icon"]').getAttribute("href")
          : "No favicon found";

        // Update the state with extracted data
        setMetaData({
          title,
          description,
          favicon,
          thumbnail: "", // Clear thumbnail for non-YouTube URLs
        });

        // Optional: Translate the title and description
        const translatedTitle = await translateText(title); // Replace with your translation function
        const translatedDescription = await translateText(description); // Replace with your translation function

        setTranslatedMetaData({
          title: translatedTitle,
          description: translatedDescription,
        });
      } catch (err) {
        console.error("Error fetching the URL:", err);
        setError("Failed to fetch data. Make sure the URL is correct.");
      }
    }
  };

  // Dummy translation function - replace with actual translation API call
  const translateText = async (text) => {
    // Call your translation API here
    // For example, using fetch to call a translation service
    return text; // Return the translated text
  };

  // Check if the URL is a YouTube link
  const isYouTubeLink = (url) => {
    return url.includes("youtube.com") || url.includes("youtu.be");
  };

  // Extract YouTube video ID
  const extractYouTubeVideoId = (url) => {
    const regex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    const match = url.match(regex);
    return match ? match[1] : null;
  };


  useEffect(()=>{
fetchMetaData()
  },[url])

  return (
    <div style={{ padding: "20px", fontFamily: "Arial, sans-serif" }}>
      <h1>Link Meta Data Fetcher</h1>

      {/* Input box to enter URL */}
      <label htmlFor="urlInput">Enter URL:</label>
      <input
        type="text"
        id="urlInput"
        value={url}
        onChange={(e) => setUrl(e.target.value)}
        placeholder="https://www.example.com"
        style={{ width: "300px", padding: "10px", fontSize: "16px", marginRight: "10px" }}
      />
      {/* <button onClick={fetchMetaData} style={{ padding: "10px", fontSize: "16px", cursor: "pointer" }}>
        Get Meta Data
      </button> */}

      {/* Error message */}
      {error && <p style={{ color: "red", marginTop: "20px" }}>{error}</p>}

      {/* Display meta data results */}
      {metaData.title && (
        <div className="output" style={{ marginTop: "20px" }}>
          <h3>Meta Data for {url}</h3>
          <p><strong>Title:</strong> {metaData.title} <em>(Translated: {translatedMetaData.title})</em></p>
          <p><strong>Description:</strong> {metaData.description} <em>(Translated: {translatedMetaData.description})</em></p>
          <p><strong>Favicon:</strong> {metaData.favicon && <img src={metaData.favicon} alt="Favicon" style={{ maxWidth: "50px", height: "auto" }} />}</p>

          {/* Display YouTube thumbnail if available */}
          {metaData.thumbnail && (
            <div>
              <strong>YouTube Thumbnail:</strong>
              <img src={metaData.thumbnail} alt="YouTube Thumbnail" style={{ maxWidth: "200px", height: "auto", marginTop: "10px" }} />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default TestLink;

