import React, { useEffect, useState } from 'react'
import styles from './Camp.module.css'
import TopTabBar from '../../components/updatedComponents/Tanolet/TopTabBar'
import { useAuth } from '../../components/context/Auth'
import SearchBar from '../../components/updatedComponents/FormComponents/SearchBar'
import GoToPage from '../../components/updatedComponents/Pagnation/GoToPage'
import Pagenation from '../../components/updatedComponents/Pagnation/Pagenation'
import Button from '../../components/updatedComponents/Dealers/Button'
import { AddBtnicon, TemplateDone, TemplateNotFound } from '../../components/icons/Icon2'
import DrawerWrapper from '../../components/updatedComponents/layoutWrapper/DrawerWrapper'
import InputTag from '../../components/updatedComponents/FormComponents/InputTag'
import { Back, Pluse } from '../../components/icons/Icon3'
import { LocationsFilter } from '../../components/updatedComponents/Filter/LocationsFilter'
import { TextColorCode, truncateText } from '../../Utils/TextUtils'
import useWindowWidth from '../../components/updatedComponents/Hooks/WidthSize'
import TimeOutFetch from '../../APIsControll/TimeOutFechControll'
import NoDataFound from '../../components/updatedComponents/NoDataFound/NoDataFound'
import Skeleton from '../../components/updatedComponents/Skeleton/Skeleton'
import { useToast } from '../../components/updatedComponents/Toaster/Toaster'
import useDebounce from '../../Utils/Debounce'
const CreateGroup = () => {
    const { mode, managerId } = useAuth();
    const width = useWindowWidth()
    const [isOpen, setIsOpen] = useState(false);
    const [dealerSarch, setDealerSarch] = useState("")
    const { show } = useToast()
    const statusOption = [
        { label: "All" },
        { label: "New" },
        { label: "Rejected" },
        { label: "Deleted" },
        { label: "Verified" },
        { label: "Pending" },
        { label: "Update" },
        { label: "Blacklisted" },
        // { label: "Stockists" },
        // { label: "Not Active" },
        // { label: "Not Verified" },
        // { label: "Purchase" },
        // { label: "Not Purchase" }
    ]
    const toggleDrawer = () => {
        setIsOpen(true);
    };
    const closeDrawer = () => {
        setIsOpen(false);
    };

    const [loading, setloading] = useState({
        loading1: false,
        loading2: false,

    });
    const [sarchLocation, setSarchLocation] = useState({
        stateSearchLocation: "",
        DistricsSearchLocation: "",
        CitySearchLocation: "",
        pincodeSarch: ""
    });

    const [isOpenSelectSegment, setIsOpenSelectSegment] = useState(false);
    const [selectedOption, setSelectedOption] = useState("Select");
    const handleMouseEnter = () => setIsOpenSelectSegment(true)
    const handleMouseLeave = () => setIsOpenSelectSegment(false)

    const [showMenu, setShowMenu] = useState(false);
    const [activeSubMenu, setActiveSubMenu] = useState(null);
    const [activeSubMenu1, setActiveSubMenu1] = useState(null);

    const [state, settate] = useState([]);
    const [state1, settate1] = useState([]);
    const [state2, settate2] = useState([]);
    const [findGroupDealers, setfindGroupDealers] = useState([])
    const [page, setpage] = useState(1)
    const [pages, setpages] = useState(1)

    const [locations0, setlocations0] = useState([]);
    const [locations1, setlocations1] = useState([]);
    const [locations2, setlocations2] = useState([]);
    // const [initialRender, setInitialRender] = useState(true);
    // const [initialRender1, setInitialRender1] = useState(true);

    const [groupDealer, setGroupDealer] = useState([])


    const [groupDetails, setGroupDetails] = useState({
        name: "",
        description: ""
    });


    // get location filter 
    const getLoactionFilter = async () => {
        const payload = {
            managerId: managerId,
            stateSearch: sarchLocation.stateSearchLocation,
            districtSearch: sarchLocation.DistricsSearchLocation,
            citySearch: sarchLocation.CitySearchLocation,
            pincodeSearch: sarchLocation.pincodeSarch,
            locations: {
                states: state.length > 0 ? state : [],
                districts: state1.length > 0 ? state1 : [],
                cities: state2.length > 0 ? state2 : [],
                pincodes: sarchLocation.pincodeSarch ? [sarchLocation.pincodeSarch] : []
            }
        };
        try {
            const res = await TimeOutFetch(`wallikonCampaign/filterLocation`, payload);
            // setlocations0(prev => [...prev, ...res?.data?.states]);
            setlocations0(res?.data?.states);
            setlocations1(res?.data?.districts);
            setlocations2(res?.data?.cities);
        } catch (error) {
            console.log(error);
        }
    };
        const getLoactionFilterRset = async (click) => {
        const payload = {
            managerId: managerId,
            stateSearch: sarchLocation.stateSearchLocation,
            districtSearch: sarchLocation.DistricsSearchLocation,
            citySearch: sarchLocation.CitySearchLocation,
            pincodeSearch: sarchLocation.pincodeSarch,
            locations: {
                states: click === "State" ? [] : state,
                districts: click === "District" ? [] : state1,
                cities: click === "City" ? [] : state2,
                pincodes: sarchLocation.pincodeSarch ? [sarchLocation.pincodeSarch] : []
            }
        };
        try {
            const res = await TimeOutFetch(`wallikonCampaign/filterLocation`, payload);
            // setlocations0(prev => [...prev, ...res?.data?.states]);
            getDealserreset(click)
            setlocations0(res?.data?.states);
            setlocations1(res?.data?.districts);
            setlocations2(res?.data?.cities);
        } catch (error) {
            console.log(error);
        }
    };

    const getDealser = async () => {
        try {
            setloading(prve => ({
                ...prve, loading1: true
            }))
            const payload = {
                managerId: managerId,
                keyword: dealerSarch,
                status: selectedOption === "All" || selectedOption === "Select" ? "" : selectedOption,
                locations: {
                    states: state.length > 0 ? state : [],
                    districts: state1.length > 0 ? state1 : [],
                    cities: state2.length > 0 ? state2 : [],
                    pincodes: []
                }
            };
            const res = await TimeOutFetch(`wallikonCampaign/findGroupDealers?page=${page}&limit=${28}`, payload);
            setloading(prve => ({
                ...prve, loading1: false
            }))
            setfindGroupDealers(res?.data);
            setpage(res?.page);
            setpages(res?.pages);

        } catch (error) {
            console.log(error);
            setloading(prve => ({
                ...prve, loading1: false
            }))
        }
    }
    const getDealserreset = async (click) => {
        try {
            setloading(prve => ({
                ...prve, loading1: true
            }))
            const payload = {
                managerId: managerId,
                keyword: dealerSarch,
                status: selectedOption === "All" || selectedOption === "Select" ? "" : selectedOption,
                locations: {
                    states: click === "State" ? [] : state,
                    districts: click === "District" ? [] : state1,
                    cities: click === "City" ? [] : state2,
                    pincodes: []
                }
            };
            const res = await TimeOutFetch(`wallikonCampaign/findGroupDealers?page=${page}&limit=${28}`, payload);
            setloading(prve => ({
                ...prve, loading1: false
            }))
            setfindGroupDealers(res?.data);
            setpage(res?.page);
            setpages(res?.pages);

        } catch (error) {
            console.log(error);
            setloading(prve => ({
                ...prve, loading1: false
            }))
        }
    }

    const CreateGroup = async (e) => {
        e.preventDefault()
        try {
            if (groupDealer.length <= 0) return show("Please select dealers", "error");
            const payload = {
                managerId: managerId,
                groupName: groupDetails.name,
                description: groupDetails.description,
                dealerInfo: groupDealer
            }
            setloading(prve => ({
                ...prve, loading2: true
            }))
            const res = await TimeOutFetch(`wallikonCampaign/createGroup`, payload);
            setloading(prve => ({
                ...prve, loading2: false
            }))
            if (res.status === "success") {
                closeDrawer()
                setGroupDetails({ name: "", description: "" })
                show(`${res.msg}`, "success");
                return
            }
            if (res.status === "failed") {
                return show(`${res.msg}`, "error")
            } else {
                return show("Something went wrong", "error");

            };
        } catch (error) {
            setloading(prve => ({
                ...prve, loading2: false
            }))
            return show("Something went wrong", "error");
        }
    }

    //  filter and chack 
    const handelChack = (event, item) => {
        const { _id, updatedAt, createdAt, ...cleanedItem } = item;
        if (event.target.checked === true) {
            setGroupDealer(prevGroupDealer => {
                const haveExect = prevGroupDealer?.some(dealers => dealers?.dealerId === cleanedItem?.dealerId);
                if (!haveExect) {
                    return [...prevGroupDealer, cleanedItem]
                }
                return prevGroupDealer;
            })
        } else {
            setGroupDealer(prevGroupDealer => {
                return prevGroupDealer?.filter(dealers => dealers?.dealerId !== cleanedItem?.dealerId);
            })
        }

    }



    useDebounce(getDealser, 700, [page, selectedOption, dealerSarch]);

    useEffect(() => {
        getLoactionFilter();
    }, [sarchLocation ]);

  

    return (
        <div className={styles.mainCintner}>
            <div className={styles.Continer} style={{ backgroundColor: mode ? "#2C2E33" : "#F8F9FC" }}>
                <DrawerWrapper isOpen={isOpen} onClose={closeDrawer} >
                    <div className={mode ? styles.DrawerWrapperContinetr : styles.DrawerWrapperContinetr_light} >
                        <div className={styles.DrawerWrapperText} >
                            <span>Selected: {groupDealer.length}</span>
                        </div>
                        <form onSubmit={CreateGroup} >

                            <div className={styles.DrawerWrapperMainCointner} >
                                <div className={styles.DrawerWrapperInputConintner1} >
                                    <div className={styles.inputWidth} >
                                        <InputTag
                                            placeholderText={"New Group..."}
                                            labelText={"Group Name"}
                                            placeholderType={"text"}
                                            onChange={(e) => setGroupDetails(prv => ({
                                                ...prv, name: e.target.value
                                            }))}
                                            maxLength={50}
                                            required={true}
                                        />
                                    </div>
                                    <div className={styles.inputWidth} >
                                        <InputTag
                                            placeholderText={"Lucknow Trader..."}
                                            labelText={"Description"}
                                            placeholderType={"text"}
                                            onChange={(e) => setGroupDetails(prv => ({
                                                ...prv, description: e.target.value
                                            }))}
                                            maxLength={100}
                                            required={true}
                                        />
                                    </div>
                                </div>
                                <div className={styles.DrawerWrapperInputConintner} >
                                    <div className={styles.btnwidth} >
                                        <Button
                                            buttonBgColor={"#000000"}
                                            buttonIcon={<Back />}
                                            buttonTextColor={"#fff"}
                                            buttonText={"Back"}
                                            buttonClick={closeDrawer}
                                        />
                                    </div>
                                    <div className={styles.btnwidth} >
                                        <Button
                                            buttonBgColor={"#4164E3"}
                                            buttonIcon={<TemplateDone />}
                                            buttonTextColor={"#fff"}
                                            buttonText={"Create"}
                                            // buttonClick={CreateGroup}
                                            loading={loading.loading2}
                                        />
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </DrawerWrapper>

                <div className={styles.goBackCon}  >
                    <div className={styles.gobacFristChlid1} >
                        <div style={{ width: "100%" }}>
                            <TopTabBar text={"New Campaigns"} />

                        </div>
                        <div style={{ maxWidth: "170px", height: "100%", display: "flex", justifyContent: "center" }} >
                            <Button
                                buttonBgColor={"#4164E3"}
                                buttonText={width > 700 ? "Create Group" : null}
                                // buttonIcon={<CreateTem />}
                                buttonIcon={<Pluse />}
                                buttonTextColor={"#fff"}
                                buttonClick={toggleDrawer}
                            />
                        </div>
                    </div>
                </div>
                <div className={styles.sarchBarConinnter}>
                    <div className={styles.sarchconGrop}>
                        <div className={styles.sacrchGroup} >
                            <SearchBar placeholder={"Search"} onChange={(e) => setDealerSarch(e.target.value)} />
                        </div>
                        <div className={styles.cont} style={{ color: mode ? "white" : "black" }} >
                            <div>
                                <span style={{ fontSize: "12px", fontWeight: "500", color: mode ? "#fff" : "#000" }} > Status </span>
                            </div>
                            <div className={styles.Who_Select3} style={{
                                background: mode ? "#1B1D21" : "#fff",
                                border: mode ? "none" : "1px solid #E4E7EB",
                            }}
                                // onMouseEnter={handleMouseEnter}
                                // onMouseLeave={handleMouseLeave}
                                onClick={ ()=>setIsOpenSelectSegment(!isOpenSelectSegment) }
                            >
                                <div>
                                    <span style={{ fontSize: "12px" }}>{selectedOption}</span>
                                </div>
                                <div>
                                    <span>
                                        <AddBtnicon mode={mode} rotate={isOpenSelectSegment ? 180 : 0} />
                                    </span>
                                </div>
                                {isOpenSelectSegment && (
                                    <div className={styles.Who_selectBox} style={{
                                        backgroundColor: mode ? "#000" : "#fff",
                                        border: mode ? "none" : "1px solid #E4E7EB",
                                        zIndex: "9999",
                                        left: width > 701 ? "100%" : "-30%",
                                        top: width > 701 ? "0%" : "100%"
                                    }} >
                                        {statusOption.map((e, i) => (
                                            <div className={mode ? styles.Who_selectBoxhover_dark : styles.Who_selectBoxhover} key={i}
                                                onClick={() => {
                                                    setSelectedOption(e?.label);
                                                    setIsOpenSelectSegment(false);
                                                }} >
                                                {e?.label}
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        </div>
                        {
                            width > 700 ? <>
                                <div className={styles.iconWrapper0}
                                    // onMouseEnter={() => setShowMenu(true)}
                                    // onMouseLeave={() => {
                                    //     setShowMenu(false);
                                    // }}
                                    onClick={()=>setShowMenu(!showMenu)}
                                    style={{ color: mode ? "white" : "black" }}
                                >
                                    <div>
                                        <span style={{ fontSize: "12px", fontWeight: "500", color: mode ? "#fff" : "#000" }} > Location </span>
                                    </div>
                                    <div className={styles.Who_Select3} style={{
                                        background: mode ? "#1B1D21" : "#fff",
                                        border: mode ? "none" : "1px solid #E4E7EB",
                                    }}
                                    >
                                        <div>
                                            <span style={{ fontSize: "14px" }}> select</span>
                                        </div>
                                        <div>
                                            <span>
                                                <AddBtnicon mode={mode} rotate={showMenu ? 180 : 0} />
                                            </span>
                                        </div>

                                    </div>
                                    {
                                        showMenu &&
                                        <div className={styles.mainMenu0}
                                            style={{ backgroundColor: mode ? "#000" : "#fff", color: mode ? "#fff" : "#000", left: "100%" }}
                                        >
                                            <div className={mode ? styles.menuItem_dark0 : styles.menuItem0}
                                                onMouseEnter={() => setActiveSubMenu('category')}
                                                onMouseLeave={() => setActiveSubMenu(null)}
                                            >
                                                <LocationsFilter
                                                    title={"State"}
                                                    locations={locations0}
                                                    /* loading={true}  */
                                                    mode={true}
                                                    setSelectedLocations={settate}
                                                    selectedLocations={state}
                                                    sareshOnchange={(value) => setSarchLocation({ stateSearchLocation: value })}
                                                    sarchValue={sarchLocation.stateSearchLocation}
                                                    ApplyOnclick={() => {
                                                        getLoactionFilter();
                                                        getDealser();
                                                    }}
                                                    ResetOnclick={() => {
                                                        settate([]); // Clear selected locations
                                                        getLoactionFilterRset("State")
                                                    }}

                                                />
                                                {activeSubMenu === 'category' && (
                                                    <div className={styles.mainMenu0}
                                                        style={{ backgroundColor: mode ? "#000" : "#fff", color: mode ? "#fff" : "#000", left: "100%" }}
                                                    >
                                                        <div className={mode ? styles.menuItem_dark0 : styles.menuItem0}
                                                            onMouseEnter={() => setActiveSubMenu1("toggle")}
                                                            onMouseLeave={() => setActiveSubMenu1(null)}
                                                        >
                                                            <LocationsFilter
                                                                title={"District"}
                                                                locations={locations1}
                                                                setSelectedLocations={settate1}
                                                                /* loading={true}  */
                                                                selectedLocations={state1}
                                                                sareshOnchange={(value) => setSarchLocation({ DistricsSearchLocation: value })}
                                                                sarchValue={sarchLocation.DistricsSearchLocation}
                                                                ApplyOnclick={() => {
                                                                    getLoactionFilter();
                                                                    getDealser();
                                                                }}
                                                                ResetOnclick={() => {
                                                                    settate1([]);
                                                                    getLoactionFilterRset("District")

                                                                }}
                                                            />
                                                            {
                                                                activeSubMenu1 === "toggle" && (
                                                                    <div className={styles.mainMenu0}
                                                                        style={{ backgroundColor: mode ? "#000" : "#fff", color: mode ? "#fff" : "#000", left: "100%" }}
                                                                    >
                                                                        <div className={mode ? styles.menuItem_dark0 : styles.menuItem0} >
                                                                            <LocationsFilter
                                                                                title={"City"}
                                                                                locations={locations2}
                                                                                setSelectedLocations={settate2}
                                                                                selectedLocations={state2}
                                                                                sareshOnchange={(value) => setSarchLocation({ CitySearchLocation: value })}
                                                                                sarchValue={sarchLocation.CitySearchLocation}
                                                                                ApplyOnclick={() => {
                                                                                    getLoactionFilter();
                                                                                    getDealser();
                                                                                }}
                                                                                ResetOnclick={() => {
                                                                                    settate2([]);
                                                                                    getLoactionFilterRset("City")
                                                                                }}
                                                                            />

                                                                        </div>
                                                                    </div>
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    }
                                </div>
                                <div className={styles.cont} style={{ color: mode ? "white" : "black" }} >
                                    <div>
                                        <span style={{ fontSize: "12px", fontWeight: "500", color: mode ? "#fff" : "#000" }} > Pin  </span>
                                    </div>
                                    <div style={{ fontSize: "12px", fontWeight: "400" }}>
                                        <SearchBar
                                            placeholder={"Sarch"}
                                            type={"number"}
                                            maxLength={6}
                                            onChange={(event) => setSarchLocation((prv) => ({
                                                ...prv, pincodeSarch: event.target.value
                                            }))}
                                        />
                                    </div>
                                </div>
                            </> : null
                        }
                    </div>
                </div>

                <div className={styles.tableContiner}>
                    <div className={styles.container} style={{ height: "100%" }}>
                        <ul className={styles.responsiveTable} style={{ height: "100%" }}>
                            <li className={styles.tableHeader} style={mode ? { backgroundColor: "#232529", color: "#fafafa" }
                                : { backgroundColor: "#D9DCE5" }} >
                                <div className={`${styles.col} `} >
                                    <div className={styles.TableHeadChackBox} >
                                        {/* <label className={styles.cl_checkbox} >
                                            <input type="checkbox" />
                                            <span></span>
                                        </label> */}
                                    </div>
                                    Dealer Name </div>
                                <div className={`${styles.col} ${styles.large}`} > <span>Address </span>  </div>
                                <div className={styles.col} >State</div>
                                <div className={styles.col} >District</div>
                                <div className={styles.col} >City</div>
                                <div className={styles.col} >PIN Code</div>
                                <div className={styles.col} >Status</div>
                            </li>
                            {
                                findGroupDealers.length === 0 ? <> {loading.loading1 ? <Skeleton lines={8} width="100%" thickness={8} height='400px' /> : <>
                                    <div style={{ height: "90%" }}>
                                        <NoDataFound
                                            icon={<TemplateNotFound />}
                                            linkname={"add a template."}
                                            textTitle={`No Dealers found. To run a WhatsApp campaign`}
                                            to={"/newgroup"} /> </div>
                                </>}  </> :

                                    findGroupDealers.map((e, i) =>
                                        <li className={styles.tablerow} style={mode ? { backgroundColor: "#1B1D21", color: "#fafafa" } : { backgroundColor: "#ffff" }} key={i} >
                                            <div className={`${styles.col} ${styles.coljustyfy}   `} data-label="Dealer Name" >
                                                <div className={styles.TableHeadChackBox} >
                                                    <label className={styles.cl_checkbox} >
                                                        <input type="checkbox" onClick={(event) => handelChack(event, e)} />
                                                        <span></span>
                                                    </label>
                                                </div>
                                                <div>
                                                    {e?.dealerName}
                                                </div>
                                            </div>
                                            <div className={`${styles.col} ${styles.large}`} data-label="Address" > {truncateText(`${e?.address}`, 27)}  </div>
                                            <div className={styles.col} data-label="State" > {e?.state} </div>
                                            <div className={styles.col} data-label="District" > {e?.district} </div>
                                            <div className={styles.col} data-label="City" > {e?.city} </div>
                                            <div className={styles.col} data-label="PIN Code" > {e?.pincode} </div>
                                            <div className={styles.col} data-label="Status" style={{ color: TextColorCode(e?.status) }}>
                                                {e?.status}
                                            </div>
                                        </li>
                                    )
                            }
                            <li style={{ height: "20px" }}></li>
                        </ul>
                    </div>
                </div>
                <div>
                </div>

                <div className={styles.pagnationConintner} style={{ backgroundColor: mode ? "#232529" : "#fff" }}>
                    <div className={styles.gotToPage1} >
                        <GoToPage currentPage={page} setCurrentPage={setpage} totalPages={pages} key={"1"} />
                    </div>
                    <div className={styles.gotToPage2}  >
                        <Pagenation currentPage={page} setCurrentPage={setpage} totalPages={pages} key={"1"} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CreateGroup


