import React, { useEffect, useState } from 'react'
import styles from "./Invoices.module.css"
import { useSearchParams } from 'react-router-dom';
import { useAuth } from '../../components/context/Auth';

const UploadInvoice = () => {

    // const navigate = useNavigate();
    const { managerId } = useAuth()
    const [searchparams] = useSearchParams()
    const orderId = searchparams.get("orderId")
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (orderId && managerId) {
            setLoading(true)
            var myHeaders = new Headers();
            myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                "managerId": managerId,
                "orderId": orderId
            });

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            fetch(process.env.REACT_APP_URL + "invoice/getInvoicePdf", requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.status === 'success') {
                        setData(result.data)
                        setLoading(false)
                    }
                })
                .catch(error => console.log('error', error));
        }

    }, [orderId, managerId])

    return (
        <React.Fragment>
            <div className={styles.right_main}>
                <div className={styles.dispatchDashboard_header}>
                    <h1 className={`${styles.dispatchHeading} mt-0`}>Uploaded Invoices</h1>
                </div>
                {
                    loading ? <div className={'order_loadingMain'}>
                        <img src='/wallicon.gif' alt='walliconGIF' />
                    </div> :
                        <>
                            {data.length !== 0 ?
                                <div className={styles.dispatchOrders_height}>
                                    <table className={styles.itemTable}>
                                        <thead>
                                            <tr className={styles.item}>
                                                <th>File</th>
                                                <th>Date</th>
                                                <th>Order Id</th>
                                                <th>Amount</th>
                                                <th>Status</th>
                                                <th>Last Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data && data.map((val, index) =>
                                                <tr key={index}
                                                    className={`${styles.item} ${val.reqStatus === "Pending" ? 'text-danger' : ""}`}
                                                    onClick={() => window.open(val.invoice && process.env.REACT_APP_S3URL + val.invoice, "mozillaWindow", "popup")}
                                                    title={val.reqStatus === "Pending" ? val.reqIssue : ""}
                                                >
                                                    <td>{val.invoice} </td>
                                                    <td>{val.invoiceDate} </td>
                                                    <td>{val.orderId} </td>
                                                    <td>{val.amount}</td>
                                                    <td className={`${val.status === 'Dispatched' || val.status === 'Packed'||val.status === 'Uploaded' ?
                                                        styles.table_txt_green : val.status === 'Payment Pending'|| val.status === 'Canceled' ?
                                                            styles.table_txt_red : (val.status === 'In Progress' || val.status === 'Pending'|| val.status === 'Payment Pending') ?
                                                                styles.table_txt_yellow : val.status === 'New Order' ?
                                                                    styles.table_txt_blue : ''}`}>
                                                        {val.status}
                                                    </td>
                                                    <td>{val.lastAction}</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                                :
                                <div className={styles.noData}>
                                    No Data Found !
                                </div>
                            }
                        </>
                }
            </div>
        </React.Fragment>
    )
}

export default UploadInvoice