import React, { useEffect, useRef, useState } from 'react';
import styles from './Camp.module.css';
import TopTabBar from '../../components/updatedComponents/Tanolet/TopTabBar';
import { useAuth } from '../../components/context/Auth';
import Button from '../../components/updatedComponents/Dealers/Button';
import { Draft, } from '../../components/icons/Icon3';
import Progress from '../../components/updatedComponents/progress/Progress';
import InputTag from '../../components/updatedComponents/FormComponents/InputTag';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '../../components/updatedComponents/progress/Accordion';
import { AddBtnicon, TemplateDone, TemplateNotFound, } from '../../components/icons/Icon2';
import InputTypedate from '../../components/updatedComponents/FormComponents/InputTypedate';
import { useNavigate } from 'react-router-dom';
import InputRedio from '../../components/updatedComponents/FormComponents/InputRedio';
import TimeOutFetch from '../../APIsControll/TimeOutFechControll';
import { useToast } from '../../components/updatedComponents/Toaster/Toaster';
import { changeDateFormat, TextColorCode, truncateText } from '../../Utils/TextUtils';
import fetchData from '../../APIsControll/apiControll';
import InputTypetime from '../../components/updatedComponents/FormComponents/InputTypetime';
import ModalWrapper from '../../components/updatedComponents/layoutWrapper/ModalWrapper';
import { CrossRedIcon, DoubleCheckIcon, RemoveIcon, Spinner } from '../../components/icons/Icon';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../../components/updatedComponents/TabSwitcher/TabSwitcher';
import SearchBar from '../../components/updatedComponents/FormComponents/SearchBar';
import { LocationsFilter } from '../../components/updatedComponents/Filter/LocationsFilter';
import SrarchPin from '../../components/updatedComponents/FormComponents/SrarchPin';
import Skeleton from '../../components/updatedComponents/Skeleton/Skeleton';
import NoDataFound from '../../components/updatedComponents/NoDataFound/NoDataFound';
const CreateCampaign = () => {
    const { mode, managerId } = useAuth();
    const { show } = useToast();
    const Navigator = useNavigate();
    const [page, setPage] = useState(1);
    const [pages, setPages] = useState(1);
    const [TempPage, setTempPage] = useState(1);
    const [TempPages, setTempPages] = useState(1);
    const boxRef1 = useRef(null);
    const boxRef2 = useRef(null);
    const boxRef3 = useRef(null);
    const boxRef4 = useRef(null);
    const templetRef = useRef(null);
    const [pageUser, setPageUser] = useState(1);
    const [pagesUser, setPagesUser] = useState(1);
    const [pageSelected, setPageSelected] = useState(1);
    const [pagesSelected, setPagesSelected] = useState(1);
    const [groupData, setGroupDsta] = useState([]);
    const [singleUserSelect, setSingleUserSelect] = useState([]);
    const [singleUnSelect, setSingleUnSelect] = useState([]);
    const [singleGroupSelect, setSingleGroupSelect] = useState([]);
    const [activeTab, setActiveTab] = useState("Users");
    const [selectData, setSelectData] = useState([]);
    const [selectAllUser, setsSlectAllUser] = useState(false);
    const [isOpenSelectSegment1, setIsOpenSelectSegment1] = useState(false);
    const [dealerSarch, setDealerSarch] = useState("");
    const [showMenu, setShowMenu] = useState(false);
    const containerRef = useRef(null);
    const containerRef1 = useRef(null);
    const containerRef3 = useRef(null);
    const boxRef = useRef(null);
    const [loading, setloading] = useState({
        loading1: false,
        loading2: false,
        loading3: false,
        loading4: false,
        loading5: false,
        loading6: false,
        loading7: false,
        loading8: false,
        loading9: false,
        loading10: false,
    });
    const [tempData, settempData] = useState([]);
    const [inputBox, setInputbox] = useState({
        campaignName: "",
        label: "",
    });
    const segment = useRef();
    const hanveDone = useRef();
    const messageType = useRef();
    const [InstantSendModel, setInstantSendModel] = useState(false);
    const [progress, setProgress] = useState(0)
    const [InstantSendData, setInstantSendData] = useState([]);
    const [InstantSendTemplet, setInstantSendTemplet] = useState([]);
    const today = new Date().toISOString().split('T')[0];
    const [whenRedio, setWhenRedio] = useState("");
    const [templetData, setempletData] = useState()
    const [isOpenSelectSegment, setIsOpenSelectSegment] = useState(false);
    const [isOpenSelectSegment2, setIsOpenSelectSegment2] = useState(false);
    const [selectedOption, setSelectedOption] = useState("Select");
    const [selectedOption2, setSelectedOption2] = useState("Select");
    const [isOpenSelectWhohave, setIsOpenSelectWhohave] = useState(false);
    const [selectedOptionWhohave, setSelectedOptionWhohave] = useState("Select");
    const [isOpenSelectWhat, setIsOpenSelectWhat] = useState(false);
    const [selectedOptionWhat, setSelectedOptionWhat] = useState("Select");
    const [findGroupDealers, setfindGroupDealers] = useState([]);

    const [locations0, setlocations0] = useState([]);
    const [sarchValue, setSarchValue] = useState('');
    const [filteredLocations, setFilteredLocations] = useState(locations0);
    const [selectedLocations, setSelectedLocations] = useState([]);

    const [locations1, setlocations1] = useState([]);
    const [sarchValue1, setSarchValue1] = useState('');
    const [filteredLocations1, setFilteredLocations1] = useState(locations1);
    const [selectedLocations1, setSelectedLocations1] = useState([]);

    const [locations2, setlocations2] = useState([]);
    const [sarchValue2, setSarchValue2] = useState('');
    const [filteredLocations2, setFilteredLocations2] = useState(locations2);
    const [selectedLocations2, setSelectedLocations2] = useState([]);
    const getCurrentTime = () => {
        const now = new Date();
        const hours = now.getHours().toString().padStart(2, "0");
        const minutes = now.getMinutes().toString().padStart(2, "0");
        return `${hours}:${minutes}`;
    };
    const getCurrentDate = () => {
        const now = new Date();
        const year = now.getFullYear();
        const month = (now.getMonth() + 1).toString().padStart(2, "0"); // Months are 0-indexed, so add 1
        const day = now.getDate().toString().padStart(2, "0");
        return `${year}-${month}-${day}`;
    };

    const [tempSelectModel, setTempSelectModel] = useState(false);
    const [showMenu1, setShowMenu1] = useState(false);
    const [showMenu2, setShowMenu2] = useState(false);
    const statusOption = [{ label: "All" }, { label: "New" }, { label: "Rejected" }, { label: "Deleted" }, { label: "Verified" }, { label: "Pending" }, { label: "Update" }, { label: "Blacklisted" }]
    const [sarchLocation, setSarchLocation] = useState({
        stateSearchLocation: "",
        DistricsSearchLocation: "",
        CitySearchLocation: "",
        pincodeSarch: ""
    });

    const [dateTimeInputs, setDateTimeInputs] = useState([{ date: getCurrentDate(), time: getCurrentTime(), error: false }]);
    // set date and time for send data 
    const addDateTimeInput = () => {
        setDateTimeInputs([...dateTimeInputs, { date: getCurrentDate(), time: getCurrentTime(), error: false }]);
    };
    const handleRemoveInput = (index) => {
        const updatedInputs = dateTimeInputs.filter((_, i) => i !== index);
        setDateTimeInputs(updatedInputs);
    };


    useEffect(() => {
        const allUnselect = async () => {
            try {
                const payload = {
                    managerId: managerId,
                    keyword: "",
                    status: "",
                    locations: { states: [], districts: [], cities: [], pincodes: [] }
                }
                setloading(prve => ({
                    ...prve, loading10: true
                }));
                const res = await TimeOutFetch(`wallikonCampaign/allUnselectedUpdates`, payload);
                if (res.status === 'success') {
                    getAllDealersList1();
                    getAllSelectDealers();

                    setloading(prve => ({
                        ...prve, loading10: false
                    }));
                }
                if (res.status === 'failed') {
                    getAllDealersList1();
                    getAllSelectDealers()
                    setloading(prve => ({
                        ...prve, loading10: false
                    }));
                }

            } catch (error) {
                console.log(error);
            }
        }
        allUnselect();
    }, [])


    const handleDateChange = (index, event) => {
        const newDateTimeInputs = [...dateTimeInputs];
        newDateTimeInputs[index].date = event.target.value;
        newDateTimeInputs[index].error = false;  // Reset error if any field is filled
        setDateTimeInputs(newDateTimeInputs);
    };

    const handleTimeChange = (index, event) => {
        const newDateTimeInputs = [...dateTimeInputs];
        newDateTimeInputs[index].time = event.target.value;
        newDateTimeInputs[index].error = false;  // Reset error if any field is filled
        setDateTimeInputs(newDateTimeInputs);
    };

    // get all groups Api
    const getGroups = async () => {
        setloading(prve => ({
            ...prve, loading1: true
        }))
        try {
            const payload = {
                managerId: managerId,
                keyword: ""
            }
            const res = await TimeOutFetch(`wallikonCampaign/groupList?page=${page}&limit=20`, payload);
            setloading(prve => ({
                ...prve, loading1: false
            }));
            if (res.status === "success") {
                setGroupDsta(prevDealers => {
                    const combinedData = [...prevDealers, ...res.data];
                    const uniqueData = combinedData.filter(
                        (item, index, self) => self.findIndex((t) => t._id === item._id) === index
                    );
                    return uniqueData;
                })
                setPages(res?.pages)
                return
            };
            if (res.status === "failed") {
                setGroupDsta(res?.data);
                setPages(1)
                setPage(1)
                if (res.msg === "Group list not found!") {
                    return
                } else {
                    show(`${res.msg}`, "error")
                }
            } else {
                return show("Something went wrong", "error");

            };

        } catch (error) {
            return show("Something went wrong", "error");
        }
    };

    // get all templets Api
    const getAlltemplate = async () => {
        setloading(prve => ({
            ...prve, loading2: true
        }));
        const response = await fetchData(`wallikonChat/templateList?page=${TempPage}&limit=${20}`, {
            "managerId": managerId,
            "keyword": '',
            "startDate": '',
            "endDate": '',
            "category": '',
            "headerType": '',
            "templateSent": '',
        });
        if (response.status === 'success') {
            // settempData(response?.data)
            settempData((prevTempData) => [
                ...prevTempData,
                ...response.data
            ]);
            setloading(prve => ({
                ...prve, loading2: false
            }));
            setTempPages(response.pages);
            return
        }
        if (response.status === 'failed') {
            setloading(prve => ({
                ...prve, loading2: false
            }));
            return
        }
        else {
            show("Error", "error");
            setloading(prve => ({
                ...prve, loading2: false
            }));
            return
        }
    };

    // Only create dreft and navigate 
    const CreateCampaign = async (selectedDealers) => {
        try {
            if (inputBox.campaignName === "") {
                show("Campaign Name is requird", "error");
                setloading(prve => ({
                    ...prve, loading3: false
                }));
                return
            }

            const payload = {
                managerId: managerId,
                campaignName: inputBox.campaignName,
                label: inputBox.label,
                groupName: singleGroupSelect,
                templateName: selectedOptionWhat === "Select" ? "" : selectedOptionWhat,
                dealersData: selectedDealers,
                ...(whenRedio === "Recurring" && dateTimeInputs.length > 0
                    ? { schedules: dateTimeInputs.map((input) => ({ date: input.date, time: input.time })) } // Add key for "Recurring" if dateTimeInputs is not empty
                    : {})
            };

            const res = await TimeOutFetch(`wallikonCampaign/createCampaign`, payload);
            if (res.status === 'success') {
                show(`${res.msg}`, "success");
                setloading(prve => ({
                    ...prve, loading3: false
                }));
                allUnselectedUpdates();
                Navigator("/campaign");
                return
            }
            if (res.status === 'failed') {
                setloading(prve => ({
                    ...prve, loading3: false
                }));
                show(`${res.msg}`, "error");
                return
            }
            else {
                show("Something went wrong", "error");
                setloading(prve => ({
                    ...prve, loading3: false
                }));
                return
            }
        } catch (error) {
            show("Something went wrong", "error");
            setloading(prve => ({
                ...prve, loading3: false
            }))
        }
    };

    // get  all Group Dealers 
    useEffect(() => {
        getGroups();
    }, [page]);

    // get all templets   
    useEffect(() => {
        getAlltemplate();
    }, [TempPage])


    // select all group 
    const handelChack = async (event, groupName) => {
        try {
            setloading(prve => ({
                ...prve, loading8: true
            }));

            let updatedGroupSelect = [];
            if (event.target.checked) {
                updatedGroupSelect = [...singleGroupSelect, groupName];
            } else {
                updatedGroupSelect = singleGroupSelect.filter((name) => name !== groupName);
            }

            // Update the state
            setSingleGroupSelect(updatedGroupSelect);

            const payload = {
                managerId: managerId,
                groupNames: updatedGroupSelect,
            };
            const res = await fetchData(`wallikonCampaign/selectDealersOnGroups`, payload);
            if (res.status === 'success') {
                getAllDealersList1()
                getAllSelectDealers();
                return
            }
            if (res.status === 'failed') {
                setloading(prve => ({
                    ...prve, loading8: false
                }));
                return
            }
            else {
                setloading(prve => ({
                    ...prve, loading8: false
                }));
                return
            }
        } catch (error) {
            setloading(prve => ({
                ...prve, loading8: false
            }));
        }

    };

    //  main Entry for sending api
    const handleSubmit = async () => {
        if (selectedOptionWhat === "Select") {
            show("Please select a template", "error");
            return
        }
        if (inputBox.campaignName === "") {
            show("Campaign Name is requird", "error");
            return
        }
        if (whenRedio === "Message") {
            selectedDealersWhatsAppPublish();
            return
        }
        if (whenRedio === "Recurring") {
            const newDateTimeInputs = dateTimeInputs.map(input => {
                if (!input.date || !input.time) {
                    return { ...input, error: true };
                }
                return { ...input, error: false };
            });
            setDateTimeInputs(newDateTimeInputs);

            if (newDateTimeInputs.some(input => input.error)) {
                show('Please fill in all date and time fields.', 'error');
            } else {
                selectedDealersWhatsAppPublish();
            }
            return
        }
    };

    //  create Darft 
    const CreateDraft = async (selectedDealers1) => {
        try {
            // setloading(prve => ({
            //     ...prve, loading4: true
            // }))
            const payload = {
                managerId: managerId,
                campaignName: inputBox.campaignName,
                label: inputBox.label,
                groupName: singleGroupSelect,
                templateName: selectedOptionWhat === "Select" ? "" : selectedOptionWhat,
                dealersData: selectedDealers1,
                ...(whenRedio === "Recurring" && dateTimeInputs.length > 0
                    ? { schedules: dateTimeInputs.map((input) => ({ date: input.date, time: input.time })) } // Add key for "Recurring" if dateTimeInputs is not empty
                    : {})
            };

            const res = await TimeOutFetch(`wallikonCampaign/createCampaign`, payload);
            if (res.status === 'success') {
                // allUnselectedUpdates();
                sentTemplettoAllDealers(selectedDealers1);
                return
            }
            if (res.status === 'failed') {
                setloading(prve => ({
                    ...prve, loading7: false
                }));
                show(`${res.msg}`, "error");
                return
            }
            else {
                show("Something went wrong", "error");
                setloading(prve => ({
                    ...prve, loading7: false
                }));
                return
            }
        } catch (error) {
            show("Something went wrong", "error");
            setloading(prve => ({
                ...prve, loading7: false
            }));
        }
    }

    // get Dealer data whatApp
    const selectedDealersWhatsApp = async () => {
        try {

            setloading(prve => ({
                ...prve, loading3: true
            }));
            const payload = {
                managerId: managerId,
            }
            const res = await TimeOutFetch(`wallikonCampaign/selectedDealersData`, payload);
            if (res.status === 'success') {
                CreateCampaign(res?.data)
                return
            }
            if (res.status === 'failed') {
                CreateCampaign(res?.data)
                // setloading(prve => ({
                //     ...prve, loading3: false
                // }));
                return
            }
            else {
                show("Something went wrong", "error");
                setloading(prve => ({
                    ...prve, loading3: false
                }));
                return
            }

        } catch (error) {
            show("Something went wrong", "error");
            setloading(prve => ({
                ...prve, loading3: false
            }));
        }
    };

    // get select dealer data 
    const selectedDealersWhatsAppPublish = async () => {
        try {
            setloading(prve => ({
                ...prve, loading7: true
            }));
            const payload = {
                managerId: managerId
            }
            const res = await TimeOutFetch(`wallikonCampaign/selectedDealersData`, payload);
            if (res.status === 'success') {
                CreateDraft(res?.data);
                setInstantSendData(res?.data);
                return
            }
            if (res.status === 'failed') {
                setloading(prve => ({
                    ...prve, loading7: false
                }));
                show(`${res.msg}`, "error");
                return
            }
            else {
                show("Something went wrong", "error");
                setloading(prve => ({
                    ...prve, loading7: false
                }));
                return
            }

        } catch (error) {
            show("Something went wrong", "error");
            setloading(prve => ({
                ...prve, loading7: false
            }));
        }
    };
    //  create chunck for run loop
    const createChunks = (array, chunkSize) => {
        return array.reduce((acc, _, i) => {
            if (i % chunkSize === 0) {
                acc.push(array.slice(i, i + chunkSize));
            }
            return acc;
        }, []);
    };
    //  this is main logic for send camping 
    const sentTemplettoAllDealers = async (selectedDealers1) => {
        const dealerPhoneNumbers = selectedDealers1.map(dealer => dealer?.whatsapp);
        if (whenRedio === "Message") {
            const chunkSize = 2; // Process in chunks of 5
            let pagenumber = 1;  // Start from page 1

            const chunks = createChunks(selectedDealers1, chunkSize);
            for (let i = 0; i < chunks.length; i++) {
                try {
                    const payload = {
                        managerId: managerId,
                        dealerPhoneNumber: dealerPhoneNumbers,
                        templateName: selectedOptionWhat,
                        languageCode: templetData?.language,
                        campaignName: inputBox?.campaignName,
                        headerType: templetData?.headerType,
                        headerId: templetData?.headerId,
                    };
                    if (templetData.headerType === 'document') {
                        payload.documentFilename = templetData?.documentName;
                    }
                    if (templetData.headerType === 'location') {
                        payload.latitude = "26.8428008";
                        payload.longitude = "80.9359821";
                        payload.locationName = "Wallicon Private Limited";
                        payload.locationAddress = "Wallicon, Burlington, Aminabad Road, Lucknow";
                        // payload.buttonIndex = "1";
                        // payload.buttonDataPayload = "https://dealer.wallicon.in/payment-order/Wal54LhjZqiN14";
                    }
                    // Send request to API with current page number
                    const res = await TimeOutFetch(`wallikonChat/sendTemplateCampaign?page=${pagenumber}`, payload);
                    if (res.status === 'success') {
                        pagenumber++;
                        if (res?.status === 'success' && Array.isArray(res.results)) {
                            setInstantSendTemplet((prevState) => [...prevState, ...res?.results]);
                        } else {
                            alert("Failed to add entries to InstantSendTemplet");
                        }

                        if (res.response.sentToAllDealers) {
                            setInstantSendModel(true)
                            // processResult();
                        }
                        // return
                    } else if (res.status === 'failed') {
                        pagenumber++;
                        setloading(prve => ({ ...prve, loading7: false }));
                        show(`${res.msg}`, "error");
                        return;
                    } else {
                        pagenumber++;
                        show("Something went wrong", "error");
                        setloading(prve => ({ ...prve, loading7: false }));
                        return;
                    }

                } catch (error) {
                    pagenumber++;
                    show("Something went wrong", "error");
                    setloading(prve => ({ ...prve, loading7: false }));
                    return;
                }
            }
        }
        if (whenRedio === "Recurring") {
            const chunkSize = 2; // Process in chunks of 5
            let pagenumber = 1;  // Start from page 1

            // Use createChunks function to split dealerNo into chunks of specified size
            const chunks = createChunks(selectedDealers1, chunkSize);
            const filteredSchedules = dateTimeInputs?.map(({ date, time }) => ({ date, time }));

            for (let i = 0; i < chunks?.length; i++) {
                try {
                    const payload = {
                        managerId: managerId,
                        dealerPhoneNumber: dealerPhoneNumbers,
                        templateName: selectedOptionWhat,
                        languageCode: templetData?.language,
                        campaignName: inputBox?.campaignName,
                        headerType: templetData?.headerType,
                        headerId: templetData?.headerId,
                        schedules: filteredSchedules
                    };

                    if (templetData.headerType === 'document') {
                        payload.documentFilename = templetData?.documentName;
                    }
                    if (templetData.headerType === 'location') {
                        payload.latitude = "26.8428008";
                        payload.longitude = "80.9359821";
                        payload.locationName = "Wallicon Private Limited";
                        payload.locationAddress = "Wallicon, Burlington, Aminabad Road, Lucknow";
                        // payload.buttonIndex = "1";
                        // payload.buttonDataPayload = "https://dealer.wallicon.in/payment-order/Wal54LhjZqiN14";
                    }

                    // Send request to API with current page number
                    const res = await TimeOutFetch(`wallikonChat/sendTemplateCampaign?page=${pagenumber}`, payload);
                    if (res?.status === 'success') {
                        pagenumber++;
                        setloading(prve => ({ ...prve, loading7: false }));
                    } else if (res?.status === 'failed') {
                        pagenumber++;
                        setloading(prve => ({ ...prve, loading7: false }));
                        show(`${res?.msg}`, "error");
                        return;
                    } else {
                        pagenumber++;
                        show("Something went wrong", "error");
                        setloading(prve => ({ ...prve, loading7: false }));
                        return;
                    }

                } catch (error) {
                    pagenumber++;
                    show("Something went wrong", "error");
                    setloading(prve => ({ ...prve, loading7: false }));
                    return;
                }
            }
        }
    };

    //  this is logic for process Result for  next APi and save data
    useEffect(() => {
        let count = 1;
        if (InstantSendData.length > 0 && InstantSendTemplet.length > 0) {
            if (InstantSendData.length === InstantSendTemplet.length) {
                if (count === 1) {
                    processResult();
                    count++;
                }
            }
        }
    }, [InstantSendData, InstantSendTemplet]);

    //  this is process Result and run api   
    const processResult = () => {
        if (!Array.isArray(InstantSendData) || !Array.isArray(InstantSendTemplet)) {
            return;
        }
        const newStatusArray = InstantSendData.map(dealer => {
            const foundResult = InstantSendTemplet.find(result => {
                const input = result?.data?.contacts?.[0]?.input || result?.data?.to;
                return input && (input.toString() === dealer.whatsapp.toString());
            });

            return {
                whatsapp: dealer.whatsapp,
                name: dealer.name,
                status: foundResult ? foundResult.status : 'failed'
            };
        });

        handleAddTemplateRecord(newStatusArray);
    };

    //  this api run for save data 
    const handleAddTemplateRecord = async (statusInfo) => {
        try {
            const raw = {
                "managerId": managerId,
                "templateName": selectedOptionWhat,
                "statusInfo": statusInfo
            };
            const result = await TimeOutFetch("wallikonChat/addUpTempstatus", raw)
            if (result.status === 'success') {
                setloading(prev => ({ ...prev, loading7: false }));
            }
            if (result.status === 'failed') {
                setloading(prev => ({ ...prev, loading7: false }));
            }
        } catch (error) {
            setloading(prev => ({ ...prev, loading7: false }));
        }
    }

    //  all select update 
    const allSelectUpdate = async () => {
        try {
            const payload = {
                managerId: managerId,
                keyword: dealerSarch,
                status: selectedOption === "All" || selectedOption === "Select" ? "" : selectedOption,
                locations: {
                    states: selectedLocations.length > 0 ? selectedLocations : [],
                    districts: selectedLocations1.length > 0 ? selectedLocations1 : [],
                    cities: selectedLocations2.length > 0 ? selectedLocations2 : [],
                    pincodes: sarchLocation.pincodeSarch ? [sarchLocation.pincodeSarch] : []
                }
            }
            await TimeOutFetch(`wallikonCampaign/allSelectedUpdates`, payload);
            getAllDealersList1();
            getAllSelectDealers()

        } catch (error) {
            console.log(error);
        }
    }

    // all user 
    const allUnselectedUpdates = async () => {
        try {
            const payload = {
                managerId: managerId,
                keyword: dealerSarch,
                status: selectedOption === "All" || selectedOption === "Select" ? "" : selectedOption,
                locations: {
                    states: selectedLocations.length > 0 ? selectedLocations : [],
                    districts: selectedLocations1.length > 0 ? selectedLocations1 : [],
                    cities: selectedLocations2.length > 0 ? selectedLocations2 : [],
                    pincodes: sarchLocation.pincodeSarch ? [sarchLocation.pincodeSarch] : []
                }
            }
            const res = await TimeOutFetch(`wallikonCampaign/allUnselectedUpdates`, payload);
            if (res.status === 'success') {
                getAllDealersList1();
                getAllSelectDealers()
            }

        } catch (error) {
            console.log(error);
        }
    }

    // 
    const handelUpdate = (event) => {
        if (event.target.checked) {
            allSelectUpdate();
        }
        if (event.target.checked === false) {
            allUnselectedUpdates();
        }
    };

    useEffect(() => {
        const allSelected =
            findGroupDealers.length > 0 &&
            findGroupDealers.every(dealer => dealer.selectStatus === "selected");

        // Update only if the value has changed
        setsSlectAllUser((prevState) => (prevState !== allSelected ? allSelected : prevState));
    }, [findGroupDealers]);


    useEffect(() => {
        if (
            dealerSarch !== "" ||
            (selectedOption !== "All" && selectedOption !== "Select") ||
            selectedLocations.length > 0 ||
            selectedLocations1.length > 0 ||
            selectedLocations2.length > 0 ||
            sarchLocation.pincodeSarch
        ) {
            setsSlectAllUser(false);
        }
    }, [
        dealerSarch,
        selectedOption,
        selectedLocations,
        selectedLocations1,
        selectedLocations2,
        sarchLocation.pincodeSarch
    ]);

    const getAllDealersList = async () => {
        try {
            const isFilterApplied =
                dealerSarch !== "" ||
                (selectedOption !== "All" && selectedOption !== "Select") ||
                selectedLocations.length > 0 ||
                selectedLocations1.length > 0 ||
                selectedLocations2.length > 0 ||
                sarchLocation.pincodeSarch;

            if (isFilterApplied) {
                setPageUser(1);
            }


            setloading(prve => ({
                ...prve, loading5: true
            }));
            const payload = {
                managerId: managerId,
                keyword: dealerSarch,
                status: selectedOption === "All" || selectedOption === "Select" ? "" : selectedOption,
                locations: {
                    states: selectedLocations.length > 0 ? selectedLocations : [],
                    districts: selectedLocations1.length > 0 ? selectedLocations1 : [],
                    cities: selectedLocations2.length > 0 ? selectedLocations2 : [],
                    pincodes: sarchLocation.pincodeSarch ? [sarchLocation.pincodeSarch] : []
                }
            };
            const res = await TimeOutFetch(`wallikonCampaign/findGroupDealers?page=${pageUser}&limit=${20}`, payload);
            setloading(prve => ({
                ...prve, loading5: false
            }));
            if (isFilterApplied) {
                setfindGroupDealers(res?.data);
            }
            else {
                setfindGroupDealers(prevDealers => {
                    const combinedData = [...prevDealers, ...res.data];
                    const uniqueData = combinedData.filter(
                        (item, index, self) => self.findIndex((t) => t.dealerId === item.dealerId) === index
                    );
                    return uniqueData;
                }
                );
            }
            setPagesUser(res?.pages);
        } catch (error) {
            setPagesUser(1);
            setPageUser(1);
            setloading(prve => ({
                ...prve, loading5: false
            }));
        }
    };

    const getAllDealersList1 = async () => {
        try {
            const isFilterApplied =
                dealerSarch !== "" ||
                (selectedOption !== "All" && selectedOption !== "Select") ||
                selectedLocations.length > 0 ||
                selectedLocations1.length > 0 ||
                selectedLocations2.length > 0 ||
                sarchLocation.pincodeSarch;

            if (isFilterApplied) {
                setPageUser(1); // Reset the page to 1 if a filter is applied
            }


            setloading(prve => ({
                ...prve, loading5: true
            }));
            const payload = {
                managerId: managerId,
                keyword: dealerSarch,
                status: selectedOption === "All" || selectedOption === "Select" ? "" : selectedOption,
                locations: {
                    states: selectedLocations.length > 0 ? selectedLocations : [],
                    districts: selectedLocations1.length > 0 ? selectedLocations1 : [],
                    cities: selectedLocations2.length > 0 ? selectedLocations2 : [],
                    pincodes: sarchLocation.pincodeSarch ? [sarchLocation.pincodeSarch] : []
                }
            };
            const res = await TimeOutFetch(`wallikonCampaign/findGroupDealers?page=${1}&limit=${20}`, payload);
            setloading(prve => ({
                ...prve, loading5: false
            }));
            setfindGroupDealers(res?.data);
            setPagesUser(res?.pages);
        } catch (error) {
            setPagesUser(1);
            setPageUser(1);
            setloading(prve => ({
                ...prve, loading5: false
            }));
        }
    };

    //  Filter Loction Api
    const getLoactionFilter = async () => {
        const payload = {
            managerId: managerId,
            stateSearch: "",
            districtSearch: "",
            citySearch: "",
            pincodeSearch: sarchLocation.pincodeSarch,
            locations: {
                states: [],
                districts: [],
                cities: [],
                pincodes: []
            }
        };
        try {
            const res = await TimeOutFetch(`wallikonCampaign/filterLocation`, payload);
            // setlocations0(prev => [...prev, ...res?.data?.states]);
            setlocations0(res?.data?.states);
            setlocations1(res?.data?.districts);
            setlocations2(res?.data?.cities);
        } catch (error) {
            console.log(error);
        }
    };

    const option = ["All", "Contacts", "Stockists", "Dealers", "Blacklisted", "Groups", "New Users", "Not Active", "Not Verified", "Contacts", "Purchase", "Not Purchase"];
    const option1 = ["All", "Duration"];
    useEffect(() => {
        getLoactionFilter();
    }, []);

    // this use effect for ui logic 
    useEffect(() => {
        const handeelClickOutside = (event) => {
            if (!segment.current.contains(event.target)) {
                setIsOpenSelectSegment2(false)
            }
            if (!hanveDone.current.contains(event.target)) {
                setIsOpenSelectWhohave(false)
            }
            if (!messageType.current.contains(event.target)) {
                setIsOpenSelectWhat(false)
            }
        }
        document.addEventListener("click", handeelClickOutside);
        return () => {
            document.removeEventListener("click", handeelClickOutside)
        }
    }, [])
    useEffect(() => {
        getAllDealersList();
    }, [pageUser, selectedOption, dealerSarch, selectedLocations, selectedLocations1, selectedLocations2, sarchLocation.pincodeSarch]);


    const handleSearch = (value) => {
        setSarchValue(value);
        setFilteredLocations(
            locations0.filter((loc) =>
                loc.toLowerCase().includes(value.toLowerCase())
            )
        );
    };

    const handleSearch1 = (value) => {
        setSarchValue1(value);
        setFilteredLocations1(
            locations1.filter((loc) =>
                loc.toLowerCase().includes(value.toLowerCase())
            )
        );
    };

    const handleSearch2 = (value) => {
        setSarchValue2(value);
        setFilteredLocations2(
            locations2.filter((loc) =>
                loc.toLowerCase().includes(value.toLowerCase())
            )
        );
    };

    const handleDealerSelection = (event, dealerD) => {
        const isChecked = event.target.checked;  // Get the checkbox checked status

        // Handle single dealer selection/deselection
        if (isChecked) {
            // If checked, add to select
            setSingleUserSelect((prevDealers) => [...prevDealers, dealerD.dealerId]);
        } else {
            // If unchecked, add to unselect
            setSingleUnSelect((prevUnselect) => [...prevUnselect, dealerD.dealerId]);
            setSingleUserSelect((prevDealers) => prevDealers.filter((id) => id !== dealerD.dealerId)); // Remove from select
        }

        // Update the select status in findGroupDealers
        setfindGroupDealers((prev) =>
            prev.map((d) =>
                d?.dealerId === dealerD?.dealerId
                    ? { ...d, selectStatus: isChecked ? "selected" : "unselected" }
                    : d
            )
        );
    };

    const handleDealerUnSelection = (dealerD) => {
        setSingleUnSelect((prevDealers) => {
            if (prevDealers.includes(dealerD.dealerId)) {
                // If already selected, remove the dealerId
                return prevDealers.filter((id) => id !== dealerD.dealerId);
            } else {
                // If not selected, add the dealerId
                return [...prevDealers, dealerD.dealerId];
            }
        });
        setSelectData((prev) =>
            prev.map((d) =>
                d?.dealerId === dealerD?.dealerId
                    ? { ...d, selectStatus: d?.selectStatus === "selected" ? "unselected" : "selected" }
                    : d
            )
        );
    };

    // hande unselect data 
    const handelUnselectData = async () => {
        try {
            const payload = {
                managerId: managerId,
                dealerIds: singleUnSelect
            }
            const res = await TimeOutFetch(`wallikonCampaign/updateUnselectStatus`, payload);
            if (res?.status === 'success') {
                setSingleUnSelect([])
                getAllSelectDealers();
                getAllDealersList1();
            }
            if (res?.status === 'failed') {
                setSingleUnSelect([])
                getAllSelectDealers();
                getAllDealersList1();
            }
        } catch (error) {
            console.log(error);
        }
    }

    // Handle scroll event to fetch new data and auto-select
    const handleScroll = () => {
        const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
        const bottom = Math.abs(scrollHeight - scrollTop - clientHeight) <= 5;
        if (bottom) {
            setPageUser((prevPage) => {
                if (prevPage < pagesUser) {
                    return prevPage + 1;
                }
                return prevPage;
            });
        }
    };

    const handleScroll3 = () => {
        const { scrollTop, scrollHeight, clientHeight } = containerRef3.current;
        const bottom = Math.abs(scrollHeight - scrollTop - clientHeight) <= 5;

        if (bottom) {
            setPage((prevPage) => {
                if (prevPage < pages) {
                    return prevPage + 1;
                }
                return prevPage;
            });
        }
    };

    const handleScroll1 = () => {
        const { scrollTop, scrollHeight, clientHeight } = containerRef1.current;
        const bottom = Math.abs(scrollHeight - scrollTop - clientHeight) <= 5;

        if (bottom) {
            setPageSelected((prevPage) => {
                if (prevPage < pagesSelected) {
                    return prevPage + 1;
                }
                return prevPage;
            });
        }
    };

    const handleScrolltempletRef = () => {
        const { scrollTop, scrollHeight, clientHeight } = templetRef.current;
        const bottom = Math.abs(scrollHeight - scrollTop - clientHeight) <= 5;

        if (bottom) {
            setTempPage((prevPage) => {
                if (prevPage < TempPages) {
                    return prevPage + 1;
                }
                return prevPage;
            });
        }
    };

    // get all selected dealers
    const getAllSelectDealers = async () => {
        try {
            setloading((pre) => ({
                ...pre, loading6: true
            }))
            const payload = {
                managerId: managerId,
                stateSearch: "",
                districtSearch: "",
                citySearch: "",
                pincodeSearch: [],
                locations: {
                    states: [],
                    districts: [],
                    cities: [],
                    pincodes: []
                }
            };
            const res = await TimeOutFetch(`wallikonCampaign/selectedDealers?page=${pageSelected}&limit=${20}`, payload);
            if (res.status === 'success') {
                // setSelectData(res?.data);
                setSelectData((prevTempData) => {
                    const combinedData = [...prevTempData, ...res.data];
                    const uniqueSelectedData = combinedData
                        .filter(
                            (item, index, self) =>
                                item.selectStatus === "selected" &&
                                self.findIndex((t) => t.dealerId === item.dealerId) === index
                        );

                    return uniqueSelectedData;
                });

                setPagesSelected(res?.pages);
                setloading((pre) => ({
                    ...pre, loading6: false
                }));
            }
            if (res.status === 'failed') {
                setloading((pre) => ({
                    ...pre, loading6: false
                }));
            }
        } catch (error) {
            setloading((pre) => ({
                ...pre, loading6: false
            }))
        }

    }

    //  update selected Dealer 
    const updateSelectedData = async () => {
        try {
            const payload = {
                managerId: managerId,
                dealerIds: singleUserSelect
            }
            const res = await TimeOutFetch(`wallikonCampaign/updateSelectStatus`, payload);
            if (res?.status === 'success') {
                setSingleUserSelect([])
                getAllSelectDealers()
            }
        } catch (error) {
            console.log(error);
        }
    }


    useEffect(() => {
        if (activeTab === "Select" && singleUserSelect.length > 0) {
            updateSelectedData();
        }
        if (activeTab === "Users" || activeTab === "DealersGroup" || activeTab === "Select") {
            handelUnselectData();
        }
    }, [activeTab]);

    useEffect(() => {
        const container = containerRef.current;
        if (container) {
            container.addEventListener("scroll", handleScroll);
        }
        return () => {
            if (container) {
                container.removeEventListener("scroll", handleScroll);
            }
        };
    }, [loading.loading5, pageUser, activeTab]);

    useEffect(() => {
        const container = containerRef3.current;
        if (container) {
            container.addEventListener("scroll", handleScroll3);
        }
        return () => {
            if (container) {
                container.removeEventListener("scroll", handleScroll3);
            }
        };
    }, [activeTab, loading.loading1, page]);

    useEffect(() => {
        const container1 = containerRef1.current;
        if (container1) {
            container1.addEventListener("scroll", handleScroll1);
        }
        return () => {
            if (container1) {
                container1.removeEventListener("scroll", handleScroll1);
            }
        };
    }, [loading.loading6, pageSelected, activeTab]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (boxRef.current && !boxRef.current.contains(event.target)) {
                setIsOpenSelectSegment(false);
            }
            if (boxRef1.current && !boxRef1.current.contains(event.target)) {
                setShowMenu(false)
            }
            if (boxRef2.current && !boxRef2.current.contains(event.target)) {
                setShowMenu1(false)
            }
            if (boxRef3.current && !boxRef3.current.contains(event.target)) {
                setShowMenu2(false)
            }
            if (boxRef4.current && !boxRef4.current.contains(event.target)) {
                setIsOpenSelectSegment1(false)
            }
        };
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        setFilteredLocations(locations0);
    }, [locations0]);

    useEffect(() => {
        setFilteredLocations1(locations1);
    }, [locations1]);

    useEffect(() => {
        setFilteredLocations2(locations2);
    }, [locations2]);

    useEffect(() => {
        getAllSelectDealers();
    }, [pageSelected]);

    //  Change Templates
    const ChangeStatus = async () => {
        try {
            setloading(prve => ({
                ...prve, loading9: true
            }));
            const response = await fetchData(`wallikonChat/changeTempStatus`, {
                "managerId": managerId,
                "templateName": selectedOptionWhat,
            });
            if (response.status === "success") {
                show(`${response?.msg}`, "success");
                setTempSelectModel(false);
                setloading(prve => ({
                    ...prve, loading9: false
                }));
                return
            } if (response.status === "failed") {
                show(`${response.msg}`, "error");
                setloading(prve => ({
                    ...prve, loading9: false
                }));
                return
            } else {
                show("Error", "error");
                setloading(prve => ({
                    ...prve, loading9: false
                }));
            }
        } catch (error) {
            setloading(prve => ({
                ...prve, loading9: false
            }));
        }

    }

    const handehChackTempStaus = async (temp) => {
        try {
            setSelectedOptionWhat(temp.templateName)
            if (temp.templateSent) {
                setTempSelectModel(true);
            }
        } catch (error) {

        }
    }

    useEffect(() => {
        if (isOpenSelectWhat && templetRef.current) {
            const containertempletRef = templetRef.current;
            if (containertempletRef) {
                containertempletRef.addEventListener("scroll", handleScrolltempletRef);
            }
            return () => {
                if (containertempletRef) {
                    containertempletRef.removeEventListener("scroll", handleScrolltempletRef);
                }
            };
        }
    }, [isOpenSelectWhat]);

    const handlePercentage = () => {
        let progress = 0;
        if (inputBox.campaignName.trim() !== "") progress += 20;
        if (inputBox.label.trim() !== "") progress += 20;
        if (selectData.length > 0) progress += 30;
        if (selectedOptionWhat !== "Select") progress += 30;
        setProgress(progress);
    };

    useEffect(() => {
        handlePercentage();
    }, [inputBox, selectData, selectedOptionWhat, whenRedio]);



    return (
        <div className={styles.mainCintner}  >
            <ModalWrapper isOpen={InstantSendModel} onClose={() => setInstantSendModel(!InstantSendModel)} >
                <div className={styles.modelCon} style={{ backgroundColor: mode ? "#2C2E33" : "#fff", color: mode ? "#fff" : "#000" }} >
                    <div className={styles.SacandContiner}>
                        <div className={styles.headre}>
                            <div >SN</div>
                            <div style={{ color: "red", marginLeft: "25px" }} >  Number </div>
                            <div style={{ color: "green" }}> Success </div>
                        </div>
                        <div className={styles.bodyContiner} >
                            {
                                InstantSendTemplet.map((e, i) =>
                                    <div className={styles.bodyContiner2} key={`${i}E`}>
                                        <div >{i + 1}</div>
                                        <div> {e?.data?.contacts[0]?.input} </div>
                                        <div> {e?.status === "success" ? <DoubleCheckIcon /> : <CrossRedIcon />}  </div>
                                    </div>
                                )
                            }
                        </div>
                        <div className={styles.btnCon} >
                            <Button buttonBgColor={"#000"} buttonText={"Close"} buttonTextColor={"#fff"} buttonClick={() => setInstantSendModel(false)} />
                        </div>
                    </div>
                </div>
            </ModalWrapper>
            <ModalWrapper closeBtn={true} isOpen={tempSelectModel} onClose={() => setTempSelectModel(!tempSelectModel)} style={{ width: "800px", }} >
                <div style={{
                    // minWidth: "350px",
                    maxWidth: "500px",
                    justifyContent: "center",
                    display: "flex",
                    alignItems: "center",
                    // border: "1px solid",
                    paddingBottom: "20px",
                    backgroundColor: mode ? "#2C2E33" : "#fff",
                    borderRadius: "9px",
                    margin: "0 auto"
                }} >
                    <div className={styles.containermode}  >
                        <div className={styles.model_text}>
                            <span style={{ color: mode ? "#fff" : "#000" }}> Are you sure want to change status </span>
                        </div>

                        <div className={styles.btn_Continer} >
                            <div className={styles.mode_btn} >
                                <Button
                                    buttonText={"NO"}
                                    buttonBgColor={"#000000"}
                                    buttonBorder={"2px"}
                                    buttonTextColor={"#ffff"}
                                    buttonClick={() => setTempSelectModel(false)}
                                />
                            </div>
                            <div className={styles.mode_btn}>
                                <Button
                                    buttonText={"YES"}
                                    buttonBgColor={"#4164E3"}
                                    buttonBorder={"2px"}
                                    buttonTextColor={"#ffff"}
                                    buttonClick={ChangeStatus}
                                    loading={loading.loading9}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </ModalWrapper>
            <div className={styles.Continer} style={{ backgroundColor: mode ? "#2C2E33" : "#F8F9FC" }} >
                <div className={styles.goBackCon}   >
                    <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                        <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                            <div>
                                <TopTabBar text={"Campaigns / New Campaign"} />
                            </div>
                            <div style={{ width: "130px" }}>
                                <Button
                                    buttonBgColor={"#4164E3"}
                                    buttonText={"Save Draft"}
                                    buttonIcon={<Draft />}
                                    buttonTextColor={"#fff"}
                                    // buttonClick={CreateCampaign}
                                    buttonClick={selectedDealersWhatsApp}
                                    loading={loading.loading3}
                                // disabled={whenRedio === '' ? false : true}
                                // buttonBorder={mode ? "1px solid #fff" : "1px solid #c2c2c2"}
                                />
                            </div>
                        </div>
                        <div className={styles.sacrchGroup}  >
                            <div style={{ width: "100%" }}>
                                <Progress progress={progress} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.sarchBarConinnter1}>
                    <div className={styles.sarchBarConinnterFristChild1}>
                        <div className={styles.sarchconGrop2} style={{ alignItems: "center", }}  >
                            <div style={{ width: "20%" }}  >
                                <InputTag
                                    labelText={"Name"}
                                    placeholderText={"Enter Name"}
                                    required={true}
                                    placeholderType={"text"}
                                    maxLength={50}
                                    onChange={(event) => {
                                        setInputbox(prv => ({
                                            ...prv, campaignName: event.target.value
                                        }))
                                        handlePercentage();
                                    }}
                                />
                            </div>
                            <div style={{ width: "20%" }}  >
                                <InputTag
                                    labelText={"Label"}
                                    placeholderText={"Halloween Offer"}
                                    required={true}
                                    placeholderType={"text"}
                                    maxLength={100}
                                    onChange={(event) => {
                                        setInputbox(prv => ({
                                            ...prv, label: event.target.value
                                        }));
                                        handlePercentage()
                                    }}
                                />
                            </div>
                        </div>
                    </div>

                    {
                        loading.loading10 ? <Skeleton lines={5} thickness={8} /> :
                            <div className={styles.tableContiner1}>
                                <Accordion type="single" collapsible   >
                                    <AccordionItem value="item-1"   >
                                        <AccordionTrigger >
                                            <div className={styles.AccordionTriggerContiner} style={{ backgroundColor: "#1E89C833" }}  >
                                                <div className={styles.Accordioncolor} style={{ backgroundColor: "#1E89C8" }} />
                                                <div className={styles.AccordionText} >
                                                    <div>
                                                        <span className={styles.AccordionTextFrist} style={{ color: mode ? "#fff" : "#000", }}> Start here </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </AccordionTrigger>
                                        <AccordionContent>
                                            <div className={styles.Start_content_conintner} style={{ backgroundColor: mode ? "#1D1F23" : "#FFFFFF" }} >
                                                <div className={styles.Start_content_conintnerMain}>
                                                    <div className={styles.Start_content_conintner_header} style={{ backgroundColor: mode ? "#232529" : "#D9DCE5" }} >
                                                        <div className={styles.headIcon}>
                                                            <div></div>
                                                        </div>
                                                        <div>
                                                            <span style={{ color: mode ? "#fff" : "#646B88", fontSize: "15px", fontWeight: "500" }}>Qualification Criteria</span>
                                                        </div>
                                                    </div>
                                                    <div className={styles.startContant}
                                                        style={{ paddingBottom: "10px", backgroundColor: mode ? "#2C2E33" : "transparent", border: mode ? "none" : "1px solid #E4E7EB" }} >
                                                        <div className={styles.startContant_justSent} style={mode ? { backgroundColor: "#232529", width: "15%" } : { border: "1px solid #E4E7EB", width: "15%" }}  >
                                                            <div className={styles.startContantContiner}  >
                                                                <span className={styles.fristLIne} style={{ color: mode ? "#fff" : "#000" }} >Just send</span>
                                                                <span className={styles.sandLine} style={{ color: mode ? "#fff" : "#646B88" }} >Send Message Directly</span>
                                                            </div>
                                                        </div>
                                                        <div className={styles.startContant_justSent} style={mode ? { backgroundColor: "#232529", width: "20%" } : { border: "1px solid #E4E7EB", width: "20%" }}  >
                                                            <div className={styles.startContantContiner}>
                                                                <span className={styles.fristLIne} style={{ color: mode ? "#fff" : "#000" }}>Live Behaviour</span>
                                                                <span className={styles.sandLine} style={{ color: mode ? "#fff" : "#646B88" }}>“Qualify users in real time, as soon as they perform
                                                                    or fail to perform an activity, for an example “As soon
                                                                    as Added to cart”.</span>
                                                            </div>
                                                        </div>
                                                        <div className={styles.startContant_justSent} style={mode ? { backgroundColor: "#232529", width: "20%" } : { border: "1px solid #E4E7EB", width: "20%" }}  >
                                                            <div className={styles.startContantContiner}>
                                                                <span className={styles.fristLIne} style={{ color: mode ? "#fff" : "#000" }}>Past Behaviour / Custom List</span>
                                                                <span className={styles.sandLine} style={{ color: mode ? "#fff" : "#646B88" }}> Qualify users based on an activity they
                                                                    performed or have been performing in the
                                                                    past, for example “check-out”.   </span>
                                                            </div>
                                                        </div>
                                                        <div style={{ width: "45%" }}  >    
                                                            <div style={{ display: "flex", width: "100%", justifyContent: "flex-end", alignItems: "flex-end", height: "100%" }}>
                                                                <div style={{ width: "180px", marginRight: "20px", marginBottom: "10px" }}>
                                                                    <Button
                                                                        buttonBgColor={"#4164E3"}
                                                                        buttonIcon={<TemplateDone />}
                                                                        buttonText={"Done"}
                                                                        buttonTextColor={"#fff"}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </AccordionContent>
                                    </AccordionItem>
                                    <AccordionItem value="item-2">
                                        <AccordionTrigger>
                                            <div className={styles.AccordionTriggerContiner} style={{ backgroundColor: "#8456FF33" }}  >
                                                <div className={styles.Accordioncolor} style={{ backgroundColor: "#8456FF" }} />
                                                <div className={styles.AccordionText} >
                                                    <div>
                                                        <span className={styles.AccordionTextFrist} style={{ color: mode ? "#fff" : "#000", }}> Who </span>
                                                    </div>
                                                    <div>
                                                        <span className={styles.AccordionTextSecand} style={{ color: mode ? "#fff" : "#000", }}>Select target segment </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </AccordionTrigger>
                                        <AccordionContent>
                                            <div className={styles.Start_content_conintner} style={{ backgroundColor: mode ? "#1D1F23" : "#FFFFFF", position: "relative" }} >
                                                <div className={styles.who_content_conintnerMain} style={{
                                                    backgroundColor: mode ? "#232529" : "#fff",
                                                    color: mode ? "#fff" : "#000",
                                                    border: mode ? "none" : "1px solid #E4E7EB",
                                                    padding: "10px",

                                                }}>
                                                    <div className={styles.Who_SelectUser}   >
                                                        <div><span style={{ fontSize: "12px" }}>  Find users form segment  </span> </div>
                                                        <div className={styles.Who_Select} style={{
                                                            background: mode ? "#1B1D21" : "#fff",
                                                            border: mode ? "none" : "1px solid #E4E7EB"
                                                        }}
                                                            onClick={() => setIsOpenSelectSegment2(!isOpenSelectSegment2)}
                                                            ref={segment}
                                                        >
                                                            <div>
                                                                <span style={{ fontSize: "14px" }}>{selectedOption2}</span>
                                                            </div>
                                                            <div>
                                                                <span>
                                                                    <AddBtnicon mode={mode} rotate={isOpenSelectSegment ? 180 : 0} />
                                                                </span>
                                                            </div>
                                                            {isOpenSelectSegment2 && (
                                                                <div className={styles.Who_selectBox} style={{
                                                                    backgroundColor: mode ? "#2C2E33" : "#fff",
                                                                    border: mode ? "none" : "1px solid #E4E7EB",
                                                                    zIndex: "9999",
                                                                    padding: "10px"
                                                                }}>
                                                                    {option.map((e, i) => (
                                                                        <div className={mode ? styles.Who_selectBoxhover_dark : styles.Who_selectBoxhover} key={`${i}D`}
                                                                            onClick={() => {
                                                                                setSelectedOption2(e);
                                                                                setIsOpenSelectSegment2(false);
                                                                            }} >
                                                                            {e}
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>

                                                    <div className={styles.Who_SecandSection} style={{ backgroundColor: mode ? "#1B1D21" : "#F8F9FC" }} >
                                                        <div style={{ fontSize: "12px" }}> Who have done </div>
                                                        <div> </div>
                                                        <div className={styles.Who_Select}
                                                            style={{
                                                                background: mode ? "#232529" : "#fff",
                                                                border: mode ? "none" : "1px solid #E4E7EB"
                                                            }}
                                                            onClick={() => setIsOpenSelectWhohave(!isOpenSelectWhohave)}
                                                            ref={hanveDone}
                                                        >
                                                            <div> <span style={{ fontSize: "14px" }}>{selectedOptionWhohave} </span>  </div>
                                                            <div> <span>  <AddBtnicon mode={mode} rotate={isOpenSelectWhohave ? 180 : 0} />   </span> </div>
                                                            {isOpenSelectWhohave && <div className={styles.Who_selectBox} style={{
                                                                backgroundColor: mode ? "#2C2E33" : "#fff",
                                                                border: mode ? "none" : "1px solid #E4E7EB",
                                                                zIndex: "9999",
                                                                padding: "10px"
                                                            }} >
                                                                {option1.map((e, i) => <div className={mode ? styles.Who_selectBoxhover_dark : styles.Who_selectBoxhover} key={`${i}C`} onClick={() => { setSelectedOptionWhohave(e); setIsOpenSelectWhohave(false) }}  > {e} </div>)}
                                                            </div>
                                                            }
                                                        </div>
                                                        <div style={{ fontSize: "12px" }} >of this event</div>
                                                    </div>

                                                    <div className={styles.Who_dateContiner} >
                                                        <div className={styles.Who_dateContiner1} >
                                                            <div>
                                                                <InputTypedate text={"Form"} />
                                                            </div>
                                                            <div>
                                                                <InputTypedate text={"To"} />
                                                            </div>
                                                        </div>
                                                        <div className={styles.Who_dateContiner2} >
                                                            <div style={{ width: "180px", marginRight: "20px", marginBottom: "20px", float: "right" }}>
                                                                <Button
                                                                    buttonBgColor={"#4164E3"}
                                                                    buttonIcon={<TemplateDone />}
                                                                    buttonText={"Done"}
                                                                    buttonTextColor={"#fff"}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div style={{ marginTop: "10px" }}  >
                                                    <Tabs activeTab={activeTab} setActiveTab={setActiveTab}  >
                                                        <TabsList activeTab={activeTab} onTabChange={setActiveTab} >
                                                            <TabsTrigger value="Select" activeTab={activeTab} onTabChange={setActiveTab} mode={mode} >  Selected </TabsTrigger>
                                                            <TabsTrigger value="Users" activeTab={activeTab} onTabChange={setActiveTab} mode={mode} >Users</TabsTrigger>
                                                            <TabsTrigger value="DealersGroup" activeTab={activeTab} onTabChange={setActiveTab} mode={mode} >Dealers Group</TabsTrigger>
                                                        </TabsList>
                                                        <TabsContent value="Select" activeTab={activeTab} >
                                                            <div className={styles.tableContinerTable} style={{ backgroundColor: mode ? "#232529" : "#fff", }} >
                                                                <div className={styles.container} style={{ height: "100%" }} >
                                                                    <ul className={styles.responsiveTable} style={{ height: "100%", }}>
                                                                        <li className={styles.tableHeader} style={mode ? { backgroundColor: "#000", color: "#fafafa" } : { backgroundColor: "#D9DCE5" }} >
                                                                            <div className={`${styles.col} `} style={{ width: "800px" }} >
                                                                                <div className={styles.TableHeadChackBox}> </div> Dealer Name </div>
                                                                            <div className={`${styles.col}`} > <span>Address </span>  </div>
                                                                            <div className={styles.col} >State</div>
                                                                            <div className={styles.col} >District</div>
                                                                            <div className={styles.col} >City</div>
                                                                            <div className={styles.col} >PIN Code</div>
                                                                            <div className={styles.col} >Status</div>
                                                                            <div className={styles.col} >Joining Date</div>
                                                                        </li>
                                                                        <div style={{ height: "580px", overflowY: "scroll", }} ref={containerRef1} >
                                                                            {
                                                                                loading.loading6 ? <>
                                                                                    <Skeleton lines={10} thickness={7} />
                                                                                </> : selectData.length === 0 ? <>
                                                                                    <div style={{ width: "100%", display: "flex" }}>
                                                                                        <NoDataFound
                                                                                            icon={<TemplateNotFound />}
                                                                                            textTitle={`No dealers selected`} />
                                                                                    </div>
                                                                                </> :
                                                                                    selectData?.map((e, i) =>
                                                                                        //  Array.from({ length: 90 })?.map((e, i) =>
                                                                                        <li className={styles.tablerow}
                                                                                            style={mode ? { backgroundColor: "#1B1D21", color: "#fafafa" } : { backgroundColor: "#ffff" }} key={`${i}B`}                                                                                >
                                                                                            <div className={`${styles.col}`} >
                                                                                                <div className={styles.TableHeadChackBox} >
                                                                                                    <label className={styles.cl_checkbox} >
                                                                                                        <input type="checkbox"
                                                                                                            checked={e?.selectStatus === "selected"}
                                                                                                            onChange={() => handleDealerUnSelection(e)}
                                                                                                        />
                                                                                                        <span></span>
                                                                                                    </label>
                                                                                                </div>
                                                                                                {truncateText(`${e?.dealerName}`, 15)}
                                                                                            </div>
                                                                                            <div className={`${styles.col} `}  >{truncateText(`${e?.address}`, 25)}</div>
                                                                                            <div className={styles.col}  > {e?.status} </div>
                                                                                            <div className={styles.col}  > {e?.district} </div>
                                                                                            <div className={styles.col}  > {e?.city} </div>
                                                                                            <div className={styles.col}  > {e?.pincode} </div>
                                                                                            {/* <div className={styles.col}  >{e?.status}</div> */}
                                                                                            <div className={styles.col} style={{ color: TextColorCode(e?.status) }} >{e?.status} </div>
                                                                                            <div className={styles.col}  > {changeDateFormat(e?.createdAt, "-")}</div>
                                                                                        </li>
                                                                                    )
                                                                            }
                                                                        </div>
                                                                        <li style={{ height: "40px" }}></li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </TabsContent>
                                                        <TabsContent value="Users" activeTab={activeTab} >
                                                            <div className={styles.tableContinerTable} style={{ backgroundColor: mode ? "#232529" : "#fff", }} >
                                                                <div className={styles.sarchBarConinnter}>
                                                                    <div className={styles.sarchconGrop} style={{ marginBottom: "-10px", marginTop: "-10px" }}>
                                                                        <div className={styles.sacrchGroup} >
                                                                            <SearchBar placeholder={"Search"} onChange={(e) => setDealerSarch(e.target.value)} />
                                                                        </div>
                                                                        <div className={styles.cont} style={{ color: mode ? "white" : "black" }} ref={boxRef4}>
                                                                            <div>
                                                                                <span style={{ fontSize: "12px", fontWeight: "500", color: mode ? "#fff" : "#000" }} > Status </span>
                                                                            </div>
                                                                            <div className={styles.Who_Select3} style={{
                                                                                background: mode ? "#1B1D21" : "#fff",
                                                                                border: mode ? "none" : "1px solid #E4E7EB",
                                                                            }}
                                                                                onClick={() => setIsOpenSelectSegment1(!isOpenSelectSegment1)}
                                                                                ref={boxRef} >
                                                                                <div>
                                                                                    <span style={{ fontSize: "12px" }}>{selectedOption}</span>
                                                                                </div>
                                                                                <div><span><AddBtnicon mode={mode} rotate={isOpenSelectSegment1 ? 180 : 0} /></span> </div>
                                                                                {isOpenSelectSegment1 && (
                                                                                    <div className={styles.Who_selectBox} style={{
                                                                                        backgroundColor: mode ? "#2C2E33" : "#fff",
                                                                                        border: mode ? "none" : "1px solid #E4E7EB",
                                                                                        zIndex: "9999",
                                                                                        top: "100%",
                                                                                        left: "0%",
                                                                                        padding: "10px"
                                                                                        // left: width > 701 ? "100%" : "-30%",
                                                                                        // top: width > 701 ? "0%" : "100%"
                                                                                    }} >
                                                                                        {statusOption.map((e, i) => (
                                                                                            <div className={mode ? styles.Who_selectBoxhover_dark : styles.Who_selectBoxhover} key={i}
                                                                                                onClick={() => {
                                                                                                    setSelectedOption(e?.label);
                                                                                                    setIsOpenSelectSegment(false);
                                                                                                }}>
                                                                                                {e?.label}
                                                                                            </div>
                                                                                        ))}
                                                                                    </div>
                                                                                )}
                                                                            </div>
                                                                        </div>

                                                                        <div className={styles.iconWrapper0} style={{ color: mode ? "white" : "black" }} ref={boxRef1} >
                                                                            <div>
                                                                                <span style={{ fontSize: "12px", fontWeight: "500", color: mode ? "#fff" : "#000" }} > State </span>
                                                                            </div>
                                                                            <div className={styles.Who_Select3} style={{
                                                                                background: mode ? "#1B1D21" : "#fff",
                                                                                border: mode ? "none" : "1px solid #E4E7EB",
                                                                                position: "relative"
                                                                            }}
                                                                                onClick={() => setShowMenu(!showMenu)}
                                                                            >
                                                                                <div> <span style={{ fontSize: "12px" }}> Haryana </span>  </div>
                                                                                <div> <span> <AddBtnicon mode={mode} rotate={showMenu ? 180 : 0} />  </span>  </div>

                                                                            </div>
                                                                            {
                                                                                showMenu &&
                                                                                <div className={styles.NewLocatiionFilter} style={{ top: "100%", left: "0%", zIndex: "999", backgroundColor: mode ? "#2C2E33" : "#fff", color: mode ? "#fff" : "#000" }} >
                                                                                    <LocationsFilter
                                                                                        locations={filteredLocations}
                                                                                        sarchValue={sarchValue}
                                                                                        sareshOnchange={handleSearch}
                                                                                        selectedLocations={selectedLocations}
                                                                                        setSelectedLocations={setSelectedLocations}
                                                                                    />
                                                                                </div>
                                                                            }
                                                                        </div>

                                                                        <div className={styles.iconWrapper0} style={{ color: mode ? "white" : "black" }} ref={boxRef2} >
                                                                            <div>
                                                                                <span style={{ fontSize: "12px", fontWeight: "500", color: mode ? "#fff" : "#000" }} > District   </span>
                                                                            </div>
                                                                            <div className={styles.Who_Select3} style={{
                                                                                background: mode ? "#1B1D21" : "#fff",
                                                                                border: mode ? "none" : "1px solid #E4E7EB",
                                                                                position: "relative"
                                                                            }}
                                                                                onClick={() => setShowMenu1(!showMenu1)}
                                                                            >
                                                                                <div> <span style={{ fontSize: "12px" }}> Kheri </span>  </div>
                                                                                <div> <span> <AddBtnicon mode={mode} rotate={showMenu1 ? 180 : 0} />  </span>  </div>

                                                                            </div>
                                                                            {
                                                                                showMenu1 &&
                                                                                <div className={styles.NewLocatiionFilter} style={{ top: "100%", left: "0%", zIndex: "999", backgroundColor: mode ? "#2C2E33" : "#fff", color: mode ? "#fff" : "#000" }} >
                                                                                    <LocationsFilter
                                                                                        locations={filteredLocations1}
                                                                                        sarchValue={sarchValue1}
                                                                                        sareshOnchange={handleSearch1}
                                                                                        selectedLocations={selectedLocations1}
                                                                                        setSelectedLocations={setSelectedLocations1}
                                                                                    />
                                                                                </div>
                                                                            }

                                                                        </div>

                                                                        <div className={styles.iconWrapper0} style={{ color: mode ? "white" : "black" }} ref={boxRef3} >
                                                                            <div>
                                                                                <span style={{ fontSize: "12px", fontWeight: "500", color: mode ? "#fff" : "#000" }} > City  </span>
                                                                            </div>
                                                                            <div className={styles.Who_Select3} style={{
                                                                                background: mode ? "#1B1D21" : "#fff",
                                                                                border: mode ? "none" : "1px solid #E4E7EB",
                                                                                position: "relative"
                                                                            }}
                                                                                onClick={() => setShowMenu2(!showMenu2)}
                                                                            >
                                                                                <div> <span style={{ fontSize: "12px" }}> Lucknow </span>  </div>
                                                                                <div> <span> <AddBtnicon mode={mode} rotate={showMenu2 ? 180 : 0} />  </span>  </div>

                                                                            </div>
                                                                            {
                                                                                showMenu2 &&
                                                                                <div className={styles.NewLocatiionFilter} style={{ top: "100%", left: "0%", zIndex: "999", backgroundColor: mode ? "#2C2E33" : "#fff", color: mode ? "#fff" : "#000" }} >
                                                                                    <LocationsFilter
                                                                                        locations={filteredLocations2}
                                                                                        sarchValue={sarchValue2}
                                                                                        sareshOnchange={handleSearch2}
                                                                                        selectedLocations={selectedLocations2}
                                                                                        setSelectedLocations={setSelectedLocations2}
                                                                                    />
                                                                                </div>
                                                                            }
                                                                        </div>

                                                                        <div className={styles.cont1} style={{ color: mode ? "white" : "black" }} >
                                                                            <div>
                                                                                <span style={{ fontSize: "12px", fontWeight: "500", color: mode ? "#fff" : "#000" }} > Pin  </span>
                                                                            </div>
                                                                            <div style={{ fontSize: "12px", fontWeight: "400" }}>
                                                                                <SrarchPin
                                                                                    placeholder={"Search"}
                                                                                    type={"number"}
                                                                                    maxLength={6}
                                                                                    onChange={(event) => setSarchLocation((prv) => ({
                                                                                        ...prv, pincodeSarch: event
                                                                                    }))}
                                                                                />
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                                <div className={styles.container} style={{ height: "100%" }}>
                                                                    <ul className={styles.responsiveTable} style={{ height: "100%" }} >
                                                                        <li className={styles.tableHeader} style={mode ? { backgroundColor: "#000", color: "#fafafa" } : { backgroundColor: "#D9DCE5" }} >
                                                                            <div className={`${styles.col} `} style={{ width: "800px" }} >
                                                                                <div className={styles.TableHeadChackBox} >
                                                                                    <label className={styles.cl_checkbox} >
                                                                                        <input type="checkbox"
                                                                                            checked={selectAllUser}
                                                                                            onChange={(event) => { setsSlectAllUser((prev) => !prev); handelUpdate(event) }}
                                                                                        />
                                                                                        <span></span>
                                                                                    </label>
                                                                                </div>
                                                                                Dealer Name
                                                                            </div>
                                                                            <div className={`${styles.col}`} > <span>Address </span>  </div>
                                                                            <div className={styles.col} >State</div>
                                                                            <div className={styles.col} >District</div>
                                                                            <div className={styles.col} >City</div>
                                                                            <div className={styles.col} >PIN Code</div>
                                                                            <div className={styles.col} >Status</div>
                                                                            <div className={styles.col} >Joining Date</div>
                                                                        </li>
                                                                        <div style={{ height: "540px", overflowY: "scroll" }} ref={containerRef} >
                                                                            {loading.loading5 ? <> <Skeleton lines={10} thickness={7} /></> : <>
                                                                                {
                                                                                    findGroupDealers.length === 0 ? <> <div style={{ width: "100%", display: "flex" }}>
                                                                                        <NoDataFound
                                                                                            icon={<TemplateNotFound />}
                                                                                            textTitle={`Dealers Not Found`}

                                                                                        />
                                                                                    </div>
                                                                                    </> : <>
                                                                                        {
                                                                                            // Array.from({ length: 90 })?.map((e, i) =>
                                                                                            findGroupDealers?.map((e, i) =>
                                                                                                <li className={styles.tablerow}
                                                                                                    style={mode ? { backgroundColor: "#1B1D21", color: "#fafafa" } : { backgroundColor: "#ffff" }} key={`${i}B`}                                                                                >
                                                                                                    <div className={`${styles.col}`} >
                                                                                                        <div className={styles.TableHeadChackBox} >
                                                                                                            <label className={styles.cl_checkbox} >
                                                                                                                <input type="checkbox"
                                                                                                                    checked={e?.selectStatus === "selected"}
                                                                                                                    onChange={(event) => handleDealerSelection(event, e)}
                                                                                                                />
                                                                                                                <span></span>
                                                                                                            </label>
                                                                                                        </div>
                                                                                                        {truncateText(`${e?.dealerName}`, 15)}
                                                                                                    </div>
                                                                                                    <div className={`${styles.col} `} > {truncateText(`${e?.address}`, 25)} </div>
                                                                                                    <div className={styles.col} > {e?.state} </div>
                                                                                                    <div className={styles.col} > {e?.district} </div>
                                                                                                    <div className={styles.col} > {e?.city} </div>
                                                                                                    <div className={styles.col} > {e?.pincode} </div>
                                                                                                    <div className={styles.col} style={{ color: TextColorCode(e?.status) }}> {e?.status} </div>
                                                                                                    <div className={styles.col}  > {changeDateFormat(e?.createdAt, "-")} </div>
                                                                                                </li>
                                                                                            )
                                                                                        }
                                                                                    </>
                                                                                }
                                                                            </>
                                                                            }
                                                                        </div>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </TabsContent>
                                                        <TabsContent value="DealersGroup" activeTab={activeTab}>
                                                            <div className={styles.tableContiner} style={{ backgroundColor: mode ? "#232529" : "#fff", height: '100%' }} >
                                                                <div className={styles.container} style={{ height: "100%" }}>
                                                                    <ul className={styles.responsiveTable} style={{ height: "100%", }}>
                                                                        <li className={styles.tableHeader} style={mode ? { backgroundColor: "#000", color: "#fafafa" } : { backgroundColor: "#D9DCE5" }} >
                                                                            <div className={`${styles.col} `} style={{ width: "800px" }} >
                                                                                <div className={styles.TableHeadChackBox} >
                                                                                    {/* <label className={styles.cl_checkbox} >
                                                                                <input type="checkbox" />
                                                                                <span></span>
                                                                            </label> */}
                                                                                </div>
                                                                                Group Name </div>
                                                                            <div className={`${styles.col}`} > <span>Description </span>  </div>
                                                                            <div className={styles.col} >Member</div>
                                                                            <div className={styles.col} >Creation Date</div>
                                                                            <div className={styles.col} >Status</div>
                                                                        </li>
                                                                        <div style={{ height: "600px", overflowY: "scroll" }} ref={containerRef3} >
                                                                            {
                                                                                loading.loading1 ? <>
                                                                                    <Skeleton lines={10} thickness={7} />
                                                                                </> : groupData.length === 0 ? <>
                                                                                    <div style={{ width: "100%", display: "flex" }}>
                                                                                        <NoDataFound
                                                                                            icon={<TemplateNotFound />}
                                                                                            textTitle={`Group Not Found`} />
                                                                                    </div>
                                                                                </> :
                                                                                    groupData?.map((e, i) =>
                                                                                        // Array.from({length :40}).map((e,i) =>
                                                                                        <li className={styles.tablerow}
                                                                                            style={mode ? { backgroundColor: "#1B1D21", color: "#fafafa" } : { backgroundColor: "#ffff" }} key={`${i}B`}                                                                                >
                                                                                            <div className={`${styles.col}`}>
                                                                                                <div className={styles.TableHeadChackBox} >
                                                                                                    <label className={styles.cl_checkbox}>
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            onChange={(event) => handelChack(event, e.groupName)}
                                                                                                            checked={singleGroupSelect.includes(e.groupName)}
                                                                                                        />
                                                                                                        <span></span>
                                                                                                    </label>
                                                                                                </div>
                                                                                                {truncateText(`${e?.groupName}`, 15)}
                                                                                            </div>
                                                                                            <div className={`${styles.col} `} >  {truncateText(`${e?.description}`, 25)}</div>
                                                                                            <div className={styles.col}  > {e?.member}</div>
                                                                                            <div className={styles.col}  > {changeDateFormat(e?.createdAt, "-")}</div>
                                                                                            <div className={styles.col} style={{ color: TextColorCode(e?.status) }} >{e?.status} </div>
                                                                                        </li>
                                                                                    )
                                                                            }
                                                                        </div>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </TabsContent>
                                                    </Tabs>
                                                </div>
                                            </div>
                                        </AccordionContent>
                                    </AccordionItem>
                                    <AccordionItem value="item-3">
                                        <AccordionTrigger  >
                                            <div className={styles.AccordionTriggerContiner} style={{ backgroundColor: "#E2749933" }}  >
                                                <div className={styles.Accordioncolor} style={{ backgroundColor: "#E27499" }} />
                                                <div className={styles.AccordionText} >
                                                    <div>
                                                        <span className={styles.AccordionTextFrist} style={{ color: mode ? "#fff" : "#000", }}> What </span>
                                                    </div>
                                                    <div>
                                                        <span className={styles.AccordionTextSecand} style={{ color: mode ? "#fff" : "#000", }}> Create Message </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </AccordionTrigger>
                                        <AccordionContent>
                                            <div className={styles.Start_content_conintner}
                                                style={{ backgroundColor: mode ? "#1D1F23" : "#FFFFFF", position: "relative", minHeight: "500px" }}
                                            >
                                                <div className={styles.who_content_conintnerMain} style={{
                                                    backgroundColor: mode ? "#232529" : "#fff",
                                                    color: mode ? "#fff" : "#000",
                                                    border: mode ? "none" : "1px solid #E4E7EB",
                                                    padding: "10px",
                                                }}>
                                                    <div className={styles.whatMainConitner} >
                                                        <div className={styles.what_MessgeType} > <span> Message Type </span>
                                                        </div>
                                                        <div style={{ justifyContent: "flex-end", width: "100%", display: "flex" }} >
                                                            <div>
                                                                {/* <span onClick={() => Navigator('/templates/template')}
                                                            style={{ cursor: "pointer", color: "#4164E3", fontSize: "12px" }}
                                                        > Go to Template </span> */}
                                                            </div>
                                                        </div >
                                                    </div>
                                                </div>

                                                <div className={styles.redioBtnContiner} style={{ padding: "0px 10px " }} >
                                                    <div>
                                                        <InputRedio
                                                            id="Select"
                                                            lableText="Select Message"
                                                            name="hello"
                                                            value="Select"
                                                            required={true}
                                                        />
                                                    </div>
                                                    <div >
                                                        <InputRedio
                                                            id="Multiple"
                                                            lableText="Multiple Delivery"
                                                            name="hello"
                                                            value="Multiple"
                                                            required={true}

                                                        />
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className={styles.Who_Select1}
                                                        style={{
                                                            background: mode ? "#232529" : "#fff",
                                                            border: mode ? "none" : "1px solid #E4E7EB",
                                                            padding: "0px 20px"
                                                        }} onClick={() => setIsOpenSelectWhat(!isOpenSelectWhat)} ref={messageType} >
                                                        <div> <span style={{ fontSize: "14px", color: mode ? "#fff" : "#000" }}>
                                                            {truncateText(selectedOptionWhat && (selectedOptionWhat.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')), 20)}
                                                        </span>
                                                        </div>
                                                        <div> <span>  <AddBtnicon mode={mode} rotate={isOpenSelectWhat ? 180 : 0} />   </span> </div>
                                                        {isOpenSelectWhat &&
                                                            <div ref={templetRef} className={styles.Who_selectBoxWho} style={{
                                                                backgroundColor: mode ? "#2C2E33" : "#fff",
                                                                border: mode ? "none" : "1px solid #E4E7EB",
                                                                zIndex: "9999",
                                                                color: mode ? "#fff" : "#000",
                                                                maxHeight: "345px",
                                                                overflowY: "scroll",
                                                                lineHeight: "0",
                                                                // scrollbarWidth:"thin",
                                                                // scrollbarColor:"#F8F9FC #000"

                                                            }} /* ref={templetRef} */ >
                                                                {tempData
                                                                    .filter(e => e?.category !== "UTILITY")
                                                                    .map((e, i) =>
                                                                        <div className={mode ? styles.Who_selectBoxhover_dark : styles.Who_selectBoxhover}
                                                                            key={`${i}A`}
                                                                            onClick={() => {
                                                                                // setSelectedOptionWhat(e?.templateName);
                                                                                handehChackTempStaus(e)
                                                                                setempletData(e);
                                                                                setIsOpenSelectWhat(false);
                                                                            }}  >
                                                                            {truncateText(e?.templateName && (e?.templateName.split('_').map(word => word?.charAt(0).toUpperCase() + word?.slice(1)).join(' ')), 40)}
                                                                        </div>)}
                                                                {loading.loading2 ? <div> <Spinner size="20" /> </div> : null}
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </AccordionContent>
                                    </AccordionItem>
                                    <AccordionItem value="item-4">
                                        <AccordionTrigger>
                                            <div className={styles.AccordionTriggerContiner} style={{ backgroundColor: "#FFB44833" }}  >
                                                <div className={styles.Accordioncolor} style={{ backgroundColor: "#FFB448" }} />
                                                <div className={styles.AccordionText} >
                                                    <div>
                                                        <span className={styles.AccordionTextFrist} style={{ color: mode ? "#fff" : "#000", }}  > When </span>
                                                    </div>
                                                    <div>
                                                        <span className={styles.AccordionTextSecand} style={{ color: mode ? "#fff" : "#000", }}> Set Delivery Preferences </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </AccordionTrigger>
                                        <AccordionContent>
                                            <div className={styles.Start_content_conintner}
                                                style={{ backgroundColor: mode ? "#1D1F23" : "#FFFFFF", position: "relative", minHeight: "400px" }}
                                            >
                                                <div className={styles.Start_content_conintner_header} style={{ backgroundColor: mode ? "#232529" : "#D9DCE5" }} >
                                                    <div className={styles.headIcon} style={{ backgroundColor: "#FFB44833" }} >
                                                        <div style={{ backgroundColor: "#FFB448" }} ></div>
                                                    </div>
                                                    <div>
                                                        <span style={{ color: mode ? "#fff" : "#646B88", fontSize: "15px", fontWeight: "500" }}>Date and Time</span>
                                                    </div>
                                                </div>
                                                <div className={styles.redioBtnContiner1} style={{ backgroundColor: mode ? "#232529" : "#fff" }}  >
                                                    <div>
                                                        <InputRedio
                                                            id="Marketing"
                                                            lableText="Instant Sending"
                                                            name="hello"
                                                            value="Marketing"
                                                            onChange={() => setWhenRedio("Message")}
                                                            required={true}
                                                            checked={whenRedio === "Message" ? true : false}
                                                        />
                                                    </div>
                                                    <div>
                                                        <InputRedio
                                                            id="Marketing"
                                                            // lableText="Set as Recurring multiple"
                                                            lableText="Multiple Sending"
                                                            name="hello"
                                                            value="Marketing"
                                                            onChange={() => setWhenRedio("Recurring")}
                                                            required={true}
                                                            checked={whenRedio === "Recurring" ? true : false}
                                                        />
                                                    </div>
                                                    {
                                                        whenRedio === "Recurring" &&
                                                        <div style={{ width: "100%", display: "flex", flexDirection: "column", flexWrap: "wrap", }}>
                                                            {dateTimeInputs.map((input, index) => (
                                                                <div key={`${index}F`} style={{ display: "flex", alignItems: "", gap: "10px", marginBottom: "-30px" }}>
                                                                    <div style={{ display: "flex", flexDirection: "column", height: "80px", }}>
                                                                        <div>
                                                                            <InputTypedate
                                                                                value={input.date}
                                                                                onChange={(event) => handleDateChange(index, event)}
                                                                                min={today}
                                                                            />
                                                                        </div>
                                                                        <div>
                                                                            {input.error && !input.date && (
                                                                                <span style={{ color: "red", fontSize: "11px", fontWeight: "300" }}>Please select a date.</span>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ display: "flex", flexDirection: "column", height: "80px", }} >
                                                                        <div>
                                                                            <InputTypetime
                                                                                text="At"
                                                                                value={input.time}
                                                                                onChange={(event) => handleTimeChange(index, event)}
                                                                            />
                                                                        </div>
                                                                        <div>
                                                                            {input.error && !input.time && (
                                                                                <span style={{ color: "red", fontSize: "11px", fontWeight: "300" }}>Please select a time.</span>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <div style={{
                                                                            height: "50%",
                                                                            display: "flex",
                                                                            alignItems: "center",
                                                                            justifyContent: "center",
                                                                            cursor: "pointer"
                                                                        }} onClick={() => handleRemoveInput(index)} >
                                                                            <RemoveIcon />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                            {dateTimeInputs.length > 3 ? null :
                                                                <div style={{ width: "100px" }}>
                                                                    <Button
                                                                        buttonBgColor={"#4164E3"}
                                                                        buttonText={" + "}
                                                                        buttonTextColor={"#fff"}
                                                                        buttonClick={addDateTimeInput}

                                                                    />
                                                                </div>
                                                            }
                                                        </div>
                                                    }
                                                    {/* <div style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
                                                <div style={{ width: "170px" }}>
                                                    <Button
                                                        buttonBgColor={"#4164E3"}
                                                        buttonText={"Reset"}
                                                        buttonTextColor={"#fff"}
                                                        buttonClick={() => {
                                                            setWhenRedio("");
                                                            setDateTimeInputs([{ date: '', time: '', error: false }]);
                                                        }}
                                                    />
                                                </div>
                                            </div> */}
                                                </div>
                                            </div>
                                        </AccordionContent>
                                    </AccordionItem>
                                    <AccordionItem value="item-5">
                                        <AccordionTrigger pop={true} >
                                            <div className={styles.AccordionTriggerContiner} style={{ backgroundColor: "#7698FF33" }}  >
                                                <div className={styles.Accordioncolor} style={{ backgroundColor: "#7698FF" }} />
                                                <div className={styles.AccordionText} >
                                                    <div>
                                                        <span className={styles.AccordionTextFrist} style={{ color: mode ? "#fff" : "#000", }} > Publish Campaign </span>
                                                    </div>
                                                </div>
                                                <div style={{ width: "85%", display: "flex", justifyContent: "flex-end" }}>
                                                    <div style={{ width: "180px" }} >
                                                        <Button
                                                            buttonBgColor={"#4164E3"}
                                                            buttonText={"Publish"}
                                                            buttonIcon={<TemplateDone />}
                                                            buttonTextColor={"#fff"}
                                                            buttonClick={handleSubmit}
                                                            loading={loading.loading7}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </AccordionTrigger>
                                    </AccordionItem>
                                </Accordion>
                            </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default CreateCampaign


