import React, { useState } from 'react'
import ProductEditDelete from './ProductEditDelete'
import VoucherEditDelete from './VoucherEditDelete'

const EditDelete = () => {
    const [switchBtn, setSwitchBtn] = useState(0)
    return (
        <React.Fragment>
            <div className='w-100 m-3'>
                <div className='w-100 mb-3'>
                    <div className="bg-white rounded-pill p-2 text-center w_fit mx-auto">
                        <button className={`${switchBtn === 0 ? "omBtnClickClr" : "bg-transparent"} omBtnWidth p-2 rounded-pill border-0 btnMKD`} onClick={() => setSwitchBtn(0)}>Vouchers</button>
                        <button className={`${switchBtn === 1 ? "omBtnClickClr" : "bg-transparent"} omBtnWidth p-2 rounded-pill border-0 btnMKD`} onClick={() => setSwitchBtn(1)}>Products</button>
                    </div>
                </div>

                {switchBtn === 0 && <VoucherEditDelete />}
                {switchBtn === 1 && <ProductEditDelete />}
            </div>
        </React.Fragment>
    )
}

export default EditDelete