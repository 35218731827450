import { useAuth } from "../../context/Auth";
import { NavbarArrowDownDarkIcon, NavbarArrowDownIcon } from "../../icons/Icon";
import styles from "./FromComponent.module.css";
import { useState, useEffect, useRef } from "react";

const VerticalDropDown = ({
  label,
  options,
  dropDownText,
  star,
  value,
  multiple = false,
  onchange,
  height,
  fontSize,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState(multiple ? [] : null);
  const dropdownRef = useRef(null);

  const handleOptionSelect = (option) => {
    if (multiple) {
      setSelectedOptions((prevSelected) => {
        const newSelection = prevSelected.includes(option)
          ? prevSelected.filter((item) => item !== option)
          : [...prevSelected, option];
        const uniqueSelection = Array.from(
          new Set(newSelection?.map((o) => o.value))
        )?.map((value) => newSelection.find((o) => o.value === value));

        setTimeout(() => {
          onchange(uniqueSelection);
        }, 0);

        return uniqueSelection;
      });
      setIsOpen(false);
    } else {
      setTimeout(() => {
        onchange(option);
      }, 0);
      setIsOpen(false);
    }
  };

  const handleItemRemove = (option) => {
    const newSelection = selectedOptions.filter((item) => item !== option);
    setSelectedOptions(newSelection);
    onchange(newSelection);
  };

  const { mode } = useAuth();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  useEffect(() => {
    setSelectedOptions(value || (multiple ? [] : null));
  }, [value, multiple]);

  return (
    <div className={styles.vertical_dropdown_container} ref={dropdownRef}>
      <label
        className={styles.vertical_dropdown_label}
        style={mode ? { color: "#fff" } : {}}
      >
        {label}
        {star && <span style={{ color: "red" }}>*</span>}
      </label>
      <div
        className={styles.vertical_custom_select}
        style={mode ? { border: "none" } : {}}
      >
        <div
          className={styles.vertical_dropdown_trigger}
          style={{
            height: height && height,
            fontSize: fontSize && fontSize,
            backgroundColor: mode && "#1b1d21",
            borderColor: "#1b1d21",
          }}
          onClick={() => setIsOpen(!isOpen)}
        >
          <div
            className={styles.vertical_dropdown_placeholder}
            style={{ color: mode ? "#fff" : "#646b88" }}
          >
            {!multiple &&
              (value ? (
                value.label
              ) : (
                <span
                  style={{
                    fontSize: "14px",
                    fontWeight: "400",
                    color: mode && "#9C9797",
                  }}
                >
                  {dropDownText}
                </span>
              ))}
            {multiple &&
              (value ? (
                <span
                  style={{
                    fontSize: "14px",
                    fontWeight: "400",
                    color: mode && "#9C9797",
                  }}
                >
                  Select States
                </span>
              ) : (
                <span
                  style={{
                    fontSize: "14px",
                    fontWeight: "400",
                    color: mode && "#9C9797",
                  }}
                >
                  {dropDownText}
                </span>
              ))}
            {mode ? <NavbarArrowDownDarkIcon /> : <NavbarArrowDownIcon />}
          </div>
        </div>
        {isOpen && (
          <ul
            className={styles.vertical_dropdown_list}
            style={
              mode ? { backgroundColor: "#232529", borderColor: "#232529" } : {}
            }
          >
            {options?.map((option, index) => (
              <li
                key={index}
                className={`${styles.vertical_dropdown_item} ${
                  mode ? styles.vertical_dropdown_item_dark : ""
                }`}
                onClick={() => handleOptionSelect(option)}
                style={{ fontSize: fontSize && fontSize }}
              >
                {option.label}
              </li>
            ))}
          </ul>
        )}
      </div>
      {multiple && (
        <div className={styles.selected_items}>
          {selectedOptions.length > 0 ? (
            selectedOptions?.map((opt, index) => (
              <span
                key={index}
                className={styles.selected_item}
                onClick={() => handleItemRemove(opt)}
              >
                {opt.value}
                <span className={styles.remove_item}>&times;</span>{" "}
              </span>
            ))
          ) : (
            <span
              className={styles.placeholder_text}
              style={mode ? { color: "#fff" } : {}}
            >
              No State Selected{" "}
            </span>
          )}
        </div>
      )}
    </div>
  );
};

export default VerticalDropDown;
