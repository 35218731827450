import React from 'react';
import styles from '../globalwhatsappchat.module.css';
import { useAuth } from '../../../context/Auth';

const Wrapper = ({children}) => {
  const { mode } = useAuth(); // Assuming 'mode' is a boolean where 'true' means dark mode
  return (
    <div className={`${styles.wrapper} ${mode ? styles.darkMode : styles.lightMode}`}>
      <div className={`${styles.whatsappMessage_main} ${mode ? styles.maindarkMode : styles.mainlightMode}` }>
      {children}

  

      </div>
    </div>
  );
};

export default Wrapper;


