// import Pagination from "../../components/Paginantion";
// import { useAuth } from "../../components/context/Auth";
// import React, { useEffect, useState } from "react";
// import styles from "../dispatchmanager/DishpatchManager.module.css";
// import { useNavigate, useSearchParams } from "react-router-dom";
// import OrderModal from "./OrderModal";
// import { GreenButton, RedOutButton } from "../../components/Buttons";
// import Modal from "../../components/Modal";
// import OrdersTab from "../../components/OrdersTab";

// const AllOrders = () => {
//   const navigate = useNavigate();
//   const [searchparams] = useSearchParams();
//   const page = searchparams.get("page")
//     ? parseInt(searchparams.get("page"))
//     : 1;
//   const { switchDisplay, managerId } = useAuth();
//   const [msgShow, setMsgShow] = useState({
//     status: "",
//     msg: "",
//   });
//   const [orders, setOrders] = useState("");
//   const [allData, setAllData] = useState([]);
//   const [showItemModal, setShowItemModal] = useState(false);
//   const [showAcceptModal, setShowAcceptModal] = useState(false);
//   const [orderData, setOrderData] = useState({});
//   const [pages, setPages] = useState(1);
//   const [loading, setLoading] = useState(false);
//   const [sortKey, setSortKey] = useState("");
//   const [orderStatus, setOrderStatus] = useState("");
//   const [orderType, setOrderType] = useState("");
//   const [keyword, setKeyword] = useState("");
//   const [warehouses, setWarehouses] = useState([]);
//   const [warehouseName, setWarehouseName] = useState("");
//   const [managers, setManagers] = useState([]);
//   const [managerName, setManagerName] = useState("");
//   const [daysTab, setDaysTab] = useState("today");
//   const [startDate, setStartDate] = useState("");
//   const [endDate, setEndDate] = useState("");

//   useEffect(() => {
//     if (managerId) {
//       setLoading(true);
//       var myHeaders = new Headers();
//       myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
//       myHeaders.append("Content-Type", "application/json");

//       var raw = JSON.stringify({
//         managerId: parseInt(managerId),
//         keyword: keyword,
//         sortKey: sortKey,
//         orderType: orderType,
//         orderStatus: orderStatus,
//         cartType: "",
//         warehousename: warehouseName,
//         ordermanagerId: managerName,
//         day: daysTab,
//         startDate: startDate,
//         endDate: endDate,
//       });

//       var requestOptions = {
//         method: "POST",
//         headers: myHeaders,
//         body: raw,
//         redirect: "follow",
//       };
//       // fetch('http://192.168.0.117:5055/order/orders?page=' + page + '&limit=8', requestOptions)
//       fetch(
//         process.env.REACT_APP_URL + "order/allorders?page=" + page + "&limit=8",
//         requestOptions
//       )
//         .then((response) => response.json())
//         .then((result) => {
//           if (result.status === "success") {
//             setLoading(false);
//             setPages(result.pages);
//             setAllData(result.data);
//             setOrders(result);
//             if (result.warehouses) {
//               setWarehouses(result.warehouses);
//             }
//             if (result.managers) {
//               setManagers(result.managers);
//             }
//           }
//         })
//         .catch((error) => console.log("error", error));
//     }
//   }, [
//     managerId,
//     page,
//     sortKey,
//     orderType,
//     keyword,
//     warehouseName,
//     managerName,
//     daysTab,
//     endDate,
//     startDate,
//     orderStatus,
//   ]);

//   const handleOrderAccept = (orderId) => {
//     setMsgShow({
//       status: "",
//       msg: "",
//     });
//     var myHeaders = new Headers();
//     myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
//     myHeaders.append("Content-Type", "application/json");

//     var raw = JSON.stringify({
//       managerId: managerId,
//       orderId: orderId,
//     });

//     var requestOptions = {
//       method: "POST",
//       headers: myHeaders,
//       body: raw,
//       redirect: "follow",
//     };

//     fetch(process.env.REACT_APP_URL + "order/acceptorder", requestOptions)
//       .then((response) => response.json())
//       .then((result) => {
//         if (result.status === "success") {
//           setShowItemModal(false);
//         }
//         setMsgShow({
//           status: result.status,
//           msg: result.msg,
//         });
//         setShowAcceptModal(true);
//       })
//       .catch((error) => console.log("error", error));
//   };

//   return (
//     <React.Fragment>
//       <div
//         className={`${!switchDisplay ? styles.bg_dark : styles.bg_white} ${
//           styles.ff
//         }`}
//       >
//         <OrdersTab
//           styles={styles}
//           orders={orders}
//           sortKey={sortKey}
//           setSortKey={setSortKey}
//           loading={loading}
//           orderType={orderType}
//           setOrderType={setOrderType}
//           keyword={keyword}
//           setKeyword={setKeyword}
//           warehouses={warehouses}
//           warehouseName={warehouseName}
//           setWarehouseName={setWarehouseName}
//           managers={managers}
//           setManagers={setManagers}
//           managerName={managerName}
//           setManagerName={setManagerName}
//           daysTab={daysTab}
//           setDaysTab={setDaysTab}
//           endDate={endDate}
//           setEndDate={setEndDate}
//           startDate={startDate}
//           setStartDate={setStartDate}
//           setOrderStatus={setOrderStatus}
//           orderStatus={orderStatus}
//         />
//         {loading ? (
//           <div className={"order_loadingMain"}>
//             <img src="/wallicon.gif" alt="walliconGIF" />
//           </div>
//         ) : (
//           <>
//             <div className={styles.order_heightFix_div}>
//               <table className={styles.itemTable}>
//                 <thead>
//                   <tr className={styles.item}>
//                     <th> Name & City </th>
//                     <th>Cart Type</th>
//                     <th>Date</th>
//                     <th>Order Id</th>
//                     <th>Order Manager</th>
//                     <th>Source</th>
//                     <th>Amount</th>
//                     <th>Status</th>
//                     <th>Last Action</th>
//                   </tr>
//                 </thead>

//                 <tbody>
//                   {allData &&
//                     allData.map((val, index) => (
//                       <tr
//                         key={index}
//                         className={styles.item}
//                         onClick={() => {
//                           if (val.status !== "New Order") {
//                             navigate(
//                               "/orders/saleorder?orderId=" + val.orderId
//                             );
//                           } else {
//                             setShowItemModal(true);
//                             setOrderData(val);
//                           }
//                         }}
//                       >
//                         <td>{val.storeName + ", " + val.city}</td>
//                         <td>{val.cartType}</td>
//                         <td>{val.orderDate}</td>
//                         <td>{val.orderId}</td>
//                         <td>{val.ordermanager}</td>
//                         <td>{val.orderType}</td>
//                         <td>{val.totalAmount}</td>
//                         <td
//                           className={`${
//                             val.status === "ordered" || val.status === "Packed"
//                               ? styles.table_txt_green
//                               : val.status === "Not Packed" ||
//                                 val.status === "ordered Pending" ||
//                                 val.status === "Payment Under Verification" ||
//                                 val.status === "Request For Edit" ||
//                                 val.status === "Edit request Approved" ||
//                                 val.status === "Request For Cancel"
//                               ? styles.table_txt_red
//                               : val.status === "Packing In Progress" ||
//                                 val.status === "Draft" ||
//                                 val.status === "In Progress" ||
//                                 val.status === "Payment Pending"
//                               ? styles.table_txt_yellow
//                               : val.status === "New Order"
//                               ? styles.table_txt_blue
//                               : ""
//                           }`}
//                         >
//                           {val.status}
//                         </td>
//                         {/* <td className={`${val.status === 'In Progress' ? styles.table_txt_yellow : val.status === 'Updated' ? styles.table_txt_green : styles.table_txt_red}`}>{val.status} </td> */}
//                         <td>{val.lastAction} </td>
//                       </tr>
//                     ))}
//                 </tbody>
//               </table>
//             </div>
//             <Pagination total={pages} current={page} />
//           </>
//         )}
//         <OrderModal
//           show={showItemModal}
//           close={setShowItemModal}
//           closeBtn={true}
//           heading={orderData.storeName}
//           content={
//             <div className={styles.text_center}>
//               <div className={styles.product_date}>
//                 <span>Dealer ID: {orderData.dealerId}</span>
//                 <span> {orderData.orderDate}</span>
//               </div>
//               <div className={styles.product_date}>
//                 Order ID: {orderData.orderId}
//               </div>

//               <div className={styles.itemCard_div}>
//                 {orderData.items &&
//                   orderData.items.map((item, index) => (
//                     <div
//                       key={index}
//                       className={
//                         switchDisplay ? styles.itemCard_light : styles.itemCard
//                       }
//                     >
//                       <div className={styles.itemCard_left}>
//                         <img
//                           src={process.env.REACT_APP_S3URL + item.thumbnail}
//                           alt="item_Image"
//                           className={styles.itemImage}
//                         />
//                         <div className={styles.itemDetail_div}>
//                           <div className={styles.itemDetail}>
//                             <span className={styles.itemName_txt}>
//                               Product:
//                             </span>
//                             <span className={styles.itemName}>
//                               {item.product_name}
//                             </span>
//                           </div>
//                           <div className={styles.itemDetail}>
//                             <span className={styles.itemName_txt}>
//                               Item No:
//                             </span>
//                             <span className={styles.itemName}>
//                               {item.itemNo}
//                             </span>
//                           </div>
//                           <div className={styles.itemDetail}>
//                             <span className={styles.itemName_txt}>Price:</span>
//                             <span className={styles.itemName}>
//                               {parseFloat(item.price).toLocaleString("en-IN", {
//                                 style: "currency",
//                                 currency: "INR",
//                               })}
//                               /{item.unit}
//                             </span>
//                           </div>
//                           <div className={styles.itemDetail}>
//                             <span className={styles.itemName_txt}>Qty:</span>
//                             <span className={styles.itemName}>
//                               {item.qty} {item.unit}
//                             </span>
//                           </div>
//                         </div>
//                       </div>
//                       <div className={styles.itemCard_right}>
//                         <span className={styles.itemName_txt}>Total:</span>
//                         <span className={styles.itemName}>
//                           &nbsp;
//                           {(
//                             parseFloat(item.price) * parseFloat(item.qty)
//                           ).toLocaleString("en-IN", {
//                             style: "currency",
//                             currency: "INR",
//                           })}
//                           /-
//                         </span>
//                       </div>
//                     </div>
//                   ))}
//               </div>

//               <div
//                 className={
//                   switchDisplay
//                     ? styles.modalFooter_div_light
//                     : styles.modalFooter_div
//                 }
//               >
//                 <div className={styles.modalFooter_btn}>
//                   <RedOutButton
//                     title="CANCEL"
//                     handleSubmit={() => setShowItemModal(false)}
//                   />
//                   <GreenButton
//                     title="ACCEPT"
//                     handleSubmit={() => handleOrderAccept(orderData.orderId)}
//                   />
//                 </div>
//                 <div className={styles.modalFooter_GTotal}>
//                   <div className={styles.borderBottom_div}>
//                     <div className={styles.total_div}>
//                       <span className={styles.itemName_txt}>Total</span>
//                       <span className={styles.total_rs}>
//                         {parseFloat(orderData.subtotal).toLocaleString(
//                           "en-IN",
//                           { style: "currency", currency: "INR" }
//                         )}
//                       </span>
//                     </div>
//                     <div className={styles.total_div}>
//                       <span className={styles.itemName_txt}>
//                         Shipping Charges
//                       </span>
//                       <span className={styles.total_rs}>
//                         {parseFloat(
//                           orderData.shippingInfo &&
//                             orderData.shippingInfo.shippingCharge
//                         ).toLocaleString("en-IN", {
//                           style: "currency",
//                           currency: "INR",
//                         })}
//                       </span>
//                     </div>
//                   </div>
//                   <div className={styles.grandTotal}>
//                     <span>Grand Total:</span>
//                     <span>
//                       {parseFloat(orderData.totalAmount).toLocaleString(
//                         "en-IN",
//                         { style: "currency", currency: "INR" }
//                       )}
//                     </span>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           }
//         />

//         <Modal
//           show={showAcceptModal}
//           close={setShowAcceptModal}
//           closeBtn={true}
//           heading="Order Status"
//           content={
//             <div className={styles.text_center}>
//               <p className={styles.message}>{msgShow.msg}</p>
//               <div className={styles.massageModal_btn}>
//                 <RedOutButton
//                   title="CANCEL"
//                   handleSubmit={() => setShowAcceptModal(false)}
//                 />
//                 <GreenButton
//                   title="OK"
//                   handleSubmit={() => {
//                     if (msgShow.status === "success") {
//                       navigate(
//                         "/orders/saleorder?orderId=" + orderData.orderId
//                       );
//                       setShowAcceptModal(false);
//                     } else {
//                       setShowAcceptModal(false);
//                     }
//                   }}
//                 />
//               </div>
//             </div>
//           }
//         />
//       </div>
//     </React.Fragment>
//   );
// };
// export default AllOrders;

import styles from "./Orders.module.css";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAuth } from "../../components/context/Auth";
import {
  TestTopBackArrowIcon,
  TestTopDotIcon,
  DispatchReloadIcon,
  DispatchDownloadIcon,
  CreateVoucherIcon,
} from "../../components/icons/Icon";
import InputTypedate from "../../components/updatedComponents/FormComponents/InputTypedate";
import SlidingFilter from "../../components/updatedComponents/Dealers/SlidingFilter";
import Button from "../../components/updatedComponents/Dealers/Button";
import DealerStatusCard from "../../components/updatedComponents/Dealers/DealerStatusCard";
import DropDown from "../../components/updatedComponents/FormComponents/DropDown";
import SearchBar from "../../components/updatedComponents/FormComponents/SearchBar";
import { useCallback, useEffect, useState } from "react";
import Spinner from "../../components/updatedComponents/FormComponents/Spinner";

const AllOrders = () => {
  const { mode, managerId } = useAuth();
  const navigate = useNavigate();
  const [searchparams] = useSearchParams();

  const page = searchparams.get("page")
    ? parseInt(searchparams.get("page"))
    : 1;

  const [orders, setOrders] = useState([]);
  const [allData, setAllData] = useState([]);
  const [warehouses, setWarehouses] = useState([]);
  const [selectedWarehouse, setSelectedWarehouse] = useState("");
  const [managers, setManagers] = useState([]);
  const [selectedManagerID, setSelectedManagerID] = useState("");
  const [daysTab, setDaysTab] = useState("today");
  const [orderStatus, setOrderStatus] = useState("All");
  const [keyword, setKeyword] = useState("");
  const [debouncedKeyword, setDebouncedKeyword] = useState(keyword);
  const [loading, setLoading] = useState(false);

  const getOrderData = useCallback(async () => {
    try {
      setLoading(true);
      const myHeaders = new Headers({
        "wa-platform": process.env.REACT_APP_ADMIN_SECRET,
        "Content-Type": "application/json",
      });

      const requestBody = {
        managerId: parseInt(managerId),
        day: daysTab,
        orderStatus: orderStatus,
        warehousename: selectedWarehouse,
        ordermanagerId: selectedManagerID,
        keyword: debouncedKeyword,
      };

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(requestBody),
        redirect: "follow",
      };

      const response = await fetch(
        `${process.env.REACT_APP_URL}order/allorders?page=${page}&limit=8`,
        requestOptions
      );

      const result = await response.json();

      if (result.status === "success") {
        setAllData(result.data);

        if (result.orders) {
          const formattedOrders = result.orders.map((s) => ({
            statusText: s.status,
            statusCount: s.count,
          }));
          setOrders(formattedOrders);
        }

        if (result.warehouses) {
          const formattedWarehouses = result.warehouses.map((w) => ({
            label: w.warehousename,
            value: w._id,
          }));
          setWarehouses(formattedWarehouses);
        }

        if (result.managers) {
          const formattedManagers = result.managers.map((m) => ({
            label: m.name,
            value: m.managerId,
          }));
          setManagers(formattedManagers);
        }
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching order data:", error);
      setLoading(false);
    }
  }, [
    daysTab,
    managerId,
    page,
    orderStatus,
    selectedWarehouse,
    selectedManagerID,
    debouncedKeyword,
  ]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedKeyword(keyword);
    }, 700);

    return () => clearTimeout(timer);
  }, [keyword]);

  useEffect(() => {
    if (managerId) {
      getOrderData();
    }
  }, [
    daysTab,
    managerId,
    page,
    orderStatus,
    selectedWarehouse,
    selectedManagerID,
    debouncedKeyword,
    getOrderData,
  ]);

  const getStatusColor = (status) => {
    switch (status) {
      case "ordered":
      case "Packed":
        return "green";

      case "Not Packed":
      case "ordered Pending":
      case "Payment Under Verification":
      case "Request For Edit":
      case "Edit request Approved":
      case "Request For Cancel":
        return "red";

      case "Packing In Progress":
      case "Draft":
      case "In Progress":
      case "Payment Pending":
        return "red";

      case "New Order":
        return "blue";

      default:
        return "";
    }
  };
  const handleSF1Change = (label) => {
    const selectedFilter = slidingFilter1.find((item) => item.label === label);
    if (selectedFilter) {
      setDaysTab(selectedFilter.value);
    } else {
      selectedFilter("today");
    }
  };
  const handleWarehouseSelection = (option) => {
    setSelectedWarehouse(option.label);
  };
  const handleWarehouseRejection = () => {
    setSelectedWarehouse(null);
  };
  const handleManagerSelection = (option) => {
    setSelectedManagerID(option.value);
  };
  const handleManagerRejection = () => {
    setSelectedManagerID(null);
  };
  const routeName = "order";
  const slidingFilter1 = [
    {
      value: "today",
      label: "Today",
    },
    {
      value: "yesterday",
      label: "Yesterday",
    },
    {
      value: "month",
      label: "This Month",
    },
    {
      value: "previousyear",
      label: "Previous Year",
    },
  ];
  const slidingFilter2 = [
    "Order",
    "Purchase",
    "Adjustment",
    "Transfer",
    "Production",
    "Production & Sale",
    "Production & Transfer",
  ];
  const orderHeader = [
    "Name",
    "Order ID",
    "Order Manager",
    "Source",
    "Amount",
    "Payment Status",
    "Last Action",
  ];
  return (
    <div
      className={styles.order}
      style={mode ? { backgroundColor: "#2C2E33", border: "none" } : {}}
    >
      <div className={styles.order_top}>
        <div className={styles.order_top_left}>
          <div
            className={styles.order_top_left_go_back}
            onClick={() => navigate(-1)}
          >
            <TestTopBackArrowIcon color={mode ? "#ffffff" : "#0100E4"} />
            <p
              className={styles.order_go_back_text}
              style={mode ? { color: "#ffffff" } : {}}
            >
              Go Back
            </p>
          </div>
          <TestTopDotIcon color={mode ? "#E4E7EB" : "#646B88"} />
          <p
            className={styles.order_top_left_path_text}
            style={{
              color: mode ? "#9C9797" : "#646B88",
            }}
          >
            {routeName}
          </p>
        </div>
        <div className={styles.order_right}>
          <div>
            <SlidingFilter
              itemList={slidingFilter1.map((item) => item.label)}
              setFilter={handleSF1Change}
            />
          </div>
          <div>
            <InputTypedate text={"From"} />
          </div>
          <div>
            <InputTypedate text={"To"} />
          </div>
          <div>
            <Button
              buttonIcon={<DispatchReloadIcon />}
              buttonBgColor={"#000000"}
              buttonTextColor={"#ffffff"}
              buttonBorder={"none"}
              buttonClick={() => window.location.reload()}
            />
          </div>
          <div>
            <Button
              buttonIcon={<DispatchDownloadIcon />}
              buttonBgColor={"#000000"}
              buttonTextColor={"#ffffff"}
              buttonBorder={"none"}
            />
          </div>
          <div>
            <Button
              buttonIcon={<CreateVoucherIcon />}
              buttonText={"Create Voucher"}
              buttonBgColor={"#4164E3"}
              buttonTextColor={"#ffffff"}
              buttonBorder={"none"}
              buttonClick={() => {
                navigate("/orders/estimate");
              }}
            />
          </div>
        </div>
      </div>

      <div
        className={styles.order_filters}
        style={
          mode ? { borderColor: "#232529", backgroundColor: "#232529" } : {}
        }
      >
        {loading ? (
          <div style={{ margin: "0 auto" }}>
            <Spinner height={80} width={80} />
          </div>
        ) : (
          <>
            {orders?.map((s) => (
              <div
                key={s.statusText}
                className={`${styles.order_filter} ${
                  mode ? styles.order_filter_dark : ""
                } ${
                  s.statusText === orderStatus ? styles.order_filter_active : ""
                }`}
                onClick={() => {
                  setOrderStatus(s.statusText);
                }}
              >
                <DealerStatusCard
                  statusText={s.statusText}
                  statusItemCount={s.statusCount}
                />
              </div>
            ))}
          </>
        )}
      </div>

      <div
        className={styles.order_data}
        style={mode ? { backgroundColor: "transparent" } : {}}
      >
        <div className={styles.order_data_filters}>
          <div>
            <SearchBar
              placeholder={"Search by Order ID"}
              value={keyword}
              onChange={(e) => setKeyword(e.target.value)}
            />
            {/* <SlidingFilter itemList={slidingFilter2} /> */}
          </div>

          <div>
            <DropDown
              label={"Voucher"}
              listLabel={"Order"}
              options={slidingFilter2.map((d) => ({
                label: d,
                value: d,
              }))}
            />
            <DropDown
              label={"Warehouse"}
              listLabel={"All"}
              options={warehouses}
              onSelect={handleWarehouseSelection}
              onReject={handleWarehouseRejection}
            />
            <DropDown
              label={"Manager"}
              listLabel={"All"}
              options={managers}
              onSelect={handleManagerSelection}
              onReject={handleManagerRejection}
            />
            {/* <DropDown
              label={"Sort By"}
              listLabel={"View All"}
              options={orders.map((o) => ({
                label: o.statusText,
                value: o.statusText,
              }))}
            /> */}
          </div>
        </div>

        <ul className={styles.order_data_list}>
          <li
            className={`${styles.order_data_list_row_header} ${
              mode ? styles.order_data_list_row_header_dark : ""
            }`}
          >
            {orderHeader.map((h) => (
              <div className="col" key={h}>
                {h}
              </div>
            ))}
          </li>
          <div className={styles.order_data_list_container}>
            {loading ? (
              <div style={{ margin: "1rem auto" }}>
                <Spinner height={80} width={80} />
              </div>
            ) : (
              <>
                {allData.length === 0 ? (
                  <img
                    src="/assets/updatedAssets/noDATA.png"
                    alt="img"
                    style={{
                      height: "12rem",
                      width: "16rem",
                      margin: "1rem auto",
                    }}
                  />
                ) : (
                  <>
                    {allData &&
                      allData.map((d, index) => (
                        <li
                          key={index}
                          className={`${styles.order_data_list_row} ${
                            mode ? styles.order_data_list_row_dark : {}
                          }}`}
                          onClick={() => {
                            if (d.status !== "New Order") {
                              navigate(
                                "/orders/saleorder?orderId=" + d.orderId
                              );
                            }
                          }}
                        >
                          <div className="col">
                            {d.storeName + ", " + d.city}
                          </div>
                          <div className="col">{d.orderId}</div>
                          <div className="col">{d.ordermanager}</div>
                          <div className="col">{d.orderType}</div>
                          <div className="col">{d.totalAmount}</div>
                          <div
                            className="col"
                            style={{ color: getStatusColor(d.status) }}
                          >
                            {d.status}
                          </div>
                          <div className="col">{d.lastAction}</div>
                        </li>
                      ))}
                  </>
                )}
              </>
            )}
          </div>
        </ul>
      </div>
    </div>
  );
};

export default AllOrders;
