import React, { useEffect, useRef, useState } from "react";
import styles from "./Orders.module.css";
import {
  TestTopBackArrowIcon,
  TestTopDotIcon,
  ChatIcon,
  RemoveIcon,
  DividerIcon,
  SaveAsDraftIcon,
  TestTopAddDealerIcon,
  RightArrowIcon,
  LeftArrowIcon,
  SideBarOrdersIcon,
  ShareIcon,
} from "../../components/icons/Icon";
import { useAuth } from "../../components/context/Auth";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import SlidingFilter from "../../components/updatedComponents/Dealers/SlidingFilter";
import InputTag from "../../components/updatedComponents/FormComponents/InputTag";
import VerticalDropDown from "../../components/updatedComponents/FormComponents/VerticalDropDown";
import Button from "../../components/updatedComponents/Dealers/Button";
import TextElement from "../../components/updatedComponents/FormComponents/TextElement";
import ModalWrapper from "../../components/updatedComponents/layoutWrapper/ModalWrapper";
import ShareLinkModal from "../../components/updatedComponents/OrderManager/ShareLinkModal";
import AddNewItemModal from "../../components/updatedComponents/OrderManager/AddNewItemModal";
import AddReceiptModal from "../../components/updatedComponents/OrderManager/AddReceiptModal";
import fetchData from "../../APIsControll/apiControll";
import SearchDealer from "../../components/updatedComponents/OrderManager/SearchDealer";
import ItemList from "../../components/updatedComponents/OrderManager/ItemList";
import NewItemList from "../../components/updatedComponents/OrderManager/NewItemList";
import AdduserModel from "../../components/updatedComponents/models/AdduserModel";

const SaleOrder = () => {
  const { mode, managerId } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const orderId = searchParams.get("orderId")
    ? searchParams.get("orderId")
    : "";
  const [loading, setLoading] = useState(false);
  const [shippingInfo, setShippingInfo] = useState("");
  const [generalInfo, setGeneralInfo] = useState("");
  const [reciptsInfo, setReciptsInfo] = useState([]);
  const [helpData, setHelpData] = useState("");
  // const [reload, setReload] = useState(false);
  const [warehouses, setWarehouses] = useState([]);
  const [dispatchManagers, setDispatchManagers] = useState([]);
  const [accounts, setAccounts] = useState([]);
  // const [newItems, setNewItems] = useState([]);
  const [modalContent, setModalContent] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  // dealer search
  const [showDealerSearch, setShowDealerSearch] = useState(false);
  const [searchDealer, setSearchDealer] = useState("");
  const [defaultDealer, setDefaultDealer] = useState(false);
  // item search
  const [showItemSearch, setShowItemSearch] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [itemsdetails, setItemsdetails] = useState([
    {
      itemNo: "",
      product_name: "",
      collection_name: "",
      warehouse: "",
      qty: "",
      dp: "",
      unit: "",
      amount: "",
      totalQty: "",
      desc: "",
      descKey: "",
    },
  ]);

  const searchDealerRef = useRef(null);
  const newItemListRefs = useRef([]);

  let isNew = false;
  if (location.search === "") {
    isNew = true;
  } else {
    isNew = false;
  }

  useEffect(() => {
    if (itemsdetails.length > 1) {
      if (newItemListRefs.current.length) {
        const lastItemRef =
          newItemListRefs.current[newItemListRefs.current.length - 1];
        lastItemRef?.focus();
      }
    }
  }, [itemsdetails]);

  useEffect(() => {
    if (managerId) {
      const fetchOrder = async () => {
        setLoading(true);
        try {
          const apiData = {
            managerId: managerId,
            orderId: orderId,
            warehousename: "",
          };
          const result = await fetchData("order/order", apiData);
          if (result.status === "success" && result.data.warehouses) {
            setWarehouses(result.data["warehouses"]);
          }

          if (
            result.status === "success" &&
            result.data.order &&
            result.data.warehouses
          ) {
            setGeneralInfo((prevState) => ({
              ...prevState,
              dealerId: result.data.order.dealerId,
              storeName: result.data.order.storeName,
              dealerName: result.data.order.name,
              billingAddress: result.data.order.billingAddress,
              shippingType: result.data.order.shippingInfo.shippingType,
              shippingCharge: result.data.order.shippingInfo.shippingCharge,
              courierName: result.data.order.shippingInfo.courierName,
              partnerName: result.data.order.shippingInfo.methodName
                ? result.data.order.shippingInfo.methodName
                : result.data.order.shippingInfo.partnerName,
              deliveryPartner: result.data.order.shippingInfo.methodName
                ? result.data.order.shippingInfo.methodName
                : result.data.order.shippingInfo.partnerName,
              whatsapp: result.data.order.DealerInfo.whatsapp
                ? result.data.order.DealerInfo.whatsapp
                : result.data.order.shippingInfo.mobile,
              warehousename: result.data.order.warehousename || "",
              dmId: result.data.order.dmId,
              dmName: result.data.order.dmName,
              cartType:
                result.data.order.cartType === "Cart" ? "ocart" : "pcart",
              orderDate: result.data.order.orderDate,
              orderId: result.data.order.orderId,
              transactionScreenshot: result.data.order.transactionScreenshot,
              paymentMethod: result.data.order.paymentMethod,
              paymentType: result.data.order.paymentType,
              paymentStatus: result.data.order.paymentStatus,
              paymentAmount: result.data.order.paymentAmount,
              transferTransId: result.data.order.transferTransId,
              status: result.data.order.status,
              manageName: result.data.order.manageName,
              manageValue: result.data.order.manageValue,
              orderType: result.data.order.orderType,
              toPay: result.data.order.toPay,
              dispatchStatus: result.data.order.dispatchStatus,
              reqStatus: result.data.order.reqStatus,
              reqType: result.data.order.reqType,
            }));
            setShippingInfo(result.data.order.shippingInfo);
            setItemsdetails(result.data.order.items);
            setReciptsInfo(result.data.receipts);
            setHelpData((prevState) => ({
              ...prevState,
              reqType: result.data.order.reqType,
              reqIssue: result.data.order.reqIssue,
              reqStatus: result.data.order.reqStatus,
              reqHelpNumber: result.data.order.reqHelpNumber,
            }));
            if (result.data.dispatchmanagers?.length > 0) {
              setDispatchManagers(result.data.dispatchmanagers);
              setGeneralInfo((prevState) => ({
                ...prevState,
                dmId: result.data.dispatchmanagers[0].managerId,
              }));
            }
            setAccounts(result.data.accounts);
          }
        } catch (error) {
          console.log("error", error);
        } finally {
          setLoading(false);
        }
      };
      fetchOrder();
    }
  }, [orderId, managerId]);

  const handleOpenModal = (content) => {
    setModalContent(content);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setModalContent(null);
  };

  const handleAddClick = () => {
    setItemsdetails([
      ...itemsdetails,
      {
        itemNo: "",
        product_name: "",
        collection_name: "",
        qty: "",
        price: "",
        unit: "",
        amount: "",
      },
    ]);
  };

  const handleRemoveClick = (index) => {
    if (itemsdetails.length > 1) {
      const list = [...itemsdetails];
      list.splice(index, 1);
      setItemsdetails(list);
    }
  };

  const handleSelectDealer = (item) => {
    if (item.name === "Cash") {
      setGeneralInfo((prevState) => ({
        ...prevState,
        dealerId: item.dealerId,
        dealerName: item.name,
        storeName: item.storeName,
        shippingType: "Standard",
        courierName: "Pick Up",
        partnerName: "Self",
      }));
      // itemFocus.current.focus();
    } else {
      setGeneralInfo((prevState) => ({
        ...prevState,
        dealerId: item.dealerId,
        dealerName: item.name,
        storeName: item.storeName,
        billingAddress:
          (item.street ||
            item.city ||
            item.district ||
            item.state ||
            item.pincode) &&
          [item.street, item.city, item.district, item.state, item.pincode]
            .filter(Boolean)
            .join(" "),
      }));
      setShippingInfo((prevState) => ({
        ...prevState,
        mobile: item.mobile,
        name: item.storeName,
        pincode: item.pincode,
        street: item.street,
        city: item.city,
        district: item.district,
        state: item.state,
      }));
      // itemFocus.current.focus();
    }
  };

  const handleSelectItem = (item, index) => {
    // console.log("item", item);
    // console.log("index", index);
    setSelectedItem(item);
    setGeneralInfo((prevState) => ({
      ...prevState,
      cartType: item.cartType,
      shippingType: "Standard",
    }));
    // setSelectedItem(item);
    // console.log("Selected item in parent:", item);
  };

  // const getStatusColor = (status) => {
  //   switch (status) {
  //     case "ordered":
  //     case "Packed":
  //       return "green";

  //     case "Not Packed":
  //     case "ordered Pending":
  //     case "Payment Under Verification":
  //     case "Request For Edit":
  //     case "Edit request Approved":
  //     case "Request For Cancel":
  //       return "red";

  //     case "Packing In Progress":
  //     case "Draft":
  //     case "In Progress":
  //     case "Payment Pending":
  //       return "red";

  //     case "New Order":
  //       return "blue";

  //     default:
  //       return "";
  //   }
  // };

  // const getTypeColor = (type) =>{
  //   if(type === "offline"){
  //     return "#F44336"
  //   }
  //   else{
  //     return "#4CAF50"
  //   }
  // }

  const routeName = "Sale Order";
  const slidingFilter = [
    "Sales",
    "Purchase",
    "Adjustment",
    "Transfer",
    "Production",
    "Production & Sale",
    "Production & Transfer",
  ];
  const orderHeader = [
    "S.No",
    "Item Description",
    "Product",
    "Warehouse",
    "Quantity",
    "Price",
    "Unit",
    "Amount",
  ];
  const shippingOptions = [
    { label: "Self", value: "Self" },
    { label: "Erickshaw", value: "erickshaw" },
    { label: "Rapido", value: "Rapido" },
    { label: "Tata Ace", value: "Tata Ace" },
  ];

  // {isNew ? "NEW" : "EXISTIING"}
  // console.log("ITEM DETAILS ", itemsdetails);
  // console.log("DISPATCH MANAGERS", dispatchManagers);
  // console.log("Item Details  ", itemsdetails);
  return (
    <>
      <div
        className={styles.order}
        style={mode ? { backgroundColor: "#2C2E33", border: "none" } : {}}
      >
        <ModalWrapper isOpen={isModalOpen} onClose={handleCloseModal} noBtn>
          {modalContent}
        </ModalWrapper>

        <div className={styles.order_top}>
          <div className={styles.order_top_left}>
            <div
              className={styles.order_top_left_go_back}
              onClick={() => navigate(-1)}
            >
              <TestTopBackArrowIcon color={mode ? "#ffffff" : "#0100E4"} />
              <p
                style={{
                  fontSize: "16px",
                  fontWeight: "500",
                  lineHeight: "24px",
                  margin: 0,
                  paddingTop: "0.21rem",
                  color: mode ? "#FFFFFF" : "#0100E4",
                }}
              >
                Go Back
              </p>
            </div>
            <TestTopDotIcon color={mode ? "#E4E7EB" : "#646B88"} />
            <p
              className={styles.order_top_left_path_text}
              style={{
                color: mode ? "#9C9797" : "#646B88",
              }}
            >
              {routeName}
            </p>
          </div>
          <div className={styles.order_right}>
            {isNew ? (
              <div>
                <SlidingFilter itemList={slidingFilter} />
              </div>
            ) : (
              <div className={styles.so_right}>
                <p style={{ color: mode && "#9C9797" }}>
                  {generalInfo.orderType}
                </p>
                <div>
                  <SideBarOrdersIcon color={mode ? "#fff" : "#646B88"} />
                  <p style={{ color: mode && "#fff" }}>{generalInfo.status}</p>
                </div>
              </div>
            )}

            <div>
              <Button
                buttonIcon={<ChatIcon />}
                buttonText={"Chat"}
                buttonBgColor={"#4164E3"}
                buttonTextColor={"#ffffff"}
                buttonBorder={"none"}
                buttonClick={() => {
                  navigate("/whatsappchats");
                }}
              />
            </div>
          </div>
        </div>

        <div className={styles.so_scroll_wrapper}>
          <form className={styles.so_form}>
            <div className={styles.so_form_1}>
              <div style={{ position: "relative" }}>
                {!isNew && (
                  <div className={styles.voucher_det}>
                    <TextElement
                      label={"Voucher No."}
                      labelColor={mode ? "#fff" : "#646B88"}
                      value={generalInfo.orderId}
                      valueColor={mode ? "#fff" : "#646B88"}
                      fontWeight={"400"}
                    />
                    <TextElement
                      label={"Voucher Date"}
                      labelColor={mode ? "#fff" : "#646B88"}
                      value={generalInfo.orderDate}
                      valueColor={mode ? "#fff" : "#646B88"}
                      fontWeight={"400"}
                    />
                  </div>
                )}

                <InputTag
                  labelText={"Dealers Name / Dealer / Dealer ID"}
                  placeholderText={"Asmit Singh"}
                  padding={"8px"}
                  fontSize={"12px"}
                  value={
                    (generalInfo.storeName &&
                      generalInfo.dealerName &&
                      generalInfo.storeName + " / " + generalInfo.dealerName) ||
                    searchDealer
                  }
                  onChange={(e) => {
                    setSearchDealer(e.target.value);
                    setShowDealerSearch(true);
                  }}
                  onFocus={() => {
                    setShowDealerSearch(true);
                  }}
                  onKeyDown={(e) => {
                    if (showDealerSearch) {
                      if (e.key === "ArrowDown" || e.key === "ArrowUp") {
                        e.preventDefault();
                        setTimeout(() => searchDealerRef?.current?.focus(), 0);
                      }

                      if (e.key === "Escape") {
                        setShowDealerSearch(false);
                      }

                      if (e.altKey && e.key.toLowerCase() === "c") {
                        e.preventDefault();
                        setIsModalOpen(true);
                        setModalContent(
                          <AdduserModel close={() => setIsModalOpen(false)} />
                        );
                      }

                      if (e.key === "Enter") {
                        e.preventDefault();
                        newItemListRefs.current[0]?.focus();
                        setDefaultDealer(true);
                      }
                    }
                  }}
                  maxLength={100}
                />

                {showDealerSearch && (
                  <SearchDealer
                    ref={searchDealerRef}
                    mode={mode}
                    name={searchDealer}
                    onSelect={handleSelectDealer}
                    setShowDealerSearch={setShowDealerSearch}
                    defaultIndex={0}
                    defaultDealer={defaultDealer}
                  />
                )}
              </div>
              <div>
                <InputTag
                  labelText={"Billing Address"}
                  placeholderText={"All"}
                  value={generalInfo.billingAddress}
                  padding={"8px"}
                  fontSize={"12px"}
                />
              </div>
            </div>
            <div className={styles.so_form_1}>
              <div>
                <div style={{ width: "50%" }}>
                  {/* <InputTag
                    labelText={"Name"}
                    placeholderText={"ABS & Company"}
                    value={shippingInfo?.name}
                    padding={"8px"}
                    fontSize={"12px"}
                  /> */}
                  <VerticalDropDown
                    height={"37px"}
                    fontSize={"12px"}
                    dropDownText={"Auto"}
                    label={"Warehouse"}
                    value={{
                      label: generalInfo.warehousename || "Select",
                      value: generalInfo.warehousename,
                    }}
                    options={
                      warehouses &&
                      warehouses?.map((w) => ({
                        label: w.warehousename,
                        value: w.warehousename,
                      }))
                    }
                    onchange={(option) =>
                      setGeneralInfo(() => ({
                        warehousename: option.value,
                      }))
                    }
                  />
                </div>
                <div style={{ width: "50%" }}>
                  {/* <InputTag
                    labelText={"Mobile Number"}
                    placeholderText={"+91 9451156705"}
                    value={shippingInfo?.mobile}
                    padding={"8px"}
                    fontSize={"12px"}
                  /> */}
                  <VerticalDropDown
                    height={"37px"}
                    fontSize={"12px"}
                    dropDownText={"Auto"}
                    label={"Dispatch Manager"}
                    options={
                      warehouses &&
                      warehouses?.map((w) => ({
                        label: w.warehousename,
                        value: w.warehousename,
                      }))
                    }
                  />
                </div>
              </div>
              {/* <div>
                <InputTag
                  labelText={"Street Address"}
                  placeholderText={"All"}
                  value={shippingInfo?.street}
                  padding={"8px"}
                  fontSize={"12px"}
                />
              </div>
              <div>
                <div style={{ width: "25%" }}>
                  <InputTag
                    labelText={"PIN Code"}
                    placeholderText={"226001"}
                    value={shippingInfo?.pincode}
                    padding={"8px"}
                    fontSize={"12px"}
                  />
                </div>
                <div style={{ width: "25%" }}>
                  <InputTag
                    labelText={"City"}
                    placeholderText={"Lucknow"}
                    value={shippingInfo?.city}
                    padding={"8px"}
                    fontSize={"12px"}
                  />
                </div>
                <div style={{ width: "25%" }}>
                  <InputTag
                    labelText={"District"}
                    placeholderText={"Lucknow"}
                    value={shippingInfo?.district}
                    padding={"8px"}
                    fontSize={"12px"}
                  />
                </div>
                <div style={{ width: "25%" }}>
                  <InputTag
                    labelText={"State"}
                    placeholderText={"Uttar Pradesh"}
                    value={shippingInfo?.state}
                    padding={"8px"}
                    fontSize={"12px"}
                  />
                </div>
              </div> */}
              <div>
                <div style={{ width: "100%", position: "relative" }}>
                  <div className={styles.voucher_det}>
                    <TextElement
                      labelColor={mode ? "#fff" : "#646B88"}
                      value={
                        shippingInfo.length === 0
                          ? "NA"
                          : shippingInfo.methodName === "Self"
                          ? "Pick Up"
                          : "Lucknow City Delivery"
                      }
                      valueColor={mode ? "#fff" : "#646B88"}
                      fontWeight={"400"}
                    />
                  </div>
                  <VerticalDropDown
                    height={"37px"}
                    fontSize={"12px"}
                    label={"Delivery Method"}
                    dropDownText={
                      shippingInfo?.methodName
                        ? shippingInfo.methodName
                        : "Select"
                    }
                    value={{
                      label: shippingInfo?.methodName || "Select",
                      value: shippingInfo?.methodName || "",
                    }}
                    options={shippingOptions}
                    onchange={(option) =>
                      setShippingInfo({
                        methodName: option.value,
                      })
                    }
                  />
                </div>
              </div>
            </div>
          </form>

          <ul className={styles.so_data_list}>
            {/* <li
              className={styles.so_data_list_row_1}
              style={{ color: mode ? "#fafafa" : "#646B88" }}
            >
              <>
                {orderHeader.map((h) => (
                  <div className="col" key={h}>
                    {h}
                  </div>
                ))}
              </>
            </li> */}
            <div className={styles.so_data_list_container}>
              {/* {isNew ? (
                <NewItemList
                  mode={mode}
                  itemsdetailslength={itemsdetails?.length}
                  onFocus={() => {
                    setShowItemSearch(true);
                  }}
                />
              ) : (
                <>
                  {itemsdetails.map((d, index) => (
                    <ItemList
                      d={d}
                      index={index}
                      isNew={isNew}
                      mode={mode}
                      itemsdetailslength={itemsdetails?.length}
                      removeItem={() => handleRemoveClick(index)}
                    />
                  ))}
                </>
              )} */}
              <table className={styles.order_table}>
                <thead>
                  <tr>
                    <th className={styles.table_header}>S.No</th>
                    <th className={styles.table_header}>Item Description</th>
                    <th className={styles.table_header}>Product</th>
                    <th className={styles.table_header}>Warehouse</th>
                    <th className={styles.table_header}>Quantity</th>
                    <th className={styles.table_header}>Unit</th>
                    <th className={styles.table_header}>Rate</th>
                    <th className={styles.table_header}>Amount</th>
                    {/* <th className={styles.table_header}></th> */}
                  </tr>
                </thead>
                {isNew
                  ? itemsdetails.map((item, index) => (
                      <>
                        <NewItemList
                          newItemListRef={(el) =>
                            (newItemListRefs.current[index] = el)
                          }
                          key={index}
                          index={index}
                          mode={mode}
                          showItemSearch={showItemSearch}
                          setShowItemSearch={setShowItemSearch}
                          managerId={managerId}
                          orderType={generalInfo.orderType}
                          onSelectItem={handleSelectItem}
                          itemsdetailslength={itemsdetails.length}
                          removeItem={() => handleRemoveClick(index)}
                          addItem={() => handleAddClick()}
                        />
                        <div style={{ height: "5px" }}></div>
                      </>
                    ))
                  : itemsdetails.map((d, index) => (
                      <ItemList
                        key={d.id}
                        d={d}
                        index={index}
                        mode={mode}
                        itemsdetailslength={itemsdetails.length}
                        removeItem={() => handleRemoveClick(index)}
                      />
                    ))}
              </table>
            </div>
            <div
              style={{
                alignSelf: "flex-end",
                border: "2px solid #4164E3",
                borderRadius: "50%",
                cursor: "pointer",
              }}
              onClick={() => {
                handleAddClick();
              }}
            >
              <TestTopAddDealerIcon color="#4164E3" />
              {/* <Button
                buttonIcon={<TestTopAddDealerIcon color="#4164E3" />}
                // buttonText={"Add More Item"}
                buttonBgColor={"transparent"}
                buttonTextColor={"#4164E3"}
                buttonBorder={"2px solid #4164E3"}
                buttonClick={() => {
                  handleAddClick();
                }}
              /> */}
            </div>
          </ul>

          <div className={styles.so_instc} style={{ display: "none" }}>
            <p>Press Enter to Add Item</p>
            <DividerIcon />
            <p>Double Enter for Next Option</p>
            <DividerIcon />
            <p>Ctrl + Backspace for Remove Item</p>
          </div>
        </div>

        <div
          className={`${styles.so_footer} ${mode ? styles.so_footer_dark : ""}`}
        >
          <div className={styles.so_footer_top}>
            {!isNew && (
              <div className={styles.so_footer_top_left}>
                <div
                  className={styles.so_receipt_data}
                  style={mode ? { backgroundColor: "#1B1D21" } : {}}
                >
                  <ul
                    style={
                      mode ? { backgroundColor: "#2C2E33", color: "#fff" } : {}
                    }
                  >
                    <li className="col">Receipt Number</li>
                    <li className="col">Payment Method</li>
                    <li className="col">Amount</li>
                    <li className="col">Status</li>
                  </ul>
                  {reciptsInfo.map((r, index) => (
                    <ul
                      style={
                        mode
                          ? { backgroundColor: "#232529", color: "#fff" }
                          : {}
                      }
                    >
                      <li className="col" key={index + r.receiptId}>
                        {r.receiptId}
                      </li>
                      <li className="col" key={index + r.paymentMethod}>
                        {r.paymentMethod}
                      </li>
                      <li className="col" key={index + r.paymentAmount}>
                        {r.paymentAmount}
                      </li>
                      <li
                        className="col"
                        key={index + r.status}
                        style={{ color: "#FB9600" }}
                      >
                        {r.status}
                      </li>
                    </ul>
                  ))}
                </div>
                <div style={{ marginLeft: "auto" }}>
                  <Button
                    buttonIcon={<TestTopAddDealerIcon />}
                    buttonBgColor={"#000000"}
                    buttonTextColor={"#ffffff"}
                    buttonBorder={"none"}
                    buttonClick={() =>
                      handleOpenModal(
                        <AddReceiptModal
                          handleCloseModal={handleCloseModal}
                          mode={mode}
                        />
                      )
                    }
                  />
                </div>
              </div>
            )}
            <div className={styles.so_footer_top_right}>
              <div
                className={`${styles.so_footer_top_right_1} ${
                  mode ? styles.so_footer_top_right_1_dark : ""
                }`}
              >
                <div>
                  <p>Sub Total:</p>
                  <p>0.00</p>
                </div>
                <div className={styles.so_shipping}>
                  <p>Shipping Charges:</p>
                  <input
                    type="number"
                    value={shippingInfo?.shippingCharge}
                    style={mode ? { color: "#fff" } : {}}
                  />
                  <div className={styles.so_footer_top_right_1_check}>
                    <p>To Pay</p>
                    <input type="checkbox" />
                  </div>
                  <p>0.00</p>
                </div>
                <div>
                  <p>Total Amount(INR):</p>
                  <p>0.00</p>
                </div>
              </div>
              <div className={styles.so_footer_top_right_2}>
                <div>
                  <Button
                    buttonIcon={<RightArrowIcon />}
                    buttonText={"Back"}
                    buttonBgColor={"#000000"}
                    buttonTextColor={"#ffffff"}
                    buttonBorder={"none"}
                  />
                </div>
                <div>
                  <Button
                    buttonIcon={<SaveAsDraftIcon />}
                    buttonText={"Save as Draft"}
                    buttonBgColor={"#000000"}
                    buttonTextColor={"#ffffff"}
                    buttonBorder={"none"}
                  />
                </div>
                <div>
                  <Button
                    buttonIcon={<LeftArrowIcon />}
                    buttonText={"Next"}
                    buttonBgColor={"#4164E3"}
                    buttonTextColor={"#ffffff"}
                    buttonBorder={"none"}
                    buttonClick={() =>
                      handleOpenModal(
                        <AddNewItemModal
                          handleCloseModal={handleCloseModal}
                          mode={mode}
                        />
                      )
                    }
                  />
                </div>
                <div>
                  <Button
                    buttonIcon={<ShareIcon />}
                    buttonText={"Share"}
                    buttonBgColor={"#4164E3"}
                    buttonTextColor={"#ffffff"}
                    buttonBorder={"none"}
                    buttonClick={() =>
                      handleOpenModal(
                        <ShareLinkModal
                          handleCloseModal={handleCloseModal}
                          mode={mode}
                        />
                      )
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SaleOrder;
