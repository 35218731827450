    import React, { useEffect, useRef, useState } from "react";
    import styles from "./TabSwitcher.module.css";

    const Tabs = ({ children, activeTab, setActiveTab }) => {
        const handleTabChange = (value) => {
            setActiveTab(value);
        };

        return (
            <div className={styles.tabs}>
                {React.Children.map(children, (child) => {
                    if (child.type === TabsList) {
                        return React.cloneElement(child, { activeTab, onTabChange: handleTabChange });
                    }
                    if (child.type === TabsContent) {
                        return React.cloneElement(child, { activeTab });
                    }
                    return child;
                })}
            </div>
        );
    };

    const TabsList = ({ children, activeTab, onTabChange, className, mode }) => (
        <div className={`${styles.tabsList} ${className}`}>
            {React.Children.map(children, (child) =>
                React.cloneElement(child, { activeTab, onTabChange })
            )}
        </div>
    );

    const TabsTrigger = ({ value, activeTab, onTabChange, children, mode }) => {
        const getStyle = () => {
            if (value === "Select") {
                return { borderRadius: "5px 0px 0px 0px" };
            } else if (value === "DealersGroup") {
                return { borderRadius: "0px 5px 0px 0px" };
            } else if (value === "Users") {
                return { borderRadius: "0px 0px 0px 0px" };
            }
            return {};
        };

        const getInactiveStyle = () => {
            if (mode) {
                return { backgroundColor: "#2C2E33", color:"#fff" }; 
            }
            return { backgroundColor: "#F8F9FC" };
        };

        return (
            <button
                className={`${styles.tabsTrigger} 
                ${activeTab === value ? (mode === false ? styles.active : styles.active1) : ""}`}
                onClick={() => onTabChange(value)}
                style={activeTab === value ? getStyle() : { ...getInactiveStyle(), ...getStyle() }}
            >
                {children}
            </button>
        );
    };



    // const TabsContent = ({ value, activeTab, children, className }) => {
    //     return activeTab === value ? <div className={className}  >{children}</div> : null;
    // };
    const TabsContent = ({ value, activeTab, children, className }) => {
        const contentRef = useRef(null);
        const [height, setHeight] = useState("auto");

        useEffect(() => {
            const updateHeight = () => {
                if (contentRef.current) {
                    setHeight(`${contentRef.current.scrollHeight}px`);
                }
            };

            if (activeTab === value) {
                updateHeight(); // Set height when the tab becomes active
            }

            // ResizeObserver to adjust height if the content changes dynamically
            const resizeObserver = new ResizeObserver(updateHeight);
            if (contentRef.current) {
                resizeObserver.observe(contentRef.current);
            }

            return () => {
                if (contentRef.current) {
                    resizeObserver.disconnect();
                }
            };
        }, [activeTab, value , children]); 

        return activeTab === value ? (
            <div
                ref={contentRef}
                className={`${styles.tabContent} ${className}`}
                style={{ height: "700px", overflow: "hidden", transition: "height 0.3s ease-in-out"}}
            >
                {children}
            </div>
        ) : null;
    };

    export { Tabs, TabsList, TabsTrigger, TabsContent };
