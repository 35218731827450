import { NavbarWhatsAppIcon, NewManagerCancelIcon, PaymentChatIcon, PaymentCopyLinkIcon, PaymentMailIcon, PaymentMessageIcon, SaveTaxRateIcon } from "../../icons/Icon";
import { CloseIcon } from "../../icons/Icon2";
import Button from "../Dealers/Button";
import styles from "../../../pages/ordermanager/Orders.module.css";

const ShareLinkModal = ({ mode, handleCloseModal }) => {
  return (
    <div
      className={styles.share_link_modal}
      style={{ backgroundColor: mode && "#232529" }}
    >
      <div
        style={{ alignSelf: "flex-end", cursor: "pointer" }}
        onClick={handleCloseModal}
      >
        <CloseIcon mode={mode} />
      </div>
      <div className={styles.header_text}>
        <p style={{ color: mode && "#FFFFFF" }}>Share Payment Link</p>
        <p style={{ color: mode && "#FFFFFF" }}>
          Select a method to share the payment link.
        </p>
      </div>

      <div className={styles.icons_tray}>
        <div>
          <div style={{ backgroundColor: mode && "#1b1d21" }}>
            <NavbarWhatsAppIcon />
          </div>
          <p style={{ color: mode && "#FFFFFF" }}>WhatsApp</p>
        </div>
        <div>
          <div style={{ backgroundColor: mode && "#1b1d21" }}>
            <PaymentMessageIcon color={mode ? "#FFFFFF" : "#292D32"} />
          </div>
          <p style={{ color: mode && "#FFFFFF" }}>SMS</p>
        </div>
        <div>
          <div style={{ backgroundColor: mode && "#1b1d21" }}>
            <PaymentMailIcon color={mode ? "#1B1D21" : "#ffffff"} />
          </div>
          <p style={{ color: mode && "#FFFFFF" }}>Email</p>
        </div>
        <div>
          <div style={{ backgroundColor: mode && "#1b1d21" }}>
            <PaymentCopyLinkIcon color={mode ? "#FFFFFF" : "#41416E"} />
          </div>
          <p style={{ color: mode && "#FFFFFF" }}>Copy</p>
        </div>
        <div>
          <div style={{ backgroundColor: mode && "#1b1d21" }}>
            <PaymentChatIcon color={mode ? "#FFFFFF" : "#1B1D21"} />
          </div>
          <p style={{ color: mode && "#FFFFFF" }}>Chat</p>
        </div>
      </div>

      <div className={styles.pay_link}>
        <input
          style={{
            width: "100%",
            backgroundColor: mode && "#1B1D21",
            color: mode && "#FFFFFF",
            borderColor: mode && "#1B1D21",
          }}
          type="text"
          value="https://example.com/spring-ux-mercurial/E"
        />
      </div>

      <div className={styles.send_link}>
        <input
          style={{
            backgroundColor: mode && "#1B1D21",
            color: mode && "#FFFFFF",
            borderColor: mode && "#1B1D21",
          }}
          type="text"
          value="+91 749369020"
        />

        <Button
          buttonIcon={<NavbarWhatsAppIcon />}
          buttonText={"Send Link"}
          buttonBgColor={"#4164E3"}
          buttonTextColor={"#ffffff"}
          buttonBorder={"none"}
        />
      </div>

      <div className={styles.button_group}>
        <div>
          <Button
            buttonBgColor={"#000"}
            buttonText={"CANCEL"}
            buttonTextColor={"#fff"}
            buttonIcon={<NewManagerCancelIcon />}
            buttonClick={handleCloseModal}
          />
        </div>
        <div>
          <Button
            buttonBgColor={"#4164E3"}
            buttonText={"SAVE"}
            buttonTextColor={"#fff"}
            buttonIcon={<SaveTaxRateIcon />}
          />
        </div>
      </div>
    </div>
  );
};

export default ShareLinkModal;
