import React, { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import Select from 'react-select';
import { GreenButton, GreenOutButton, RedOutButton } from '../../components/Buttons';
import { CheckGreenIcon, DeleteFile, Spinner, UpLoadFile } from '../../components/icons/Icon';
import stylescss from './AddItem.module.css';


const AddItem = () => {
    const navigate = useNavigate()
    const [searchParams] = useSearchParams();
    const getId = searchParams.get("product_id");
    const product_id = (!getId || getId === null) ? "" : getId
    const collId = searchParams.get("collection_id");
    const collection_id = (!collId || collId === null) ? "" : collId
    const itemId = searchParams.get("itemId");
    const item_id = (!itemId || itemId === null) ? "" : itemId

    const product_name = window.location.pathname.split("/")[window.location.pathname.split("/").length - 2]
    const collection_name = window.location.pathname.split("/")[window.location.pathname.split("/").length - 1]
    const [loading, setLoading] = useState(false)
    const [addData, setAddData] = useState({
        productimg: "",
        closeupimg: "",
        thumbnail: "",
        pdfImage: "",
        type_name: "",
        itemNo: "",
        rating: "",
        description: "",
        title: "",
        url: "",
        onlineVisibility: "",
        secondaryUnit: "",
        productionItem: "",
        sellersItem: "",
        status: "",
        itemSize: "",
        itemColor: "",
        itemAddMoreColor: [],
        metaTitle: "",
        metaKeywords: "",
        focusKeyword: "",
        metaDescription: "",
    });

    // ---------------------------Add-More-----------------------------------
    const [addMoreItem, setAddMoreItem] = useState([{
        demoimg: "",
        style_name: "",
        masterimg: false,
    }])
    const addFields = () => {
        let newfield = { demoimg: "", style_name: "", masterimg: "" }
        setAddMoreItem([...addMoreItem, newfield])
    }

    const handleFormChange = (index, event) => {
        let data = [...addMoreItem];
        data[index][event.target.name] = event.target.value;
        setAddMoreItem(data);
    }

    const handleFileChange = (index, event) => {
        let data = [...addMoreItem];
        data[index]["demoimg"] = event.target.files[0];
        setAddMoreItem(data);
    }
    const removeFormFields = (i) => {
        const values = [...addMoreItem];
        values.splice(i, 1);
        setAddMoreItem(values);
    }

    // ---------------------------Remove-Update-Img-Key-----------------------------------
    const [demoUpdate, setDemoUpdate] = useState([])

    // ---------------------------Add-More-Color-----------------------------------
    const colourStyles = {
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            // console.log({ data, isDisabled, isFocused, isSelected });
            return {
                ...styles,
                backgroundColor: isFocused ? "#999999" : "#1E2022",
                color: "#fff"
            };
        }
    };

    const [selectedValue, setSelectedValue] = useState([]);

    const handleChange = (e) => {
        setSelectedValue(Array.isArray(e) ? e.map(x => x.value) : []);
    }
    const [styles, setStyles] = useState([]);
    const [items, setItems] = useState([]);
    const [types, setTypes] = useState([]);
    // ---------------------------Meta Keywords----------------------
    const [input, setInput] = useState('');
    const [tags, setTags] = useState([]);
    const [isKeyReleased, setIsKeyReleased] = useState(false);

    const onKeyDown = (e) => {
        const { key } = e;
        const trimmedInput = input.trim();

        if ((key === ',' || key === "Enter") && trimmedInput.length && !tags.includes(trimmedInput)) {
            e.preventDefault();
            setTags(prevState => [...prevState, trimmedInput]);
            setInput('');
        }

        if (key === "Backspace" && !input.length && tags.length && isKeyReleased) {
            const tagsCopy = [...tags];
            const poppedTag = tagsCopy.pop();
            e.preventDefault();
            setTags(tagsCopy);
            setInput(poppedTag);
        }

        setIsKeyReleased(false);
    };

    const onKeyUp = () => {
        setIsKeyReleased(true);
    }

    const deleteTag = (index) => {
        setTags(prevState => prevState.filter((tag, i) => i !== index))
    }

    const onChange = (e) => {
        const { value } = e.target;
        setInput(value);
    };

    // Ravi Changes
    const [tages2, setTages2] = useState([])
    useEffect(() => {
        const trimmedInput = input.trim();
        if (input.includes(",")) {
            setTages2(trimmedInput.split(','))
        }
    }, [input])

    useEffect(() => {
        if (tages2) {
            setTags(tages2)
            setInput("")
        }
    }, [tages2])
    // Ravi Changes
    // ----------------------------------------------------------recomended-Items-----------------------------------------------------------
    const [inputItems, setInputItems] = useState('');
    const [recomendedTags, setRecomendedTags] = useState([]);
    const [isKeyReleasedItems, setIsKeyReleasedItems] = useState(false);

    const onKeyDownItems = (e) => {
        const { key } = e;
        const trimmedInput = inputItems.trim();

        if ((key === ',' || key === "Enter") && trimmedInput.length && !recomendedTags.includes(trimmedInput)) {
            e.preventDefault();
            setRecomendedTags(prevState => [...prevState, trimmedInput]);
            setInputItems('');
        }

        if (key === "Backspace" && !inputItems.length && recomendedTags.length && isKeyReleasedItems) {
            const tagsCopy = [...recomendedTags];
            const poppedTag = tagsCopy.pop();
            e.preventDefault();
            setRecomendedTags(tagsCopy);
            setInputItems(poppedTag);
        }

        setIsKeyReleasedItems(false);
    };

    const onKeyUpItems = () => {
        setIsKeyReleasedItems(true);
    }

    const deleteTagItems = (index) => {
        setRecomendedTags(prevState => prevState.filter((tag, i) => i !== index))
    }

    const onChangeItems = (e) => {
        const { value } = e.target;
        setInputItems(value);
    };

    // Ravi Changes
    const [tages2Items, setTages2Items] = useState([])
    useEffect(() => {
        const trimmedInput = inputItems.trim();
        if (inputItems.includes(",")) {
            setTages2Items(trimmedInput.split(','))
        }
    }, [inputItems])

    useEffect(() => {
        if (tages2Items) {
            setRecomendedTags(tages2Items)
            setInputItems("")
        }
    }, [tages2Items])

    // ----------------------------------------------------------focus-Keyword-----------------------------------------------------------
    const [inputFocus, setInputFocus] = useState('');
    const [focusKeywordTags, setFocusKeywordTags] = useState([]);
    const [isKeyReleasedFocus, setIsKeyReleasedFocus] = useState(false);

    const onKeyDownFocus = (e) => {
        const { key } = e;
        const trimmedInput = inputFocus.trim();

        if ((key === ',' || key === "Enter") && trimmedInput.length && !focusKeywordTags.includes(trimmedInput)) {
            e.preventDefault();
            setFocusKeywordTags(prevState => [...prevState, trimmedInput]);
            setInputFocus('');
        }

        if (key === "Backspace" && !inputFocus.length && focusKeywordTags.length && isKeyReleasedFocus) {
            const tagsCopy = [...focusKeywordTags];
            const poppedTag = tagsCopy.pop();
            e.preventDefault();
            setFocusKeywordTags(tagsCopy);
            setInputFocus(poppedTag);
        }

        setIsKeyReleasedFocus(false);
    };

    const onKeyUpFocus = () => {
        setIsKeyReleasedFocus(true);
    }

    const deleteTagFocus = (index) => {
        setFocusKeywordTags(prevState => prevState.filter((tag, i) => i !== index))
    }

    const onChangeFocus = (e) => {
        const { value } = e.target;
        setInputFocus(value);
    };

    // Ravi Changes
    const [tages2Focus, setTages2Focus] = useState([])
    useEffect(() => {
        const trimmedInput = inputFocus.trim();
        if (inputFocus.includes(",")) {
            setTages2Focus(trimmedInput.split(','))
        }
    }, [inputFocus])

    useEffect(() => {
        if (tages2Focus) {
            setFocusKeywordTags(tages2Focus)
            setInputFocus("")
        }
    }, [tages2Focus])


    // ---------------------------Get Item-----------------------------------
    useEffect(() => {
        var myHeaders = new Headers();
        myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "prodId": product_id,
            "collection_id": collection_id,
            "_id": item_id
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        // fetch("http://192.168.0.108:5052/content/item", requestOptions)
        fetch(process.env.REACT_APP_URL + "content/item", requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status === "success") {
                    if (result.data) {
                        setAddData(prevState => ({
                            ...prevState,
                            productimg: result.data.itemImgDetails.productimg,
                            closeupimg: result.data.itemImgDetails.closeupimg,
                            thumbnail: result.data.itemImgDetails.thumbnail,
                            pdfImage: result.data.itemImgDetails.pdfImage,
                            type_name: result.data.materialType,
                            itemNo: result.data.itemNo,

                            onlineVisibility: result.data.onlineVisibility,
                            secondaryUnit: result.data.secondaryUnit,
                            productionItem: result.data.productionItem,
                            sellersItem: result.data.sellersItem,
                            status: result.data.status,

                            itemSize: result.data.itemSize,
                            itemColor: result.data.itemColor,

                            title: result.data.title,
                            url: result.data.url ? result.data.url : "",

                            rating: result.data.rating,
                            description: result.data.description,

                            metaTitle: result.data.metaTitle,
                            metaKeywords: result.data.metaKeywords,
                            // focusKeyword: result.data.focusKeyword,
                            metaDescription: result.data.metaDescription,
                        }));

                        if (result.data.metaKeywords.length === 1) {
                            setTags(result.data.metaKeywords[0].split(','))
                        } else {
                            setTags(result.data.metaKeywords)
                        }

                        if (result.data.focusKeyword.length === 1) {
                            setFocusKeywordTags(result.data.focusKeyword[0].split(','))
                        } else {
                            setFocusKeywordTags(result.data.focusKeyword)
                        }

                        if (result.data.recommendedItems.length === 1) {
                            setRecomendedTags(result.data.recommendedItems[0].split(','))
                        } else {
                            setRecomendedTags(result.data.recommendedItems)
                        }

                        setAddMoreItem(result.data.itemImgDetails.stylesImgs);
                        setSelectedValue(result.data.itemAddMoreColor);
                    }

                    setStyles(result.styles);
                    setItems(result.items);
                    setTypes(result.types);
                }
            })
            .catch(error => console.log('error', error));
    }, [item_id, product_id, collection_id])


    // ---------------------------Add-Update-Item-----------------------------------
    // const handleSubmit = (e) => {
    //     e.preventDefault();
    //     var myHeaders = new Headers();
    //     myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);

    //     var formdata = new FormData();
    //     formdata.append("product_name", product_name.replace(/-/g, " "));
    //     formdata.append("product_id", product_id);
    //     formdata.append("collection_name", collection_name.replace(/-/g, " "));
    //     formdata.append("collection_id", collection_id);
    //     formdata.append("itemSize", addData.itemSize);
    //     formdata.append("itemColor", addData.itemColor);
    //     formdata.append("itemAddMoreColor", selectedValue);
    //     formdata.append("type_name", addData.type_name);
    //     formdata.append("url", addData.url);
    //     formdata.append("metaTitle", addData.metaTitle); 
    //     formdata.append("title", addData.title);
    //     formdata.append("description", addData.description);
    //     formdata.append("rating", addData.rating);
    //     formdata.append("metaKeywords", JSON.stringify(tags));
    //     formdata.append("focusKeyword", addData.focusKeyword);
    //     formdata.append("metaDescription", addData.metaDescription);
    //     formdata.append("status", addData.status);
    //     formdata.append("productimg", addData.productimg);
    //     formdata.append("closeupimg", addData.closeupimg);
    //     formdata.append("thumbnail", addData.thumbnail);
    //     demoUpdate && formdata.append("demoUpdate", demoUpdate);
    //     item_id && formdata.append("_id", item_id);

    //     addMoreItem.map(item =>
    //         <>
    //             {(formdata.append("demoimg", item.demoimg),
    //                 formdata.append("styles", item.style_name),
    //                 formdata.append("masterimg", item.masterimg)
    //             )}
    //         </>
    //     )

    //     var requestOptions = {
    //         method: 'POST',
    //         headers: myHeaders,
    //         body: formdata,
    //         redirect: 'follow'
    //     };

    //     fetch(process.env.REACT_APP_URL + "content/addUpdateItem/" + addData.itemNo + '?item_id=' + item_id, requestOptions)
    //         .then(response => response.json())
    //         .then(result => {
    //             if (result.status === "success") {
    //                 alert("Item Updated Successfully");
    //                 navigate(-1)
    //             } else {
    //                 alert(result.msg);
    //             }
    //         })
    //         .catch(error => console.log('error', error));

    // }

    const formattedRecommendedItems = selectedValue.map(item => ({ itemNo: item }));

    const transformedItems = addMoreItem.map(item => ({
        style_name: item.style_name,
        demoimg: item.demoimg.path,
        masterimg: item.masterimg
      }));

    const handleSubmit = (e) => {
        if (item_id) {
            e.preventDefault();
            var myHeaders = new Headers();
            myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);

            var formdata = new FormData();
            formdata.append("itemNo", addData.itemNo);
            formdata.append("product_name", product_name.replace(/-/g, " "));
            formdata.append("product_id", product_id);
            formdata.append("collection_id", collection_id);
            formdata.append("materialType", addData.type_name);
            formdata.append("collection_name", collection_name.replace(/-/g, " "));
            formdata.append("itemSize", addData.itemSize);
            formdata.append("itemColor", addData.itemColor);
            formdata.append("metaTitle", addData.metaTitle);
            formdata.append("metaDescription", addData.metaDescription);
            formdata.append("stylesImgsInfo",  JSON.stringify(transformedItems));
            formdata.append("title", addData.title);
            formdata.append("description", addData.description);
            formdata.append("itemAddMoreColor", JSON.stringify(recomendedTags));
            formdata.append("metaKeywords", JSON.stringify(tags));
            formdata.append("focusKeyword", JSON.stringify(focusKeywordTags));
            formdata.append("onlineVisibility", addData.onlineVisibility);
            formdata.append("secondaryUnit", addData.secondaryUnit);
            formdata.append("productionItem", addData.productionItem);
            formdata.append("sellersItem", addData.sellersItem);
            formdata.append("status", addData.status);
            formdata.append("recommendedItems", JSON.stringify(formattedRecommendedItems));
            formdata.append("url", addData.url);
            formdata.append("productimg", addData.productimg);
            formdata.append("closeupimg", addData.closeupimg);
            formdata.append("thumbnail", addData.thumbnail);
            formdata.append("pdfImage", addData.pdfImage);
            addMoreItem.map(item =>
                <>
                    {formdata.append("demoimg", item.demoimg)}
                </>
            )

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: formdata,
                redirect: 'follow'
            };

            // fetch(process.env.REACT_APP_URL + "content/addUpdateItem/" + addData.itemNo + '?item_id=' + item_id, requestOptions)
            fetch(process.env.REACT_APP_URL + "content/updateItem/" + item_id, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.status === "success") {
                        alert("Item Updated Successfully");
                        // navigate(-1)
                    } else {
                        alert(result.msg);
                    }
                })
                .catch(error => console.log('error', error));
        } else {
            e.preventDefault();
            setLoading(true)
            const myHeaders = new Headers();
            myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);

            const formdata = new FormData();
            formdata.append("itemNo", addData.itemNo);
            formdata.append("product_name", product_name.replace(/-/g, " "));
            formdata.append("product_id", product_id);
            formdata.append("collection_id", collection_id);
            formdata.append("materialType", addData.type_name);
            formdata.append("collection_name", collection_name.replace(/-/g, " "));
            formdata.append("itemSize", addData.itemSize);
            formdata.append("itemColor", addData.itemColor);
            formdata.append("metaTitle", addData.metaTitle);
            formdata.append("metaDescription", addData.metaDescription);
            formdata.append("stylesImgsInfo",  JSON.stringify(transformedItems));
            formdata.append("title", addData.title);
            formdata.append("description", addData.description);
            formdata.append("itemAddMoreColor", JSON.stringify(recomendedTags));
            formdata.append("metaKeywords", JSON.stringify(tags));
            formdata.append("focusKeyword", JSON.stringify(focusKeywordTags));
            formdata.append("onlineVisibility", addData.onlineVisibility);
            formdata.append("secondaryUnit", addData.secondaryUnit);
            formdata.append("productionItem", addData.productionItem);
            formdata.append("sellersItem", addData.sellersItem);
            formdata.append("status", addData.status);
            formdata.append("recommendedItems", JSON.stringify(formattedRecommendedItems));
            formdata.append("url", addData.url);
            formdata.append("productimg", addData.productimg);
            formdata.append("closeupimg", addData.closeupimg);
            formdata.append("thumbnail", addData.thumbnail);
            formdata.append("pdfImage", addData.pdfImage);
            addMoreItem.map(item =>
                <>
                    {formdata.append("demoimg", item.demoimg)}
                </>
            )

            // formdata.append("itemNo", addData.itemNo);
            // formdata.append("product_name", product_name.replace(/-/g, " "));
            // formdata.append("product_id", product_id);
            // formdata.append("collection_id", collection_id);
            // formdata.append("collection_name", collection_name.replace(/-/g, " "));

            // formdata.append("itemSize", addData.itemSize);
            // formdata.append("itemColor", addData.itemColor);
            // formdata.append("metaTitle", addData.metaTitle);
            // formdata.append("metaDescription", addData.metaDescription);
            // formdata.append("stylesImgsInfo", JSON.stringify(addMoreItem));
            // formdata.append("productimg", addData.productimg);
            // formdata.append("closeupimg", addData.closeupimg);
            // formdata.append("thumbnail", addData.thumbnail);
            // formdata.append("pdfImage", addData.pdfImage);
            // addMoreItem.map(item =>
            //     <>
            //         {formdata.append("demoimg", item.demoimg)}
            //     </>
            // )
            // formdata.append("title", addData.title);
            // formdata.append("description", addData.description);
            // formdata.append("itemAddMoreColor", selectedValue);
            // formdata.append("materialType", addData.type_name);
            // formdata.append("metaKeywords", JSON.stringify(tags));
            // formdata.append("focusKeyword", JSON.stringify(focusKeywordTags));
            // formdata.append("onlineVisibility", addData.onlineVisibility);
            // formdata.append("secondaryUnit", addData.secondaryUnit);
            // formdata.append("productionItem", addData.productionItem);
            // formdata.append("sellersItem", addData.sellersItem);
            // formdata.append("status", addData.status);

            // formdata.append("recommendedItems", JSON.stringify(recomendedTags));
            // formdata.append("url", addData.url);

            const requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: formdata,
                redirect: "follow"
            };

            fetch(process.env.REACT_APP_URL + "content/addItem", requestOptions)
                .then((response) => response.json())
                .then(result => {
                    if (result.message==="Item successfully Created with Styles!") {
                        setLoading(false)
                        alert(result.message);
                        navigate(-1)
                    } else {
                        alert(result.message);
                    }
                })
                .catch((error) => console.error(error));
        }

    }



    // console.log("productimg", addData.productimg);
    // console.log("addMoreItem", addMoreItem);
    return (
        <React.Fragment>
            <div className={stylescss.product_right}>
                <div className={stylescss.product_header}>
                    <div>
                        <u onClick={() => navigate(-1)}>
                            &lt; Go Back
                        </u>
                    </div>
                    <div>
                        <h1>{product_name} {'>'} {collection_name}</h1>
                    </div>
                </div>
                <form onSubmit={handleSubmit} className={stylescss.add_product_main_div}>
                    <div className={stylescss.metaRow}>
                        <div className={stylescss.meta}>Meta URL</div>
                        <div className={stylescss.url}>
                            {process.env.REACT_APP_GLOBALURL}
                            <input type={"text"}
                                name='url'
                                value={addData.url}
                                onChange={(e) => setAddData({ ...addData, url: e.target.value })}
                                placeholder="Enter Url"
                                className={stylescss.add_url_input} />
                            {process.env.REACT_APP_GLOBALURL.length + addData.url.length}
                            {/* {addData.url.length} */}
                        </div>
                    </div>

                    <div className={stylescss.inputField_row}>

                        <div className={stylescss.col_left}>

                            <div className={stylescss.addItemRow}>
                                <div className={stylescss.addProductCol3}>Product Image</div>
                                <div className={stylescss.addBannerImage}>
                                    <img src={
                                        addData.productimg && addData.productimg.name ?
                                            URL.createObjectURL(addData.productimg)
                                            : addData.productimg ? process.env.REACT_APP_S3URL + addData.productimg
                                                : "/assets/preview.png"
                                    }
                                        alt="img" height={63} width={48} />
                                </div>
                                <label className={stylescss.bannerAddBtn}>
                                    <UpLoadFile />
                                    Upload Image
                                    <input
                                        type="file"
                                        className={stylescss.bannerImgInput}
                                        name="productimg"
                                        onChange={(e) => {
                                            setAddData({ ...addData, productimg: e.target.files[0] });
                                        }}
                                        accept="image/*"
                                    />
                                </label>
                                <div className={stylescss.bannerDeleteBtn} onClick={() => setAddData({ ...addData, productimg: "" })}>
                                    <DeleteFile color="red" /><br />
                                    Delete
                                </div>
                            </div>

                            <div className={stylescss.addItemRow}>
                                <div className={stylescss.addProductCol3}>Closeup Image</div>
                                <div className={stylescss.addBannerImage}>
                                    <img src={
                                        addData.closeupimg && addData.closeupimg.name ?
                                            URL.createObjectURL(addData.closeupimg)
                                            : addData.closeupimg ? process.env.REACT_APP_S3URL + addData.closeupimg
                                                : "/assets/preview.png"
                                    }
                                        alt="img" height={63} width={48} />
                                </div>
                                <label className={stylescss.bannerAddBtn}>
                                    <UpLoadFile />
                                    Upload Image
                                    <input
                                        type="file"
                                        className={stylescss.bannerImgInput}
                                        name="closeupimg"
                                        onChange={(e) => {
                                            setAddData({ ...addData, closeupimg: e.target.files[0] });
                                        }}
                                        accept="image/*"
                                    />
                                </label>
                                <div className={stylescss.bannerDeleteBtn} onClick={() => setAddData({ ...addData, closeupimg: "" })}>
                                    <DeleteFile color="red" /><br />
                                    Delete
                                </div>
                            </div>

                            <div className={stylescss.addItemRow}>
                                <div className={stylescss.addProductCol3}>Thumbnail Image</div>
                                <div className={stylescss.addBannerImage}>
                                    <img src={
                                        addData.thumbnail && addData.thumbnail.name ?
                                            URL.createObjectURL(addData.thumbnail)
                                            : addData.thumbnail ? (process.env.REACT_APP_S3URL + addData.thumbnail)
                                                : "/assets/preview.png"
                                    }
                                        alt="img" height={63} width={48} />
                                </div>
                                <label className={stylescss.bannerAddBtn}>
                                    <UpLoadFile />
                                    Upload Image
                                    <input
                                        type="file"
                                        className={stylescss.bannerImgInput}
                                        name="thumbnail"
                                        onChange={(e) => {
                                            setAddData({ ...addData, thumbnail: e.target.files[0] });
                                        }}
                                        accept="image/*"
                                    />
                                </label>
                                <div className={stylescss.bannerDeleteBtn} onClick={() => setAddData({ ...addData, thumbnail: "" })}>
                                    <DeleteFile color="red" /><br />
                                    Delete
                                </div>
                            </div>

                            <div className={stylescss.addItemRow}>
                                <div className={stylescss.addProductCol3}>PDF Image</div>
                                <div className={stylescss.addBannerImage}>
                                    <img src={
                                        addData.pdfImage && addData.pdfImage.name ?
                                            URL.createObjectURL(addData.pdfImage)
                                            : addData.pdfImage ? (process.env.REACT_APP_S3URL + addData.pdfImage)
                                                : "/assets/preview.png"
                                    }
                                        alt="img" height={63} width={48} />
                                </div>
                                <label className={stylescss.bannerAddBtn}>
                                    <UpLoadFile />
                                    Upload Image
                                    <input
                                        type="file"
                                        className={stylescss.bannerImgInput}
                                        name="pdfImage"
                                        onChange={(e) => {
                                            setAddData({ ...addData, pdfImage: e.target.files[0] });
                                        }}
                                        accept="image/*"
                                    />
                                </label>
                                <div className={stylescss.bannerDeleteBtn} onClick={() => setAddData({ ...addData, pdfImage: "" })}>
                                    <DeleteFile color="red" /><br />
                                    Delete
                                </div>
                            </div>



                            <div className='w-100 position-relative mt-2'>
                                <div className={stylescss.addProductCol3}><u>Style Name</u></div>
                                <div className={stylescss.masterimg_text}> Select <br />Master Image </div>
                            </div>
                            {addMoreItem && addMoreItem.map((item, index) => (
                                <div className={stylescss.addItemRow} key={index}>

                                    <select className={stylescss.bannerSelect}
                                        name="style_name"
                                        onChange={(e) => handleFormChange(index, e)}
                                        value={addMoreItem[index].style_name && addMoreItem[index].style_name.trim()}

                                    >
                                        <option value="" className={stylescss.bg_dark}>Select Style</option>
                                        {item.style_name && <option className={stylescss.bg_dark}>{item.style_name ? item.style_name : ""}</option>}
                                        {
                                            styles.filter(x => !addMoreItem.some(y =>
                                                (x.style_name && x.style_name.trim()) === (y.style_name && y.style_name.trim()))).map((obj, i) =>
                                                    <option className={stylescss.bg_dark} key={i} value={(obj.style_name && obj.style_name.trim())}>{(obj.style_name && obj.style_name.trim())}</option>)
                                        }
                                    </select>

                                    <div className={stylescss.addBannerImage}>
                                        <img src={
                                            item.demoimg && item.demoimg.name ?
                                                URL.createObjectURL(item.demoimg)
                                                : item.demoimg ? process.env.REACT_APP_S3URL + item.demoimg
                                                    : "/assets/preview.png"
                                        }
                                            alt="img" height={63} width={48} />
                                    </div>
                                    <label className={stylescss.bannerAddBtn}>
                                        <UpLoadFile />
                                        Upload Image
                                        <input
                                            name="demoimg"
                                            type="file"
                                            className={stylescss.bannerImgInput}
                                            onChange={(e) => { handleFileChange(index, e) }}
                                            accept="image/*"
                                        />
                                    </label>

                                    {/* <input
                                        type="radio"
                                        name="masterimg"
                                        checked={addMoreItem[index].masterimg === true}
                                        onChange={(e) => {
                                            let isChecked = e.target.checked;
                                            addMoreItem[index].masterimg = isChecked;
                                            setAddMoreItem([...addMoreItem]);

                                            if (isChecked) {
                                                let data = [...addMoreItem];
                                                data.map((item, i) => (i !== index) && (item.masterimg = false));
                                                setAddMoreItem(data);
                                            }
                                        }}
                                        required
                                    /> */}

                                    <div
                                        role="button"
                                        className={stylescss.checkBox_div}
                                        onClick={() => {
                                            let updatedItems = addMoreItem.map((item, i) => {
                                                if (i === index) {
                                                    return { ...item, masterimg: !item.masterimg };
                                                } else {
                                                    return { ...item, masterimg: false };
                                                }
                                            });

                                            setAddMoreItem(updatedItems);
                                        }}
                                    >
                                        {addMoreItem[index].masterimg ? (
                                            <CheckGreenIcon />
                                        ) : (
                                            <span className="p-2"></span>
                                        )}
                                    </div>
                                    <div className={stylescss.bannerDeleteBtn}
                                        onClick={() => {
                                            removeFormFields(index);
                                            setDemoUpdate([...demoUpdate, item._id]);
                                        }} >
                                        <DeleteFile color="red" /><br />
                                        Delete
                                    </div>
                                </div>

                            ))}
                            <div className={stylescss.addMoreBtn} >
                                <button type="button" disabled={addMoreItem.length !== styles.length ? false : true} onClick={() => { addFields() }}>+ Add More</button>
                            </div>

                            <div className={stylescss.InputRadioRow}>
                                <div className={stylescss.addProductCol3}>Online Visible</div>
                                <div className={stylescss.activeDisable_btn}>
                                    <label>
                                        <input
                                            required
                                            className={stylescss.activeDisable}
                                            type="radio"
                                            name="onlineVisibility"
                                            value="Active"
                                            onChange={(e) => setAddData({ ...addData, onlineVisibility: e.target.value })}
                                            checked={addData.onlineVisibility === "Active" ? true : false}
                                        />Yes
                                    </label>
                                    <label className='me-4 pe-2'>
                                        <input
                                            required
                                            className={stylescss.activeDisable_disable}
                                            type="radio"
                                            name="onlineVisibility"
                                            value="Disable"
                                            onChange={(e) => setAddData({ ...addData, onlineVisibility: e.target.value })}
                                            checked={addData.onlineVisibility === "Disable" ? true : false}
                                        />No
                                    </label>
                                </div>
                            </div>
                            <div className={stylescss.InputRadioRow}>
                                <div className={stylescss.addProductCol3}>Secondary Unit</div>
                                <div className={stylescss.activeDisable_btn}>
                                    <label>
                                        <input
                                            required
                                            className={stylescss.activeDisable}
                                            type="radio"
                                            name="secondaryUnit"
                                            value="Active"
                                            onChange={(e) => setAddData({ ...addData, secondaryUnit: e.target.value })}
                                            checked={addData.secondaryUnit === "Active" ? true : false}
                                        />Active
                                    </label>
                                    <label>
                                        <input
                                            required
                                            className={stylescss.activeDisable_disable}
                                            type="radio"
                                            name="secondaryUnit"
                                            value="Disable"
                                            onChange={(e) => setAddData({ ...addData, secondaryUnit: e.target.value })}
                                            checked={addData.secondaryUnit === "Disable" ? true : false}
                                        />Disable
                                    </label>
                                </div>
                            </div>
                            <div className={stylescss.InputRadioRow}>
                                <div className={stylescss.addProductCol3}>Production Item</div>
                                <div className={stylescss.activeDisable_btn}>
                                    <label>
                                        <input
                                            required
                                            className={stylescss.activeDisable}
                                            type="radio"
                                            name="productionItem"
                                            value="Active"
                                            onChange={(e) => setAddData({ ...addData, productionItem: e.target.value })}
                                            checked={addData.productionItem === "Active" ? true : false}
                                        />Active
                                    </label>
                                    <label>
                                        <input
                                            required
                                            className={stylescss.activeDisable_disable}
                                            type="radio"
                                            name="productionItem"
                                            value="Disable"
                                            onChange={(e) => setAddData({ ...addData, productionItem: e.target.value })}
                                            checked={addData.productionItem === "Disable" ? true : false}
                                        />Disable
                                    </label>
                                </div>
                            </div>
                            <div className={stylescss.InputRadioRow}>
                                <div className={stylescss.addProductCol3}>Sellers Item</div>
                                <div className={stylescss.activeDisable_btn}>
                                    <label>
                                        <input
                                            required
                                            className={stylescss.activeDisable}
                                            type="radio"
                                            name="sellersItem"
                                            value="Active"
                                            onChange={(e) => setAddData({ ...addData, sellersItem: e.target.value })}
                                            checked={addData.sellersItem === "Active" ? true : false}
                                        />Active
                                    </label>
                                    <label>
                                        <input
                                            required
                                            className={stylescss.activeDisable_disable}
                                            type="radio"
                                            name="sellersItem"
                                            value="Disable"
                                            onChange={(e) => setAddData({ ...addData, sellersItem: e.target.value })}
                                            checked={addData.sellersItem === "Disable" ? true : false}
                                        />Disable
                                    </label>
                                </div>
                            </div>
                            <div className={stylescss.InputRadioRow}>
                                <div className={stylescss.addProductCol3}>Status</div>
                                <div className={stylescss.activeDisable_btn}>
                                    <label>
                                        <input
                                            required
                                            className={stylescss.activeDisable}
                                            type="radio"
                                            name="status"
                                            value="Active"
                                            onChange={(e) => setAddData({ ...addData, status: e.target.value })}
                                            checked={addData.status === "Active" ? true : false}
                                        />Active
                                    </label>
                                    <label>
                                        <input
                                            required
                                            className={stylescss.activeDisable_disable}
                                            type="radio"
                                            name="status"
                                            value="Disable"
                                            onChange={(e) => setAddData({ ...addData, status: e.target.value })}
                                            checked={addData.status === "Disable" ? true : false}
                                        />Disable
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div className={stylescss.col_right}>
                            <div className={stylescss.addItemRow}>
                                <div className={stylescss.addProductCol3}>Item Number</div>
                                <input
                                    type="text"
                                    name='itemNo'
                                    value={addData.itemNo}
                                    onChange={(e) => setAddData({ ...addData, itemNo: e.target.value })}
                                    placeholder="Enter Item Number"
                                    className={stylescss.add_product_input}
                                    required
                                />
                            </div>
                            <div className={stylescss.addBannerRow}>
                                <div className={stylescss.addProductCol3}>Title</div>
                                <textarea
                                    rows={3}
                                    name="proctTitle"
                                    value={addData.title}
                                    onChange={(e) => setAddData({ ...addData, title: e.target.value })}
                                    placeholder="Enter Title"
                                    className={stylescss.add_product_input}
                                    required
                                />
                            </div>
                            <div className={stylescss.addBannerRow}>
                                <div className={stylescss.addProductCol3}>Description</div>
                                <textarea
                                    name="description"
                                    value={addData.description}
                                    onChange={(e) => setAddData({ ...addData, description: e.target.value })}
                                    placeholder="Enter Description"
                                    className={stylescss.add_product_input}
                                    minLength={60}
                                    rows={4}
                                    required
                                />
                            </div>
                            <div className={stylescss.addItemRow}>
                                <div className={stylescss.addProductCol3}>Size</div>
                                <input
                                    type="text"
                                    name="itemSize"
                                    value={addData.itemSize}
                                    onChange={(e) => setAddData({ ...addData, itemSize: e.target.value })}
                                    placeholder="Enter itemSize"
                                    className={stylescss.add_product_input}
                                    required
                                />
                            </div>

                            <div className={stylescss.addItemRow}>
                                <div className={stylescss.addProductCol3}>Color</div>
                                <input
                                    type="text"
                                    name="itemColor"
                                    value={addData.itemColor}
                                    onChange={(e) => setAddData({ ...addData, itemColor: e.target.value })}
                                    placeholder="Enter Color"
                                    className={stylescss.add_product_input}
                                    required
                                />
                            </div>
                            <div className={stylescss.addItemRow}>
                                <div className={stylescss.addProductCol3}>Add More Color</div>
                                <div className={stylescss.addtag}>
                                    <div className={stylescss.tag_main_div}>
                                        {recomendedTags.map((tag, index) =>
                                            <div key={index} className={stylescss.tag}>{tag}
                                                <button type='button' className={stylescss.tag_button} onClick={() => deleteTagItems(index)}>x</button>
                                            </div>
                                        )}
                                        <textarea
                                            rows={1}
                                            value={inputItems}
                                            placeholder="Enter Recomended Items"
                                            onKeyDown={onKeyDownItems}
                                            onKeyUp={onKeyUpItems}
                                            onChange={onChangeItems}
                                            className={stylescss.add_inputtag}
                                        />
                                    </div>
                                </div>
                                {/* <Select
                                    options={items.map(x => ({ value: x.itemNo, label: x.itemNo, }))}
                                    value={items.map(x => ({ value: x.itemNo, label: x.itemNo, })).filter(obj => selectedValue.includes(obj.value))}
                                    onChange={handleChange}
                                    className={stylescss.selectReact}
                                    styles={colourStyles}
                                    isMulti
                                /> */}
                            </div>
                            <div className={stylescss.addItemRow}>
                                <div className={stylescss.addProductCol3}>Select Type</div>
                                <select className={stylescss.add_product_input + " " + stylescss.w_100}
                                    name="type_name"
                                    value={addData.type_name}
                                    onChange={(e) => setAddData({ ...addData, type_name: e.target.value })}
                                >
                                    <option value="" className={stylescss.bg_dark}>Select Style</option>
                                    {types && types.map((item, i) => <option className={stylescss.bg_dark} key={i} value={item.type_name}>{item.type_name}</option>)}
                                </select>
                            </div>
                            <div className={stylescss.addBannerRow}>
                                <div className={stylescss.addProductCol3}>Keywords (optional)</div>
                                <div className={stylescss.addtag}>
                                    <div className={stylescss.tag_main_div}>
                                        {tags.map((tag, index) =>
                                            <div key={index} className={stylescss.tag}>{tag}
                                                <button type='button' className={stylescss.tag_button} onClick={() => deleteTag(index)}>x</button>
                                            </div>
                                        )}
                                        <textarea
                                            rows={1}
                                            value={input}
                                            placeholder="Enter a tag"
                                            onKeyDown={onKeyDown}
                                            onKeyUp={onKeyUp}
                                            onChange={onChange}
                                            className={stylescss.add_inputtag}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className={stylescss.addBannerRow}>
                                <div className={stylescss.addProductCol3}>Meta Title</div>
                                <div className='d-flex flex-column w-100'>
                                    <textarea
                                        rows={3}
                                        name="metaTitle"
                                        value={addData.metaTitle}
                                        onChange={(e) => {
                                            setAddData({
                                                ...addData, metaTitle: e.target.value,
                                                url: e.target.value.toLocaleLowerCase().replace(/[\s,-=;]+/g, "-")
                                            })
                                        }}
                                        placeholder="Enter Title"
                                        className={stylescss.add_product_input}
                                        required
                                    />
                                    <div>
                                        *use 60 letters only<span className={stylescss.counter}> {addData.metaTitle.length}</span>
                                    </div>
                                </div>
                            </div>

                            <div className={stylescss.addBannerRow}>
                                <div className={stylescss.addProductCol3}>Meta Description</div>
                                <div className='d-flex flex-column w-100'>
                                    <textarea
                                        name="metaDescription"
                                        value={addData.metaDescription}
                                        onChange={(e) => setAddData({ ...addData, metaDescription: e.target.value })}
                                        placeholder="Enter Description"
                                        className={stylescss.add_product_input}
                                        minLength={60}
                                        rows={4}
                                        required
                                    />
                                    <div>
                                        *use 60 letters only<span className={stylescss.counter}>{addData.metaDescription.length}</span>
                                    </div>
                                </div>
                            </div>

                            <div className={stylescss.addBannerRow}>
                                <div className={stylescss.addProductCol3}>Focus Keywords</div>
                                <div className={stylescss.addtag}>
                                    <div className={stylescss.tag_main_div}>
                                        {focusKeywordTags.map((tag, index) =>
                                            <div key={index} className={stylescss.tag}>{tag}
                                                <button type='button' className={stylescss.tag_button} onClick={() => deleteTagFocus(index)}>x</button>
                                            </div>
                                        )}
                                        <textarea
                                            rows={1}
                                            value={inputFocus}
                                            placeholder="Enter Focus Keywords"
                                            onKeyDown={onKeyDownFocus}
                                            onKeyUp={onKeyUpFocus}
                                            onChange={onChangeFocus}
                                            className={stylescss.add_inputtag}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={stylescss.addBannerRow}>
                                <div className={stylescss.addProductCol3}>Recomended Items</div>
                                <Select
                                    options={items.map(x => ({ value: x.itemNo, label: x.itemNo, }))}
                                    value={items.map(x => ({ value: x.itemNo, label: x.itemNo, })).filter(obj => selectedValue.includes(obj.value))}
                                    onChange={handleChange}
                                    className={stylescss.selectReact}
                                    styles={colourStyles}
                                    isMulti
                                />
                                {/* <div className={stylescss.addtag}>
                                    <div className={stylescss.tag_main_div}>
                                        {recomendedTags.map((tag, index) =>
                                            <div key={index} className={stylescss.tag}>{tag}
                                                <button type='button' className={stylescss.tag_button} onClick={() => deleteTagItems(index)}>x</button>
                                            </div>
                                        )}
                                        <textarea
                                            rows={1}
                                            value={inputItems}
                                            placeholder="Enter Recomended Items"
                                            onKeyDown={onKeyDownItems}
                                            onKeyUp={onKeyUpItems}
                                            onChange={onChangeItems}
                                            className={stylescss.add_inputtag}
                                        />
                                    </div>
                                </div> */}
                            </div>
                            {addData.rating &&
                                <div className={stylescss.addItemRow}>
                                    <div className={stylescss.addProductCol3}>Rating</div>
                                    <input
                                        type="text"
                                        placeholder="Enter Rating"
                                        name="rating"
                                        value={addData.rating}
                                        onChange={(e) => setAddData({ ...addData, rating: e.target.value })}
                                        className={stylescss.add_product_input}
                                        required
                                        disabled
                                    />
                                </div>
                            }
                        </div>

                    </div>

                    <div className={stylescss.addItemBtmBtn}>
                        <RedOutButton btnType="button" title="CANCEL" handleSubmit={() => navigate(-1)} />
                        <GreenOutButton btnType="button" title={'WRITE WITH AI'} />
                        <GreenButton btnType="submit" title={item_id ? "UPDATE" : loading? <Spinner size="15" /> :"CREATE"} />
                    </div>
                </form>
            </div>
        </React.Fragment>
    )
}

export default AddItem