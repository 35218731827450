import { useRef, useState } from "react";
import { CancelIcon2, SaveTaxRateIcon } from "../../icons/Icon";
import { CloseIcon } from "../../icons/Icon2";
import Button from "../Dealers/Button";
import InputTag from "../FormComponents/InputTag";
import VerticalDropDown from "../FormComponents/VerticalDropDown";
import styles from "../../../pages/ordermanager/Orders.module.css";
import FileUpload from "./FileUpload";
import VerticalDropDown2 from "../FormComponents/VerticalDropDown2";

const AddReceiptModal = ({
  handleCloseModal,
  mode,
  managerId,
  orderId,
  totalAmount,
  accounts,
}) => {
  // console.log("manager id ", managerId);
  // console.log("order id ", orderId);
  // console.log("account options ", accounts);
  const transactionAmountRef = useRef(null);
  const [reciptData, setReciptData] = useState({
    paymentMethod: "Cash",
    transactionScreenshot: "",
    paymentType: "",
    paymentAmount: "",
    transactionId: "",
    accountNumber: "",
    totalAmount: "",
    receiptId: "",
  });

  console.log("selected account ", reciptData.accountNumber);
  const handleAddReceipt = async () => {
    const myHeaders = new Headers();
    myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
    const formData = new FormData();
    // formData.append("transactionScreenshot", isImage ? [...isImage][0] : "");
    formData.append(
      "paymentMethod",
      reciptData.paymentMethod ? reciptData.paymentMethod : ""
    );
    formData.append(
      "paymentType",
      reciptData.paymentType ? reciptData.paymentType : ""
    );
    formData.append(
      "paymentAmount",
      reciptData.paymentAmount
        ? parseFloat(reciptData.paymentAmount).toFixed(2)
        : ""
    );
    formData.append(
      "transactionId",
      reciptData.transactionId ? reciptData.transactionId : ""
    );
    formData.append(
      "accountNumber",
      reciptData.accountNumber ? reciptData.accountNumber : ""
    );
    formData.append("totalAmount", totalAmount ? totalAmount.toFixed(2) : "");

    const apiData = {
      method: "POST",
      headers: myHeaders,
      body: formData,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_URL +
        "receipt/offlinePay/" +
        managerId +
        "/" +
        orderId +
        "?receiptId=" +
        reciptData.receiptId,
      apiData
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "success") {
          handleCloseModal();
        }
        alert(result.msg);
      })
      .catch((error) => console.log("error", error));
  };

  const methodOptions = [
    { label: "Cash", value: "Cash" },
    { label: "Photo", value: "Photo" },
    { label: "Pay on Delivery", value: "Pay on Delivery" },
  ];

  return (
    <div
      className={styles.add_reciept_modal}
      style={mode ? { backgroundColor: "#232529" } : {}}
    >
      <div>
        <p style={{ color: mode ? "#fff" : "#000" }}>Receipt</p>
        <div
          style={{ cursor: "pointer" }}
          onClick={() => {
            handleCloseModal();
          }}
        >
          <CloseIcon mode={mode} />
        </div>
      </div>
      <div>
        <VerticalDropDown2
          height={"37px"}
          fontSize={"12px"}
          label={"Method"}
          dropDownText={reciptData.paymentMethod || "Select Method"}
          dropDownOpen={true}
          options={methodOptions}
          value={methodOptions.find(
            (option) => option.value === reciptData.paymentMethod
          )}
          onchange={(option) => {
            setReciptData((prevState) => ({
              ...prevState,
              paymentMethod: option.value,
            }));
            transactionAmountRef.current.focus();
          }}
        />
      </div>

      <div>
        <InputTag
          padding={"8px"}
          fontSize={"12px"}
          labelText={"Total Amount"}
          placeholderText={"000.00"}
          value={totalAmount}
          readOnly={true}
        />
      </div>

      {reciptData.paymentMethod === "Photo" && (
        <>
          <div>
            <VerticalDropDown
              height={"37px"}
              fontSize={"12px"}
              label={"Account"}
              dropDownText={
                reciptData.accountNumber
                  ? accounts.find(
                      (a) => a.accountNumber === reciptData.accountNumber
                    )?.acHolderName || "Select"
                  : "Select"
              }
              options={accounts.map((a) => ({
                label: a.acHolderName, 
                value: a.accountNumber, 
              }))}
              value={accounts.find(
                (a) => a.accountNumber === reciptData.accountNumber
              )}
              onchange={(option) => {
                setReciptData((prevState) => ({
                  ...prevState,
                  accountNumber: option.value, 
                }));
              }}
            />
          </div>
          {/* <FileUpload mode={mode} /> */}
          <div>
            <InputTag
              padding={"8px"}
              fontSize={"12px"}
              labelText={"Transtaction ID"}
              placeholderText={"Ref / UTR Number / Transaction ID"}
              value={reciptData.transactionId}
              onChange={(e) =>
                setReciptData((prevState) => ({
                  ...prevState,
                  transactionId: e.target.value,
                }))
              }
              maxLength={20}
              required={true}
            />
          </div>
        </>
      )}

      {reciptData.paymentMethod === "Pay on Delivery" && (
        <>
          <div style={{ gap: "1rem" }}>
            <div style={{ width: "30%" }}>
              <VerticalDropDown
                height={"37px"}
                fontSize={"12px"}
                label={"Sub Method"}
                dropDownText={"Select"}
              />
            </div>
            <div style={{ width: "70%" }}>
              <VerticalDropDown
                height={"37px"}
                fontSize={"12px"}
                label={"Account"}
                dropDownText={"Select"}
              />
            </div>
          </div>
          <FileUpload mode={mode} />
          <div>
            <InputTag
              padding={"8px"}
              fontSize={"12px"}
              labelText={"Transtaction ID"}
              placeholderText={"Ref / UTR Number / Transaction ID"}
              value={reciptData.transactionId}
              onChange={(e) =>
                setReciptData((prevState) => ({
                  ...prevState,
                  transactionId: e.target.value,
                }))
              }
              maxLength={20}
              required={true}
            />
          </div>
        </>
      )}

      <div>
        <InputTag
          padding={"8px"}
          fontSize={"12px"}
          labelText={"Transtaction"}
          placeholderText={"Enter Transaction Amount (In INR)"}
          placeholderType={"number"}
          ref={transactionAmountRef}
          value={reciptData.paymentAmount}
          onChange={(e) =>
            setReciptData((prevState) => ({
              ...prevState,
              paymentAmount: e.target.value,
            }))
          }
          maxLength={10}
          required={true}
        />
      </div>
      <div
        style={{ gap: "1rem", justifyContent: "center", marginTop: "0.5rem" }}
      >
        <div>
          <Button
            buttonBgColor={"#000"}
            buttonText={"CANCEL"}
            buttonTextColor={"#fff"}
            buttonIcon={<CancelIcon2 />}
            buttonClick={handleCloseModal}
          />
        </div>
        <div>
          <Button
            buttonBgColor={"#4164E3"}
            buttonText={"SAVE"}
            buttonTextColor={"#fff"}
            buttonIcon={<SaveTaxRateIcon />}
            buttonClick={() => handleAddReceipt()}
          />
        </div>
      </div>
    </div>
  );
};

export default AddReceiptModal;
