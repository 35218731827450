import React, { useEffect } from 'react'
import { useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { GreenButton, GreenOutButton, RedOutButton } from '../../components/Buttons'
import { useAuth } from '../../components/context/Auth'
import { DeleteIcon, Edit, View } from '../../components/icons/Icon'
import Modal from '../../components/Modal'
import styles from './Product.module.css'

const Items = () => {
    const navigate = useNavigate()
    const { managerId } = useAuth()
    const [data, setData] = useState([])
    const product_name = window.location.pathname.split("/")[window.location.pathname.split("/").length - 2]
    const collection_name = window.location.pathname.split("/")[window.location.pathname.split("/").length - 1]
    const [searchParams] = useSearchParams();
    const getId = searchParams.get("product_id");
    const product_id = (!getId || getId === null) ? "" : getId
    const collId = searchParams.get("id");
    const collection_id = (!collId || collId === null) ? "" : collId
    const [loading, setLoading] = useState(false)
    const [showDeleteItemModal, setShowDeleteItemModal] = useState(false)
    const [deleteItemId, setDeleteItemId] = useState('')
    const [deleteItemTrue, setDeleteItemTrue] = useState(false)
    const [deleteItemName, setDeleteItemName] = useState('')

    useEffect(() => {
        setLoading(true)
        var myHeaders = new Headers();
        myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "collection_id": collection_id,
            "product_id": product_id
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(process.env.REACT_APP_URL + "content/Items", requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status === "success") {
                    setLoading(false)
                    setData(result.data)
                }
            })
            .catch(error => console.log('error', error));
    }, [product_id, collection_id, deleteItemTrue])

    const handleDeleteItem = (deleteItemId) => {
        if (managerId) {
            setDeleteItemTrue(false)
            var myHeaders = new Headers();
            myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                "managerId": managerId,
                "_id": deleteItemId
            });

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            fetch(process.env.REACT_APP_URL + "content/deleteItem", requestOptions)
                .then(response => response.json())
                .then(result => {
                    // alert(result.msg)
                    if (result.status === 'success') {
                        setDeleteItemTrue(true)
                    }
                })
                .catch(error => console.log('error', error));
        }
    }

    return (
        <React.Fragment>
            <Modal
                show={showDeleteItemModal}
                close={setShowDeleteItemModal}
                closeBtn={true}
                heading={deleteItemName&&deleteItemName}
                content={
                    <div className={styles.text_center}>
                        <div className={styles.deleteModal_text}>Are You Sure Want to Delete<p>{deleteItemName&&deleteItemName}</p></div>
                        <div className={styles.massageModal_btn}>
                            <RedOutButton title="CANCEL" handleSubmit={() => setShowDeleteItemModal(false)} />
                            <GreenButton title={<div className={styles.deleteButton}><DeleteIcon />&nbsp;DELETE</div>} handleSubmit={() => { handleDeleteItem(deleteItemId); setShowDeleteItemModal(false) }} />
                        </div>
                    </div>
                }
            />
            <div className={styles.product_main_div}>
                <div className={styles.product_header}>
                    <div >
                        <u onClick={() => navigate(-1)}>
                            &lt; Go Back
                        </u>
                        <h1>{product_name} {'>'} {collection_name}</h1>
                    </div>
                    <div>
                        Sort By
                        <select className={styles.product_filter}>
                            <option className={styles.bg_dark}>View All</option>
                            <option className={styles.bg_dark}>Active</option>
                            <option className={styles.bg_dark}>Disabled</option>
                            <option className={styles.bg_dark}>Deleted</option>
                            <option className={styles.bg_dark}>Ascending</option>
                            <option className={styles.bg_dark}>Descending</option>
                        </select>

                        <GreenOutButton type="button" title="+ ADD ITEM" css="" handleSubmit={() => navigate('/item/' + product_name + "/" + collection_name + "?product_id=" + product_id + "&collection_id=" + collection_id)} />
                    </div>
                </div>
                <div className={styles.product_scroll_div}>
                    {
                        loading ? <div className={'loadingMainDealer'}>
                            <img src='/wallicon.gif' alt='walliconGIF' />
                        </div> :
                            <>
                                {data && data.map((item, index) =>
                                    <div className={styles.productRow} key={index}>
                                        <div className={styles.w_100} onClick={() => navigate('/item/' + product_name + "/" + collection_name + "?itemId=" + item._id + "&product_id=" + product_id + "&collection_id=" + collection_id)}>
                                            <input type="radio" defaultChecked={item.status === "Active" ? true : false} /> <span className={styles.muted_clr}>Active</span>
                                            <h1>
                                                {item.itemNo}
                                            </h1>
                                        </div>
                                        <div className={styles.productBtns}>
                                            <div className={styles.productsEVS} onClick={() => { setShowDeleteItemModal(true); setDeleteItemId(item._id); setDeleteItemName(item.itemNo) }}>
                                                <div><DeleteIcon /></div>
                                                <span>Delete</span>
                                            </div>
                                            <div className={styles.productsEVS} onClick={() => navigate('/item/' + product_name + "/" + collection_name + "?itemId=" + item._id + "&product_id=" + product_id + "&collection_id=" + collection_id)}>
                                                <div><Edit color="#ffffff" /></div>
                                                Edit
                                            </div>
                                            <div className={styles.productsEVS} onClick={() => navigate('/item/' + product_name + "/" + collection_name + "?itemId=" + item._id + "&product_id=" + product_id + "&collection_id=" + collection_id)}>
                                                <div><View /></div>
                                                View
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </>
                    }
                </div>
            </div>
        </React.Fragment >
    )
}

export default Items