import React, {
  useState,
  useEffect,
  lazy,
  Suspense,
  useRef,
  useCallback,
} from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useAuth } from "../../components/context/Auth";
import {
  ArowUpIcon,
  EditManagerIcon,
  NavbarArrowDownIcon,
  NavbarFullScreenIcon,
  NewManagerCancelIcon,
  SaveTaxRateIcon,
  TestTopBackArrowIcon,
  TestTopDotIcon,
} from "../../components/icons/Icon";
import styles from "./Inventory.module.css";

import { useToast } from "../../components/updatedComponents/Toaster/Toaster";

const SearchBar = lazy(() =>
  import("../../components/updatedComponents/FormComponents/SearchBar")
);
const SlidingFilter = lazy(() =>
  import("../../components/updatedComponents/Dealers/SlidingFilter")
);
const TextElement = lazy(() =>
  import("../../components/updatedComponents/FormComponents/TextElement")
);
const Button = lazy(() =>
  import("../../components/updatedComponents/Dealers/Button")
);
const Spinner = lazy(() =>
  import("../../components/updatedComponents/FormComponents/Spinner")
);
const MultiSelectDropdown = lazy(() =>
  import(
    "../../components/updatedComponents/FormComponents/MultiSelectDropdown.jsx"
  )
);
const ManagerModal = lazy(() =>
  import("../../components/updatedComponents/Manager/ManagerModal")
);
const ModalWrapper = lazy(() =>
  import("../../components/updatedComponents/layoutWrapper/ModalWrapper")
);
const RenderRadioButtonGroup = lazy(() =>
  import("./RenderRadioButtonGroup.jsx")
);
const RenderInput = lazy(() => import("./RenderInput.jsx"));
const RenderMultiSelectField = lazy(() =>
  import("./RenderMultiSelectField.jsx")
);

const CollectionInfo = () => {
  const { managerId, mode } = useAuth();
  const { show } = useToast();
  const navigate = useNavigate();
  const params = useParams();
  const liRefs = useRef([]);
  const [searchparams] = useSearchParams();
  const product_id = searchparams.get("product_id");
  const collection_id = searchparams.get("collection_id");
  const collection_name = params.collection;
  const product_name = params.product;
  const [loading, setLoading] = useState(false);
  const [warehouses, setWarehouses] = useState([]);
  const [selectedWarehouses, setSelectedWarehouses] = useState([]);
  const [wholesaleTabs, setWholesaleTabs] = useState("Inventory");
  const [showDropdown, setShowDropdown] = useState(null);
  const [openAll, setOpenAll] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [taxRates, setTaxRates] = useState([]);
  const [itemSearch, setItemSearch] = useState("");
  const [allItems, setAllItems] = useState([]);
  const [updatedItems, setUpdatedItems] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isFocusMode, setIsFocusMode] = useState(false);
  const [focusedIndex, setFocusedIndex] = useState(null);

  const fetchData = useCallback(async () => {
    const myHeaders = new Headers();
    myHeaders.append(
      "wa-platform",
      "WAzxe01MBXU2werWrW2WAIMAdminWi342ASDADAShyIIoKvmYI"
    );
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      managerId: managerId,
      product_id: product_id,
      collection_id: collection_id,
      sortBy: wholesaleTabs.toLowerCase(),
      searchKeyword: itemSearch,
      warehouses:
        selectedWarehouses.length !== 0
          ? selectedWarehouses.map((w) => ({ warehousename: w }))
          : [],
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    setLoading(true);
    fetch(process.env.REACT_APP_URL + "inventory/items", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setLoading(false);
        setAllItems(result.data);
        setWarehouses(result.warehouses);
        if (wholesaleTabs.toLowerCase() === "items") {
          setTaxRates(result.taxRate);
        }
      })

      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  }, [
    collection_id,
    itemSearch,
    managerId,
    product_id,
    selectedWarehouses,
    wholesaleTabs,
  ]);

  useEffect(() => {
    if (!managerId) return;

    const delayDebounceFn = setTimeout(() => {
      fetchData();
    }, 700);

    return () => clearTimeout(delayDebounceFn);
  }, [
    collection_id,
    fetchData,
    itemSearch,
    managerId,
    product_id,
    selectedWarehouses,
    wholesaleTabs,
  ]);

  const handleWarehouseChange = (updatedOptions) => {
    setSelectedWarehouses(updatedOptions);
  };

  const handleFieldChange = (index, field, value) => {
    const updatedItemsCopy = [...allItems];

    let filteredTaxRates = [];

    if (field === "taxRate") {
      filteredTaxRates = taxRates
        .filter((taxRate) => value.includes(taxRate.name))
        .map((taxRate) => ({
          taxRateId: taxRate.taxRateId,
          name: taxRate.name,
          valuev: taxRate.value.toString(),
        }));
      updatedItemsCopy[index] = {
        ...updatedItemsCopy[index],
        [field]: filteredTaxRates,
      };
    } else {
      updatedItemsCopy[index] = { ...updatedItemsCopy[index], [field]: value };
    }

    setAllItems(updatedItemsCopy);

    const itemToUpdate = updatedItemsCopy[index];
    const isItemUpdated = updatedItems.find(
      (item) => item.itemNo === itemToUpdate.itemNo
    );

    if (isItemUpdated) {
      setUpdatedItems((prevUpdatedItems) =>
        prevUpdatedItems.map((item) =>
          item.itemNo === itemToUpdate.itemNo
            ? {
                ...item,
                [field]: field === "taxRate" ? filteredTaxRates : value,
              }
            : item
        )
      );
    } else {
      setUpdatedItems((prevUpdatedItems) => [
        ...prevUpdatedItems,
        {
          ...itemToUpdate,
          [field]: field === "taxRate" ? filteredTaxRates : value,
        },
      ]);
    }
  };

  const handleUpdateItem = async () => {
    const myHeaders = new Headers();
    myHeaders.append(
      "wa-platform",
      "WAzxe01MBXU2werWrW2WAIMAdminWi342ASDADAShyIIoKvmYI"
    );
    myHeaders.append("Content-Type", "application/json");

    const isValidVm = (value) => /^\d+(\.\d{1,4})?$/.test(value);
    const isValidHscode = (value) => value && value.length === 8;

    const invalidItems = updatedItems.filter(
      (item) => !isValidVm(item.vm) || !isValidHscode(item.hscode)
    );

    if (invalidItems.length > 0) {
      console.error("Validation failed for items:", invalidItems);
      show(
        "Validation failed: Ensure 'vm' has up to 4 decimal places and 'hscode' is 8 characters long.",
        "error"
      );
      return;
    }

    const raw = JSON.stringify({
      managerId: managerId,
      warehouses:
        selectedWarehouses.length !== 0
          ? selectedWarehouses.map((w) => ({ warehousename: w }))
          : [],
      itemsdetails: updatedItems.length !== 0 ? updatedItems : [],
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      setLoading(true);
      await fetch(
        process.env.REACT_APP_URL + "inventory/updateInventory",
        requestOptions
      );
      setLoading(false);
      setEditMode(false);
      show("Item Updated Successfully", "success");
    } catch (error) {
      console.error("Error during Updating Item:", error);
      setLoading(false);
    }
  };

  const handleCancel = () => {
    setEditMode(false);
    // setWholesaleTabs("Inventory");
  };

  const toggleDropdown = (index) => {
    setOpenAll(false);

    if (showDropdown === index) {
      setShowDropdown(null);
    } else {
      setShowDropdown(index);
    }
  };

  const handleOpenAll = () => {
    setOpenAll(!openAll);
    setShowDropdown(!openAll ? "all" : null);
  };

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.altKey && e.key.toLowerCase() === "c") {
        e.preventDefault(); // Prevent default behavior for Alt + C
        setFocusedIndex(0); // Start at the first item
        liRefs.current[0]?.focus(); // Focus the first <li> element
        setIsFocusMode(true); // Enable focus mode
      }
    };

    // Listen for keydown event on the window
    window.addEventListener("keydown", handleKeyPress);

    // Cleanup listener on component unmount
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  const handleKeyDown = (e, index) => {
    if (!isFocusMode) return; // Ensure focus mode is active

    if (e.key === "Enter") {
      // Toggle dropdown only on Enter key
      setShowDropdown(showDropdown === index ? null : index);
    } else if (e.key === "ArrowDown") {
      // Move to the next item if not at the last item
      if (index < allItems.length - 1) {
        // Only move if not at last item
        const nextIndex = index + 1;
        setFocusedIndex(nextIndex);
        liRefs.current[nextIndex]?.focus();
        liRefs.current[nextIndex]?.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
        // setShowDropdown(null); 
      }
    } else if (e.key === "ArrowUp") {
      // Move to the previous item if not at the first item
      if (index > 0) {
        // Only move if not at first item
        const prevIndex = index - 1;
        setFocusedIndex(prevIndex);
        liRefs.current[prevIndex]?.focus();
        liRefs.current[prevIndex]?.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
        // setShowDropdown(null); 
      }
    }
  };

  const slidingFilterData = ["Inventory", "Items"];
  const inventoryHeader = [
    "Status",
    "Item",
    "Warehouse",
    "Inventory Type",
    "Weight (Kg)",
    "Volume (CBM)",
    "Opening",
    "Purchase",
    "Sold",
    "Adjust",
    "Closing",
  ];
  const itemsHeader = [
    "Status",
    "Item",
    "Batch No.",
    "Warehouse",
    "Inventory Type",
    "Description",
    "HS Code",
    "Alias",
    "Weight (Kg)",
    "Volume (CBM)",
    "Unit",
    "MRP",
    "Online Price",
    "Offline Price",
  ];
  const sellCondition = [
    { label: "City", value: "City" },
    { label: "State", value: "State" },
    { label: "Country", value: "Country" },
    { label: "Continent", value: "Continent" },
  ];
  const units = [
    { label: "Roll", value: "Roll" },
    { label: "Pcs", value: "Pcs" },
    { label: "Box", value: "Box" },
    { label: "Sq Meter", value: "Sq Meter" },
    { label: "Meter", value: "Meter" },
    { label: "Sq Feet", value: "Sq Feet" },
    { label: "Feet", value: "Feet" },
    { label: "Kg", value: "Kg" },
  ];

  return (
    <>
      <div
        className={styles.inventory}
        style={mode ? { backgroundColor: "#2C2E33", border: "none" } : {}}
      >
        <div className={styles.inventory_top}>
          <div className={styles.inventory_top_left}>
            <div
              className={styles.inventory_top_left_go_back}
              onClick={() => navigate(-1)}
            >
              <TestTopBackArrowIcon color={mode ? "#ffffff" : "#0100E4"} />
              <p
                style={{
                  fontSize: "16px",
                  fontWeight: "500",
                  lineHeight: "24px",
                  margin: 0,
                  paddingTop: "0.21rem",
                  color: mode ? "#FFFFFF" : "#0100E4",
                }}
              >
                Go Back
              </p>
            </div>
            <TestTopDotIcon color={mode ? "#E4E7EB" : "#646B88"} />
            <p
              className={styles.inventory_top_left_path_text}
              style={{
                color: mode ? "#9C9797" : "#646B88",
              }}
            >
              Product/{product_name}/{collection_name}
            </p>
          </div>
        </div>

        <div className={styles.collection_header}>
          <div className={styles.collection_header_left}>
            <div className={styles.inventory_search_bar}>
              <Suspense fallback={<div>Loading...</div>}>
                <SearchBar
                  placeholder={"Search by Item"}
                  value={itemSearch}
                  onChange={(e) => setItemSearch(e.target.value)}
                />
              </Suspense>
            </div>
            <div className={styles.hide}>
              <Suspense fallback={<div>Loading...</div>}>
                <SlidingFilter
                  itemList={slidingFilterData}
                  setFilter={setWholesaleTabs}
                />
              </Suspense>
            </div>
          </div>
          <div className={styles.collection_header_right}>
            <div className={styles.drop_down}>
              <p style={{ color: mode ? "#fff" : "#000" }}>Warehouse</p>
              <div>
                <Suspense fallback={<div>Loading...</div>}>
                  <MultiSelectDropdown
                    label="All"
                    options={warehouses?.map((w) => ({
                      label: w.warehousename,
                      value: w.warehousename,
                    }))}
                    onChange={handleWarehouseChange}
                    mode={mode}
                    padding={"10px"}
                  />
                </Suspense>
              </div>
            </div>
            {wholesaleTabs === "Inventory" && (
              <div
                style={{ cursor: "pointer" }}
                className={styles.hide}
                onClick={() => handleOpenAll()}
              >
                <NavbarFullScreenIcon color={mode ? "#ffffff" : "#646B88"} />
              </div>
            )}
            {wholesaleTabs === "Items" && !editMode && (
              <Suspense fallback={<div>Loading...</div>}>
                <Button
                  buttonIcon={<EditManagerIcon />}
                  buttonText={"Edit"}
                  buttonBgColor={"#4164E3"}
                  buttonTextColor={"#ffffff"}
                  buttonBorder={"none"}
                  buttonClick={() => {
                    setEditMode(true);
                  }}
                />
              </Suspense>
            )}

            {wholesaleTabs === "Items" && editMode && (
              <Suspense fallback={<div>Loading...</div>}>
                <Button
                  buttonIcon={<SaveTaxRateIcon />}
                  buttonText={"SAVE"}
                  buttonBgColor={"#4164E3"}
                  buttonTextColor={"#ffffff"}
                  buttonBorder={"none"}
                  buttonClick={() => {
                    setIsModalOpen(true);
                  }}
                />
              </Suspense>
            )}

            <Suspense fallback={<div>Loading...</div>}>
              <ModalWrapper
                isOpen={isModalOpen}
                onClose={() => {
                  setIsModalOpen(false);
                }}
                noBtn
              >
                <Suspense fallback={<div>Loading...</div>}>
                  <ManagerModal
                    heading={"Update Item"}
                    centerHeading={"Are you sure you want to Update?"}
                    subHeading={"To confirm! type update in the text field."}
                    targetWord={"update"}
                    handleSubmit={() => {
                      handleUpdateItem();
                    }}
                    handleCloseModal={() => setIsModalOpen(false)}
                  />
                </Suspense>
              </ModalWrapper>
            </Suspense>

            {wholesaleTabs === "Items" && editMode && (
              <Suspense fallback={<div>Loading...</div>}>
                <Button
                  buttonIcon={<NewManagerCancelIcon />}
                  buttonText={"CANCEL"}
                  buttonBgColor={"#000000"}
                  buttonTextColor={"#ffffff"}
                  buttonBorder={"none"}
                  buttonClick={handleCancel}
                />
              </Suspense>
            )}
          </div>
        </div>

        <ul
          className={`${styles.responsiveTable} ${styles.hidden_table}`}
          style={{ gap: "5px" }}
        >
          <li
            className={`${styles.collection_list} ${styles.collection_list_FW}`}
            style={
              mode
                ? {
                    backgroundColor: "#232529",
                    color: "#fafafa",
                  }
                : {
                    backgroundColor: "#D9DCE5",
                    color: "#646B88",
                  }
            }
          >
            {wholesaleTabs === "Inventory" ? (
              <>
                {inventoryHeader?.map((h) => (
                  <div className="col" key={h}>
                    {h}
                  </div>
                ))}
              </>
            ) : (
              <>
                {itemsHeader?.map((h) => (
                  <>
                    <div className="col" key={h}>
                      {h}
                    </div>
                  </>
                ))}
              </>
            )}
          </li>

          <div
            className={styles.inventory_data_container}
            style={{ gap: "5px" }}
          >
            {loading ? (
              <div className={styles.collection_container}>
                <Suspense fallback={<div>Loading...</div>}>
                  <Spinner />
                </Suspense>
              </div>
            ) : (
              allItems &&
              allItems?.map((item, index) => (
                <div className={styles.collection_data}>
                  <li
                    key={index}
                    ref={(el) => (liRefs.current[index] = el)}
                    // onBlur={() => setShowDropdown(null)}
                    onFocus={() => setFocusedIndex(index)}
                    // tabIndex="0"
                    // onKeyDown={(e) => {
                    //   if (e.key === "Enter") {
                    //     toggleDropdown(index);
                    //   }
                    // }}
                    // ${
                    //   showDropdown === index || showDropdown === "all"
                    //     ? styles.collection_list_FOCUSED
                    //     : ""
                    // }
                    tabIndex={-1}
                    onKeyDown={(e) => handleKeyDown(e, index)}
                    className={`${styles.collection_list} ${
                      mode ? styles.collection_list_dark : ""
                    }
                  
                    ${
                      index === focusedIndex
                        ? styles.collection_list_FOCUSED
                        : ""
                    }`}
                    style={{
                      borderRadius:
                        showDropdown === index || showDropdown === "all"
                          ? "5px 5px 0 0"
                          : "5px",
                    }}
                  >
                    {wholesaleTabs === "Inventory" ? (
                      <>
                        <div
                          className="col"
                          style={{
                            color:
                              item.status === "Active" ? "#34A853" : "#F93E3E",
                          }}
                        >
                          {item.status === "Active" ? "Active" : "Inactive"}
                        </div>
                        <div style={{ fontWeight: "600" }} className="col">
                          {item.itemNo || ""}
                        </div>
                        <div className={styles.collection_item}>
                          <p>{item.warehouse || ""}</p>
                          <span>{item.warehouse || ""}</span>
                        </div>
                        <div className="col">{item.inventoryType || ""}</div>
                        <div className="col">{item.weight || ""}</div>
                        <div className="col">{item.vm || ""}</div>
                        <div className="col">{item.openings || ""}</div>
                        <div className="col">{item.purchase || ""}</div>
                        <div className="col">{item.sold || ""}</div>
                        <div className="col">{item.adjust || ""}</div>
                        <div className="col">{item.closing || ""}</div>
                        <div>
                          <div
                            onClick={() => toggleDropdown(index)}
                            className={styles.collection_arrow_icon}
                          >
                            {showDropdown === index ||
                            showDropdown === "all" ? (
                              <ArowUpIcon
                                color={mode ? "#ffffff" : "#646B88"}
                              />
                            ) : (
                              <NavbarArrowDownIcon
                                color={mode ? "#ffffff" : "#646B88"}
                              />
                            )}
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div
                          style={{
                            color:
                              item.status === "Active" ? "#34A853" : "#F93E3E",
                          }}
                          className="col"
                        >
                          {item.status === "Active" ? "Active" : "Inactive"}
                        </div>
                        <div style={{ fontWeight: "600" }} className="col">
                          {item.itemNo || ""}
                        </div>
                        <div className="col">{item.batch || ""}</div>
                        <div className={styles.collection_item}>
                          <p>{item.warehouse || ""}</p>
                          <span>{item.warehouse}</span>
                        </div>
                        <div className="col">{item.inventoryType || ""}</div>
                        {["description", "hscode", "alias", "weight", "vm"].map(
                          (i) => (
                            <Suspense fallback={<div>Loading...</div>}>
                              <RenderInput
                                item={item}
                                index={index}
                                mode={mode}
                                editMode={editMode}
                                wholesaleTabs={wholesaleTabs}
                                handleFieldChange={handleFieldChange}
                                field={i}
                                value={item[i]}
                              />
                            </Suspense>
                          )
                        )}
                        <Suspense fallback={<div>Loading...</div>}>
                          {/* <RenderInput
                            item={item}
                            index={index}
                            mode={mode}
                            editMode={editMode}
                            wholesaleTabs={wholesaleTabs}
                            handleFieldChange={handleFieldChange}
                            field={"unit"}
                            value={item["unit"]}
                          /> */}
                          <div className="col">
                            {editMode ? (
                              <select
                                value={item["unit"]}
                                onChange={(e) =>
                                  handleFieldChange(
                                    index,
                                    "unit",
                                    e.target.value
                                  )
                                }
                                className={styles.unit_dd}
                              >
                                {units.map((option, index) => (
                                  <option key={index} value={option.value}>
                                    {option.label}
                                  </option>
                                ))}
                              </select>
                            ) : (
                              item["unit"]
                            )}
                          </div>
                        </Suspense>
                        {["dmrp", "dp", "odp"].map((i) => (
                          <Suspense fallback={<div>Loading...</div>}>
                            <RenderInput
                              item={item}
                              index={index}
                              mode={mode}
                              editMode={editMode}
                              wholesaleTabs={wholesaleTabs}
                              handleFieldChange={handleFieldChange}
                              field={i}
                              value={item[i]}
                            />
                          </Suspense>
                        ))}
                        <div>
                          <div
                            onClick={() => toggleDropdown(index)}
                            className={styles.collection_arrow_icon}
                          >
                            {showDropdown === index ||
                            showDropdown === "all" ? (
                              <ArowUpIcon
                                color={mode ? "#ffffff" : "#646B88"}
                              />
                            ) : (
                              <NavbarArrowDownIcon
                                color={mode ? "#ffffff" : "#646B88"}
                              />
                            )}
                          </div>
                        </div>
                      </>
                    )}
                  </li>

                  {(showDropdown === index || showDropdown === "all") && (
                    <div
                      className={`${styles.collection_hidden_Data} ${
                        mode ? styles.collection_hidden_Data_dark : ""
                      }   ${
                        (openAll && focusedIndex === index) || showDropdown === index
                          ? styles.collection_hidden_Data_focused // Only add if both are true
                          : ""
                      }`}
                      onClick={(e) => e.stopPropagation()}
                    >
                      <div>
                        <Suspense fallback={<div>Loading...</div>}>
                          <RenderRadioButtonGroup
                            label="Our sourced:"
                            value={item.outsourcedItem}
                            key={`${item.itemNo}${item.warehouse}our_sourced`}
                            name={`${item.itemNo}${item.warehouse}our_sourced`}
                            editMode={editMode}
                            options={[
                              { value: "Active", label: "Active" },
                              { value: "Disable", label: "Disable" },
                            ]}
                            onChange={(value) =>
                              handleFieldChange(index, "outsourcedItem", value)
                            }
                            wholesaleTabs={wholesaleTabs}
                            mode={mode}
                          />
                        </Suspense>
                      </div>
                      <div>
                        {wholesaleTabs === "Inventory" ? (
                          <Suspense fallback={<div>Loading...</div>}>
                            <TextElement
                              label={"Recommended Items:"}
                              labelColor={mode ? "#fff" : undefined}
                              valueColor={mode ? "#fff" : undefined}
                              value={
                                item.recommendedItems?.length > 0
                                  ? item.recommendedItems.map((r) => r?.itemNo)
                                  : ""
                              }
                              fontSize={"12px"}
                              fontWeight={"500"}
                            />
                          </Suspense>
                        ) : (
                          <Suspense fallback={<div>Loading...</div>}>
                            <RenderInput
                              label="Limit"
                              item={item}
                              index={index}
                              mode={mode}
                              editMode={editMode}
                              wholesaleTabs={wholesaleTabs}
                              handleFieldChange={handleFieldChange}
                              field="outSourceItemlimit"
                              value={item.outSourceItemlimit}
                            />
                          </Suspense>
                        )}
                      </div>
                      <div>
                        <Suspense fallback={<div>Loading...</div>}>
                          <RenderRadioButtonGroup
                            label="Production Item:"
                            value={item.productionItem}
                            key={`${item.itemNo}${item.warehouse}production_item`}
                            name={`${item.itemNo}${item.warehouse}production_item`}
                            editMode={editMode}
                            options={[
                              { value: "Active", label: "Active" },
                              { value: "Disable", label: "Disable" },
                            ]}
                            onChange={(value) =>
                              handleFieldChange(index, "productionItem", value)
                            }
                            wholesaleTabs={wholesaleTabs}
                            mode={mode}
                          />
                        </Suspense>
                      </div>
                      <div>
                        <Suspense fallback={<div>Loading...</div>}>
                          <RenderMultiSelectField
                            label="Sell Condition"
                            value={item.sellCondition.map((s) => s)}
                            fieldName="sellCondition"
                            options={
                              sellCondition?.length > 0
                                ? sellCondition.map((s) => ({
                                    label: s?.label,
                                    value: s?.value,
                                  }))
                                : []
                            }
                            index={index}
                            mode={mode}
                            editMode={editMode}
                            wholesaleTabs={wholesaleTabs}
                            handleFieldChange={handleFieldChange}
                            width={"120px"}
                          />
                        </Suspense>
                      </div>

                      <div>
                        <Suspense fallback={<div>Loading...</div>}>
                          <RenderMultiSelectField
                            label="Tax Rate"
                            value={item.taxRate.map((t) => t?.name)}
                            fieldName="taxRate"
                            options={
                              taxRates?.length > 0
                                ? taxRates.map((t) => ({
                                    label: t?.name,
                                    value: t?.name,
                                  }))
                                : []
                            }
                            index={index}
                            mode={mode}
                            editMode={editMode}
                            wholesaleTabs={wholesaleTabs}
                            handleFieldChange={handleFieldChange}
                            width="120px"
                          />
                        </Suspense>
                      </div>
                    </div>
                  )}
                </div>
              ))
            )}
            <li
              style={{
                color: "#FFF",
                width: "100%",
                padding: "2rem",
                visibility: "hidden",
              }}
            >
              wdawaddwa
            </li>
          </div>
        </ul>

        <div className={styles.item_card_mob_det_con}>
          {loading && (
            <div className={styles.collection_container}>
              <Suspense fallback={<div>Loading...</div>}>
                <Spinner />
              </Suspense>
            </div>
          )}
          {allItems &&
            allItems.map((i, index) => (
              <div
                className={`${styles.item_card_mob} ${
                  mode ? styles.item_card_mob_dark : ""
                }`}
                key={`${i.itemNo}_${index}`}
                style={
                  mode ? { backgroundColor: "#1B1D21", color: "#fff" } : {}
                }
              >
                <div key={`${i.itemNo}_${index}_info`}>
                  <p>{i.itemNo}</p>
                  <p
                    style={{
                      color: i.status === "Active" ? "#34A853" : "#F93E3E",
                    }}
                  >
                    {i.status}
                  </p>
                </div>
                <div
                  className={styles.item_card_mob_det}
                  key={`${i.itemNo}_${index}_weight`}
                >
                  <div>
                    <p>Weight</p>
                    <p style={mode ? { backgroundColor: "#2C2E33" } : {}}>
                      {i.weight}
                    </p>
                  </div>
                  <div>
                    <p>Purchase</p>
                    <p style={mode ? { backgroundColor: "#2C2E33" } : {}}>
                      {i.purchase}
                    </p>
                  </div>
                </div>
                <div
                  className={styles.item_card_mob_det}
                  key={`${i.itemNo}_${index}_opening`}
                >
                  <div>
                    <p>Opening</p>
                    <p style={mode ? { backgroundColor: "#2C2E33" } : {}}>
                      {i.openings}
                    </p>
                  </div>
                  <div>
                    <p>Closing</p>
                    <p style={mode ? { backgroundColor: "#2C2E33" } : {}}>
                      {i.closing}
                    </p>
                  </div>
                </div>
                <div
                  className={styles.item_card_mob_det}
                  key={`${i.itemNo}_${index}_adjust`}
                >
                  <div>
                    <p>Adjust</p>
                    <p style={mode ? { backgroundColor: "#2C2E33" } : {}}>
                      {i.adjust}
                    </p>
                  </div>
                  <div>
                    <p>Sold</p>
                    <p style={mode ? { backgroundColor: "#2C2E33" } : {}}>
                      {i.sold}
                    </p>
                  </div>
                </div>
                <div
                  className={styles.item_card_mob_os}
                  key={`${i.itemNo}_${index}_outsourced`}
                >
                  <TextElement
                    label={"Our Sourced:"}
                    value={i.outsourcedItem}
                    fontWeight={"400"}
                  />
                </div>
              </div>
            ))}
        </div>
      </div>
    </>
  );
};

export default CollectionInfo;



















// import React, { useState, useEffect } from "react";
// import { useNavigate, useParams, useSearchParams } from "react-router-dom";
// import { GreenButton, RedOutButton } from "../../components/Buttons";
// import { useAuth } from "../../components/context/Auth";
// import {
//   AllInventoryViewIcon,
//   DownArrowCircle,
//   Edit,
//   Search,
//   UpArrowCircle,
// } from "../../components/icons/Icon";
// import Modal from "../../components/Modal";
// import styles from "../inventorymanager/Inventories.module.css";

// const CollectionInfo = () => {
//   const { managerId } = useAuth();
//   const navigate = useNavigate();
//   const params = useParams();
//   const [searchparams] = useSearchParams();
//   const product_id = searchparams.get("product_id");
//   const collection_id = searchparams.get("collection_id");
//   const collection_name = params.collection;
//   const [inputSearch, setInputSearch] = useState("");
//   const [items, setItems] = useState([]);
//   const [collectionInfoModal, setCollectionInfoModal] = useState(false);
//   const [msgShow, setMsgShow] = useState("");
//   const [loading, setLoading] = useState(false);
//   const [warehouses, setWarehouses] = useState([]);
//   const [totalItems, setTotalItems] = useState({});
//   const [reload, setReload] = useState(false);
//   const [wholesaleTabs, setWholesaleTabs] = useState("inventory");
//   const [showDropdown, setShowDropdown] = useState(null);
//   const [collectionInfo, setCollectionInfo] = useState({
//     warehouse: "",
//     description: "",
//     hscode: "",
//     unit: "",
//     odp: "",
//     dp: "",
//     dmrp: "",
//     cu: "",
//     cp: "",
//     cmrp: "",
//     mcp: "",
//     vm: "",
//     accessories: [],
//   });

//   useEffect(() => {
//     if (managerId) {
//       setLoading(true);
//       setItems([]);
//       var myHeaders = new Headers();
//       myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
//       myHeaders.append("Content-Type", "application/json");

//       var raw = JSON.stringify({
//         managerId: managerId,
//         product_id: product_id,
//         collection_id: collection_id,
//         warehousename: collectionInfo.warehouse,
//       });

//       var requestOptions = {
//         method: "POST",
//         headers: myHeaders,
//         body: raw,
//         redirect: "follow",
//       };

//       fetch(
//         process.env.REACT_APP_URL + "inventory/collection-info",
//         requestOptions
//       )
//         .then((response) => response.json())
//         .then((result) => {
//           if (result.status === "success") {
//             setLoading(false);
//             if (result.totalItems) {
//               setTotalItems((prevState) => ({
//                 ...prevState,
//                 weight: result.totalItems.weight ? result.totalItems.weight : 0,
//                 vm: result.totalItems.vm ? result.totalItems.vm : 0,
//                 openingstock: result.totalItems.openingstock
//                   ? result.totalItems.openingstock
//                   : 0,
//                 purchase: result.totalItems.purchase
//                   ? result.totalItems.purchase
//                   : 0,
//                 sold: result.totalItems.sold ? result.totalItems.sold : 0,
//                 adjust: result.totalItems.adjust ? result.totalItems.adjust : 0,
//                 closing: result.totalItems.closing
//                   ? result.totalItems.closing
//                   : 0,
//               }));
//             }
//             if (result.warehouses) {
//               setWarehouses(result.warehouses);
//             }
//             if (result.collectionInfo) {
//               setCollectionInfo((prevState) => ({
//                 ...prevState,
//                 description: result.collectionInfo.inventdesc
//                   ? result.collectionInfo.inventdesc
//                   : "",
//                 hscode: result.collectionInfo.hscode
//                   ? result.collectionInfo.hscode
//                   : "",
//                 unit: result.collectionInfo.unit
//                   ? result.collectionInfo.unit
//                   : "",
//                 odp: result.collectionInfo.odp ? result.collectionInfo.odp : "",
//                 dp: result.collectionInfo.dp ? result.collectionInfo.dp : "",
//                 dmrp: result.collectionInfo.dmrp
//                   ? result.collectionInfo.dmrp
//                   : "",
//                 cu: result.collectionInfo.cu ? result.collectionInfo.cu : "",
//                 cp: result.collectionInfo.cp ? result.collectionInfo.cp : "",
//                 cmrp: result.collectionInfo.cmrp
//                   ? result.collectionInfo.cmrp
//                   : "",
//                 mcp: result.collectionInfo.mcp ? result.collectionInfo.mcp : "",
//                 vm: result.collectionInfo.vm ? result.collectionInfo.vm : "",
//                 accessories: result.collectionInfo.accessories
//                   ? result.collectionInfo.accessories
//                   : "",
//               }));
//             }
//             if (result.items) {
//               setItems(result.items);
//             }
//           }
//         })
//         .catch((error) => console.log("error", error));
//     }
//   }, [managerId, product_id, collection_id, collectionInfo.warehouse, reload]);

//   const handleSubmitCollectionInfo = () => {
//     setReload(false);
//     setMsgShow("");
//     var myHeaders = new Headers();
//     myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
//     myHeaders.append("Content-Type", "application/json");

//     var raw = JSON.stringify({
//       managerId: managerId,
//       collection_id: collection_id,
//       inventdesc: collectionInfo.description,
//       hscode: collectionInfo.hscode,
//       unit: collectionInfo.unit,
//       odp: collectionInfo.odp,
//       dp: collectionInfo.dp,
//       cu: collectionInfo.cu,
//       cp: collectionInfo.cp,
//       mcp: collectionInfo.mcp,
//       // "vm": collectionInfo.vm,
//       dmrp: collectionInfo.dmrp,
//       cmrp: collectionInfo.cmrp,
//       accessories: collectionInfo.accessories,
//       warehousename: collectionInfo.warehouse,
//       itemsdetails: items,
//     });

//     var requestOptions = {
//       method: "POST",
//       headers: myHeaders,
//       body: raw,
//       redirect: "follow",
//     };

//     fetch(
//       process.env.REACT_APP_URL + "inventory/update-collection",
//       requestOptions
//     )
//       .then((response) => response.json())
//       .then((result) => {
//         if (result.status === "success") {
//           setMsgShow(result.message);
//           setCollectionInfoModal(true);
//           setReload(true);
//         } else {
//           alert(result.msg);
//         }
//       })
//       .catch((error) => console.log("error", error));
//   };
//   // const [showDropdown, setShowDropdown] = useState(null);
//   const [openAll, setOpenAll] = useState(false);
//   const [editAllItem, setEditAllItem] = useState(false);
//   // const [showDropdown, setShowDropdown] = useState(null);

//   const toggleDropdown = (index) => {
//     // If openAll is true, reset it to false since we are toggling an individual dropdown
//     setOpenAll(false);

//     // Toggle the individual dropdown
//     if (showDropdown === index) {
//       setShowDropdown(null);
//     } else {
//       setShowDropdown(index);
//     }
//   };

//   const handleOpenAll = () => {
//     setOpenAll(!openAll);
//     setShowDropdown(!openAll ? "all" : null);
//   };

//   const toggleDropdownEdit = (index) => {
//     if (openAll) {
//       setShowDropdown(null);
//       setOpenAll(false);
//     } else {
//       setShowDropdown(index);
//     }
//   };

//   // const [newWeight, setNewWeight] = useState('');
//   // const [newVolume, setNewVolume] = useState('');
//   // const handleBulkChange = () => {
//   //     const updatedItems = items.map(item => ({
//   //         ...item,
//   //         weight: newWeight,
//   //         volume: newVolume
//   //     }));
//   //     setItems(updatedItems);
//   // };
//   // console.log(items);

//   return (
//     <React.Fragment>
//       <Modal
//         show={collectionInfoModal}
//         close={setCollectionInfoModal}
//         closeBtn={true}
//         heading="COLLECTION STATUS"
//         content={
//           <div className={styles.text_center}>
//             <p className={styles.message}>{msgShow}</p>
//             <div className={styles.massageModal_btn}>
//               <RedOutButton
//                 title="CANCEL"
//                 handleSubmit={() => setCollectionInfoModal(false)}
//               />
//               <GreenButton
//                 title="OK"
//                 handleSubmit={() => setCollectionInfoModal(false)}
//               />
//             </div>
//           </div>
//         }
//       />

//       <div className={styles.main_Container}>
//         <div className="d-flex justify-content-between">
//           <div>
//             <span onClick={() => navigate(-1)}>
//               &lt;&nbsp;&nbsp;<span className={styles.goBack}>Go Back</span>
//             </span>
//             <h3 className="mb-0">{collection_name.toUpperCase()}</h3>
//           </div>
//           <div>
//             <div className="d-flex justify-content-left align-items-center mt-2">
//               <h4 className="me-3 mb-0">Warehouse</h4>
//               <select
//                 className={styles.selectDropdown}
//                 value={collectionInfo.warehouse}
//                 onChange={(e) =>
//                   setCollectionInfo({
//                     ...collectionInfo,
//                     warehouse: e.target.value,
//                   })
//                 }
//               >
//                 <option value="">All Warehouse</option>
//                 {warehouses &&
//                   warehouses.map((item, index) => (
//                     <option key={index} value={item.warehousename}>
//                       {item.warehousename}
//                     </option>
//                   ))}
//               </select>
//             </div>
//           </div>
//         </div>

//         <div className={styles.ItemsCollection_div}>
//           <div className="w-25">
//             <div className={styles.wholesale_seacrh_div}>
//               <label className={styles.search_Icon_wholesale}>
//                 {inputSearch.length === 0 && <Search color="#FFFFFF" />}
//               </label>
//               <input
//                 type="search"
//                 placeholder="Search item"
//                 className={styles.seacrh_inputField_wholesale}
//                 value={inputSearch}
//                 onChange={(e) => {
//                   setInputSearch(e.target.value);
//                 }}
//               />
//             </div>
//           </div>

//           <div className="w-25 d-flex justify-content-center">
//             <div className={styles.wholesale_tabs}>
//               <div
//                 className={`${styles.wholesale_single_tab} ${
//                   wholesaleTabs === "inventory" ? styles.tabs_active : ""
//                 }`}
//                 onClick={() => {
//                   setWholesaleTabs("inventory");
//                 }}
//               >
//                 Inventory
//               </div>
//               <div
//                 className={`${styles.wholesale_single_tab} ${
//                   wholesaleTabs === "items" ? styles.tabs_active : ""
//                 }`}
//                 onClick={() => {
//                   setWholesaleTabs("items");
//                 }}
//               >
//                 Items
//               </div>
//             </div>
//           </div>

//           <div className="w-25 d-flex justify-content-end">
//             <div
//               className={`${styles.editAll} me-4`}
//               onClick={() => handleOpenAll()}
//             >
//               <span>
//                 <AllInventoryViewIcon
//                   color={showDropdown === "all" ? "#2196F3" : "#FFFFFF"}
//                 />
//               </span>
//               {/* <span>Edit All</span> */}
//             </div>
//             <div
//               className={styles.editAll}
//               onClick={() => setEditAllItem(!editAllItem)}
//             >
//               <span>
//                 <Edit color={editAllItem ? "#2196F3" : "#FFFFFF"} />
//               </span>
//               {/* <span>Edit All</span> */}
//             </div>
//           </div>
//         </div>

//         {wholesaleTabs === "inventory" ? (
//           <div className={styles.table_heading_div}>
//             <span className={styles.table_heading_shot}>Status</span>
//             <span className={styles.table_heading}>Item</span>
//             <span className={styles.table_heading_mid}>Warehouse</span>
//             <span className={styles.table_heading}>Inventory Type</span>
//             <span className={styles.table_heading}>Weight (Kg)</span>
//             <span className={styles.table_heading}>Volume (CBM)</span>
//             <span className={styles.table_heading_shot}>Opening</span>
//             <span className={styles.table_heading_shot}>Purchase</span>
//             <span className={styles.table_heading_shot}>Sold</span>
//             <span className={styles.table_heading_shot}>Adjust</span>
//             <span className={styles.table_heading_shot}>Closing</span>
//             <span className={styles.table_dropdown}></span>
//           </div>
//         ) : (
//           <div className={styles.table_heading_div}>
//             <span className={styles.table_heading_status}>Status</span>
//             <span className={styles.table_heading}>Item</span>
//             <span className={styles.table_heading_mid}>Batch No.</span>
//             <span className={styles.table_heading_mid}>Warehouse</span>
//             <span className={styles.table_heading}>Inventory Type</span>
//             <span className={styles.table_heading}>Description</span>
//             <span className={styles.table_heading_shot}>HS Code</span>
//             <span className={styles.table_heading_shot}>Alias</span>
//             <span className={styles.table_heading_price}>Weight (Kg)</span>
//             <span className={styles.table_heading}>Volume (CBM)</span>
//             <span className={styles.table_heading_status}>Unit</span>
//             <span className={styles.table_heading_status}>MRP</span>
//             <span className={styles.table_heading_price}>Online Price</span>
//             <span className={styles.table_heading_price}>Offline Price</span>
//             <span className={styles.table_dropdown}></span>
//           </div>
//         )}

//         {loading ? (
//           <div className={"loadingMain"}>
//             <img src="/wallicon.gif" alt="walliconGIF" />
//           </div>
//         ) : (
//           <div className={styles.inventory_scroll_wholesale}>
//             {wholesaleTabs === "inventory" ? (
//               <>
//                 {items &&
//                   items.map((item, index) => (
//                     <div
//                       key={index}
//                       className={styles.table_content_div}
//                       onClick={() => toggleDropdown(index)}
//                     >
//                       <div className={styles.table_content_subdiv}>
//                         <span className={styles.table_txt_shot}>Active</span>
//                         <span className={styles.table_txt}>WSHT4455667</span>
//                         <span className={styles.table_txt_mid}>Golaganj</span>
//                         <span className={styles.table_txt}>Seller</span>
//                         <span className={styles.table_txt}>
//                           <b>12.50</b>
//                         </span>
//                         <span className={styles.table_txt}>
//                           <b>0.2233</b>
//                         </span>
//                         <span className={styles.table_txt_shot}>
//                           <b>200</b>
//                         </span>
//                         <span className={styles.table_txt_shot}>
//                           <b>200</b>
//                         </span>
//                         <span className={styles.table_txt_shot}>
//                           <b>200</b>
//                         </span>
//                         <span className={styles.table_txt_shot}>
//                           <b>200</b>
//                         </span>
//                         <span className={styles.table_txt_shot}>
//                           <b>200</b>
//                         </span>
//                         <span className={styles.table_txt_dropdwon}>
//                           <span className={styles.reciptPositionAbsolute}>
//                             {showDropdown === index ||
//                             showDropdown === "all" ? (
//                               <span
//                                 onClick={(e) => {
//                                   e.stopPropagation();
//                                   toggleDropdown(index);
//                                 }}
//                               >
//                                 <UpArrowCircle color="#FFFFFF" />
//                               </span>
//                             ) : (
//                               <span
//                                 onClick={(e) => {
//                                   e.stopPropagation();
//                                   toggleDropdown(index);
//                                 }}
//                               >
//                                 <DownArrowCircle color="#FFFFFF" />
//                               </span>
//                             )}
//                           </span>
//                         </span>
//                       </div>
//                       {showDropdown === index || showDropdown === "all" ? (
//                         <div
//                           className={styles.dropdown_wholesale_div}
//                           onClick={() => {}}
//                         >
//                           {true && (
//                             <div className={styles.dropdown_reason_wholesale}>
//                               <div>
//                                 <span>Outsourced Item : </span>
//                                 <span>Yes</span>
//                               </div>
//                               <div>
//                                 <span>Production Item : </span>
//                                 <span>No</span>
//                               </div>
//                               <div>
//                                 <span>Sell Condition : </span>
//                                 <span>Not Applicable</span>
//                               </div>
//                               <div>
//                                 <span>Tax Rate : </span>
//                                 <span>Not Applicable</span>
//                               </div>
//                               <div>
//                                 <span>Recommended Items : </span>
//                                 <span>WAS400 WSD334</span>
//                               </div>
//                             </div>
//                           )}
//                         </div>
//                       ) : (
//                         ""
//                       )}
//                     </div>
//                   ))}
//               </>
//             ) : (
//               <>
//                 {items &&
//                   items.map((item, index) => (
//                     <div
//                       key={index}
//                       className={styles.table_content_div}
//                       onClick={() => {
//                         editAllItem
//                           ? toggleDropdownEdit(index)
//                           : toggleDropdown(index);
//                       }}
//                     >
//                       <div className={styles.table_content_subdiv}>
//                         <span className={styles.table_txt_status}>Active</span>
//                         <span className={styles.table_txt}>WSHT44556677</span>
//                         <span className={styles.table_txt_mid}>Seller</span>
//                         <span className={styles.table_txt_mid}>Golaganj</span>
//                         <span className={styles.table_txt}>Own</span>
//                         <span
//                           className={`${styles.table_txt} ${styles.description_truncate}`}
//                           title={"Wallpaper"}
//                         >
//                           {editAllItem ? (
//                             <input
//                               type="text"
//                               value=""
//                               className={styles.itemEdit_input_txt}
//                             />
//                           ) : (
//                             "Wallpaper"
//                           )}
//                         </span>
//                         <span className={styles.table_txt_shot}>
//                           {editAllItem ? (
//                             <input
//                               type="text"
//                               value=""
//                               className={styles.itemEdit_input_txt}
//                             />
//                           ) : (
//                             " 48142000"
//                           )}
//                         </span>
//                         <span className={styles.table_txt_shot}>
//                           {editAllItem ? (
//                             <input
//                               type="text"
//                               value=""
//                               className={styles.itemEdit_input_txt}
//                             />
//                           ) : (
//                             "AL5000"
//                           )}
//                         </span>
//                         <span className={styles.table_txt_price}>
//                           {editAllItem ? (
//                             <input
//                               type="text"
//                               value=""
//                               className={styles.itemEdit_input_number}
//                             />
//                           ) : (
//                             <b>12.50</b>
//                           )}
//                         </span>
//                         <span className={styles.table_txt}>
//                           {editAllItem ? (
//                             <input
//                               type="text"
//                               value=""
//                               className={styles.itemEdit_input_number}
//                             />
//                           ) : (
//                             <b>0.2233</b>
//                           )}
//                         </span>
//                         <span className={styles.table_txt_status}>
//                           {editAllItem ? (
//                             <input
//                               type="text"
//                               value=""
//                               className={styles.itemEdit_input_number}
//                             />
//                           ) : (
//                             <b>SQ Ft</b>
//                           )}
//                         </span>
//                         <span className={styles.table_txt_status}>
//                           {editAllItem ? (
//                             <input
//                               type="text"
//                               value=""
//                               className={styles.itemEdit_input_number}
//                             />
//                           ) : (
//                             <b>200</b>
//                           )}
//                         </span>
//                         <span className={styles.table_txt_price}>
//                           {editAllItem ? (
//                             <input
//                               type="text"
//                               value=""
//                               className={styles.itemEdit_input_number}
//                             />
//                           ) : (
//                             <b>200</b>
//                           )}
//                         </span>
//                         <span className={styles.table_txt_price}>
//                           {editAllItem ? (
//                             <input
//                               type="text"
//                               value=""
//                               className={styles.itemEdit_input_number}
//                             />
//                           ) : (
//                             <b>200</b>
//                           )}
//                         </span>
//                         <span className={styles.table_txt_dropdwon}>
//                           <span className={styles.reciptPositionAbsolute}>
//                             {showDropdown === index ||
//                             showDropdown === "all" ? (
//                               <span
//                                 onClick={(e) => {
//                                   e.stopPropagation();
//                                   if (editAllItem) {
//                                     toggleDropdownEdit(index);
//                                   } else {
//                                     toggleDropdown(index);
//                                   }
//                                 }}
//                               >
//                                 <UpArrowCircle color="#FFFFFF" />
//                               </span>
//                             ) : (
//                               <span
//                                 onClick={(e) => {
//                                   e.stopPropagation();
//                                   if (editAllItem) {
//                                     toggleDropdownEdit(index);
//                                   } else {
//                                     toggleDropdown(index);
//                                   }
//                                 }}
//                               >
//                                 <DownArrowCircle color="#FFFFFF" />
//                               </span>
//                             )}
//                           </span>
//                         </span>
//                       </div>
//                       {showDropdown === index || showDropdown === "all" ? (
//                         <div
//                           className={styles.dropdown_wholesale_div}
//                           onClick={() => {}}
//                         >
//                           {true && (
//                             <div className={styles.dropdown_reason_wholesale}>
//                               <div className="d-flex">
//                                 <span>Outsourced Item : </span>
//                                 {editAllItem ? (
//                                   <div className={styles.activeDisable_btn}>
//                                     <label className="ms-2 me-3">
//                                       <input
//                                         required
//                                         className={styles.activeDisable}
//                                         type="radio"
//                                         name="onlineVisibility"
//                                         value="Active"
//                                         // onChange={(e) => setAddData({ ...addData, onlineVisibility: e.target.value })}
//                                         // checked={addData.onlineVisibility === "Active" ? true : false}
//                                       />
//                                       Yes
//                                     </label>
//                                     <label>
//                                       <input
//                                         required
//                                         className={styles.activeDisable_disable}
//                                         type="radio"
//                                         name="onlineVisibility"
//                                         value="Disable"
//                                         // onChange={(e) => setAddData({ ...addData, onlineVisibility: e.target.value })}
//                                         // checked={addData.onlineVisibility === "Disable" ? true : false}
//                                       />
//                                       No
//                                     </label>
//                                   </div>
//                                 ) : (
//                                   <>&nbsp;Yes</>
//                                 )}
//                               </div>
//                               <div>
//                                 <span>Limit : </span>
//                                 {editAllItem ? (
//                                   <input
//                                     type="text"
//                                     value=""
//                                     className={styles.itemEdit_input}
//                                   />
//                                 ) : (
//                                   <>&nbsp;50 pcs</>
//                                 )}
//                               </div>
//                               <div className="d-flex">
//                                 <span>Production Item : </span>
//                                 {editAllItem ? (
//                                   <div className={styles.activeDisable_btn}>
//                                     <label className="ms-2 me-3">
//                                       <input
//                                         required
//                                         className={styles.activeDisable}
//                                         type="radio"
//                                         name="onlineVisibility"
//                                         value="Active"
//                                         // onChange={(e) => setAddData({ ...addData, onlineVisibility: e.target.value })}
//                                         // checked={addData.onlineVisibility === "Active" ? true : false}
//                                       />
//                                       Yes
//                                     </label>
//                                     <label>
//                                       <input
//                                         required
//                                         className={styles.activeDisable_disable}
//                                         type="radio"
//                                         name="onlineVisibility"
//                                         value="Disable"
//                                         // onChange={(e) => setAddData({ ...addData, onlineVisibility: e.target.value })}
//                                         // checked={addData.onlineVisibility === "Disable" ? true : false}
//                                       />
//                                       No
//                                     </label>
//                                   </div>
//                                 ) : (
//                                   <>&nbsp;Yes</>
//                                 )}
//                               </div>
//                               <div>
//                                 <span>Sell condition : </span>
//                                 {editAllItem ? (
//                                   <select
//                                     value=""
//                                     className={styles.itemEdit_input}
//                                   >
//                                     <option value="">Not Applicable</option>
//                                   </select>
//                                 ) : (
//                                   <>Not Applicable</>
//                                 )}
//                               </div>
//                               <div>
//                                 <span>Tax Rate : </span>
//                                 {editAllItem ? (
//                                   <select
//                                     value=""
//                                     className={styles.itemEdit_input}
//                                   >
//                                     <option value="">Not Applicable</option>
//                                   </select>
//                                 ) : (
//                                   <>Not Applicable</>
//                                 )}
//                               </div>
//                             </div>
//                           )}
//                         </div>
//                       ) : (
//                         ""
//                       )}
//                     </div>
//                   ))}
//               </>
//             )}

//             {/* <div className={styles.collectionInfoCard}>
//                                 <GreenOutButton btnType="button" title="SAVE" css={styles.updateCollectionBtn} handleSubmit={() => { handleSubmitCollectionInfo() }} />
//                                 <h2 className={styles.collection_heading}>Collection Info</h2>
//                                 <div className={styles.collectionInfo_div}>
//                                     <div className={styles.details_div}>
//                                         <span className={styles.details_txt}>Warehouse</span>
//                                         <select
//                                             className={styles.selectDropdown}
//                                             value={collectionInfo.warehouse}
//                                             onChange={(e) => setCollectionInfo({ ...collectionInfo, warehouse: e.target.value })}
//                                         >
//                                             <option value="">All Warehouse</option>
//                                             {warehouses && warehouses.map((item, index) =>
//                                                 <option key={index} value={item.warehousename}>{item.warehousename}</option>
//                                             )}
//                                         </select>
//                                     </div>
//                                     <div className={styles.details_div}>
//                                         <span className={styles.details_txt}>Description</span>
//                                         <input
//                                             type='text'
//                                             className={styles.inputField}
//                                             value={collectionInfo.description}
//                                             onChange={(e) => setCollectionInfo({ ...collectionInfo, description: e.target.value })}
//                                         />
//                                     </div>
//                                     <div className={styles.details_div}>
//                                         <span className={styles.details_txt}>HS Code</span>
//                                         <input
//                                             type='text'
//                                             className={styles.inputField}
//                                             value={collectionInfo.hscode}
//                                             onChange={(e) => setCollectionInfo({ ...collectionInfo, hscode: e.target.value })}
//                                         />
//                                     </div>
//                                     <div className={styles.details_div}>
//                                         <span className={styles.details_txt}>Unit</span>
//                                         <select
//                                             className={styles.selectDropdown}
//                                             value={collectionInfo.unit}
//                                             onChange={(e) => setCollectionInfo({ ...collectionInfo, unit: e.target.value })}
//                                         >
//                                             <option value="" hidden>Select</option>
//                                             <option value="Roll">Roll</option>
//                                             <option value="Pcs">Pcs</option>
//                                             <option value="Box">Box</option>
//                                             <option value="Sq Meter">Sq Meter</option>
//                                             <option value="Meter">Meter</option>
//                                             <option value="Sq Feet">Sq Feet</option>
//                                             <option value="Feet">Feet</option>
//                                             <option value="Kg">Kg</option>
//                                         </select>
//                                     </div>
//                                     <div className={styles.details_div}>
//                                         <span className={styles.details_txt}>Dealer MRP</span>
//                                         <input
//                                             type='number'
//                                             className={styles.inputField}
//                                             value={collectionInfo.dmrp}
//                                             onChange={(e) => setCollectionInfo({ ...collectionInfo, dmrp: e.target.value })}
//                                             onWheel={(e) => e.target.blur()}
//                                         />
//                                     </div>
//                                     <div className={styles.details_div}>
//                                         <span className={styles.details_txt}>Offline Price</span>
//                                         <input
//                                             type='number'
//                                             className={styles.inputField}
//                                             value={collectionInfo.odp}
//                                             onChange={(e) => setCollectionInfo({ ...collectionInfo, odp: e.target.value })}
//                                             onWheel={(e) => e.target.blur()}
//                                         />
//                                     </div>
//                                     <div className={styles.details_div}>
//                                         <span className={styles.details_txt}>Dealer Price</span>
//                                         <input
//                                             type='number'
//                                             className={styles.inputField}
//                                             value={collectionInfo.dp}
//                                             onChange={(e) => setCollectionInfo({ ...collectionInfo, dp: e.target.value })}
//                                             onWheel={(e) => e.target.blur()}
//                                         />
//                                     </div>

//                                     <div className={styles.details_div}>
//                                         <span className={styles.details_txt}>Cust Unit</span>
//                                         <select
//                                             className={styles.selectDropdown}
//                                             value={collectionInfo.cu}
//                                             onChange={(e) => setCollectionInfo({ ...collectionInfo, cu: e.target.value })}
//                                         >
//                                             <option value="" hidden>Select</option>
//                                             <option value="Roll">Roll</option>
//                                             <option value="Pcs">Pcs</option>
//                                             <option value="Box">Box</option>
//                                             <option value="Sq Meter">Sq Meter</option>
//                                             <option value="Meter">Meter</option>
//                                             <option value="Sq Feet">Sq Feet</option>
//                                             <option value="Feet">Feet</option>
//                                             <option value="Kg">Kg</option>
//                                         </select>
//                                     </div>
//                                     <div className={styles.details_div}>
//                                         <span className={styles.details_txt}>Cust MRP</span>
//                                         <input
//                                             type='number'
//                                             className={styles.inputField}
//                                             value={collectionInfo.cmrp}
//                                             onChange={(e) => setCollectionInfo({ ...collectionInfo, cmrp: e.target.value })}
//                                             onWheel={(e) => e.target.blur()}
//                                         />
//                                     </div>
//                                     <div className={styles.details_div}>
//                                         <span className={styles.details_txt}>Cust Price </span>
//                                         <input
//                                             type='number'
//                                             className={styles.inputField}
//                                             value={collectionInfo.cp}
//                                             onChange={(e) => setCollectionInfo({ ...collectionInfo, cp: e.target.value })}
//                                             onWheel={(e) => e.target.blur()}
//                                         />
//                                     </div>
//                                     <div className={styles.details_div}>
//                                         <span className={styles.details_txt}>&#x3e;Cust Price</span>
//                                         <input
//                                             type='number'
//                                             className={styles.inputField}
//                                             value={collectionInfo.mcp}
//                                             onChange={(e) => setCollectionInfo({ ...collectionInfo, mcp: e.target.value })}
//                                             onWheel={(e) => e.target.blur()}
//                                         />
//                                     </div>

//                                     {
//                                         collectionInfo.accessories && collectionInfo.accessories.map((item, index) =>
//                                             <div key={index} className={styles.details_div}>
//                                                 <span className={styles.details_txt}>{item.accessoriesName}</span>
//                                                 <input
//                                                     type='text'
//                                                     className={styles.inputField}
//                                                     value={item.accessoriesPrice}
//                                                     onChange={(e) => setCollectionInfo({
//                                                         ...collectionInfo, accessories: [...collectionInfo.accessories.map((obj1, i1) => {
//                                                             if (i1 === index) {
//                                                                 obj1.accessoriesPrice = e.target.value;
//                                                             }
//                                                             return obj1;
//                                                         })
//                                                         ]
//                                                     })
//                                                     }
//                                                 />
//                                             </div>
//                                         )}
//                                 </div>
//                             </div> */}

//             {/* <h2 className={styles.collection_heading}>Items in Collection</h2>
//                             <div className={styles.ItemsCollection_div}>
//                                 <div className={styles.seacrh_div}>
//                                     <label className={styles.search_Icon}>{inputSearch.length === 0 && <Search color="#FFFFFF" />}</label>
//                                     <input
//                                         type="search"
//                                         placeholder='Search item'
//                                         className={styles.seacrh_inputField}
//                                         value={inputSearch}
//                                         onChange={(e) => { setInputSearch(e.target.value) }}
//                                     />
//                                 </div>

//                                 <div className={styles.editAll}>
//                                     <span><Edit color="#FFFFFF" /></span>
//                                     <span>Edit All</span>
//                                 </div>
//                             </div> */}

//             {/* <div className={styles.collectionCard} >
//                                 <div className={styles.totalItem_div}>
//                                     Total <span className={styles.qty}>{items?.length ? items.length : ''}&nbsp;Items</span>
//                                 </div>
//                                 <div className={styles.weight_div}>
//                                     <div className={styles.view_div}>
//                                         <span className={styles.qty}>Weight</span>
//                                         <span className={styles.qty_txt}>KG</span>
//                                     </div>
//                                     <div className={styles.view_div}>
//                                         <span className={styles.qty}>Volume</span>
//                                         <span className={styles.qty_txt}>CBM</span>
//                                     </div>
//                                 </div>
//                                 <div className={styles.view_div}>
//                                     <span className={styles.qty}>{totalItems.openingstock}</span>
//                                     <span className={styles.qty_txt}>Opening</span>
//                                 </div>
//                                 <div className={styles.view_div}>
//                                     <span className={styles.qty}>{totalItems.purchase}</span>
//                                     <span className={styles.qty_txt}>Purchase</span>
//                                 </div>
//                                 <div className={styles.view_div}>
//                                     <span className={styles.qty}>{totalItems.sold}</span>
//                                     <span className={styles.qty_txt}>Sold</span>
//                                 </div>
//                                 <div className={styles.view_div}>
//                                     <span className={styles.qty}>{totalItems.adjust}</span>
//                                     <span className={styles.qty_txt}>Adjust</span>
//                                 </div>
//                                 <div className={styles.view_div}>
//                                     <span className={styles.qty}>{totalItems.closing}</span>
//                                     <span className={styles.qty_txt}>Closing</span>
//                                 </div>
//                             </div> */}

//             {/* {
//                                 items && items.map((item, index) =>
//                                     <div key={index} className={styles.collectionCard_div} >
//                                         <div className={styles.totalItem_details_div}>
//                                             <div className={styles.activeRadioBtn}>
//                                                 <input type="radio" defaultChecked={item.status === "Active" ? true : false} />
//                                                 <span className={styles.status_active}>Active</span>
//                                             </div>
//                                             <h2 className={styles.itemName}>{item.itemNo}</h2>
//                                             <div className={styles.outsourcedProduct}>
//                                                 <div>Outsourced Product</div>
//                                                 <div className={styles.outsourcedStatus}>
//                                                     <input type="radio" disabled={item.outsourcedProduct === "Yes" ? false : true} defaultChecked={item.outsourcedProduct === "Yes" ? true : false} />Yes
//                                                     <input type="radio" disabled={item.outsourcedProduct === "No" ? false : true} defaultChecked={item.outsourcedProduct === "No" ? true : false} />No
//                                                 </div>
//                                             </div>
//                                         </div>
//                                         <div className={styles.weight_div}>
//                                             <div className={styles.details_div}>
//                                                 <span className={styles.details_txt}>Weight (in kg)</span>
//                                                 <input
//                                                     type='number'
//                                                     className={styles.inputField}
//                                                     value={item.weight}
//                                                     onChange={(e) => {
//                                                         const list = [...items];
//                                                         list[index]["weight"] = e.target.value;
//                                                         setItems(list);
//                                                     }}
//                                                     onWheel={(e) => e.target.blur()}
//                                                 />
//                                             </div>
//                                             <div className={`${styles.details_div} ml-2`}>
//                                                 <span className={styles.details_txt}>Volume (CBM)</span>
//                                                 <input
//                                                     type='number'
//                                                     className={styles.inputField}
//                                                     value={item.vm}
//                                                     onChange={(e) => {
//                                                         const list = [...items];
//                                                         list[index]["vm"] = e.target.value;
//                                                         setItems(list);
//                                                     }}
//                                                     onWheel={(e) => e.target.blur()}
//                                                 />
//                                             </div>
//                                         </div>
//                                         <div className={styles.details_div}>
//                                             <span className={styles.details_txt}>Opening Stock</span>
//                                             <input
//                                                 type='number'
//                                                 className={styles.inputField}
//                                                 value={item.openingstock}
//                                                 onChange={(e) => {
//                                                     const list = [...items];
//                                                     list[index]["openingstock"] = e.target.value;
//                                                     setItems(list);
//                                                 }}
//                                                 onWheel={(e) => e.target.blur()}
//                                             />
//                                         </div>
//                                         <div className={styles.details_div}>
//                                             <span className={styles.details_txt}>Purchase</span>
//                                             <input
//                                                 type='number'
//                                                 className={styles.inputField}
//                                                 value={item.purchase}
//                                                 onChange={(e) => { }}
//                                                 disabled
//                                             />
//                                         </div>
//                                         <div className={styles.details_div}>
//                                             <span className={styles.details_txt}>Sold</span>
//                                             <input
//                                                 type='number'
//                                                 className={styles.inputField}
//                                                 value={item.sold}
//                                                 onChange={(e) => { }}
//                                                 disabled
//                                             />
//                                         </div>
//                                         <div className={styles.details_div}>
//                                             <span className={styles.details_txt}>Adjust</span>
//                                             <input
//                                                 type='number'
//                                                 className={styles.inputField}
//                                                 value={item.adjust}
//                                                 onChange={(e) => { }}
//                                                 disabled
//                                             />
//                                         </div>
//                                         <div className={styles.details_div}>
//                                             <span className={styles.details_txt}>Closing</span>
//                                             <input
//                                                 type='number'
//                                                 className={styles.inputField}
//                                                 value={item.closing}
//                                                 onChange={(e) => { }}
//                                                 disabled
//                                             />
//                                         </div>
//                                     </div>
//                                 )} */}

//             {editAllItem && (
//               <GreenButton
//                 btnType="button"
//                 title="SAVE"
//                 css={styles.CollectionBtn_items}
//                 handleSubmit={() => {
//                   handleSubmitCollectionInfo();
//                 }}
//               />
//             )}
//           </div>
//         )}
//       </div>
//     </React.Fragment>
//   );
// };

// export default CollectionInfo;












// By mohit 

// import React, { useState, useEffect, lazy, Suspense } from "react";
// import { useNavigate, useParams, useSearchParams } from "react-router-dom";
// import { useAuth } from "../../components/context/Auth";
// import {
//   ArowUpIcon,
//   EditManagerIcon,
//   NavbarArrowDownIcon,
//   NavbarFullScreenIcon,
//   NewManagerCancelIcon,
//   SaveTaxRateIcon,
//   TestTopBackArrowIcon,
//   TestTopDotIcon,
// } from "../../components/icons/Icon";
// import styles from "./Inventory.module.css";

// import { useToast } from "../../components/updatedComponents/Toaster/Toaster";

// const SearchBar = lazy(() =>
//   import("../../components/updatedComponents/FormComponents/SearchBar")
// );
// const SlidingFilter = lazy(() =>
//   import("../../components/updatedComponents/Dealers/SlidingFilter")
// );
// const TextElement = lazy(() =>
//   import("../../components/updatedComponents/FormComponents/TextElement")
// );
// const Button = lazy(() =>
//   import("../../components/updatedComponents/Dealers/Button")
// );
// const Spinner = lazy(() =>
//   import("../../components/updatedComponents/FormComponents/Spinner")
// );
// const MultiSelectDropdown = lazy(() =>
//   import(
//     "../../components/updatedComponents/FormComponents/MultiSelectDropdown.jsx"
//   )
// );
// const ManagerModal = lazy(() =>
//   import("../../components/updatedComponents/Manager/ManagerModal")
// );
// const ModalWrapper = lazy(() =>
//   import("../../components/updatedComponents/layoutWrapper/ModalWrapper")
// );

// const RenderRadioButtonGroup = lazy(() =>
//   import("./RenderRadioButtonGroup.jsx")
// );
// const RenderInput = lazy(() => import("./RenderInput.jsx"));
// const RenderMultiSelectField = lazy(() =>
//   import("./RenderMultiSelectField.jsx")
// );

// const CollectionInfo = () => {
//   const { managerId, mode } = useAuth();
//   const { show } = useToast();
//   const navigate = useNavigate();
//   const params = useParams();
//   const [searchparams] = useSearchParams();
//   const product_id = searchparams.get("product_id");
//   const collection_id = searchparams.get("collection_id");
//   const collection_name = params.collection;
//   const product_name = params.product;
//   const [loading, setLoading] = useState(false);
//   const [warehouses, setWarehouses] = useState([]);
//   const [selectedWarehouses, setSelectedWarehouses] = useState([]);
//   const [wholesaleTabs, setWholesaleTabs] = useState("Inventory");
//   const [showDropdown, setShowDropdown] = useState(null);
//   const [openAll, setOpenAll] = useState(false);
//   const [editMode, setEditMode] = useState(false);
//   const [taxRates, setTaxRates] = useState([]);
//   const [itemSearch, setItemSearch] = useState("");
//   const [allItems, setAllItems] = useState([]);
//   const [updatedItems, setUpdatedItems] = useState([]);
//   const [isModalOpen, setIsModalOpen] = useState(false);

//   console.log(loading , "Loading");

  


//   const handleEditClick = () => {
//     console.log("loading 1");
//     if (loading) return; // Prevent clicking while loading
//     console.log("loading 2");
//     setEditMode(true); // Enable edit mode
//   };

//   // Function to handle Done button click
//   const handleDoneClick = () => {
//     if (loading) return; // Prevent clicking while loading
//     setLoading(true); // Start loading state

//     // Simulate saving the changes (could be an API call)
//     setTimeout(() => {
//       setLoading(false); // Stop loading after the operation is done
//       setEditMode(false); // Exit edit mode after done
//     }, 2000); // Simulate async save (adjust duration as needed)
//   };

//   useEffect(() => {
//     const myHeaders = new Headers();
//     myHeaders.append(
//       "wa-platform",
//       "WAzxe01MBXU2werWrW2WAIMAdminWi342ASDADAShyIIoKvmYI"
//     );
//     myHeaders.append("Content-Type", "application/json");

//     const raw = JSON.stringify({
//       managerId: managerId,
//       product_id: product_id,
//       collection_id: collection_id,
//       sortBy: wholesaleTabs.toLowerCase(),
//       searchKeyword: itemSearch,
//       warehouses:
//         selectedWarehouses.length !== 0
//           ? selectedWarehouses.map((w) => ({ warehousename: w }))
//           : [],
//     });

//     const requestOptions = {
//       method: "POST",
//       headers: myHeaders,
//       body: raw,
//       redirect: "follow",
//     };
//     setLoading(true);
//     fetch(process.env.REACT_APP_URL + "inventory/items", requestOptions)
//       .then((response) => response.json())
//       .then((result) => {
//         setLoading(false);
//         setAllItems(result.data);
//         setWarehouses(result.warehouses);
//         if (wholesaleTabs.toLowerCase() === "items") {
//           setTaxRates(result.taxRate);
//         }
//       })

//       .catch((error) => {
//         setLoading(false);
//         console.error(error);
//       });
//   }, [
//     collection_id,
//     itemSearch,
//     managerId,
//     product_id,
//     selectedWarehouses,
//     wholesaleTabs,
//   ]);

//   const handleWarehouseChange = (updatedOptions) => {
//     setSelectedWarehouses(updatedOptions);
//   };

//   const handleFieldChange = (index, field, value) => {
//     const updatedItemsCopy = [...allItems];

//     let filteredTaxRates = [];

//     if (field === "taxRate") {
//       filteredTaxRates = taxRates
//         .filter((taxRate) => value.includes(taxRate.name))
//         .map((taxRate) => ({
//           taxRateId: taxRate.taxRateId,
//           name: taxRate.name,
//           valuev: taxRate.value.toString(),
//         }));
//       updatedItemsCopy[index] = {
//         ...updatedItemsCopy[index],
//         [field]: filteredTaxRates,
//       };
//     } else {
//       updatedItemsCopy[index] = { ...updatedItemsCopy[index], [field]: value };
//     }

//     setAllItems(updatedItemsCopy);

//     const itemToUpdate = updatedItemsCopy[index];
//     const isItemUpdated = updatedItems.find(
//       (item) => item.itemNo === itemToUpdate.itemNo
//     );

//     if (isItemUpdated) {
//       setUpdatedItems((prevUpdatedItems) =>
//         prevUpdatedItems.map((item) =>
//           item.itemNo === itemToUpdate.itemNo
//             ? {
//               ...item,
//               [field]: field === "taxRate" ? filteredTaxRates : value,
//             }
//             : item
//         )
//       );
//     } else {
//       setUpdatedItems((prevUpdatedItems) => [
//         ...prevUpdatedItems,
//         {
//           ...itemToUpdate,
//           [field]: field === "taxRate" ? filteredTaxRates : value,
//         },
//       ]);
//     }
//   };

//   const handleUpdateItem = async () => {
//     const myHeaders = new Headers();
//     myHeaders.append(
//       "wa-platform",
//       "WAzxe01MBXU2werWrW2WAIMAdminWi342ASDADAShyIIoKvmYI"
//     );
//     myHeaders.append("Content-Type", "application/json");
//     const raw = JSON.stringify({
//       managerId: managerId,
//       warehouses:
//         selectedWarehouses.length !== 0
//           ? selectedWarehouses.map((w) => ({ warehousename: w }))
//           : [],
//       itemsdetails: updatedItems.length !== 0 ? updatedItems : [],
//     });

//     const requestOptions = {
//       method: "POST",
//       headers: myHeaders,
//       body: raw,
//       redirect: "follow",
//     };

//     try {
//       setLoading(true);
//       await fetch(
//         process.env.REACT_APP_URL + "inventory/updateInventory",
//         requestOptions
//       );
//       setLoading(false);
//       setEditMode(false);
//       show("Item Updated Successfully", "success");
//     } catch (error) {
//       console.error("Error during Updating Item:", error);
//       setLoading(false);
//     }
//   };

//   const handleCancel = () => {
//     setWholesaleTabs("Inventory");
//     setEditMode(false);
//   };

//   const toggleDropdown = (index) => {
//     setOpenAll(false);

//     if (showDropdown === index) {
//       setShowDropdown(null);
//     } else {
//       setShowDropdown(index);
//     }
//   };

//   const handleOpenAll = () => {
//     setOpenAll(!openAll);
//     setShowDropdown(!openAll ? "all" : null);
//   };

//   const slidingFilterData = ["Inventory", "Items"];
//   const inventoryHeader = [
//     "Status",
//     "Item",
//     "Warehouse",
//     "Inventory Type",
//     "Weight (Kg)",
//     "Volume (CBM)",
//     "Opening",
//     "Purchase",
//     "Sold",
//     "Adjust",
//     "Closing",
//   ];
//   const itemsHeader = [
//     "Status",
//     "Item",
//     "Batch No.",
//     "Warehouse",
//     "Inventory Type",
//     "Description",
//     "HS Code",
//     "Alias",
//     "Weight (Kg)",
//     "Volume (CBM)",
//     "Unit",
//     "MRP",
//     "Online Price",
//     "Offline Price",
//   ];
//   const sellCondition = [
//     { label: "City", value: "City" },
//     { label: "State", value: "State" },
//     { label: "Country", value: "Country" },
//     { label: "Continent", value: "Continent" },
//     { label: "Earth", value: "Earth" },
//     {
//       label: "Solar System",
//       value: "Solar System",
//     },
//     { label: "Galaxy", value: "Galaxy" },
//     { label: "Universe", value: "Universe" },
//   ];

//   return (
//     <>
//       <div
//         className={styles.inventory}
//         style={mode ? { backgroundColor: "#2C2E33", border: "none" } : {}}
//       >
//         <div className={styles.inventory_top}>
//           <div className={styles.inventory_top_left}>
//             <div
//               className={styles.inventory_top_left_go_back}
//               onClick={() => navigate(-1)}
//             >
//               <TestTopBackArrowIcon color={mode ? "#ffffff" : "#0100E4"} />
//               <p
//                 style={{
//                   fontSize: "16px",
//                   fontWeight: "500",
//                   lineHeight: "24px",
//                   margin: 0,
//                   paddingTop: "0.21rem",
//                   color: mode ? "#FFFFFF" : "#0100E4",
//                 }}
//               >
//                 Go Back
//               </p>
//             </div>
//             <TestTopDotIcon color={mode ? "#E4E7EB" : "#646B88"} />
//             <p
//               className={styles.inventory_top_left_path_text}
//               style={{
//                 color: mode ? "#9C9797" : "#646B88",
//               }}
//             >
//               Product/{product_name}/{collection_name}
//             </p>
//           </div>
//         </div>

//         <div className={styles.collection_header}>
//           <div className={styles.collection_header_right}>
//             <div className={styles.inventory_search_bar}>
//               <Suspense fallback={<div>Loading...</div>}>
//                 <SearchBar
//                   placeholder={"Search by Item"}
//                   value={itemSearch}
//                   onChange={(e) => setItemSearch(e.target.value)}
//                 />
//               </Suspense>
//             </div>
//             <div>
//               <Suspense fallback={<div>Loading...</div>}>
//                 <SlidingFilter
//                   itemList={slidingFilterData}
//                   setFilter={setWholesaleTabs}
//                 />
//               </Suspense>
//             </div>
//           </div>
//           <div className={styles.collection_header_right}>
//             <div className={styles.drop_down}>
//               <p style={{ color: mode ? "#fff" : "#000" }}>Warehouse</p>
//               <div style={{ width: "200px" }}>
//                 <Suspense fallback={<div>Loading...</div>}>
//                   <MultiSelectDropdown
//                     label="All"
//                     options={warehouses?.map((w) => ({
//                       label: w.warehousename,
//                       value: w.warehousename,
//                     }))}
//                     onChange={handleWarehouseChange}
//                     mode={mode}
//                   />
//                 </Suspense>
//               </div>
//             </div>
//             {/* <div style={{ cursor: "pointer" }} onClick={() => handleOpenAll()}>
//               <NavbarFullScreenIcon color={mode ? "#ffffff" : "#646B88"} />
//             </div> */}

//             {/* {wholesaleTabs === "Items" && !editMode && (
//               <Suspense fallback={<div>Loading...</div>}>
//                 <Button
//                   buttonIcon={<EditManagerIcon />}
//                   buttonText={"Edit"}
//                   buttonBgColor={"#4164E3"}
//                   buttonTextColor={"#ffffff"}
//                   buttonBorder={"none"}
//                   buttonClick={() => {
//                     setEditMode(true);
//                   }}
//                 />
//               </Suspense>
//             )} */}


//             <div>
//               {/* FullScreen Icon Button */}
//               <div style={{ cursor: "pointer" }} onClick={() => handleOpenAll()}>
//                 <NavbarFullScreenIcon color={mode ? "#ffffff" : "#646B88"} />
//               </div>

//               {/* Edit Button (shown when not in edit mode) */}
//               {wholesaleTabs === "Items" && !editMode && (
//                 <Suspense fallback={<div>Loading...</div>}>
//                   <Button
//                     buttonIcon={<EditManagerIcon />}
//                     buttonText={"Edit"}
//                     buttonBgColor={"#4164E3"}
//                     buttonTextColor={"#ffffff"}
//                     buttonBorder={"none"}
//                     buttonClick={handleEditClick}
//                     disabled={loading} // Disable if loading
//                   />
//                 </Suspense>
//               )}

//               {/* Done Button (shown when in edit mode) */}
//               {wholesaleTabs === "Items" && editMode && (
//                 <Suspense fallback={<div>Loading...</div>}>
//                   <Button
//                     buttonIcon={<SaveTaxRateIcon />} // Replace with Done Icon
//                     buttonText={"Done"}
//                     buttonBgColor={"#34A853"} // Green for Done
//                     buttonTextColor={"#ffffff"}
//                     buttonBorder={"none"}
//                     buttonClick={handleDoneClick}
//                     disabled={loading} // Disable if loading
//                   />
//                 </Suspense>
//               )}

//               {/* Fullscreen Overlay (to block screen visually but allow background events) */}
//               {loading && (
//                 <div
//                   style={{
//                     position: "fixed",
//                     top: 0,
//                     left: 0,
//                     width: "100%",
//                     height: "100%",
//                     backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent background
//                     zIndex: 9999, // Ensure it covers everything
//                     display: "flex",
//                     justifyContent: "center",
//                     alignItems: "center",
//                   }}
//                 >
//                   <div
//                     style={{
//                       color: "#fff",
//                       fontSize: "24px",
//                       fontWeight: "600",
//                       textAlign: "center",
//                     }}
//                   >
//                     Saving... Please wait.
//                   </div>
//                 </div>
//               )}
//             </div>






//             {/* {wholesaleTabs === "Items" && editMode && (
//               <Suspense fallback={<div>Loading...</div>}>
//                 <Button
//                   buttonIcon={<SaveTaxRateIcon />}
//                   buttonText={"SAVE"}
//                   buttonBgColor={"#4164E3"}
//                   buttonTextColor={"#ffffff"}
//                   buttonBorder={"none"}
//                   buttonClick={() => {
//                     setIsModalOpen(true);
//                   }}
//                 />
//               </Suspense>
//             )} */}

//             <Suspense fallback={<div>Loading...</div>}>
//               <ModalWrapper
//                 isOpen={isModalOpen}
//                 onClose={() => {
//                   setIsModalOpen(false);
//                 }}
//                 noBtn
//               >
//                 <Suspense fallback={<div>Loading...</div>}>
//                   <ManagerModal
//                     heading={"Update Item"}
//                     centerHeading={"Are you sure you want to Update?"}
//                     subHeading={"To confirm! type update in the text field."}
//                     targetWord={"update"}
//                     handleSubmit={() => {
//                       handleUpdateItem();
//                     }}
//                     handleCloseModal={() => setIsModalOpen(false)}
//                   />
//                 </Suspense>
//               </ModalWrapper>
//             </Suspense>

//             {wholesaleTabs === "Items" && editMode && (
//               <Suspense fallback={<div>Loading...</div>}>
//                 <Button
//                   buttonIcon={<NewManagerCancelIcon />}
//                   buttonText={"CANCEL"}
//                   buttonBgColor={"#000000"}
//                   buttonTextColor={"#ffffff"}
//                   buttonBorder={"none"}
//                   buttonClick={handleCancel}
//                 />
//               </Suspense>
//             )}
//           </div>
//         </div>

//         <ul className={styles.responsiveTable} style={{ gap: "5px" }}>
//           <li
//             className={
//               wholesaleTabs === "Inventory"
//                 ? `${styles.inventory_list}`
//                 : `${styles.collection_list}`
//             }
//             style={
//               mode
//                 ? {
//                   backgroundColor: "#232529",
//                   color: "#fafafa",
//                   fontWeight: "500",
//                 }
//                 : {
//                   backgroundColor: "#D9DCE5",
//                   color: "#646B88",
//                   fontWeight: "500",
//                 }
//             }
//           >
//             {wholesaleTabs === "Inventory" ? (
//               <>
//                 {inventoryHeader?.map((h) => (
//                   <div className="col" key={h}>
//                     {h}
//                   </div>
//                 ))}
//               </>
//             ) : (
//               <>
//                 {itemsHeader?.map((h) => (
//                   <>
//                     <div className="col" key={h}>
//                       {h}
//                     </div>
//                   </>
//                 ))}
//               </>
//             )}
//           </li>

//           <div
//             className={styles.inventory_data_container}
//             style={{ gap: "5px" }}
//           >
//             {loading ? (
//               <div className={styles.collection_container}>
//                 <Suspense fallback={<div>Loading...</div>}>
//                   <Spinner />
//                 </Suspense>
//               </div>
//             ) : (
//               allItems &&
//               allItems?.map((item, index) => (
//                 <div className={styles.collection_data}>
//                   <li
//                     key={index}
//                     className={
//                       wholesaleTabs === "Inventory"
//                         ? styles.inventory_list
//                         : styles.collection_list
//                     }
//                     style={{
//                       backgroundColor: mode ? "#1B1D21" : "#FFFFFF",
//                       color: mode ? "#fafafa" : "",
//                       borderRadius:
//                         showDropdown === index || showDropdown === "all"
//                           ? "5px 5px 0 0"
//                           : "5px",
//                     }}
//                   >
//                     {wholesaleTabs === "Inventory" ? (
//                       <>
//                         <div
//                           className="col"
//                           style={{
//                             color:
//                               item.status === "Active" ? "#34A853" : "#F93E3E",
//                           }}
//                         >
//                           {item.status === "Active" ? "Active" : "Inactive"}
//                         </div>
//                         <div style={{ fontWeight: "600" }} className="col">
//                           {item.itemNo || ""}
//                         </div>
//                         <div className={styles.collection_item}>
//                           <p>{item.warehouse || ""}</p>
//                           <span>{item.warehouse || ""}</span>
//                         </div>
//                         <div className="col">{item.inventoryType || ""}</div>
//                         <div className="col">{item.weight || ""}</div>
//                         <div className="col">{item.vm || ""}</div>
//                         <div className="col">{item.openings || ""}</div>
//                         <div className="col">{item.purchase || ""}</div>
//                         <div className="col">{item.sold || ""}</div>
//                         <div className="col">{item.adjust || ""}</div>
//                         <div className="col">{item.closing || ""}</div>
//                         <div>
//                           <div
//                             onClick={() => toggleDropdown(index)}
//                             className={styles.collection_arrow_icon}
//                           >
//                             {showDropdown === index ||
//                               showDropdown === "all" ? (
//                               <ArowUpIcon
//                                 color={mode ? "#ffffff" : "#646B88"}
//                               />
//                             ) : (
//                               <NavbarArrowDownIcon
//                                 color={mode ? "#ffffff" : "#646B88"}
//                               />
//                             )}
//                           </div>
//                         </div>
//                       </>
//                     ) : (
//                       <>
//                         <div
//                           style={{
//                             color:
//                               item.status === "Active" ? "#34A853" : "#F93E3E",
//                           }}
//                           className="col"
//                         >
//                           {item.status === "Active" ? "Active" : "Inactive"}
//                         </div>
//                         <div style={{ fontWeight: "600" }} className="col">
//                           {item.itemNo || ""}
//                         </div>
//                         <div className="col">{item.batch || ""}</div>
//                         <div className={styles.collection_item}>
//                           <p>{item.warehouse || ""}</p>
//                           <span>{item.warehouse}</span>
//                         </div>
//                         <div className="col">{item.inventoryType || ""}</div>
//                         {[
//                           "description",
//                           "hscode",
//                           "alias",
//                           "weight",
//                           "vm",
//                           "unit",
//                           "dmrp",
//                           "dp",
//                           "odp",
//                         ].map((i) => (
//                           <Suspense fallback={<div>Loading...</div>}>
//                             <RenderInput
//                               item={item}
//                               index={index}
//                               mode={mode}
//                               editMode={editMode}
//                               wholesaleTabs={wholesaleTabs}
//                               handleFieldChange={handleFieldChange}
//                               field={i}
//                               value={item[i]}
//                             />
//                           </Suspense>
//                         ))}
//                         <div>
//                           <div
//                             onClick={() => toggleDropdown(index)}
//                             className={styles.collection_arrow_icon}
//                           >
//                             {showDropdown === index ||
//                               showDropdown === "all" ? (
//                               <ArowUpIcon
//                                 color={mode ? "#ffffff" : "#646B88"}
//                               />
//                             ) : (
//                               <NavbarArrowDownIcon
//                                 color={mode ? "#ffffff" : "#646B88"}
//                               />
//                             )}
//                           </div>
//                         </div>
//                       </>
//                     )}
//                   </li>

//                   {(showDropdown === index || showDropdown === "all") && (
//                     <div
//                       className={styles.collection_hidden_Data}
//                       onClick={(e) => e.stopPropagation()}
//                       style={{
//                         backgroundColor: mode ? "#1B1D21" : "#FFFFFF",
//                       }}
//                     >
//                       <div>
//                         <Suspense fallback={<div>Loading...</div>}>
//                           <RenderRadioButtonGroup
//                             label="Our sourced:"
//                             value={item.outsourcedItem}
//                             key={`${item.itemNo}${item.warehouse}our_sourced`}
//                             name={`${item.itemNo}${item.warehouse}our_sourced`}
//                             editMode={editMode}
//                             options={[
//                               { value: "Active", label: "Active" },
//                               { value: "Disable", label: "Disable" },
//                             ]}
//                             onChange={(value) =>
//                               handleFieldChange(index, "outsourcedItem", value)
//                             }
//                             wholesaleTabs={wholesaleTabs}
//                             mode={mode}
//                           />
//                         </Suspense>
//                       </div>
//                       <div>
//                         {wholesaleTabs === "Inventory" ? (
//                           <Suspense fallback={<div>Loading...</div>}>
//                             <TextElement
//                               label={"Recommended Items:"}
//                               labelColor={mode ? "#fff" : undefined}
//                               valueColor={mode ? "#fff" : undefined}
//                               value={
//                                 item.recommendedItems?.length > 0
//                                   ? item.recommendedItems.map((r) => r?.itemNo)
//                                   : ""
//                               }
//                               fontSize={"12px"}
//                               fontWeight={"500"}
//                             />
//                           </Suspense>
//                         ) : (
//                           <Suspense fallback={<div>Loading...</div>}>
//                             <RenderInput
//                               label="Limit"
//                               item={item}
//                               index={index}
//                               mode={mode}
//                               editMode={editMode}
//                               wholesaleTabs={wholesaleTabs}
//                               handleFieldChange={handleFieldChange}
//                               field="outSourceItemlimit"
//                               value={item.outSourceItemlimit}
//                             />
//                           </Suspense>
//                         )}
//                       </div>
//                       <div>
//                         <Suspense fallback={<div>Loading...</div>}>
//                           <RenderRadioButtonGroup
//                             label="Production Item:"
//                             value={item.productionItem}
//                             key={`${item.itemNo}${item.warehouse}production_item`}
//                             name={`${item.itemNo}${item.warehouse}production_item`}
//                             editMode={editMode}
//                             options={[
//                               { value: "Active", label: "Active" },
//                               { value: "Disable", label: "Disable" },
//                             ]}
//                             onChange={(value) =>
//                               handleFieldChange(index, "productionItem", value)
//                             }
//                             wholesaleTabs={wholesaleTabs}
//                             mode={mode}
//                           />
//                         </Suspense>
//                       </div>
//                       <div>
//                         <Suspense fallback={<div>Loading...</div>}>
//                           <RenderMultiSelectField
//                             label="Sell Condition"
//                             value={item.sellCondition.map((s) => s)}
//                             fieldName="sellCondition"
//                             options={
//                               sellCondition?.length > 0
//                                 ? sellCondition.map((s) => ({
//                                   label: s?.label,
//                                   value: s?.value,
//                                 }))
//                                 : []
//                             }
//                             index={index}
//                             mode={mode}
//                             editMode={editMode}
//                             wholesaleTabs={wholesaleTabs}
//                             handleFieldChange={handleFieldChange}
//                           />
//                         </Suspense>
//                       </div>

//                       <div>
//                         <Suspense fallback={<div>Loading...</div>}>
//                           <RenderMultiSelectField
//                             label="Tax Rate"
//                             value={item.taxRate.map((t) => t?.name)}
//                             fieldName="taxRate"
//                             options={
//                               taxRates?.length > 0
//                                 ? taxRates.map((t) => ({
//                                   label: t?.name,
//                                   value: t?.name,
//                                 }))
//                                 : []
//                             }
//                             index={index}
//                             mode={mode}
//                             editMode={editMode}
//                             wholesaleTabs={wholesaleTabs}
//                             handleFieldChange={handleFieldChange}
//                             width="120px"
//                           />
//                         </Suspense>
//                       </div>
//                     </div>
//                   )}
//                 </div>
//               ))
//             )}
//           </div>
//         </ul>
//       </div>
//     </>
//   );
// };

// export default CollectionInfo;