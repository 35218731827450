import React, { useEffect, useState } from 'react'
import styles from './AddDealerModalMob.module.css'
import { Spinner } from '../../components/icons/Icon';
import { useAuth } from '../../components/context/Auth';
import ModalMob from './ModalMob';

const AddDealerModalMob = ({ show, close, setData }) => {
  const { managerId } = useAuth()
  const [showAcceptModal, setShowAcceptModal] = useState(false);
  const [msgShow, setMsgShow] = useState("");
  const [managers, setManagers] = useState([]);
  const [loading, setLoading] = useState(false)
  const [addNewDealerData, setAddNewDealerData] = useState({
    name: '',
    designation: '',
    storeName: '',
    mobile: '',
    pincode: '',
    address: '',
    SubDistName: '',
    city: '',
    state: '',
    gst: '',
    whatsapp: '',
    isWhatsaap: true,
    managerId: ""
  })

  useEffect(() => {
    if (show) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
  }, [show,])

  useEffect(() => {
    if (addNewDealerData.pincode.length === 6) {
      var myHeaders = new Headers();
      myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        "keyword": addNewDealerData.pincode,
      });

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      fetch(process.env.REACT_APP_URL + "locations/locations", requestOptions)
        .then(response => response.json())
        .then(result => {
          if (result.status === "success") {
            setAddNewDealerData(prevState => ({
              ...prevState,
              SubDistName: result.SubDistName[0] ? result.SubDistName[0].toUpperCase() : "",
              city: result.districts[0] ? result.districts[0] : "",
              state: result.states[0] ? result.states[0] : "",
            }))
          }
        })
        .catch(error => console.log('error', error));
    }
  }, [addNewDealerData.pincode])

  const handleSubmitAddDealer = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      handleAddDealerApi()
    }
  };

  const handleAddDealerApi = (event) => {
    setLoading(true)
    // event.preventDefault();
    setMsgShow("")
    var myHeaders = new Headers();
    myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      "mobile": addNewDealerData.mobile,
      "refcode": managerId,
      "name": addNewDealerData.name,
      "street": addNewDealerData.address,
      "pincode": addNewDealerData.pincode,
      "city": addNewDealerData.SubDistName,
      "district": addNewDealerData.city,
      "state": addNewDealerData.state,
      "designation": addNewDealerData.designation,
      "gstuin": addNewDealerData.gst,
      "storeName": addNewDealerData.storeName,
      "whatsapp": addNewDealerData.whatsapp,
      "managerId": addNewDealerData.managerId,
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(process.env.REACT_APP_URL + "order/addDealer", requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.status === "success") {
          setMsgShow(result.msg)
          setShowAcceptModal(true)
          close(false)
          setData(prevState => ({
            ...prevState,
            dealerId: result.data.dealerId,
            mobile: result.data.mobile,
            name: result.data.name,
            pincode: result.data.pincode,
            street: result.data.street,
            district: result.data.district,
            city: result.data.city,
            state: result.data.state,
          }))
        }
        else {
          setMsgShow(result.msg)
          setShowAcceptModal(true)
        }
      })
      .catch(error => console.log('error', error))
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    if (show) {
      var myHeaders = new Headers();
      myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        "managerId": managerId,
      });

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      fetch(process.env.REACT_APP_URL + "order/ordermanagers", requestOptions)
        .then(response => response.json())
        .then(result => {
          if (result.status === "success") {
            setManagers(result.data)
            if (result.data.map(obj => obj.managerId === managerId)) {
              setAddNewDealerData(prevState => ({ ...prevState, managerId: managerId }))
            }
          }
        })
        .catch(error => console.log('error', error));
    }
  }, [show, managerId])

  const [dealerWarning, setDealerWarning] = useState("")

  useEffect(() => {
    if (addNewDealerData.mobile.length === 10) {
      var myHeaders = new Headers();
      myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        "managerId": managerId,
        "dealerId": addNewDealerData.mobile
      });

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      fetch(process.env.REACT_APP_URL + "order/checkDealer", requestOptions)
        .then(response => response.json())
        .then(result => {
          if (result.status === "success") {
            setDealerWarning(result.msg)
            alert(result.msg)
          }
        })
        .catch(error => console.log('error', error));
    }
  }, [managerId, addNewDealerData.mobile])

  return show && (
    <div className={styles.modal_backdrop} onClick={() => { close(); }}>
      <div className={styles.modal_content} onClick={e => { e.stopPropagation(); }}  >
        <h3 className={styles.addDealer_heading}>Add Dealer</h3>
        <form onSubmit={handleSubmitAddDealer}>
          <div>
            <label className={styles.order_lebel}>Mobile Number</label>
            &nbsp;
            <input
              type="checkbox"
              className={styles.mobileCheckbox}
              onChange={(e) => {
                if (e.target.checked) {
                  setAddNewDealerData({ ...addNewDealerData, whatsapp: addNewDealerData.mobile })
                } else {
                  setAddNewDealerData({ ...addNewDealerData, whatsapp: '' })
                }
              }}
            />
            <input type="Number"
              placeholder="Enter Mobile Number"
              value={addNewDealerData.mobile}
              onChange={(e) => {
                setAddNewDealerData({ ...addNewDealerData, mobile: e.target.value });
                setDealerWarning("");
              }}
              maxLength="10"
              onInput={(e) => e.target.value = e.target.value.slice(0, 10)}
              required className={styles.AddDealer_input}
            />
            <span className='text-danger fs_12'>{dealerWarning}</span>
          </div>

          <div>
            <label className={styles.order_lebel}>Name</label>
            <input type="text"
              placeholder="Enter Name"
              value={addNewDealerData.name}
              onChange={(e) => setAddNewDealerData({ ...addNewDealerData, name: e.target.value.toLocaleLowerCase().replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase()) })}
              required
              className={styles.AddDealer_input}
            />
          </div>

          <div>
            <label className={styles.order_lebel}>Store/Company Name</label>
            <input
              type="text"
              placeholder="Enter Store/Company Name"
              value={addNewDealerData.storeName}
              onChange={(e) => setAddNewDealerData({ ...addNewDealerData, storeName: e.target.value.toLocaleLowerCase().replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase()) })}
              required
              className={styles.AddDealer_input}
            />
          </div>

          <div>
            <label className={styles.order_lebel}>Designation</label>
            <select
              className={styles.addDealer_select}
              value={addNewDealerData.designation}
              onChange={(e) => setAddNewDealerData({ ...addNewDealerData, designation: e.target.value })}
              required
            >
              <option value="">Select Designation</option>
              <option value="Interior Designer">Interior Designer</option>
              <option value="Store Designer">Store Owner</option>
              <option value="Contractor">Contractor</option>
              <option value="Architect">Architect</option>
              <option value="Others">Others</option>
            </select>
          </div>

          <div>
            <label className={styles.order_lebel}>Assign Order Manager</label>
            <select
              className={styles.addDealer_select}
              value={addNewDealerData.managerId}
              onChange={(e) => setAddNewDealerData({ ...addNewDealerData, managerId: e.target.value })}
              required
            >
              <option value="" >Select Manager</option>
              {managers && managers.map((obj, index) =>
                <option key={index} value={obj.managerId}>{obj.name}</option>
              )}
            </select>
          </div>

          <div>
            <label className={styles.order_lebel}>Whatsapp Number</label>
            <input
              type="Number"
              className={styles.AddDealer_input}
              placeholder="Enter Whatsapp Number"
              value={addNewDealerData.whatsapp}
              onChange={(e) => setAddNewDealerData({ ...addNewDealerData, whatsapp: e.target.value })}
              maxLength="10"
              onInput={(e) => e.target.value = e.target.value.slice(0, 10)}
            />
          </div>

          <div>
            <label className={styles.order_lebel}>Street Address</label>
            <input
              className={styles.AddDealer_input}
              type="text"
              placeholder="Enter Street Address"
              rows="2"
              value={addNewDealerData.address}
              onChange={(e) => setAddNewDealerData({ ...addNewDealerData, address: e.target.value.toLocaleLowerCase().replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase()) })}
              required
            />
          </div>

          <div>
            <label className={styles.order_lebel}>Pincode</label>
            <input
              className={styles.AddDealer_input}
              type="Number"
              placeholder="Enter Pincode"
              value={addNewDealerData.pincode}
              onChange={(e) => setAddNewDealerData({ ...addNewDealerData, pincode: e.target.value })}
              maxLength="6"
              onInput={(e) => e.target.value = e.target.value.slice(0, 6)}
              required
            />
          </div>

          {/* <div>
            <label className={styles.order_lebel}>City</label>
            <input
              className={styles.AddDealer_input}
              type="text"
              placeholder="Enter City"
              value={addNewDealerData.city}
              onChange={(e) => setAddNewDealerData({ ...addNewDealerData, city: e.target.value.toLocaleLowerCase().replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase()) })}
              required
            />
          </div>

          <div>
            <label className={styles.order_lebel}>State</label>
            <input
              className={styles.AddDealer_input}
              type="text"
              placeholder="Enter State"
              value={addNewDealerData.state}
              onChange={(e) => setAddNewDealerData({ ...addNewDealerData, state: e.target.value.toLocaleLowerCase().replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase()) })}
              required
            />
          </div> */}

          <div>
            <label className={styles.order_lebel}>GSTUIN <span className={styles.unbold_text}>(optional)</span></label>
            <input
              className={styles.AddDealer_input}
              type="text"
              placeholder="Enter GSTUIN Number"
              value={addNewDealerData.gst}
              onChange={(e) => setAddNewDealerData({ ...addNewDealerData, gst: e.target.value })}
            />
          </div>

          <div className={styles.orders_flex}>
            <button className={styles.order_btn} onClick={() => close(false)}>CANCEL</button>
            <button btnType="submit" className={styles.order_btn1}>{loading ? <Spinner size="15" /> : "ADD"}</button>
          </div>

        </form>

        <ModalMob
          show={showAcceptModal}
          close={setShowAcceptModal}
          css={styles.date_margin_top}
          content={
            <div className={styles.text_center} >
              <p className={styles.message}>{msgShow}</p>
              <div className={styles.orders_flex}>
                <button className={styles.order_btn} onClick={() => setShowAcceptModal(false)}>CANCEL</button>
                <button onClick={() => setShowAcceptModal(false)} className={styles.order_btn1}>{loading ? <Spinner size="15" /> : "OK"}</button>
              </div>
            </div>
          }
        />

      </div>
    </div>

  )
}
export default AddDealerModalMob