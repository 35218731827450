import React, { useEffect, useState } from 'react'
import { GreenButton, RedOutButton } from './Buttons';
import styles from '../pages/ordermanager/Order.module.css'
import { useAuth } from './context/Auth';
// import Modal from './Modal';
import Modal from 'react-bootstrap/Modal';
import { Spinner } from './icons/Icon';

const AddDealerModal = ({ show, close, setData }) => {
  const { managerId } = useAuth()
  const [showAcceptModal, setShowAcceptModal] = useState(false);
  const [msgShow, setMsgShow] = useState("");
  const [managers, setManagers] = useState([]);
  const [loading, setLoading] = useState(false)
  const [loadingAddress, setLoadingAddress] = useState(false)
  const [addNewDealerData, setAddNewDealerData] = useState({
    name: '',
    designation: '',
    storeName: '',
    mobile: '',
    pincode: '',
    address: '',
    SubDistName: '',
    city: '',
    state: '',
    country: '',
    gst: '',
    whatsapp: '',
    isWhatsaap: true,
    managerId: ""
  })


  useEffect(() => {
    if (addNewDealerData.pincode.length === 6) {
      setLoadingAddress(true)
      let myHeaders = new Headers();
      myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
      myHeaders.append("Content-Type", "application/json");

      let raw = JSON.stringify({
        "keyword": addNewDealerData.pincode,
      });

      let requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      fetch(process.env.REACT_APP_URL + "locations/locations", requestOptions)
        .then(response => response.json())
        .then(result => {
          if (result.status === "success") {
            setLoadingAddress(false)
            setAddNewDealerData(prevState => ({
              ...prevState,
              SubDistName: result.SubDistName[0] ? result.SubDistName[0].toUpperCase() : "",
              city: result.districts[0] ? result.districts[0] : "",
              state: result.states[0] ? result.states[0] : "",
              country: 'India',
            }))
          }
        })
        .catch(error => console.log('error', error));
    }
  }, [addNewDealerData.pincode])

  const handleSubmitAddDealer = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      handleAddDealerApi()
    }
  };

  const handleAddDealerApi = (event) => {
    setLoading(true)
    // event.preventDefault();
    setMsgShow("")
    let myHeaders = new Headers();
    myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
    myHeaders.append("Content-Type", "application/json");

    let raw = JSON.stringify({
      "mobile": addNewDealerData.mobile,
      "refcode": managerId,
      "name": addNewDealerData.name,
      "street": addNewDealerData.address,
      "pincode": addNewDealerData.pincode,
      "city": addNewDealerData.SubDistName,
      "district": addNewDealerData.city,
      "state": addNewDealerData.state,
      "country": addNewDealerData.country,
      "designation": addNewDealerData.designation,
      "gstuin": addNewDealerData.gst,
      "storeName": addNewDealerData.storeName,
      "whatsapp": addNewDealerData.whatsapp,
      "managerId": addNewDealerData.managerId,
    });

    let requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(process.env.REACT_APP_URL + "order/addDealer", requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.status === "success") {
          setMsgShow(result.msg)
          setShowAcceptModal(true)
          close(false)
          setData(prevState => ({
            ...prevState,
            dealerId: result.data.dealerId,
            mobile: result.data.mobile,
            name: result.data.name,
            pincode: result.data.pincode,
            street: result.data.street,
            city: result.data.city,
            district: result.data.district,
            state: result.data.state,
          }))
        }
        else {
          setMsgShow(result.msg)
          setShowAcceptModal(true)
        }
      })
      .catch(error => console.log('error', error))
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    if (show) {
      let myHeaders = new Headers();
      myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
      myHeaders.append("Content-Type", "application/json");

      let raw = JSON.stringify({
        "managerId": managerId,
      });

      let requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      fetch(process.env.REACT_APP_URL + "order/ordermanagers", requestOptions)
        .then(response => response.json())
        .then(result => {
          if (result.status === "success") {
            setManagers(result.data)
            if (result.data.map(obj => obj.managerId === managerId)) {
              setAddNewDealerData(prevState => ({ ...prevState, managerId: managerId }))
            }
          }
        })
        .catch(error => console.log('error', error));
    }
  }, [show, managerId])

  const [dealerWarning, setDealerWarning] = useState("")

  useEffect(() => {
    if (addNewDealerData.mobile.length === 10) {
      let myHeaders = new Headers();
      myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
      myHeaders.append("Content-Type", "application/json");

      let raw = JSON.stringify({
        "managerId": managerId,
        "dealerId": addNewDealerData.mobile
      });

      let requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      fetch(process.env.REACT_APP_URL + "order/checkDealer", requestOptions)
        .then(response => response.json())
        .then(result => {
          if (result.status === "success") {
            setDealerWarning(result.msg)
            alert(result.msg)
          }
        })
        .catch(error => console.log('error', error));
    }
  }, [managerId, addNewDealerData.mobile])

  return (
    <React.Fragment>
      <Modal show={show} onHide={() => {
        close(false);
        setAddNewDealerData(prevState => ({
          ...prevState,
          name: '',
          designation: '',
          storeName: '',
          mobile: '',
          pincode: '',
          address: '',
          SubDistName: '',
          city: '',
          state: '',
          country: '',
          gst: '',
          whatsapp: '',
          isWhatsaap: true,
          managerId: ""
        }))
      }} centered size="lg"
        className={"modalContent"}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Dealer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmitAddDealer}>
            <div className={styles.nameAndDesignation}>
              <div className={styles.inputFieldName}>
                <label className={styles.inputFields_heading}>Mobile Number</label>
                &nbsp;
                <input
                  type="checkbox"
                  className={styles.mobileCheckbox}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setAddNewDealerData({ ...addNewDealerData, whatsapp: addNewDealerData.mobile })
                    } else {
                      setAddNewDealerData({ ...addNewDealerData, whatsapp: '' })
                    }
                  }}
                />
                <input
                  type="Number"
                  className={styles.inputField_name}
                  placeholder="Enter Mobile Number"
                  value={addNewDealerData.mobile}
                  onChange={(e) => {
                    setAddNewDealerData({ ...addNewDealerData, mobile: e.target.value });
                    setDealerWarning("");
                  }}
                  maxLength="10"
                  onInput={(e) => e.target.value = e.target.value.slice(0, 10)}
                  required
                />

                <span className='text-danger fs_12'>{dealerWarning}</span>
              </div>

              <div className={styles.DesignationFields_div}>
                <label className={styles.inputFields_heading}>Name</label>
                <input
                  type="text"
                  className={styles.inputField_name}
                  placeholder="Enter Name"
                  value={addNewDealerData.name}
                  onChange={(e) => setAddNewDealerData({ ...addNewDealerData, name: e.target.value.toLocaleLowerCase().replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase()) })}
                  required
                />
              </div>
            </div>

            <div className={styles.nameAndDesignation}>
              <div className={styles.inputFieldName}>
                <label className={styles.inputFields_heading}>Store/Company Name</label>
                <input
                  type="text"
                  className={styles.inputField_name}
                  placeholder="Enter Store/Company Name"
                  value={addNewDealerData.storeName}
                  onChange={(e) => setAddNewDealerData({ ...addNewDealerData, storeName: e.target.value.toLocaleLowerCase().replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase()) })}
                  required
                />
              </div>

              <div className={styles.DesignationFields_div}>
                <label className={styles.inputFields_heading}>Designation</label>
                <select
                  className={styles.inputField_name}
                  value={addNewDealerData.designation}
                  onChange={(e) => setAddNewDealerData({ ...addNewDealerData, designation: e.target.value })}
                  required
                >
                  <option value="">Select Designation</option>
                  <option value="Interior Designer">Interior Designer</option>
                  <option value="Store Designer">Store Owner</option>
                  <option value="Contractor">Contractor</option>
                  <option value="Architect">Architect</option>
                  <option value="Others">Others</option>
                </select>
              </div>
            </div>
            <div className={styles.nameAndDesignation}>
              <div className={styles.inputFieldName}>
                <label className={styles.inputFields_heading}>Assign Order Manager</label>
                <select
                  className={styles.inputField_name}
                  value={addNewDealerData.managerId}
                  onChange={(e) => setAddNewDealerData({ ...addNewDealerData, managerId: e.target.value })}
                  required
                >
                  <option value="" >Select Manager</option>
                  {managers && managers.map((obj, index) =>
                    <option key={index} value={obj.managerId}>{obj.name}</option>
                  )}
                </select>
              </div>

              <div className={styles.DesignationFields_div}>
                <label className={styles.inputFields_heading}>Whatsapp Number</label>
                <input
                  type="Number"
                  className={styles.inputField_name}
                  placeholder="Enter Whatsapp Number"
                  value={addNewDealerData.whatsapp}
                  onChange={(e) => setAddNewDealerData({ ...addNewDealerData, whatsapp: e.target.value })}
                  maxLength="10"
                  onInput={(e) => e.target.value = e.target.value.slice(0, 10)}
                />
              </div>
            </div>

            <div className={styles.nameAndDesignation}>
              <div className={styles.inputFieldName}>
                <label className={styles.inputFields_heading}>Street Address</label>
                <textarea
                  type="text"
                  className={styles.inputField_name}
                  placeholder="Enter Street Address"
                  rows="2"
                  value={addNewDealerData.address}
                  onChange={(e) => setAddNewDealerData({ ...addNewDealerData, address: e.target.value.toLocaleLowerCase().replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase()) })}
                  required
                />
              </div>
            </div>

            <div className={styles.nameAndDesignation}>
              <div className={styles.inputFieldName}>
                <label className={styles.inputFields_heading}>Pincode</label>
                <input
                  type="Number"
                  className={styles.inputField_name}
                  placeholder="Enter Pincode"
                  value={addNewDealerData.pincode}
                  onChange={(e) => setAddNewDealerData({ ...addNewDealerData, pincode: e.target.value })}
                  maxLength="6"
                  onInput={(e) => e.target.value = e.target.value.slice(0, 6)}
                  required
                />
              </div>

              <div className={styles.DesignationFields_div}>
                <label className={styles.inputFields_heading}>City</label>
                {loadingAddress ?
                  <div className={`${styles.inputField_name} d-flex justify-content-center align-items-center`}><Spinner size="15" /></div>
                  :
                  <input
                    type="text"
                    className={styles.inputField_name}
                    placeholder="Enter City"
                    value={addNewDealerData.SubDistName}
                    onChange={(e) => setAddNewDealerData({ ...addNewDealerData, SubDistName: e.target.value.toUpperCase().replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase()) })}
                    required
                  />
                }

              </div>

              <div className={styles.DesignationFields_div}>
                <label className={styles.inputFields_heading}>District</label>
                {loadingAddress ?
                  <div className={`${styles.inputField_name} d-flex justify-content-center align-items-center`}><Spinner size="15" /></div>
                  :
                  <input
                    type="text"
                    className={styles.inputField_name}
                    placeholder="Enter City"
                    value={addNewDealerData.city}
                    onChange={(e) => setAddNewDealerData({ ...addNewDealerData, city: e.target.value.toLocaleLowerCase().replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase()) })}
                    required
                  />
                }
              </div>
            </div>

            <div className={styles.nameAndDesignation}>
              <div className={styles.inputFieldName}>
                <label className={styles.inputFields_heading}>State</label>
                {loadingAddress ?
                  <div className={`${styles.inputField_name} d-flex justify-content-center align-items-center`}><Spinner size="15" /></div>
                  :
                  <input
                    type="text"
                    className={styles.inputField_name}
                    placeholder="Enter State"
                    value={addNewDealerData.state}
                    onChange={(e) => setAddNewDealerData({ ...addNewDealerData, state: e.target.value.toLocaleLowerCase().replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase()) })}
                    required
                  />
                }
              </div>
              <div className={styles.DesignationFields_div}>
                <label className={styles.inputFields_heading}>GSTUIN <span className={styles.unbold_text}>(optional)</span></label>
                <input
                  type="text"
                  className={styles.inputField_name}
                  placeholder="Enter GSTUIN Number"
                  value={addNewDealerData.gst}
                  onChange={(e) => setAddNewDealerData({ ...addNewDealerData, gst: e.target.value })}
                />
              </div>
            </div>
            <div className={styles.modalButton_div}>
              <RedOutButton btnType="button" title="CANCEL" handleSubmit={() => {
                close(false);
                setAddNewDealerData(prevState => ({
                  ...prevState,
                  name: '',
                  designation: '',
                  storeName: '',
                  mobile: '',
                  pincode: '',
                  address: '',
                  SubDistName: '',
                  city: '',
                  state: '',
                  country: '',
                  gst: '',
                  whatsapp: '',
                  isWhatsaap: true,
                  managerId: ""
                }))
              }} />
              <GreenButton btnType="submit" title={loading ? <Spinner size="15" /> : "ADD"} />
            </div>
          </form>
        </Modal.Body>

      </Modal>

      <Modal show={showAcceptModal} onHide={() => setShowAcceptModal(false)} centered
        className={"modalContent"}
      >
        <Modal.Header >
          <Modal.Title>Add Dealer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          < div className={styles.text_center} >
            <p className={styles.message}>{msgShow}</p>
            <div className={styles.massageModal_btn}>
              <RedOutButton title="CANCEL" handleSubmit={() => setShowAcceptModal(false)} />
              <GreenButton title="OK" handleSubmit={() => { setShowAcceptModal(false) }} />
            </div>
          </div >
        </Modal.Body>

      </Modal>
    </React.Fragment >
  )
}
export default AddDealerModal