import React from "react";
import styles from "./Manager.module.css";
import { ManagerDevices3Dots } from "../../icons/Icon";
import { useAuth } from "../../context/Auth";

const ManagerDevicesTable = () => {
  const { mode } = useAuth();

  const tableData = [
    {
      col1: "Home computer",
      col2: "39c2d4c95b967827",
      col3: "CPH2251",
      col4: "Windows",
      col5: "pending",
      col6: <ManagerDevices3Dots color={mode ? "#ffffff" : "#1C274C"} />,
    },
    {
      col1: "Home computer",
      col2: "39c2d4c95b967827",
      col3: "CPH2251",
      col4: "Linux",
      col5: "pending",
      col6: <ManagerDevices3Dots color={mode ? "#ffffff" : "#1C274C"} />,
    },
    {
      col1: "Home computer",
      col2: "39c2d4c95b967827",
      col3: "CPH2251",
      col4: "Windows",
      col5: "pending",
      col6: <ManagerDevices3Dots color={mode ? "#ffffff" : "#1C274C"} />,
    },
  ];

  const getCellStyle = (mode, baseColor = "#1B1D21") => {
    return mode ? { backgroundColor: baseColor, color: "#E4E7EB" } : {};
  };

  const getHeaderStyle = (mode) => {
    return mode ? { backgroundColor: "#232529", color: "#E4E7EB" } : {};
  };

  return (
    <div
      className={styles.table_container}
      style={mode ? { backgroundColor: "#3D3D3D" } : {}}
    >
      <table className={styles.custom_table}>
        <thead>
          <tr>
            <th style={getHeaderStyle(mode)}>Device Name</th>
            <th style={getHeaderStyle(mode)}>Device ID</th>
            <th style={getHeaderStyle(mode)}>Model</th>
            <th style={getHeaderStyle(mode)}>OS</th>
            <th style={getHeaderStyle(mode)}>Status</th>
            <th style={getHeaderStyle(mode)}>Action</th>
          </tr>
        </thead>
        <tbody>
          {tableData.map((row, index) => (
            <tr key={index}>
              <td style={getCellStyle(mode)}>{row.col1}</td>
              <td style={getCellStyle(mode)}>{row.col2}</td>
              <td style={getCellStyle(mode)}>{row.col3}</td>
              <td style={getCellStyle(mode)}>{row.col4}</td>
              <td
                style={{ color: "#FB9600", backgroundColor: mode && "#1B1D21" }}
              >
                {row.col5}
              </td>
              <td style={getCellStyle(mode)}>{row.col6}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ManagerDevicesTable;
