import React from "react";
import styles from "./globalmobilewhatsappchat.module.css";

import { useNavigate } from "react-router-dom";

const MobileOneToOneChat = ({children}) => {
  const navigate = useNavigate();

  return (
    <React.Fragment>
      <div className={styles.whatsappMessage_chats}>{children}</div>
    </React.Fragment>
  );
};

export default MobileOneToOneChat;
