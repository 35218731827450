import React, { useEffect, useRef, useState } from 'react'
import styles from './ViewDispatchDetails.module.css'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../../components/context/Auth';
import { useReactToPrint } from 'react-to-print';
import { GreenButton, GreenOutButton, RedOutButton } from '../../components/Buttons';
import { DeleteFile, DeleteIcon, DownArrowCircle, UpArrowCircle } from '../../components/icons/Icon';
import { FileHandler } from '../../components/DragAndDrop';
import Modal from '../../components/Modal';
import { PrintLabel } from './PrintLabel';

const ViewDispatchDetails = () => {

    const navigate = useNavigate()
    // const location = useLocation().pathname
    // let componentRef = React.useRef();
    const { switchDisplay, managerId } = useAuth();
    const params = useParams()
    const orderId = params.orderId.includes('saleorder') ? params.orderId.split('&')[0] : params.orderId
    const [loading, setLoading] = useState(true);
    const [show, setShow] = useState(false);
    const [showLabelModal, setShowLabelModal] = useState(false);
    const [showDeliveryModal, setShowDeliveryModal] = useState(false);
    const [showAcceptModal, setShowAcceptModal] = useState(false);
    const [msgShow, setMsgShow] = useState("");
    const [packer, setPacker] = useState(managerId);
    const [couriers, setCouriers] = useState([])
    const [partners, setPartners] = useState("")
    const [data, setData] = useState({})
    const [isReloaded, setIsReloaded] = useState(false)

    const [isImage, setisImage] = React.useState("");

    const [delivery, setDelivery] = useState({
        courierName: "",
        partnerName: "",
    })
    const [printLabel, setPrintLabel] = useState({
        qtyPacks: "",
        delPerName: "",
        delPerMobile: "",
        delTrackingNo: "",
        delTrackingUrl: "",
        courierSlip: "",
    })
    const [courierSlip, setCourierSlip] = useState()
    // const [detailShow, setDetailShow] = useState(false);
    const [showPackedModal, setShowPackedModal] = useState(false);
    const [check, setCheck] = useState(false);
    const [images, setImages] = useState([]);
    const [packs, setPacks] = useState()
    const [point, setPoint] = useState()

    useEffect(() => {
        if (managerId) {
            var myHeaders = new Headers();
            myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                "managerId": managerId,
                "orderId": orderId,
                "courierName": delivery.courierName
            });

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            fetch(process.env.REACT_APP_URL + "dispatch/order", requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.status === "success") {
                        setData(result.data)
                        setPacker(result.data.order.pmId)
                        setCouriers(result.data.couriers)
                        setPartners(result.data.courierPartners)
                        setPrintLabel(prevState => ({
                            ...prevState,
                            qtyPacks: result.data.order.qtyPacks,
                            delPerName: result.data.order.delPerName,
                            delPerMobile: result.data.order.delPerMobile,
                            delTrackingNo: result.data.order.delTrackingNo,
                            delTrackingUrl: result.data.order.delTrackingUrl,
                            courierSlip: result.data.order.courierSlip,
                        }))
                        if (!delivery.courierName) {
                            setDelivery(prevState => ({
                                ...prevState,
                                courierName: result.data.order.courierName,
                                partnerName: result.data.order.partnerName,
                                shippingType: result.data.order.shippingType,
                            }))
                        }
                        // setCourierSlip(result.data.order.courierSlip)
                    }
                    setLoading(false)
                })
                .catch(error => console.log('error', error));
        }
    }, [orderId, managerId, delivery.courierName, isReloaded])

    // -----------------Courier---------//

    const handleAcceptOrder = (e) => {
        e.preventDefault()

        var myHeaders = new Headers();
        myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "managerId": managerId,
            "orderId": orderId,
            "pmId": packer ? packer : managerId,
            "courierName": delivery.courierName,
            "partnerName": delivery.partnerName,
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(process.env.REACT_APP_URL + "dispatch/acceptOrder", requestOptions)
            .then(response => response.json())
            .then(result => {
                setMsgShow(result.msg)
                setShowAcceptModal(true)
            })
            .catch(error => console.log('error', error));
    }
    useEffect(() => {
        if (packs) {
            setPoint(Array.from(Array(packs === "0" ? 1 : packs === "1" ? 1 : packs === "2" ? 2 : parseInt(packs - 1)).keys()))
        }
    }, [packs])



    const addFormFields = () => {
        point.forEach(element => {
            setImages(prevState => ([...prevState, {}]))
        });
    }

    useEffect(() => {
        if (check && (images.length < parseInt(packs))) {
            point.forEach(element => {
                setImages(prevState => [
                    ...prevState.slice(0, images.length),
                    {},
                    // ...images.slice(index + 1)
                ])
            });
        }
    }, [check, images.length, packs, point])


    const handlePrintLabel = () => {
        var myHeaders = new Headers();
        myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "managerId": managerId,
            "orderId": orderId,
            "qtyPacks": printLabel.qtyPacks,
            "delPerName": printLabel.delPerName,
            "delPerMobile": printLabel.delPerMobile,
            "delTrackingNo": printLabel.delTrackingNo,
            "delTrackingUrl": printLabel.delTrackingUrl,
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(process.env.REACT_APP_URL + "dispatch/printLabel", requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status === "success") {
                    handlePrint()
                    setShowLabelModal(false)
                } else {
                    alert(result.msg)
                }
            })
            .catch(error => console.log('error', error));
    }

    const handlePacked = (e) => {
        e.preventDefault();
        var myHeaders = new Headers();
        myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);

        var formdata = new FormData();
        formdata.append("packingQty", packs);
        formdata.append("images", images);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch(process.env.REACT_APP_URL + "packing/orderPacked/" + managerId + "/" + orderId, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status === "success") {
                    window.location.reload()
                } else {
                    alert(result.msg)
                }
            })
            .catch(error => console.log('error', error));
    }

    const labelRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => labelRef.current
    });

    const handleDispatched = () => {
        var myHeaders = new Headers();
        myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);

        var formdata = new FormData();
        formdata.append("shippingType", delivery.shippingType);
        formdata.append("courierName", delivery.courierName);
        formdata.append("partnerName", delivery.partnerName);

        formdata.append("delPerName", printLabel.delPerName);
        formdata.append("delPerMobile", printLabel.delPerMobile);
        formdata.append("delTrackingNo", printLabel.delTrackingNo);
        formdata.append("delTrackingUrl", printLabel.delTrackingUrl);
        formdata.append("image", courierSlip[0]);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };
        fetch(process.env.REACT_APP_URL + "dispatch/deliveryDetails/" + managerId + "/" + orderId, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status === "success") {
                    setShowDeliveryModal(false)
                    setIsReloaded(Math.random())
                }
                alert(result.msg)
            })
            .catch(error => console.log('error', error));
    }

    useEffect(() => {
        if (isImage) {
            setImages()
        }
    }, [isImage])

    const handleSubmit = (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            handleDispatched()
        }
    };

    const filterItem = (data.order && data.order.items && data.order.items.length>0)&& data.order.items.filter(item => item.qty>0)

    return (
        <React.Fragment>
            <Modal
                show={showAcceptModal}
                close={setShowAcceptModal}
                closeBtn={true}
                heading="DISPATCH ORDER STATUS"
                content={
                    <div className={styles.text_center}>
                        <p className={styles.message}>{msgShow}</p>
                        <div className={styles.massageModal_btn}>
                            <RedOutButton title="CANCEL" handleSubmit={() => setShowAcceptModal(false)} />
                            <GreenButton title="OK" handleSubmit={() => { setShowAcceptModal(false); window.location.reload() }} />
                        </div>
                    </div>
                }
            />

            <Modal
                show={showPackedModal}
                close={setShowPackedModal}
                closeBtn={true}
                heading="PACKING STATUS"
                content={
                    <form onSubmit={handlePacked} className={styles.text_center}>
                        <div className={styles.main_div}>
                            <div>
                                <span>Enter Number Of Packs:</span>&nbsp;&nbsp;
                                <input type="number"
                                    name="packs"
                                    value={packs}
                                    // setPacks
                                    onInput={(e) => e.target.value = e.target.value.slice(0, 4)}
                                    onChange={(e) => {
                                        setPacks(e.target.value)

                                        if (check && e.target.value && (images.length > parseInt(e.target.value))) {
                                            const values = [...images];
                                            values.splice(point, (images.length - (parseInt(e.target.value) === 0 ? 1 : parseInt(e.target.value))));
                                            setImages(values);
                                        }

                                    }}
                                    className={styles.enterPackets}
                                    required
                                    autoFocus={true}
                                />
                            </div>

                            <div className={styles.checkBox_addImage}>
                                <input
                                    type="checkbox"
                                    name="vehicle1"
                                    value="check"
                                    className={styles.checkbox}
                                    checked={check ? true : false}
                                    onChange={(e) => {
                                        if (e.target.checked && packs) {
                                            addFormFields()
                                            setCheck(true)
                                        } else {
                                            const values = [...images];
                                            values.splice(point, point.length);
                                            setImages(values);
                                            setCheck(false)
                                        }
                                    }}
                                />
                                <label htmlFor="vehicle1">&nbsp;&nbsp;Add image of every pack</label>
                            </div>

                            <div className={styles.dragImage_div}>
                                <FileHandler
                                    setisImage={setisImage}
                                    styles={styles}
                                />
                            </div>

                            {/* <div className={styles.dragImage_div}>
                                <span> <img src='/assets/packing_image.png' alt="item_image" className={styles.packing_image} /></span>
                                <p className={styles.drag_txt}>Drag photos and documents</p>
                                <hr className={styles.border_OR_div} />
                                <span className={styles.OR_css}>OR</span>
                                <button type='button' className={styles.browseFiles_btn}>Browse Photos</button>
                            </div> */}

                            <div>
                                {images.map((item, index) =>
                                    <div key={index} className={styles.flexdiv}>
                                        <span>Pack {index + 1}</span>
                                        {/* <label className={styles.label}>
                                            <input
                                                name=""
                                                type="file"
                                                hidden
                                                onChange={(e) => {
                                                    let data = [...images];
                                                    data[index] = e.target.files[0];
                                                    setImages(data);
                                                }}
                                            />
                                            <UpLoadFile />&nbsp;Upload Your Photos Here
                                        </label> */}

                                        {item.name ? <img src={URL.createObjectURL(item)} alt="" className={styles.fileimg}

                                            onClick={() => window.open(URL.createObjectURL(item), "mozillaWindow", "popup")}
                                        /> :
                                            <img
                                                src='/assets/packingImage.png'
                                                alt='packing_order_image'
                                                className={styles.packingImage}
                                            />
                                        }

                                        <div className={styles.view_packingImage} onClick={() => window.open(URL.createObjectURL(item), "mozillaWindow", "popup")} >View</div>

                                        <div className={styles.deletediv}
                                            onClick={e => {
                                                let data = [...images];
                                                data[index] = {};
                                                setImages(data);
                                            }}
                                        >
                                            <DeleteFile color={"red"} />
                                            <p className={styles.deleteicon}>Delete</p>
                                        </div>
                                        {images.length !== 1 &&
                                            <div className={styles.deletediv}
                                                onClick={e => {
                                                    setPacks(parseInt(packs) - 1)
                                                    const list = [...images];
                                                    list.splice(index, 1);
                                                    setImages(list);
                                                }}
                                            >
                                                <span className={styles.crossIcon}> &#10006;</span>
                                            </div>
                                        }
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className={styles.packingModal_btn}>
                            <RedOutButton btnType="button" title="CANCEL" handleSubmit={() => setShowPackedModal(false)} />
                            <GreenButton btnType="submit" title="OK" />
                        </div>
                    </form>
                }
            />

            <Modal
                show={showLabelModal}
                close={setShowLabelModal}
                closeBtn={true}
                heading="PRINT LABEL"
                content={
                    <div className={styles.text_center}>
                        <p className={styles.sliptext}>Are you sure you want to Print Label & Marked as Packed?</p>
                        <div className={styles.modal_div}>
                            <p className={styles.sliptext}>Enter Number Of Packs</p>
                            <input
                                name="name"
                                type="number"
                                className={`${switchDisplay ? styles.inputcolorpack : styles.inputpack}`}
                                value={printLabel.qtyPacks}
                                onChange={(e) => {
                                    setPrintLabel(prevState => ({
                                        ...prevState,
                                        qtyPacks: e.target.value
                                    }))
                                }}
                            />
                        </div>
                        {show ?
                            <div>
                                <div className={styles.modal_div}>
                                    <input
                                        name="name"
                                        type="text"
                                        placeholder='Name'
                                        className={`${switchDisplay ? styles.inputcolor : styles.input}`}
                                        value={printLabel.delPerName}
                                        onChange={(e) => {
                                            setPrintLabel(prevState => ({
                                                ...prevState,
                                                delPerName: e.target.value
                                            }))
                                        }}
                                    />
                                    <input
                                        name="mobile"
                                        type="number"
                                        placeholder='Mobile Number'
                                        className={`${switchDisplay ? styles.inputcolor : styles.input}`}
                                        value={printLabel.delPerMobile}
                                        onChange={(e) => {
                                            setPrintLabel(prevState => ({
                                                ...prevState,
                                                delPerMobile: e.target.value
                                            }))
                                        }}
                                    />
                                </div>
                                <div className={styles.modal_div}>
                                    <input
                                        name="name"
                                        type="text"
                                        placeholder='Tracking Number'
                                        className={`${switchDisplay ? styles.inputcolor : styles.input}`}
                                        value={printLabel.delTrackingNo}
                                        onChange={(e) => {
                                            setPrintLabel(prevState => ({
                                                ...prevState,
                                                delTrackingNo: e.target.value
                                            }))
                                        }}
                                    />
                                    <input
                                        name="name"
                                        type="text"
                                        placeholder='Tracking URL'
                                        className={`${switchDisplay ? styles.inputcolor : styles.input}`}
                                        value={printLabel.delTrackingUrl}
                                        onChange={(e) => {
                                            setPrintLabel(prevState => ({
                                                ...prevState,
                                                delTrackingUrl: e.target.value
                                            }))
                                        }}
                                    />
                                </div>
                            </div> : ""
                        }
                        <div className={styles.modalBtn_div}>
                            <RedOutButton title={"CANCEL"} handleSubmit={() => { setShowLabelModal(false) }} />
                            <div className={styles.printicon}>
                                <GreenOutButton css={!printLabel.qtyPacks ? styles.disableBtn : ''} title={"PRINT"} disabled={printLabel.qtyPacks ? false : true} handleSubmit={() => { handlePrintLabel() }} />
                            </div>
                            <div style={{ display: "none" }}>
                                <PrintLabel
                                    data={data.order}
                                    qtyPacks={printLabel.qtyPacks}
                                    ref={labelRef}
                                    handlePrint={handlePrint}
                                />
                            </div>
                        </div>
                        <div className={styles.modal_div} onClick={() => setShow(!show)}>
                            {show ? <p className={styles.sliptext}>Add Delivery Details</p> : <p className={styles.sliptext}>Hide Delivery Details</p>}

                            <div>
                                {show ? <DownArrowCircle color={switchDisplay ? "#000000" : "#FFFFFF"} /> : <UpArrowCircle color={switchDisplay ? "#000000" : "#FFFFFF"} />}
                            </div>
                        </div>
                    </div>
                }
            />

            <Modal
                show={showDeliveryModal}
                close={setShowDeliveryModal}
                closeBtn={true}
                heading={data.order && data.order.dispatchStatus === "Dispatched" ? "UPDATE DELIVERY DETAILS" : "DELIVERY DETAILS"}
                content={
                    <div className={styles.text_center}>
                        <form onSubmit={handleSubmit}>
                            <div className={styles.modal_div}>
                                <select
                                    className={`${switchDisplay ? styles.inputcolor : styles.input}`}
                                    value={delivery.shippingType}
                                    onChange={(e) => setDelivery(prevState => ({
                                        ...prevState,
                                        shippingType: e.target.value
                                    }))}
                                    required
                                >
                                    <option value="" hidden>Delivery Type</option>
                                    <option>Standard</option>
                                    <option>Express</option>
                                </select>
                                <select
                                    className={`${switchDisplay ? styles.inputcolor : styles.input}`}
                                    value={delivery.courierName}
                                    onChange={(e) => setDelivery(prevState => ({
                                        ...prevState,
                                        courierName: e.target.value
                                    }))}
                                    required
                                >
                                    <option value="" hidden>Delivery Method</option>
                                    {couriers && couriers.map((obj, index) =>
                                        <option key={index} value={obj.courierName}>{obj.courierName}</option>
                                    )}
                                </select>
                                <select
                                    className={`${switchDisplay ? styles.inputcolor : styles.input}`}
                                    value={delivery.partnerName}
                                    onChange={(e) => {
                                        setDelivery(prevState => ({
                                            ...prevState,
                                            partnerName: e.target.value
                                        }));
                                        setPrintLabel(prevState => ({
                                            ...prevState,
                                            delPerName: partners && partners.partners.find(x => x.partnerName === e.target.value)?.partnerName,
                                            delPerMobile: partners && partners.partners.find(x => x.partnerName === e.target.value)?.contactNumber,
                                            delTrackingNo: partners && partners.partners.find(x => x.partnerName === e.target.value)?.trackingNumber,
                                            delTrackingUrl: partners && partners.partners.find(x => x.partnerName === e.target.value)?.trackingUrl,
                                        }));
                                    }}
                                    required
                                >
                                    <option value="" hidden>Delivery Partner</option>
                                    {partners.partners && partners.partners.map((obj, index) =>
                                        <option key={index} value={obj.partnerName}>{obj.partnerName}</option>
                                    )}
                                </select>
                            </div>
                            <div className={styles.modal_div}>
                                <input
                                    name="name"
                                    type="text"
                                    placeholder='Name'
                                    className={`${switchDisplay ? styles.inputcolor : styles.input}`}
                                    value={printLabel.delPerName}
                                    onChange={(e) => {
                                        setPrintLabel(prevState => ({
                                            ...prevState,
                                            delPerName: e.target.value
                                        }))
                                    }}
                                />
                                <input
                                    name="mobile"
                                    type="number"
                                    placeholder='Mobile Number'
                                    className={`${switchDisplay ? styles.inputcolor : styles.input}`}
                                    value={printLabel.delPerMobile}
                                    onChange={(e) => {
                                        setPrintLabel(prevState => ({
                                            ...prevState,
                                            delPerMobile: e.target.value
                                        }))
                                    }}
                                />
                            </div>
                            <div className={styles.modal_div}>
                                <input
                                    name="name"
                                    type="text"
                                    placeholder='Tracking Number'
                                    className={`${switchDisplay ? styles.inputcolor : styles.input}`}
                                    value={printLabel.delTrackingNo}
                                    onChange={(e) => {
                                        setPrintLabel(prevState => ({
                                            ...prevState,
                                            delTrackingNo: e.target.value
                                        }))
                                    }}
                                />
                                <input
                                    name="name"
                                    type="text"
                                    placeholder='Tracking URL'
                                    className={`${switchDisplay ? styles.inputcolor : styles.input}`}
                                    value={printLabel.delTrackingUrl}
                                    onChange={(e) => {
                                        setPrintLabel(prevState => ({
                                            ...prevState,
                                            delTrackingUrl: e.target.value
                                        }))
                                    }}
                                />
                            </div>

                            <div className={styles.dragImage_div}>
                                <FileHandler
                                    setisImage={setCourierSlip}
                                    styles={styles}
                                />
                            </div>

                            <div className={styles.uploadImage_div}>

                                <div className={styles.demoImage_div}>
                                    {(courierSlip || printLabel.courierSlip) ?
                                        <img
                                            src={courierSlip ? URL.createObjectURL(courierSlip[0]) : process.env.REACT_APP_S3URL + printLabel.courierSlip}
                                            className={styles.modalItem_Image}
                                            alt="courierSlip"
                                        />
                                        :
                                        <img
                                            src='/assets/packingImage.png'
                                            className={styles.modalItem_Image}
                                            alt="courier"
                                        />
                                    }
                                </div>
                                <label className={styles.uploadImage_btn} >
                                    {/* <input
                                        name=""
                                        type="file"
                                        hidden
                                        onChange={(e) => {
                                            setCourierSlip(e.target.files[0])
                                        }}
                                    />
                                    <UpLoadFile />&nbsp;&nbsp;Upload Courier Slip */}
                                    View
                                </label>
                                <div className={styles.delete_div} onClick={() => { setCourierSlip(''); setPrintLabel(prevState => ({ ...prevState, courierSlip: '' })) }}>
                                    <span><DeleteIcon /></span>
                                    <span>Delete</span>
                                </div>
                            </div>

                            <div className={styles.modalBtn_div}>
                                <RedOutButton btnType="button" title={"CANCEL"} handleSubmit={() => { setShowDeliveryModal(false) }} />
                                <GreenOutButton title={data.order && data.order.dispatchStatus === "Dispatched" ? "UPDATE DISPATCHED" : "DISPATCHED"} />
                            </div>
                        </form>
                    </div>
                }
            />
            {loading ? "Loading" :
                <form onSubmit={handleAcceptOrder} className={styles.mainContainer}>
                    <div className={styles.packingHeading}>
                        <div className={styles.backArrow} onClick={() => navigate(-1)}>&lt;&nbsp;Go Back</div>
                        <h2>Packing Voucher</h2>
                        {data.order.dispatchStatus&&<div className={`${(data.order.dispatchStatus === "New Order") ? styles.status_blue :
                            (data.order.dispatchStatus === "Packed" || data.order.dispatchStatus === "Dispatched") ? styles.statuscolor3 :
                                (data.order.dispatchStatus === "Not Packed") ? styles.statuscolor1 :
                                    (data.order.dispatchStatus === 'In Progress' || data.order.dispatchStatus === 'Pending') ?
                                        styles.status : ''
                            }`}>
                            &#9679; {data.order.dispatchStatus}
                        </div>}
                    </div>

                    <div className={styles.voucher_flex}>
                        <div className={styles.voucherFlexDirection}>
                            <h2 className={styles.slipheading_div}>{data.order && data.order.storeName}</h2>
                            <span className={styles.sliptext}>Ship To:&nbsp;<b>{data.order.shippingInfo && data.order.shippingInfo.name}</b></span>
                            <span className={styles.sliptext}>Address:&nbsp;<b>{data.order.shippingInfo &&
                                (data.order.shippingInfo.street + ", " + data.order.shippingInfo.city + ", " +
                                    data.order.shippingInfo.state + ", " + data.order.shippingInfo.pincode
                                )}</b></span>
                            <span className={styles.sliptextGustin}>GSTUIN:&nbsp;<b>{data.order && data.order.gstuin}</b></span>
                            {/* <span className={styles.sliptext}>State:&nbsp;{data.order && data.order.state}</span> */}
                            <span className={styles.sliptext}>Contact:&nbsp;{data.order.shippingInfo && data.order.shippingInfo.mobile}</span>
                            {/* <span className={styles.sliptext}>Email:&nbsp;{data.order && data.order.email}</span> */}
                            <span className={styles.sliptext}>Order Manager:&nbsp;{data.order && data.order.ordermanager}</span>
                            <span className={styles.sliptext}>Packing Manager:&nbsp;{data.order && data.order.packermanager}</span>
                        </div>
                        <div className={styles.packingVoucher_left}>
                            <div className={styles.voucher_flex}>
                                <span className={styles.slipDetailsTxt}>Voucher No.</span>
                                <span className={styles.slipDetailsData}><b>{data.order && data.order.orderId}</b></span>
                                <span className={styles.slipDetailsTxt}>Voucher Date</span>
                                <span className={styles.slipDetailsData}><b>{data.order && data.order.orderDate}</b></span>
                            </div>
                            <div className={styles.voucher_flex}>
                                <span className={styles.slipDetailsTxt}>Delivery Partner</span>
                                <input disabled type='text' className={styles.deliveryPartner} value={data.order && data.order.partnerName} />
                            </div>
                            <div className={styles.voucher_flex}>
                                <span className={styles.slipDetailsTxt}>Contact Number</span>
                                <input disabled type='number' className={styles.deliveryPartner} value={data.order && data.order.delPerMobile} />
                            </div>
                            <div className={styles.voucher_flex}>
                                <span className={styles.slipDetailsTxt}>Tracking Number</span>
                                <input disabled type='number' className={styles.deliveryPartner} value={data.order && data.order.delTrackingNo} />
                            </div>
                            <div className={styles.voucher_flex}>
                                <span className={styles.slipDetailsTxt}>Tracking URL</span>
                                <Link to={data.order && data.order.delTrackingUrl}>{data.order && data.order.delTrackingUrl}</Link>
                            </div>
                        </div>
                    </div>

                    <div className={styles.mainPack_delivery_image}>

                        <div className={styles.pack_image_div}>
                            <span className={styles.DeliveryDetailsTxt}>Delivery Detail Slip</span>
                            {data.order.courierSlip ? <img src={process.env.REACT_APP_S3URL + data.order.courierSlip} alt='delivery__slip' className={styles.delivery_image} onClick={() => window.open(process.env.REACT_APP_S3URL + data.order.courierSlip, "mozillaWindow", "popup")} /> : <div className={styles.delivery_image}>No Data</div>}
                        </div>
                        {
                            data.order.packingImgs && data.order.packingImgs.map((item, i) =>
                                <div key={i} className={styles.pack_image_div}>
                                    <span className={styles.DeliveryDetailsTxt}>Pack {i + 1}</span>
                                    <img key={i} src={process.env.REACT_APP_S3URL + item} alt='pack_image' onClick={() => window.open(process.env.REACT_APP_S3URL + item, "mozillaWindow", "popup")} className={styles.delivery_image} />
                                </div>
                            )
                        }
                    </div>

                    <div className='w-100 mt-4 d-flex flex-wrap'>
                        {
                           filterItem && filterItem.map((item, index) =>
                                <div key={index} className={styles.item_card}>
                                    <div className='d-flex flex-row w-100 mb-2'>
                                        <img src={process.env.REACT_APP_S3URL + item.thumbnail} alt={process.env.REACT_APP_S3URL + item.thumbnail} className={styles.item_image} />
                                        <div className='d-flex flex-column ms-2'>
                                           {item.product_name&& <span>Catogory: {item.product_name}</span>}
                                            {/* <span>Maharana</span> */}
                                            <span>Item No: {item.itemNo ? item.itemNo : item.accessoriesName}</span>
                                        </div>
                                    </div>
                                    {item.packingImg && <div className={styles.item_pack_div}>
                                        <img src={process.env.REACT_APP_S3URL + item.packingImg} alt='items_pack_image' className={styles.main_pack_image} onClick={() => window.open(process.env.REACT_APP_S3URL + item.packingImg, "mozillaWindow", "popup")} />
                                    </div>}
                                </div>
                            )
                        }
                    </div>

                    <div className={styles.button_div}>
                        <div className={styles.btn_flex}>
                            <GreenOutButton
                                btnType="button"
                                title='BACK'
                                handleSubmit={() => { navigate(-1) }}
                            />
                            {orderId && !orderId.includes('saleorder')&&<GreenButton
                                btnType="button"
                                title='UPDATE'  
                                disabled={data.order.dispatchStatus === "Cancelled" ? true : false}
                                handleSubmit={() => {
                                    setPrintLabel(prevState => ({
                                        ...prevState,
                                        delPerName: partners && partners.partners.find(x => x.partnerName === delivery.partnerName)?.partnerName,
                                        delPerMobile: partners && partners.partners.find(x => x.partnerName === delivery.partnerName)?.contactNumber,
                                        delTrackingNo: partners && partners.partners.find(x => x.partnerName === delivery.partnerName)?.trackingNumber,
                                        delTrackingUrl: partners && partners.partners.find(x => x.partnerName === delivery.partnerName)?.trackingUrl,
                                    }));
                                    setShowDeliveryModal(true)
                                }}
                            />}
                            <GreenButton
                                btnType="button"
                                title='SHARE'
                            />
                        </div>
                    </div>
                </form>
            }
        </React.Fragment >
    )
}

export default ViewDispatchDetails