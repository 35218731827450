import React, { useEffect, useState } from 'react'
import { useAuth } from '../../components/context/Auth'
import styles from '../inventorymanager/Inventories.module.css'
import { useNavigate, useSearchParams } from 'react-router-dom'
import Modal from '../../components/Modal'
import { DeleteIcon, DemoImageIcon, UpLoadFile } from '../../components/icons/Icon'
import { GreenButton, RedOutButton } from '../../components/Buttons'
import Pagination from '../../components/Paginantion'

const OutSideIvtCollInfo = () => {
    const navigate = useNavigate();
    const { managerId } = useAuth()
    const [searchParams] = useSearchParams();
    const page = searchParams.get("page") ? parseInt(searchParams.get("page")) : 1;
    const [pages, setPages] = useState(1)
    const collectionId = searchParams.get("collection_id")
    const [loading, setLoading] = useState(false)
    const [showAddItem, setShowAddItem] = useState(false)
    const [reload, setReload] = useState(false)
    const [msgShow, setMsgShow] = useState("");
    const [showAcceptModal, setShowAcceptModal] = useState(false);
    const [data, setData] = useState([])
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [deleteText, setDeleteText] = useState('')
    const [deleteItemNumber, setDeleteItemNumber] = useState('')
    // const [itemNo, setItemNo] = useState('');

    const [addNewItemData, setAddNewItemData] = useState({
        product_name: '',
        collection_name: '',
        itemNo: '',
        unit: '',
        price: '',
        weight: '',
        vm: '',
        description: '',
        productimg: '',
        // collection_id: '',
        products: [],
        collections: [],
    })



    const [file, setFile] = useState();
    function handleChange(e) {
        setFile(e.target.files[0]);
    }

    useEffect(() => {
        if (managerId) {
            setLoading(true)
            var myHeaders = new Headers();
            myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                "managerId": managerId,
                "collection_id": collectionId,
            });

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            fetch(process.env.REACT_APP_URL + "inventory/outsideitems?page=" + page + '&limit=12', requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.status === "success") {
                        setLoading(false)
                        setData(result.data)
                        setPages(result.pages)
                    }
                })
                .catch(error => console.log('error', error));
        }
    }, [managerId, collectionId, page, reload])

    const handleSingleItem = (itemNo) => {
        if (itemNo) {
            var myHeaders = new Headers();
            myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                "managerId": managerId,
                "itemNo": itemNo
            });

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            fetch(process.env.REACT_APP_URL + "inventory/outsideitems-singleItems", requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.status === "success") {
                        setAddNewItemData(prevState => ({
                            ...prevState,
                            product_name: result.data.product_name ? result.data.product_name : '',
                            collection_name: result.data.collection_name ? result.data.collection_name : '',
                            itemNo: result.data.itemNo ? result.data.itemNo : '',
                            unit: result.data.unit ? result.data.unit : '',
                            price: result.data.price ? result.data.price : '',
                            weight: result.data.weight ? result.data.weight : '',
                            vm: result.data.vm ? result.data.vm : '',
                            description: result.data.description ? result.data.description : '',
                            productimg: result.data.productimg ? result.data.productimg : '',
                        }))
                    }
                })
                .catch(error => console.log('error', error));
        }
    }


    const handleSubmitAddItem = (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            handleAddItemApi()
        }
    };

    const handleAddItemApi = () => {
        setReload(true)
        setMsgShow("")
        var myHeaders = new Headers();
        myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);

        var formdata = new FormData();
        formdata.append("image", file ? file : addNewItemData.productimg);
        formdata.append("product_Id", addNewItemData.product_Id);
        formdata.append("collection_id", addNewItemData.collection_id);
        formdata.append("product_name", addNewItemData.product_name);
        formdata.append("collection_name", addNewItemData.collection_name);
        formdata.append("unit", addNewItemData.unit);
        formdata.append("price", addNewItemData.price);
        formdata.append("weight", addNewItemData.weight);
        formdata.append("vm", addNewItemData.vm);
        formdata.append("description", addNewItemData.description);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch(process.env.REACT_APP_URL + `inventory/addUpdateOutsideItem/${managerId}/${addNewItemData.itemNo}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status === "success") {
                    setMsgShow(result.msg)
                    setShowAcceptModal(true)
                    setShowAddItem(false)
                    setReload(false)
                }
                else {
                    setMsgShow(result.msg)
                    setShowAcceptModal(true)
                }
            })
            .catch(error => console.log('error', error));
    }

    const handleDeleteItem = () => {

        if (deleteItemNumber) {
            setReload(true)
            var myHeaders = new Headers();
            myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                "managerId": managerId,
                "itemNo": deleteItemNumber
            });

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            fetch(process.env.REACT_APP_URL + "inventory/outsideitems-deleteItems", requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.status === "success") {
                        if (result.status === "success") {
                            setDeleteItemNumber('')
                            setDeleteText('')
                            setShowDeleteModal(false)
                            setReload(false)
                            alert(result.msg)
                        }
                    }
                })
                .catch(error => console.log('error', error));
        }
    };

    return (
        <React.Fragment>

            <div className={styles.main_Container}>
                <div>
                    <span onClick={() => navigate(-1)}>
                        &lt;&nbsp;&nbsp;<span className={styles.goBack}>Go Back</span>
                    </span>
                    <h1 className={styles.itemCollection_heading}>{data.length !== 0 && (data[0].product_name.toUpperCase() + "-" + data[0].collection_name.toUpperCase())}</h1>
                </div>
                {/* <h1 className={styles.OutSide_heading}>OutSide Items</h1> */}
                <div className={styles.OutSide_scroll_div}>
                    {loading ? <div className={'loadingMain'}>
                        <img src='/wallicon.gif' alt='walliconGIF' />
                    </div> :
                        <>

                            {data.length !== 0 ?
                                <div className={styles.dispatchOrders_height}>
                                    <table className={styles.itemTable}>
                                        <thead>
                                            <tr className={styles.item}>
                                                <th>Item No.</th>
                                                <th>Price</th>
                                                <th>Unit</th>
                                                <th>Weight</th>
                                                <th>Volume</th>
                                                <th>Date</th>
                                                <th>Description</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data && data.map((val, index) =>
                                                <tr
                                                    key={index}
                                                    className={styles.item}
                                                    onClick={() => {
                                                        setShowAddItem(true);
                                                        // setAddNewItemData(val)
                                                        handleSingleItem(val.itemNo)
                                                    }}
                                                >
                                                    <td>{val.itemNo}</td>
                                                    <td>{val.price}</td>
                                                    <td>{val.unit}</td>
                                                    <td>{val.weight}</td>
                                                    <td>{val.vm}</td>
                                                    <td>{val.createdAt.slice(8, 10) + '-' + val.createdAt.slice(5, 7) + '-' + val.createdAt.slice(0, 4)}</td>
                                                    <td className={styles.description_truncate}>{val.description}</td>
                                                    <td><div className={styles.delete_btn} onClick={(e) => { e.stopPropagation(); setShowDeleteModal(true); setDeleteItemNumber(val.itemNo) }}><DeleteIcon /><span>Delete</span></div></td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                                :
                                <div className={styles.noData}>
                                    No Data Found !
                                </div>}
                        </>
                    }

                    {!loading && data.length !== 0 ?
                        <Pagination
                            total={pages}
                            current={page}
                        />
                        :
                        ''
                    }
                </div>
            </div>

            <Modal
                show={showAddItem}
                close={setShowAddItem}
                closeBtn={true}
                heading=" "
                content={
                    <div className={styles.text_center}>
                        <div className={styles.addItem_Container}>
                            <h2 className={styles.modalMain_heading}>Add New Item</h2>
                            <form
                                onSubmit={handleSubmitAddItem}
                            >
                                <div className={styles.orderInput_div}>
                                    <div className={styles.inputFields_div}>
                                        <label className={styles.inputFields_heading}>Product Name</label>
                                        <input type="text" list="prodcuts"
                                            className={styles.inputField_name}
                                            value={addNewItemData.product_name}
                                            onChange={(e) => setAddNewItemData({
                                                ...addNewItemData,
                                                product_name: e.target.value,
                                            })}
                                            autoFocus
                                        />
                                        <datalist id="prodcuts">
                                            {addNewItemData.products && addNewItemData.products.map((item, index) =>
                                                <option key={index} >{item.product_name}</option>
                                            )}
                                        </datalist>
                                    </div>
                                    <div className={styles.inputFields_div}>
                                        <label className={styles.inputFields_heading}>Collection Name</label>
                                        <input type="text" list="collections"
                                            className={styles.inputField_name}
                                            value={addNewItemData.collection_name}
                                            onChange={(e) => setAddNewItemData({
                                                ...addNewItemData,
                                                collection_name: e.target.value,
                                            })}
                                        />
                                        <datalist id="collections">
                                            {addNewItemData.collections && addNewItemData.collections.map((item, index) =>
                                                <option key={index} >{item.collection_name}</option>
                                            )}
                                        </datalist>
                                    </div>
                                </div>
                                <div className={styles.orderInput_div}>
                                    <div className={styles.inputFields_div}>
                                        <label className={styles.inputFields_heading}>Item Number</label>
                                        <input
                                            type="text"
                                            className={styles.inputField_name}
                                            placeholder="Enter Item Number"
                                            value={addNewItemData.itemNo}
                                            onChange={(e) => setAddNewItemData({ ...addNewItemData, itemNo: e.target.value })}
                                            required
                                        />
                                    </div>
                                    <div className={styles.inputFields_div}>
                                        <label className={styles.inputFields_heading}>Unit</label>
                                        <input type="text" list="units"
                                            className={styles.inputField_name}
                                            value={addNewItemData.unit}
                                            onChange={(e) => setAddNewItemData({
                                                ...addNewItemData,
                                                unit: e.target.value,
                                            })}
                                        />
                                        <datalist id="units">
                                            <option value="Roll" >Roll</option>
                                            <option value="Pcs">Pcs</option>
                                            <option value="Box">Box</option>
                                            <option value="Sq Meter">Sq Meter</option>
                                            <option value="Meter">Meter</option>
                                            <option value="Sq Feet">Sq Feet</option>
                                            <option value="Feet">Feet</option>
                                            <option value="Kg">Kg</option>
                                        </datalist>
                                    </div>
                                </div>
                                <div className={styles.orderInput_div}>
                                    <div className={styles.inputFields_div}>
                                        <label className={styles.inputFields_heading}>Price</label>
                                        <input
                                            type="number"
                                            onWheel={(e) => e.target.blur()}
                                            className={styles.inputField_name}
                                            placeholder="Enter Price"
                                            value={addNewItemData.price}
                                            onChange={(e) => setAddNewItemData({ ...addNewItemData, price: e.target.value })}
                                            required
                                        />
                                    </div>
                                    <div className={styles.inputFields_div}>
                                        <label className={styles.inputFields_heading}>Weight</label>
                                        <input
                                            type="text"
                                            className={styles.inputField_name}
                                            placeholder="Enter Weight"
                                            value={addNewItemData.weight}
                                            onChange={(e) => setAddNewItemData({ ...addNewItemData, weight: e.target.value })}
                                        />
                                    </div>
                                </div>

                                <div className={styles.orderInput_div}>
                                    <div className={styles.inputFields_div}>
                                        <label className={styles.inputFields_heading}>Volume (CBM)</label>
                                        <input
                                            type="number"
                                            onWheel={(e) => e.target.blur()}
                                            className={styles.inputField_name}
                                            placeholder="Enter Volume"
                                            value={addNewItemData.vm}
                                            onChange={(e) => setAddNewItemData({ ...addNewItemData, vm: e.target.value })}

                                        />
                                    </div>
                                    <div className={styles.inputFields_div}>
                                        <label className={styles.inputFields_heading}>Description&nbsp;<span className={styles.unbold_text}>(optional)</span></label>
                                        <input
                                            type="text"
                                            className={styles.inputField_name}
                                            placeholder="Enter Description"
                                            value={addNewItemData.description}
                                            onChange={(e) => setAddNewItemData({ ...addNewItemData, description: e.target.value })}
                                        />
                                    </div>
                                </div>

                                <div className={styles.uploadImage_div}>
                                    <label onChange={handleChange} className={styles.uploadImage_btn}>
                                        <input name="" type="file" id="formId" hidden />
                                        <UpLoadFile />&nbsp;&nbsp;Upload Image
                                    </label>
                                    {(file || (addNewItemData.productimg)) &&
                                        <div className={styles.demoImage_div}> <img src={file ? URL.createObjectURL(file) : process.env.REACT_APP_S3URL + addNewItemData.productimg} alt="item_image" className={styles.modalItem_Image} /></div>
                                    }
                                    {
                                        (!file && !addNewItemData.productimg) &&
                                        <div className={styles.demoImage_div}>
                                            <div className={styles.demoImage_bgc}>
                                                <DemoImageIcon />
                                            </div>
                                        </div>
                                    }
                                    {/* {(file || (data.length!==0)) ?
                                        <div className={styles.demoImage_div}> <img src={file ? URL.createObjectURL(file) : data[0].productimg } alt="item_image" className={styles.modalItem_Image} /></div> :
                                        <div className={styles.demoImage_div}>
                                            <div className={styles.demoImage_bgc}>
                                                <DemoImageIcon />
                                            </div>
                                        </div>
                                    } */}
                                    <div className={styles.delete_div} onClick={() => {
                                        setFile(); setAddNewItemData(prevState => ({
                                            ...prevState,
                                            productimg: ''
                                        }))
                                    }}>
                                        <span><DeleteIcon /></span>
                                        <span>Delete</span>
                                    </div>
                                </div>
                                <div className={styles.modalButton_div}>
                                    <RedOutButton title="CANCEL" handleSubmit={() => { setShowAddItem(false) }} />
                                    <GreenButton btnType="submit" title={"ADD"} handleSubmit={() => { }} />
                                </div>
                            </form>
                        </div>
                    </div>
                }
            />

            <Modal
                show={showAcceptModal}
                close={setShowAcceptModal}
                closeBtn={true}
                heading="ADD DEALER STATUS"
                content={
                    <div className={styles.text_center}>
                        <p className={styles.message}>{msgShow}</p>
                        <div className={styles.massageModal_btn}>
                            <RedOutButton title="CANCEL" handleSubmit={() => setShowAcceptModal(false)} />
                            <GreenButton title="OK" handleSubmit={() => { setShowAcceptModal(false) }} />
                        </div>
                    </div>
                }
            />

            <Modal
                show={showDeleteModal}
                close={setShowDeleteModal}
                closeBtn={true}
                heading="Delete Items"
                content={
                    <div className={styles.text_center}>
                        <p className={styles.message}>To confirm! type <span className='text-danger'>delete</span> in the text input field.</p>
                        <input type='text' maxLength={6} className={styles.input_delete} placeholder='Enter' onChange={(e) => setDeleteText(e.target.value)} />
                        <div className={styles.massageModal_btn}>
                            <RedOutButton title="CANCEL" handleSubmit={() => setShowDeleteModal(false)} />
                            <GreenButton disabled={deleteText.toUpperCase() === 'DELETE' ? false : true} title="DELETE" handleSubmit={() => { handleDeleteItem() }} />
                        </div>
                    </div>
                }
            />
        </React.Fragment>
    )
}

export default OutSideIvtCollInfo
