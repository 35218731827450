import React, { useState } from 'react'
import { useEffect } from 'react';
import { Col, Modal, Row, Table } from 'react-bootstrap'
// import { BlackOutButton, RedOutButton } from '../buttons/AllButtons'
import { useAuth } from '../../components/context/Auth';
import { GreenOutButton, RedOutButton } from '../../components/Buttons';


const ProductEditDelete = () => {
    const [show, setShow] = useState(false);
    const [show1, setShow1] = useState(false);
    const handleShow = () => setShow(true);
    const [steps, setSteps] = useState(0)
    const [orderfilter, setOrderFilter] = useState(0)
    const [boxShadow, setBoxShadow] = useState(0)
    const [loadingItem, setLoadingItem] = useState(false);
    const [productDelete, setProductDelete] = useState([]);
    const [styleDelete, setStyleDelete] = useState([]);
    const [itemDelete, setItemDelete] = useState([]);
    const [typeDelete, setTypeDelete] = useState([]);
    const [collectionDelete, setCollectionDelete] = useState([]);
    const [name, setName] = useState('');
    const [productId, setProductId] = useState('');
    const [styleId, setStyleId] = useState('');
    const [typeId, setTypeId] = useState('');
    const [collectionId, setCollectionId] = useState('');
    const [itemId, setItemId] = useState('');

    const { updateDelete, setUpdateDelete } = useAuth()

    const getDeleteRequest = async (req) => {
        await fetch(process.env.REACT_APP_URL + "admin/getUpdDelReq", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                "wa-platform": process.env.REACT_APP_ADMIN_SECRET,
            },
            body: JSON.stringify({
                "reqType": req,
            }),
        })
            .then(response => response.json())
            .then(result => {
                if (result.status === 'success') {
                    setLoadingItem(true)
                    setProductDelete(result.productsData)
                    setItemDelete(result.itemsData)
                    setStyleDelete(result.stylesData)
                    setTypeDelete(result.typesData)
                    setCollectionDelete([...result.collectionsData])
                }
            })
    }

    const deleteProduct = async (id) => {
        await fetch(process.env.REACT_APP_URL + "product/deleteProduct", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                "wa-platform": process.env.REACT_APP_ADMIN_SECRET,
            },
            body: JSON.stringify({
                "product_id": id,
            }),
        })
            .then(response => response.json())
            .then(result => {
                if (result.status === 'success') {
                    getDeleteRequest("Delete")
                    setLoadingItem(true)
                    setShow(false)
                    setProductId('')
                    setUpdateDelete(6)
                }
                else {
                    setLoadingItem(false)
                    setShow(false)
                    setProductId('')
                    alert(result.msg)
                }
            })
    }

    const deleteStyle = async (id) => {
        await fetch(process.env.REACT_APP_URL + "product/deleteStyle", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                "wa-platform": process.env.REACT_APP_ADMIN_SECRET,
            },
            body: JSON.stringify({
                "style_id": id,
            }),
        })
            .then(response => response.json())
            .then(result => {
                if (result.status === 'success') {
                    getDeleteRequest("Delete")
                    setLoadingItem(true)
                    setShow(false)
                    setStyleId('')
                    setUpdateDelete(5)
                }
                else {
                    setLoadingItem(false)
                    setShow(false)
                    setStyleId('')
                    alert(result.msg)
                }
            })
    }

    const deleteType = async (id) => {
        await fetch(process.env.REACT_APP_URL + "product/deleteType", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                "wa-platform": process.env.REACT_APP_ADMIN_SECRET,
            },
            body: JSON.stringify({
                "type_id": id,
            }),
        })
            .then(response => response.json())
            .then(result => {
                if (result.status === 'success') {
                    getDeleteRequest("Delete")
                    setLoadingItem(true)
                    setShow(false)
                    setTypeId('')
                    setUpdateDelete(7)
                }
                else {
                    setLoadingItem(false)
                    setShow(false)
                    setTypeId('')
                    alert(result.msg)
                }
            })
    }

    const deleteCollection = async (id) => {
        await fetch(process.env.REACT_APP_URL + "product/deleteCollection", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                "wa-platform": process.env.REACT_APP_ADMIN_SECRET,
            },
            body: JSON.stringify({
                "collection_id": id,
            }),
        })
            .then(response => response.json())
            .then(result => {
                if (result.status === 'success') {
                    getDeleteRequest("Delete")
                    setLoadingItem(true)
                    setShow(false)
                    setCollectionId('')
                    setUpdateDelete(8)
                }
                else {
                    setLoadingItem(false)
                    setShow(false)
                    setCollectionId('')
                    alert(result.msg)
                }
            })
    }

    const deleteItem = async (id) => {
        await fetch(process.env.REACT_APP_URL + "product/deleteItem", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                "wa-platform": process.env.REACT_APP_ADMIN_SECRET,
            },
            body: JSON.stringify({
                "item_id": id,
            }),
        })
            .then(response => response.json())
            .then(result => {
                if (result.status === 'success') {
                    getDeleteRequest("Delete")
                    setLoadingItem(true)
                    setShow(false)
                    setItemId('')
                    setUpdateDelete(9)
                }
                else {
                    setLoadingItem(false)
                    setShow(false)
                    setItemId('')
                    alert(result.msg)
                }
            })
    }

    const orderData = [
        {
            num: productDelete.length,
            status: 'Products'
        },
        {
            num: styleDelete.length,
            status: 'Styles'
        },
        {
            num: typeDelete.length,
            status: 'Types'
        },
        {
            num: collectionDelete.length,
            status: 'Collections'
        },
        {
            num: itemDelete.length,
            status: 'Items'
        },
    ]

    useEffect(() => {
        getDeleteRequest("Delete")
    }, [updateDelete])

    return (
        <React.Fragment>
            <div className='product_editDelete'>
                <Row className="mx-0">
                    <Col md="10">
                        <div className="fs_30 pf">Delete &amp; Update</div>
                    </Col>
                    <Col md="2 pe-0" >
                        <div className="d-none bg-white rounded-pill py-2 text-center">
                            <button className={`${orderfilter === 0 ? "omBtnClickClr" : "bg-transparent"} omBtnWidth p-2 rounded-pill border-0 btnMKD`} onClick={() => setOrderFilter(0)}>Deleted</button>
                            <button className={`${orderfilter === 1 ? "omBtnClickClr" : "bg-transparent"} omBtnWidth p-2 rounded-pill border-0 btnMKD`} onClick={() => setOrderFilter(1)}>Edited</button>
                        </div>
                    </Col>
                </Row>
                {loadingItem === false ? <div className="App-header">
                    <img src="/wallicon.gif" alt="loader" />
                </div> : <>
                    <Row className="mt-2">
                        {orderData.map((item, index) =>
                            <Col key={index} className={`border_text text-center m-2 cpm mouse ${index === boxShadow ? "redButtonShadow" : ""} `} onClick={() => { setBoxShadow(index); setSteps(index) }}>

                                <div className={item.status === "Products" ? "text-warning fs_50" :
                                    item.status === "Styles" ? "orangeclr fs_50" :
                                        item.status === "Types" ? "greenClr fs_50" :
                                            item.status === "Collections" ? "stColr fs_50" :
                                                "text-danger fs_50"}>
                                    {item.num}
                                </div>
                                <div className="fs_18" onClick={() => setSteps(true)}>{item.status}</div>
                            </Col>
                        )}
                    </Row>
                    {steps === 0 ?
                        <Table striped hover variant="dark" className="gfg text-center" >
                            <thead >
                                <tr className="fs_20">
                                    <th>Product Name</th>
                                    <th>Cart Type</th>
                                    <th>Date Created</th>
                                    {/* <th>Total Styles</th> */}
                                    {/* <th>Total Types</th> */}
                                    {/* <th>Total Collections</th> */}
                                    {/* <th>Total Items</th> */}
                                    <th>Status</th>
                                    <th>Date Deleted</th>
                                </tr>
                            </thead>
                            <tbody >
                                {productDelete.map((its, index) => (
                                    <tr className="py-5 fs_18 cp" key={index}>
                                        <td className='text-start'>{its.product_name}</td>
                                        <td>{its.cartType}</td>
                                        <td>{`${new Date(its.createdAt).getDate()}-${new Date(its.createdAt).getMonth() + 1}-${new Date(its.createdAt).getFullYear()}`}</td>
                                        {/* <td>{ its.styles }</td> */}
                                        {/* <td>{ its.types }</td> */}
                                        {/* <td>{ its.collections }</td> */}
                                        {/* <td>{ its.items }</td> */}
                                        {orderfilter === 0 ? <td className="text-danger mouse" onClick={() => { handleShow(); setName(its.product_name); setProductId(its._id) }}>Request to delete </td> : <td className="text-warning mouse">Request to delete</td>}
                                        <td>{`${new Date(its.updatedAt).getDate()}-${new Date(its.updatedAt).getMonth() + 1}-${new Date(its.updatedAt).getFullYear()}`}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                        :
                        steps === 1 ?
                            <Table striped hover variant="dark" className="gfg text-center" >
                                <thead>
                                    <tr className="fs_20">
                                        <th>Style Name</th>
                                        {/* <th>Product Catogory</th> */}
                                        <th className='text-start'>Date Created</th>
                                        {/* <th>Total Items</th> */}
                                        <th className='text-start'>Status</th>
                                        <th className='text-start'>Date Deleted</th>
                                    </tr>
                                </thead>
                                <tbody >{
                                    styleDelete.map((its, index) => (
                                        <tr className="py-5 fs_18 cp" key={index}>
                                            <td>{its.style_name}</td>
                                            {/* <td>{ its.catogory }</td> */}
                                            <td>{`${new Date(its.createdAt).getDate()}-${new Date(its.createdAt).getMonth() + 1}-${new Date(its.createdAt).getFullYear()}`}</td>
                                            {/* <td>{ its.items }</td> */}
                                            <td className="text-danger mouse" onClick={() => { handleShow(); setName(its.style_name); setStyleId(its._id) }}>Request to delete</td>
                                            <td>{`${new Date(its.updatedAt).getDate()}-${new Date(its.updatedAt).getMonth() + 1}-${new Date(its.updatedAt).getFullYear()}`}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                            :
                            steps === 2 ?
                                <Table striped hover variant="dark" className="gfg text-center" >
                                    <thead >
                                        <tr className="fs_20">
                                            <th>Type Name</th>
                                            {/* <th>Product Catogory</th> */}
                                            <th className='text-start'>Date Created</th>
                                            {/* <th>Total Collections</th> */}
                                            {/* <th>Total Items</th> */}
                                            <th className='text-start'>Status</th>
                                            <th className='text-start'>Date Deleted</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            typeDelete.map((its, index) => (
                                                <tr className="py-5 fs_18 cp" key={index}>
                                                    <td>{its.type_name}</td>
                                                    {/* <td>{ its.catogory }</td> */}
                                                    <td>{`${new Date(its.createdAt).getDate()}-${new Date(its.createdAt).getMonth() + 1}-${new Date(its.createdAt).getFullYear()}`}</td>
                                                    {/* <td>{ its.collections }</td> */}
                                                    {/* <td>{ its.items }</td> */}
                                                    <td className="text-danger mouse" onClick={() => { handleShow(); setName(its.type_name); setTypeId(its._id) }}>Request to delete</td>
                                                    <td>{`${new Date(its.updatedAt).getDate()}-${new Date(its.updatedAt).getMonth() + 1}-${new Date(its.updatedAt).getFullYear()}`}</td>
                                                </tr>
                                            ))}

                                    </tbody>
                                </Table>
                                :
                                steps === 3 ?
                                    <Table striped hover variant="dark" className="gfg text-center" >
                                        <thead >
                                            <tr className="fs_20">
                                                <th>Collection Name</th>
                                                {/* <th>Product Catogory</th> */}
                                                <th className='text-start'>Date Created</th>
                                                {/* <th>Total Items</th> */}
                                                <th className='text-start'>Status</th>
                                                <th className='text-start'>Date Deleted</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                collectionDelete.map((its, index) => (
                                                    <tr className="py-5 fs_18 cp" key={index}>
                                                        <td>{its.collection_name}</td>
                                                        {/* <td>{ its.catogory }</td> */}
                                                        <td>{`${new Date(its.createdAt).getDate()}-${new Date(its.createdAt).getMonth() + 1}-${new Date(its.createdAt).getFullYear()}`}</td>
                                                        {/* <td>{ its.items }</td> */}
                                                        <td className="text-danger mouse" onClick={() => { handleShow(); setName(its.collection_name); setCollectionId(its._id) }}>Request To Delete</td>
                                                        <td>{`${new Date(its.updatedAt).getDate()}-${new Date(its.updatedAt).getMonth() + 1}-${new Date(its.updatedAt).getFullYear()}`}</td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </Table>
                                    :
                                    steps === 4 ?
                                        <Table striped hover variant="dark" className="gfg text-center" >
                                            <thead >
                                                <tr className="fs_20">
                                                    <th>Items</th>
                                                    {/* <th>Product Catogory</th> */}
                                                    <th className='text-start'>Date Created</th>
                                                    <th className='text-start'>Status</th>
                                                    <th className='text-start'>Date Deleted</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    itemDelete.map((its, index) => (
                                                        <tr className="py-5 fs_18 cp" key={index}>
                                                            <td>{its.itemNo}</td>
                                                            {/* <td>{ its.catogory }</td> */}
                                                            <td>{`${new Date(its.createdAt).getDate()}-${new Date(its.createdAt).getMonth() + 1}-${new Date(its.createdAt).getFullYear()}`}</td>
                                                            <td className="text-danger mouse" onClick={() => { handleShow(); setName(its.itemNo); setItemId(its._id) }}>Request To Delete</td>
                                                            <td>{`${new Date(its.updatedAt).getDate()}-${new Date(its.updatedAt).getMonth() + 1}-${new Date(its.updatedAt).getFullYear()}`}</td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </Table>
                                        : null
                    }
                </>}
            </div>
            <Modal className="cp textGray" size="lg" show={show} centered>
                <Modal.Body className="bgDark cp text-center py-5">
                    <img src="/assets/icons/redDustbin.svg" alt="delete" className="deleteIcon" />
                    <h1 className="text-danger redClr fw-bold">
                        Delete {name !== '' ? name : null}
                        {/* Delete { styleName !== '' ? styleName : typeName !== '' ? typeName : collectionViewHeading !== '' ? collectionViewHeading : itemName !== '' ? itemName : null } */}
                    </h1>
                    <div className="fs_28">
                        Are you sure you want to delete "{name !== '' ? name : null}" ?
                        {/* Are you sure you want to delete "{ styleName !== '' ? styleName : typeName !== '' ? typeName : collectionViewHeading !== '' ? collectionViewHeading : itemName !== '' ? itemName : null }" ? */}
                    </div>
                    <div className="fs_28">
                        You cannot undo this action.
                    </div>
                    {productId !== '' ?
                        <div className="fs_28 p-4 text-dark tw_center">
                            <div className="borderRightRed ">
                                <div className="d-flex whiteBg justify-content-center fw-bold">
                                    <img src="/assets/icons/warning.svg" alt="warning" className="warningIcon" /><div className="redClr fs_30">Warning</div>
                                </div>
                                <div className="fs_24 whiteBg">
                                    This Action will permanently delete all the:
                                </div>
                                <div className="fs_24 fw-bold whiteBg d-flex justify-content-evenly">
                                    <div className="fs_24">
                                        &bull; Styles
                                    </div>
                                    <div className="fs_24">
                                        &bull; Types
                                    </div>
                                    <div className="fs_24">
                                        &bull; Collection
                                    </div>
                                    <div className="fs_24">
                                        &bull; Items
                                    </div>
                                </div>
                            </div>
                        </div>
                        : null}
                    <div className="d-flex justify-content-center mt-3 tw_center">
                        <GreenOutButton btnType="button" css="bg-transparent text-white border-white" title="CANCEL" handleSubmit={() => { setShow(false); setProductId(''); setStyleId(''); setTypeId(''); setCollectionId(''); setItemId('') }} />
                        {/* <BlackOutButton btnType="button" css="bg-transparent text-white border-white" title="CANCEL" handleSubmit={ () => { setShow( false );setProductId(''); setStyleId( '' ); setTypeId( '' ); setCollectionId( '' ); setItemId( '' ) } } /> */}
                        <RedOutButton title="DELETE" css="ms-5 bg-danger text-white" handleSubmit={() => productId !== '' ? deleteProduct(productId) : styleId !== '' ? deleteStyle(styleId) : typeId !== '' ? deleteType(typeId) : collectionId !== '' ? deleteCollection(collectionId) : itemId !== '' ? deleteItem(itemId) : null} />
                        {/* <RedOutButton title="DELETE" css="ms-5 bg-danger text-white" handleSubmit={ () => styleId !== '' ? deleteStyle( "Delete" ) : typeId !== '' ? deleteType( "Delete" ) : collectionId !== '' ? deleteCollection( "Delete" ) : itemId !== '' ? deleteItem( "Delete" ) : null } /> */}
                    </div>
                </Modal.Body>
            </Modal>
            <Modal show={show1} onHide={() => setShow1(false)} size="lg" centered>
                <Modal.Body className="bgDark cp text-center p-4" >
                    <img src="/assets/icons/edit.svg" alt="delete" className="" width="100" height="100" />
                    <div className="text-danger fs_50 cpm">Edited Wallpapers</div>
                    <div className="fs_28">Are you sure you want to Edit <b>“Wallpapers” </b> ?</div>
                    <div className="fs_28">You cannot undo this action. </div>
                    <GreenOutButton btnType="button" css="bg-transparent text-white border-white fs_26 hwBtn mouse mt-5" title="CANCEL" />
                    <RedOutButton title="EDIT" css="ms-5 bg-danger text-white hwBtn mouse mt-5" />
                </Modal.Body>
            </Modal>
        </React.Fragment>
    )
}

export default ProductEditDelete
