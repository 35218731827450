import React from 'react'
import styles from "../../../pages/kyc/Dealer.module.css"
import { DealerInfoCardActiveStatusIcon, DealerInfoCardBlackListedStatusIcon, DealerInfoCardCallDarkIcon, DealerInfoCardNewStatusIcon, DealerInfoCardPendingStatusIcon, DealerInfoCardPhoneDarkIcon, DealerInfoCardRejectedStatusIcon, DealerInfoCardWhatsAppDarkIcon } from '../../icons/Icon'
import { CollCartIconLight, PhoneCartIconLight, WhatppCartIconLight } from '../../icons/Icon2'
import { useAuth } from '../../context/Auth'
const DealerCartMobil = ({ imgUri, dealerLocation, dealerDesignation, dealerStatus, dealerName }) => {
  const { mode } = useAuth();
  const renderStatusIcon = () => {
    switch (dealerStatus) {
      case "active":
        return <DealerInfoCardActiveStatusIcon />;
      case "blackListed":
        return <DealerInfoCardBlackListedStatusIcon />;
      case "new":
        return <DealerInfoCardNewStatusIcon />;
      case "pending":
        return <DealerInfoCardPendingStatusIcon />;
      case "rejected":
        return <DealerInfoCardRejectedStatusIcon />;
      default:
        return null;
    }
  };
  return (
    <div className={mode ? styles.md_card_mainContiner_dark : styles.md_card_mainContiner}>
      <div className={styles.md_imageCoit}>
        <img src={process.env.REACT_APP_S3URLA + imgUri || "./assets/updatedAssets/pfp.png"} alt="" className={styles.md_Image} />
      </div>
      <div className={styles.md_About_Continer}>
        <div className={styles.md_icon} >
          <span >{renderStatusIcon()}</span > <span className={mode ? styles.md_Nametext_dark : styles.md_Nametext}>{dealerName}</span>
        </div>
        <div className={styles.md_text_Role} >
          <span className={mode ? styles.md_roleName_dark : styles.md_roleName} >{dealerDesignation}</span>
          <span className={mode ? styles.md_storeName_dark : styles.md_storeName} >{dealerLocation}</span>
        </div>
        <div className={styles.md_dealer_Info_Card_Icons_Tray}>
          {mode ? (
            <>
              <DealerInfoCardPhoneDarkIcon />
              <DealerInfoCardCallDarkIcon />
              <DealerInfoCardWhatsAppDarkIcon />
            </>
          ) : (
            <>
              <PhoneCartIconLight />
              <CollCartIconLight />
              <WhatppCartIconLight />
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default DealerCartMobil