// import React, { useRef, useState, useEffect } from "react";
// import styles from "./FromComponent.module.css";
// import { useAuth } from "../../context/Auth";
// import { BoldIcon, EmojisIcon, ItalicIcon } from "../../icons/Icon";

// const Editer = ({ bodyText, setBodyText }) => {
//   const { mode } = useAuth();
//   const [selectionStart, setSelectionStart] = useState(0);
//   const [selectionEnd, setSelectionEnd] = useState(0);
//   const [showEmojis, setShowEmojis] = useState(false);

// useEffect(()=>{

// }, [bodyText])

//   const applyBold = () => {
//     const newText =
//       bodyText &&
//       `${bodyText.slice(0, selectionStart)}*${bodyText.slice(
//         selectionStart,
//         selectionEnd
//       )}*${bodyText.slice(selectionEnd)}`;
//     setBodyText(newText);
//   };

//   const applyItalic = () => {
//     const newText =
//       bodyText &&
//       `${bodyText.slice(0, selectionStart)}_${bodyText.slice(
//         selectionStart,
//         selectionEnd
//       )}_${bodyText.slice(selectionEnd)}`;
//     setBodyText(newText);
//   };


//   const handleTextChange = (e) => {
//     let text = e.target.value;
//     text = text.replace(/(\n\s*){2,}/g, '\n\n');
//     setBodyText(text);
//   };

//   const handleSelectionChange = (event) => {
//     setSelectionStart(event.target.selectionStart);
//     setSelectionEnd(event.target.selectionEnd);
//   };




//   const handleEmojiSelect = (emoji) => {
//     setBodyText((prevContent) => prevContent + emoji);
//   };

//   const emojisRef = useRef(null);
//   useEffect(() => {
//     const handleOutsideClick = (event) => {
//       if (emojisRef.current && !emojisRef.current.contains(event.target)) {
//         setShowEmojis(false);
//       }
//     };

//     document.addEventListener("mousedown", handleOutsideClick);

//     return () => {
//       document.removeEventListener("mousedown", handleOutsideClick);
//     };
//   }, []);




//   return (
//     <div className={styles.editerCointner}>
//       <div className={styles.editerCointner1} >
//         <p className={styles.para}>
//           Body&nbsp;<span className={styles.star}>*</span>
//         </p>
//         <textarea
//           placeholder="Enter Message Here"
//           rows={4}
//           className={styles.templateBody}
//           value={bodyText}
//           // onChange={(e) => setBodyText(e.target.value)}
//           onChange={handleTextChange}
//           onSelect={handleSelectionChange}
//           // onKeyDown={handleKeyDown} 
//           required
//           style={mode ?{ backgroundColor:"#1B1D21" ,  border: "1px solid #1B1D21" , color:"#fff"}:
//           {backgroundColor:"#fff" ,  border: "1px solid #cecccc", color:"#000"}}
//         />
//       </div>
//       <div className={styles.btnCon}>
//         <div className={styles.templateBody_count} style={{color: mode ? "#fff":"#000"}} >
//           Characters: {bodyText?.length} / 1024
//         </div>
//         <div className={styles.btnContiner}>
//           <div className={styles.txtEditor_btn}>
//             <div
//               className={styles.icon_css1}
//               onClick={() => setShowEmojis(!showEmojis)}
//             >
//               <div className={styles.po}>
//               {showEmojis && <EmojiPicker onSelect={handleEmojiSelect}  /> }
//               </div>
//               <EmojisIcon mode={mode}/>
//             </div>
//             <div className={styles.icon_css} onClick={applyBold}>
//               <BoldIcon mode={mode} />
//             </div>
//             <div className={styles.icon_css} onClick={applyItalic}>
//               <ItalicIcon  mode={mode}  />
//             </div>
//             {/* <div className={styles.icon_css} onClick={applyLineBreak}>
//             <Arro   mode={mode}/>
//             </div> */}
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Editer;

// const EmojiPicker = ({ onSelect }) => {

//   const {mode} = useAuth()
//   const smileys = [
//       "😀", "😃", "😄", "😁", "😆", "😅", "😂", "🤣", "😊", "😇", "🙂", "🙃", "😉", "😌", "😍",
//       "🥰", "😘", "😗", "😙", "😚", "😋", "😛", "😝", "😜", "🤪", "🤨", "🧐", "🤓", "😎", "🤩",
//       "🥳", "😏", "😒", "😞", "😔", "😟", "😕", "🙁", "☹️", "😣", "😖", "😫", "😩", "🥺", "😢",
//       "😭", "😤", "😠", "😡", "🤬", "🤯", "😳", "🥵", "🥶", "😱", "😨", "😰", "😥", "😓", "🤗",
//       "🤔", "🤭", "🤫", "🤥", "😶", "😐", "😑", "😬", "🙄", "😯", "😦", "😧", "😮", "😲", "🥱",
//       "😴", "🤤", "😪", "😵", "🤐", "🥴", "🤢", "🤮", "🤧", "😷", "🤒", "🤕", "🤑", "🤠", "😈",
//       "👿", "👹", "👺", "🤡", "💩", "👻", "💀", "☠️", "👽", "👾", "🤖", "🎃", "😺", "😸", "😹",
//       "😻", "😼", "😽", "🙀", "😿", "😾", "🤲", "👐", "🙌", "👏", "🤝", "👍", "👎", "👊", "✊",
//       "🤛", "🤜", "🤞", "✌️", "🤟", "🤘", "👌", "👈", "👉", "👆", "👇", "☝️", "✋", "🤚", "🖐️",
//       "🖖", "👋", "🤙", "💪", "🦾", "🖕", "✍️", "🙏", "🦶", "🦵", "🦿", "🦷", "🦴", "👀", "👁️‍🗨️",
//       "👅", "👄", "💋", "🩸", "👂", "🦻", "👃", "🫁", "👣", "🦰", "🦱", "🦲", "🦳", "🐵", "🐒",
//       "🦍", "🦧", "🐶", "🐕", "🦮", "🐕‍🦺", "🐩", "🐺", "🦊", "🦝", "🐱", "🐈", "🐈‍⬛", "🦁", "🐯",
//       "🐅", "🐆", "🐴", "🐎", "🦄", "🦓", "🦌", "🦬", "🐮", "🐂", "🐃", "🐄", "🐷", "🐖", "🐗",
//       "🐽", "🐏", "🐑", "🐐", "🐪", "🐫", "🦙", "🦒", "🐘", "🦣", "🦏", "🦛", "🐭", "🐁", "🐀",
//       "🐹", "🐰", "🐇", "🐿️", "🦫", "🦔", "🦇", "🐻", "🐨", "🐼", "🦥", "🦦", "🦨", "🦘", "🦡",
//       "🐾", "🦃", "🐔", "🐓", "🐣", "🐤", "🐥", "🐦", "🐧", "🕊️", "🦅", "🦆", "🦢", "🦉", "🦩",
//       "🦚", "🦜", "🐸", "🐊", "🐢", "🦎", "🐍", "🐲", "🐉", "🦕", "🦖", "🐳", "🐋", "🐬", "🦭",
//       "🐟", "🐠", "🐡", "🦈", "🐙", "🐚", "🐌", "🦋", "🐛", "🐜", "🐝", "🪰", "🐞", "🦗", "🪳",
//       "🕷️", "🕸️", "🦂", "🦟", "🪲", "🦠", "💐", "🌸", "💮", "🏵️", "🌹", "🥀", "🌺", "🌻", "🌼",
//       "🌷", "🌱", "🌲", "🌳", "🌴", "🌵", "🌾", "🌿", "☘️", "🍀", "🍁", "🍂", "🍃", "🍄", "🌰",
//       "🦀", "🦞", "🦐", "🦑", "🌍", "🌎", "🌏", "🌐", "🌑", "🌒", "🌓", "🌔", "🌕", "🌖", "🌗",
//       "🌘", "🌙", "🌚", "🌛", "🌜", "☀️", "🌝", "🌞", "🪐", "⭐", "🌟", "🌠", "🌌", "☁️", "⛅",
//       "⛈️", "🌤️", "🌥️", "🌦️", "🌧️", "🌨️", "🌩️", "🌪️", "🌫️", "🌬️", "🌀", "🌈", "🌂", "☂️", "☔",
//       "⚡", "❄️", "☃️", "⛄", "☄️", "🔥", "💧", "🌊"
//   ];

//   const travelAndPlaces = [
//       "🚗", "🚕", "🚙", "🚌", "🚎", "🏎️", "🚓", "🚑", "🚒", "🚐", "🚚", "🚛", "🚜", "🛴", "🚲",
//       "🛵", "🏍️", "🛺", "🚍", "🚘", "🚖", "🚡", "🚠", "🚟", "🚃", "🚋", "🚞", "🚝", "🚄", "🚅",
//       "🚈", "🚂", "🚆", "🚇", "🚊", "🚉", "🛸", "🛫", "🛬", "🛩️", "💺", "🚁", "🛶", "⛵", "🚤",
//       "🛥️", "🚢", "🗼", "🏰", "🏯", "🏟️", "🎡", "🎢", "🎠", "⛲", "⛺", "🌁", "🏔️", "⛰️", "🌋",
//       "🗻", "🏕️", "🏖️", "🏜️", "🏝️", "🏞️", "🏗️", "🌇", "🌆", "🏙️", "🌃", "🌉", "🌌", "🌠", "🎇",
//       "🎆", "🌈", "🏘️", "🏚️", "🏢", "🏬", "🏣", "🏤", "🏥", "🏦", "🏨", "🏪", "🏫", "🏩", "💒",
//       "🏛️", "⛪", "🕌", "🕍", "🕋", "⛩️", "🛕", "🌄", "🌅", "🏠", "🏡", "🏥", "🏦", "🏤", "🏨",
//       "🏪", "🏬", "🌆", "🌇", "🌃", "🏙️", "🌉", "🌌", "🌠", "🎇", "🎆", "🌈",
//   ];

//   const object = [
//       "⌚", "📱", "💻", "⌨️", "🖥️", "🖱️", "🕹️", "🗜️", "💽", "💾", "💿", "📀", "🧮", "🎥", "📷",
//       "📸", "📹", "📼", "🔍", "🔎", "🔬", "🔭", "📡", "📠", "📺", "📻", "🎙️", "🎚️", "🎛️", "⏱️",
//       "⏲️", "⏰", "🕰️", "⌛", "⏳", "📡", "🔋", "🔌", "💡", "🔦", "🕯️", "🪔", "🧯", "🛢️", "🔧",
//       "🔨", "⚒️", "🛠️", "⛏️", "🔩", "⚙️", "🗜️", "⚖️", "🦯", "🔗", "⛓️", "🧰", "🧲", "🔫", "🛡️",
//       "🚬", "⚰️", "⚱️", "🗺️", "🧭", "🧱", "🔮", "🧿", "🏮", "🪑", "🧼", "🧽", "🧴", "🛁", "🚿",
//       "🪒", "🧻", "🧺", "🧹", "🧺", "🧹", "🧼", "🧽", "🧯", "🛢️", "🪣", "🧰", "🔧", "🔩", "⚙️",
//       "🛠️", "🧲", "🔫", "🚬", "🔪", "🧫", "🧬", "🔬", "🔭", "📡", "🛎️", "🔑", "🗝️", "🚪", "🛋️",
//       "🪑", "🚽", "🚿", "🛁", "🪒", "🧻", "🧼", "🧽", "🧴", "🪥", "🪦", "🏺", "🔑", "🗝️", "🛋️",
//       "🪑", "🚽", "🚿", "🛁", "🪒", "🧻", "🧼", "🧽", "🧴", "🪥", "🪦", "🏺", "🔖", "🚬", "🔗",
//       "📎", "🖇️", "📌", "📍", "🧷", "🧵", "🧶", "🔊", "🔈", "🔇", "📢", "📣", "📯"
//   ];


//   return (
//       <div className={styles.emojis_div} style={{backgroundColor: mode ? "#000":"#F8F9FC"}} >
//           <div className={styles.emojis_div1}>
//               <b style={{color:mode?"#fff":"#000"}} >Smileys</b>
//               <div className={styles.imogy} >
//               {smileys?.map((emoji, index) => (
//                     <span
//                         key={index}
//                         className={styles.emojis_icon_css}
//                         onClick={() => onSelect(emoji)} >

//                         {emoji}
//                     </span>
//                 ))}
//               </div>
//           </div>
//            <div className={styles.emojis_div1}>
//               <b style={{color:mode?"#fff":"#000"}}  >Travel And Places</b>
//               <div className={styles.imogy} >
//               {travelAndPlaces?.map((emoji, index) => (
//                     <span
//                         key={index}
//                         className={styles.emojis_icon_css}
//                         onClick={() => onSelect(emoji)}
//                     >
//                         {emoji}
//                     </span>
//                 ))}
//               </div>
//           </div>
//          <div className={styles.emojis_div1}>
//               <b style={{color:mode?"#fff":"#000"}}  >Object</b>
//               <div className={styles.imogy} >
//               {object?.map((emoji, index) => (
//                     <span
//                         key={index}
//                         className={styles.emojis_icon_css}
//                         onClick={() => onSelect(emoji)}
//                     >
//                         {emoji}
//                     </span>
//                 ))}
//               </div>
//           </div>


//       </div>
//   );
// };
























import React, { useRef, useState, useEffect } from "react";
import styles from "./FromComponent.module.css";
import { useAuth } from "../../context/Auth";
import { BoldIcon, EmojisIcon, ItalicIcon } from "../../icons/Icon";

const Editer = ({ bodyText, setBodyText }) => {
  const textareaRef = useRef(null);
  const { mode } = useAuth();
  const [selectionStart, setSelectionStart] = useState(0);
  const [selectionEnd, setSelectionEnd] = useState(0);
  const [showEmojis, setShowEmojis] = useState(false);

  useEffect(() => {

  }, [bodyText])

  // const applyBold = () => {
  //   if (selectionStart === selectionEnd) {
  //     textareaRef.current.focus();
  //     return;
  //   }
  //   const selectedText = bodyText.slice(selectionStart, selectionEnd);
  //   const isBold = selectedText.startsWith('*') && selectedText.endsWith('*');

  //   let newText;
  //   if (isBold) {
  //     newText = `${bodyText.slice(0, selectionStart)}${selectedText.slice(1, -1)}${bodyText.slice(selectionEnd)}`;
  //   } else {
  //     newText = `${bodyText.slice(0, selectionStart)}*${selectedText}*${bodyText.slice(selectionEnd)}`;
  //   }
  //   setSelectionEnd(0);
  //   setSelectionStart(0);
  //   setBodyText(newText);
  // };

  // const applyItalic = () => {
  //   if (selectionStart === selectionEnd) {
  //     textareaRef.current.focus();
  //     return;
  //   }
  //   const selectedText = bodyText.slice(selectionStart, selectionEnd);
  //   const isItalic = selectedText.startsWith('_') && selectedText.endsWith('_');

  //   let newText;
  //   if (isItalic) {
  //     newText = `${bodyText.slice(0, selectionStart)}${selectedText.slice(1, -1)}${bodyText.slice(selectionEnd)}`;
  //   } else {
  //     newText = `${bodyText.slice(0, selectionStart)}_${selectedText}_${bodyText.slice(selectionEnd)}`;
  //   }
  //   setSelectionEnd(0);
  //   setSelectionStart(0);
  //   setBodyText(newText);
  // };



  const applyBold = () => {
    if (selectionStart === selectionEnd) {
        textareaRef.current.focus();
        return;
    }

    // Selected text ko trim karein
    const selectedText = bodyText.slice(selectionStart, selectionEnd).trim();
    const isBold = selectedText.startsWith('*') && selectedText.endsWith('*');

    let newText;
    if (isBold) {
        // Agar text already bold hai, toh unbold karein
        newText = `${bodyText.slice(0, selectionStart)}${selectedText.slice(1, -1)}${bodyText.slice(selectionEnd)}`;
    } else {
        // Agar text bold nahi hai, toh bold karein
        newText = `${bodyText.slice(0, selectionStart)}*${selectedText}*${bodyText.slice(selectionEnd)}`;
    }
    setSelectionEnd(0);
    setSelectionStart(0);
    setBodyText(newText);
};

const applyItalic = () => {
    if (selectionStart === selectionEnd) {
        textareaRef.current.focus();
        return;
    }

    // Selected text ko trim karein
    const selectedText = bodyText.slice(selectionStart, selectionEnd).trim();
    const isItalic = selectedText.startsWith('_') && selectedText.endsWith('_');

    let newText;
    if (isItalic) {
        // Agar text already italic hai, toh unitalic karein
        newText = `${bodyText.slice(0, selectionStart)}${selectedText.slice(1, -1)}${bodyText.slice(selectionEnd)}`;
    } else {
        // Agar text italic nahi hai, toh italic karein
        newText = `${bodyText.slice(0, selectionStart)}_${selectedText}_${bodyText.slice(selectionEnd)}`;
    }
    setSelectionEnd(0);
    setSelectionStart(0);
    setBodyText(newText);
};


  const handleTextChange = (e) => {
    let text = e.target.value;
    text = text.replace(/(\n\s*){2,}/g, '\n\n');
    setBodyText(text);
  };

  const handleSelectionChange = (event) => {
    setSelectionStart(event.target.selectionStart);
    setSelectionEnd(event.target.selectionEnd);
  };




  const handleEmojiSelect = (emoji) => {
    setBodyText((prevContent) => prevContent + emoji);
  };

  const emojisRef = useRef(null);
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (emojisRef.current && !emojisRef.current.contains(event.target)) {
        setShowEmojis(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);




  return (
    <div className={styles.editerCointner}>
      <div className={styles.editerCointner1} >
        <p className={styles.para}>
          Body&nbsp;<span className={styles.star}>*</span>
        </p>
        <textarea
          ref={textareaRef}
          placeholder="Enter Message Here"
          rows={4}
          className={styles.templateBody}
          value={bodyText}
          // onChange={(e) => setBodyText(e.target.value)}
          onChange={handleTextChange}
          onSelect={handleSelectionChange}
          // onKeyDown={handleKeyDown} 
          required
          style={mode ? { backgroundColor: "#1B1D21", border: "1px solid #1B1D21", color: "#fff" } :
            { backgroundColor: "#fff", border: "1px solid #cecccc", color: "#000" }}
        />
      </div>
      <div className={styles.btnCon}>
        <div className={styles.templateBody_count} style={{ color: mode ? "#fff" : "#000" }} >
          Characters: {bodyText?.length} / 1024
        </div>
        <div className={styles.btnContiner}>
          <div className={styles.txtEditor_btn}>
            <div
              className={styles.icon_css1}
              onClick={() => setShowEmojis(!showEmojis)}
            >
              <div className={styles.po}>
                {showEmojis && <EmojiPicker onSelect={handleEmojiSelect} />}
              </div>
              <EmojisIcon mode={mode} />
            </div>
            <div className={styles.icon_css} onClick={applyBold}>
              <BoldIcon mode={mode} />
            </div>
            <div className={styles.icon_css} onClick={applyItalic}>
              <ItalicIcon mode={mode} />
            </div>
            {/* <div className={styles.icon_css} onClick={applyLineBreak}>
            <Arro   mode={mode}/>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Editer;

const EmojiPicker = ({ onSelect }) => {

  const { mode } = useAuth()
  const smileys = [
    "😀", "😃", "😄", "😁", "😆", "😅", "😂", "🤣", "😊", "😇", "🙂", "🙃", "😉", "😌", "😍",
    "🥰", "😘", "😗", "😙", "😚", "😋", "😛", "😝", "😜", "🤪", "🤨", "🧐", "🤓", "😎", "🤩",
    "🥳", "😏", "😒", "😞", "😔", "😟", "😕", "🙁", "☹️", "😣", "😖", "😫", "😩", "🥺", "😢",
    "😭", "😤", "😠", "😡", "🤬", "🤯", "😳", "🥵", "🥶", "😱", "😨", "😰", "😥", "😓", "🤗",
    "🤔", "🤭", "🤫", "🤥", "😶", "😐", "😑", "😬", "🙄", "😯", "😦", "😧", "😮", "😲", "🥱",
    "😴", "🤤", "😪", "😵", "🤐", "🥴", "🤢", "🤮", "🤧", "😷", "🤒", "🤕", "🤑", "🤠", "😈",
    "👿", "👹", "👺", "🤡", "💩", "👻", "💀", "☠️", "👽", "👾", "🤖", "🎃", "😺", "😸", "😹",
    "😻", "😼", "😽", "🙀", "😿", "😾", "🤲", "👐", "🙌", "👏", "🤝", "👍", "👎", "👊", "✊",
    "🤛", "🤜", "🤞", "✌️", "🤟", "🤘", "👌", "👈", "👉", "👆", "👇", "☝️", "✋", "🤚", "🖐️",
    "🖖", "👋", "🤙", "💪", "🦾", "🖕", "✍️", "🙏", "🦶", "🦵", "🦿", "🦷", "🦴", "👀", "👁️‍🗨️",
    "👅", "👄", "💋", "🩸", "👂", "🦻", "👃", "🫁", "👣", "🦰", "🦱", "🦲", "🦳", "🐵", "🐒",
    "🦍", "🦧", "🐶", "🐕", "🦮", "🐕‍🦺", "🐩", "🐺", "🦊", "🦝", "🐱", "🐈", "🐈‍⬛", "🦁", "🐯",
    "🐅", "🐆", "🐴", "🐎", "🦄", "🦓", "🦌", "🦬", "🐮", "🐂", "🐃", "🐄", "🐷", "🐖", "🐗",
    "🐽", "🐏", "🐑", "🐐", "🐪", "🐫", "🦙", "🦒", "🐘", "🦣", "🦏", "🦛", "🐭", "🐁", "🐀",
    "🐹", "🐰", "🐇", "🐿️", "🦫", "🦔", "🦇", "🐻", "🐨", "🐼", "🦥", "🦦", "🦨", "🦘", "🦡",
    "🐾", "🦃", "🐔", "🐓", "🐣", "🐤", "🐥", "🐦", "🐧", "🕊️", "🦅", "🦆", "🦢", "🦉", "🦩",
    "🦚", "🦜", "🐸", "🐊", "🐢", "🦎", "🐍", "🐲", "🐉", "🦕", "🦖", "🐳", "🐋", "🐬", "🦭",
    "🐟", "🐠", "🐡", "🦈", "🐙", "🐚", "🐌", "🦋", "🐛", "🐜", "🐝", "🪰", "🐞", "🦗", "🪳",
    "🕷️", "🕸️", "🦂", "🦟", "🪲", "🦠", "💐", "🌸", "💮", "🏵️", "🌹", "🥀", "🌺", "🌻", "🌼",
    "🌷", "🌱", "🌲", "🌳", "🌴", "🌵", "🌾", "🌿", "☘️", "🍀", "🍁", "🍂", "🍃", "🍄", "🌰",
    "🦀", "🦞", "🦐", "🦑", "🌍", "🌎", "🌏", "🌐", "🌑", "🌒", "🌓", "🌔", "🌕", "🌖", "🌗",
    "🌘", "🌙", "🌚", "🌛", "🌜", "☀️", "🌝", "🌞", "🪐", "⭐", "🌟", "🌠", "🌌", "☁️", "⛅",
    "⛈️", "🌤️", "🌥️", "🌦️", "🌧️", "🌨️", "🌩️", "🌪️", "🌫️", "🌬️", "🌀", "🌈", "🌂", "☂️", "☔",
    "⚡", "❄️", "☃️", "⛄", "☄️", "🔥", "💧", "🌊"
  ];

  const travelAndPlaces = [
    "🚗", "🚕", "🚙", "🚌", "🚎", "🏎️", "🚓", "🚑", "🚒", "🚐", "🚚", "🚛", "🚜", "🛴", "🚲",
    "🛵", "🏍️", "🛺", "🚍", "🚘", "🚖", "🚡", "🚠", "🚟", "🚃", "🚋", "🚞", "🚝", "🚄", "🚅",
    "🚈", "🚂", "🚆", "🚇", "🚊", "🚉", "🛸", "🛫", "🛬", "🛩️", "💺", "🚁", "🛶", "⛵", "🚤",
    "🛥️", "🚢", "🗼", "🏰", "🏯", "🏟️", "🎡", "🎢", "🎠", "⛲", "⛺", "🌁", "🏔️", "⛰️", "🌋",
    "🗻", "🏕️", "🏖️", "🏜️", "🏝️", "🏞️", "🏗️", "🌇", "🌆", "🏙️", "🌃", "🌉", "🌌", "🌠", "🎇",
    "🎆", "🌈", "🏘️", "🏚️", "🏢", "🏬", "🏣", "🏤", "🏥", "🏦", "🏨", "🏪", "🏫", "🏩", "💒",
    "🏛️", "⛪", "🕌", "🕍", "🕋", "⛩️", "🛕", "🌄", "🌅", "🏠", "🏡", "🏥", "🏦", "🏤", "🏨",
    "🏪", "🏬", "🌆", "🌇", "🌃", "🏙️", "🌉", "🌌", "🌠", "🎇", "🎆", "🌈",
  ];

  const object = [
    "⌚", "📱", "💻", "⌨️", "🖥️", "🖱️", "🕹️", "🗜️", "💽", "💾", "💿", "📀", "🧮", "🎥", "📷",
    "📸", "📹", "📼", "🔍", "🔎", "🔬", "🔭", "📡", "📠", "📺", "📻", "🎙️", "🎚️", "🎛️", "⏱️",
    "⏲️", "⏰", "🕰️", "⌛", "⏳", "📡", "🔋", "🔌", "💡", "🔦", "🕯️", "🪔", "🧯", "🛢️", "🔧",
    "🔨", "⚒️", "🛠️", "⛏️", "🔩", "⚙️", "🗜️", "⚖️", "🦯", "🔗", "⛓️", "🧰", "🧲", "🔫", "🛡️",
    "🚬", "⚰️", "⚱️", "🗺️", "🧭", "🧱", "🔮", "🧿", "🏮", "🪑", "🧼", "🧽", "🧴", "🛁", "🚿",
    "🪒", "🧻", "🧺", "🧹", "🧺", "🧹", "🧼", "🧽", "🧯", "🛢️", "🪣", "🧰", "🔧", "🔩", "⚙️",
    "🛠️", "🧲", "🔫", "🚬", "🔪", "🧫", "🧬", "🔬", "🔭", "📡", "🛎️", "🔑", "🗝️", "🚪", "🛋️",
    "🪑", "🚽", "🚿", "🛁", "🪒", "🧻", "🧼", "🧽", "🧴", "🪥", "🪦", "🏺", "🔑", "🗝️", "🛋️",
    "🪑", "🚽", "🚿", "🛁", "🪒", "🧻", "🧼", "🧽", "🧴", "🪥", "🪦", "🏺", "🔖", "🚬", "🔗",
    "📎", "🖇️", "📌", "📍", "🧷", "🧵", "🧶", "🔊", "🔈", "🔇", "📢", "📣", "📯"
  ];


  return (
    <div className={styles.emojis_div} style={{ backgroundColor: mode ? "#000" : "#F8F9FC" }} >
      <div className={styles.emojis_div1}>
        <b style={{ color: mode ? "#fff" : "#000" }} >Smileys</b>
        <div className={styles.imogy} >
          {smileys?.map((emoji, index) => (
            <span
              key={index}
              className={styles.emojis_icon_css}
              onClick={() => onSelect(emoji)} >

              {emoji}
            </span>
          ))}
        </div>
      </div>
      <div className={styles.emojis_div1}>
        <b style={{ color: mode ? "#fff" : "#000" }}  >Travel And Places</b>
        <div className={styles.imogy} >
          {travelAndPlaces?.map((emoji, index) => (
            <span
              key={index}
              className={styles.emojis_icon_css}
              onClick={() => onSelect(emoji)}
            >
              {emoji}
            </span>
          ))}
        </div>
      </div>
      <div className={styles.emojis_div1}>
        <b style={{ color: mode ? "#fff" : "#000" }}  >Object</b>
        <div className={styles.imogy} >
          {object?.map((emoji, index) => (
            <span
              key={index}
              className={styles.emojis_icon_css}
              onClick={() => onSelect(emoji)}
            >
              {emoji}
            </span>
          ))}
        </div>
      </div>


    </div>
  );
};


