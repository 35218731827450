import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../components/context/Auth";
import {
  HomeImg,
  LogInBtnIcon,
  LogInImg,
  WalliconLogo,
} from "../components/icons/Icon";
import styles from "./Home.module.css";
import CryptoJS from "crypto-js";
import InputTag2 from "../components/updatedComponents/FormComponents/InputTag2";

const Home = () => {
  const navigate = useNavigate();
  const { setDesignation, setManagerId, setManagerName, setLoggedIn, mode } =
    useAuth();
  const [credentials, setCredentials] = useState({
    username: "",
    password: "",
    error: "",
  });
  const [msg, setMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const handleClick = (e) => {
    // e.preventDefault();
    setError(false);
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);

    var raw = JSON.stringify({
      // "loginId": "admin1",
      loginId: credentials.username,
      password: credentials.password,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(process.env.REACT_APP_URL + "managers/login", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "success") {
          // sessionStorage.setItem("designations", result.user.designation)
          // sessionStorage.setItem("designation", result.user.designation[0])
          // sessionStorage.setItem("name", result.user.name)
          sessionStorage.setItem("managerId", result.user.managerId);
          // Encrypt data
          const encryptedName = CryptoJS.AES.encrypt(
            result.user.name,
            "secret key"
          ).toString();
          const encryptedDesignation = CryptoJS.AES.encrypt(
            result.user.designation[0],
            "secret key"
          ).toString();
          const encryptedDesignations = CryptoJS.AES.encrypt(
            JSON.stringify(result.user.designation),
            "secret key"
          ).toString();
          const encryptedLoginId = CryptoJS.AES.encrypt(
            JSON.stringify(result.user.loginId),
            "secret key"
          ).toString();
          // const encryptedManagerId = CryptoJS.AES.encrypt(result.user.managerId, 'secret key').toString();

          // Store encrypted data in session storage
          sessionStorage.setItem("name", encryptedName);
          sessionStorage.setItem("designation", encryptedDesignation);
          sessionStorage.setItem("designations", encryptedDesignations);
          sessionStorage.setItem("loginID", encryptedLoginId);
          // sessionStorage.setItem('managerId', encryptedManagerId);

          setDesignation(result.user.designation[0]);
          setManagerId(result.user.managerId);
          setManagerName(result.user.name);
          setLoggedIn(true);
          navigate("/dashboard");
        } else {
          setCredentials({ ...credentials, error: result.msg });
        }
        setMsg(result.msg);
        setLoading(false);
      })
      .catch((error) => {
        setError(true);
        setLoading(false);
        console.log("ERROR ", error);
      });
  };

  // var designations = sessionStorage.getItem("designation");
  const getDesignation = sessionStorage.getItem("designation")
    ? sessionStorage.getItem("designation")
    : "";
  const decryptedDesignationBytes = CryptoJS.AES.decrypt(
    getDesignation,
    "secret key"
  );
  const designations = decryptedDesignationBytes.toString(CryptoJS.enc.Utf8);
  useEffect(() => {
    if (designations) {
      setDesignation(designations.split(",")[0]);
    }
  }, [setDesignation, designations]);

  // black 1 - #232529
  // black 2 - #2C2E33
  // black 3 - #1B1D21

  return (
    <React.Fragment>
      <div
        className={styles.home}
        style={mode ? { backgroundColor: "#1B1D21" } : {}}
      >
        <div
          className={styles.frame}
          style={mode ? { backgroundColor: "#232529" } : {}}
        >
          <div className={styles.sub_Frame}>
            <div className={styles.home_Logo}>
              <WalliconLogo />
            </div>
            <HomeImg />
            <h1 className={styles.home_wallicon_heading}>
              Wallicon Admin Suite
            </h1>
          </div>
        </div>

        <div
          className={styles.login_container}
          style={mode ? { backgroundColor: "#2C2E33" } : {}}
        >
          <div
            className={styles.login}
            style={mode ? { backgroundColor: "#232529" } : {}}
          >
            <div className={styles.sub_Login}>
              <div className={styles.login_Header}>
                <div className={styles.login_Header_Text}>
                  <h2 style={mode ? { color: "#ffffff" } : {}}>Login</h2>
                  <p style={mode ? { color: "#9C9797" } : {}}>
                    Thank you for get back to Wallicon
                  </p>
                </div>
                <LogInImg />
              </div>
              <form
                className={styles.login_Form}
                onSubmit={(e) => {
                  e.preventDefault();
                  handleClick();
                }}
              >
                <InputTag2
                  labelText={"Username"}
                  placeholderType={"text"}
                  placeholderText={"Enter Username"}
                  required={true}
                  value={credentials.username}
                  maxLength={36}
                  onChange={(e) =>
                    setCredentials({ ...credentials, username: e.target.value })
                  }
                  padding={"16px 12px 16px 12px"}
                />
                <InputTag2
                  labelText={"Password"}
                  placeholderType={"password"}
                  placeholderText={"Enter Password"}
                  required={true}
                  value={credentials.password}
                  maxLength={30}
                  onChange={(e) =>
                    setCredentials({ ...credentials, password: e.target.value })
                  }
                  padding={"16px 12px 16px 12px"}
                />
                <button
                  type="submit"
                  disabled={loading}
                  className={styles.login_button}
                >
                  {loading ? (
                    <>
                      <span className={styles.spinner}></span>
                      <p>Loading...</p>
                    </>
                  ) : (
                    <>
                      <LogInBtnIcon />
                      <p>Login</p>
                    </>
                  )}
                </button>
              </form>

              <Link to={"#"} style={mode ? { color: "#749AFF" } : {}}>
                Need Help?
              </Link>
              {msg && <div className={styles.err}>{msg}</div>}
              {error && (
                <div className={styles.err}>
                  Oops! Something went wrong. Please try again later.
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* 
      <div className={styles.AppHome}>
        <h1>Warning!</h1>
        <Danger className={styles.danger} />
        <h2>The Site ahead contains Malware &amp; Virus!</h2>
        <p>Attackers may trick you into doing something dangerous like installing software which may harm your computer or revealing your personal information (for example, passwords, phone numbers or credit cards).</p>
        <div >
          <GreenOutButton title="Back to Safety" css={styles.btnW} />
          <RedOutButton title="Proceed Anyway" css={styles.btnW + " " + styles.ms50 + " " + styles.px10} handleSubmit={() => setShow(true)} />
        </div>
      </div> */}

      {/* <div
        className={styles.custom_modal}
        onClick={() => setShow(false)}
        style={divStyle}
      >
        <div
          className={`${styles.center} ${styles.text_center} ${styles.modal_content}`}
          onClick={(e) => e.stopPropagation()}
        >
          <div className={styles.center}>
            <h1>Login</h1>
            <input
              type="text"
              placeholder="Enter Login Id"
              className={styles.loginInput}
              value={credentials.username}
              onChange={(e) =>
                setCredentials({ ...credentials, username: e.target.value })
              }
            />
            <br />
            <br />
            <input
              type={eyeSH ? "text" : "password"}
              placeholder="Enter Password"
              className={styles.loginInput}
              value={credentials.password}
              onChange={(e) =>
                setCredentials({ ...credentials, password: e.target.value })
              }
            />
            {eyeSH ?
              <EyeHide className={styles.loginEye} onClick={() => setEyeSH(false)} />
              :
              <Eye className={styles.loginEye} onClick={() => setEyeSH(true)} />
            }
            <div className={styles.err}>{msg}</div>
            <br />
            <br />
            <GreenOutButton
              title={loading ? <Spinner size="15" /> : "Login"}
              handleSubmit={() => {
                handleClick();
              }}
            />
          </div>
        </div>
      </div> */}
    </React.Fragment>
  );
};

export default Home;
