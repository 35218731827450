import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import styles from "./mobile/globalmobilewhatsappchat.module.css";
import { useSearchParams, useNavigate } from "react-router-dom";
import {
  Doubletick,
  DoubletickActive,
  Search,
  SendMessageIcon,
  Close,
  CheckGreenIcon,
  Spinner,
  CrossCircelIcon,
  CheckIcon,
  DownloadFileIcon,
  ThreeDots,
  WhatsappEmojisWhiteIcon,
  AddFileDark,
  BackArrowIcon,
  Phone,
  GotoWebsiteIcon,
  WhatsappEmojisIcon,
  AddFile,
  ImageShare,
  VideoShare,
  DocumentShare,
  AudioShare,
  LinkShare,
  LocationShare,
  PdfShare,
  StockShare,
  Unread,
} from "../../icons/Icon3";
// import Modal from "../../Modal";

// import { GreenButton, RedOutButton } from "../../Buttons";
import { io } from "socket.io-client";
import { useAuth } from "../../context/Auth";
import MobileWrapper from "./mobile/MobileWrapper";
import MobileHeader from "./mobile/MobileHeader";
import MobileOneToOneChat from "./mobile/MobileOneToOneChat";
import ModalWrapper from "../layoutWrapper/ModalWrapper";
import Button from "../Dealers/Button";
import { Block } from "../../icons/Icon2";
import MobileAllDealerChats from "./mobile/MobileAllDealerChats";
import CustomHeadingText from "./chats/CustomHeadingText";
import Modal from "./mobile/BottomDrawer";
const SOCKET_SERVER_URL = process.env.REACT_APP_URL;
const SOCKET_HEROKUAPP_URL = "https://whatsapp-meta-79578db556ac.herokuapp.com";

// const fileOptions = [
//   { type: "image", label: "Image", Icon: ImageShare },
//   { type: "video", label: "Video", Icon: VideoShare },
//   { type: "document", label: "Document", Icon: DocumentShare },
//   { type: "audio", label: "Audio", Icon: AudioShare },
//   { type: "link", label: "Link", Icon: LinkShare },
//   { type: "location", label: "Location", Icon: LocationShare },
//   { type: "pdf", label: "PDF", Icon: PdfShare },
//   { type: "stock", label: "Stock", Icon: StockShare },
// ];

const MainMobileChats = () => {
  const { mode, totalUnreadChat } = useAuth();
  const headerStyle = {
    backgroundColor: mode ? "#2C2E33" : "#F0F2F5",
    color: mode ? "#fff" : "#646B88",
  };

  const btnstyle = {
    backgroundColor: mode ? "#000000" : "#E4E7EB",
    color: mode ? "#fff" : "#000",
  };

  const borderColor = mode ? "2px solid #F1F1F1" : "2px solid #82889F";

  const navigate = useNavigate();
  const { managerId, setTotalUnreadChat, designation } = useAuth();
  const [searchParams] = useSearchParams();
  const mobile = searchParams.get("mobile");
  const [message, setMessage] = useState();
  const [mergeMessage, setMergeMessage] = useState([]);
  const [chats, setChats] = useState([]);
  const [reload, setReload] = useState(false);
  const [reloadDealer, setReloadDealer] = useState(false);
  const [data, setData] = useState([]);
  const [dealerData, setDealerData] = useState({});
  const [keyword, setKeyword] = useState("");
  const [showFileOptions, setShowFileOptions] = useState();
  const [managersOptions, setManagersOptions] = useState();
  const [markRead, setMarkRead] = useState();
  const [fileType, setFileType] = useState();
  const [fileExt, setFileExt] = useState();
  const [files, setFiles] = useState([]);
  const [caption, setCaption] = useState();
  const [selectDealers, setSelectDealers] = useState(false);
  const [selectAllDealers, setSelectAllDealers] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  // const [selectAll, setSelectAll] = useState(false);
  const [readMessage, setReadMessage] = useState("");
  const [uploadLoading, setUploadLoading] = useState(false);
  const [selectManager, setSelectManager] = useState("");
  const [allDealers, setAllDealers] = useState([]);
  const [selectNewDealers, setSelectNewDealers] = useState(false);
  const [selectAllNewDealers, setSelectAllNewDealers] = useState(false);
  // const [selectAllNew, setSelectAllNew] = useState(false);
  const [keywordDealers, setKeywordDealers] = useState("");
  const [day, setDay] = useState("");
  const [selectManagerData, setSelectManagerData] = useState([]);
  const [showMarkRead, setShowMarkRead] = useState(false);
  const [selectManagerChat, setSelectManagerChat] = useState(false);

  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState();
  const [pageChat, setPageChat] = useState(1);
  const [totalPageChat, setTotalPageChat] = useState();
  const containerRef = useRef(null);
  const containerRefwhatsapp = useRef(null);
  const [loadingPagination, setLoadingPagination] = useState(false);
  const [loadingPaginationContact, setLoadingPaginationContact] =
    useState(false);
  const [loadingPaginationChats, setLoadingPaginationChats] = useState(false);
  const containerChatRef = useRef(null);
  const [paginationChat, setPaginationChat] = useState(1);
  const [totalPaginationChat, setTotalPaginationChat] = useState();
  const [showAssignManagerModal, setShowAssignManagerModal] = useState(false);
  const [allAdminChats, setAllAdminChats] = useState([]);
  const [allDealerChats, setAllDealerChats] = useState([]);

  // ============================================= for Link =======================================

  const [metaData, setMetaData] = useState({}); // Store metadata for multiple URLs
  const [error, setError] = useState("");

  // Function to fetch metadata
  const fetchMetaData = async (url) => {
    if (!url) {
      setError("Please enter a valid URL");
      return;
    }

    setError(""); // Clear previous errors

    if (isYouTubeLink(url)) {
      // Use YouTube oEmbed API to get metadata
      const videoId = extractYouTubeVideoId(url);
      const oEmbedUrl = `https://www.youtube.com/oembed?url=https://www.youtube.com/watch?v=${videoId}&format=json`;

      try {
        const response = await fetch(oEmbedUrl);
        const data = await response.json();

        // Set YouTube metadata
        setMetaData((prevMetaData) => ({
          ...prevMetaData,
          [url]: {
            title: data.title || "No title found",
            description: `By ${data.author_name || "Unknown"}`, // Use author name from oEmbed response
            favicon: "https://www.youtube.com/favicon.ico",
            thumbnail: data.thumbnail_url || "", // Use the thumbnail URL from oEmbed response
          },
        }));
      } catch (err) {
        console.error("Error fetching YouTube video details:", err);
        setError("Error fetching YouTube video details.");
      }
    } else {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_URL}fetch-metadata?url=${encodeURIComponent(
            url
          )}`
        );

        const html = await response.text();

        // Parse the HTML using DOMParser
        const parser = new DOMParser();
        const doc = parser.parseFromString(html, "text/html");

        // Extract meta title
        const title = doc.querySelector("title")
          ? doc.querySelector("title").innerText
          : "No title found";

        // Extract meta description
        const description = doc.querySelector('meta[name="description"]')
          ? doc
              .querySelector('meta[name="description"]')
              .getAttribute("content")
          : "No description found";

        // Extract favicon link
        const favicon = doc.querySelector('link[rel="icon"]')
          ? doc.querySelector('link[rel="icon"]').getAttribute("href")
          : doc.querySelector('link[rel="shortcut icon"]')
          ? doc.querySelector('link[rel="shortcut icon"]').getAttribute("href")
          : "";

        // Update the state with extracted data
        setMetaData((prevMetaData) => ({
          ...prevMetaData,
          [url]: {
            title,
            description,
            favicon,
            thumbnail: "", // Clear thumbnail for non-YouTube URLs
          },
        }));
      } catch (err) {
        console.error("Error fetching the URL:", err);
        setError("Failed to fetch data. Make sure the URL is correct.");
      }
    }
  };

  // Check if the URL is a YouTube link
  const isYouTubeLink = (url) => {
    return url.includes("youtube.com") || url.includes("youtu.be");
  };

  // Extract YouTube video ID
  const extractYouTubeVideoId = (url) => {
    const regex =
      /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    const match = url.match(regex);
    return match ? match[1] : null;
  };

  // Fetch metadata for each chat's adminChat URL
  useEffect(() => {
    chats?.forEach((chat) => {
      if (chat.adminChat && chat.adminChat.includes("https")) {
        const existingMeta = metaData[chat.adminChat];
        if (!existingMeta) {
          fetchMetaData(chat.adminChat); // Fetch metadata for each URL
        }
      }
      if (chat.dealerChat && chat.dealerChat.includes("https")) {
        const existingMetaDealer = metaData[chat.dealerChat];
        if (!existingMetaDealer) {
          fetchMetaData(chat.dealerChat); // Fetch metadata for dealerChat
        }
      }
    });
  }, [chats]); // Dependency on chats

  // console.log("metaData =======------>", metaData);

  // ==================================================================================

  // const sentMessage = () => {
  //   if (mobile) {
  //     if (files && files.length !== 0) {
  //       setUploadLoading(true);
  //       const myHeaders = new Headers();
  //       myHeaders.append(
  //         "Authorization",
  //         "Bearer EABljmRig1VcBO9kZCqpF22rK9agSLkczn0HjJ52IiTdupNsFPjot7YV83mijy73Ej1xu4nEror2K5geGAP9W27AbgvWmUPKbVNVZC65zRabCuf3ZCVJcW9beMYotKihZBhrMy0WojNeqfFZAkyMFczmg8jXjgDBXLhl5watioYBy5lLPBmLHbrgVGRWnvMdpj0wnmZADWJVYSoWZBKz"
  //       );
  //       myHeaders.append("Cookie", "ps_l=1; ps_n=1");

  //       const formdata = new FormData();
  //       formdata.append("messaging_product", "whatsapp");
  //       formdata.append("file", files[0]);

  //       const requestOptions = {
  //         method: "POST",
  //         headers: myHeaders,
  //         body: formdata,
  //         redirect: "follow",
  //       };

  //       fetch(
  //         "https://graph.facebook.com/v19.0/161149970425453/media",
  //         requestOptions
  //       )
  //         .then((response) => response.json())
  //         .then((result) => {
  //           if (result.id) {
  //             sendMediaRequest(result.id);
  //           }
  //         })
  //         .catch((error) => console.error(error));
  //     } else if (
  //       (fileType === "link" || message.includes("https")) &&
  //       message
  //     ) {
  //       const myHeaders = new Headers();
  //       myHeaders.append("Content-Type", "application/json");
  //       myHeaders.append(
  //         "Authorization",
  //         "Bearer EABljmRig1VcBO9kZCqpF22rK9agSLkczn0HjJ52IiTdupNsFPjot7YV83mijy73Ej1xu4nEror2K5geGAP9W27AbgvWmUPKbVNVZC65zRabCuf3ZCVJcW9beMYotKihZBhrMy0WojNeqfFZAkyMFczmg8jXjgDBXLhl5watioYBy5lLPBmLHbrgVGRWnvMdpj0wnmZADWJVYSoWZBKz"
  //       );
  //       myHeaders.append("Cookie", "ps_l=1; ps_n=1");

  //       const raw = JSON.stringify({
  //         messaging_product: "whatsapp",
  //         to: mobile,
  //         text: {
  //           preview_url: true,
  //           body: message,
  //         },
  //       });

  //       const requestOptions = {
  //         method: "POST",
  //         headers: myHeaders,
  //         body: raw,
  //         redirect: "follow",
  //       };

  //       fetch(
  //         "https://graph.facebook.com/v19.0/161149970425453/messages",
  //         requestOptions
  //       )
  //         .then((response) => response.json())
  //         .then((result) => {
  //           if (result.messages[0].id) {
  //             setMessage("");
  //             wallikonChat();
  //           }
  //         })
  //         .catch((error) => console.error(error));
  //     } else if (message && message.length !== 0) {
  //       setUploadLoading(true);
  //       const myHeaders = new Headers();
  //       myHeaders.append("Content-Type", "application/json");
  //       myHeaders.append(
  //         "Authorization",
  //         "Bearer EABljmRig1VcBO9kZCqpF22rK9agSLkczn0HjJ52IiTdupNsFPjot7YV83mijy73Ej1xu4nEror2K5geGAP9W27AbgvWmUPKbVNVZC65zRabCuf3ZCVJcW9beMYotKihZBhrMy0WojNeqfFZAkyMFczmg8jXjgDBXLhl5watioYBy5lLPBmLHbrgVGRWnvMdpj0wnmZADWJVYSoWZBKz"
  //       );

  //       const raw = JSON.stringify({
  //         messaging_product: "whatsapp",
  //         to: mobile,
  //         text: {
  //           body: message,
  //         },
  //       });

  //       const requestOptions = {
  //         method: "POST",
  //         headers: myHeaders,
  //         body: raw,
  //         redirect: "follow",
  //       };

  //       fetch(
  //         "https://graph.facebook.com/v19.0/161149970425453/messages",
  //         requestOptions
  //       )
  //         .then((response) => response.json())
  //         .then((result) => {
  //           if (result.messages[0].id) {
  //             setMessage("");
  //             setUploadLoading(false);
  //             wallikonChat();
  //           }
  //         })
  //         .catch((error) => console.error(error));
  //     } else {
  //       alert("Please Type Message");
  //     }
  //   } else {
  //     alert("Please select a dealer");
  //   }
  // };

  const sentMessage = async () => {
    const audio = new Audio("/assets/sendmessage.mp3");
    setUploadLoading(true);
    try {
      const myHeaders = new Headers();
      myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);

      const formdata = new FormData();
      formdata.append("managerId", managerId);
      formdata.append("phoneNumber", mobile);
      formdata.append("messageType", fileType ? fileType : "text");
      formdata.append("adminChat", message);
      if (fileType && files.length > 0) {
        formdata.append("fileName", files[0]?.name);
        formdata.append("file", files[0]);

        formdata.append("caption", caption);
      }

      // console.log("==============> Formdata of Files", formdata);

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        // redirect: "follow",
      };

      const res = await fetch(
        process.env.REACT_APP_URL + "wallikonChat/metaChat",
        requestOptions
      );

      const result = await res.json();
      if (result.status === "success") {
        setUploadLoading(false);
        // console.log("Data send =========>", result);
        setMessage("");
        setCaption("");
        setFiles([]);
        setFileType("");
        fetchChat(setPaginationChat(1));
        dealerAllChat();
        audio
          .play()
          .catch((error) => console.error("Error playing sound:", error));
        // alert(result.msg);
      }
    } catch (error) {
      setMessage("");
      setCaption("");
      setFiles([]);
      setFileType("");
      fetchChat(setPaginationChat(1));
      console.log("Something Error Found");
      setUploadLoading(false);
    } finally {
      setUploadLoading(false);
    }
  };

  // const handleAddAdminMediaFile = () => {
  //   setReload(false);
  //   const myHeaders = new Headers();
  //   myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);

  //   const formdata = new FormData();
  //   formdata.append("media", files[0]);

  //   const requestOptions = {
  //     method: "POST",
  //     headers: myHeaders,
  //     body: formdata,
  //     redirect: "follow",
  //   };
  //   fetch(
  //     process.env.REACT_APP_URL +
  //       "wallikonChat/metaChat?whatsapp=" +
  //       mobile +
  //       "&managerId=" +
  //       managerId,
  //     requestOptions
  //   )
  //     .then((response) => response.json())
  //     .then((result) => {
  //       if (result.status === "success") {
  //         setFiles([]);
  //         setReload(true);
  //         setUploadLoading(false);
  //       }
  //     })
  //     .catch((error) => console.error(error));
  // };

  // const sendMediaRequest = (fileId) => {
  //   if (mobile && fileId && files[0]) {
  //     if (
  //       fileType === "image" ||
  //       fileExt === "jpg" ||
  //       fileExt === "jpeg" ||
  //       fileExt === "png" ||
  //       fileExt === "webp" ||
  //       fileExt === "svg" ||
  //       fileExt === "gif" ||
  //       fileExt === "ai" ||
  //       fileExt === "heic" ||
  //       fileExt === "eps" ||
  //       fileExt === "psd" ||
  //       fileExt === "cdr"
  //     ) {
  //       const myHeaders = new Headers();
  //       myHeaders.append("Content-Type", "application/json");
  //       myHeaders.append(
  //         "Authorization",
  //         "Bearer EABljmRig1VcBO9kZCqpF22rK9agSLkczn0HjJ52IiTdupNsFPjot7YV83mijy73Ej1xu4nEror2K5geGAP9W27AbgvWmUPKbVNVZC65zRabCuf3ZCVJcW9beMYotKihZBhrMy0WojNeqfFZAkyMFczmg8jXjgDBXLhl5watioYBy5lLPBmLHbrgVGRWnvMdpj0wnmZADWJVYSoWZBKz"
  //       );
  //       myHeaders.append("Cookie", "ps_l=1; ps_n=1");

  //       const raw = JSON.stringify({
  //         messaging_product: "whatsapp",
  //         recipient_type: "individual",
  //         to: mobile,
  //         type:
  //           fileExt === "jpg" ||
  //           fileExt === "jpeg" ||
  //           fileExt === "png" ||
  //           fileExt === "webp" ||
  //           fileExt === "svg" ||
  //           fileExt === "gif" ||
  //           fileExt === "ai" ||
  //           fileExt === "heic" ||
  //           fileExt === "eps" ||
  //           fileExt === "psd" ||
  //           fileExt === "cdr"
  //             ? "image"
  //             : fileType,
  //         image: {
  //           id: fileId,
  //           caption: caption ? caption : "",
  //         },
  //       });

  //       const requestOptions = {
  //         method: "POST",
  //         headers: myHeaders,
  //         body: raw,
  //         redirect: "follow",
  //       };

  //       fetch(
  //         "https://graph.facebook.com/v19.0/161149970425453/messages",
  //         requestOptions
  //       )
  //         .then((response) => response.json())
  //         .then((result) => {
  //           if (result.messages[0].id) {
  //             handleAddAdminMediaFile();
  //           }
  //         })
  //         .catch((error) => console.error(error));
  //     } else if (fileType === "document" || fileExt === "pdf") {
  //       const myHeaders = new Headers();
  //       myHeaders.append("Content-Type", "application/json");
  //       myHeaders.append(
  //         "Authorization",
  //         "Bearer EABljmRig1VcBO9kZCqpF22rK9agSLkczn0HjJ52IiTdupNsFPjot7YV83mijy73Ej1xu4nEror2K5geGAP9W27AbgvWmUPKbVNVZC65zRabCuf3ZCVJcW9beMYotKihZBhrMy0WojNeqfFZAkyMFczmg8jXjgDBXLhl5watioYBy5lLPBmLHbrgVGRWnvMdpj0wnmZADWJVYSoWZBKz"
  //       );
  //       myHeaders.append("Cookie", "ps_l=1; ps_n=1");

  //       const raw = JSON.stringify({
  //         messaging_product: "whatsapp",
  //         recipient_type: "individual",
  //         to: mobile,
  //         type: fileExt === "pdf" ? "document" : fileType,
  //         document: {
  //           id: fileId,
  //           caption: caption ? caption : "",
  //           filename: files[0].name && files[0].name,
  //         },
  //       });

  //       const requestOptions = {
  //         method: "POST",
  //         headers: myHeaders,
  //         body: raw,
  //         redirect: "follow",
  //       };

  //       fetch(
  //         "https://graph.facebook.com/v19.0/161149970425453/messages",
  //         requestOptions
  //       )
  //         .then((response) => response.json())
  //         .then((result) => {
  //           if (result.messages[0].id) {
  //             handleAddAdminMediaFile();
  //           }
  //         })
  //         .catch((error) => console.error(error));
  //     } else if (fileType === "video" || fileExt === "mp4") {
  //       const myHeaders = new Headers();
  //       myHeaders.append("Content-Type", "application/json");
  //       myHeaders.append(
  //         "Authorization",
  //         "Bearer EABljmRig1VcBO9kZCqpF22rK9agSLkczn0HjJ52IiTdupNsFPjot7YV83mijy73Ej1xu4nEror2K5geGAP9W27AbgvWmUPKbVNVZC65zRabCuf3ZCVJcW9beMYotKihZBhrMy0WojNeqfFZAkyMFczmg8jXjgDBXLhl5watioYBy5lLPBmLHbrgVGRWnvMdpj0wnmZADWJVYSoWZBKz"
  //       );
  //       myHeaders.append("Cookie", "ps_l=1; ps_n=1");

  //       const raw = JSON.stringify({
  //         messaging_product: "whatsapp",
  //         recipient_type: "individual",
  //         to: mobile,
  //         type: fileExt === "mp4" ? "video" : fileType,
  //         video: {
  //           id: fileId,
  //           caption: caption ? caption : "",
  //         },
  //       });

  //       const requestOptions = {
  //         method: "POST",
  //         headers: myHeaders,
  //         body: raw,
  //         redirect: "follow",
  //       };

  //       fetch(
  //         "https://graph.facebook.com/v19.0/161149970425453/messages",
  //         requestOptions
  //       )
  //         .then((response) => response.json())
  //         .then((result) => {
  //           if (result.messages[0].id) {
  //             handleAddAdminMediaFile();
  //           }
  //         })
  //         .catch((error) => console.error(error));
  //     } else if (fileType === "audio" || fileExt === "mp3") {
  //       const myHeaders = new Headers();
  //       myHeaders.append("Content-Type", "application/json");
  //       myHeaders.append(
  //         "Authorization",
  //         "Bearer EABljmRig1VcBO9kZCqpF22rK9agSLkczn0HjJ52IiTdupNsFPjot7YV83mijy73Ej1xu4nEror2K5geGAP9W27AbgvWmUPKbVNVZC65zRabCuf3ZCVJcW9beMYotKihZBhrMy0WojNeqfFZAkyMFczmg8jXjgDBXLhl5watioYBy5lLPBmLHbrgVGRWnvMdpj0wnmZADWJVYSoWZBKz"
  //       );
  //       myHeaders.append("Cookie", "ps_l=1; ps_n=1");

  //       const raw = JSON.stringify({
  //         messaging_product: "whatsapp",
  //         recipient_type: "individual",
  //         to: mobile,
  //         type: fileExt === "mp3" ? "audio" : fileType,
  //         audio: {
  //           id: fileId,
  //         },
  //       });

  //       const requestOptions = {
  //         method: "POST",
  //         headers: myHeaders,
  //         body: raw,
  //         redirect: "follow",
  //       };

  //       fetch(
  //         "https://graph.facebook.com/v19.0/161149970425453/messages",
  //         requestOptions
  //       )
  //         .then((response) => response.json())
  //         .then((result) => {
  //           if (result.messages[0].id) {
  //             handleAddAdminMediaFile();
  //           }
  //         })
  //         .catch((error) => console.error(error));
  //     } else {
  //       alert("wrong file type please try again");
  //     }
  //   }
  // };

  // const wallikonChat = () => {
  //   setReload(false);
  //   const myHeaders = new Headers();
  //   myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);

  //   const formdata = new FormData();
  //   formdata.append(
  //     "adminChat",
  //     fileType === "link" && message
  //       ? "Please visit my website " + message
  //       : message
  //   );

  //   const requestOptions = {
  //     method: "POST",
  //     headers: myHeaders,
  //     body: formdata,
  //     redirect: "follow",
  //   };

  //   fetch(
  //     process.env.REACT_APP_URL +
  //       "wallikonChat/metaChat?whatsapp=" +
  //       mobile +
  //       "&managerId=" +
  //       managerId,
  //     requestOptions
  //   )
  //     .then((response) => response.json())
  //     .then((result) => {
  //       if (result.status === "success") {
  //         setFileType();
  //         setReload(true);
  //       }
  //     })
  //     .catch((error) => console.error(error));
  // };

  // const playSoundSendMessage = () => {
  //   // alert('Play sound')
  //   const audio = new Audio("/assets/sendmessage.mp3");
  //   audio.play().catch((error) => console.log(error));
  // };
  // const playSoundWhatsappMessage = () => {
  //   // alert('Play sound')
  //   const audio = new Audio("/assets/whatsapp_message.mp3");
  //   audio.play().catch((error) => console.log(error));
  // };

  // const playSoundNotification = () => {
  //   // alert('Play sound')
  //   const audio = new Audio("/assets/whatsapp_notification.mp3");
  //   audio.play().catch((error) => console.log(error));
  // };

  // useEffect(() => {
  //   if (mobile && managerId) {
  //     // Establish socket connection
  //     const socket = io(SOCKET_SERVER_URL);
  //     const socketHerokuapp = io(SOCKET_HEROKUAPP_URL);

  //     // Listen for allChats event
  //     socket.on("updateAdminChats", (allAdminChats) => {
  //       // console.log('allAdminChats', typeof allAdminChats.whatsapp);
  //       setAllAdminChats([...allAdminChats.message]);
  //       if (allAdminChats.whatsapp.toString() === mobile) {
  //         playSoundSendMessage();
  //       }
  //     });
  //     socket.on("updateDealerChats", (allDealerChats) => {
  //       console.log("allDealerChats", allDealerChats);
  //       if (allDealerChats && allDealerChats.message) {
  //         setAllDealerChats([...allDealerChats.message]);
  //         if (allDealerChats.whatsapp.toString() === mobile) {
  //           // alert('DealerChats')
  //           playSoundWhatsappMessage();
  //         }
  //       }
  //     });

  //     socketHerokuapp.on("updateDealerChats", (allHerokuChats) => {
  //       // console.log('allHerokuChats', allHerokuChats);
  //       if (allHerokuChats && allHerokuChats.message) {
  //         setAllDealerChats([...allHerokuChats.message]);
  //         if (allHerokuChats.whatsapp.toString() === mobile) {
  //           // alert('allHerokuChats')
  //           playSoundWhatsappMessage();
  //         }
  //       }
  //     });

  //     setLoadingPaginationChats(true);
  //     const myHeaders = new Headers();
  //     myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
  //     myHeaders.append("Content-Type", "application/json");

  //     const raw = JSON.stringify({
  //       whatsapp: parseInt(mobile),
  //       managerId: managerId,
  //     });

  //     const requestOptions = {
  //       method: "POST",
  //       headers: myHeaders,
  //       body: raw,
  //       redirect: "follow",
  //     };

  //     fetch(process.env.REACT_APP_URL + "wallikonChat/chats", requestOptions)
  //       .then((response) => response.json())
  //       .then((result) => {
  //         if (result.status === "success") {
  //           if (result.data?.length !== 0) {
  //             setDealerData(result.data);
  //             setTotalPaginationChat(result.pages);
  //             setLoadingPaginationChats(false);
  //             // setManagers(result.managerInfo)
  //             if (result.data.messages?.length !== 0) {
  //               setMergeMessage([...result.data.messages]);
  //             }
  //           }
  //         }
  //       })
  //       .catch((error) => console.error(error))
  //       .finally(() => setLoadingPaginationChats(false));
  //     return () => {
  //       socket.disconnect();
  //       socketHerokuapp.disconnect();
  //     };
  //   }
  // }, [mobile, reload, managerId, allAdminChats.length, allDealerChats.length]);

  const singleDealerChat = async () => {
    // if (mobile && managerId) {
    try {
      setLoadingPaginationChats(true);
      const myHeaders = new Headers();
      myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({
        whatsapp: parseInt(mobile),
        managerId: managerId,
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        process.env.REACT_APP_URL + "wallikonChat/chats",
        requestOptions
      );
      const result = await res.json();
      if (result.status === "success") {
        if (result.data?.length !== 0) {
          setDealerData(result.data);
          setTotalPaginationChat(result.pages);
          setLoadingPaginationChats(false);
          if (result.data.messages?.length !== 0) {
            setMergeMessage([...result.data.messages]);
          }

          // setManagers(result.managerInfo)
          // if (result.data[0].dealerMessage?.length !== 0 && !result.data[0].adminMessage) {
          //     setMergeMessage([...result.data[0].dealerMessage])
          // } else if (!result.data[0].dealerMessage && result.data[0].adminMessage?.length !== 0) {
          //     setMergeMessage([...result.data[0].adminMessage])
          // } else {
          //     setMergeMessage([...result.data[0].dealerMessage, ...result.data[0].adminMessage])
          // }
        }
      }
    } catch (error) {
      console.log("Error Find", error);
    } finally {
      setLoadingPaginationChats(false);
    }

    // }
  };

  useEffect(() => {
    singleDealerChat();
  }, []);

  // useEffect(() => {
  //   const fetchChat = () => {
  //     if (mobile && paginationChat !== 1) {
  //       setLoadingPaginationChats(true);
  //       const myHeaders = new Headers();
  //       myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
  //       myHeaders.append("Content-Type", "application/json");

  //       const raw = JSON.stringify({
  //         whatsapp: parseInt(mobile),
  //         managerId: managerId,
  //       });

  //       const requestOptions = {
  //         method: "POST",
  //         headers: myHeaders,
  //         body: raw,
  //         redirect: "follow",
  //       };

  //       fetch(
  //         `${process.env.REACT_APP_URL}wallikonChat/chats?page=${paginationChat}`,
  //         requestOptions
  //       )
  //         .then((response) => response.json())
  //         .then((result) => {
  //           if (result.status === "success") {
  //             if (result.data?.length !== 0) {
  //               setDealerData(result.data);
  //               setTotalPaginationChat(result.pages);
  //               setLoadingPaginationChats(false);
  //               // setManagers(result.managerInfo)
  //               if (result.data.messages?.length !== 0) {
  //                 setMergeMessage((prevData) =>
  //                   paginationChat === 1
  //                     ? result.data.messages
  //                     : [...prevData, ...result.data.messages]
  //                 );
  //               }
  //             }
  //           }
  //         })
  //         .catch((error) => console.error(error))
  //         .finally(() => setLoadingPaginationChats(false));
  //     }
  //   };
  //   fetchChat();
  // }, [paginationChat, managerId, mobile]);

  // Function to check if user has scrolled to the bottom
  // const handlePaginationChat = () => {
  //     if (
  //         // containerChatRef.current.scrollTop + containerChatRef.current.clientHeight >=
  //         // containerChatRef.current.scrollHeight &&
  //         // paginationChat < totalPaginationChat
  //         chatContainerRef.current.scrollTop === 0 &&
  //         paginationChat < totalPaginationChat
  //     ) {
  //         // User has scrolled to the bottom, load more data
  //         setPaginationChat(prevPage => prevPage + 1);
  //     }
  // };

  const fetchChat = () => {
    // if (mobile && paginationChat !== 1) {
    if (mobile && paginationChat >= 1) {
      setLoadingPaginationChats(true);
      const myHeaders = new Headers();
      myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({
        whatsapp: parseInt(mobile),
        managerId: managerId,
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        `${process.env.REACT_APP_URL}wallikonChat/chats?page=${paginationChat}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.status === "success") {
            if (result.data?.length !== 0) {
              setDealerData(result.data);
              setTotalPaginationChat(result.pages);
              setLoadingPaginationChats(false);
              // setManagers(result.managerInfo)
              if (result.data.messages?.length !== 0) {
                setMergeMessage((prevData) =>
                  paginationChat === 1
                    ? result.data.messages
                    : [...prevData, ...result.data.messages]
                );
              }
              // if (result.data[0].dealerMessage?.length !== 0 && !result.data[0].adminMessage) {
              //     setMergeMessage(prevData => paginationChat === 1 ? result.data[0].dealerMessage : [...prevData, ...result.data[0].dealerMessage])
              // } else if (!result.data[0].dealerMessage && result.data[0].adminMessage?.length !== 0) {
              //     setMergeMessage(prevData => paginationChat === 1 ? result.data[0].adminMessage : [...prevData, ...result.data[0].adminMessage])
              // } else {
              //     setMergeMessage(prevData => paginationChat === 1 ? [...result.data[0].dealerMessage, ...result.data[0].adminMessage] : [...prevData, ...result.data[0].dealerMessage, ...result.data[0].adminMessage])
              // }
            }
          }
        })
        .catch((error) => console.error(error))
        .finally(() => setLoadingPaginationChats(false));
    }
  };

  useEffect(() => {
    fetchChat();
  }, [paginationChat, managerId, mobile]);

  const convertToCorrectTime = (dateTime) => {
    const date = new Date(dateTime);
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const meridiem = hours >= 12 ? "pm" : "am";
    hours = hours % 12 || 12;
    const formattedTime = `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")} ${meridiem}`;

    return formattedTime;
  };

  const renderDateIfChanged = (currentDate, previousDate) => {
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);

    const currentDateStr = currentDate && currentDate.toDateString();
    const previousDateStr = previousDate && previousDate.toDateString();

    if (currentDateStr !== previousDateStr) {
      if (currentDateStr === today.toDateString()) {
        return "Today";
      } else if (currentDateStr === yesterday.toDateString()) {
        return "Yesterday";
      } else {
        // Format the date as day Month year
        const day = currentDate.getDate();
        const monthIndex = currentDate.getMonth();
        const year = currentDate.getFullYear();

        const monthNames = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];

        const monthName = monthNames[monthIndex];

        return `${day} ${monthName} ${year}`;
      }
    }

    return null;
  };

  let previousDate = null;

  useEffect(() => {
    // Sort chats based on timestamp
    const sortedChats = mergeMessage.sort((a, b) => {
      const dateA = new Date(a.dateTime);
      const dateB = new Date(b.dateTime);
      return dateA - dateB;
    });

    setChats(sortedChats);
  }, [mergeMessage]);

  const [selectedManager, setSelectedManager] = useState([]);
  const handleManagerClick = (managerId, managerName) => {
    if (selectedManager.some((manager) => manager.managerId === managerId)) {
      setSelectedManager(
        selectedManager.filter((manager) => manager.managerId !== managerId)
      );
    } else {
      setSelectedManager([...selectedManager, { managerId, managerName }]);
    }
  };

  const newSelectedManagerName = selectedManager.map((manager) => ({
    managerName: manager.managerName,
  }));
  // const newSelectedManagerId = selectedManager.map(manager =>  manager.managerId);

  const newSelectedManagerId = useMemo(() => {
    return selectedManager.map((manager) => manager.managerId);
  }, [selectedManager]);

  const handleSelectAllChats = () => {
    setLoadingPagination(true);
    const myHeaders = new Headers();
    myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      keyword: keyword,
      managerId: managerId,
      sortKey: readMessage,
      day: day,
      ordermanagerId: newSelectedManagerId,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_URL + "wallikonChat/dealerAllChat",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "success") {
          setData(result.data);
          setTotalPageChat(result.pages);
          setTotalUnreadChat(result.totalUnreadChat);
          setLoadingPagination(false);
          setSelectedItems(
            result.data.map((item) => ({ whatsapp: item.whatsapp }))
          );
        }
      })
      .catch((error) => console.error(error))
      .finally(() => setLoadingPagination(false));
  };

  const dealerAllChat = async () => {
    try {
      setLoadingPagination(true);
      const myHeaders = new Headers();
      myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({
        keyword: keyword,
        managerId: managerId,
        sortKey: readMessage,
        day: day,
        ordermanagerId: newSelectedManagerId,
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        process.env.REACT_APP_URL + "wallikonChat/dealerAllChat?page=1",
        requestOptions
      );

      const result = await res.json();
      if (result.status === "success") {
        setData(result.data);
        setTotalPageChat(result.pages);
        setTotalUnreadChat(result.totalUnreadChat);
        setLoadingPagination(false);
      } else if (result.status === "failed") {
        setData([]);
        setTotalPageChat([]);
        setTotalUnreadChat([]);

        console.log(result.status);

        setLoadingPagination(false);
      }
    } catch (error) {
      console.log(error);
      setLoadingPagination(false);
    } finally {
      setLoadingPagination(false);
    }
  };

  useEffect(() => {
    // if (managerId?.length !== 0) {
    dealerAllChat();
    // }
  }, [keyword, managerId, readMessage, day, newSelectedManagerId]);

  // useEffect(() => {
  //   const socket = io(process.env.REACT_APP_URL);
  //   // Listen for allChats event
  //   socket.on("updateAdminChats", (allAdminChats) => {
  //     // console.log('allAdminChats', typeof allAdminChats.whatsapp);
  //     // setAllAdminChats([...allAdminChats.message]);
  //     // if (allAdminChats.whatsapp.toString() === mobile) {
  //     //   playSoundSendMessage();
  //     //   dealerAllChat()
  //     // }

  //     if (allAdminChats) {
  //       singleDealerChat();
  //       dealerAllChat();

  //       // console.log('all admin chat data from socket ===========>',allAdminChats);
  //     }
  //   });
  //   socket.on("updateDealerChats", (allDealerChats) => {
  //     // console.log("allDealerChats", allDealerChats);
  //     // if (allDealerChats && allDealerChats.message) {
  //     //   setAllDealerChats([...allDealerChats.message]);
  //     //   if (allDealerChats.whatsapp.toString() === mobile) {
  //     //     dealerAllChat()
  //     //     // alert('DealerChats')
  //     //     // setData(allDealerChats)
  //     //   }
  //     // }

  //     if (allDealerChats) {
  //       singleDealerChat();
  //       dealerAllChat();

  //       console.log('Socket Data',allDealerChats);

  //       if(allDealerChats?.whatsapp === mobile){
  //         handleMarkRead(mobile)
  //       }
  //        console.log('all dealer chat data from socket ===========>',allDealerChats);
  //     }
  //   });
  // }, [mobile, reload, managerId, allAdminChats.length, allDealerChats.length]);

  // useEffect(() => {
  //   if (managerId?.length !== 0) {
  //     const socket = io(SOCKET_SERVER_URL);
  //     const socketHerokuapp = io(SOCKET_HEROKUAPP_URL);

  //     // Listen for allChats event
  //     socket.on("updateAdminChats", (allAdminChats) => {
  //       // console.log('allAdminChats', allAdminChats.message);
  //       setAllAdminChats([...allAdminChats.message]);
  //       // playSoundNotification()
  //     });
  //     socket.on("updateDealerChats", (allDealerChats) => {
  //       // console.log('allDealerChats', allDealerChats.message);
  //       setAllDealerChats([...allDealerChats.message]);
  //       playSoundNotification();
  //     });
  //     socketHerokuapp.on("updateDealerChats", (allHerokuChats) => {
  //       // console.log('allHerokuChats', allHerokuChats.message);
  //       if (allHerokuChats && allHerokuChats.message) {
  //         setAllDealerChats([...allHerokuChats.message]);
  //         playSoundNotification();
  //       }
  //     });
  //     setLoadingPagination(true);
  //     const myHeaders = new Headers();
  //     myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
  //     myHeaders.append("Content-Type", "application/json");

  //     const raw = JSON.stringify({
  //       keyword: keyword,
  //       managerId: managerId,
  //       sortKey: readMessage,
  //       day: day,
  //       ordermanagerId: newSelectedManagerId,
  //     });

  //     const requestOptions = {
  //       method: "POST",
  //       headers: myHeaders,
  //       body: raw,
  //       redirect: "follow",
  //     };

  //     fetch(
  //       process.env.REACT_APP_URL + "wallikonChat/dealerAllChat?page=1",
  //       requestOptions
  //     )
  //       .then((response) => response.json())
  //       .then((result) => {
  //         if (result.status === "success") {
  //           setData(result.data);
  //           setTotalPageChat(result.pages);
  //           setTotalUnreadChat(result.totalUnreadChat);
  //           setLoadingPagination(false);
  //         }
  //       })
  //       .catch((error) => console.error(error))
  //       .finally(() => setLoadingPagination(false));
  //     return () => {
  //       socket.disconnect();
  //       socketHerokuapp.disconnect();
  //     };
  //   }
  // }, [
  //   reloadDealer,
  //   keyword,
  //   setTotalUnreadChat,
  //   managerId,
  //   readMessage,
  //   day,
  //   newSelectedManagerId,
  //   allAdminChats.length,
  //   allDealerChats.length,
  // ]);

  // useEffect(() => {
  //   const fetchDataChat = () => {
  //     if (pageChat !== 1) {
  //       setLoadingPagination(true);
  //       const myHeaders = new Headers();
  //       myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
  //       myHeaders.append("Content-Type", "application/json");

  //       const raw = JSON.stringify({
  //         keyword: keyword,
  //         managerId: managerId,
  //         sortKey: readMessage,
  //         day: day,
  //         ordermanagerId: newSelectedManagerId,
  //       });

  //       const requestOptions = {
  //         method: "POST",
  //         headers: myHeaders,
  //         body: raw,
  //         redirect: "follow",
  //       };

  //       fetch(
  //         `${process.env.REACT_APP_URL}wallikonChat/dealerAllChat?page=${pageChat}`,
  //         requestOptions
  //       )
  //         .then((response) => response.json())
  //         .then((result) => {
  //           if (result.status === "success") {
  //             setData((prevData) =>
  //               pageChat === 1 ? result.data : [...prevData, ...result.data]
  //             );
  //             // setTotalUnreadChat(result.totalUnreadChat);
  //             setTotalPageChat(result.pages);
  //             setLoadingPagination(false);
  //           }
  //         })
  //         .catch((error) => console.error(error))
  //         .finally(() => setLoadingPagination(false));
  //     }
  //   };
  //   fetchDataChat();
  // }, [
  //   pageChat,
  //   keyword,
  //   managerId,
  //   readMessage,
  //   day,
  //   newSelectedManagerId,
  //   totalPageChat,
  // ]);

  // Function to check if user has scrolled to the bottom

  useEffect(() => {
    const socket = io(process.env.REACT_APP_URL);

    // Listen for updateAdminChats event
    socket.on("updateAdminChats", (allAdminChats) => {
      if (allAdminChats) {
        singleDealerChat();
        dealerAllChat();
      }
    });

    // Listen for updateDealerChats event
    socket.on("updateDealerChats", (allDealerChats) => {
      console.log("Socket Data", allDealerChats);

      if (allDealerChats) {
        singleDealerChat();
        dealerAllChat();

        // Check if the message matches the current user's mobile number
        if (allDealerChats?.whatsapp?.toString() === mobile?.toString()) {
          console.log("Dealer message received for mobile:", mobile);

          if (designation && designation === "ordermanager") {
            handleMarkRead(mobile);
          }
        } else {
          console.log("No match for mobile:", mobile);
        }

        console.log("All dealer chat data from socket:", allDealerChats);
      }
    });

    return () => {
      // Cleanup the socket listener on component unmount
      socket.off("updateAdminChats");
      socket.off("updateDealerChats");
    };
  }, [mobile, reload, managerId, allAdminChats.length, allDealerChats.length]);

  const handleScrollChat = () => {
    if (
      containerRefwhatsapp.current.scrollTop +
        containerRefwhatsapp.current.clientHeight >=
        containerRefwhatsapp.current.scrollHeight &&
      pageChat < totalPageChat
    ) {
      // User has scrolled to the bottom, load more data
      setPageChat((prevPage) => prevPage + 1);
    }
  };

  useEffect(() => {
    if (managerId && (managersOptions || selectManagerChat)) {
      const myHeaders = new Headers();
      myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({
        managerId: managerId,
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        process.env.REACT_APP_URL + "wallikonChat/orderManagers",
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.status === "success") {
            setSelectManagerData(result.managerInfo);
          }
        })
        .catch((error) => console.error(error));
    }
  }, [managerId, managersOptions, selectManagerChat]);

  useEffect(() => {
    const statusRead = () => {
      setReloadDealer(true);
      const myHeaders = new Headers();
      myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({
        // "whatsapp": parseInt(mobile),
        whatsapp: [mobile],
        managerId: managerId,
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        process.env.REACT_APP_URL + "wallikonChat/statusRead",
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.status === "success") {
            setReloadDealer(false);
          }
        })
        .catch((error) => console.error(error));
    };

    if (dealerData.whatsapp_id && designation !== "superadmin") {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        "Bearer EABljmRig1VcBO9kZCqpF22rK9agSLkczn0HjJ52IiTdupNsFPjot7YV83mijy73Ej1xu4nEror2K5geGAP9W27AbgvWmUPKbVNVZC65zRabCuf3ZCVJcW9beMYotKihZBhrMy0WojNeqfFZAkyMFczmg8jXjgDBXLhl5watioYBy5lLPBmLHbrgVGRWnvMdpj0wnmZADWJVYSoWZBKz"
      );
      myHeaders.append("Cookie", "ps_l=0; ps_n=0");

      const raw = JSON.stringify({
        messaging_product: "whatsapp",
        status: "read",
        message_id: dealerData.whatsapp_id && dealerData.whatsapp_id,
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        "https://graph.facebook.com/v19.0/161149970425453/messages",
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.success === true) {
            statusRead();
          }
        })
        .catch((error) => console.error(error));
    }
  }, [dealerData.whatsapp_id, mobile, managerId, designation]);

  // const handleMarkRead = () => {
  //   setReloadDealer(true);
  //   const myHeaders = new Headers();
  //   myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
  //   myHeaders.append("Content-Type", "application/json");

  //   const raw = JSON.stringify({
  //     managerId: managerId,
  //     whatsapp:
  //       selectedItems?.length !== 0
  //         ? selectedItems.map((item) => item.whatsapp)
  //         : [],
  //   });

  //   const requestOptions = {
  //     method: "POST",
  //     headers: myHeaders,
  //     body: raw,
  //     redirect: "follow",
  //   };

  //   fetch(process.env.REACT_APP_URL + "wallikonChat/statusRead", requestOptions)
  //     .then((response) => response.json())
  //     .then((result) => {
  //       if (result.status === "success") {
  //         setShowMarkRead(false);
  //         setReloadDealer(false);
  //         setMarkRead(false);
  //         setSelectedItems([]);
  //         setSelectDealers(false);
  //       } else {
  //         alert(result.msg);
  //         setShowMarkRead(false);
  //       }
  //     })
  //     .catch((error) => console.error(error));
  // };

  const handleMarkRead = (mobile) => {
    setReloadDealer(true);
    const myHeaders = new Headers();
    myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      managerId: managerId,
      whatsapp: mobile
        ? [mobile]
        : selectedItems?.length !== 0
        ? selectedItems.map((item) => item.whatsapp)
        : [],
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(process.env.REACT_APP_URL + "wallikonChat/statusRead", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "success") {
          // alert(result.databaseUpdates.msg);
          setShowMarkRead(false);
          setReloadDealer(false);
          setMarkRead(false);
          setSelectedItems([]);
          setSelectDealers(false);
        } else {
          // alert(result.databaseUpdates.msg);
          setShowMarkRead(false);
          setReloadDealer(false);
          setMarkRead(false);
          setSelectedItems([]);
          setSelectDealers(false);
        }
      })
      .catch((error) => console.error(error));
  };

  const chatContainerRef = useRef(null);

  useEffect(() => {
    if (chatContainerRef.current && paginationChat === 1) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, [chats, paginationChat]);

  const handlePaginationChat = useCallback(() => {
    if (
      chatContainerRef.current &&
      chatContainerRef.current.scrollTop === 0 &&
      paginationChat < totalPaginationChat
    ) {
      setPaginationChat((prevPage) => prevPage + 1);
    }
  }, [paginationChat, totalPaginationChat]);

  useEffect(() => {
    const container = chatContainerRef.current;

    if (container) {
      container.addEventListener("scroll", handlePaginationChat);

      return () => {
        container.removeEventListener("scroll", handlePaginationChat);
      };
    }
  }, [handlePaginationChat, paginationChat, totalPaginationChat]);

  const [dragging, setDragging] = useState(false);
  const handleDragOver = (e) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setDragging(false);

    const droppedFiles = Array.from(e.dataTransfer.files);
    const fileExtensions = Array.from(e.dataTransfer.files).map((file) => {
      const fileName = file.name;
      const fileExtension = fileName.split(".").pop();
      return fileExtension;
    });
    // console.log('fileExtensions', fileExtensions[0] ); // This will log an array of file extensions
    if (
      fileExtensions[0] === "jpg" ||
      fileExtensions[0] === "jpeg" ||
      fileExtensions[0] === "png" ||
      fileExtensions[0] === "webp" ||
      fileExtensions[0] === "svg" ||
      fileExtensions[0] === "gif" ||
      fileExtensions[0] === "ai" ||
      fileExtensions[0] === "heic" ||
      fileExtensions[0] === "eps" ||
      fileExtensions[0] === "psd" ||
      fileExtensions[0] === "cdr" ||
      fileExtensions[0] === "mp4" ||
      fileExtensions[0] === "pdf" ||
      fileExtensions[0] === "mp3"
    ) {
      setFileExt(fileExtensions[0]);
      // console.log('fileExtensions', fileExtensions[0] ); // This will log an array of file extensions
      setFiles(droppedFiles);
      // setShowFileOptions(false)
    } else {
      alert("wrong file type please try again");
    }
  };

  // const handleFileChange = (e) => {
  //   const uploadedFiles = e.target.files;
  //   const fileExtensions = Array.from(uploadedFiles).map((file) => {
  //     const fileName = file.name;
  //     const fileExtension = fileName.split(".").pop();
  //     return fileExtension;
  //   });
  //   // console.log('fileExtensions', fileExtensions[0]); // This will log an array of file extensions
  //   if (
  //     (fileType === "image" &&
  //       (fileExtensions[0] === "jpg" ||
  //         fileExtensions[0] === "jpeg" ||
  //         fileExtensions[0] === "png" ||
  //         fileExtensions[0] === "webp" ||
  //         fileExtensions[0] === "svg" ||
  //         fileExtensions[0] === "gif" ||
  //         fileExtensions[0] === "ai" ||
  //         fileExtensions[0] === "heic" ||
  //         fileExtensions[0] === "eps" ||
  //         fileExtensions[0] === "psd" ||
  //         fileExtensions[0] === "cdr")) ||
  //     (fileType === "video" && fileExtensions[0] === "mp4") ||
  //     (fileType === "document" && fileExtensions[0] === "pdf") ||
  //     (fileType === "audio" && fileExtensions[0] === "mp3")
  //   ) {
  //     setFileExt(fileExtensions[0]);
  //     // console.log('fileExtensions', fileExtensions[0] ); // This will log an array of file extensions
  //     setFiles(uploadedFiles);
  //     setShowFileOptions(false);
  //   } else {
  //     alert("wrong file type please try again");
  //   }
  // };

  const handleFileChange = (e) => {
    const uploadedFiles = e.target.files;
    const fileSize = uploadedFiles[0] && uploadedFiles[0].size; // Size in bytes
    const fileExtensions = Array.from(uploadedFiles).map((file) => {
      const fileName = file.name;
      const fileExtension = fileName.split(".").pop().toLowerCase(); // Ensure case insensitivity
      return fileExtension;
    });

    const maxImageSize = 4 * 1024 * 1024; // 2MB in bytes
    const maxOtherSize = 16 * 1024 * 1024; // 16MB in bytes

    // Define allowed extensions for different file types
    const allowedImageExtensions = ["png", "jpeg", "jpg", "webp", "svg", "gif"];
    const allowedVideoExtensions = ["mp4", "mov", "webm", "mpg", "mpeg"];
    const allowedDocumentExtensions = ["pdf", "doc", "xls", "ppt", "txt"];
    const allowedAudioExtensions = ["mp3", "wav"];

    const isImage = allowedImageExtensions.includes(fileExtensions[0]);
    const isVideo = allowedVideoExtensions.includes(fileExtensions[0]);
    const isDocument = allowedDocumentExtensions.includes(fileExtensions[0]);
    const isAudio = allowedAudioExtensions.includes(fileExtensions[0]);

    if (
      (fileType === "image" && isImage && fileSize <= maxImageSize) ||
      (fileType === "video" && isVideo && fileSize <= maxOtherSize) ||
      (fileType === "document" && isDocument && fileSize <= maxOtherSize) ||
      (fileType === "audio" && isAudio && fileSize <= maxOtherSize)
    ) {
      setFileExt(fileExtensions[0]);
      setFiles(uploadedFiles);
      setShowFileOptions(false);
    } else {
      // Show different alerts for incorrect type or size
      if (!isImage && !isVideo && !isDocument && !isAudio) {
        alert("Wrong file type, please try again.");
      } else if (
        (fileType === "image" && fileSize > maxImageSize) ||
        fileSize > maxOtherSize
      ) {
        alert(
          `File size exceeds the limit. Images must be less than 4MB, and videos, audio, and documents must be less than 16MB.`
        );
      }
    }
  };

  const formatBytes = (bytes) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
  };

  const fileOptionsRef = useRef(null);

  function handleClickOutside(event) {
    if (
      fileOptionsRef.current &&
      !fileOptionsRef.current.contains(event.target)
    ) {
      setShowFileOptions();
    }
  }
  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const managerRef = useRef(null);
  const handleClickOutsideManager = (event) => {
    if (managerRef.current && !managerRef.current.contains(event.target)) {
      setManagersOptions();
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleClickOutsideManager);
    return () => {
      document.removeEventListener("click", handleClickOutsideManager);
    };
  }, []);

  const markReadRef = useRef(null);
  const handleClickOutsideReadMark = (event) => {
    if (markReadRef.current && !markReadRef.current.contains(event.target)) {
      setMarkRead();
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleClickOutsideReadMark);
    return () => {
      document.removeEventListener("click", handleClickOutsideReadMark);
    };
  }, []);

  const navigateToPage = (url) => {
    window.location.href = url;
  };

  // Function to handle individual item selection
  const toggleSelectItem = (whatsapp) => {
    const isSelected = selectedItems.some((item) => item.whatsapp === whatsapp);
    if (isSelected) {
      setSelectedItems((prevSelected) =>
        prevSelected.filter((item) => !(item.whatsapp === whatsapp))
      );
    } else {
      setSelectedItems((prevSelected) => [...prevSelected, { whatsapp }]);
    }
  };

  // const toggleSelectAll = () => {
  //     if (selectAll) {
  //         setSelectedItems([]);
  //     } else {
  //         setSelectedItems(data.map(item => ({ whatsapp: item.whatsapp })));
  //     }
  //     setSelectAll(prevSelectAll => !prevSelectAll);
  // };

  // const toggleSelectAllDealers = () => {
  //     if (selectAllNew) {
  //         setSelectedItems([]);
  //     } else {
  //         setSelectedItems(allDealers.map(item => ({ whatsapp: item.whatsapp })));
  //     }
  //     setSelectAllNew(prevSelectAll => !prevSelectAll);
  // };

  // const sendSelectMessage = async () => {
  //   if (fileType === "link" && message) {
  //     const myHeaders = new Headers();
  //     myHeaders.append("Content-Type", "application/json");
  //     myHeaders.append(
  //       "Authorization",
  //       "Bearer EABljmRig1VcBO9kZCqpF22rK9agSLkczn0HjJ52IiTdupNsFPjot7YV83mijy73Ej1xu4nEror2K5geGAP9W27AbgvWmUPKbVNVZC65zRabCuf3ZCVJcW9beMYotKihZBhrMy0WojNeqfFZAkyMFczmg8jXjgDBXLhl5watioYBy5lLPBmLHbrgVGRWnvMdpj0wnmZADWJVYSoWZBKz"
  //     );
  //     myHeaders.append("Cookie", "ps_l=1; ps_n=1");

  //     const templatePayload = {
  //       messaging_product: "whatsapp",
  //       to: mobile,
  //       text: {
  //         preview_url: true,
  //         body: "Please visit my website " + message,
  //       },
  //     };

  //     // const raw = JSON.stringify({
  //     //     "messaging_product": "whatsapp",
  //     //     "to": mobile,
  //     //     "text": {
  //     //         "preview_url": true,
  //     //         "body": "Please visit my website " + message
  //     //     }
  //     // });
  //     for (const number of selectedItems) {
  //       const requestOptions = {
  //         method: "POST",
  //         headers: myHeaders,
  //         body: JSON.stringify({
  //           ...templatePayload,
  //           to: number.whatsapp,
  //         }),
  //         redirect: "follow",
  //       };

  //       try {
  //         const response = await fetch(
  //           "https://graph.facebook.com/v19.0/161149970425453/messages",
  //           requestOptions
  //         );
  //         const result = await response.json();

  //         if (result.messages && result.messages.length > 0) {
  //           setMessage("");
  //           const myHeaders = new Headers();
  //           myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);

  //           const formdata = new FormData();
  //           formdata.append("adminChat", "Please visit my website " + message);

  //           const requestOptions = {
  //             method: "POST",
  //             headers: myHeaders,
  //             body: formdata,
  //             redirect: "follow",
  //           };

  //           fetch(
  //             process.env.REACT_APP_URL +
  //               "wallikonChat/metaChat?whatsapp=" +
  //               number.whatsapp +
  //               "&managerId=" +
  //               managerId,
  //             requestOptions
  //           )
  //             .then((response) => response.json())
  //             .then((result) => {
  //               if (result.status === "success") {
  //                 setReload(true);
  //                 setSelectAllDealers();
  //                 setSelectDealers();
  //                 setSelectedItems([]);
  //                 setFileType();
  //               }
  //             })
  //             .catch((error) => console.error(error));
  //           //   const waId = result.contacts[0].wa_id;
  //           //   newWaIds.push(waId);

  //           // Find the corresponding dealerNumber object
  //           //   const matchingDealerNumber = dealerNumber.find(dealer => dealer.whatsapp === number.whatsapp);

  //           // If a match is found, store the name in newDealerNames array
  //           //   if (matchingDealerNumber) {
  //           //     newDealerNames.push(matchingDealerNumber.name);

  //           //     // Update the state with the newDealerNames array
  //           //     setDealerNames(prevDealerNames => [...prevDealerNames, matchingDealerNumber.name]);
  //           //   }
  //         }
  //       } catch (error) {
  //         console.log("error", error);
  //       }
  //     }
  //   } else {
  //     const myHeaders = new Headers();
  //     myHeaders.append("Content-Type", "application/json");
  //     myHeaders.append(
  //       "Authorization",
  //       "Bearer EABljmRig1VcBO9kZCqpF22rK9agSLkczn0HjJ52IiTdupNsFPjot7YV83mijy73Ej1xu4nEror2K5geGAP9W27AbgvWmUPKbVNVZC65zRabCuf3ZCVJcW9beMYotKihZBhrMy0WojNeqfFZAkyMFczmg8jXjgDBXLhl5watioYBy5lLPBmLHbrgVGRWnvMdpj0wnmZADWJVYSoWZBKz"
  //     );
  //     // const newWaIds = []
  //     const templatePayload = {
  //       messaging_product: "whatsapp",
  //       to: mobile,
  //       text: {
  //         body: message,
  //       },
  //     };
  //     // const newDealerNames = [];
  //     for (const number of selectedItems) {
  //       const requestOptions = {
  //         method: "POST",
  //         headers: myHeaders,
  //         body: JSON.stringify({
  //           ...templatePayload,
  //           to: number.whatsapp,
  //         }),
  //         redirect: "follow",
  //       };

  //       try {
  //         const response = await fetch(
  //           "https://graph.facebook.com/v19.0/161149970425453/messages",
  //           requestOptions
  //         );
  //         const result = await response.json();

  //         if (result.messages && result.messages.length > 0) {
  //           setMessage("");
  //           const myHeaders = new Headers();
  //           myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);

  //           const formdata = new FormData();
  //           formdata.append("adminChat", message);

  //           const requestOptions = {
  //             method: "POST",
  //             headers: myHeaders,
  //             body: formdata,
  //             redirect: "follow",
  //           };

  //           fetch(
  //             process.env.REACT_APP_URL +
  //               "wallikonChat/metaChat?whatsapp=" +
  //               number.whatsapp +
  //               "&managerId=" +
  //               managerId,
  //             requestOptions
  //           )
  //             .then((response) => response.json())
  //             .then((result) => {
  //               if (result.status === "success") {
  //                 setReload(true);
  //                 setSelectAllDealers();
  //                 setSelectDealers();
  //                 setSelectedItems([]);
  //               }
  //             })
  //             .catch((error) => console.error(error));
  //           //   const waId = result.contacts[0].wa_id;
  //           //   newWaIds.push(waId);

  //           // Find the corresponding dealerNumber object
  //           //   const matchingDealerNumber = dealerNumber.find(dealer => dealer.whatsapp === number.whatsapp);

  //           // If a match is found, store the name in newDealerNames array
  //           //   if (matchingDealerNumber) {
  //           //     newDealerNames.push(matchingDealerNumber.name);

  //           //     // Update the state with the newDealerNames array
  //           //     setDealerNames(prevDealerNames => [...prevDealerNames, matchingDealerNumber.name]);
  //           //   }
  //         }
  //       } catch (error) {
  //         console.log("error", error);
  //       }
  //     }
  //     // Here, you can still set the state with the final array if needed
  //     // setDealerNames(newDealerNames);
  //   }
  // };

  // const sendMedia = () => {
  //   if (files && files.length !== 0) {
  //     setUploadLoading(true);
  //     const myHeaders = new Headers();
  //     myHeaders.append(
  //       "Authorization",
  //       "Bearer EABljmRig1VcBO9kZCqpF22rK9agSLkczn0HjJ52IiTdupNsFPjot7YV83mijy73Ej1xu4nEror2K5geGAP9W27AbgvWmUPKbVNVZC65zRabCuf3ZCVJcW9beMYotKihZBhrMy0WojNeqfFZAkyMFczmg8jXjgDBXLhl5watioYBy5lLPBmLHbrgVGRWnvMdpj0wnmZADWJVYSoWZBKz"
  //     );
  //     myHeaders.append("Cookie", "ps_l=1; ps_n=1");

  //     const formdata = new FormData();
  //     formdata.append("messaging_product", "whatsapp");
  //     formdata.append("file", files[0]);

  //     const requestOptions = {
  //       method: "POST",
  //       headers: myHeaders,
  //       body: formdata,
  //       redirect: "follow",
  //     };

  //     fetch(
  //       "https://graph.facebook.com/v19.0/161149970425453/media",
  //       requestOptions
  //     )
  //       .then((response) => response.json())
  //       .then((result) => {
  //         if (result.id) {
  //           sendMediaFiles(result.id);
  //           // setFileId(result.id)
  //           // sendMediaFiles()
  //           // setFiles([])
  //           // handleSendFile()
  //         }
  //       })
  //       .catch((error) => console.error(error));
  //   }
  // };

  // const sendMediaFiles = async (fileId) => {
  //   if (fileId && files[0]) {
  //     if (
  //       fileType === "image" ||
  //       fileExt === "jpg" ||
  //       fileExt === "jpeg" ||
  //       fileExt === "png" ||
  //       fileExt === "webp" ||
  //       fileExt === "svg" ||
  //       fileExt === "gif" ||
  //       fileExt === "ai" ||
  //       fileExt === "heic" ||
  //       fileExt === "eps" ||
  //       fileExt === "psd" ||
  //       fileExt === "cdr"
  //     ) {
  //       const myHeaders = new Headers();
  //       myHeaders.append("Content-Type", "application/json");
  //       myHeaders.append(
  //         "Authorization",
  //         "Bearer EABljmRig1VcBO9kZCqpF22rK9agSLkczn0HjJ52IiTdupNsFPjot7YV83mijy73Ej1xu4nEror2K5geGAP9W27AbgvWmUPKbVNVZC65zRabCuf3ZCVJcW9beMYotKihZBhrMy0WojNeqfFZAkyMFczmg8jXjgDBXLhl5watioYBy5lLPBmLHbrgVGRWnvMdpj0wnmZADWJVYSoWZBKz"
  //       );
  //       myHeaders.append("Cookie", "ps_l=1; ps_n=1");

  //       const templatePayload = {
  //         messaging_product: "whatsapp",
  //         recipient_type: "individual",
  //         to: mobile,
  //         type:
  //           fileExt === "jpg" ||
  //           fileExt === "jpeg" ||
  //           fileExt === "png" ||
  //           fileExt === "webp" ||
  //           fileExt === "svg" ||
  //           fileExt === "gif" ||
  //           fileExt === "ai" ||
  //           fileExt === "heic" ||
  //           fileExt === "eps" ||
  //           fileExt === "psd" ||
  //           fileExt === "cdr"
  //             ? "image"
  //             : fileType,
  //         image: {
  //           id: fileId,
  //           caption: caption ? caption : "",
  //         },
  //       };

  //       for (const number of selectedItems) {
  //         const requestOptions = {
  //           method: "POST",
  //           headers: myHeaders,
  //           body: JSON.stringify({
  //             ...templatePayload,
  //             to: number.whatsapp,
  //           }),
  //           redirect: "follow",
  //         };

  //         try {
  //           const response = await fetch(
  //             "https://graph.facebook.com/v19.0/161149970425453/messages",
  //             requestOptions
  //           );
  //           const result = await response.json();

  //           if (result.messages[0].id) {
  //             const myHeaders = new Headers();
  //             myHeaders.append(
  //               "wa-platform",
  //               process.env.REACT_APP_ADMIN_SECRET
  //             );

  //             const formdata = new FormData();
  //             formdata.append("file", files[0]);

  //             // const formdata = new FormData();
  //             // formdata.append("file", files[0]);

  //             const requestOptions = {
  //               method: "POST",
  //               headers: myHeaders,
  //               body: formdata,
  //               redirect: "follow",
  //             };
  //             fetch(
  //               process.env.REACT_APP_URL +
  //                 "wallikonChat/metaChat?whatsapp=" +
  //                 number.whatsapp +
  //                 "&managerId=" +
  //                 managerId,
  //               requestOptions
  //             )
  //               .then((response) => response.json())
  //               .then((result) => {
  //                 if (result.status === "success") {
  //                   setFiles([]);
  //                   setSelectedItems([]);
  //                   setUploadLoading(false);
  //                 }
  //               })
  //               .catch((error) => console.error(error));
  //             //   const waId = result.contacts[0].wa_id;
  //             //   newWaIds.push(waId);

  //             // Find the corresponding dealerNumber object
  //             //   const matchingDealerNumber = dealerNumber.find(dealer => dealer.whatsapp === number.whatsapp);

  //             // If a match is found, store the name in newDealerNames array
  //             //   if (matchingDealerNumber) {
  //             //     newDealerNames.push(matchingDealerNumber.name);

  //             //     // Update the state with the newDealerNames array
  //             //     setDealerNames(prevDealerNames => [...prevDealerNames, matchingDealerNumber.name]);
  //             //   }
  //           }
  //         } catch (error) {
  //           console.log("error", error);
  //         }
  //       }
  //     } else if (fileType === "document" || fileExt === "pdf") {
  //       const myHeaders = new Headers();
  //       myHeaders.append("Content-Type", "application/json");
  //       myHeaders.append(
  //         "Authorization",
  //         "Bearer EABljmRig1VcBO9kZCqpF22rK9agSLkczn0HjJ52IiTdupNsFPjot7YV83mijy73Ej1xu4nEror2K5geGAP9W27AbgvWmUPKbVNVZC65zRabCuf3ZCVJcW9beMYotKihZBhrMy0WojNeqfFZAkyMFczmg8jXjgDBXLhl5watioYBy5lLPBmLHbrgVGRWnvMdpj0wnmZADWJVYSoWZBKz"
  //       );
  //       myHeaders.append("Cookie", "ps_l=1; ps_n=1");

  //       const templatePayload = {
  //         messaging_product: "whatsapp",
  //         recipient_type: "individual",
  //         to: mobile,
  //         type: fileExt === "pdf" ? "document" : fileType,
  //         document: {
  //           id: fileId,
  //           caption: caption ? caption : "",
  //           filename: files[0].name && files[0].name,
  //         },
  //       };

  //       // const raw = JSON.stringify({
  //       //     "messaging_product": "whatsapp",
  //       //     "recipient_type": "individual",
  //       //     "to": mobile,
  //       //     "type": fileType,
  //       //     "document": {
  //       //         "id": fileId,
  //       //         "caption": caption ? caption : "",
  //       //         "filename": files[0].name && files[0].name
  //       //     }
  //       // });

  //       for (const number of selectedItems) {
  //         const requestOptions = {
  //           method: "POST",
  //           headers: myHeaders,
  //           body: JSON.stringify({
  //             ...templatePayload,
  //             to: number.whatsapp,
  //           }),
  //           redirect: "follow",
  //         };

  //         try {
  //           const response = await fetch(
  //             "https://graph.facebook.com/v19.0/161149970425453/messages",
  //             requestOptions
  //           );
  //           const result = await response.json();

  //           if (result.messages[0].id) {
  //             const myHeaders = new Headers();
  //             myHeaders.append(
  //               "wa-platform",
  //               process.env.REACT_APP_ADMIN_SECRET
  //             );

  //             const formdata = new FormData();
  //             formdata.append("file", files[0]);

  //             const requestOptions = {
  //               method: "POST",
  //               headers: myHeaders,
  //               body: formdata,
  //               redirect: "follow",
  //             };
  //             fetch(
  //               process.env.REACT_APP_URL +
  //                 "wallikonChat/metaChat?whatsapp=" +
  //                 number.whatsapp +
  //                 "&managerId=" +
  //                 managerId,
  //               requestOptions
  //             )
  //               .then((response) => response.json())
  //               .then((result) => {
  //                 if (result.status === "success") {
  //                   setFiles([]);
  //                   setSelectedItems([]);
  //                   setUploadLoading(false);
  //                 }
  //               })
  //               .catch((error) => console.error(error));
  //           }
  //         } catch (error) {
  //           console.log("error", error);
  //         }
  //       }
  //     } else if (fileType === "video" || fileExt === "mp4") {
  //       const myHeaders = new Headers();
  //       myHeaders.append("Content-Type", "application/json");
  //       myHeaders.append(
  //         "Authorization",
  //         "Bearer EABljmRig1VcBO9kZCqpF22rK9agSLkczn0HjJ52IiTdupNsFPjot7YV83mijy73Ej1xu4nEror2K5geGAP9W27AbgvWmUPKbVNVZC65zRabCuf3ZCVJcW9beMYotKihZBhrMy0WojNeqfFZAkyMFczmg8jXjgDBXLhl5watioYBy5lLPBmLHbrgVGRWnvMdpj0wnmZADWJVYSoWZBKz"
  //       );
  //       myHeaders.append("Cookie", "ps_l=1; ps_n=1");

  //       const templatePayload = {
  //         messaging_product: "whatsapp",
  //         recipient_type: "individual",
  //         to: mobile,
  //         type: fileExt === "mp4" ? "video" : fileType,
  //         video: {
  //           id: fileId,
  //           caption: caption ? caption : "",
  //         },
  //       };

  //       // const raw = JSON.stringify({
  //       //     "messaging_product": "whatsapp",
  //       //     "recipient_type": "individual",
  //       //     "to": mobile,
  //       //     "type": fileType,
  //       //     "video": {
  //       //         "id": fileId,
  //       //         "caption": caption ? caption : '',
  //       //     }
  //       // });

  //       for (const number of selectedItems) {
  //         const requestOptions = {
  //           method: "POST",
  //           headers: myHeaders,
  //           body: JSON.stringify({
  //             ...templatePayload,
  //             to: number.whatsapp,
  //           }),
  //           redirect: "follow",
  //         };

  //         try {
  //           const response = await fetch(
  //             "https://graph.facebook.com/v19.0/161149970425453/messages",
  //             requestOptions
  //           );
  //           const result = await response.json();

  //           if (result.messages[0].id) {
  //             const myHeaders = new Headers();
  //             myHeaders.append(
  //               "wa-platform",
  //               process.env.REACT_APP_ADMIN_SECRET
  //             );

  //             const formdata = new FormData();
  //             formdata.append("file", files[0]);

  //             const requestOptions = {
  //               method: "POST",
  //               headers: myHeaders,
  //               body: formdata,
  //               redirect: "follow",
  //             };
  //             fetch(
  //               process.env.REACT_APP_URL +
  //                 "wallikonChat/metaChat?whatsapp=" +
  //                 number.whatsapp +
  //                 "&managerId=" +
  //                 managerId,
  //               requestOptions
  //             )
  //               .then((response) => response.json())
  //               .then((result) => {
  //                 if (result.status === "success") {
  //                   setFiles([]);
  //                   setSelectedItems([]);
  //                   setUploadLoading(false);
  //                 }
  //               })
  //               .catch((error) => console.error(error));
  //           }
  //         } catch (error) {
  //           console.log("error", error);
  //         }
  //       }
  //     } else if (fileType === "audio" || fileExt === "mp3") {
  //       const myHeaders = new Headers();
  //       myHeaders.append("Content-Type", "application/json");
  //       myHeaders.append(
  //         "Authorization",
  //         "Bearer EABljmRig1VcBO9kZCqpF22rK9agSLkczn0HjJ52IiTdupNsFPjot7YV83mijy73Ej1xu4nEror2K5geGAP9W27AbgvWmUPKbVNVZC65zRabCuf3ZCVJcW9beMYotKihZBhrMy0WojNeqfFZAkyMFczmg8jXjgDBXLhl5watioYBy5lLPBmLHbrgVGRWnvMdpj0wnmZADWJVYSoWZBKz"
  //       );
  //       myHeaders.append("Cookie", "ps_l=1; ps_n=1");

  //       const templatePayload = {
  //         messaging_product: "whatsapp",
  //         recipient_type: "individual",
  //         to: mobile,
  //         type: fileExt === "mp3" ? "audio" : fileType,
  //         audio: {
  //           id: fileId,
  //         },
  //       };

  //       // const raw = JSON.stringify({
  //       //     "messaging_product": "whatsapp",
  //       //     "recipient_type": "individual",
  //       //     "to": mobile,
  //       //     "type": fileType,
  //       //     "audio": {
  //       //         "id": fileId
  //       //     }
  //       // });

  //       for (const number of selectedItems) {
  //         const requestOptions = {
  //           method: "POST",
  //           headers: myHeaders,
  //           body: JSON.stringify({
  //             ...templatePayload,
  //             to: number.whatsapp,
  //           }),
  //           redirect: "follow",
  //         };

  //         try {
  //           const response = await fetch(
  //             "https://graph.facebook.com/v19.0/161149970425453/messages",
  //             requestOptions
  //           );
  //           const result = await response.json();

  //           if (result.messages[0].id) {
  //             const myHeaders = new Headers();
  //             myHeaders.append(
  //               "wa-platform",
  //               process.env.REACT_APP_ADMIN_SECRET
  //             );

  //             const formdata = new FormData();
  //             formdata.append("file", files[0]);

  //             const requestOptions = {
  //               method: "POST",
  //               headers: myHeaders,
  //               body: formdata,
  //               redirect: "follow",
  //             };
  //             fetch(
  //               process.env.REACT_APP_URL +
  //                 "wallikonChat/metaChat?whatsapp=" +
  //                 number.whatsapp +
  //                 "&managerId=" +
  //                 managerId,
  //               requestOptions
  //             )
  //               .then((response) => response.json())
  //               .then((result) => {
  //                 if (result.status === "success") {
  //                   setFiles([]);
  //                   setSelectedItems([]);
  //                   setUploadLoading(false);
  //                 }
  //               })
  //               .catch((error) => console.error(error));
  //           }
  //         } catch (error) {
  //           console.log("error", error);
  //         }
  //       }
  //     } else {
  //       alert("wrong file type please try again");
  //     }
  //   }
  //   // Here, you can still set the state with the final array if needed
  //   // setDealerNames(newDealerNames);
  // };

  const handleAssignManager = () => {
    if (selectManager && !dealerData.managerName) {
      const myHeaders = new Headers();
      myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({
        managerId: managerId,
        whatsapp: mobile,
        selectedManagerId: selectManager,
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        process.env.REACT_APP_URL + "wallikonChat/assignManager",
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.status === "success") {
            alert(result.msg);
            setReload(true);
            setShowAssignManagerModal(false);
          }
        })
        .catch((error) => console.error(error))
        .finally(() => {
          setShowAssignManagerModal(false);
        });
    }
  };

  // useEffect(() => {
  //     if (selectManager && !dealerData.managerName) {
  //         const myHeaders = new Headers();
  //         myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
  //         myHeaders.append("Content-Type", "application/json");

  //         const raw = JSON.stringify({
  //             "managerId": managerId,
  //             "whatsapp": mobile,
  //             "selectedManagerId": selectManager
  //         });

  //         const requestOptions = {
  //             method: "POST",
  //             headers: myHeaders,
  //             body: raw,
  //             redirect: "follow"
  //         };

  //         fetch(process.env.REACT_APP_URL + "wallikonChat/assignManager", requestOptions)
  //             .then((response) => response.json())
  //             .then((result) => {
  //                 if (result.status === 'success') {
  //                     alert(result.msg)
  //                     setReload(true)
  //                 }
  //             })
  //             .catch((error) => console.error(error));
  //     }
  // }, [selectManager, mobile, managerId, dealerData.managerName])

  const handleSelectAllContacts = () => {
    const myHeaders = new Headers();
    myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      managerId: managerId,
      keyword: keywordDealers,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_URL + "wallikonChat/dealersWhatsapp",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "success") {
          setAllDealers(result.data);
          setTotalPage(result.pages);
          setSelectedItems(
            result.data.map((item) => ({ whatsapp: item.whatsapp }))
          );
        }
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    if (selectNewDealers) {
      const myHeaders = new Headers();
      myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({
        managerId: managerId,
        keyword: keywordDealers,
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        process.env.REACT_APP_URL + "wallikonChat/dealersWhatsapp?page=1",
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.status === "success") {
            setAllDealers(result.data);
            setTotalPage(result.pages);
          }
        })
        .catch((error) => console.error(error));
    }
  }, [managerId, keywordDealers, selectNewDealers]);

  useEffect(() => {
    const fetchData = () => {
      if (selectNewDealers && page !== 1) {
        setLoadingPaginationContact(true);
        const myHeaders = new Headers();
        myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
          managerId: managerId,
          keyword: keywordDealers,
        });

        const requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
        };

        fetch(
          process.env.REACT_APP_URL +
            "wallikonChat/dealersWhatsapp?page=" +
            page,
          requestOptions
        )
          .then((response) => response.json())
          .then((result) => {
            if (result.status === "success") {
              setAllDealers((prevData) =>
                page === 1 ? result.data : [...prevData, ...result.data]
              );
              setTotalPage(result.pages);
              setLoadingPaginationContact(false);
            }
          })
          .catch((error) => console.error(error))
          .finally(() => setLoadingPaginationContact(false));
      }
    };
    fetchData();
  }, [page, managerId, keywordDealers, selectNewDealers]); // Fetch data whenever page changes

  // Function to check if user has scrolled to the bottom
  const handleScroll = () => {
    if (
      containerRef.current.scrollTop + containerRef.current.clientHeight >=
        containerRef.current.scrollHeight &&
      page < totalPage
    ) {
      // User has scrolled to the bottom, load more data
      setPage((prevPage) => prevPage + 1);
    }
  };

  const [openFiles, setOpenFiles] = useState([]);

  const handleFileOpen = (file) => {
    if (!openFiles.includes(file)) {
      setOpenFiles([...openFiles, file]);
    }
  };

  const ensureProtocol = (url) => {
    if (!/^https?:\/\//i.test(url)) {
      return "https://" + url;
    }
    return url;
  };
  const [openFile, setOpenFile] = useState([]);

  const handleTemplateOpen = (file) => {
    if (!openFile.includes(file)) {
      setOpenFile([...openFile, file]);
    }
  };

  // console.log('pageChat', pageChat);

  return (
    <React.Fragment>
      <MobileWrapper>
        {mobile && managerId ? (
          <MobileOneToOneChat>
            {/* <div className={styles.whatsapp_chats_header}>
                            <div className='' onClick={() => { navigateToPage('/to') }}><BackArrowIcon color='#000' /></div>
                            {!dealerData.profileImage ?
                                <img
                                    src='/assets/whatsapp_profile_mob.png'
                                    alt='profile_Image'
                                    className={styles.whatsappMessage_image}
                                />
                                :
                                <img
                                    src={process.env.REACT_APP_S3URL + dealerData.profileImage}
                                    className={styles.whatsappMessage_image}
                                    alt='dealer_image'
                                />
                            }
                            <div className='w-100 ms-1'>
                                <div className='w-100 d-flex justify-content-between'>
                                    <div className='d-flex flex-column'>
                                        <div className={styles.header_storeName}> {dealerData.storeName ? dealerData.storeName : dealerData.whatsapp}</div>
                                        {dealerData.dealerName && <div className={styles.header_dealerName}> {dealerData.dealerName}{dealerData.dealerCity && <span>&nbsp;({dealerData.dealerCity})</span>}</div>}
                                      
                                    </div>

                                </div>
                                <div
                                    role='button'
                                    className={styles.header_mobileNo}
                                    onClick={() => navigate("/dealers/" + dealerData.whatsapp)}
                                >
                                    <u>{dealerData.whatsapp}</u>
                                </div>
                                {dealerData.dealerStatus && <span className={`${styles.header_status} ms-2 ${dealerData.dealerStatus === 'Pending' ? 'text-warning' : dealerData.dealerStatus === 'Rejected' ? 'text-danger' : dealerData.dealerStatus === 'Verified' ? 'text-success' : dealerData.dealerStatus === 'Update' ? 'text-success' : dealerData.dealerStatus === 'New' ? 'text-info' : ''}`}>{dealerData.dealerStatus}</span>}
                            </div>
                            {mobile &&
                                <div className={styles.managerName_div}>
                                    <label>Order Manager</label>
                                    {dealerData.managerName ?
                                        <div className={styles.selectManager}>{dealerData.managerName}</div>
                                        :
                                        <select className={styles.selectManager} value={selectManager} onChange={(e) => { setSelectManager(e.target.value); setShowAssignManagerModal(true) }} onClick={() => setSelectManagerChat(true)}>
                                            <option value={''}>Select Manager</option>
                                            {selectManagerData && selectManagerData.length !== 0 && selectManagerData.map((item, index) => <option key={index} value={item.managerId}>{item.name}</option>)}
                                        </select>}
                                </div>
                            }
                        </div> */}

            <MobileHeader dealerData={dealerData} mobile={mobile} />

            {files && files.length !== 0 ? (
              <>
                <div
                  ref={chatContainerRef}
                  className={`${
                    dragging ? styles.invoice_dragDrop : styles.chats_max_height
                  } 
               ${mode ? styles.dark : styles.lightMode}`}
                  onDragOver={handleDragOver}
                  onDragLeave={handleDragLeave}
                  onDrop={handleDrop}
                >
                  <div
                    className={`${styles.view_selectedFile_caption}  ${
                      mode ? styles.dark : styles.lightMode
                    }`}
                  >
                    <div
                      style={{
                        position: "absolute",
                        right: "20px",
                        top: "20px",
                      }}
                      // className={styles.whatsappImage_remove}
                      onClick={() => setFiles([])}
                    >
                      <Close />
                    </div>

                    <div className={styles.view_selectedFile}>
                      {fileExt === "mp3" ? (
                        <div className="d-flex justify-content-between align-items-center w-100">
                          <div
                            className={mode ? styles.darkMode : styles.light}
                            style={{
                              display: "flex",
                              height: "380px",
                              width: "300px",
                              padding: "10px",
                              justifyContent: "center",
                              alignItems: "center",
                              borderRadius: "10px",
                              objectFit: "cover",
                            }}
                          >
                            <audio controls>
                              <source
                                src={URL.createObjectURL(files[0])}
                                type="audio/mpeg"
                                className={styles.selected_whatsappImage}
                              />
                              Your browser does not support the audio element.
                            </audio>
                          </div>
                          {/* <div className={`${styles.fileName} pt-3`}>
                          <span>{files[0].name && files[0].name}</span>
                        </div> */}
                        </div>
                      ) : fileExt === "mp4" ? (
                        <div
                          className={mode ? styles.darkMode : styles.light}
                          style={{
                            display: "flex",
                            height: "380px",
                            width: "300px",
                            padding: "10px",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: "10px",
                            objectFit: "cover",
                          }}
                        >
                          <video
                            width={300}
                            controls
                            className={styles.selected_whatsappImage}
                          >
                            <source
                              src={URL.createObjectURL(files[0])}
                              type="video/mp4"
                            />
                            Your browser does not support HTML5 video.
                          </video>
                        </div>
                      ) : fileExt === "pdf" ? (
                        <div
                          className={mode ? styles.darkMode : styles.light}
                          style={{
                            display: "flex",
                            height: "380px",
                            width: "300px",
                            padding: "10px",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: "10px",
                            objectFit: "cover",
                          }}
                        >
                          {/* <iframe
                        className={styles.selected_whatsappImage}
                        title="PDF Viewer"
                        src={URL.createObjectURL(files[0])}
                        width="100%"
                        height="500px"
                        frameBorder="0"
                      /> */}

                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <DocumentShare
                              mode={mode}
                              height={100}
                              width={100}
                            />
                          </div>
                        </div>
                      ) : (
                        <div
                          className={mode ? styles.darkMode : styles.light}
                          style={{
                            display: "flex",
                            height: "380px",
                            width: "300px",
                            padding: "10px",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: "10px",
                            objectFit: "cover",
                          }}
                        >
                          <img
                            src={
                              files &&
                              files.length !== 0 &&
                              URL.createObjectURL(files[0])
                            }
                            alt="selected_Image"
                            className={styles.selected_whatsappImage}
                          />
                        </div>
                      )}
                    </div>
                    {fileExt === "mp3" ? (
                      <>
                        <div className="d-flex flex-column justify-centent-center align-item-center pt-3">
                          <div
                            className={styles.fileName}
                            style={{ color: mode ? "#fff" : "#000" }}
                          >
                            File Name :{" "}
                            <span>{files[0].name && files[0].name}</span>
                          </div>
                          <div
                            className={styles.fileName}
                            style={{ color: mode ? "#fff" : "#000" }}
                          >
                            <span>
                              File Size:{" "}
                              {formatBytes(files[0] && files[0].size)}
                            </span>
                          </div>
                        </div>
                      </>
                    ) : (
                      <div className="d-flex flex-column justify-centent-center align-item-center pt-3">
                        <div
                          className={styles.fileName}
                          style={{ color: mode ? "#fff" : "#000" }}
                        >
                          File Name :{" "}
                          <span>{files[0].name && files[0].name}</span>
                        </div>
                        <div
                          className={styles.fileName}
                          style={{ color: mode ? "#fff" : "#000" }}
                        >
                          <span>
                            File Size: {formatBytes(files[0] && files[0].size)}
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div
                  className={`${styles.whatsapp_input_div}  ${
                    mode ? styles.maindarkMode : styles.light
                  }`}
                >
                  <div className={styles.AddFile}>
                    <span className={styles.icon_css}>
                      <WhatsappEmojisIcon mode={mode} />
                    </span>
                    <span
                      onClick={(e) => {
                        e.stopPropagation();
                        if (showFileOptions === "files") {
                          setShowFileOptions();
                        } else {
                          setShowFileOptions("files");
                        }
                      }}
                    >
                      <AddFile mode={mode} />
                    </span>
                  </div>

                  {/* {files && files.length !== 0 ? (
                <div className={styles.view_selectedFile_caption}>
                  <div className={styles.view_selectedFile}>
                    <div
                      className={styles.whatsappImage_remove}
                      onClick={() => setFiles([])}
                    >
                      <Close />
                    </div>
                    {fileExt === "mp3" ? (
                      <div className="d-flex justify-content-between align-items-center w-100">
                        <audio controls>
                          <source
                            src={URL.createObjectURL(files[0])}
                            type="audio/mpeg"
                            className={styles.selected_whatsappImage}
                          />
                          Your browser does not support the audio element.
                        </audio>
                        <div className={`${styles.fileName} pt-3`}>
                          <span>{files[0].name && files[0].name}</span>
                        </div>
                      </div>
                    ) : fileExt === "mp4" ? (
                      <video
                        width="400"
                        controls
                        className={styles.selected_whatsappImage}
                      >
                        <source
                          src={URL.createObjectURL(files[0])}
                          type="video/mp4"
                        />
                        Your browser does not support HTML5 video.
                      </video>
                    ) : fileExt === "pdf" ? (
                      <iframe
                        className={styles.selected_whatsappImage}
                        title="PDF Viewer"
                        src={URL.createObjectURL(files[0])}
                        width="100%"
                        height="500px"
                        frameBorder="0"
                      />
                    ) : (
                      <img
                        src={
                          files &&
                          files.length !== 0 &&
                          URL.createObjectURL(files[0])
                        }
                        alt="selected_Image"
                        className={styles.selected_whatsappImage}
                      />
                    )}
                  </div>
                  {fileExt === "mp3" ? (
                    ""
                  ) : (
                    <div className="d-flex flex-column justify-centent-center align-item-center w-100">
                      <div className={styles.fileName}>
                        <span>{files[0].name && files[0].name}</span>
                      </div>
                      <input
                        type="text"
                        placeholder="Enter Caption"
                        className={styles.caption}
                        value={caption}
                        onChange={(e) => setCaption(e.target.value)}
                      />
                    </div>
                  )}
                </div>
              ) : (
                <input
                  placeholder={
                    fileType === "link"
                      ? "Type Link"
                      : fileType === "location"
                      ? "Type location"
                      : "Type Message"
                  }
                  type="text"
                  // autoFocus
                  className={`${styles.whatsapp_chats_input}  ${
                    mode ? styles.dark : styles.lightMode
                  }`}
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      if (selectDealers && files.length === 0) {
                        sendSelectMessage();
                      } else if (selectDealers && files.length !== 0) {
                        sendMedia();
                      } else {
                        sentMessage();
                      }
                    }
                  }}
                />
              )} */}

                  {/* <input
                  placeholder={
                    fileType === "link"
                      ? "Type Link"
                      : fileType === "location"
                      ? "Type location"
                      : "Type Message"
                  }
                  type="text"
                  // autoFocus
                  className={`${styles.whatsapp_chats_input}  ${
                    mode ? styles.dark : styles.lightMode
                  }`}
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      if (selectDealers && files.length === 0) {
                        sendSelectMessage();
                      } else if (selectDealers && files.length !== 0) {
                        sendMedia();
                      } else {
                        sentMessage();
                      }
                    }
                  }}
                /> */}

                  <input
                    className={`${styles.whatsapp_chats_input}  ${
                      mode ? styles.dark : styles.lightMode
                    }`}
                    type="text"
                    placeholder="Enter Caption"
                    // className={styles.caption}
                    value={caption}
                    onChange={(e) => setCaption(e.target.value)}
                  />

                  <div className="d-flex h-100vh justify-content-center align-items-center">
                    <button
                      type="button"
                      className={styles.SendMessage}
                      // onClick={() => {
                      //   if (selectDealers && files.length === 0) {
                      //     sendSelectMessage();
                      //   } else if (selectDealers && files.length !== 0) {
                      //     sendMedia();
                      //   } else {
                      //     sentMessage();
                      //   }
                      // }}
                      onClick={sentMessage}
                      disabled={uploadLoading ? true : false}
                    >
                      {uploadLoading ? (
                        <Spinner size="15" />
                      ) : (
                        <SendMessageIcon />
                      )}
                    </button>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div
                  ref={chatContainerRef}
                  className={`${
                    dragging ? styles.invoice_dragDrop : styles.chats_max_height
                  } 
                 ${mode ? styles.dark : styles.lightMode}`}
                  onDragOver={handleDragOver}
                  onDragLeave={handleDragLeave}
                  onDrop={handleDrop}
                >
                  {showFileOptions === "files" && (
                    <div
                      className={`${styles.showFileOptions} ${
                        mode ? styles.borderLight : styles.borderDark
                      }`}
                      ref={fileOptionsRef}
                    >
                      <label
                        style={{ color: mode ? "#fff" : "#000" }}
                        className={styles.uploadData_btn}
                        onClick={() => setFileType("image")}
                      >
                        <ImageShare mode={mode} />
                        &ensp; Image
                        <input
                          className="d-none"
                          type="file"
                          onChange={handleFileChange}
                        />
                      </label>
                      <label
                        style={{ color: mode ? "#fff" : "#000" }}
                        className={styles.uploadData_btn}
                        onClick={() => setFileType("video")}
                      >
                        <VideoShare mode={mode} />
                        &ensp; Video
                        <input
                          className="d-none"
                          type="file"
                          onChange={handleFileChange}
                        />
                      </label>
                      <label
                        style={{ color: mode ? "#fff" : "#000" }}
                        className={styles.uploadData_btn}
                        onClick={() => setFileType("document")}
                      >
                        <DocumentShare mode={mode} />
                        &ensp; Document
                        <input
                          className="d-none"
                          type="file"
                          onChange={handleFileChange}
                        />
                      </label>
                      <label
                        style={{ color: mode ? "#fff" : "#000" }}
                        className={styles.uploadData_btn}
                        onClick={() => setFileType("audio")}
                      >
                        <AudioShare mode={mode} />
                        &ensp; Audio
                        <input
                          className="d-none"
                          type="file"
                          onChange={handleFileChange}
                        />
                      </label>
                      <label
                        style={{ color: mode ? "#fff" : "#000" }}
                        className={styles.uploadData_btn}
                        onClick={() => {
                          setFileType("link");
                          setShowFileOptions(false);
                        }}
                      >
                        <LinkShare mode={mode} />
                        &ensp; Link
                      </label>
                      <label
                        style={{ color: mode ? "#fff" : "#000" }}
                        className={styles.uploadData_btn}
                        onClick={() => {
                          setFileType("location");
                          setShowFileOptions(false);
                        }}
                      >
                        <LocationShare mode={mode} />
                        &ensp; Location
                      </label>

                      <label
                        style={{ color: mode ? "#fff" : "#000" }}
                        className={styles.uploadData_btn}
                        onClick={() => {
                          setFileType("pdf");
                          setShowFileOptions(false);
                        }}
                      >
                        <PdfShare mode={mode} />
                        &ensp; PDF
                      </label>

                      <label
                        style={{ color: mode ? "#fff" : "#000" }}
                        className={styles.uploadData_btn}
                        onClick={() => {
                          setFileType("stock");
                          setShowFileOptions(false);
                        }}
                      >
                        <StockShare mode={mode} />
                        &ensp; Stock
                      </label>
                    </div>
                  )}

                  {/* {showFileOptions === "files" && (
                <div
                  className={`${styles.showFileOptions} ${
                    mode ? styles.borderLight : styles.borderDark
                  }`}
                  ref={fileOptionsRef}
                >
                  {fileOptions.map(({ type, label, Icon }) => (
                    <label
                      style={{ color: mode ? "#fff" : "#000" }}
                      key={type}
                      className={styles.uploadData_btn}
                      onClick={() => {
                        setFileType(type);
                        if (
                          type !== "link" &&
                          type !== "location" &&
                          type !== "pdf" &&
                          type !== "stock"
                        ) {
                          // For file input types, we might not want to close the options
                          setShowFileOptions(false);
                        }
                      }}
                    >
                      <Icon mode={mode} /> &ensp;
                      {label}
                      {type !== "link" &&
                        type !== "location" &&
                        type !== "pdf" &&
                        type !== "stock" && (
                          <input
                            className="d-none"
                            type="file"
                            onChange={handleFileChange}
                          />
                        )}
                    </label>
                  ))}
                </div>
              )} */}

                  <div ref={containerChatRef} onScroll={handlePaginationChat}>
                    {loadingPaginationChats && (
                      <div>
                        <Spinner css={styles.spinnerWhite} size="24" />
                      </div>
                    )}
                    {chats?.map((dealer, index) => {
                      const date = new Date(dealer.dateTime);
                      const showDate = renderDateIfChanged(date, previousDate);
                      previousDate = date;

                      return (
                        <div
                          key={index}
                          className={
                            dealer.dealerChat
                              ? styles.whatsapp_message
                              : styles.whatsapp_message_right
                          }
                        >
                          <div className={styles.chatDate}>
                            <div className={styles.showDate}>{showDate}</div>
                          </div>
                          <div
                            className={
                              dealer.dealerChat || dealer.messageType
                                ? dealer.dealerChat.split(".")[1] === "mp4" ||
                                  dealer.dealerChat.split(".")[1] === "mp3" ||
                                  dealer.dealerChat.split(".")[1] === "pdf" ||
                                  dealer.dealerChat.split(".")[1] === "jpg" ||
                                  dealer.dealerChat.split(".")[1] === "jpeg" ||
                                  dealer.dealerChat.split(".")[1] === "cdr" ||
                                  dealer.dealerChat.split(".")[1] === "psd" ||
                                  dealer.dealerChat.split(".")[1] === "eps" ||
                                  dealer.dealerChat.split(".")[1] === "heic" ||
                                  dealer.dealerChat.split(".")[1] === "ai" ||
                                  dealer.dealerChat.split(".")[1] === "webp" ||
                                  dealer.dealerChat.split(".")[1] === "png" ||
                                  dealer.messageType === "location"
                                  ? // ? styles.whatsapp_message_media
                                    // : styles.whatsapp_message_txt
                                    `${styles.whatsapp_message_media} ${
                                      mode ? styles.darkMode : styles.light
                                    }`
                                  : `${styles.whatsapp_message_txt} ${
                                      mode ? styles.darkMode : styles.light
                                    }`
                                : dealer.adminChat
                                ? dealer.adminChat.split(".")[1] === "mp4" ||
                                  dealer.adminChat.split(".")[1] === "mp3" ||
                                  dealer.adminChat.split(".")[1] === "pdf" ||
                                  dealer.adminChat.split(".")[1] === "jpg" ||
                                  dealer.adminChat.split(".")[1] === "jpeg" ||
                                  dealer.adminChat.split(".")[1] === "cdr" ||
                                  dealer.adminChat.split(".")[1] === "psd" ||
                                  dealer.adminChat.split(".")[1] === "eps" ||
                                  dealer.adminChat.split(".")[1] === "heic" ||
                                  dealer.adminChat.split(".")[1] === "ai" ||
                                  dealer.adminChat.split(".")[1] === "webp" ||
                                  dealer.adminChat.split(".")[1] === "png" ||
                                  dealer.adminChat === "Template" ||
                                  dealer.adminChat === "Campaign"
                                  ? // ? styles.whatsapp_message_right_media
                                    // : styles.whatsapp_message_right_css

                                    `${styles.whatsapp_message_right_media} ${
                                      mode ? styles.darkMode : styles.light
                                    }`
                                  : `${styles.whatsapp_message_right_css} ${
                                      mode ? styles.darkMode : styles.light
                                    }`
                                : ""
                            }
                          >
                            {dealer.designation && (
                              <div
                                className={
                                  dealer.adminChat
                                    ? dealer.adminChat.split(".")[1] ===
                                        "mp4" ||
                                      dealer.adminChat.split(".")[1] ===
                                        "mp3" ||
                                      dealer.adminChat.split(".")[1] ===
                                        "pdf" ||
                                      dealer.adminChat.split(".")[1] ===
                                        "jpg" ||
                                      dealer.adminChat.split(".")[1] ===
                                        "jpeg" ||
                                      dealer.adminChat.split(".")[1] ===
                                        "cdr" ||
                                      dealer.adminChat.split(".")[1] ===
                                        "psd" ||
                                      dealer.adminChat.split(".")[1] ===
                                        "eps" ||
                                      dealer.adminChat.split(".")[1] ===
                                        "heic" ||
                                      dealer.adminChat.split(".")[1] === "ai" ||
                                      dealer.adminChat.split(".")[1] ===
                                        "webp" ||
                                      dealer.adminChat.split(".")[1] ===
                                        "png" ||
                                      dealer.adminChat === "Template" ||
                                      dealer.adminChat === "Campaign"
                                      ? // ? styles.managerNameDesignation_media
                                        // : styles.managerNameDesignation
                                        `${styles.managerNameDesignation_media} 
                                  ${mode ? styles.dark : styles.lightMode}
                                    `
                                      : `${styles.managerNameDesignation} ${
                                          mode ? styles.dark : styles.lightMode
                                        }`
                                    : ""
                                }
                              >
                                <div className={styles.designation}>
                                  (
                                  {dealer.designation === "superadmin"
                                    ? "Super Admin"
                                    : dealer.designation === "ordermanager"
                                    ? "Order Manager"
                                    : dealer.designation}
                                  )
                                </div>
                                <div
                                  className={`${styles.managerName} ${
                                    dealer.designation === "superadmin"
                                      ? "text-danger"
                                      : dealer.designation === "ordermanager"
                                      ? "text-warning"
                                      : "text-primary"
                                  }`}
                                >
                                  {dealer.managerName.length > 10
                                    ? dealer.managerName.slice(0, 8) + "..."
                                    : dealer.managerName}
                                </div>
                              </div>
                            )}

                            {/* ============================  for dealer message desingnation ======================== */}

                            {dealer.type === "dealerMessage" && (
                              <div
                                className={`${styles.dealerNameDesignation} ${
                                  mode ? styles.dark : styles.lightMode
                                }`}
                              >
                                <div className={styles.designation}>
                                  ({dealer?.role ? dealer?.role : dealer?.type})
                                </div>
                                <div className={`${styles.managerName}`}>
                                  {/* {dealer?.name
                                    ? dealer?.name?.length > 8
                                      ? dealer?.name.slice(0, 8) + "..."
                                      : dealer?.name
                                    : dealerData?.dealerName} */}
                                    {dealer?.name ? dealer?.name :dealerData?.dealerName}
                                </div>
                              </div>
                            )}

                            <div className={styles.message_time}>
                              {dealer.type === "dealerMessage" ? (
                                convertToCorrectTime(dealer.dateTime)
                              ) : (
                                <>
                                  {convertToCorrectTime(dealer.dateTime)}&nbsp;
                                  {/* <Doubletick mode={mode} /> */}
                                  {
                                    // dealer.adminChat === "Template" ||
                                    //   dealer.adminChat === "Campaign" ? null :
                                    <Doubletick
                                      mode={mode}
                                      icon={
                                        dealer.status === "delivered"
                                          ? "Double"
                                          : dealer.status === "read"
                                          ? "DoubleWithColor"
                                          : "default"
                                      }
                                    />
                                  }
                                </>
                              )}
                            </div>
                            <div
                              className={
                                ((dealer.dealerChat || dealer.messageType) &&
                                  (dealer.dealerChat.split(".")[1] === "mp4" ||
                                    dealer.dealerChat.split(".")[1] === "mp3" ||
                                    dealer.dealerChat.split(".")[1] === "pdf" ||
                                    dealer.dealerChat.split(".")[1] === "jpg" ||
                                    dealer.dealerChat.split(".")[1] ===
                                      "jpeg" ||
                                    dealer.dealerChat.split(".")[1] === "cdr" ||
                                    dealer.dealerChat.split(".")[1] === "psd" ||
                                    dealer.dealerChat.split(".")[1] === "eps" ||
                                    dealer.dealerChat.split(".")[1] ===
                                      "heic" ||
                                    dealer.dealerChat.split(".")[1] === "ai" ||
                                    dealer.dealerChat.split(".")[1] ===
                                      "webp" ||
                                    dealer.dealerChat.split(".")[1] === "png" ||
                                    dealer.messageType === "location")) ||
                                (dealer.adminChat &&
                                  (dealer.adminChat.split(".")[1] === "mp4" ||
                                    dealer.adminChat.split(".")[1] === "mp3" ||
                                    dealer.adminChat.split(".")[1] === "pdf" ||
                                    dealer.adminChat.split(".")[1] === "jpg" ||
                                    dealer.adminChat.split(".")[1] === "jpeg" ||
                                    dealer.adminChat.split(".")[1] === "cdr" ||
                                    dealer.adminChat.split(".")[1] === "psd" ||
                                    dealer.adminChat.split(".")[1] === "eps" ||
                                    dealer.adminChat.split(".")[1] === "heic" ||
                                    dealer.adminChat.split(".")[1] === "ai" ||
                                    dealer.adminChat.split(".")[1] === "webp" ||
                                    dealer.adminChat.split(".")[1] === "png" ||
                                    dealer.adminChat === "Template" ||
                                    dealer.adminChat === "Campaign"))
                                  ? ""
                                  : styles.adminChats_css
                              }
                            >
                              {dealer.dealerChat || dealer.messageType ? (
                                dealer.dealerChat.split(".")[1] === "jpg" ||
                                dealer.dealerChat.split(".")[1] === "jpeg" ||
                                dealer.dealerChat.split(".")[1] === "cdr" ||
                                dealer.dealerChat.split(".")[1] === "psd" ||
                                dealer.dealerChat.split(".")[1] === "eps" ||
                                dealer.dealerChat.split(".")[1] === "heic" ||
                                dealer.dealerChat.split(".")[1] === "ai" ||
                                dealer.dealerChat.split(".")[1] === "webp" ||
                                dealer.dealerChat.split(".")[1] === "png" ? (
                                  <>
                                    {openFiles.includes(dealer.dealerChat) ? (
                                      <img
                                        src={
                                          dealer.dealerChat &&
                                          process.env.REACT_APP_S3URL +
                                            dealer.dealerChat
                                        }
                                        alt={dealer.dealerChat}
                                        className={
                                          styles.selected_whatsappImage
                                        }
                                        onClick={() =>
                                          window.open(
                                            dealer.dealerChat &&
                                              process.env.REACT_APP_S3URL +
                                                dealer.dealerChat,
                                            "mozillaWindow",
                                            "popup"
                                          )
                                        }
                                      />
                                    ) : (
                                      <div
                                        role="button"
                                        className={styles.openFile}
                                        onClick={() =>
                                          handleFileOpen(dealer.dealerChat)
                                        }
                                      >
                                        <div>
                                          <DownloadFileIcon mode={mode} />
                                        </div>
                                        <div>Image</div>
                                      </div>
                                    )}
                                  </>
                                ) : dealer.dealerChat.split(".")[1] ===
                                  "pdf" ? (
                                  <>
                                    {openFiles.includes(dealer.dealerChat) ? (
                                      <iframe
                                        className={
                                          styles.selected_whatsappImage
                                        }
                                        title="PDF Viewer"
                                        src={
                                          dealer.dealerChat &&
                                          process.env.REACT_APP_S3URL +
                                            dealer.dealerChat
                                        }
                                        width="100%"
                                        height="500px"
                                        frameBorder="0"
                                      />
                                    ) : (
                                      <div
                                        role="button"
                                        className={styles.openFile}
                                        onClick={() =>
                                          handleFileOpen(dealer.dealerChat)
                                        }
                                      >
                                        <div>
                                          <DownloadFileIcon mode={mode} />
                                        </div>
                                        <div>PDF</div>
                                      </div>
                                    )}
                                  </>
                                ) : dealer.dealerChat.split(".")[1] ===
                                  "mp4" ? (
                                  <>
                                    {openFiles.includes(dealer.dealerChat) ? (
                                      <video
                                        width="400"
                                        controls
                                        className={
                                          styles.selected_whatsappImage
                                        }
                                      >
                                        <source
                                          src={
                                            dealer.dealerChat &&
                                            process.env.REACT_APP_S3URL +
                                              dealer.dealerChat
                                          }
                                          type="video/mp4"
                                          className={
                                            styles.selected_whatsappImage
                                          }
                                        />
                                        Your browser does not support HTML5
                                        video.
                                      </video>
                                    ) : (
                                      <div
                                        role="button"
                                        className={styles.openFile}
                                        onClick={() =>
                                          handleFileOpen(dealer.dealerChat)
                                        }
                                      >
                                        <div>
                                          <DownloadFileIcon mode={mode} />
                                        </div>
                                        <div>Video</div>
                                      </div>
                                    )}
                                  </>
                                ) : dealer.dealerChat.split(".")[1] ===
                                  "mp3" ? (
                                  <>
                                    {openFiles.includes(dealer.dealerChat) ? (
                                      <audio controls>
                                        <source
                                          src={
                                            dealer.dealerChat &&
                                            process.env.REACT_APP_S3URL +
                                              dealer.dealerChat
                                          }
                                          type="audio/mpeg"
                                          className={
                                            styles.selected_whatsappImage
                                          }
                                        />
                                        Your browser does not support the audio
                                        element.
                                      </audio>
                                    ) : (
                                      <div
                                        role="button"
                                        className={styles.openFile}
                                        onClick={() =>
                                          handleFileOpen(dealer.dealerChat)
                                        }
                                      >
                                        <div>
                                          <DownloadFileIcon mode={mode} />
                                        </div>
                                        <div>Audio</div>
                                      </div>
                                    )}
                                  </>
                                ) : dealer.messageType === "location" ? (
                                  <>
                                    {openFiles.includes(dealer.dealerChat) ? (
                                      <iframe
                                        className={styles.google_map}
                                        loading="lazy"
                                        allowFullScreen
                                        title="Wallicon Private Limited"
                                        src={`https://www.google.com/maps/embed/v1/place?q=place_id:${dealer.dealerChat}&key=${process.env.REACT_APP_MAP_KEY}`}
                                      />
                                    ) : (
                                      <div
                                        role="button"
                                        className={styles.openFile}
                                        onClick={() =>
                                          handleFileOpen(dealer.dealerChat)
                                        }
                                      >
                                        <div>
                                          <DownloadFileIcon mode={mode} />
                                        </div>
                                        <div>Location</div>
                                      </div>
                                    )}
                                  </>
                                ) : dealer.messageType === "button" ? (
                                  <>{dealer.dealerChat}</>
                                ) : dealer.dealerChat.includes("https") ? (
                                  // <a
                                  //   className={styles.link_color}
                                  //   href={dealer.dealerChat}
                                  //   target="_blank"
                                  //   rel="noopener noreferrer"
                                  // >
                                  //   {dealer.dealerChat}
                                  // </a>

                                  <div
                                    key={dealer._id}
                                    style={{ marginBottom: "20px" }}
                                  >
                                    {metaData[dealer.dealerChat] && (
                                      <div>
                                        <h4>
                                          {metaData[dealer.dealerChat].title}
                                        </h4>
                                        <p>
                                          {
                                            metaData[dealer.dealerChat]
                                              .description
                                          }
                                        </p>

                                        {metaData[dealer.dealerChat]
                                          .thumbnail && (
                                          <div>
                                            <img
                                              src={
                                                metaData[dealer.dealerChat]
                                                  .thumbnail
                                              }
                                              alt="YouTube Thumbnail"
                                              style={{
                                                width: "200px",
                                                height: "120px",
                                              }}
                                            />
                                          </div>
                                        )}

                                        {metaData[dealer.dealerChat]
                                          .favicon && (
                                          <img
                                            src={
                                              metaData[dealer.dealerChat]
                                                .favicon
                                            }
                                            alt=""
                                            className={styles.favicon}
                                            style={{
                                              width: "26px",
                                              height: "26px",
                                              marginRight: "13px",
                                              backgroundColor: mode
                                                ? "#000"
                                                : "#f0f0f0",
                                              borderRadius: "50%",
                                              padding: "2px",
                                            }}
                                          />
                                        )}
                                        <a
                                          href={dealer.dealerChat}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          {dealer.dealerChat}
                                        </a>
                                      </div>
                                    )}
                                  </div>
                                ) : (
                                  dealer.dealerChat
                                )
                              ) : (
                                dealer.adminChat &&
                                (dealer.adminChat.split(".")[1] === "pdf" ? (
                                  <>
                                    {openFiles.includes(dealer.adminChat) ? (
                                      <iframe
                                        className={
                                          styles.selected_whatsappImage
                                        }
                                        title="PDF Viewer"
                                        src={
                                          dealer.adminChat &&
                                          process.env.REACT_APP_S3URL +
                                            dealer.adminChat
                                        }
                                        width="100%"
                                        height="500px"
                                        frameBorder="0"
                                      />
                                    ) : (
                                      <div
                                        role="button"
                                        className={styles.openFile}
                                        onClick={() =>
                                          handleFileOpen(dealer.adminChat)
                                        }
                                      >
                                        <div>
                                          <DownloadFileIcon mode={mode} />
                                        </div>
                                        <div>PDF</div>
                                      </div>
                                    )}
                                  </>
                                ) : dealer.adminChat.split(".")[1] === "mp4" ? (
                                  <>
                                    {openFiles.includes(dealer.adminChat) ? (
                                      <video
                                        width="400"
                                        controls
                                        className={
                                          styles.selected_whatsappImage
                                        }
                                      >
                                        <source
                                          src={
                                            dealer.adminChat &&
                                            process.env.REACT_APP_S3URL +
                                              dealer.adminChat
                                          }
                                          type="video/mp4"
                                          className={
                                            styles.selected_whatsappImage
                                          }
                                        />
                                        Your browser does not support HTML5
                                        video.
                                      </video>
                                    ) : (
                                      <div
                                        role="button"
                                        className={styles.openFile}
                                        onClick={() =>
                                          handleFileOpen(dealer.adminChat)
                                        }
                                      >
                                        <div>
                                          <DownloadFileIcon mode={mode} />
                                        </div>
                                        <div>Video</div>
                                      </div>
                                    )}
                                  </>
                                ) : dealer.adminChat.split(".")[1] === "mp3" ? (
                                  <>
                                    {openFiles.includes(dealer.adminChat) ? (
                                      <audio controls>
                                        <source
                                          src={
                                            dealer.adminChat &&
                                            process.env.REACT_APP_S3URL +
                                              dealer.adminChat
                                          }
                                          type="audio/mpeg"
                                          className={styles.whatsappAudio}
                                        />
                                        Your browser does not support the audio
                                        element.
                                      </audio>
                                    ) : (
                                      <div
                                        role="button"
                                        className={styles.openFile}
                                        onClick={() =>
                                          handleFileOpen(dealer.adminChat)
                                        }
                                      >
                                        <div>
                                          <DownloadFileIcon mode={mode} />
                                        </div>
                                        <div>Audio</div>
                                      </div>
                                    )}
                                  </>
                                ) : dealer.adminChat.split(".")[1] === "jpg" ||
                                  dealer.adminChat.split(".")[1] === "jpeg" ||
                                  dealer.adminChat.split(".")[1] === "cdr" ||
                                  dealer.adminChat.split(".")[1] === "psd" ||
                                  dealer.adminChat.split(".")[1] === "eps" ||
                                  dealer.adminChat.split(".")[1] === "heic" ||
                                  dealer.adminChat.split(".")[1] === "ai" ||
                                  dealer.adminChat.split(".")[1] === "webp" ||
                                  dealer.adminChat.split(".")[1] === "png" ? (
                                  <>
                                    {openFiles.includes(dealer.adminChat) ? (
                                      <img
                                        src={
                                          dealer.adminChat &&
                                          process.env.REACT_APP_S3URL +
                                            dealer.adminChat
                                        }
                                        alt={dealer.adminChat}
                                        className={
                                          styles.selected_whatsappImage
                                        }
                                        onClick={() =>
                                          window.open(
                                            dealer.adminChat &&
                                              process.env.REACT_APP_S3URL +
                                                dealer.adminChat,
                                            "mozillaWindow",
                                            "popup"
                                          )
                                        }
                                      />
                                    ) : (
                                      <div
                                        role="button"
                                        className={styles.openFile}
                                        onClick={() =>
                                          handleFileOpen(dealer.adminChat)
                                        }
                                      >
                                        <div>
                                          <DownloadFileIcon mode={mode} />
                                        </div>
                                        <div>Image</div>
                                      </div>
                                    )}
                                  </>
                                ) : dealer.adminChat === "Template" ||
                                  dealer.adminChat === "Campaign" ? (
                                  <div
                                    className={`${styles.templateContent} ${
                                      mode ? styles.dark : styles.lightMode
                                    }`}
                                  >
                                    {/* {['document', 'video'].includes(dealer.headerType) ? <div className={styles.pdf_vertical}></div> : <div className={styles.pdf_vertical_doc}></div>} */}

                                    {dealer.headerType === "text" ? (
                                      <h4 className="text-dark text-capitalize">
                                        {dealer.adminChat}
                                      </h4>
                                    ) : (
                                      ["document", "video", "image"].includes(
                                        dealer.headerType
                                      ) && (
                                        <>
                                          {openFile.includes(dealer._id) ? (
                                            <>
                                              {dealer.headerType === "image" &&
                                              dealer.header ? (
                                                <img
                                                  src={`${process.env.REACT_APP_S3URL}${dealer.header}`}
                                                  alt="template_image"
                                                  width="230px"
                                                  height="100%"
                                                />
                                              ) : (
                                                <div
                                                  className={styles.mediadiv}
                                                >
                                                  <span
                                                    className={styles.mediaIcon}
                                                  >
                                                    {dealer.headerType ===
                                                    "video" ? (
                                                      <VideoThumbnail
                                                        videoUrl={`${process.env.REACT_APP_S3URL}${dealer.header}`}
                                                      />
                                                    ) : dealer.headerType ===
                                                      "document" ? (
                                                      <PDFViewer
                                                        url={`${process.env.REACT_APP_S3URL}${dealer.header}`}
                                                      />
                                                    ) : null}
                                                  </span>
                                                </div>
                                              )}
                                            </>
                                          ) : (
                                            <div
                                              role="button"
                                              className={
                                                styles.openFileTemplate
                                              }
                                              onClick={() =>
                                                handleTemplateOpen(dealer._id)
                                              }
                                            >
                                              <div>
                                                <DownloadFileIcon mode={mode} />
                                              </div>
                                              <div
                                                style={{
                                                  color: mode ? "#fff" : "#000",
                                                }}
                                              >
                                                View
                                              </div>
                                            </div>
                                          )}
                                        </>
                                      )
                                    )}

                                    {dealer.body && (
                                      <div
                                        style={{
                                          color: mode ? "#fff" : "#000",
                                        }}
                                        className={`${
                                          dealer.header ? "mt-2" : ""
                                        } mb-0`}
                                        dangerouslySetInnerHTML={{
                                          __html: dealer.body
                                            .replace(
                                              /\*\*(.*?)\*\*/g,
                                              "<strong>$1</strong>"
                                            )
                                            .replace(/_(.*?)_/g, "<em>$1</em>")
                                            .replace(/\n/g, "<br />"),
                                        }}
                                      />
                                    )}

                                    {dealer.footer && (
                                      <div className="text-secondary small mt-1 text-capitalize">
                                        {dealer.footer}
                                      </div>
                                    )}

                                    {dealer.buttons?.map((button, index) => (
                                      <div
                                        key={index}
                                        className="text-capitalize text-primary text-center border-top mt-2 pt-1"
                                      >
                                        <span className="me-2">
                                          {button.type === "PHONE_NUMBER" ? (
                                            <Phone color="#fff" />
                                          ) : (
                                            <GotoWebsiteIcon />
                                          )}
                                        </span>
                                        {button.url ? (
                                          <a
                                            className={styles.websiteLink}
                                            href={ensureProtocol(button.url)}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                          >
                                            <b>{button.text}</b>
                                          </a>
                                        ) : (
                                          <span className={styles.websiteLink}>
                                            <b>{button.text}</b>
                                          </span>
                                        )}
                                      </div>
                                    ))}
                                  </div>
                                ) : dealer.adminChat.includes("https") ? (
                                  // <a
                                  //   href={dealer.adminChat}
                                  //   target="_blank"
                                  //   rel="noopener noreferrer"
                                  // >
                                  //   {dealer.adminChat}
                                  // </a>

                                  <div
                                    key={dealer._id}
                                    style={{ marginBottom: "20px" }}
                                  >
                                    {metaData[dealer.adminChat] && (
                                      <div>
                                        <h4>
                                          {metaData[dealer.adminChat].title}
                                        </h4>
                                        <p>
                                          {
                                            metaData[dealer.adminChat]
                                              .description
                                          }
                                        </p>

                                        {metaData[dealer.adminChat]
                                          .thumbnail && (
                                          <div>
                                            <img
                                              src={
                                                metaData[dealer.adminChat]
                                                  .thumbnail
                                              }
                                              alt="YouTube Thumbnail"
                                              style={{
                                                width: "200px",
                                                height: "120px",
                                              }}
                                            />
                                          </div>
                                        )}

                                        {metaData[dealer.adminChat].favicon && (
                                          <img
                                            src={
                                              metaData[dealer.adminChat].favicon
                                            }
                                            className={styles.favicon}
                                            alt=""
                                            style={{
                                              width: "26px",
                                              height: "26px",
                                              marginRight: "13px",
                                              backgroundColor: mode
                                                ? "#000"
                                                : "#f0f0f0",
                                              borderRadius: "50%",
                                              padding: "2px",
                                            }}
                                          />
                                        )}
                                        <a
                                          href={dealer.adminChat}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          {dealer.adminChat}
                                        </a>
                                      </div>
                                    )}
                                  </div>
                                ) : (
                                  dealer.adminChat
                                ))
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>

                <div
                  className={`${styles.whatsapp_input_div}  ${
                    mode ? styles.maindarkMode : styles.light
                  }`}
                >
                  <div className={styles.AddFile}>
                    <span className={styles.icon_css}>
                      <WhatsappEmojisIcon mode={mode} />
                    </span>
                    <span
                      onClick={(e) => {
                        e.stopPropagation();
                        if (showFileOptions === "files") {
                          setShowFileOptions();
                        } else {
                          setShowFileOptions("files");
                        }
                      }}
                    >
                      <AddFile mode={mode} />
                    </span>
                  </div>

                  {/* {files && files.length !== 0 ? (
                <div className={styles.view_selectedFile_caption}>
                  <div className={styles.view_selectedFile}>
                    <div
                      className={styles.whatsappImage_remove}
                      onClick={() => setFiles([])}
                    >
                      <Close />
                    </div>
                    {fileExt === "mp3" ? (
                      <div className="d-flex justify-content-between align-items-center w-100">
                        <audio controls>
                          <source
                            src={URL.createObjectURL(files[0])}
                            type="audio/mpeg"
                            className={styles.selected_whatsappImage}
                          />
                          Your browser does not support the audio element.
                        </audio>
                        <div className={`${styles.fileName} pt-3`}>
                          <span>{files[0].name && files[0].name}</span>
                        </div>
                      </div>
                    ) : fileExt === "mp4" ? (
                      <video
                        width="400"
                        controls
                        className={styles.selected_whatsappImage}
                      >
                        <source
                          src={URL.createObjectURL(files[0])}
                          type="video/mp4"
                        />
                        Your browser does not support HTML5 video.
                      </video>
                    ) : fileExt === "pdf" ? (
                      <iframe
                        className={styles.selected_whatsappImage}
                        title="PDF Viewer"
                        src={URL.createObjectURL(files[0])}
                        width="100%"
                        height="500px"
                        frameBorder="0"
                      />
                    ) : (
                      <img
                        src={
                          files &&
                          files.length !== 0 &&
                          URL.createObjectURL(files[0])
                        }
                        alt="selected_Image"
                        className={styles.selected_whatsappImage}
                      />
                    )}
                  </div>
                  {fileExt === "mp3" ? (
                    ""
                  ) : (
                    <div className="d-flex flex-column justify-centent-center align-item-center w-100">
                      <div className={styles.fileName}>
                        <span>{files[0].name && files[0].name}</span>
                      </div>
                      <input
                        type="text"
                        placeholder="Enter Caption"
                        className={styles.caption}
                        value={caption}
                        onChange={(e) => setCaption(e.target.value)}
                      />
                    </div>
                  )}
                </div>
              ) : (
                <input
                  placeholder={
                    fileType === "link"
                      ? "Type Link"
                      : fileType === "location"
                      ? "Type location"
                      : "Type Message"
                  }
                  type="text"
                  // autoFocus
                  className={`${styles.whatsapp_chats_input}  ${
                    mode ? styles.dark : styles.lightMode
                  }`}
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      if (selectDealers && files.length === 0) {
                        sendSelectMessage();
                      } else if (selectDealers && files.length !== 0) {
                        sendMedia();
                      } else {
                        sentMessage();
                      }
                    }
                  }}
                />
              )} */}

                  <input
                    placeholder={
                      fileType === "link"
                        ? "Type Link"
                        : fileType === "location"
                        ? "Type location"
                        : "Type Message"
                    }
                    type="text"
                    // autoFocus
                    className={`${styles.whatsapp_chats_input}  ${
                      mode ? styles.dark : styles.lightMode
                    }`}
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        // if (selectDealers && files.length === 0) {
                        //   sendSelectMessage();
                        // } else if (selectDealers && files.length !== 0) {
                        //   sendMedia();
                        // } else {
                        //   sentMessage();
                        // }

                        if (selectDealers && files.length !== 0) {
                          sentMessage();
                        } else {
                          sentMessage();
                        }
                      }
                    }}
                  />

                  <div className="d-flex h-100vh justify-content-center align-items-center">
                    <button
                      type="button"
                      className={styles.SendMessage}
                      // onClick={() => {
                      //   if (selectDealers && files.length === 0) {
                      //     sendSelectMessage();
                      //   } else if (selectDealers && files.length !== 0) {
                      //     sendMedia();
                      //   } else {
                      //     sentMessage();
                      //   }
                      // }}
                      onClick={sentMessage}
                      disabled={uploadLoading ? true : false}
                    >
                      {uploadLoading ? (
                        <Spinner size="15" />
                      ) : (
                        <SendMessageIcon />
                      )}
                    </button>
                  </div>
                </div>
              </>
            )}
          </MobileOneToOneChat>
        ) : (
          <MobileAllDealerChats>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                overflowX: "auto",
                flexWrap: "nowrap",
              }}
            >
              {newSelectedManagerName && newSelectedManagerName.length !== 0 ? (
                newSelectedManagerName.map((item, index) => (
                  <div
                    key={index} // Always place the key on the outermost element inside the map
                    className="d-flex text-light my-2 mx-2"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      whiteSpace: "nowrap", // Prevent line wrapping
                    }}
                  >
                    <span
                      key={index}
                      style={btnstyle}
                      role="button"
                      className="rounded-pill  d-flex justify-content-evenly align-items-start px-3 py-1 m-1"
                      // className={styles.managerName_selected}
                    >
                      {item.managerName}
                    </span>
                  </div>
                ))
              ) : (
                <>
                  <div
                    className={`${styles.whatsapp_search_div} ${
                      mode ? styles.darkMode : styles.lightMode
                    }`}
                  >
                    <input
                      type="search"
                      placeholder="Search"
                      className={`${styles.whatsapp_search} ${
                        mode ? styles.dark : styles.light
                      }`}
                      value={selectNewDealers ? keywordDealers : keyword}
                      onChange={(e) => {
                        if (selectNewDealers) {
                          setKeywordDealers(e.target.value);
                        } else {
                          setKeyword(e.target.value);
                        }
                      }}
                    />
                    <Search mode={mode} css={styles.whatsapp_search_icon} />

                    {designation === "superadmin" ? (
                      <div className="position-relative">
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                            gap: 10,
                          }}
                        >
                          <span
                            onClick={() => {
                              if (selectNewDealers) {
                                setSelectNewDealers(!selectNewDealers);
                              } else {
                                if (readMessage === "unread") {
                                  setReadMessage("");
                                } else {
                                  setReadMessage("unread");
                                }
                              }
                            }}
                          >
                            <Unread
                              mode={mode}
                              unread={
                                totalUnreadChat && totalUnreadChat >= 1
                                  ? true
                                  : false
                              }
                            />
                          </span>
                          <span
                            onClick={(e) => {
                              e.stopPropagation();
                              if (markRead === "read") {
                                setMarkRead();
                              } else {
                                setMarkRead("read");
                              }
                            }}
                          >
                            <ThreeDots mode={mode} css={styles.threedot_size} />
                          </span>
                        </div>
                      </div>
                    ) : (
                      <span
                        onClick={() => {
                          if (selectNewDealers) {
                            setSelectNewDealers(!selectNewDealers);
                          } else {
                            if (readMessage === "unread") {
                              setReadMessage("");
                            } else {
                              setReadMessage("unread");
                            }
                          }
                        }}
                      >
                        <Unread
                          mode={mode}
                          unread={
                            totalUnreadChat && totalUnreadChat >= 1
                              ? true
                              : false
                          }
                        />
                      </span>
                    )}
                  </div>

                  {markRead === "read" && (
                    <div
                      className={`${styles.markRead_div}  ${
                        mode ? styles.borderLight : styles.borderDark
                      }`}
                      ref={markReadRef}
                    >
                      <div
                        className={styles.active_markRead}
                        onClick={() => setShowMarkRead(true)}
                      >
                        Mark As Read
                      </div>
                      <div className={styles.active_markRead}>
                        Mark As Unread
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>

            <div className={styles.scrollAll_filters}>
              <div
                role="button"
                style={btnstyle}
                // className={`${
                //   styles.min_filter_width
                // } border rounded-pill text-warning border-warning d-flex justify-content-center align-items-center me-2 ${
                //   mobile ? "ps-1 pe-1" : "px-2"
                // }`}
                className={`rounded-pill  d-flex justify-content-center align-items-center px-3 py-1 ${
                  mobile && "bg-success"
                }`}
                onClick={() => {
                  if (mobile && managerId) {
                    navigateToPage("/whatsappchats");
                  } else {
                    setSelectDealers(!selectDealers);
                    setSelectedItems([]);
                  }
                }}
              >
                {selectDealers ? (
                  "Unselect"
                ) : mobile ? (
                  <>
                    {/* <CrossCircelIcon /> */}
                    {/* &nbsp; */}
                    Close Chat
                  </>
                ) : (
                  <>Select </>
                )}
              </div>

              {selectDealers && !selectNewDealers ? (
                <div
                  style={{
                    padding: "8px 16px",
                    borderRadius: "50px",
                    cursor: "pointer",
                    minWidth: "120px",
                    backgroundColor: mode ? "#000000" : "#F1F1F1",
                    color: mode ? "#fff" : "#000",
                  }}
                  role="button"
                  //   className={`${styles.min_filter_width} border px-2 rounded-pill d-flex justify-content-center align-items-center border-success text-success`}
                  className={`rounded-pill  d-flex justify-content-center align-items-center px-3 py-1`}
                  onClick={() => {
                    setSelectAllDealers(!selectAllDealers);
                    handleSelectAllChats();
                  }}
                >
                  Select All
                </div>
              ) : selectDealers && selectNewDealers ? (
                <div
                  style={{
                    padding: "8px 16px",
                    borderRadius: "50px",
                    cursor: "pointer",
                    minWidth: "120px",
                    backgroundColor: mode ? "#000000" : "#F1F1F1",
                    color: mode ? "#fff" : "#000",
                  }}
                  role="button"
                  //   className={`${styles.min_filter_width} border px-2 d-flex justify-content-center align-items-center rounded-pill border-info text-info me-2`}
                  className={`rounded-pill d-flex justify-content-center align-items-center px-3 py-1`}
                  onClick={() => {
                    setSelectAllNewDealers(!selectAllNewDealers);
                    handleSelectAllContacts();
                  }}
                >
                  Select All
                </div>
              ) : (
                <div
                  style={btnstyle}
                  role="button"
                  //   className="border px-2 rounded-pill border-info text-info me-2"
                  className={`rounded-pill  d-flex justify-content-center align-items-center px-3 py-1 `}
                  onClick={() => {
                    if (mobile && managerId) {
                      navigateToPage("/whatsappchats");
                    } else {
                      setSelectNewDealers(!selectNewDealers);
                      setPage(1);
                      setPageChat(1);
                    }
                  }}
                >
                  {selectNewDealers ? (
                    <>&nbsp;&nbsp;&nbsp;Chats&nbsp;&nbsp;</>
                  ) : (
                    "Contacts"
                  )}
                </div>
              )}

              {designation === "superadmin" && (
                <div className="position-relative me-2">
                  <div
                    // className={`${styles.managerFilter} ${
                    //   managersOptions === "manager" ? styles.managerActive : ""
                    // } `}
                    style={btnstyle}
                    className="position-relative rounded-pill  px-3 py-1"
                    onClick={(e) => {
                      e.stopPropagation();
                      if (managersOptions === "manager") {
                        setManagersOptions();
                      } else {
                        setManagersOptions("manager");
                      }
                    }}
                  >
                    &nbsp;Manager&nbsp;
                  </div>
                </div>
              )}
              <div
                role="button"
                style={btnstyle}
                // className={`border px-2 rounded-pill border-success d-flex justify-content-center align-items-center text-success me-2 ${
                //   readMessage === "" ? "bg-success text-light" : ""
                // }`}
                className={`rounded-pill  d-flex justify-content-center align-items-center px-3 py-1 ${
                  readMessage === "" ? "bg-success text-light" : ""
                }`}
                onClick={() => {
                  if (selectNewDealers) {
                    setSelectNewDealers(!selectNewDealers);
                  } else {
                    setReadMessage("");
                    setDay("");
                  }
                }}
              >
                Inbox
              </div>
              <div
                style={btnstyle}
                role="button"
                // className={`border px-2 rounded-pill border-danger d-flex justify-content-center align-items-center text-danger me-2 ${
                //   readMessage === "unread" ? "bg-danger text-light" : ""
                // }`}

                className={`rounded-pill  d-flex justify-content-center align-items-center px-3 py-1 ${
                  readMessage === "unread" ? "bg-danger text-light" : ""
                } `}
                onClick={() => {
                  if (selectNewDealers) {
                    setSelectNewDealers(!selectNewDealers);
                  } else {
                    if (readMessage === "unread") {
                      setReadMessage("");
                    } else {
                      setReadMessage("unread");
                    }
                  }
                }}
              >
                Unread
              </div>
              <div
                style={btnstyle}
                role="button"
                // className={`border px-2 rounded-pill border-primary d-flex justify-content-center align-items-center text-primary me-2 ${
                //   day === "today" ? "bg-primary text-light" : ""
                // }`}
                className={`rounded-pill  d-flex justify-content-center align-items-center px-3 py-1 ${
                  day === "today" ? "bg-primary text-light" : ""
                }`}
                onClick={() => {
                  if (selectNewDealers) {
                    setSelectNewDealers(!selectNewDealers);
                    setDay("today");
                  } else {
                    if (day === "today") {
                      setDay("");
                    } else {
                      setDay("today");
                    }
                  }
                }}
              >
                Today
              </div>

              {/* {designation === "superadmin" && (
                <div className="position-relative">
                  <span
                    onClick={(e) => {
                      e.stopPropagation();
                      if (markRead === "read") {
                        setMarkRead();
                      } else {
                        setMarkRead("read");
                      }
                    }}
                  >
                    <ThreeDots mode={mode} css={styles.threedot_size} />
                  </span>
                </div>
              )} */}
            </div>

            {selectNewDealers ? (
              <div
                className={styles.whatsappMessage}
                ref={containerRef}
                onScroll={handleScroll}
              >
                {allDealers?.length !== 0 &&
                  allDealers.map((item, index) => (
                    <div
                      style={{
                        flex: 1,
                        borderBottom: mode
                          ? "2px solid #000"
                          : "2px solid #E9EDEF",
                        width: "95%",
                        margin: "auto",
                      }}
                      key={index}
                      className={`${styles.profile_card} ${
                        item.whatsapp && item.whatsapp === dealerData.whatsapp
                          ? selectDealers
                            ? ""
                            : styles.active_dealer_chat
                          : ""
                      }`}
                      onClick={() => {
                        item.whatsapp &&
                          navigate("/whatsappchats?mobile=" + item.whatsapp);
                        setFiles([]);
                        setFileType();
                      }}
                    >
                      {/* {item.unreadChat !== 0 && <div className={styles.unreadChat}>{item.unreadChat}</div>} */}
                      {selectDealers && (
                        <div
                          className={styles.checkbox_css}
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          <div
                            role="button"
                            style={{
                              border: "2px solid #4164E3",
                              borderRadius: "5px",
                              padding: "2px",
                            }}
                            // className="border rounded p-1 d-flex justify-content-center align-items-center me-2"
                            className="d-flex justify-content-center align-items-center me-2"
                            onClick={(e) => {
                              toggleSelectItem(item.whatsapp);
                              e.stopPropagation();
                            }}
                          >
                            {/* {(selectedItems.some(selected => selected.whatsapp === item.whatsapp && selected.managerId === item.managerId)) ? ( */}
                            {selectedItems.some(
                              (selected) => selected.whatsapp === item.whatsapp
                            ) ? (
                              <CheckGreenIcon />
                            ) : (
                              <span className={styles.checkbox_height}></span>
                            )}
                          </div>
                        </div>
                      )}
                      {!item.profileImage ? (
                        <img
                          src="/assets/whatsapp_profile_mob.png"
                          alt="profile_Image"
                          className={styles.whatsappMessage_image}
                          style={{ border: borderColor }}
                        />
                      ) : (
                        <img
                          src={process.env.REACT_APP_S3URL + item.profileImage}
                          className={styles.whatsappMessage_image}
                          alt="dealer_image"
                          style={{ border: borderColor }}
                        />
                      )}
                      <div className="w-100 ms-2">
                        <div className="d-flex justify-content-between">
                          <label
                            className={`${styles.dealer_name} ${
                              mode ? styles.dark_text : styles.light_text
                            }`}
                          >
                            {item.storeName && item.name
                              ? item.storeName
                              : !item.storeName && item.name
                              ? item.dealerName
                              : item.whatsapp}
                          </label>
                        </div>
                        <div className="d-flex justify-content-between">
                          <label
                            className={`${styles.profile_card_message} ${
                              mode ? styles.dark_text : styles.light_text
                            }`}
                          >
                            {item.dealerId}
                          </label>
                        </div>
                      </div>
                    </div>
                  ))}
                {loadingPaginationContact && (
                  <div>
                    <Spinner css={styles.spinnerWhite} size="24" />
                  </div>
                )}
              </div>
            ) : (
              <div
                className={`${styles.whatsappMessage} ${
                  mode ? styles.maindarkMode : styles.mainlightMode
                }`}
                ref={containerRefwhatsapp}
                onScroll={handleScrollChat}
              >
                {managersOptions === "manager" && (
                  <div
                    className={`${styles.managerFilter_div} ${
                      mode ? styles.borderLight : styles.borderDark
                    }`}
                    ref={managerRef}
                  >
                    {/* {selectManagerData.length !== 0 && <div role="button" className='bg-light text-dark d-flex justify-content-between align-items-center border-bottom px-2 py-1' onClick={() => { if (selectedManager === selectManagerData) { setSelectedManager([]) } else { handleSelectAllManager() } }}>
                                        <span>Select All</span>
                                        {selectedManager === selectManagerData ? <CheckIcon /> : ''}
                                    </div>} */}
                    {selectManagerData &&
                      selectManagerData.map((manager) => (
                        <div
                          // style={btnstyle}

                          role="button"
                          className="d-flex justify-content-between align-items-center px-2 py-1  mx-1 my-1"
                          key={manager.managerId}
                          onClick={() =>
                            handleManagerClick(manager.managerId, manager.name)
                          }
                        >
                          <span>{manager.name}</span>
                          {selectedManager.some(
                            (selected) =>
                              selected.managerId === manager.managerId
                          ) ? (
                            <CheckIcon selected={true} />
                          ) : (
                            ""
                          )}
                        </div>
                      ))}
                  </div>
                )}
                {data?.length !== 0 ? (
                  data.map((item, index) => {
                    let latestMessage = null;
                    let latestMessageTime = null;

                    if (item.dealerMessage && item.dealerMessage.length !== 0) {
                      const dealerLastMessageTime = new Date(
                        item.dealerMessage[
                          item.dealerMessage.length - 1
                        ].dealerDateTime
                      );
                      if (
                        !latestMessageTime ||
                        dealerLastMessageTime > latestMessageTime
                      ) {
                        latestMessage =
                          item.dealerMessage[item.dealerMessage.length - 1]
                            .dealerChat;
                        latestMessageTime = dealerLastMessageTime;
                      }
                    }

                    if (item.adminMessage && item.adminMessage.length !== 0) {
                      const adminLastMessageTime = new Date(
                        item.adminMessage[
                          item.adminMessage.length - 1
                        ].adminDateTime
                      );
                      if (
                        !latestMessageTime ||
                        adminLastMessageTime > latestMessageTime
                      ) {
                        latestMessage =
                          item.adminMessage[item.adminMessage.length - 1]
                            .adminChat;
                        latestMessageTime = adminLastMessageTime;
                      }
                    }

                    return (
                      <div
                        key={index}
                        style={{
                          flex: 1,
                          borderBottom: mode
                            ? "2px solid #000"
                            : "2px solid #E9EDEF",
                          width: "95%",
                          margin: "auto",
                        }}
                        className={`${styles.profile_card} ${
                          item.whatsapp && item.whatsapp === dealerData.whatsapp
                            ? selectDealers
                              ? ""
                              : styles.active_dealer_chat
                            : ""
                        }`}
                        onClick={() => {
                          item.whatsapp &&
                            navigate("/whatsappchats?mobile=" + item.whatsapp);
                          setFiles([]);
                          setFileType();
                          setPaginationChat(1);

                          setPaginationChat(1);
                          {
                            designation === "ordermanager" &&
                              handleMarkRead(item.whatsapp);
                          }
                        }}
                      >
                        {item.unreadChat !== 0 && (
                          <div className={styles.unreadChat}>
                            {item.unreadChat}
                          </div>
                        )}
                        {selectDealers && (
                          <div
                            className={styles.checkbox_css}
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                          >
                            <div
                              role="button"
                              style={{
                                border: "2px solid #4164E3",
                                borderRadius: "5px",
                                padding: "2px",
                              }}
                              className="d-flex justify-content-center align-items-center me-3"
                              onClick={(e) => {
                                toggleSelectItem(item.whatsapp);
                                e.stopPropagation();
                              }}
                            >
                              {/* {(selectedItems.some(selected => selected.whatsapp === item.whatsapp && selected.managerId === item.managerId)) ? ( */}
                              {selectedItems.some(
                                (selected) =>
                                  selected.whatsapp === item.whatsapp
                              ) ? (
                                <CheckGreenIcon />
                              ) : (
                                <span className={styles.checkbox_height}></span>
                              )}
                            </div>
                          </div>
                        )}
                        {!item.profileImage ? (
                          <img
                            src="/assets/whatsapp_profile_mob.png"
                            alt="profile_Image"
                            className={styles.whatsappMessage_image}
                            style={{ border: borderColor }}
                          />
                        ) : (
                          <img
                            src={
                              process.env.REACT_APP_S3URL + item.profileImage
                            }
                            className={styles.whatsappMessage_image}
                            alt="dealer_image"
                            style={{ border: borderColor }}
                          />
                        )}
                        <div className={styles.whatsapp_dealer_detail}>
                          <div className="d-flex justify-content-between">
                            <label
                              className={`${styles.dealer_name} ${
                                mode ? styles.dark_text : styles.light_text
                              }`}
                            >
                              {item.storeName && item.dealerName
                                ? item.storeName
                                : !item.storeName && item.dealerName
                                ? item.dealerName
                                : item.whatsapp}
                            </label>
                            <span
                              style={{ color: mode ? "#F8F9FC" : "#646B88" }}
                            >
                              {latestMessageTime &&
                                renderDateIfChanged(latestMessageTime)}
                            </span>
                          </div>
                          <div className="d-flex justify-content-between">
                            <label
                              className={`${styles.profile_card_message} ${
                                mode ? styles.dark_text : styles.light_text
                              } `}
                            >
                              {latestMessage &&
                                (latestMessage.split(".")[1] === "mp4"
                                  ? "video"
                                  : latestMessage.split(".")[1] === "mp3"
                                  ? "Audio"
                                  : latestMessage.split(".")[1] === "pdf"
                                  ? "PDF File"
                                  : latestMessage.split(".")[1] === "jpg" ||
                                    latestMessage.split(".")[1] === "cdr" ||
                                    latestMessage.split(".")[1] === "psd" ||
                                    latestMessage.split(".")[1] === "eps" ||
                                    latestMessage.split(".")[1] === "heic" ||
                                    latestMessage.split(".")[1] === "ai" ||
                                    latestMessage.split(".")[1] === "webp" ||
                                    latestMessage.split(".")[1] === "jpeg" ||
                                    latestMessage.split(".")[1] === "png"
                                  ? "Image"
                                  : latestMessage.includes("payment-order")
                                  ? "Order Link"
                                  : latestMessage)}
                            </label>
                            <span
                              style={{ color: mode ? "#F8F9FC" : "#646B88" }}
                            >
                              {convertToCorrectTime(
                                latestMessageTime && latestMessageTime
                              )}
                              &nbsp;
                              {/* {item.dealerMessage?.length !== 0 &&
                              item.dealerMessage[item.dealerMessage.length - 1]
                                .status === "read" ? (
                                <DoubletickActive />
                              ) : (
                                <Doubletick />
                              )} */}
                              {item.dealerMessage?.length !== 0 && (
                                // latestMessage === "Template" || latestMessage === "Campaign" ? null :

                                // ================================= FOr Double Tick Changes ==========================================
                                <Doubletick
                                  mode={mode}
                                  icon={
                                    item.dealerMessage.length > 0 &&
                                    item.adminMessage.length > 0
                                      ? // Check both dealer and admin messages
                                        item.dealerMessage[
                                          item.dealerMessage.length - 1
                                        ].status === "sent" &&
                                        item.adminMessage[
                                          item.adminMessage.length - 1
                                        ].status === "sent"
                                        ? "Double"
                                        : item.dealerMessage[
                                            item.dealerMessage.length - 1
                                          ].status === "read" &&
                                          item.adminMessage[
                                            item.adminMessage.length - 1
                                          ].status === "read"
                                        ? "DoubleWithColor"
                                        : "default"
                                      : // If only dealerMessage exists
                                      item.dealerMessage.length > 0
                                      ? item.dealerMessage[
                                          item.dealerMessage.length - 1
                                        ].status === "sent"
                                        ? "Double"
                                        : item.dealerMessage[
                                            item.dealerMessage.length - 1
                                          ].status === "read"
                                        ? "DoubleWithColor"
                                        : "default"
                                      : // If only adminMessage exists
                                      item.adminMessage.length > 0
                                      ? item.adminMessage[
                                          item.adminMessage.length - 1
                                        ].status === "sent"
                                        ? "Double"
                                        : item.adminMessage[
                                            item.adminMessage.length - 1
                                          ].status === "read"
                                        ? "DoubleWithColor"
                                        : "default"
                                      : "default"
                                  }
                                />

                                // =================== Correct Double Tick Top Change code   =====================

                                // <Doubletick
                                //   mode={mode}
                                //   icon={
                                //     item.dealerMessage[
                                //       item.dealerMessage.length - 1
                                //     ].status === "sent"
                                //       ? "Double"
                                //       : item.dealerMessage[
                                //           item.dealerMessage.length - 1
                                //         ].status === "read"
                                //       ? "DoubleWithColor"
                                //       : "default"
                                //   }
                                // />
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div
                    style={{
                      height: "400px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      textAlign: "center",
                    }}
                  >
                    <CustomHeadingText text={"No Data Found"} />
                  </div>
                )}
                {loadingPagination && (
                  <div>
                    <Spinner css={styles.spinnerWhite} size="24" />
                  </div>
                )}
              </div>
            )}
          </MobileAllDealerChats>
        )}
      </MobileWrapper>

      <Modal
        show={showMarkRead}
        close={setShowMarkRead}
        closeBtn={true}
        heading="Mark As Read"
        content={
          <div className={styles.text_center}>
            <h3 className="text-center my-5">Are you sure want to mark read</h3>
            {/* <div className="d-flex justify-content-between align-items-center mx-auto w-100 mb-4"> */}
            {/* <RedOutButton
                title="NO"
                handleSubmit={() => setShowMarkRead(false)}
              />
              <GreenButton
                title="YES"
                handleSubmit={() => {
                  handleMarkRead();
                }}
              /> */}
            <div className="d-flex justify-content-between align-items-center mx-auto w-100 mb-4 gap-3">
              <Button
                buttonIcon={<Block />}
                buttonTextColor={"#fff"}
                buttonBgColor={"#000"}
                buttonText="NO"
                buttonClick={() => setShowMarkRead(false)}
              />
              <Button
                buttonBgColor={"#4164E3"}
                buttonTextColor={"#fff"}
                buttonText="YES"
                buttonClick={() => {
                  handleMarkRead();
                }}
              />
            </div>
          </div>
        }
      />

      {/* <ModalWrapper
        isOpen={showMarkRead}
        onClose={() => setShowMarkRead(false)}
        closeBtn={true}
        text={"Mark As Read"}
        ModalTopTextStyle={{
          color: mode ? "red" : "green",
          fontSize: 18,
          fontWeight: 500,
        }}
        children={
          <div className="p-3">
            <h3 className="text-center my-5">Are you sure want to mark read</h3>
            <div className="d-flex justify-content-evenly align-items-center mx-auto w-100 gap-5">
              <Button
                buttonIcon={<Block />}
                buttonBgColor={"#000"}
                buttonTextColor={"#fff"}
                buttonText="NO"
                buttonClick={() => setShowMarkRead(false)}
              />
              <Button
                buttonBgColor={"#4164E3"}
                buttonTextColor={"#fff"}
                buttonText="YES"
                buttonClick={() => {
                  handleMarkRead();
                }}
              />
            </div>
          </div>
        }
      ></ModalWrapper> */}

      <Modal
        show={showAssignManagerModal}
        close={setShowAssignManagerModal}
        closeBtn={true}
        heading="Assign Manager"
        content={
          <div className={styles.text_center}>
            <h3 className="text-center my-5">
              Are you sure want to Assign this Manager
            </h3>
            <div className="d-flex justify-content-between align-items-center mx-auto w-100 mb-4">
              {/* <RedOutButton
                title="NO"
                handleSubmit={() => {
                  setShowAssignManagerModal(false);
                  setSelectManager("");
                }}
              />
              <GreenButton
                title="YES"
                handleSubmit={() => {
                  handleAssignManager();
                }}
              /> */}

              <div className="d-flex justify-content-between align-items-center mx-auto w-100 mb-4 gap-3">
                <Button
                  buttonIcon={<Block />}
                  buttonTextColor={"#fff"}
                  buttonBgColor={"#000"}
                  buttonText="NO"
                  buttonClick={() => {
                    setShowAssignManagerModal(false);
                    setSelectManager("");
                  }}
                />
                <Button
                  buttonBgColor={"#4164E3"}
                  buttonTextColor={"#fff"}
                  buttonText="YES"
                  buttonClick={() => {
                    handleAssignManager();
                  }}
                />
              </div>
            </div>
          </div>
        }
      />
    </React.Fragment>
  );
};

export default MainMobileChats;

const PDFViewer = ({ url }) => {
  return (
    <>
      <div className={styles.pdfView}>
        <object
          className={styles.pdf_container}
          data={`${url}#page=1`}
          type="application/pdf"
          width="50%"
          height="100%"
        >
          <p>
            This browser does not support PDFs. Please download the PDF to view
            it: <a href={url}>Download PDF</a>
          </p>
        </object>
      </div>
    </>
  );
};

const VideoThumbnail = ({ videoUrl }) => {
  const videoRef = useRef(null);
  return (
    <div className={styles.pdfView}>
      <video ref={videoRef} controls>
        <source src={videoUrl} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};
