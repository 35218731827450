
// import React, { useEffect, useState } from 'react';
// import styles from './Camp.module.css';
// import TopTabBar from '../../components/updatedComponents/Tanolet/TopTabBar';
// import { useAuth } from '../../components/context/Auth';
// import Button from '../../components/updatedComponents/Dealers/Button';
// import { Draft, } from '../../components/icons/Icon3';
// import Progress from '../../components/updatedComponents/progress/Progress';
// import InputTag from '../../components/updatedComponents/FormComponents/InputTag';
// import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '../../components/updatedComponents/progress/Accordion';
// import { AddBtnicon, TemplateDone, TemplateNotFound } from '../../components/icons/Icon2';
// import InputTypedate from '../../components/updatedComponents/FormComponents/InputTypedate';
// import { useNavigate, useParams } from 'react-router-dom';
// import InputRedio from '../../components/updatedComponents/FormComponents/InputRedio';
// import TimeOutFetch from '../../APIsControll/TimeOutFechControll';
// import { useToast } from '../../components/updatedComponents/Toaster/Toaster';
// import Skeleton from '../../components/updatedComponents/Skeleton/Skeleton';
// import NoDataFound from '../../components/updatedComponents/NoDataFound/NoDataFound';
// import { changeDateFormat, TextColorCode, truncateText } from '../../Utils/TextUtils';
// import GoToPage from '../../components/updatedComponents/Pagnation/GoToPage';
// import Pagenation from '../../components/updatedComponents/Pagnation/Pagenation';
// import fetchData from '../../APIsControll/apiControll';

// const SingleCampaing = () => {

//     const { mode, managerId } = useAuth();
//     const { show } = useToast();
//     const { campaing } = useParams()
//     const Navigator = useNavigate();
//     const [page, setPage] = useState(0);
//     const [data, setGroupDsta] = useState([]);
//     const [pages, setPages] = useState(0);
//     const [loading, setloading] = useState({
//         loading1: false,
//         loading2: false,
//         loading3: false,
//         loading4: false,
//     });
//     const [GroupDealer, setGroupDealer] = useState([]);
//     const [tempData, settempData] = useState([]);
//     const [inputBox, setInputbox] = useState({
//         campaignName: "",
//         label: "",
//     })
//     const [singleCampaign, setSigleCampange] = useState({})
//     const [isOpenSelectSegment, setIsOpenSelectSegment] = useState(false);
//     const [selectedOption, setSelectedOption] = useState("Select");
//     const handleMouseEnter = () => setIsOpenSelectSegment(true);
//     const handleMouseLeave = () => setIsOpenSelectSegment(false);

//     const [isOpenSelectWhohave, setIsOpenSelectWhohave] = useState(false);
//     const [selectedOptionWhohave, setSelectedOptionWhohave] = useState("Select");
//     const handleMouseEnterWhohave = () => setIsOpenSelectWhohave(true);
//     const handleMouseLeaveWhohave = () => setIsOpenSelectWhohave(false);

//     const [isOpenSelectWhat, setIsOpenSelectWhat] = useState(false);
//     const [selectedOptionWhat, setSelectedOptionWhat] = useState("Select");
//     const handleMouseEnterWhat = () => setIsOpenSelectWhat(true);
//     const handleMouseLeaveWhat = () => setIsOpenSelectWhat(false);



//     // get all groups 
//     const getGroups = async () => {
//         setloading(prve => ({
//             ...prve, loading1: true
//         }))
//         try {
//             const payload = {
//                 managerId: managerId,
//                 keyword: ""
//             }

//             const res = await TimeOutFetch(`wallikonCampaign/groupList?page=${page}`, payload);
//             setloading(prve => ({
//                 ...prve, loading1: false
//             }));
//             if (res.status === "success") {
//                 setGroupDsta(res?.data);
//                 setPages(res?.pages)
//                 setPage(res?.page)
//                 return
//             };
//             if (res.status === "failed") {
//                 setGroupDsta(res?.data);
//                 setPages(0)
//                 setPage(0)
//                 if (res.msg === "Group list not found!") {
//                     return
//                 } else {
//                     show(`${res.msg}`, "error")
//                 }
//             } else {
//                 return show("Something went wrong", "error");

//             };

//         } catch (error) {
//             return show("Something went wrong", "error");
//         }
//     }
//     // get all templets
//     const getAlltemplate = async () => {
//         setloading(prve => ({
//             ...prve, loading2: true
//         }))

//         const response = await fetchData(`wallikonChat/templateList?page=${1}`, {
//             "managerId": managerId,
//             "keyword": '',
//             "startDate": '',
//             "endDate": '',
//             "category": '',
//             "headerType": '',
//             "templateSent": '',
//         })
//         if (response.status === 'success') {
//             // setData(response?.data);
//             settempData(response?.data)
//             setloading(prve => ({
//                 ...prve, loading2: false
//             }))

//             // setPages(response.pages);
//             return
//         }
//         if (response.status === 'failed') {
//             settempData(response?.data)
//             setloading(prve => ({
//                 ...prve, loading2: false
//             }))
//             // setPages(response.pages || 1);
//             return
//         }
//         else {
//             show("Error", "error");
//             setloading(prve => ({
//                 ...prve, loading2: false
//             }));
//             return
//         }
//     }

//     const CreateCampaign = async () => {
//         try {
//             if (selectedOptionWhat === "Select") {
//                 show("Please select a template", "error");
//                 return
//             }
//             if (GroupDealer.length <= 0) {
//                 show("Please select a Group", "error");
//                 return
//             }
//             if (inputBox.campaignName === "" && inputBox.label === "") {
//                 show("Campaign Name and  label is requird", "error");
//                 return
//             }


//             setloading(prve => ({
//                 ...prve, loading3: true
//             }))
//             const payload = {
//                 managerId: managerId,
//                 campaignName: inputBox.campaignName,
//                 label: inputBox.label,
//                 groupName: GroupDealer,
//                 templateName: selectedOptionWhat
//             }
//             const res = await TimeOutFetch(`wallikonCampaign/createCampaign`, payload);
//             console.log(res);
//             if (res.status === 'success') {
//                 show(`${res.msg}`, "success");
//                 setloading(prve => ({
//                     ...prve, loading3: false
//                 }));
//                 Navigator("/campaign");
//                 return
//             }
//             if (res.status === 'failed') {
//                 setloading(prve => ({
//                     ...prve, loading3: false
//                 }));
//                 show(`${res.msg}`, "error");
//                 return
//             }
//             else {
//                 show("Something went wrong", "error");
//                 setloading(prve => ({
//                     ...prve, loading2: false
//                 }));
//                 return
//             }
//         } catch (error) {
//             show("Something went wrong", "error");
//             setloading(prve => ({
//                 ...prve, loading3: false
//             }))
//         }
//     }
//     const getOnecampaing = async () => {
//         try {
//             setloading(prve => ({
//                 ...prve, loading4: true
//             }))
//             const payload = {
//                 managerId: managerId,
//                 campaignName: campaing
//             }
//             const res = await TimeOutFetch(`wallikonCampaign/singleCampaign`, payload);
//             if (res.status === 'success') {
//                 setSigleCampange(res?.data)
//                 setSelectedOptionWhat(res?.data?.templateName);
//                 setInputbox(prev => ({
//                     ...prev, label: res?.data?.label
//                 }))
//                 setGroupDealer(res?.data?.groupName)
//                 setloading(prve => ({
//                     ...prve, loading4: false
//                 }));
//                 return
//             }
//             if (res.status === 'failed') {
//                 setloading(prve => ({
//                     ...prve, loading4: false
//                 }));
//                 show(`${res.msg}`, "error");
//                 return
//             }
//             else {
//                 show("Something went wrong", "error");
//                 setloading(prve => ({
//                     ...prve, loading2: false
//                 }));
//                 return
//             }
//         } catch (error) {
//             show("Something went wrong", "error");
//             setloading(prve => ({
//                 ...prve, loading4: false
//             }))
//         }
//     }



//     useEffect(() => {
//         getAlltemplate();
//         getGroups();
//         getOnecampaing()
//     }, []);




//     const handelChack = (event, item) => {
//         const { groupName, } = item;
//         setGroupDealer(prevGroupDealer => {
//             const haveExect = prevGroupDealer?.some(dealers => dealers === groupName);

//             if (event.target.checked === true) {
//                 if (!haveExect) {
//                     return [...prevGroupDealer, groupName];
//                 }
//             } else {
//                 return prevGroupDealer.filter(dealers => dealers !== groupName);
//             }
//             return prevGroupDealer;
//         });
//     }


//     const option = ["All", "Contacts", "Stockists", "Dealers", "Blacklisted", "Groups", "New Users", "Not Active", "Not Verified", "Contacts", "Purchase", "Not Purchase"]
//     const option1 = ["All", "Duration"]


//     return (
//         <div className={styles.mainCintner}>
//             <div className={styles.Continer} style={{ backgroundColor: mode ? "#2C2E33" : "#F8F9FC" }}>
//                 <div className={styles.goBackCon}  >
//                     <div className={styles.gobacFristChlid1}>
//                         <div>
//                             <TopTabBar text={"Campaigns / New Campaign"} />
//                         </div>
//                         {/* <div style={{ width: "160px" }}>
//                             <Button
//                                 buttonBgColor={"#4164E3"}
//                                 buttonText={"Create Group"}
//                                 buttonIcon={<CreateTem />}
//                                 buttonTextColor={"#fff"}
//                             />
//                         </div> */}
//                     </div>
//                 </div>
//                 <div className={styles.sarchBarConinnter1}>
//                     <div className={styles.sarchBarConinnterFristChild1}>
//                         <div className={styles.sarchconGrop} style={{ alignItems: "center", marginTop: "-30px" }}  >
//                             <div className={styles.sacrchGroup} >
//                                 <Progress progress={10} />
//                             </div>
//                             <div className={styles.groupFilter}>
//                                 {/* <div style={{ width: "130px" }}>
//                                     <Button
//                                         buttonBgColor={"#000"}
//                                         buttonText={"Unsaved"}
//                                         buttonIcon={<Unsaved />}
//                                         buttonTextColor={"#fff"}
//                                     />
//                                 </div> */}
//                                 <div style={{ width: "130px" }}>
//                                     <Button
//                                         buttonBgColor={"#4164E3"}
//                                         buttonText={"Save Draft"}
//                                         buttonIcon={<Draft />}
//                                         buttonTextColor={"#fff"}
//                                         buttonClick={CreateCampaign}
//                                         loading={loading.loading3}
//                                     />
//                                 </div>
//                             </div>
//                         </div>
//                         <div className={styles.sarchconGrop2} style={{ alignItems: "center", }}  >
//                             <div style={{ width: "20%" }}  >
//                                 <InputTag
//                                     labelText={"Name"}
//                                     placeholderText={"Enter Name"}
//                                     required={true}
//                                     placeholderType={"text"}
//                                     maxLength={50}
//                                     // onChange={(event) => setInputbox(prv => ({
//                                     //     ...prv, campaignName: event.target.value
//                                     // }))}
//                                     value={singleCampaign.campaignName}
//                                 />
//                             </div>
//                             <div style={{ width: "20%" }}  >
//                                 <InputTag
//                                     labelText={"Label"}
//                                     placeholderText={"Halloween Offer"}
//                                     required={true}
//                                     placeholderType={"text"}
//                                     maxLength={100}
//                                     onChange={(event) => setInputbox(prv => ({
//                                         ...prv, label: event.target.value
//                                     }))}
//                                     value={inputBox.label}
//                                 />
//                             </div>
//                             {/* <div style={{ width: "20%" }} >
//                                 <InputTag
//                                     labelText={"Created By"}
//                                     placeholderText={"mohit@123@gmail.com"}
//                                     required={true}
//                                     placeholderType={"text"}
//                                 />
//                             </div> */}
//                         </div>
//                     </div>
//                 </div>

//                 <div className={styles.tableContiner1}>
//                     <Accordion type="single" collapsible >
//                         <AccordionItem value="item-1" >
//                             <AccordionTrigger >
//                                 <div className={styles.AccordionTriggerContiner} style={{ backgroundColor: "#1E89C833" }} >
//                                     <div className={styles.Accordioncolor} style={{ backgroundColor: "#1E89C8" }} />
//                                     <div className={styles.AccordionText} >
//                                         <div>
//                                             <span className={styles.AccordionTextFrist} style={{ color: mode ? "#fff" : "#000", }}> Start here </span>
//                                         </div>
//                                     </div>
//                                 </div>
//                             </AccordionTrigger>
//                             <AccordionContent>
//                                 <div className={styles.Start_content_conintner} style={{ backgroundColor: mode ? "#1D1F23" : "#FFFFFF" }} >
//                                     <div className={styles.Start_content_conintnerMain}>
//                                         <div className={styles.Start_content_conintner_header} style={{ backgroundColor: mode ? "#232529" : "#D9DCE5" }} >
//                                             <div className={styles.headIcon}>
//                                                 <div></div>
//                                             </div>
//                                             <div>
//                                                 <span style={{ color: mode ? "#fff" : "#646B88", fontSize: "15px", fontWeight: "500" }}>Qualification Criteria</span>
//                                             </div>
//                                         </div>
//                                         <div className={styles.startContant} style={{ paddingBottom: "10px", backgroundColor: mode ? "#2C2E33" : "transparent", border: mode ? "none" : "1px solid #E4E7EB" }} >
//                                             <div className={styles.startContant_justSent} style={mode ? { backgroundColor: "#232529" } : { border: "1px solid #E4E7EB" }}  >
//                                                 <p style={{ color: mode ? "#fff" : "#000", fontSize: "14px", fontWeight: "500" }}>Just send</p>
//                                                 <p style={{ color: mode ? "#fff" : "#000", fontSize: "14px", fontWeight: "400" }}>Send Message Directly</p>
//                                             </div>
//                                             <div style={{ display: "flex", width: "100%", justifyContent: "flex-end", alignItems: "flex-end" }}>
//                                                 <div style={{ width: "180px", marginRight: "20px", marginBottom: "10px" }}>
//                                                     <Button
//                                                         buttonBgColor={"#4164E3"}
//                                                         buttonIcon={<TemplateDone />}
//                                                         buttonText={"Done"}
//                                                         buttonTextColor={"#fff"}
//                                                     />
//                                                 </div>
//                                             </div>

//                                         </div>
//                                     </div>
//                                 </div>
//                             </AccordionContent>
//                         </AccordionItem>
//                         <AccordionItem value="item-2">
//                             <AccordionTrigger>
//                                 <div className={styles.AccordionTriggerContiner} style={{ backgroundColor: "#8456FF33" }} >
//                                     <div className={styles.Accordioncolor} style={{ backgroundColor: "#8456FF" }} />
//                                     <div className={styles.AccordionText} >
//                                         <div>
//                                             <span className={styles.AccordionTextFrist} style={{ color: mode ? "#fff" : "#000", }}> Who </span>
//                                         </div>
//                                         <div>
//                                             <span className={styles.AccordionTextSecand} style={{ color: mode ? "#fff" : "#000", }}>Select target segment </span>
//                                         </div>
//                                     </div>
//                                 </div>
//                             </AccordionTrigger>
//                             <AccordionContent>
//                                 <div className={styles.Start_content_conintner} style={{ backgroundColor: mode ? "#1D1F23" : "#FFFFFF", position: "relative" }} >
//                                     <div className={styles.who_content_conintnerMain} style={{
//                                         backgroundColor: mode ? "#232529" : "#fff",
//                                         color: mode ? "#fff" : "#000",
//                                         border: mode ? "none" : "1px solid #E4E7EB",
//                                         padding: "10px",
//                                         // minHeight: "300px"
//                                     }}>
//                                         <div className={styles.Who_SelectUser}>
//                                             <div><span style={{ fontSize: "12px" }}  >Find users form segment </span> </div>
//                                             {/* <div className={styles.Who_Select}
//                                                 style={{
//                                                     background: mode ? "#1B1D21" : "#fff",
//                                                     border: mode ? "none" : "1px solid #E4E7EB"
//                                                 }} onClick={() => setIsopenSelect(!isOpeseSelect)} >
//                                                 <div> <span style={{ fontSize: "14px" }}>{SelectOption} </span>  </div>  <div> <span>  <AddBtnicon mode={mode} rotate={isOpeseSelect ? 180 : 0} />   </span> </div>
//                                                 {isOpeseSelect && <div className={styles.Who_selectBox} style={{
//                                                     backgroundColor: mode ? "#000" : "#fff",
//                                                     border: mode ? "none" : "1px solid #E4E7EB",
//                                                     zIndex: "9999"
//                                                 }} >
//                                                     {option.map((e, i) =>
//                                                         <div className={mode ? styles.Who_selectBoxhover_dark : styles.Who_selectBoxhover}
//                                                             key={i}
//                                                             onClick={() => setSelectOption(e)}  > {e} </div>)}
//                                                 </div>
//                                                 }
//                                             </div> */}


//                                             <div className={styles.Who_Select} style={{
//                                                 background: mode ? "#1B1D21" : "#fff",
//                                                 border: mode ? "none" : "1px solid #E4E7EB"
//                                             }}
//                                                 onMouseEnter={handleMouseEnter}
//                                                 onMouseLeave={handleMouseLeave}
//                                             >
//                                                 <div>
//                                                     <span style={{ fontSize: "14px" }}>{selectedOption}</span>
//                                                 </div>
//                                                 <div>
//                                                     <span>
//                                                         <AddBtnicon mode={mode} rotate={isOpenSelectSegment ? 180 : 0} />
//                                                     </span>
//                                                 </div>
//                                                 {isOpenSelectSegment && (
//                                                     <div className={styles.Who_selectBox} style={{
//                                                         backgroundColor: mode ? "#000" : "#fff",
//                                                         border: mode ? "none" : "1px solid #E4E7EB",
//                                                         zIndex: "9999"
//                                                     }} >
//                                                         {option.map((e, i) => (
//                                                             <div className={mode ? styles.Who_selectBoxhover_dark : styles.Who_selectBoxhover} key={i}
//                                                                 onClick={() => {
//                                                                     setSelectedOption(e);
//                                                                     setIsOpenSelectSegment(false);
//                                                                 }} >
//                                                                 {e}
//                                                             </div>
//                                                         ))}
//                                                     </div>
//                                                 )}
//                                             </div>
//                                         </div>

//                                         <div className={styles.Who_SecandSection} style={{ backgroundColor: mode ? "#1B1D21" : "#F8F9FC" }} >
//                                             <div style={{ fontSize: "12px" }}  > Who have done </div>
//                                             <div> </div>
//                                             <div className={styles.Who_Select}
//                                                 style={{
//                                                     background: mode ? "#1B1D21" : "#fff",
//                                                     border: mode ? "none" : "1px solid #E4E7EB"
//                                                 }}
//                                                 onMouseEnter={handleMouseEnterWhohave}
//                                                 onMouseLeave={handleMouseLeaveWhohave}
//                                             >
//                                                 <div> <span style={{ fontSize: "14px" }}>{selectedOptionWhohave} </span>  </div>  <div> <span>  <AddBtnicon mode={mode} rotate={isOpenSelectWhohave ? 180 : 0} />   </span> </div>
//                                                 {isOpenSelectWhohave && <div className={styles.Who_selectBox} style={{
//                                                     backgroundColor: mode ? "#000" : "#fff",
//                                                     border: mode ? "none" : "1px solid #E4E7EB"
//                                                 }} >
//                                                     {option1.map((e, i) => <div className={mode ? styles.Who_selectBoxhover_dark : styles.Who_selectBoxhover} key={i} onClick={() => { setSelectedOptionWhohave(e); setIsOpenSelectWhohave(false) }}  > {e} </div>)}
//                                                 </div>
//                                                 }
//                                             </div>
//                                             <div style={{ fontSize: "12px" }}  >of this event</div>
//                                         </div>

//                                         <div className={styles.Who_dateContiner} >
//                                             <div className={styles.Who_dateContiner1} >
//                                                 <div /* style={mode ? {}:{}}  */>
//                                                     <InputTypedate text={"Form"} />
//                                                 </div>
//                                                 <div>
//                                                     <InputTypedate text={"To"} />
//                                                 </div>
//                                             </div>

//                                         </div>
//                                         <div className={styles.Who_dateContiner2} >
//                                             <div style={{ width: "180px", marginRight: "20px", marginBottom: "20px", float: "right" }}>
//                                                 <Button
//                                                     buttonBgColor={"#4164E3"}
//                                                     buttonIcon={<TemplateDone />}
//                                                     buttonText={"Done"}
//                                                     buttonTextColor={"#fff"}
//                                                 />
//                                             </div>
//                                         </div>
//                                     </div>
//                                     <div className={styles.whou_content_conintnerMain} style={{
//                                         backgroundColor: mode ? "#232529" : "#fff",
//                                         color: mode ? "#fff" : "#000",
//                                         // border: mode ? "none" : "1px solid #E4E7EB",
//                                         padding: "10px",
//                                         marginTop: "20px"
//                                     }}>
//                                         <div className={styles.Who_User} style={{ backgroundColor: mode ? "#000" : "#F8F9FC" }} >
//                                             <div>
//                                                 <span style={{ fontSize: "12px" }} >Group</span>
//                                             </div>
//                                             <div>
//                                                 <span onClick={() => Navigator('/newgroup')}
//                                                     style={{ cursor: "pointer", color: "#4164E3", fontSize: "12px" }}
//                                                 > Create New Group </span>
//                                             </div>
//                                         </div>

//                                         <div className={styles.tableContiner} >
//                                             <div className={styles.container} style={{ height: "100%" }}>
//                                                 <ul className={styles.responsiveTable} style={{ height: "100%" }}>
//                                                     <li className={styles.tableHeader} style={mode ? { backgroundColor: "#000", color: "#fafafa" }
//                                                         : { backgroundColor: "#D9DCE5" }} >
//                                                         <div className={`${styles.col} `} style={{ width: "800px" }} >
//                                                             <div className={styles.TableHeadChackBox} >
//                                                                 {/* <label className={styles.cl_checkbox} >
//                                                                     <input type="checkbox" />
//                                                                     <span></span>
//                                                                 </label> */}
//                                                             </div>
//                                                             Group Name </div>
//                                                         <div className={`${styles.col}`} > <span>Description </span>  </div>
//                                                         <div className={styles.col} >Member</div>
//                                                         <div className={styles.col} >Creation Date</div>
//                                                         <div className={styles.col} >Status</div>
//                                                     </li>
//                                                     {
//                                                         loading?.loading1 ? <>  <Skeleton lines={8} width="100%" thickness={8} height='400px' /> </>
//                                                             : <>
//                                                                 {
//                                                                     data.length === 0 ? <>
//                                                                         <div style={{ height: "90%" }}>
//                                                                             <NoDataFound
//                                                                                 icon={<TemplateNotFound />}
//                                                                                 linkname={"add a template."}
//                                                                                 textTitle={`No Dealers found. To run a WhatsApp campaign`}
//                                                                                 to={"/newgroup"} /> </div>
//                                                                     </> : <>
//                                                                         {
//                                                                             data?.map((e, i) =>
//                                                                                 <li className={styles.tablerow}
//                                                                                     style={mode ? { backgroundColor: "#1B1D21", color: "#fafafa" } : { backgroundColor: "#ffff" }} key={i}
//                                                                                 // onClick={() => navigation(`/newgroup/${e?.groupName}`)}
//                                                                                 >
//                                                                                     <div className={`${styles.col}  `} >
//                                                                                         <div className={styles.TableHeadChackBox} >
//                                                                                             <label className={styles.cl_checkbox} >
//                                                                                                 <input type="checkbox" onChange={(event) => handelChack(event, e)} checked={GroupDealer.includes(e.groupName)} />
//                                                                                                 <span></span>
//                                                                                             </label>
//                                                                                         </div>
//                                                                                         {truncateText(`${e?.groupName}`, 15)}
//                                                                                     </div>
//                                                                                     <div className={`${styles.col} `}  >{truncateText(`${e?.description}`, 25)}</div>
//                                                                                     <div className={styles.col}  >{e?.member}</div>
//                                                                                     <div className={styles.col}  > {changeDateFormat(e?.createdAt, "-")}</div>
//                                                                                     <div className={styles.col} style={{ color: TextColorCode(e?.status) }} >{e?.status} </div>
//                                                                                 </li>
//                                                                             )
//                                                                         }
//                                                                     </>
//                                                                 }
//                                                             </>}
//                                                     <li style={{ height: "20px" }}></li>
//                                                 </ul>
//                                             </div>
//                                         </div>
//                                         <div className={styles.pagnationConintner} style={{ backgroundColor: mode ? "#232529" : "#fff" }}>
//                                             <div className={styles.gotToPage1} >
//                                                 <GoToPage currentPage={page} setCurrentPage={setPage} totalPages={pages} key={"1"} />
//                                             </div>
//                                             <div className={styles.gotToPage2}  >
//                                                 <Pagenation currentPage={page} setCurrentPage={setPage} totalPages={pages} key={"1"} />
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </div>
//                             </AccordionContent>
//                         </AccordionItem>
//                         <AccordionItem value="item-3">
//                             <AccordionTrigger  >
//                                 <div className={styles.AccordionTriggerContiner} style={{ backgroundColor: "#E2749933" }}  >
//                                     <div className={styles.Accordioncolor} style={{ backgroundColor: "#E27499" }} />
//                                     <div className={styles.AccordionText} >
//                                         <div>
//                                             <span className={styles.AccordionTextFrist} style={{ color: mode ? "#fff" : "#000", }}> What </span>
//                                         </div>
//                                         <div>
//                                             <span className={styles.AccordionTextSecand} style={{ color: mode ? "#fff" : "#000", }}> Create Message </span>
//                                         </div>
//                                     </div>
//                                 </div>
//                             </AccordionTrigger>
//                             <AccordionContent>
//                                 <div className={styles.Start_content_conintner}
//                                     style={{ backgroundColor: mode ? "#1D1F23" : "#FFFFFF", position: "relative", minHeight: "500px" }}
//                                 >
//                                     <div className={styles.who_content_conintnerMain} style={{
//                                         backgroundColor: mode ? "#232529" : "#fff",
//                                         color: mode ? "#fff" : "#000",
//                                         border: mode ? "none" : "1px solid #E4E7EB",
//                                         padding: "10px",
//                                         // minHeight: "300px"
//                                     }}>
//                                         <div className={styles.whatMainConitner} >
//                                             <div className={styles.what_MessgeType} > <span> Message Type </span>
//                                             </div>
//                                             <div /* className={styles.what_MessgeType} */ style={{ justifyContent: "flex-end", width: "100%", display: "flex" }} >
//                                                 {/* <Button
//                                                     buttonBgColor={"#000"}
//                                                     buttonText={"Go to Template"}
//                                                     buttonTextColor={"#fff"}
//                                                     buttonIcon={<Unsaved />}
//                                                 /> */}
//                                                 <div>
//                                                     <span onClick={() => Navigator('/newgroup')}
//                                                         style={{ cursor: "pointer", color: "#4164E3", fontSize: "12px" }}
//                                                     > Go to Template </span>
//                                                 </div>

//                                             </div >
//                                         </div>
//                                     </div>

//                                     <div className={styles.redioBtnContiner} style={{ padding: "0px 10px " }} >
//                                         <div>
//                                             <InputRedio
//                                                 id="Select"
//                                                 lableText="Select Message"
//                                                 name="hello"
//                                                 value="Select"
//                                                 // checked={selectedValue === "Marketing"}
//                                                 // onChange={() => handleChangee("Marketing")}
//                                                 required={true}
//                                             />
//                                         </div>
//                                         <div>
//                                             <InputRedio
//                                                 id="Multiple"
//                                                 lableText="Multiple Delivery"
//                                                 name="hello"
//                                                 value="Multiple"
//                                                 // checked={selectedValue === "Marketing"}
//                                                 // onChange={() => handleChangee("Marketing")}
//                                                 required={true}
//                                             />
//                                         </div>
//                                     </div>
//                                     <div>
//                                         <div className={styles.Who_Select1}
//                                             style={{
//                                                 background: mode ? "#232529" : "#fff",
//                                                 border: mode ? "none" : "1px solid #E4E7EB",
//                                                 padding: "0px 20px "

//                                             }} onMouseEnter={handleMouseEnterWhat} onMouseLeave={handleMouseLeaveWhat} >
//                                             <div> <span style={{ fontSize: "14px", color: mode ? "#fff" : "#000" }}>
//                                                 {truncateText(selectedOptionWhat && (selectedOptionWhat.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')), 20)}

//                                             </span>  </div>  <div> <span> {/*  <AddBtnicon mode={mode} rotate={isOpenSelectWhat ? 180 : 0} /> */}   </span> </div>
//                                             {/* {isOpenSelectWhat && <div className={styles.Who_selectBoxWho} style={{
//                                                 backgroundColor: mode ? "#000" : "#fff",
//                                                 border: mode ? "none" : "1px solid #E4E7EB",
//                                                 zIndex: "9999",
//                                                 color: mode ? "#fff" : "#000"
//                                             }} >
//                                                 {tempData.map((e, i) =>
//                                                     <div className={mode ? styles.Who_selectBoxhover_dark : styles.Who_selectBoxhover}
//                                                         key={i}
//                                                         onClick={() => { setSelectedOptionWhat(e?.templateName); setIsOpenSelectWhat(false) }}  >
//                                                         {truncateText(e?.templateName && (e?.templateName.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')), 20)}
//                                                     </div>)}
//                                             </div>
//                                             } */}
//                                         </div>
//                                     </div>

//                                 </div>
//                             </AccordionContent>
//                         </AccordionItem>
//                         <AccordionItem value="item-4">
//                             <AccordionTrigger>
//                                 <div className={styles.AccordionTriggerContiner} style={{ backgroundColor: "#FFB44833" }}  >
//                                     <div className={styles.Accordioncolor} style={{ backgroundColor: "#FFB448" }} />
//                                     <div className={styles.AccordionText} >
//                                         <div>
//                                             <span className={styles.AccordionTextFrist} style={{ color: mode ? "#fff" : "#000", }}  > When </span>
//                                         </div>
//                                         <div>
//                                             <span className={styles.AccordionTextSecand} style={{ color: mode ? "#fff" : "#000", }}> Set Delivery Preferences </span>
//                                         </div>
//                                     </div>
//                                 </div>
//                             </AccordionTrigger>
//                             <AccordionContent>
//                                 <div className={styles.Start_content_conintner}
//                                     style={{ backgroundColor: mode ? "#1D1F23" : "#FFFFFF", position: "relative", minHeight: "300px" }}
//                                 >
//                                     <div className={styles.Start_content_conintner_header} style={{ backgroundColor: mode ? "#232529" : "#D9DCE5" }} >
//                                         <div className={styles.headIcon} style={{ backgroundColor: "#FFB44833" }} >
//                                             <div style={{ backgroundColor: "#FFB448" }} ></div>
//                                         </div>
//                                         <div>
//                                             <span style={{ color: mode ? "#fff" : "#646B88", fontSize: "15px", fontWeight: "500" }}>Date and Time</span>
//                                         </div>
//                                     </div>


//                                     <div className={styles.redioBtnContiner1} style={{ backgroundColor: mode ? "#232529" : "#fff" }}  >
//                                         <div>
//                                             <InputRedio
//                                                 id="Marketing"
//                                                 lableText="Select Message"
//                                                 name="hello"
//                                                 value="Marketing"
//                                                 // checked={selectedValue === "Marketing"}
//                                                 // onChange={() => handleChangee("Marketing")}
//                                                 required={true}
//                                             />
//                                         </div>
//                                         <div>
//                                             <InputRedio
//                                                 id="Marketing"
//                                                 lableText="Schedule for Later"
//                                                 name="hello"
//                                                 value="Marketing"
//                                                 // checked={selectedValue === "Marketing"}
//                                                 // onChange={() => handleChangee("Marketing")}
//                                                 required={true}
//                                             />
//                                         </div>
//                                         <div>
//                                             <InputRedio
//                                                 id="Marketing"
//                                                 lableText="Set as Recurring"
//                                                 name="hello"
//                                                 value="Marketing"
//                                                 // checked={selectedValue === "Marketing"}
//                                                 // onChange={() => handleChangee("Marketing")}
//                                                 required={true}
//                                             />
//                                         </div>
//                                     </div>

//                                 </div>
//                             </AccordionContent>
//                         </AccordionItem>
//                         <AccordionItem value="item-5">
//                             <AccordionTrigger>
//                                 <div className={styles.AccordionTriggerContiner} style={{ backgroundColor: "#7698FF33" }}  >
//                                     <div className={styles.Accordioncolor} style={{ backgroundColor: "#7698FF" }} />
//                                     <div className={styles.AccordionText} >
//                                         <div>
//                                             <span className={styles.AccordionTextFrist} style={{ color: mode ? "#fff" : "#000", }}  > Publish Campaign </span>
//                                         </div>
//                                         {/* <div>
//                                             <span className={styles.AccordionTextSecand} style={{ color: mode ? "#fff" : "#000", }}> Start here </span>
//                                         </div> */}
//                                     </div>
//                                 </div>
//                             </AccordionTrigger>
//                             <AccordionContent>
//                                 Yes. It's animated by default, but you can disable it if you prefer.
//                             </AccordionContent>
//                         </AccordionItem>
//                     </Accordion>

//                 </div>
//                 <div>
//                 </div>


//             </div>
//         </div>
//     )

// }

// export default SingleCampaing




























































import React, { useEffect, useState } from 'react';
import styles from './Camp.module.css';
import TopTabBar from '../../components/updatedComponents/Tanolet/TopTabBar';
import { useAuth } from '../../components/context/Auth';
import Button from '../../components/updatedComponents/Dealers/Button';
import { Draft, } from '../../components/icons/Icon3';
import Progress from '../../components/updatedComponents/progress/Progress';
import InputTag from '../../components/updatedComponents/FormComponents/InputTag';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '../../components/updatedComponents/progress/Accordion';
import { AddBtnicon, TemplateDone, TemplateNotFound } from '../../components/icons/Icon2';
import InputTypedate from '../../components/updatedComponents/FormComponents/InputTypedate';
import { useNavigate, useParams } from 'react-router-dom';
import InputRedio from '../../components/updatedComponents/FormComponents/InputRedio';
import TimeOutFetch from '../../APIsControll/TimeOutFechControll';
import { useToast } from '../../components/updatedComponents/Toaster/Toaster';
import Skeleton from '../../components/updatedComponents/Skeleton/Skeleton';
import NoDataFound from '../../components/updatedComponents/NoDataFound/NoDataFound';
import { changeDateFormat, TextColorCode, truncateText } from '../../Utils/TextUtils';
import GoToPage from '../../components/updatedComponents/Pagnation/GoToPage';
import Pagenation from '../../components/updatedComponents/Pagnation/Pagenation';
import fetchData from '../../APIsControll/apiControll';
import InputTypetime from '../../components/updatedComponents/FormComponents/InputTypetime';
import ModalWrapper from '../../components/updatedComponents/layoutWrapper/ModalWrapper';
import { CrossRedIcon, DoubleCheckIcon } from '../../components/icons/Icon';
const SingleCampaing = () => {
    const { mode, managerId } = useAuth();
    const { show } = useToast();
    const Navigator = useNavigate();
    const { campaing } = useParams()
    const [page, setPage] = useState(0);
    const [data, setGroupDsta] = useState([]);
    const [pages, setPages] = useState(0);
    const [loading, setloading] = useState({
        loading1: false,
        loading2: false,
        loading3: false,
        loading4: false,
    });
    const [GroupDealer, setGroupDealer] = useState([]);
    const [tempData, settempData] = useState([]);
    const [inputBox, setInputbox] = useState({
        campaignName: "",
        label: "",
    });

    const [InstantSendModel, setInstantSendModel] = useState(false);
    const [InstantSendData, setInstantSendData] = useState([]);
    const [InstantSendTemplet, setInstantSendTemplet] = useState([]);
    const today = new Date().toISOString().split('T')[0];
    const [whenRedio, setWhenRedio] = useState("");
    // const [templetData, setempletData] = useState()
    const [isOpenSelectSegment, setIsOpenSelectSegment] = useState(false);
    const [selectedOption, setSelectedOption] = useState("Select");
    const handleMouseEnter = () => setIsOpenSelectSegment(true);
    const handleMouseLeave = () => setIsOpenSelectSegment(false);

    const [isOpenSelectWhohave, setIsOpenSelectWhohave] = useState(false);
    const [selectedOptionWhohave, setSelectedOptionWhohave] = useState("Select");
    const handleMouseEnterWhohave = () => setIsOpenSelectWhohave(true);
    const handleMouseLeaveWhohave = () => setIsOpenSelectWhohave(false);

    // const [isOpenSelectWhat, setIsOpenSelectWhat] = useState(false);
    const [selectedOptionWhat, setSelectedOptionWhat] = useState("Select");
    // const handleMouseEnterWhat = () => setIsOpenSelectWhat(true);
    // const handleMouseLeaveWhat = () => setIsOpenSelectWhat(false);
    const [singleCampaign, setSigleCampange] = useState({})

    const [ScheduledateTime, setScheduledateTime] = useState({
        date: '',
        time: ''
    });

    console.log("singleCampaign", singleCampaign);

    const [dateTimeInputs, setDateTimeInputs] = useState([{ date: '', time: '', error: false }]);

    // console.log("InstantSendTemplet",InstantSendTemplet);

    const addDateTimeInput = () => {
        setDateTimeInputs([...dateTimeInputs, { date: '', time: '', error: false }]);
    };

    const handleDateChange = (index, event) => {
        const newDateTimeInputs = [...dateTimeInputs];
        newDateTimeInputs[index].date = event.target.value;
        newDateTimeInputs[index].error = false;  // Reset error if any field is filled
        setDateTimeInputs(newDateTimeInputs);
    };

    const handleTimeChange = (index, event) => {
        const newDateTimeInputs = [...dateTimeInputs];
        newDateTimeInputs[index].time = event.target.value;
        newDateTimeInputs[index].error = false;  // Reset error if any field is filled
        setDateTimeInputs(newDateTimeInputs);
    };



    // get all groups 
    const getGroups = async () => {
        setloading(prve => ({
            ...prve, loading1: true
        }))
        try {
            const payload = {
                managerId: managerId,
                keyword: ""
            }

            const res = await TimeOutFetch(`wallikonCampaign/groupList?page=${page}`, payload);
            setloading(prve => ({
                ...prve, loading1: false
            }));
            if (res.status === "success") {
                setGroupDsta(res?.data);
                setPages(res?.pages)
                setPage(res?.page)
                return
            };
            if (res.status === "failed") {
                setGroupDsta(res?.data);
                setPages(0)
                setPage(0)
                if (res.msg === "Group list not found!") {
                    return
                } else {
                    show(`${res.msg}`, "error")
                }
            } else {
                return show("Something went wrong", "error");

            };

        } catch (error) {
            return show("Something went wrong", "error");
        }
    };
    // get all templets
    const getAlltemplate = async () => {
        setloading(prve => ({
            ...prve, loading2: true
        }))

        const response = await fetchData(`wallikonChat/templateList?page=${1}`, {
            "managerId": managerId,
            "keyword": '',
            "startDate": '',
            "endDate": '',
            "category": '',
            "headerType": '',
            "templateSent": '',
        })
        if (response.status === 'success') {
            // setData(response?.data);
            settempData(response?.data)
            setloading(prve => ({
                ...prve, loading2: false
            }))

            // setPages(response.pages);
            return
        }
        if (response.status === 'failed') {
            settempData(response?.data)
            setloading(prve => ({
                ...prve, loading2: false
            }))
            // setPages(response.pages || 1);
            return
        }
        else {
            show("Error", "error");
            setloading(prve => ({
                ...prve, loading2: false
            }));
            return
        }
    };

    const CreateCampaign = async () => {
        try {
            if (selectedOptionWhat === "Select") {
                show("Please select a template", "error");
                return
            }
            if (GroupDealer.length <= 0) {
                show("Please select a Group", "error");
                return
            }
            if (inputBox.campaignName === "" && inputBox.label === "") {
                show("Campaign Name and  label is requird", "error");
                return
            }


            setloading(prve => ({
                ...prve, loading3: true
            }))
            const payload = {
                managerId: managerId,
                campaignName: inputBox.campaignName,
                label: inputBox.label,
                groupName: GroupDealer,
                templateName: selectedOptionWhat
            }
            const res = await TimeOutFetch(`wallikonCampaign/createCampaign`, payload);
            console.log(res);
            if (res.status === 'success') {
                show(`${res.msg}`, "success");
                setloading(prve => ({
                    ...prve, loading3: false
                }));
                Navigator("/campaign");
                return
            }
            if (res.status === 'failed') {
                setloading(prve => ({
                    ...prve, loading3: false
                }));
                show(`${res.msg}`, "error");
                return
            }
            else {
                show("Something went wrong", "error");
                setloading(prve => ({
                    ...prve, loading3: false
                }));
                return
            }
        } catch (error) {
            show("Something went wrong", "error");
            setloading(prve => ({
                ...prve, loading3: false
            }))
        }
    };

    const getOnecampaing = async () => {
        try {
            setloading(prve => ({
                ...prve, loading4: true
            }))
            const payload = {
                managerId: managerId,
                campaignName: campaing
            }
            const res = await TimeOutFetch(`wallikonCampaign/singleCampaign`, payload);
            if (res.status === 'success') {
                setSigleCampange(res?.data)
                setSelectedOptionWhat(res?.data?.templateName);
                setInputbox(prev => ({
                    ...prev, label: res?.data?.label
                }))
                setInputbox(prev => ({
                    ...prev, campaignName: res?.data?.campaignName
                }))
                setGroupDealer(res?.data?.groupName)
                setloading(prve => ({
                    ...prve, loading4: false
                }));
                return
            }
            if (res.status === 'failed') {
                setloading(prve => ({
                    ...prve, loading4: false
                }));
                show(`${res.msg}`, "error");
                return
            }
            else {
                show("Something went wrong", "error");
                setloading(prve => ({
                    ...prve, loading2: false
                }));
                return
            }
        } catch (error) {
            show("Something went wrong", "error");
            setloading(prve => ({
                ...prve, loading4: false
            }))
        }
    }


    useEffect(() => {
        getAlltemplate();
        getGroups();
        getOnecampaing()
    }, []);




    const handelChack = (event, item) => {
        const { groupName, } = item;
        setGroupDealer(prevGroupDealer => {
            const haveExect = prevGroupDealer?.some(dealers => dealers === groupName);

            if (event.target.checked === true) {
                if (!haveExect) {
                    return [...prevGroupDealer, groupName];
                }
            } else {
                return prevGroupDealer.filter(dealers => dealers !== groupName);
            }
            return prevGroupDealer;
        });
    };

    const handleSubmit = async () => {
        if (whenRedio === "Message") {
            CreateDraft();
            return
        }
        if (whenRedio === "Recurring") {
            const newDateTimeInputs = dateTimeInputs.map(input => {
                if (!input.date || !input.time) {
                    return { ...input, error: true };
                }
                return { ...input, error: false };
            });
            setDateTimeInputs(newDateTimeInputs);

            if (newDateTimeInputs.some(input => input.error)) {
                show('Please fill in all date and time fields.', 'error');
            } else {
                CreateDraft();
            }
            return
        } else {
            show("Something went wrong", "error");
        }
        if (whenRedio === "Schedule") {
            if (ScheduledateTime.date === '' && ScheduledateTime.time === '') {
                show("Please fill in all date and time fields", "error");
            } else {
                CreateDraft();
            }
        } else {
            show("Something went wrong", "error");
        }
    };

    const CreateDraft = async () => {
        try {
            if (selectedOptionWhat === "Select") {
                show("Please select a template", "error");
                return
            }
            if (GroupDealer.length <= 0) {
                show("Please select a Group", "error");
                return
            }
            if (inputBox.campaignName === "" && inputBox.label === "") {
                show("Campaign Name and  label is requird", "error");
                return
            }
            setloading(prve => ({
                ...prve, loading4: true
            }))
            const payload = {
                managerId: managerId,
                campaignName: inputBox.campaignName,
                label: inputBox.label,
                groupName: GroupDealer,
                templateName: selectedOptionWhat
            }
            const res = await TimeOutFetch(`wallikonCampaign/createCampaign`, payload);
            if (res.status === 'success') {
                await getDealerWhatApp();
                return
            }
            if (res.status === 'failed') {
                setloading(prve => ({
                    ...prve, loading4: false
                }));
                show(`${res.msg}`, "error");
                return
            }
            else {
                show("Something went wrong", "error");
                setloading(prve => ({
                    ...prve, loading4: false
                }));
                return
            }
        } catch (error) {
            show("Something went wrong", "error");
            setloading(prve => ({
                ...prve, loading4: false
            }))
        }
    }


    const getDealerWhatApp = async () => {
        try {
            const payload = {
                managerId: managerId,
                campaignName: inputBox.campaignName,
            }
            const res = await TimeOutFetch(`wallikonCampaign/getDealersWhatsApp`, payload);
            if (res.status === 'success') {
                console.log(res?.data[0]?.dealersWhatsApp);
                // sentTemplettoAllDealers(res?.data[0]?.dealersWhatsApp)
                const dealersWhatsAppNumbers = res.data[0].dealersWhatsApp.map(dealer => dealer.whatsapp);
                setInstantSendData(res?.data[0]?.dealersWhatsApp)
                sentTemplettoAllDealers(dealersWhatsAppNumbers);
                return
            }
            if (res.status === 'failed') {
                setloading(prve => ({
                    ...prve, loading4: false
                }));
                show(`${res.msg}`, "error");
                return
            }
            else {
                show("Something went wrong", "error");
                setloading(prve => ({
                    ...prve, loading4: false
                }));
                return
            }
        } catch (error) {
            show("Something went wrong", "error");
            setloading(prve => ({
                ...prve, loading4: false
            }))
        }
    };


    const createChunks = (array, chunkSize) => {
        return array.reduce((acc, _, i) =>
            (i % chunkSize === 0 ? acc.push(array.slice(i, i + chunkSize)) : acc, acc), []);
    };


    const sentTemplettoAllDealers = async (dealerNo) => {

        if (whenRedio === "Schedule") {
            const chunkSize = 2; // Process in chunks of 5
            let pagenumber = 1;  // Start from page 1

            const chunks = createChunks(dealerNo, chunkSize);
            const filteredSchedules = [{ date: ScheduledateTime.date, time: ScheduledateTime.time }];

            for (const chunk of chunks) {
                try {
                    const payload = {
                        managerId: managerId,
                        dealerPhoneNumber: dealerNo,
                        templateName: selectedOptionWhat,
                        languageCode: singleCampaign?.language,
                        campaignName: inputBox?.campaignName,
                        headerType: singleCampaign?.headerType,
                        headerId: singleCampaign?.headerId,
                        schedules: filteredSchedules
                    };

                    if (singleCampaign.headerType === 'document') {
                        payload.documentFilename = singleCampaign?.documentName;
                    }

                    if (singleCampaign.headerType === 'location') {
                        payload.latitude = "26.8428008";
                        payload.longitude = "80.9359821";
                        payload.locationName = "Wallicon Private Limited";
                        payload.locationAddress = "Wallicon, Burlington, Aminabad Road, Lucknow";
                        // payload.buttonIndex = "1";
                        // payload.buttonDataPayload = "https://dealer.wallicon.in/payment-order/Wal54LhjZqiN14";
                    }

                    // Send request to API with current page number
                    const res = await TimeOutFetch(`wallikonChat/sendTemplateCampaign?page=${pagenumber}`, payload);
                    if (res.status === 'success') {
                        pagenumber++;
                        return
                    } else if (res.status === 'failed') {
                        setloading(prev => ({ ...prev, loading4: false }));
                        show(`${res.msg}`, "error");
                        pagenumber++;
                        return;
                    } else {
                        show("Something went wrong", "error");
                        setloading(prev => ({ ...prev, loading4: false }));
                        pagenumber++;
                        return;
                    }

                } catch (error) {
                    show("Something went wrong", "error");
                    pagenumber++;
                    setloading(prev => ({ ...prev, loading4: false }));
                    return;
                }
            }
        }
        if (whenRedio === "Message") {
            const chunkSize = 2; // Process in chunks of 5
            let pagenumber = 1;  // Start from page 1

            // Use createChunks function to split dealerNo into chunks of specified size
            const chunks = createChunks(dealerNo, chunkSize);

            for (const chunk of chunks) {
                try {
                    const payload = {
                        managerId: managerId,
                        dealerPhoneNumber: dealerNo,
                        templateName: selectedOptionWhat,
                        languageCode: singleCampaign?.language,
                        campaignName: inputBox?.campaignName,
                        headerType: singleCampaign?.headerType,
                        headerId: singleCampaign?.headerId,
                    };

                    if (singleCampaign.headerType === 'document') {
                        payload.documentFilename = singleCampaign?.documentName;
                    }
                    if (singleCampaign.headerType === 'location') {
                        payload.latitude = "26.8428008";
                        payload.longitude = "80.9359821";
                        payload.locationName = "Wallicon Private Limited";
                        payload.locationAddress = "Wallicon, Burlington, Aminabad Road, Lucknow";
                        // payload.buttonIndex = "1";
                        // payload.buttonDataPayload = "https://dealer.wallicon.in/payment-order/Wal54LhjZqiN14";
                    }

                    // Send request to API with current page number
                    const res = await TimeOutFetch(`wallikonChat/sendTemplateCampaign?page=${pagenumber}`, payload);
                    if (res.status === 'success') {

                        if (res?.status === 'success' && Array.isArray(res.results)) {
                            console.log("ringig arr", res.results);

                            setInstantSendTemplet((prevState) => [...prevState, ...res.results]);

                        } else {
                            alert("Failed to add entries to InstantSendTemplet");
                        }

                        if (res.response.sentToAllDealers) {
                            setInstantSendModel(true)
                            // processResult();
                        }
                        pagenumber++;
                        // return
                    } else if (res.status === 'failed') {
                        setloading(prev => ({ ...prev, loading4: false }));
                        show(`${res.msg}`, "error");
                        pagenumber++;
                        return;
                    } else {
                        show("Something went wrong", "error");
                        setloading(prev => ({ ...prev, loading4: false }));
                        pagenumber++;
                        return;
                    }

                } catch (error) {
                    show("Something went wrong", "error");
                    pagenumber++;
                    setloading(prev => ({ ...prev, loading4: false }));
                    return;
                }
            }
        }
        if (whenRedio === "Recurring") {
            const chunkSize = 2; // Process in chunks of 5
            let pagenumber = 1;  // Start from page 1

            // Use createChunks function to split dealerNo into chunks of specified size
            const chunks = createChunks(dealerNo, chunkSize);
            const filteredSchedules = dateTimeInputs.map(({ date, time }) => ({ date, time }));

            for (const chunk of chunks) {
                try {
                    const payload = {
                        managerId: managerId,
                        dealerPhoneNumber: dealerNo,
                        templateName: selectedOptionWhat,
                        languageCode: singleCampaign?.language,
                        campaignName: inputBox?.campaignName,
                        headerType: singleCampaign?.headerType,
                        headerId: singleCampaign?.headerId,
                        schedules: filteredSchedules
                    };

                    if (singleCampaign.headerType === 'document') {
                        payload.documentFilename = singleCampaign?.documentName;
                    }
                    if (singleCampaign.headerType === 'location') {
                        payload.latitude = "26.8428008";
                        payload.longitude = "80.9359821";
                        payload.locationName = "Wallicon Private Limited";
                        payload.locationAddress = "Wallicon, Burlington, Aminabad Road, Lucknow";
                        // payload.buttonIndex = "1";
                        // payload.buttonDataPayload = "https://dealer.wallicon.in/payment-order/Wal54LhjZqiN14";
                    }

                    // Send request to API with current page number
                    const res = await TimeOutFetch(`wallikonChat/sendTemplateCampaign?page=${pagenumber}`, payload);
                    if (res.status === 'success') {
                        pagenumber++;
                    } else if (res.status === 'failed') {
                        setloading(prev => ({ ...prev, loading4: false }));
                        show(`${res.msg}`, "error");
                        pagenumber++;
                        return;
                    } else {
                        show("Something went wrong", "error");
                        setloading(prev => ({ ...prev, loading4: false }));
                        pagenumber++;
                        return;
                    }

                } catch (error) {
                    show("Something went wrong", "error");
                    pagenumber++;
                    setloading(prev => ({ ...prev, loading4: false }));
                    return;
                }
            }
        }
    };

    // const processResult = () => {
    //     if (!InstantSendData || !Array.isArray(InstantSendData)) {
    //         console.error("slicedData is undefined or not an array");
    //         return;
    //       }
    //       const newStatusArray = InstantSendData.map(dealer => {
    //         const foundResult = InstantSendTemplet.find(result => {
    //           const input = result?.data?.contacts?.[0]?.input || result?.data?.to;
    //           return input && (input.toString() === dealer.whatsapp.toString());
    //         });

    //         if (foundResult) {
    //           return {
    //             whatsapp: dealer.whatsapp,
    //             name: dealer.name,
    //             status: foundResult.status
    //           };
    //         } else {
    //           return {
    //             whatsapp: dealer.whatsapp,
    //             name: dealer.name,
    //             status: 'failed'
    //           };
    //         }
    //       });
    //       handleAddTemplateRecord(newStatusArray);
    // }
    // const processResult = () => {
    //     // Ensure InstantSendData is defined and an array
    //     if (!InstantSendData || !Array.isArray(InstantSendData)) {
    //         console.error("InstantSendData is undefined or not an array");
    //         return;
    //     }
    //     console.log("InstantSendData" ,InstantSendData);
    //     console.log("InstantSendTemplet" ,InstantSendTemplet);
    //     const newStatusArray = InstantSendData.map(dealer => {
    //         // Find the corresponding result in InstantSendTemplet
    //         const foundResult = InstantSendTemplet.find(result => {
    //             const input = result?.data?.contacts?.[0]?.input || result?.data?.to;
    //             return input && (input.toString() === dealer.whatsapp.toString());
    //         });

    //         // If a match is found, use its status; otherwise, set status to 'failed'
    //         return {
    //             whatsapp: dealer.whatsapp,
    //             name: dealer.name,
    //             status: foundResult ? foundResult.status : 'failed'
    //         };
    //     });
    //     console.log("newStatusArray" ,newStatusArray);


    //     // Call handleAddTemplateRecord with the new array
    //     handleAddTemplateRecord(newStatusArray);
    // };

    useEffect(() => {
        let count = 1
        if (InstantSendData.length > 0 && InstantSendTemplet.length > 0) {
            if (InstantSendData.length === InstantSendTemplet.length) {
                if (count === 1) {
                    processResult();
                    count++;
                }
            }
        } else {
            console.log("Waiting for both InstantSendData and InstantSendTemplet to be populated...");
        }
    }, [InstantSendData, InstantSendTemplet]);

    const processResult = () => {
        if (!Array.isArray(InstantSendData) || !Array.isArray(InstantSendTemplet)) {
            console.error("InstantSendData or InstantSendTemplet is undefined or not an array");
            return;
        }

        console.log("InstantSendData", InstantSendData);
        console.log("InstantSendTemplet", InstantSendTemplet);

        const newStatusArray = InstantSendData.map(dealer => {
            const foundResult = InstantSendTemplet.find(result => {
                const input = result?.data?.contacts?.[0]?.input || result?.data?.to;
                return input && (input.toString() === dealer.whatsapp.toString());
            });

            return {
                whatsapp: dealer.whatsapp,
                name: dealer.name,
                status: foundResult ? foundResult.status : 'failed'
            };
        });

        console.log("newStatusArray", newStatusArray);

        handleAddTemplateRecord(newStatusArray);
    };




    const handleAddTemplateRecord = (statusInfo) => {
        const myHeaders = new Headers();
        myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            "managerId": managerId,
            "templateName": selectedOptionWhat,
            "statusInfo": statusInfo
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        fetch(process.env.REACT_APP_URL + "wallikonChat/addUpTempstatus", requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.status === 'success') {
                    // setTemplateData(result.data)
                }
            })
            .catch((error) => console.error(error));
    }

    const option = ["All", "Contacts", "Stockists", "Dealers", "Blacklisted", "Groups", "New Users", "Not Active", "Not Verified", "Contacts", "Purchase", "Not Purchase"];
    const option1 = ["All", "Duration"];
    console.log("InstantSendData chack", InstantSendData);
    console.log("InstantSendTemplet chack", InstantSendTemplet);
    return (
        <div className={styles.mainCintner}>
            <ModalWrapper isOpen={InstantSendModel} onClose={() => setInstantSendModel(!InstantSendModel)} >
                <div className={styles.modelCon} style={{ backgroundColor: mode ? "#2C2E33" : "#fff", color: mode ? "#fff" : "#000" }} >
                    <div className={styles.SacandContiner}>
                        <div className={styles.headre}>
                            <div >SN</div>
                            <div style={{ color: "red", marginLeft: "25px" }} >  Number </div>
                            <div style={{ color: "green" }}> Success </div>
                        </div>
                        <div className={styles.bodyContiner} >
                            {
                                InstantSendTemplet.map((e, i) =>
                                    <div className={styles.bodyContiner2}>
                                        <div >{i + 1}</div>
                                        <div> {e?.data?.contacts[0]?.input} </div>
                                        <div> {e?.status === "success" ? <DoubleCheckIcon /> : <CrossRedIcon />}  </div>
                                    </div>
                                )
                            }
                        </div>
                        <div className={styles.btnCon} >
                            <Button buttonBgColor={"#000"} buttonText={"Close"} buttonTextColor={"#fff"} buttonClick={() => setInstantSendModel(false)} />
                        </div>
                    </div>
                </div>
            </ModalWrapper>


            <div className={styles.Continer} style={{ backgroundColor: mode ? "#2C2E33" : "#F8F9FC" }}>
                <div className={styles.goBackCon}  >
                    <div className={styles.gobacFristChlid1}>
                        <div>
                            <TopTabBar text={"Campaigns / New Campaign"} />
                        </div>
                        {/* <div style={{ width: "160px" }}>
                            <Button
                                buttonBgColor={"#4164E3"}
                                buttonText={"Create Group"}
                                buttonIcon={<CreateTem />}
                                buttonTextColor={"#fff"}
                            />
                        </div> */}
                    </div>
                </div>
                <div className={styles.sarchBarConinnter1}>
                    <div className={styles.sarchBarConinnterFristChild1}>
                        <div className={styles.sarchconGrop} style={{ alignItems: "center", marginTop: "-30px" }}  >
                            <div className={styles.sacrchGroup} >
                                <Progress progress={10} />
                            </div>
                            <div className={styles.groupFilter}>
                                {/* <div style={{ width: "130px" }}>
                                    <Button
                                        buttonBgColor={"#000"}
                                        buttonText={"Unsaved"}
                                        buttonIcon={<Unsaved />}
                                        buttonTextColor={"#fff"}
                                    />
                                </div> */}
                                <div style={{ width: "130px" }}>
                                    {
                                        whenRedio === '' &&
                                        <Button
                                            buttonBgColor={"#4164E3"}
                                            buttonText={"Save Draft"}
                                            buttonIcon={<Draft />}
                                            buttonTextColor={"#fff"}
                                            buttonClick={CreateCampaign}
                                            loading={loading.loading3}
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                        <div className={styles.sarchconGrop2} style={{ alignItems: "center", }}  >
                            <div style={{ width: "20%" }}  >
                                <InputTag
                                    labelText={"Name"}
                                    placeholderText={"Enter Name"}
                                    required={true}
                                    placeholderType={"text"}
                                    maxLength={50}
                                    // onChange={(event) => setInputbox(prv => ({
                                    //     ...prv, campaignName: event.target.value
                                    // }))}
                                    value={singleCampaign.campaignName}
                                />
                            </div>
                            <div style={{ width: "20%" }}  >
                                <InputTag
                                    labelText={"Label"}
                                    placeholderText={"Halloween Offer"}
                                    required={true}
                                    placeholderType={"text"}
                                    maxLength={100}
                                    onChange={(event) => setInputbox(prv => ({
                                        ...prv, label: event.target.value
                                    }))}
                                    value={inputBox.label}
                                />
                            </div>
                            {/* <div style={{ width: "20%" }} >
                                <InputTag
                                    labelText={"Created By"}
                                    placeholderText={"mohit@123@gmail.com"}
                                    required={true}
                                    placeholderType={"text"}
                                />
                            </div> */}
                        </div>
                    </div>
                </div>

                <div className={styles.tableContiner1}>
                    <Accordion type="single" collapsible >
                        <AccordionItem value="item-1" >
                            <AccordionTrigger >
                                <div className={styles.AccordionTriggerContiner} style={{ backgroundColor: "#1E89C833" }} >
                                    <div className={styles.Accordioncolor} style={{ backgroundColor: "#1E89C8" }} />
                                    <div className={styles.AccordionText} >
                                        <div>
                                            <span className={styles.AccordionTextFrist} style={{ color: mode ? "#fff" : "#000", }}> Start here </span>
                                        </div>
                                    </div>
                                </div>
                            </AccordionTrigger>
                            <AccordionContent>
                                <div className={styles.Start_content_conintner} style={{ backgroundColor: mode ? "#1D1F23" : "#FFFFFF" }} >
                                    <div className={styles.Start_content_conintnerMain}>
                                        <div className={styles.Start_content_conintner_header} style={{ backgroundColor: mode ? "#232529" : "#D9DCE5" }} >
                                            <div className={styles.headIcon}>
                                                <div></div>
                                            </div>
                                            <div>
                                                <span style={{ color: mode ? "#fff" : "#646B88", fontSize: "15px", fontWeight: "500" }}>Qualification Criteria</span>
                                            </div>
                                        </div>
                                        <div className={styles.startContant} style={{ paddingBottom: "10px", backgroundColor: mode ? "#2C2E33" : "transparent", border: mode ? "none" : "1px solid #E4E7EB" }} >
                                            <div className={styles.startContant_justSent} style={mode ? { backgroundColor: "#232529" } : { border: "1px solid #E4E7EB" }}  >
                                                <p style={{ color: mode ? "#fff" : "#000", fontSize: "14px", fontWeight: "500" }}>Just send</p>
                                                <p style={{ color: mode ? "#fff" : "#000", fontSize: "14px", fontWeight: "400" }}>Send Message Directly</p>
                                            </div>
                                            <div style={{ display: "flex", width: "100%", justifyContent: "flex-end", alignItems: "flex-end" }}>
                                                <div style={{ width: "180px", marginRight: "20px", marginBottom: "10px" }}>
                                                    <Button
                                                        buttonBgColor={"#4164E3"}
                                                        buttonIcon={<TemplateDone />}
                                                        buttonText={"Done"}
                                                        buttonTextColor={"#fff"}
                                                    />
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </AccordionContent>
                        </AccordionItem>
                        <AccordionItem value="item-2">
                            <AccordionTrigger>
                                <div className={styles.AccordionTriggerContiner} style={{ backgroundColor: "#8456FF33" }} >
                                    <div className={styles.Accordioncolor} style={{ backgroundColor: "#8456FF" }} />
                                    <div className={styles.AccordionText} >
                                        <div>
                                            <span className={styles.AccordionTextFrist} style={{ color: mode ? "#fff" : "#000", }}> Who </span>
                                        </div>
                                        <div>
                                            <span className={styles.AccordionTextSecand} style={{ color: mode ? "#fff" : "#000", }}>Select target segment </span>
                                        </div>
                                    </div>
                                </div>
                            </AccordionTrigger>
                            <AccordionContent>
                                <div className={styles.Start_content_conintner} style={{ backgroundColor: mode ? "#1D1F23" : "#FFFFFF", position: "relative" }} >
                                    <div className={styles.who_content_conintnerMain} style={{
                                        backgroundColor: mode ? "#232529" : "#fff",
                                        color: mode ? "#fff" : "#000",
                                        border: mode ? "none" : "1px solid #E4E7EB",
                                        padding: "10px",
                                        // minHeight: "300px"
                                    }}>
                                        <div className={styles.Who_SelectUser}>
                                            <div><span style={{ fontSize: "12px" }}  >Find users form segment </span> </div>
                                            {/* <div className={styles.Who_Select}
                                                style={{
                                                    background: mode ? "#1B1D21" : "#fff",
                                                    border: mode ? "none" : "1px solid #E4E7EB"
                                                }} onClick={() => setIsopenSelect(!isOpeseSelect)} >
                                                <div> <span style={{ fontSize: "14px" }}>{SelectOption} </span>  </div>  <div> <span>  <AddBtnicon mode={mode} rotate={isOpeseSelect ? 180 : 0} />   </span> </div>
                                                {isOpeseSelect && <div className={styles.Who_selectBox} style={{
                                                    backgroundColor: mode ? "#000" : "#fff",
                                                    border: mode ? "none" : "1px solid #E4E7EB",
                                                    zIndex: "9999"
                                                }} >
                                                    {option.map((e, i) =>
                                                        <div className={mode ? styles.Who_selectBoxhover_dark : styles.Who_selectBoxhover}
                                                            key={i}
                                                            onClick={() => setSelectOption(e)}  > {e} </div>)}
                                                </div>
                                                }
                                            </div> */}


                                            <div className={styles.Who_Select} style={{
                                                background: mode ? "#1B1D21" : "#fff",
                                                border: mode ? "none" : "1px solid #E4E7EB"
                                            }}
                                                onMouseEnter={handleMouseEnter}
                                                onMouseLeave={handleMouseLeave}
                                            >
                                                <div>
                                                    <span style={{ fontSize: "14px" }}>{selectedOption}</span>
                                                </div>
                                                <div>
                                                    <span>
                                                        <AddBtnicon mode={mode} rotate={isOpenSelectSegment ? 180 : 0} />
                                                    </span>
                                                </div>
                                                {isOpenSelectSegment && (
                                                    <div className={styles.Who_selectBox} style={{
                                                        backgroundColor: mode ? "#000" : "#fff",
                                                        border: mode ? "none" : "1px solid #E4E7EB",
                                                        zIndex: "9999"
                                                    }} >
                                                        {option.map((e, i) => (
                                                            <div className={mode ? styles.Who_selectBoxhover_dark : styles.Who_selectBoxhover} key={i}
                                                                onClick={() => {
                                                                    setSelectedOption(e);
                                                                    setIsOpenSelectSegment(false);
                                                                }} >
                                                                {e}
                                                            </div>
                                                        ))}
                                                    </div>
                                                )}
                                            </div>
                                        </div>

                                        <div className={styles.Who_SecandSection} style={{ backgroundColor: mode ? "#1B1D21" : "#F8F9FC" }} >
                                            <div style={{ fontSize: "12px" }}  > Who have done </div>
                                            <div> </div>
                                            <div className={styles.Who_Select}
                                                style={{
                                                    background: mode ? "#1B1D21" : "#fff",
                                                    border: mode ? "none" : "1px solid #E4E7EB"
                                                }}
                                                onMouseEnter={handleMouseEnterWhohave}
                                                onMouseLeave={handleMouseLeaveWhohave}
                                            >
                                                <div> <span style={{ fontSize: "14px" }}>{selectedOptionWhohave} </span>  </div>  <div> <span>  <AddBtnicon mode={mode} rotate={isOpenSelectWhohave ? 180 : 0} />   </span> </div>
                                                {isOpenSelectWhohave && <div className={styles.Who_selectBox} style={{
                                                    backgroundColor: mode ? "#000" : "#fff",
                                                    border: mode ? "none" : "1px solid #E4E7EB"
                                                }} >
                                                    {option1.map((e, i) => <div className={mode ? styles.Who_selectBoxhover_dark : styles.Who_selectBoxhover} key={i} onClick={() => { setSelectedOptionWhohave(e); setIsOpenSelectWhohave(false) }}  > {e} </div>)}
                                                </div>
                                                }
                                            </div>
                                            <div style={{ fontSize: "12px" }}  >of this event</div>
                                        </div>

                                        <div className={styles.Who_dateContiner} >
                                            <div className={styles.Who_dateContiner1} >
                                                <div /* style={mode ? {}:{}}  */>
                                                    <InputTypedate text={"Form"} />
                                                </div>
                                                <div>
                                                    <InputTypedate text={"To"} />
                                                </div>
                                            </div>

                                        </div>
                                        <div className={styles.Who_dateContiner2} >
                                            <div style={{ width: "180px", marginRight: "20px", marginBottom: "20px", float: "right" }}>
                                                <Button
                                                    buttonBgColor={"#4164E3"}
                                                    buttonIcon={<TemplateDone />}
                                                    buttonText={"Done"}
                                                    buttonTextColor={"#fff"}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className={styles.whou_content_conintnerMain} style={{
                                        backgroundColor: mode ? "#232529" : "#fff",
                                        color: mode ? "#fff" : "#000",
                                        // border: mode ? "none" : "1px solid #E4E7EB",
                                        padding: "10px",
                                        marginTop: "20px"
                                    }}>
                                        <div className={styles.Who_User} style={{ backgroundColor: mode ? "#000" : "#F8F9FC" }} >
                                            <div>
                                                <span style={{ fontSize: "12px" }} >Group</span>
                                            </div>
                                            <div>
                                                <span onClick={() => Navigator('/newgroup')}
                                                    style={{ cursor: "pointer", color: "#4164E3", fontSize: "12px" }}
                                                > Create New Group </span>
                                            </div>
                                        </div>

                                        <div className={styles.tableContiner} >
                                            <div className={styles.container} style={{ height: "100%" }}>
                                                <ul className={styles.responsiveTable} style={{ height: "100%" }}>
                                                    <li className={styles.tableHeader} style={mode ? { backgroundColor: "#000", color: "#fafafa" }
                                                        : { backgroundColor: "#D9DCE5" }} >
                                                        <div className={`${styles.col} `} style={{ width: "800px" }} >
                                                            <div className={styles.TableHeadChackBox} >
                                                                {/* <label className={styles.cl_checkbox} >
                                                                    <input type="checkbox" />
                                                                    <span></span>
                                                                </label> */}
                                                            </div>
                                                            Group Name </div>
                                                        <div className={`${styles.col}`} > <span>Description </span>  </div>
                                                        <div className={styles.col} >Member</div>
                                                        <div className={styles.col} >Creation Date</div>
                                                        <div className={styles.col} >Status</div>
                                                    </li>
                                                    {
                                                        loading?.loading1 ? <>  <Skeleton lines={8} width="100%" thickness={8} height='400px' /> </>
                                                            : <>
                                                                {
                                                                    data.length === 0 ? <>
                                                                        <div style={{ height: "90%" }}>
                                                                            <NoDataFound
                                                                                icon={<TemplateNotFound />}
                                                                                linkname={"add a template."}
                                                                                textTitle={`No Dealers found. To run a WhatsApp campaign`}
                                                                                to={"/newgroup"} /> </div>
                                                                    </> : <>
                                                                        {
                                                                            data?.map((e, i) =>
                                                                                <li className={styles.tablerow}
                                                                                    style={mode ? { backgroundColor: "#1B1D21", color: "#fafafa" } : { backgroundColor: "#ffff" }} key={i}
                                                                                // onClick={() => navigation(`/newgroup/${e?.groupName}`)}
                                                                                >
                                                                                    <div className={`${styles.col}  `} >
                                                                                        <div className={styles.TableHeadChackBox} >
                                                                                            <label className={styles.cl_checkbox} >
                                                                                                <input type="checkbox" onChange={(event) => handelChack(event, e)} />
                                                                                                <span></span>
                                                                                            </label>
                                                                                        </div>
                                                                                        {truncateText(`${e?.groupName}`, 15)}
                                                                                    </div>
                                                                                    <div className={`${styles.col} `}  >{truncateText(`${e?.description}`, 25)}</div>
                                                                                    <div className={styles.col}  >{e?.member}</div>
                                                                                    <div className={styles.col}  > {changeDateFormat(e?.createdAt, "-")}</div>
                                                                                    <div className={styles.col} style={{ color: TextColorCode(e?.status) }} >{e?.status} </div>
                                                                                </li>
                                                                            )
                                                                        }
                                                                    </>
                                                                }
                                                            </>}
                                                    <li style={{ height: "20px" }}></li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className={styles.pagnationConintner} style={{ backgroundColor: mode ? "#232529" : "#fff" }}>
                                            <div className={styles.gotToPage1} >
                                                <GoToPage currentPage={page} setCurrentPage={setPage} totalPages={pages} key={"1"} />
                                            </div>
                                            <div className={styles.gotToPage2}  >
                                                <Pagenation currentPage={page} setCurrentPage={setPage} totalPages={pages} key={"1"} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </AccordionContent>
                        </AccordionItem>
                        <AccordionItem value="item-3">
                            <AccordionTrigger  >
                                <div className={styles.AccordionTriggerContiner} style={{ backgroundColor: "#E2749933" }}  >
                                    <div className={styles.Accordioncolor} style={{ backgroundColor: "#E27499" }} />
                                    <div className={styles.AccordionText} >
                                        <div>
                                            <span className={styles.AccordionTextFrist} style={{ color: mode ? "#fff" : "#000", }}> What </span>
                                        </div>
                                        <div>
                                            <span className={styles.AccordionTextSecand} style={{ color: mode ? "#fff" : "#000", }}> Create Message </span>
                                        </div>
                                    </div>
                                </div>
                            </AccordionTrigger>
                            <AccordionContent>
                                <div className={styles.Start_content_conintner}
                                    style={{ backgroundColor: mode ? "#1D1F23" : "#FFFFFF", position: "relative", minHeight: "500px" }}
                                >
                                    <div className={styles.who_content_conintnerMain} style={{
                                        backgroundColor: mode ? "#232529" : "#fff",
                                        color: mode ? "#fff" : "#000",
                                        border: mode ? "none" : "1px solid #E4E7EB",
                                        padding: "10px",
                                        // minHeight: "300px"
                                    }}>
                                        <div className={styles.whatMainConitner} >
                                            <div className={styles.what_MessgeType} > <span> Message Type </span>
                                            </div>
                                            <div /* className={styles.what_MessgeType} */ style={{ justifyContent: "flex-end", width: "100%", display: "flex" }} >
                                                {/* <Button
                                                    buttonBgColor={"#000"}
                                                    buttonText={"Go to Template"}
                                                    buttonTextColor={"#fff"}
                                                    buttonIcon={<Unsaved />}
                                                /> */}
                                                <div>
                                                    <span onClick={() => Navigator('/templates/template')}
                                                        style={{ cursor: "pointer", color: "#4164E3", fontSize: "12px" }}
                                                    > Go to Template </span>
                                                </div>

                                            </div >
                                        </div>
                                    </div>

                                    <div className={styles.redioBtnContiner} style={{ padding: "0px 10px " }} >
                                        <div>
                                            <InputRedio
                                                id="Select"
                                                lableText="Select Message"
                                                name="hello"
                                                value="Select"
                                                // checked={selectedValue === "Marketing"}
                                                // onChange={() => handleChangee("Marketing")}
                                                required={true}
                                            />
                                        </div>
                                        <div>
                                            <InputRedio
                                                id="Multiple"
                                                lableText="Multiple Delivery"
                                                name="hello"
                                                value="Multiple"
                                                // checked={selectedValue === "Marketing"}
                                                // onChange={() => handleChangee("Marketing")}
                                                required={true}
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <div className={styles.Who_Select1}
                                            style={{
                                                background: mode ? "#232529" : "#fff",
                                                border: mode ? "none" : "1px solid #E4E7EB",
                                                padding: "0px 20px "

                                            }} /* onMouseEnter={handleMouseEnterWhat} onMouseLeave={handleMouseLeaveWhat} */ >
                                            <div> <span style={{ fontSize: "14px", color: mode ? "#fff" : "#000" }}>
                                                {truncateText(selectedOptionWhat && (selectedOptionWhat.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')), 20)}

                                            </span>  </div>  <div> <span>  {/* <AddBtnicon mode={mode} rotate={isOpenSelectWhat ? 180 : 0} /> */}   </span> </div>
                                            {/* {isOpenSelectWhat && <div className={styles.Who_selectBoxWho} style={{
                                                backgroundColor: mode ? "#000" : "#fff",
                                                border: mode ? "none" : "1px solid #E4E7EB",
                                                zIndex: "9999",
                                                color: mode ? "#fff" : "#000",
                                                maxHeight: "300px",
                                                overflowY: "scroll"
                                            }} >
                                                {tempData.map((e, i) =>
                                                    <div className={mode ? styles.Who_selectBoxhover_dark : styles.Who_selectBoxhover}
                                                        key={i}
                                                        onClick={() => {
                                                            setSelectedOptionWhat(e?.templateName);
                                                            setempletData(e);
                                                            setIsOpenSelectWhat(false);
                                                        }}  >
                                                        {truncateText(e?.templateName && (e?.templateName.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')), 20)}
                                                    </div>)}
                                            </div>
                                            } */}
                                        </div>
                                    </div>
                                </div>
                            </AccordionContent>
                        </AccordionItem>
                        <AccordionItem value="item-4">
                            <AccordionTrigger>
                                <div className={styles.AccordionTriggerContiner} style={{ backgroundColor: "#FFB44833" }}  >
                                    <div className={styles.Accordioncolor} style={{ backgroundColor: "#FFB448" }} />
                                    <div className={styles.AccordionText} >
                                        <div>
                                            <span className={styles.AccordionTextFrist} style={{ color: mode ? "#fff" : "#000", }}  > When </span>
                                        </div>
                                        <div>
                                            <span className={styles.AccordionTextSecand} style={{ color: mode ? "#fff" : "#000", }}> Set Delivery Preferences </span>
                                        </div>
                                    </div>
                                </div>
                            </AccordionTrigger>
                            <AccordionContent>
                                <div className={styles.Start_content_conintner}
                                    style={{ backgroundColor: mode ? "#1D1F23" : "#FFFFFF", position: "relative", minHeight: "400px" }}
                                >
                                    <div className={styles.Start_content_conintner_header} style={{ backgroundColor: mode ? "#232529" : "#D9DCE5" }} >
                                        <div className={styles.headIcon} style={{ backgroundColor: "#FFB44833" }} >
                                            <div style={{ backgroundColor: "#FFB448" }} ></div>
                                        </div>
                                        <div>
                                            <span style={{ color: mode ? "#fff" : "#646B88", fontSize: "15px", fontWeight: "500" }}>Date and Time</span>
                                        </div>
                                    </div>


                                    <div className={styles.redioBtnContiner1} style={{ backgroundColor: mode ? "#232529" : "#fff" }}  >
                                        <div>
                                            <InputRedio
                                                id="Marketing"
                                                lableText="Instant sending"
                                                name="hello"
                                                value="Marketing"
                                                // checked={selectedValue === "Marketing"}
                                                onChange={() => setWhenRedio("Message")}
                                                required={true}
                                            />
                                        </div>
                                        <div>
                                            <InputRedio
                                                id="Marketing"
                                                lableText="Schedule for Later"
                                                name="hello"
                                                value="Marketing"
                                                // checked={selectedValue === "Marketing"}
                                                onChange={() => setWhenRedio("Schedule")}
                                                required={true}
                                            />
                                        </div>
                                        {
                                            whenRedio === "Schedule" &&
                                            <div style={{ width: "100%", display: "flex", alignItems: "center", gap: "10px" }} >
                                                <div>
                                                    <InputTypedate
                                                        text={"From"}
                                                        onChange={(event) => setScheduledateTime(prev => ({
                                                            ...prev, date: event.target.value
                                                        }))}
                                                        min={today}
                                                    />
                                                </div>
                                                <div>
                                                    <InputTypetime
                                                        text={"From"}
                                                        onChange={(event) => setScheduledateTime(prev => ({
                                                            ...prev, time: event.target.value
                                                        }))}
                                                    />
                                                </div>
                                            </div>
                                        }
                                        <div>
                                            <InputRedio
                                                id="Marketing"
                                                lableText="Set as Recurring"
                                                name="hello"
                                                value="Marketing"
                                                // checked={selectedValue === "Marketing"}
                                                onChange={() => setWhenRedio("Recurring")}
                                                required={true}
                                            />
                                        </div>
                                        {
                                            whenRedio === "Recurring" &&
                                            <div style={{ width: "100%", display: "flex", flexDirection: "row", gap: "10px", flexWrap: "wrap", }}>
                                                {dateTimeInputs.map((input, index) => (
                                                    <div key={index} style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                                        <div style={{ display: "flex", flexDirection: "column", height: "80px", }}>
                                                            <div>
                                                                <InputTypedate
                                                                    value={input.date}
                                                                    onChange={(event) => handleDateChange(index, event)}
                                                                    min={today}
                                                                />
                                                            </div>
                                                            <div>
                                                                {input.error && !input.date && (
                                                                    <span style={{ color: "red", fontSize: "11px", fontWeight: "300" }}>Please select a date.</span>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div style={{ display: "flex", flexDirection: "column", height: "80px", }} >
                                                            <div  >
                                                                <InputTypetime
                                                                    text="At"
                                                                    value={input.time} // Set value from state
                                                                    onChange={(event) => handleTimeChange(index, event)} // Update on change
                                                                />
                                                            </div>
                                                            <div>
                                                                {input.error && !input.time && (
                                                                    <span style={{ color: "red", fontSize: "11px", fontWeight: "300" }}>Please select a time.</span>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                                {dateTimeInputs.length > 3 ? null :
                                                    <div>
                                                        <Button
                                                            buttonBgColor={"#4164E3"}
                                                            buttonText={" + "}
                                                            buttonTextColor={"#fff"}
                                                            buttonClick={addDateTimeInput}

                                                        />
                                                    </div>
                                                }
                                            </div>
                                        }
                                        <div style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>

                                            <div style={{ width: "170px" }}>
                                                <Button
                                                    buttonBgColor={"#4164E3"}
                                                    buttonText={"Reset"}
                                                    buttonTextColor={"#fff"}
                                                    buttonClick={() => setWhenRedio("")}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </AccordionContent>
                        </AccordionItem>
                        <AccordionItem value="item-5">
                            <AccordionTrigger>
                                <div className={styles.AccordionTriggerContiner} style={{ backgroundColor: "#7698FF33" }}  >
                                    <div className={styles.Accordioncolor} style={{ backgroundColor: "#7698FF" }} />
                                    <div className={styles.AccordionText} >
                                        <div>
                                            <span className={styles.AccordionTextFrist} style={{ color: mode ? "#fff" : "#000", }} > Publish Campaign </span>
                                        </div>
                                        {/* <div>
                                            <span className={styles.AccordionTextSecand} style={{ color: mode ? "#fff" : "#000", }}> Start here </span>
                                        </div> */}
                                    </div>
                                </div>
                            </AccordionTrigger>
                            <AccordionContent>
                                <div className={styles.Start_content_conintner}
                                    style={{ backgroundColor: mode ? "#1D1F23" : "#FFFFFF", position: "relative", minHeight: "200px" }}  >
                                    <div className={styles.redioBtnContiner1} style={{ backgroundColor: mode ? "#232529" : "#fff" }}  >
                                        <div style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
                                            <div style={{ width: "180px" }} >
                                                <Button
                                                    buttonBgColor={"#4164E3"}
                                                    buttonText={"Publish"}
                                                    buttonIcon={<TemplateDone />}
                                                    buttonTextColor={"#fff"}
                                                    buttonClick={handleSubmit}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </AccordionContent>
                        </AccordionItem>
                    </Accordion>

                </div>

            </div>
        </div>
    )
}

export default SingleCampaing