import React, { lazy, Suspense, useState, useEffect } from "react";
import styles from "./Manager.module.css";
import { useAuth } from "../../context/Auth";

const Button = lazy(() => import("../Dealers/Button"));

const ManagerModal = ({
  handleCloseModal,
  heading,
  centerHeading,
  subHeading,
  handleSubmit,
  targetWord,
}) => {
  const { mode } = useAuth();
  const [confirmationText, setConfirmationText] = useState("");
  const [textMismatch, setTextMismatch] = useState("");

  const cH = centerHeading?.split(new RegExp(`(${targetWord})`, "gi"));
  const sH = subHeading?.split(new RegExp(`(${targetWord})`, "gi"));

  const getColor = (word) => {
    switch (word) {
      case "logout":
        return "#F93E39";
      case "update":
        return "#4164E3";
      case "create":
        return "#34A853";
      case "order":
        return "#FF8000";
      default:
        return "#000";
    }
  };

  const color = getColor(targetWord);

  useEffect(() => {
    setTextMismatch("");
    setConfirmationText("");
  }, []);

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (targetWord === "order" && event.key.toLowerCase() === "y") {
        handleSubmit();
        handleCloseModal();
      }
      if (targetWord === "order" && event.key.toLowerCase() === "n") {
        handleCloseModal();
      }
    };

    window.addEventListener("keydown", handleKeyPress);

    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleCloseModal, handleSubmit, targetWord]);

  const handleModalSubmit = async () => {
    if (confirmationText === targetWord) {
      setTextMismatch("");
      setConfirmationText("");
      try {
        await handleSubmit();
        handleCloseModal(true);
      } catch (error) {
        console.error("Submission failed:", error);
      }
    } else {
      setTextMismatch("Please! Enter the same text.");
      setConfirmationText("");
    }
  };

  const handleClose = () => {
    setConfirmationText("");
    handleCloseModal();
    setTextMismatch("");
  };

  return (
    <div
      className={styles.device_logout}
      style={{ backgroundColor: mode ? "#232529" : "#fff" }}
    >
      <p
        className={styles.device_logout_bold_text}
        style={{ alignSelf: "flex-start", color: mode ? "#ffffff" : "#000" }}
      >
        {heading}
      </p>
      <img src="/assets/updatedAssets/logout_img.png" alt="" />

      <p
        className={`${styles.device_logout_bold_text}`}
        style={{ color: mode ? "#ffffff" : "#000" }}
      >
        {cH?.map((part, index) =>
          part?.toLowerCase() === targetWord?.toLowerCase() ? (
            <span key={index} style={{ color }}>
              {part}
            </span>
          ) : (
            <span key={index}>{part}</span>
          )
        )}
      </p>

      {targetWord !== "order" && (
        <p
          className={`${styles.device_logout_light_text}`}
          style={{ color: mode ? "#ffffff" : "#000" }}
        >
          {sH?.map((part, index) =>
            part?.toLowerCase() === targetWord?.toLowerCase() ? (
              <span key={index} style={{ color }}>
                {part}
              </span>
            ) : (
              <span key={index}>{part}</span>
            )
          )}
        </p>
      )}

      {targetWord !== "order" && (
        <>
          <div
            className={styles.enter_input}
            style={mode ? { backgroundColor: "#1B1D21", border: "none" } : {}}
          >
            <input
              type="text"
              placeholder="Enter"
              value={confirmationText}
              onChange={(e) => setConfirmationText(e.target.value)}
              style={
                mode ? { backgroundColor: "#1B1D21", color: "#919EC3" } : {}
              }
            />
          </div>

          {textMismatch && (
            <p style={{ color: "#F93E3E", fontSize: "12px" }}>{textMismatch}</p>
          )}
        </>
      )}

      <div className={styles.bottom_buttons}>
        <div style={{ width: "30%" }}>
          <Suspense fallback={<div>Loading...</div>}>
            <Button
              buttonText={"No"}
              buttonBgColor={"#000000"}
              buttonTextColor={"#ffffff"}
              buttonBorder={"none"}
              buttonClick={handleClose}
              tabIndex={-1}
            />
          </Suspense>
          {targetWord === "order" && (
            <p
              className={styles.btn_text}
              style={mode ? { color: "#FFFFFF" } : {}}
            >
              Press - N
            </p>
          )}
        </div>
        <div style={{ width: "30%" }}>
          <Suspense fallback={<div>Loading...</div>}>
            <Button
              buttonText={"Yes"}
              buttonBgColor={"#4164E3"}
              buttonTextColor={"#ffffff"}
              buttonBorder={"none"}
              buttonClick={handleModalSubmit}
              tabIndex={-1}
            />
          </Suspense>
          {targetWord === "order" && (
            <p
              className={styles.btn_text}
              style={mode ? { color: "#FFFFFF" } : {}}
            >
              Press - Y
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default ManagerModal;
