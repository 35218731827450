import "./App.css";
import NewManager from "./components/updatedComponents/Manager/NewManager";
import React, { useEffect, useRef, useState } from "react";
import {
  BrowserRouter,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { useAuth } from "./components/context/Auth";
import AddProduct from "./pages/products/AddProduct";
import SuperAdmin from "./pages/dashboard/SuperAdmin";
import Kyc from "./pages/kyc/Kyc";
import Dealers from "./pages/kyc/Dealers";
import Products from "./pages/products/Products";
import ViewProduct from "./pages/products/ViewProduct";
import Items from "./pages/products/Items";
import AddItem from "./pages/products/AddItem";
import Marketing from "./pages/marketing/Marketing";
import MarketingLead from "./pages/marketing/MarketingLead";
import MarketingDashboard from "./pages/marketing/MarketingDashboard";
import Home from "./pages/Home";
import ContentDashboard from "./pages/dashboard/ContentDashboard";
import OrderDashboard from "./pages/dashboard/OrderDashboard";
import InventoryDashboard from "./pages/dashboard/InventoryDashboard";
import DispatchDasboard from "./pages/dashboard/DispatchDasboard";
import Contacts from "./pages/marketing/Contacts";
import Leads from "./pages/marketing/Leads";
import Orders from "./pages/ordermanager/Orders";
import SaleOrder from "./pages/ordermanager/SaleOrder";
import SaleOrderInovice from "./pages/invoices/SaleOrderInovice";
import Inventory from "./pages/inventorymanager/Inventory";
import InventoryCollections from "./pages/inventorymanager/InventoryCollections";
import CollectionInfo from "./pages/inventorymanager/CollectionInfo";
import Purchase from "./pages/inventorymanager/Purchase";
import OrderDealers from "./pages/ordermanager/OrderDealers";
import Adjustment from "./pages/inventorymanager/Adjustment";
import PurchaseInovice from "./pages/invoices/PurchaseInvoice";
import AdjustmentOrders from "./pages/inventorymanager/AdjustmentOrders";
import PartnerOrders from "./pages/ordermanager/PartnerOrders";
import PurchaseOrders from "./pages/inventorymanager/PurchaseOrders";
import AdjustmentInvoice from "./pages/invoices/AdjustmentInvoice";
import DispatchOrders from "./pages/dispatchmanager/DispatchOrders";
import PackingVoucher from "./pages/dispatchmanager/PackingVoucher";
import Packers from "./pages/dispatchmanager/Packers";
import Packer from "./pages/dispatchmanager/Packer";
import CreatePackers from "./pages/dispatchmanager/CreatePackers";
import PackingOrders from "./pages/packingmanager/PackingOrders";
import PackingOrder from "./pages/packingmanager/PackingOrder";
import Estimate from "./pages/ordermanager/Estimate";
import TransferStock from "./pages/inventorymanager/TransferStock";
import EstimateOrders from "./pages/ordermanager/EstimateOrders";
import TransferOrders from "./pages/inventorymanager/TransferOrders";
import Managers from "./pages/managers/Managers";
import AddManager from "./pages/managers/AddManager";
import Complaints from "./pages/superadmin/Complaints";
import AllOrders from "./pages/ordermanager/AllOrders";
import Recipts from "./pages/superadmin/Recipts";
import EstimateInvoice from "./pages/invoices/EstimateInvoice";
import Accounts from "./pages/superadmin/Accounts";
import DispatchDasboardMob from "./mobile/pages/DispatchDasboardMob";
import DispatchOrder from "./mobile/pages/DispatchOrder";
import Delivery from "./pages/superadmin/Delivery";
import MobAllOrders from "./mobile/pages/MobAllOrders";
import SaleOrderMob from "./mobile/pages/SaleOrderMob";
import OutSideInventory from "./pages/inventorymanager/OutSideInventory";
import OutSideIvtColl from "./pages/inventorymanager/OutSideIvtColl";
import OutSideIvtCollInfo from "./pages/inventorymanager/OutSideIvtCollInfo";
import MobEstimate from "./mobile/pages/MobEstimate";
import MobEstimateOrders from "./mobile/pages/MobEstimateOrders";
import MobPurchaseOrders from "./mobile/pages/MobPurchaseOrders";
import MobPurchase from "./mobile/pages/MobPurchase";
import ViewDispatchDetailsMob from "./mobile/pages/ViewDispatchDetailsMob";
import ViewDispatchDetails from "./pages/dispatchmanager/ViewDispatchDetails";
// import InventoryMob from "./mobile/pages/InventoryMob";
// import InventoryCollectionsMob from "./mobile/pages/InventoryCollectionsMob";
// import CollectionInfoMob from "./mobile/pages/CollectionInfoMob";
import OutSideInventoryMob from "./mobile/pages/OutSideInventoryMob";
import OutSideIvtCollMob from "./mobile/pages/OutSideIvtCollMob";
import OutSideIvtCollInfomob from "./mobile/pages/OutSideIvtCollInfomob";
import TransferOrdersMob from "./mobile/pages/TransferOrdersMob";
import ReciptsMob from "./mobile/pages/ReciptsMob";
import Warehouse from "./pages/superadmin/Warehouse";
import EditDelete from "./pages/editDelete/EditDelete";
import Invoices from "./pages/superadmin/Invoices";
import SaleInvoice from "./pages/superadmin/SaleInvoice";
import SearchItem from "./pages/superadmin/SearchItem";
import AddPartner from "./pages/superadmin/AddPartner";
import UploadInvoice from "./pages/superadmin/uploadinvoice";
import Templates from "./pages/template/Templates";
import CreateTemplate from "./pages/template/CreateTemplate";
import CryptoJS from "crypto-js";
import TaxRate from "./pages/inventorymanager/TaxRate";
import Test from "./components/updatedComponents/Test/Test";
import { io } from "socket.io-client";
import NavLayout from "./components/updatedComponents/Navbar/NavLayout";
import MainWhatsappChat from "./components/updatedComponents/WhatsappChat/MainWhasappChat";
import MainMobileChats from "./components/updatedComponents/WhatsappChat/MainMobileChats";
import fetchData from "./APIsControll/apiControll";
import { debounce } from "./Utils/TextUtils";
import TestLink from "./components/updatedComponents/WhatsappChat/TestLink";
import SingleTemp from "./pages/template/SingleTemp";
import Campaign from "./pages/template/Campaign";
import CreateGroup from "./pages/template/CreateGroup";
import Groups from "./pages/template/Groups";
import DealerList from "./pages/template/DealerList";
import CreateCampaign from "./pages/template/CreateCampaign";
import SingleCampaing from "./pages/template/SingleCampaing";
import CampaignReport from "./pages/template/CampaignReport";

function App() {
  const { mode, managerId } = useAuth();

  const {
    loggedIn,
    setLoggedIn,
    designation,
    setDesignation,
    setManagerId,
    setManagerName,
    setTotalUnreadChat,
  } = useAuth();

  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    const mid = sessionStorage.getItem("managerId");
    const encryptedName = sessionStorage.getItem("name")
      ? sessionStorage.getItem("name")
      : "";
    const decryptedBytes = CryptoJS.AES.decrypt(encryptedName, "secret key");
    const mname = decryptedBytes.toString(CryptoJS.enc.Utf8);
    if (designation) {
      setLoggedIn(true);
    }
    if (mid) {
      setManagerId(mid);
    }
    if (mname) {
      setManagerName(mname);
    }
  }, [designation, setLoggedIn, setManagerId, setManagerName]);

  const getDesignation = sessionStorage.getItem("designation")
    ? sessionStorage.getItem("designation")
    : "";
  const decryptedDesignationBytes = CryptoJS.AES.decrypt(
    getDesignation,
    "secret key"
  );
  const designations = decryptedDesignationBytes.toString(CryptoJS.enc.Utf8);
  useEffect(() => {
    if (designations) {
      setDesignation(designations.split(",")[0]);
    }
  }, [setDesignation, designations]);

  useEffect(() => {
    const handleResize = debounce(() => {
      setWidth(window.innerWidth);
    }, 500);

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const previousUnreadCount = useRef(0);

  const audio = new Audio("./assets/whatsapp_notification.mp3");

  const handleUnReadChats = async () => {
    try {
      const res = await fetchData("wallikonChat/unreadChats", {
        managerId: await sessionStorage.getItem("managerId"),
      });

      if (res.status === "success") {
        const newUnreadCount = res.totalUnreadChat;

        if (
          newUnreadCount > previousUnreadCount.current &&
          designation === ""
        ) {
          audio
            .play()
            .catch((error) => console.error("Error playing sound:", error));
        }

        // Update the unread count state and ref
        setTotalUnreadChat(newUnreadCount);
        previousUnreadCount.current = newUnreadCount;
      } else {
        setTotalUnreadChat(0);
        previousUnreadCount.current = 0;
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    const getManagerId = async () => {
      const id = await sessionStorage.getItem("managerId");
      setManagerId(id);
    };

    getManagerId();
  }, []);

  useEffect(() => {
    if (managerId) {
      handleUnReadChats();
    }
  }, [managerId]);
  useEffect(() => {
    const socket = io(process.env.REACT_APP_URL);

    socket.on("updateAdminChats", (allAdminChats) => {
      if (allAdminChats) {
        handleUnReadChats();
      }
    });

    socket.on("updateDealerChats", (allDealerChats) => {
      if (allDealerChats) {
        handleUnReadChats();
      }
    });

    // Clean up on component unmount
    return () => {
      socket.off("updateAdminChats");
      socket.off("updateDealerChats");
      socket.disconnect();
    };
  }, []);


  return (
    <BrowserRouter>
      <div
        className={"main_container"}
        style={
          mode ? { backgroundColor: "#232529" } : { backgroundColor: "#D9DCE5" }
        }
      >
        {!loggedIn ? (
          <Routes>
            <Route path="/" element={<Home />} />
          </Routes>
        ) : (
          // <div className={width > 1084 ? "content" : ""}>
          <div className={"content"}>
            <Routes>
              <Route path="/" element={<NavLayout />}>
                <Route path="/newmanager" element={<NewManager />} />
                <Route
                  path="/newmanager/:manager_Id"
                  element={<NewManager />}
                />
                <Route
                  path="/whatsappchats"
                  element={
                    width < 1084 ? <MainMobileChats /> : <MainWhatsappChat />
                  }
                />
                <Route path="/test" element={<Test />} />
                <Route path="/newmanager/:manager_Id" element={<NewManager />} />
                <Route path="/to" element={<Campaign />} />
                <Route path="/too" element={<CreateGroup />} />
                <Route path="/tooo" element={<Groups />} />
                <Route path="/toooo" element={<DealerList />} />
                <Route path="/newmanager/:manager_Id" element={<NewManager />} />
                <Route path="/po" element={<CreateCampaign />} />
                <Route path="/poo" element={<CreateGroup />} />
                <Route path="/campaign" element={<Campaign />} />
                <Route path="/whatsappchats" element={width < 1084 ? <MainMobileChats /> : <MainWhatsappChat />} />
                <Route path="/TestLink" element={<TestLink />} />
                <Route path="/test1" element={<Test />} />
                {designation === "superadmin" && (
                  <>
                    <Route path="/dashboard" index element={<SuperAdmin />} />
                    <Route path="/searchitem" element={<SearchItem />} />
                    <Route path="/dealers/:id" element={<Kyc />} />
                    <Route path="/dealers" element={<Dealers />} />
                    <Route path="/addproduct" element={<AddProduct />} />
                    <Route path="/products" element={<Products />} />
                    <Route path="/product/:product" element={<ViewProduct />} />
                    <Route path="/addfaq/:product" element={<ViewProduct />} />
                    <Route
                      path="/addstyle/:product"
                      element={<ViewProduct />}
                    />
                    <Route path="/addtype/:product" element={<ViewProduct />} />
                    <Route
                      path="/addcollection/:product"
                      element={<ViewProduct />}
                    />
                    <Route
                      path="/items/:product/:collection"
                      element={<Items />}
                    />
                    <Route
                      path="/item/:product/:collection"
                      element={<AddItem />}
                    />
                    <Route path="marketing" element={<Marketing />} />
                    <Route
                      path="/marketing-lead/:id"
                      element={<MarketingLead />}
                    />
                    <Route
                      path="/orders"
                      element={width < 1084 ? <MobAllOrders /> : <AllOrders />}
                    />
                    <Route path="/cartorders" element={<Orders />} />
                    <Route path="/partnerorders" element={<PartnerOrders />} />
                    <Route path="/purchaseorders" element={width < 1084 ? (<MobPurchaseOrders />) : (<PurchaseOrders />)} />
                    <Route path="/adjustmentorders" element={<AdjustmentOrders />} />
                    <Route path="/estimateorders" element={width < 1084 ? (<MobEstimateOrders />) : (<EstimateOrders />)} />
                    <Route path="/transferorders" element={width < 1084 ? (<TransferOrdersMob />) : (<TransferOrders />)} />
                    <Route path="/orders/saleorder" element={width < 1084 ? <SaleOrderMob /> : <SaleOrder />} />
                    {/* Changed Estimate to Sale Order */}
                    <Route
                      path="/orders/estimate"
                      element={width < 1084 ? <MobEstimate /> : <SaleOrder />}
                    />
                    <Route
                      path="/orders/saleinvoice"
                      element={<SaleOrderInovice />}
                    />
                    <Route
                      path="/orders/estimateinvoice"
                      element={<EstimateInvoice />}
                    />
                    {/* Changed */}
                    <Route path="/inventory" element={<Inventory />} />
                    <Route
                      path="/inventory/:product"
                      element={<InventoryCollections />}
                    />
                    {/* <Route
                      path="/inventory"
                      element={width < 1084 ? <InventoryMob /> : <Inventory />}
                    />
                    <Route
                      path="/inventory/:product"
                      element={
                        width < 1084 ? (
                          <InventoryCollectionsMob />
                        ) : (
                          <InventoryCollections />
                        )
                      }
                    /> */}
                    <Route path="/taxrate" element={<TaxRate />} />
                    {/* Changed */}
                    <Route
                      path="/inventory/:product/:collection"
                      element={<CollectionInfo />}
                    />
                    {/* <Route path="/inventory/:product/:collection" element={width < 1084 ? (<CollectionInfoMob />) : (<CollectionInfo />)} /> */}
                    <Route
                      path="/orders/purchase"
                      element={width < 1084 ? <MobPurchase /> : <Purchase />}
                    />
                    <Route
                      path="/orders/transfer"
                      element={<TransferStock />}
                    />
                    <Route
                      path="/orders/purchaseinvoice/:voucherNo"
                      element={<PurchaseInovice />}
                    />
                    <Route
                      path="/orders/adjustmentinvoice/:voucherNo"
                      element={<AdjustmentInvoice />}
                    />
                    <Route path="/orders/adjustment" element={<Adjustment />} />
                    <Route path="/orders/dealers" element={<OrderDealers />} />
                    <Route
                      path="/dispatchorders"
                      element={<DispatchOrders />}
                    />
                    <Route
                      path="/dispatchorders/:orderId"
                      element={
                        width < 1084 ? <DispatchOrder /> : <PackingVoucher />
                      }
                    />
                    <Route
                      path="/viewdispatchdetails/:orderId"
                      element={
                        width < 1084 ? (
                          <ViewDispatchDetailsMob />
                        ) : (
                          <ViewDispatchDetails />
                        )
                      }
                    />
                    <Route path="/packingorder" element={<PackingOrders />} />
                    <Route
                      path="/packingorder/:orderId"
                      element={<PackingOrder />}
                    />
                    <Route path="/packers" element={<Packers />} />
                    <Route path="/packer/:pmId" element={<Packer />} />
                    {/* <Route path="/packer" element={<Packer />} /> */}
                    <Route path="/createpacker" element={<CreatePackers />} />
                    <Route path="/managers" element={<Managers />} />
                    <Route path="/manager" element={<AddManager />} />
                    <Route path="/complaints" element={<Complaints />} />
                    <Route
                      path="/dispatchdashboard"
                      element={
                        width < 1084 ? (
                          <DispatchDasboardMob />
                        ) : (
                          <DispatchDasboard />
                        )
                      }
                    />
                    <Route
                      path="/recipts"
                      element={width < 1084 ? <ReciptsMob /> : <Recipts />}
                    />
                    <Route path="/accounts" element={<Accounts />} />
                    <Route path="/delivery" element={<Delivery />} />
                    <Route
                      path="/delivery/addpartner"
                      element={<AddPartner />}
                    />
                    <Route
                      path="/outsideinventory"
                      element={
                        width < 1084 ? (
                          <OutSideInventoryMob />
                        ) : (
                          <OutSideInventory />
                        )
                      }
                    />
                    <Route
                      path="/outsideinventory/:product"
                      element={
                        width < 1084 ? (
                          <OutSideIvtCollMob />
                        ) : (
                          <OutSideIvtColl />
                        )
                      }
                    />
                    <Route
                      path="/outsideinventory/:product/:collection"
                      element={
                        width < 1084 ? (
                          <OutSideIvtCollInfomob />
                        ) : (
                          <OutSideIvtCollInfo />
                        )
                      }
                    />
                    <Route path="/warehouses" element={<Warehouse />} />
                    <Route path="/deleteoredit" element={<EditDelete />} />
                    <Route path="/invoices" element={<Invoices />} />
                    <Route path="/invoices/invoice" element={<SaleInvoice />} />
                    <Route path="/invoices/uploadedinvoice" element={<UploadInvoice />} />
                    {/* <Route path="/templates" element={<Templates />} /> */}
                    <Route path="/templates" element={<Templates />} />
                    <Route path="/campaign" element={<Campaign />} />
                    <Route path="/campaign-report/:tempname" element={<CampaignReport />} />
                    <Route path="/newcampaign" element={<CreateCampaign />} />
                    <Route path="/singlecampaign/:campaing" element={<SingleCampaing />} />
                    <Route path="/groups" element={<Groups />} />
                    <Route path="/newgroup" element={<CreateGroup />} />
                    <Route path="/newgroup/:tempname" element={<DealerList />} />

                    <Route path="/singeltemp" element={<SingleTemp />} />
                    <Route path="/templates/template" element={<CreateTemplate />} />
                    {/* <Route path="/invoices/viewinvoice" element={<ViewInvoice />} /> */}
                    {/* <Route path='/dealerchats' element={<DealerChats />} /> */}
                    <Route path="/profile" element={<AddManager />} />
                    {/* Add this one below */}
                    <Route path="/TestLink" element={<TestLink />} />
                    <Route path="*" element={<NoMatch />} />
                  </>
                )}
                {designation === "contentmanager" && (
                  <>
                    <Route path="/dashboard" element={<ContentDashboard />} />
                    <Route path="/addproduct" element={<AddProduct />} />
                    <Route path="/products" element={<Products />} />
                    <Route path="/product/:product" element={<ViewProduct />} />
                    <Route path="/addfaq/:product" element={<ViewProduct />} />
                    <Route path="/addstyle/:product" element={<ViewProduct />} />
                    <Route path="/addtype/:product" element={<ViewProduct />} />
                    <Route path="/addcollection/:product" element={<ViewProduct />} />
                    <Route path="/items/:product/:collection" element={<Items />} />
                    <Route path="/item/:product/:collection" element={<AddItem />} />
                    <Route path="/dealers/:id" element={<Kyc />} />
                    <Route path="/dealers" element={<Dealers />} />
                    <Route path="*" element={<NoMatch />} />
                  </>
                )}
                {designation === "ordermanager" && (
                  <>
                    <Route path="/searchitem" element={<SearchItem />} />
                    <Route path="/dashboard" element={<OrderDashboard />} />
                    <Route path="/orders" element={width < 1084 ? <MobAllOrders /> : <AllOrders />} />
                    <Route path="/cartorders" element={<Orders />} />
                    <Route path="/partnerorders" element={<PartnerOrders />} />
                    <Route path="/estimateorders" element={width < 1084 ? (<MobEstimateOrders />) : (<EstimateOrders />)} />
                    <Route path="/orders/saleorder" element={width < 1084 ? <SaleOrderMob /> : <SaleOrder />} />
                    <Route path="/viewdispatchdetails/:orderId" element={width < 1084 ? (<ViewDispatchDetailsMob />) : (<ViewDispatchDetails />)} />
                    <Route path="/orders/saleinvoice" element={<SaleOrderInovice />} />
                    <Route path="/orders/dealers" element={<OrderDealers />} />
                    <Route path="/orders/estimate" element={width < 1084 ? <MobEstimate /> : <Estimate />} />
                    <Route path="/orders/estimateinvoice" element={<EstimateInvoice />} />

                    {/* <Route path="/order/:orderId" element={<Order />} /> */}
                    <Route path="*" element={<NoMatch />} />
                  </>
                )}
                {designation === "inventorymanager" && (
                  <>
                    <Route path="/searchitem" element={<SearchItem />} />
                    <Route path="/dashboard" element={<InventoryDashboard />} />
                    <Route path="/orders" element={width < 1084 ? <MobAllOrders /> : <AllOrders />} />
                    <Route path="/orders/saleorder" element={width < 1084 ? <SaleOrderMob /> : <SaleOrder />} />
                    <Route path="/viewdispatchdetails/:orderId" element={width < 1084 ? (<ViewDispatchDetailsMob />) : (<ViewDispatchDetails />)} />
                    <Route path="/cartorders" element={<Orders />} />
                    <Route path="/partnerorders" element={<PartnerOrders />} />
                    <Route path="/purchaseorders" element={width < 1084 ? (<MobPurchaseOrders />) : (<PurchaseOrders />)} />
                    <Route path="/adjustmentorders" element={<AdjustmentOrders />} />
                    {/* <Route path="/estimateorders" element={<EstimateOrders />} /> */}
                    <Route path="/estimateorders" element={width < 1084 ? (<MobEstimateOrders />) : (<EstimateOrders />)} />
                    <Route path="/transferorders" element={width < 1084 ? (<TransferOrdersMob />) : (<TransferOrders />)} />
                    {/* <Route path="/orders/saleorder" element={<SaleOrder />} /> */}
                    <Route
                      path="/orders/saleinvoice"
                      element={<SaleOrderInovice />}
                    />
                    {/* Changed */}
                    <Route path="/inventory" element={<Inventory />} />
                    <Route
                      path="/inventory/:product"
                      element={<InventoryCollections />}
                    />
                    <Route
                      path="/inventory/:product/:collection"
                      element={<CollectionInfo />}
                    />
                    {/* <Route
                      path="/inventory"
                      element={width < 1084 ? <InventoryMob /> : <Inventory />}
                    />
                    <Route
                      path="/inventory/:product"
                      element={
                        width < 1084 ? (
                          <InventoryCollectionsMob />
                        ) : (
                          <InventoryCollections />
                        )
                      }
                    />
                    <Route
                      path="/inventory/:product/:collection"
                      element={
                        width < 1084 ? (
                          <CollectionInfoMob />
                        ) : (
                          <CollectionInfo />
                        )
                      }
                    /> */}
                    <Route path="/taxrate" element={<TaxRate />} />
                    <Route
                      path="/orders/purchase"
                      element={width < 1084 ? <MobPurchase /> : <Purchase />}
                    />
                    <Route
                      path="/orders/purchaseinvoice/:voucherNo"
                      element={<PurchaseInovice />}
                    />
                    <Route
                      path="/orders/adjustmentinvoice/:voucherNo"
                      element={<AdjustmentInvoice />}
                    />
                    <Route path="/orders/adjustment" element={<Adjustment />} />
                    {/* <Route path="/orders/estimate" element={<Estimate />} /> */}
                    <Route path="/orders/estimate" element={width < 1084 ? <MobEstimate /> : <Estimate />} />
                    <Route path="/orders/transfer" element={<TransferStock />} />
                    <Route path="/orders/estimateinvoice" element={<EstimateInvoice />} />
                    <Route path="/outsideinventory" element={width < 1084 ? (<OutSideInventoryMob />) : (<OutSideInventory />)} />
                    <Route path="/outsideinventory/:product" element={width < 1084 ? (<OutSideIvtCollMob />) : (<OutSideIvtColl />)} />
                    <Route path="/outsideinventory/:product/:collection" element={width < 1084 ? (<OutSideIvtCollInfomob />) : (<OutSideIvtCollInfo />)} />
                    <Route path="*" element={<NoMatch />} />
                  </>
                )}
                {designation === "dispatchmanager" && (
                  <>
                    <Route path="/searchitem" element={<SearchItem />} />
                    <Route path="/dashboard" element={width < 1084 ? (<DispatchDasboardMob />) : (<DispatchDasboard />)} />
                    <Route path="/dispatchorders" element={<DispatchDasboard />} />
                    <Route path="/dispatchorders/:orderId" element={width < 1084 ? <DispatchOrder /> : <PackingVoucher />} />
                    <Route path="/viewdispatchdetails/:orderId" element={width < 1084 ? (<ViewDispatchDetailsMob />) : (<ViewDispatchDetails />)} />
                    <Route path="/packers" element={<Packers />} />
                    <Route path="/packer/:pmId" element={<Packer />} />
                    <Route path="/createpacker" element={<CreatePackers />} />
                    <Route path="*" element={<NoMatch />} />
                  </>
                )}
                {designation === "paymentmanager" && (
                  <>
                    <Route path="/dashboard" element={width < 1084 ? <ReciptsMob /> : <Recipts />} />
                    <Route path="/orders/saleorder" element={width < 1084 ? <SaleOrderMob /> : <SaleOrder />} />
                    <Route path="/viewdispatchdetails/:orderId" element={width < 1084 ? (<ViewDispatchDetailsMob />) : (<ViewDispatchDetails />)} />
                    <Route path="*" element={<NoMatch />} />
                  </>
                )}
                {designation === "packingmanager" && (
                  <>
                    <Route path="/searchitem" element={<SearchItem />} />
                    <Route path="/dashboard" element={<PackingOrders />} />
                    {/* <Route path="/packingorder" element={<PackingOrders />} /> */}
                    <Route path="/packingorder/:orderId" element={<PackingOrder />} />
                  </>
                )}
                {designation === "marketing" && (
                  <>
                    <Route path="/dashboard" element={<MarketingDashboard />} />
                    <Route path="/marketing/contacts" element={<Contacts />} />
                    <Route path="/marketing/leads" element={<Leads />} />
                    <Route path="/marketing-lead/:id" element={<MarketingLead />} />
                    <Route path="*" element={<NoMatch />} />
                  </>
                )}
              </Route>
            </Routes>
          </div>
        )}
      </div>
    </BrowserRouter>
  );
}

export default App;

export const NoMatch = () => {
  let location = useLocation();
  const { managerId, switchDisplay } = useAuth();
  const navigate = useNavigate();
  useEffect(() => {
    if (managerId) {
      navigate("/dashboard");
    } else {
      navigate("/");
    }
  }, []);
  return (
    <div className={`${switchDisplay ? "bg_white" : "bg_dark"} App`}>
      <h3>
        No match for <code>{location.pathname}</code>
      </h3>
    </div>
  );
};
