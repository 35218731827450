import { useCallback, useEffect, useRef, useState } from "react";
import styles from "../../../pages/ordermanager/Orders.module.css";
import { RemoveIcon } from "../../icons/Icon";
import SearchItem from "./SearchItem";
import fetchData from "../../../APIsControll/apiControll";

const NewItemList = ({
  mode,
  showItemSearch,
  setShowItemSearch,
  managerId,
  orderType,
  onSelectItem,
  index,
  removeItem,
  addItem,
  itemsdetailslength,
  newItemListRef,
}) => {
  const quantityInputRef = useRef(null);
  const descriptionInputRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState([]);
  const [searchItem, setSearchItem] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);
  const [selectedItem, setSelectedItem] = useState();
  const [showDescriptionInput, setShowDescriptionInput] = useState(false);

  const fetchItems = useCallback(async () => {
    const apiData = {
      managerId: managerId,
      keyword: searchItem,
      orderType: orderType,
    };
    try {
      setLoading(true);
      const result = await fetchData("order/searchitems?page=", apiData);
      if (result.status === "success") {
        setResults(result.data || []);
        setCurrentIndex(0);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }, [searchItem, managerId, orderType]);

  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      if (searchItem) {
        fetchItems();
      } else {
        setResults([]);
        setCurrentIndex(0);
      }
    }, 700);

    return () => clearTimeout(debounceTimer);
  }, [searchItem, managerId, orderType, fetchItems]);

  const handleKeyDown = (e) => {
    if (e.key === "ArrowDown") {
      setCurrentIndex((prevIndex) =>
        prevIndex < results.length - 1 ? prevIndex + 1 : prevIndex
      );
      e.preventDefault();
      return;
    }

    if (e.key === "ArrowUp") {
      setCurrentIndex((prevIndex) =>
        prevIndex > 0 ? prevIndex - 1 : prevIndex
      );
      e.preventDefault();
      return;
    }

    if (e.ctrlKey && e.key === "Backspace") {
      e.preventDefault();
      removeItem();
      return;
    }

    if (e.key === "Enter") {
      e.preventDefault();
      if (e.target === quantityInputRef.current) {
        const quantityValue = e.target.value;
        if (
          quantityValue &&
          !isNaN(quantityValue) &&
          parseFloat(quantityValue) > 0
        ) {
          setShowDescriptionInput(true);
          setTimeout(() => {
            descriptionInputRef.current?.focus();
          }, 0);
        } else {
          alert("Please enter a valid quantity before proceeding.");
        }
      } else if (e.target === descriptionInputRef.current) {
        addItem();
        setShowDescriptionInput(false);
      } else {
        if (searchItem.length === 0) {
          if (itemsdetailslength === 1) {
            alert("YOU CANNOT ADD NEW ITEM BEFORE FILLING THIS ONE!");
            newItemListRef.current.focus();
          } else {
            alert("MOVING TO NEW ITEM FIELD");
          }
        } else {
          handleRowClick(currentIndex);
        }
      }
    }
  };

  const resetSearchComplete = () => {
    setCurrentIndex(0);
    setShowItemSearch(false);
  };

  const handleRowClick = (index) => {
    const selectedItem = results[index];
    if (!selectedItem) {
      resetSearchComplete();
      return;
    }
    if (selectedItem.vm === null && selectedItem.weight === null) {
      alert("Either weight or volume is not added to this item");
    } else if (selectedItem.totalQty <= 0) {
      alert("Item is out of stock");
    } else {
      setSelectedItem(selectedItem);
      onSelectItem && onSelectItem(selectedItem);
      // onShowDescription && onShowDescription()
      resetSearchComplete();
      setTimeout(() => {
        quantityInputRef.current?.focus();
      }, 0);
    }
    setSearchItem("");
  };
  return (
    <>
      <tbody>
        <tr>
          <td className={styles.table_cell}>{index + 1}</td>
          {selectedItem !== undefined ? (
            <>
              <td
                className={styles.table_cell}
                onClick={() => {
                  // setSelectedItem(undefined);
                  setShowDescriptionInput(false);
                }}
              >
                {selectedItem.itemNo}
              </td>
              <td className={styles.table_cell}>{selectedItem.product_name}</td>
              <td className={styles.table_cell}>{selectedItem.warehouse}</td>
              <td className={styles.table_cell}>
                <input
                  type="number"
                  placeholder="Enter Quantity"
                  ref={quantityInputRef}
                  onKeyDown={handleKeyDown}
                  // onChange={(e) => setQuantity(e.target.value)}
                />
              </td>
            </>
          ) : (
            <td className={styles.table_cell} colSpan="4">
              <div className={styles.inp_div}>
                <input
                  ref={newItemListRef}
                  type="text"
                  placeholder="Search your query"
                  onChange={(e) => setSearchItem(e.target.value)}
                  onFocus={() => setShowItemSearch(true)}
                  onBlur={() => setShowItemSearch(false)}
                  onKeyDown={handleKeyDown}
                />
                {showItemSearch && (
                  <SearchItem
                    mode={mode}
                    results={results}
                    currentIndex={currentIndex}
                    loading={loading}
                  />
                )}
              </div>
            </td>
          )}

          <td className={styles.table_cell}></td>
          <td className={styles.table_cell}></td>
          <td className={styles.table_cell}></td>
          {itemsdetailslength > 1 && (
            <td
              className={styles.table_cell}
              style={{ width: "0px", backgroundColor: "transparent" }}
            >
              <div
                style={{ cursor: "pointer", marginRight: "-0.5rem" }}
                onClick={removeItem}
              >
                <RemoveIcon />
              </div>
            </td>
          )}
        </tr>
      </tbody>

      {showDescriptionInput && (
        <input
          style={{ width: "496%", fontSize: "12px" }}
          type="text"
          placeholder="Enter description"
          ref={descriptionInputRef}
          onKeyDown={handleKeyDown}
        />
      )}
    </>
  );
};

export default NewItemList;

// eslint-disable-next-line no-lone-blocks
{
  /* <div>
  <li
    className={`${styles.so_data_list_row} ${
      mode ? styles.so_data_list_row_dark : ""
    }`}
  >
    <div className="col">1</div>
    <div className="col">
      <input
        className={`${styles.so_inp} ${styles.si_inp} ${
          mode ? styles.so_inp_dark : ""
        }`}
        type="text"
        onFocus={onFocus}
        onBlur={onBlur}
        onChange={(e) => setSearchItem(e.target.value)}
      />
      {showItemSearch && <SearchItem mode={mode} results={results} />}
    </div>

    <div className="col"></div>
    <div className="col"></div>
    <div className="col">
      <input
        className={`${styles.so_inp} ${mode ? styles.so_inp_dark : ""}`}
        type="text"
      />
    </div>
    <div className="col"></div>
    <div className="col"></div>
    <div className="col"></div>
  </li>
  <div
    style={{
      marginLeft: "5px",
      cursor: "pointer",
      display: itemsdetailslength === 1 ? "none" : "block",
    }}
  >
    <RemoveIcon />
  </div>
</div>; */
}
