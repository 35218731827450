import React, { useState, useEffect } from 'react'
import { useAuth } from '../../components/context/Auth';
import { GreenButton, RedOutButton } from '../../components/Buttons';
import styles from './Marketing.module.css'

const CreateLeadModal = ({ displayModal, closeModal, leadId, setLeadId, setSuccess }) => {

    const { switchDisplay, managerId } = useAuth();

    const handleSubmit = (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            submitLeadData()
        }
    };

    const [leadData, setLeadData] = useState({
        name: "",
        designation: "",
        city: "",
        pincode: "",
        state: "",
        district: "",
        mobile: "",
        gst: "",
        companyName: "",
        email: "",
        source: "",
        address: "",
        priority: ""
    })
    const divStyle = {
        display: displayModal ? 'block' : 'none'
    };
    // function closeModal1(e) {
    //     setLeadId("")
    //     setLeadData({
    //         name: "",
    //         designation: "",
    //         city: "",
    //         pincode: "",
    //         state: "",
    //         mobile: "",
    //         gst: "",
    //         companyName: "",
    //         email: "",
    //         source: "",
    //         address: "",
    //         priority: ""
    //     })
    //     // e.stopPropagation()
    //     closeModal()
    // }

    const submitLeadData = () => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);

        var raw = JSON.stringify({
            "leadId": leadId,
            "managerId": managerId,
            "name": leadData.name,
            "companyName": leadData.companyName,
            "designation": leadData.designation,
            "address": leadData.address,
            "pincode": leadData.pincode,
            "district": leadData.district,
            "state": leadData.state,
            "email": leadData.email,
            "mobile": leadData.mobile,
            "gst": leadData.gst,
            "priority": leadData.priority,
            "source": leadData.source,

        });
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(process.env.REACT_APP_URL + "marketing/addUpdateLead", requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status === "success") {
                    setLeadId("")
                    setLeadData({
                        name: "",
                        designation: "",
                        city: "",
                        pincode: "",
                        state: "",
                        mobile: "",
                        gst: "",
                        companyName: "",
                        email: "",
                        source: "",
                        address: "",
                        priority: ""
                    })
                    setSuccess(Math.random())
                    alert("Lead Added Successfully")
                }
            })
            .catch(error => console.log('error', error));
    }

    useEffect(() => {
        if (leadId) {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);

            var raw = JSON.stringify({
                "leadId": leadId,
                "managerId": managerId,
            });

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            fetch(process.env.REACT_APP_URL + "marketing/lead", requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.status === "success") {
                        if (result.data) {
                            setLeadData(prevState => ({
                                ...prevState,
                                name: result.data.name ? result.data.name : "",
                                companyName: result.data.companyName ? result.data.companyName : "",
                                designation: result.data.designation ? result.data.designation : "",
                                city: result.data.city ? result.data.city : "",
                                address: result.data.address ? result.data.address : "",
                                email: result.data.email ? result.data.email : "",
                                mobile: result.data.mobile ? result.data.mobile : "",
                                gst: result.data.gst ? result.data.gst : "",
                                priority: result.data.priority ? result.data.priority : "",
                                source: result.data.source ? result.data.source : "",
                            }))
                        }
                    }
                })
                .catch(error => console.log('error', error));
        }
    }, [leadId, managerId])

    useEffect(() => {
        if (leadData.mobile.length === 10) {
            var myHeaders = new Headers();
            myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                "mobile": leadData.mobile
            });

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            fetch(process.env.REACT_APP_URL + "marketing/searchmobile", requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.status === "success") {
                        setLeadData(prevState => ({
                            ...prevState,
                            name: result.data.name ? result.data.name : "",
                            companyName: result.data.companyName ? result.data.companyName : "",
                            designation: result.data.designation ? result.data.designation : "",
                            pincode: result.data.pincode ? result.data.pincode : "",
                            city: result.data.city ? result.data.city : "",
                            district: result.data.district ? result.data.district : "",
                            state: result.data.state ? result.data.state : "",
                            address: result.data.address ? result.data.address : "",
                            email: result.data.email ? result.data.email : "",
                            mobile: result.data.mobile ? result.data.mobile : "",
                            gst: result.data.gst ? result.data.gst : "",
                            priority: result.data.priority ? result.data.priority : "",
                            source: result.data.source ? result.data.source : "",
                        }))
                    } else {
                        setLeadData({
                            name: result.data.name ? result.data.name : "",
                            companyName: result.data.companyName ? result.data.companyName : "",
                            designation: result.data.designation ? result.data.designation : "",
                            pincode: result.data.pincode ? result.data.pincode : "",
                            city: result.data.city ? result.data.city : "",
                            district: result.data.district ? result.data.district : "",
                            state: result.data.state ? result.data.state : "",
                            address: result.data.address ? result.data.address : "",
                            email: result.data.email ? result.data.email : "",
                            mobile: leadData.mobile,
                            gst: result.data.gst ? result.data.gst : "",
                            priority: result.data.priority ? result.data.priority : "",
                            source: result.data.source ? result.data.source : "",
                        })
                    }
                })
                .catch(error => console.log('error', error));
        }
    }, [leadData.mobile])

    const [statesData, setStatesData] = useState({
        statesData: [],
        districtData: [],
    })

    useEffect(() => {
        if (leadData.pincode.length === 6 || leadData.city) {
            var myHeaders = new Headers();
            myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                "keyword": leadData.pincode ? leadData.pincode : leadData.city,
                "statename": leadData.state ? leadData.state : "",
            });

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            fetch(process.env.REACT_APP_URL + "locations/locations", requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.status === "success") {
                        setStatesData(prevState => ({
                            ...prevState,
                            statesData: result.states,
                            districtData: result.districts,
                        }))
                        if (result.states.length === 1) {
                            setLeadData(prevState => ({
                                ...prevState,
                                state: result.states[0],
                            }))
                        }
                        if (result.districts.length === 1) {
                            setLeadData(prevState => ({
                                ...prevState,
                                district: result.districts[0],
                            }))
                        }
                        console.log(result)
                    }
                })
                .catch(error => console.log('error', error));
        }
    }, [leadData.pincode, leadData.city, leadData.state])

    function enforce_maxlength(e) {
        var t = e.target;
        if (t.hasAttribute('maxlength')) {
            t.value = t.value.slice(0, t.getAttribute('maxlength'));
        }
    }

    return (
        <React.Fragment>
            <div
                className={styles.custom_modal}
                onClick={closeModal}
                style={divStyle}>
                <form onSubmit={handleSubmit}>
                    <div className={`${switchDisplay ? styles.modal_content_white : styles.modal_content} ${styles.center} ${styles.text_center}`} onClick={e => e.stopPropagation()} >
                        <div className={styles.modal_body}>
                            <div className={styles.fs_30}>{leadId ? "Update" : "Create"} Lead</div>
                            <div className={styles.row}>
                                <div className={styles.col_5 + " " + styles.offset_1}>
                                    <div className={styles.text_start + " " + styles.mt_10}>Mobile Number <span className={styles.text_danger}>*</span></div>
                                    <div>
                                        <input
                                            required
                                            type="number"
                                            placeholder="Enter Mobile Number"
                                            className={`${switchDisplay ? styles.text_Box_Dark : styles.text_Box}`}
                                            value={leadData.mobile}
                                            onChange={(e) => { enforce_maxlength(e); setLeadData({ ...leadData, mobile: e.target.value }) }}
                                            maxLength="10"
                                            onWheel={(e) => e.target.blur()}
                                        />
                                        <p className={styles.marketing_mobile_alert}>{leadData.mobile && leadData.mobile.length < 10 && "Please Enter 10 Digit.."}</p>
                                    </div>
                                    <div className={styles.text_start + " " + styles.mt_10}>Company Name <span className={styles.text_danger}>*</span></div>
                                    <div>
                                        <input
                                            required
                                            type="text"
                                            placeholder="Enter Company Name"
                                            className={`${switchDisplay ? styles.text_Box_Dark : styles.text_Box}`}
                                            value={leadData.companyName}
                                            onChange={(e) => setLeadData({ ...leadData, companyName: e.target.value })}
                                        />
                                    </div>
                                    <div className={styles.text_start + " " + styles.mt_10}>Street Address <span className={styles.text_danger}>*</span></div>
                                    <div>
                                        <input
                                            required
                                            type="textarea"
                                            placeholder="Enter Street Address"
                                            className={`${switchDisplay ? styles.text_Box_Dark : styles.text_Box}`}
                                            value={leadData.address}
                                            onChange={(e) => setLeadData({ ...leadData, address: e.target.value })}
                                        />
                                    </div>

                                    <div className={styles.text_start + " " + styles.mt_10}>Pincode <span className={styles.text_danger}>*</span></div>
                                    <div>
                                        <input
                                            required
                                            type="number"
                                            placeholder="Enter Pincode"
                                            className={`${switchDisplay ? styles.text_Box_Dark : styles.text_Box}`}
                                            value={leadData.pincode}
                                            onChange={(e) => setLeadData({ ...leadData, pincode: e.target.value })}
                                        />
                                    </div>

                                    <div className={styles.text_start + " " + styles.mt_10}>City <span className={styles.text_danger}>*</span></div>
                                    <div className={styles.cityMain}>

                                        <input
                                            // required
                                            type="text"
                                            placeholder="Enter City"
                                            className={`${switchDisplay ? styles.text_Box_Dark : styles.text_Box}`}
                                            value={leadData.city}
                                            onChange={(e) => setLeadData({ ...leadData, city: e.target.value.toLocaleLowerCase().replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase()) })}
                                        />
                                    </div>
                                    <div className={styles.text_start + " " + styles.mt_10}>District <span className={styles.text_danger}>*</span></div>
                                    <div className={styles.cityMain}>
                                        <select className={`${switchDisplay ? styles.bg_dark : styles.text_Box}`} value={leadData.district} onChange={(e) => setLeadData({ ...leadData, district: e.target.value })}>
                                            <option className={styles.bg_dark} value="">Select District</option>

                                            {statesData.districtData && statesData.districtData.map((item, index) => {
                                                return <option className={styles.bg_dark} key={index} value={item.Name}>{item}</option>
                                            }
                                            )}
                                        </select>
                                    </div>
                                    <div className={styles.text_start + " " + styles.mt_10}>State <span className={styles.text_danger}>*</span></div>
                                    <div className={styles.cityMain}>
                                        <select className={`${switchDisplay ? styles.bg_dark : styles.text_Box}`} value={leadData.state} onChange={(e) => setLeadData({ ...leadData, state: e.target.value })}>
                                            <option className={styles.bg_dark} value="">Select State</option>

                                            {statesData.statesData && statesData.statesData.map((state, index) => {
                                                return <option className={styles.bg_dark} key={index} value={state}>{state}</option>
                                            }
                                            )}
                                        </select>
                                    </div>

                                </div>
                                <div className={styles.col_5 + " " + styles.offset_1}>
                                    <div className={styles.text_start + " " + styles.mt_10}>Name</div>
                                    <div>
                                        <input
                                            // required
                                            type="text"
                                            placeholder="Enter Name"
                                            className={`${switchDisplay ? styles.text_Box_Dark : styles.text_Box}`}
                                            value={leadData.name}
                                            onChange={(e) => setLeadData({ ...leadData, name: e.target.value })}
                                        />
                                    </div>

                                    <div className={styles.text_start + " " + styles.mt_10}>Email Address</div>
                                    <div>
                                        <input
                                            // required
                                            type="text"
                                            placeholder="Enter Email Address"
                                            className={`${switchDisplay ? styles.text_Box_Dark : styles.text_Box}`}
                                            value={leadData.email}
                                            onChange={(e) => setLeadData({ ...leadData, email: e.target.value })}
                                        />
                                    </div>

                                    <div className={styles.text_start + " " + styles.mt_10}>Designation</div>
                                    <div>
                                        <select className={`${switchDisplay ? styles.bg_dark : styles.text_Box}`} value={leadData.designation} onChange={(e) => setLeadData({ ...leadData, designation: e.target.value })}>
                                            <option className={styles.bg_dark} value="">Select Designation </option>
                                            <option className={styles.bg_dark} value="Owner">Owner</option>
                                            <option className={styles.bg_dark} value="Manager">Manager</option>
                                            <option className={styles.bg_dark} value="Designer">Designer</option>
                                            <option className={styles.bg_dark} value="Contractor">Contractor</option>
                                            <option className={styles.bg_dark} value="Other">Other</option>
                                        </select>
                                    </div>


                                    <div className={styles.text_start + " " + styles.mt_10}>Source</div>
                                    <div>
                                        <select
                                            // required
                                            name='source'
                                            className={`${switchDisplay ? styles.text_Box_Dark : styles.text_Box}`}
                                            value={leadData.source}
                                            onChange={(e) => setLeadData({ ...leadData, source: e.target.value })}
                                        >
                                            <option value="" className={styles.bg_dark}>Select Source</option>
                                            <option value="Google" className={styles.bg_dark}>Google</option>
                                            <option value="Just Dial" className={styles.bg_dark}>Just Dial</option>
                                            <option value="India Mart" className={styles.bg_dark}>India Mart</option>
                                            <option value="Facebook" className={styles.bg_dark}>Facebook</option>
                                            <option value="Instagram" className={styles.bg_dark}>Instagram</option>
                                            <option value="Linkedin" className={styles.bg_dark}>Linkedin</option>
                                            <option value="Twitter" className={styles.bg_dark}>Twitter</option>
                                            <option value="Referral" className={styles.bg_dark}>Referral</option>
                                            <option value="Direct" className={styles.bg_dark}>Direct</option>
                                            <option value="Other" className={styles.bg_dark}>Other</option>
                                        </select>
                                    </div>
                                    <div className={styles.text_start + " " + styles.mt_10}>GSTUIN (optional)</div>
                                    <div>
                                        <input
                                            type="text"
                                            placeholder="Enter GSTUIN"
                                            className={`${switchDisplay ? styles.text_Box_Dark : styles.text_Box}`}
                                            value={leadData.gst}
                                            onChange={(e) => setLeadData({ ...leadData, gst: e.target.value })}
                                        />
                                    </div>

                                    <div className={styles.text_start + " " + styles.mt_10}>Priority</div>
                                    <div>
                                        <select className={`${switchDisplay ? styles.text_Box_Dark : styles.text_Box} ${styles.h_36}`}
                                            required
                                            value={leadData.priority}
                                            onChange={(e) => setLeadData({ ...leadData, priority: e.target.value })}
                                        >
                                            <option className={`${switchDisplay ? styles.bg_white : styles.bg_dark} ${styles.h_36}`} value="">Select</option>
                                            <option className={`${switchDisplay ? styles.bg_white : styles.bg_dark} ${styles.h_36}`}>High</option>
                                            <option className={`${switchDisplay ? styles.bg_white : styles.bg_dark} ${styles.h_36}`}>Modrate</option>
                                            <option className={`${switchDisplay ? styles.bg_white : styles.bg_dark} ${styles.h_36}`}>Low</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={styles.text_center + " " + styles.mt_40}>
                            <RedOutButton btnType="button" title="CANCEL" css={styles.dangerBtn + " " + styles.mouse} handleSubmit={closeModal} />
                            <GreenButton btnType="submit" title={leadId ? "UPDATE" : "SAVE"} css={styles.greenBtn + " " + styles.mouse + " " + styles.ms_15p} />
                        </div>
                    </div>
                </form>
            </div>
        </React.Fragment>
    )
}

export default CreateLeadModal