import React from "react";
import styles from "./Test.module.css";
import Button from "../Dealers/Button";
import {
  TestTopBackArrowIcon,
  TestTopDotIcon,
  NavbarSearchIcon,
  DispatchReloadIcon,
  DownloadIcon,
} from "../../icons/Icon";
import { useAuth } from "../../context/Auth";
import { useNavigate } from "react-router-dom";
import SlidingFilter from "../Dealers/SlidingFilter";
import DealerStatusCard from "../Dealers/DealerStatusCard";
import DropDown from "../FormComponents/DropDown";
import InputTypedate from "../FormComponents/InputTypedate";

const Test = () => {
  const { mode } = useAuth();
  const navigate = useNavigate();
  const routeName = "Dispatch";
  const slidingFilter1 = ["Today", "Yesterday", "This Month", "Last 30 Days"];
  const slidingFilter2 = [
    "All",
    "Sales",
    "Purchase",
    "Adjustment",
    "Transfer",
    "Production",
    "Production & Sale",
    "Production & Transfer",
  ];
  const dispatchStatusData = [
    { statusText: "Active", statusItemCount: 1 },
    { statusText: "Disabled", statusItemCount: 2 },
  ];
  const dispatchHeader = [
    "Name & City",
    "Type",
    "Date",
    "Order ID",
    "Packer",
    "Order Manager",
    "Source",
    "Sub- Method",
    "Delivery Partner",
    "Status",
    "Last Action",
  ];
  const dispatchData = [
    "Mohit, Lucknow",
    "Purchase",
    "11-07-2024",
    "123456",
    "RRA Admin",
    "Ajay Sahu",
    "Online",
    "Rapido",
    "Rapido",
    "New Order",
    "2 Days",
  ];
  return (
    <div className={`${styles.dispatch} ${styles.dispatch_dark}`}>
      <div className={styles.dispatch_top}>
        <div className={styles.dispatch_top_left}>
          <div
            className={styles.dispatch_top_left_go_back}
            onClick={() => navigate(-1)}
          >
            <TestTopBackArrowIcon color={mode ? "#ffffff" : "#0100E4"} />
            <p
              className={styles.dispatch_go_back_text}
              style={{ color: mode ? "#FFFFFF" : "#0100E4" }}
            >
              Go Back
            </p>
          </div>
          <TestTopDotIcon color={mode ? "#E4E7EB" : "#646B88"} />
          <p
            className={styles.dispatch_top_left_path_text}
            style={{
              color: mode ? "#9C9797" : "#646B88",
            }}
          >
            {routeName}
          </p>
        </div>
        <div className={styles.dispatch_right}>
          <div>
            <SlidingFilter itemList={slidingFilter1} />
          </div>
          <div>
            <InputTypedate text={"From"} />
          </div>
          <div>
            <InputTypedate text={"To"} />
          </div>
          <div>
            <Button
              buttonIcon={<DispatchReloadIcon />}
              buttonBgColor={"#000000"}
              buttonTextColor={"#ffffff"}
              buttonBorder={"none"}
            />
          </div>
          <div>
            <Button
              buttonIcon={<DownloadIcon />}
              buttonText={"Download"}
              buttonBgColor={"#4164E3"}
              buttonTextColor={"#ffffff"}
              buttonBorder={"none"}
            />
          </div>
        </div>
      </div>

      <div
        className={`${styles.dispatch_filters} ${styles.dispatch_filters_dark}`}
      >
        {dispatchStatusData?.map((s) => (
          <div key={s.statusText} className={styles.dispatch_filter_card}>
            <DealerStatusCard
              statusText={s.statusText}
              statusItemCount={s.statusItemCount}
            />
          </div>
        ))}
      </div>

      <div
        className={styles.dispatch_data}
        style={mode ? { borderColor: "transparent" } : {}}
      >
        <div className={styles.dispatch_data_filters}>
          <div>
            <div
              className={`${styles.dispatch_data_search} ${styles.dispatch_data_search_dark}`}
            >
              <NavbarSearchIcon color={mode ? "#FFFFFF" : "#646B88"} />
            </div>
            <SlidingFilter itemList={slidingFilter2} />
          </div>

          <div className={styles.dispatch_data_filters_dd}>
            <DropDown label={"Source"} listLabel={"All"} />
            <DropDown label={"Warehouse"} listLabel={"All"} />
            <DropDown label={"Manager"} listLabel={"All"} />
            <DropDown label={"Sort By"} listLabel={"All"} />
          </div>
        </div>

        <ul className={styles.dispatch_data_list}>
          <li
            className={`${styles.dispatch_data_list_item} ${
              styles.dispatch_item_header
            } ${mode ? styles.dispatch_item_header_dark : ""}`}
          >
            {dispatchHeader.map((h) => (
              <div className="col" key={h}>
                {h}
              </div>
            ))}
          </li>
          <div className={styles.dispatch_data_list_container}>
            {[...Array(20)].map((_, index) => (
              <li
                key={index}
                className={`${styles.dispatch_data_list_item} ${
                  styles.dispatch_item_value
                } ${mode ? styles.dispatch_item_value_dark : ""}`}
              >
                {dispatchData.map((d) => (
                  <div className="col" key={d}>
                    {d}
                  </div>
                ))}
              </li>
            ))}
          </div>
        </ul>
      </div>
    </div>
  );
};

export default Test;

// import React from "react";
// import styles from "../../../pages/ordermanager/Orders.module.css";
// import Button from "../Dealers/Button";
// import {
//   TestTopBackArrowIcon,
//   TestTopDotIcon,
//   ChatIcon,
//   RemoveIcon,
//   DividerIcon,
//   SaveAsDraftIcon,
//   TestTopAddDealerIcon,
//   RightArrowIcon,
//   LeftArrowIcon,
// } from "../../icons/Icon";
// import { useAuth } from "../../context/Auth";
// import { useNavigate } from "react-router-dom";
// import SlidingFilter from "../Dealers/SlidingFilter";
// import InputTag from "../FormComponents/InputTag";
// import VerticalDropDown from "../FormComponents/VerticalDropDown";

// const Test = () => {
//   const { mode } = useAuth();
//   const navigate = useNavigate();
//   const routeName = "Sale Order";
//   const slidingFilter = [
//     "Sales",
//     "Purchase",
//     "Adjustment",
//     "Transfer",
//     "Production",
//     "Production & Sale",
//     "Production & Transfer",
//   ];
//   const orderHeader = [
//     "S.No",
//     "Item Description",
//     "Product",
//     "Warehouse",
//     "Quantity",
//     "Price",
//     "Unit",
//     "Amount",
//   ];
//   const orderData = [
//     "1",
//     "3D-30001 WALLPAPER",
//     "3D-30001 WALLPAPER",
//     "Golaganj",
//     "23",
//     "65.00",
//     "Roll",
//     "0000.00",
//   ];

//   return (
//     <>
//       <div
//         className={styles.order}
//         style={mode ? { backgroundColor: "#2C2E33", border: "none" } : {}}
//       >
//         <div className={styles.order_top}>
//           <div className={styles.order_top_left}>
//             <div
//               className={styles.order_top_left_go_back}
//               onClick={() => navigate(-1)}
//             >
//               <TestTopBackArrowIcon color={mode ? "#ffffff" : "#0100E4"} />
//               <p
//                 style={{
//                   fontSize: "16px",
//                   fontWeight: "500",
//                   lineHeight: "24px",
//                   margin: 0,
//                   paddingTop: "0.21rem",
//                   color: mode ? "#FFFFFF" : "#0100E4",
//                 }}
//               >
//                 Go Back
//               </p>
//             </div>
//             <TestTopDotIcon color={mode ? "#E4E7EB" : "#646B88"} />
//             <p
//               className={styles.order_top_left_path_text}
//               style={{
//                 color: mode ? "#9C9797" : "#646B88",
//               }}
//             >
//               {routeName}
//             </p>
//           </div>
//           <div className={styles.order_right}>
//             <div>
//               <SlidingFilter itemList={slidingFilter} />
//             </div>
//             <div>
//               <Button
//                 buttonIcon={<ChatIcon />}
//                 buttonText={"Chat"}
//                 buttonBgColor={"#4164E3"}
//                 buttonTextColor={"#ffffff"}
//                 buttonBorder={"none"}
//               />
//             </div>
//           </div>
//         </div>

//         <div className={styles.so_scroll_wrapper}>
//           <form className={styles.so_form}>
//             <div className={styles.so_form_1}>
//               <div>
//                 <InputTag
//                   labelText={"Dealers Name / Dealer / Dealer ID"}
//                   placeholderText={"Asmit Singh"}
//                 />
//               </div>
//               <div>
//                 <InputTag
//                   labelText={"Billing Address"}
//                   placeholderText={"All"}
//                 />
//               </div>
//               <div>
//                 <VerticalDropDown
//                   dropDownText={"Auto"}
//                   label={"Warehouse"}
//                   height={"40px"}
//                 />
//               </div>
//               <div>
//                 <InputTag
//                   labelText={"Dispatch Manager"}
//                   placeholderText={"Choose sub method"}
//                 />
//               </div>
//             </div>
//             <div className={styles.so_form_1}>
//               <div>
//                 <div style={{ width: "50%" }}>
//                   <InputTag
//                     labelText={"Name"}
//                     placeholderText={"ABS & Company"}
//                   />
//                 </div>
//                 <div style={{ width: "50%" }}>
//                   <InputTag
//                     labelText={"Mobile Number"}
//                     placeholderText={"+91 | 9451156705"}
//                   />
//                 </div>
//               </div>
//               <div>
//                 <InputTag
//                   labelText={"Street Address"}
//                   placeholderText={"All"}
//                 />
//               </div>
//               <div>
//                 <div style={{ width: "33%" }}>
//                   <InputTag labelText={"PIN Code"} placeholderText={"226001"} />
//                 </div>
//                 <div style={{ width: "33%" }}>
//                   <InputTag labelText={"City"} placeholderText={"Lucknow"} />
//                 </div>
//                 <div style={{ width: "33%" }}>
//                   <InputTag
//                     labelText={"District"}
//                     placeholderText={"Lucknow"}
//                   />
//                 </div>
//               </div>
//               <div>
//                 <div style={{ width: "50%" }}>
//                   <InputTag
//                     labelText={"State"}
//                     placeholderText={"Uttar Pradesh"}
//                   />
//                 </div>
//                 <div style={{ width: "50%" }}>
//                   <InputTag
//                     labelText={"Delivery Method"}
//                     placeholderText={"Choose sub method"}
//                   />
//                 </div>
//               </div>
//             </div>
//           </form>

//           <ul className={styles.so_data_list}>
//             <li
//               className={styles.so_data_list_row}
//               style={{
//                 backgroundColor: "transparent",
//                 color: mode ? "#fafafa" : "#646B88",
//                 borderColor: "transparent",
//                 fontWeight: "600",
//                 padding: "0 10px",
//               }}
//             >
//               <>
//                 {orderHeader.map((h) => (
//                   <div className="col" key={h}>
//                     {h}
//                   </div>
//                 ))}
//                 <div style={{ marginLeft: "5px", visibility: "hidden" }}>
//                   <RemoveIcon />
//                 </div>
//               </>
//             </li>
//             <div className={styles.so_data_list_container}>
//               {[...Array(24)].map((_, index) => (
//                 <div>
//                   <li
//                     key={index}
//                     className={styles.so_data_list_row}
//                     style={
//                       mode
//                         ? {
//                             backgroundColor: "#1B1D21",
//                             color: "#fafafa",
//                             border: "1px solid #646B88",
//                           }
//                         : { backgroundColor: "#FFFFFF" }
//                     }
//                   >
//                     {orderData.map((d) => (
//                       <>
//                         <div className="col" key={d}>
//                           {d}
//                         </div>
//                       </>
//                     ))}
//                   </li>
//                   <div style={{ marginLeft: "5px", cursor: "pointer" }}>
//                     <RemoveIcon />
//                   </div>
//                 </div>
//               ))}
//             </div>
//           </ul>
//           <div className={styles.so_instc}>
//             <p>Press Enter to Add Item</p>
//             <DividerIcon />
//             <p>Double Enter for Next Option</p>
//             <DividerIcon />
//             <p>Ctrl + Backspace for Remove Item</p>
//           </div>
//         </div>

//         <div
//           className={styles.so_footer}
//           style={
//             mode
//               ? {
//                   background:
//                     "linear-gradient(93.25deg, rgba(44, 46, 51, 0.9) 0%, rgba(44, 46, 51, 0.9) 100%)",
//                 }
//               : {}
//           }
//         >
//           <div className={styles.so_footer_top}>
//             <div className={styles.so_footer_top_left}>
//               <div style={{ marginLeft: "auto" }}>
//                 <Button
//                   buttonIcon={<TestTopAddDealerIcon />}
//                   buttonText={"Add Receipt"}
//                   buttonBgColor={"#000000"}
//                   buttonTextColor={"#ffffff"}
//                   buttonBorder={"none"}
//                 />
//               </div>
//               <div
//                 className={styles.so_receipt_data}
//                 style={mode ? { backgroundColor: "#1B1D21" } : {}}
//               >
//                 <ul
//                   style={
//                     mode ? { backgroundColor: "#2C2E33", color: "#fff" } : {}
//                   }
//                 >
//                   <li className="col">Receipt Number</li>
//                   <li className="col">Payment Method</li>
//                   <li className="col">Amount</li>
//                   <li className="col">Status</li>
//                 </ul>
//                 <ul
//                   style={
//                     mode ? { backgroundColor: "#232529", color: "#fff" } : {}
//                   }
//                 >
//                   <li className="col">07865</li>
//                   <li className="col">Cash On Delivery (COD)</li>
//                   <li className="col">6709.00</li>
//                   <li className="col" style={{ color: "#FB9600" }}>
//                     Pending
//                   </li>
//                 </ul>
//               </div>
//             </div>
//             <div className={styles.so_footer_top_right}>
//               <div
//                 className={styles.so_footer_top_right_1}
//                 style={
//                   mode ? { backgroundColor: "#1B1D21", color: "#fff" } : {}
//                 }
//               >
//                 <div>
//                   <p>Sub Total:</p>
//                   <p>7000.00</p>
//                 </div>
//                 <div className={styles.so_shipping}>
//                   <p>Shipping Charges:</p>
//                   <input type="number" />
//                   <div className={styles.so_footer_top_right_1_check}>
//                     <p>To Pay</p>
//                     <input type="checkbox" />
//                   </div>
//                   <p>250.00</p>
//                 </div>
//                 <div>
//                   <p>Total Amount(INR):</p>
//                   <p>7250.00</p>
//                 </div>
//               </div>
//               <div className={styles.so_footer_top_right_2}>
//                 <div>
//                   <Button
//                     buttonIcon={<RightArrowIcon />}
//                     buttonText={"Back"}
//                     buttonBgColor={"#000000"}
//                     buttonTextColor={"#ffffff"}
//                     buttonBorder={"none"}
//                   />
//                 </div>
//                 <div>
//                   <Button
//                     buttonIcon={<SaveAsDraftIcon />}
//                     buttonText={"Save as Draft"}
//                     buttonBgColor={"#000000"}
//                     buttonTextColor={"#ffffff"}
//                     buttonBorder={"none"}
//                   />
//                 </div>

//                 <div>
//                   {" "}
//                   <Button
//                     buttonIcon={<LeftArrowIcon />}
//                     buttonText={"Next"}
//                     buttonBgColor={"#4164E3"}
//                     buttonTextColor={"#ffffff"}
//                     buttonBorder={"none"}
//                   />
//                 </div>
//               </div>{" "}
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default Test;

// import React, { useEffect, useState } from "react";
// import { useAuth } from "../../components/context/Auth";
// import styles from "../ordermanager/DishpatchManager.module.css";
// import { useNavigate, useSearchParams } from "react-router-dom";
// import OrderModal from "./OrderModal";
// import { GreenButton, RedOutButton } from "../../components/Buttons";
// import Modal from "../../components/Modal";
// import Pagination from "../../components/Paginantion";
// import OrdersTab from "../../components/OrdersTab";

// const AllOrders = () => {
//   const navigate = useNavigate();
//   const [searchparams] = useSearchParams();
//   const page = searchparams.get("page")
//     ? parseInt(searchparams.get("page"))
//     : 1;
//   const { switchDisplay, managerId } = useAuth();
//   const [msgShow, setMsgShow] = useState({
//     status: "",
//     msg: "",
//   });
//   const [orders, setOrders] = useState("");
//   const [allData, setAllData] = useState([]);
//   const [showItemModal, setShowItemModal] = useState(false);
//   const [showAcceptModal, setShowAcceptModal] = useState(false);
//   const [orderData, setOrderData] = useState({});
//   const [pages, setPages] = useState(1);
//   const [loading, setLoading] = useState(false);
//   const [sortKey, setSortKey] = useState("");
//   const [orderStatus, setOrderStatus] = useState("");
//   const [orderType, setOrderType] = useState("");
//   const [keyword, setKeyword] = useState("");
//   const [warehouses, setWarehouses] = useState([]);
//   const [warehouseName, setWarehouseName] = useState("");
//   const [managers, setManagers] = useState([]);
//   const [managerName, setManagerName] = useState("");
//   const [daysTab, setDaysTab] = useState("today");
//   const [startDate, setStartDate] = useState("");
//   const [endDate, setEndDate] = useState("");

//   useEffect(() => {
//     if (managerId) {
//       setLoading(true);
//       var myHeaders = new Headers();
//       myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
//       myHeaders.append("Content-Type", "application/json");

//       var raw = JSON.stringify({
//         managerId: parseInt(managerId),
//         keyword: keyword,
//         sortKey: sortKey,
//         orderType: orderType,
//         orderStatus: orderStatus,
//         cartType: "",
//         warehousename: warehouseName,
//         ordermanagerId: managerName,
//         day: daysTab,
//         startDate: startDate,
//         endDate: endDate,
//       });

//       var requestOptions = {
//         method: "POST",
//         headers: myHeaders,
//         body: raw,
//         redirect: "follow",
//       };
//       // fetch('http://192.168.0.117:5055/order/orders?page=' + page + '&limit=8', requestOptions)
//       fetch(
//         process.env.REACT_APP_URL + "order/allorders?page=" + page + "&limit=8",
//         requestOptions
//       )
//         .then((response) => response.json())
//         .then((result) => {
//           if (result.status === "success") {
//             setLoading(false);
//             setPages(result.pages);
//             setAllData(result.data);
//             setOrders(result);
//             if (result.warehouses) {
//               setWarehouses(result.warehouses);
//             }
//             if (result.managers) {
//               setManagers(result.managers);
//             }
//           }
//         })
//         .catch((error) => console.log("error", error));
//     }
//   }, [
//     managerId,
//     page,
//     sortKey,
//     orderType,
//     keyword,
//     warehouseName,
//     managerName,
//     daysTab,
//     endDate,
//     startDate,
//     orderStatus,
//   ]);

//   const handleOrderAccept = (orderId) => {
//     setMsgShow({
//       status: "",
//       msg: "",
//     });
//     var myHeaders = new Headers();
//     myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
//     myHeaders.append("Content-Type", "application/json");

//     var raw = JSON.stringify({
//       managerId: managerId,
//       orderId: orderId,
//     });

//     var requestOptions = {
//       method: "POST",
//       headers: myHeaders,
//       body: raw,
//       redirect: "follow",
//     };

//     fetch(process.env.REACT_APP_URL + "order/acceptorder", requestOptions)
//       .then((response) => response.json())
//       .then((result) => {
//         if (result.status === "success") {
//           setShowItemModal(false);
//         }
//         setMsgShow({
//           status: result.status,
//           msg: result.msg,
//         });
//         setShowAcceptModal(true);
//       })
//       .catch((error) => console.log("error", error));
//   };

//   return (
//     <React.Fragment>
//       <div
//         className={`${!switchDisplay ? styles.bg_dark : styles.bg_white} ${
//           styles.ff
//         }`}
//       >
//         <OrdersTab
//           styles={styles}
//           orders={orders}
//           sortKey={sortKey}
//           setSortKey={setSortKey}
//           loading={loading}
//           orderType={orderType}
//           setOrderType={setOrderType}
//           keyword={keyword}
//           setKeyword={setKeyword}
//           warehouses={warehouses}
//           warehouseName={warehouseName}
//           setWarehouseName={setWarehouseName}
//           managers={managers}
//           setManagers={setManagers}
//           managerName={managerName}
//           setManagerName={setManagerName}
//           daysTab={daysTab}
//           setDaysTab={setDaysTab}
//           endDate={endDate}
//           setEndDate={setEndDate}
//           startDate={startDate}
//           setStartDate={setStartDate}
//           setOrderStatus={setOrderStatus}
//           orderStatus={orderStatus}
//         />
//         {loading ? (
//           <div className={"order_loadingMain"}>
//             <img src="/wallicon.gif" alt="walliconGIF" />
//           </div>
//         ) : (
//           <>
//             <div className={styles.order_heightFix_div}>
//               <table className={styles.itemTable}>
//                 <thead>
//                   <tr className={styles.item}>
//                     <th> Name & City </th>
//                     <th>Cart Type</th>
//                     <th>Date</th>
//                     <th>Order Id</th>
//                     <th>Order Manager</th>
//                     <th>Source</th>
//                     <th>Amount</th>
//                     <th>Status</th>
//                     <th>Last Action</th>
//                   </tr>
//                 </thead>

//                 <tbody>
//                   {allData &&
//                     allData.map((val, index) => (
//                       <tr
//                         key={index}
//                         className={styles.item}
//                         onClick={() => {
//                           if (val.status !== "New Order") {
//                             navigate(
//                               "/orders/saleorder?orderId=" + val.orderId
//                             );
//                           } else {
//                             setShowItemModal(true);
//                             setOrderData(val);
//                           }
//                         }}
//                       >
//                         <td>{val.storeName + ", " + val.city}</td>
//                         <td>{val.cartType}</td>
//                         <td>{val.orderDate}</td>
//                         <td>{val.orderId}</td>
//                         <td>{val.ordermanager}</td>
//                         <td>{val.orderType}</td>
//                         <td>{val.totalAmount}</td>
//                         <td
//                           className={`${
//                             val.status === "ordered" || val.status === "Packed"
//                               ? styles.table_txt_green
//                               : val.status === "Not Packed" ||
//                                 val.status === "ordered Pending" ||
//                                 val.status === "Payment Under Verification" ||
//                                 val.status === "Request For Edit" ||
//                                 val.status === "Edit request Approved" ||
//                                 val.status === "Request For Cancel"
//                               ? styles.table_txt_red
//                               : val.status === "Packing In Progress" ||
//                                 val.status === "Draft" ||
//                                 val.status === "In Progress" ||
//                                 val.status === "Payment Pending"
//                               ? styles.table_txt_yellow
//                               : val.status === "New Order"
//                               ? styles.table_txt_blue
//                               : ""
//                           }`}
//                         >
//                           {val.status}
//                         </td>
//                         {/* <td className={`${val.status === 'In Progress' ? styles.table_txt_yellow : val.status === 'Updated' ? styles.table_txt_green : styles.table_txt_red}`}>{val.status} </td> */}
//                         <td>{val.lastAction} </td>
//                       </tr>
//                     ))}
//                 </tbody>
//               </table>
//             </div>
//             <Pagination total={pages} current={page} />
//           </>
//         )}
//         <OrderModal
//           show={showItemModal}
//           close={setShowItemModal}
//           closeBtn={true}
//           heading={orderData.storeName}
//           content={
//             <div className={styles.text_center}>
//               <div className={styles.product_date}>
//                 <span>Dealer ID: {orderData.dealerId}</span>
//                 <span> {orderData.orderDate}</span>
//               </div>
//               <div className={styles.product_date}>
//                 Order ID: {orderData.orderId}
//               </div>

//               <div className={styles.itemCard_div}>
//                 {orderData.items &&
//                   orderData.items.map((item, index) => (
//                     <div
//                       key={index}
//                       className={
//                         switchDisplay ? styles.itemCard_light : styles.itemCard
//                       }
//                     >
//                       <div className={styles.itemCard_left}>
//                         <img
//                           src={process.env.REACT_APP_S3URL + item.thumbnail}
//                           alt="item_Image"
//                           className={styles.itemImage}
//                         />
//                         <div className={styles.itemDetail_div}>
//                           <div className={styles.itemDetail}>
//                             <span className={styles.itemName_txt}>
//                               Product:
//                             </span>
//                             <span className={styles.itemName}>
//                               {item.product_name}
//                             </span>
//                           </div>
//                           <div className={styles.itemDetail}>
//                             <span className={styles.itemName_txt}>
//                               Item No:
//                             </span>
//                             <span className={styles.itemName}>
//                               {item.itemNo}
//                             </span>
//                           </div>
//                           <div className={styles.itemDetail}>
//                             <span className={styles.itemName_txt}>Price:</span>
//                             <span className={styles.itemName}>
//                               {parseFloat(item.price).toLocaleString("en-IN", {
//                                 style: "currency",
//                                 currency: "INR",
//                               })}
//                               /{item.unit}
//                             </span>
//                           </div>
//                           <div className={styles.itemDetail}>
//                             <span className={styles.itemName_txt}>Qty:</span>
//                             <span className={styles.itemName}>
//                               {item.qty} {item.unit}
//                             </span>
//                           </div>
//                         </div>
//                       </div>
//                       <div className={styles.itemCard_right}>
//                         <span className={styles.itemName_txt}>Total:</span>
//                         <span className={styles.itemName}>
//                           &nbsp;
//                           {(
//                             parseFloat(item.price) * parseFloat(item.qty)
//                           ).toLocaleString("en-IN", {
//                             style: "currency",
//                             currency: "INR",
//                           })}
//                           /-
//                         </span>
//                       </div>
//                     </div>
//                   ))}
//               </div>

//               <div
//                 className={
//                   switchDisplay
//                     ? styles.modalFooter_div_light
//                     : styles.modalFooter_div
//                 }
//               >
//                 <div className={styles.modalFooter_btn}>
//                   <RedOutButton
//                     title="CANCEL"
//                     handleSubmit={() => setShowItemModal(false)}
//                   />
//                   <GreenButton
//                     title="ACCEPT"
//                     handleSubmit={() => handleOrderAccept(orderData.orderId)}
//                   />
//                 </div>
//                 <div className={styles.modalFooter_GTotal}>
//                   <div className={styles.borderBottom_div}>
//                     <div className={styles.total_div}>
//                       <span className={styles.itemName_txt}>Total</span>
//                       <span className={styles.total_rs}>
//                         {parseFloat(orderData.subtotal).toLocaleString(
//                           "en-IN",
//                           { style: "currency", currency: "INR" }
//                         )}
//                       </span>
//                     </div>
//                     <div className={styles.total_div}>
//                       <span className={styles.itemName_txt}>
//                         Shipping Charges
//                       </span>
//                       <span className={styles.total_rs}>
//                         {parseFloat(
//                           orderData.shippingInfo &&
//                             orderData.shippingInfo.shippingCharge
//                         ).toLocaleString("en-IN", {
//                           style: "currency",
//                           currency: "INR",
//                         })}
//                       </span>
//                     </div>
//                   </div>
//                   <div className={styles.grandTotal}>
//                     <span>Grand Total:</span>
//                     <span>
//                       {parseFloat(orderData.totalAmount).toLocaleString(
//                         "en-IN",
//                         { style: "currency", currency: "INR" }
//                       )}
//                     </span>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           }
//         />

//         <Modal
//           show={showAcceptModal}
//           close={setShowAcceptModal}
//           closeBtn={true}
//           heading="Order Status"
//           content={
//             <div className={styles.text_center}>
//               <p className={styles.message}>{msgShow.msg}</p>
//               <div className={styles.massageModal_btn}>
//                 <RedOutButton
//                   title="CANCEL"
//                   handleSubmit={() => setShowAcceptModal(false)}
//                 />
//                 <GreenButton
//                   title="OK"
//                   handleSubmit={() => {
//                     if (msgShow.status === "success") {
//                       navigate(
//                         "/orders/saleorder?orderId=" + orderData.orderId
//                       );
//                       setShowAcceptModal(false);
//                     } else {
//                       setShowAcceptModal(false);
//                     }
//                   }}
//                 />
//               </div>
//             </div>
//           }
//         />
//       </div>
//     </React.Fragment>
//   );
// };
// export default AllOrders;
