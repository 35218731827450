import React, { useState, useEffect } from 'react'
import { GreenButton, RedOutButton } from '../../components/Buttons'
import { useAuth } from '../../components/context/Auth';
import styles from './Marketing.module.css'

const CreateContactModal = ({ id, setId, displayModal, closeModal, setSuccess }) => {

    const { switchDisplay, managerId } = useAuth();

    const handleSubmit = (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            submitDetail()
        }

        // setValidated(true);
    };


    const [altMobile, setAltMobile] = useState(false)
    const [contactData, setContactData] = useState({
        name: "",
        companyName: "",
        designation: "",
        pincode: "",
        city: "",
        district: "",
        state: "",
        address: "",
        email: "",
        mobile: "",
        altNumber: "",
        gst: "",
        source: "",
    })
    const divStyle = {
        display: displayModal ? 'block' : 'none'
    };
    // function closeModal1(e) {
    //     setId("")
    //     setContactData({
    //         name: "",
    //         designation: "",
    //         city: "",
    //         pincode: "",
    //         state: "",
    //         district: "",
    //         mobile: "",
    //         gst: "",
    //         companyName: "",
    //         email: "",
    //         source: "",
    //         address: "",
    //         priority: ""
    //     })
    //     // e.stopPropagation()
    //     closeModal()
    // }

    const submitDetail = () => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);

        var raw = JSON.stringify({
            "contactId": id,
            "name": contactData.name,
            "companyName": contactData.companyName,
            "designation": contactData.designation,
            "address": contactData.address,
            "pincode": contactData.pincode,
            "city": contactData.city,
            "district": contactData.district,
            "state": contactData.state,
            "email": contactData.email,
            "mobile": contactData.mobile,
            "altNumber": contactData.altNumber,
            "gst": contactData.gst,
            "source": contactData.source,
            "managerId": managerId
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(process.env.REACT_APP_URL + "marketing/addcontact", requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status === "success") {
                    // closeModal1();
                    setSuccess(Math.random())
                    setContactData({
                        name: "",
                        designation: "",
                        city: "",
                        pincode: "",
                        state: "",
                        district: "",
                        mobile: "",
                        altNumber: "",
                        gst: "",
                        companyName: "",
                        email: "",
                        source: "",
                        address: "",
                        priority: ""
                    })
                    setId("");
                    alert("Contact Added Successfully")
                }
            })
            .catch(error => console.log('error', error));
    }
    useEffect(() => {

        if (id) {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);

            var raw = JSON.stringify({
                "contactId": id,
                "managerId": managerId
            });

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            fetch(process.env.REACT_APP_URL + "marketing/contact", requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.status === "success") {
                        if (result.data) {
                            setContactData(prevState => ({
                                ...prevState,
                                name: result.data.name ? result.data.name : "",
                                companyName: result.data.companyName ? result.data.companyName : "",
                                designation: result.data.designation ? result.data.designation : "",
                                city: result.data.city ? result.data.city : "",
                                address: result.data.address ? result.data.address : "",
                                email: result.data.email ? result.data.email : "",
                                mobile: result.data.mobile ? result.data.mobile : "",
                                altNumber: result.data.altNumber ? result.data.altNumber : "",
                                gst: result.data.gst ? result.data.gst : "",
                                source: result.data.source ? result.data.source : "",
                            }))
                        }
                    }
                })
                .catch(error => console.log('error', error));
        }
    }, [id, managerId])

    const [isUser, setIsUser] = useState("")

    useEffect(() => {
        if ((contactData.mobile.length === 10 || contactData.altNumber.length === 10 ) && !id) {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);

            var raw = JSON.stringify({
                "mobile": contactData.mobile,
            });

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            fetch(process.env.REACT_APP_URL + "marketing/checkmobile", requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.status === "Found") {
                        setIsUser(result.msg)
                    } else {
                        setIsUser("")
                    }
                    console.log(result)
                })
                .catch(error => console.log('error', error));
        }
        if (contactData.mobile.length !== 10) {
            setIsUser("")
        }
    }, [contactData.mobile, id, contactData.altNumber,])

    const [statesData, setStatesData] = useState({
        statesData: [],
        districtData: [],
    })

    useEffect(() => {
        if (contactData.pincode.length === 6 || contactData.city) {
            var myHeaders = new Headers();
            myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                "keyword": contactData.pincode ? contactData.pincode : contactData.city,
                "statename": contactData.state ? contactData.state : "",
            });

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            fetch(process.env.REACT_APP_URL + "locations/locations", requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.status === "success") {
                        setStatesData(prevState => ({
                            ...prevState,
                            statesData: result.states,
                            districtData: result.districts,
                        }))
                        if (result.states.length === 1) {
                            setContactData(prevState => ({
                                ...prevState,
                                state: result.states[0],
                            }))
                        }
                        if (result.districts.length === 1) {
                            setContactData(prevState => ({
                                ...prevState,
                                district: result.districts[0],
                            }))
                        }
                    }
                })
                .catch(error => console.log('error', error));
        }
    }, [contactData.pincode, contactData.city, contactData.state])

    function enforce_maxlength(e) {
        var t = e.target;
        if (t.hasAttribute('maxlength')) {
            t.value = t.value.slice(0, t.getAttribute('maxlength'));
        }
    }
    return (
        <React.Fragment>
            <div
                className={styles.custom_modal}
                onClick={closeModal}
                style={divStyle}>

                <form onSubmit={handleSubmit}>
                    <div className={`${switchDisplay ? styles.modal_content_white : styles.modal_content} ${styles.center} ${styles.text_center}`} onClick={e => e.stopPropagation()} >
                        <div className={styles.modal_body}>
                            <div className={styles.fs_30}>{id ? "Update" : "Create"} Contact</div>
                            <div className={styles.row}>
                                <div className={styles.col_5 + " " + styles.offset_1}>
                                    <div className={styles.text_start + " " + styles.mt_10}>Mobile Number <span className={styles.text_danger}>*</span></div>
                                    <div className={styles.mob}>
                                        <input
                                            required
                                            type="number"
                                            placeholder="Enter Mobile Number"
                                            className={`${switchDisplay ? styles.text_Box_Dark : styles.text_Box}`}
                                            value={contactData.mobile}
                                            onChange={(e) => {
                                                enforce_maxlength(e);
                                                setContactData({ ...contactData, mobile: e.target.value })
                                            }}
                                            maxLength="10"
                                            minLength={10}
                                            onWheel={(e) => e.target.blur()}
                                        />
                                        <input
                                            type="button"
                                            className={styles.addMob}
                                            value="+"
                                            onClick={() => setAltMobile(true)}
                                        />
                                        {/* <button>+</button> */}
                                    </div>
                                    <p className={styles.marketing_mobile_alert}>{contactData.mobile && contactData.mobile.length < 10 ? "Please Enter 10 Digit.." : isUser}</p>

                                    {altMobile && <div >
                                        <div className={styles.text_start + " " + styles.mt_10}>Alternate Number <span className={styles.text_danger}>*</span></div>
                                        <div className={styles.mob}>
                                            <input
                                                required
                                                type="number"
                                                placeholder="Enter Mobile Number"
                                                className={`${switchDisplay ? styles.text_Box_Dark : styles.text_Box}`}
                                                value={contactData.altNumber}
                                                onChange={(e) => {
                                                    enforce_maxlength(e);
                                                    setContactData({ ...contactData, altNumber: e.target.value })
                                                }}
                                                maxLength="10"
                                                minLength={10}
                                                onWheel={(e) => e.target.blur()}
                                            />
                                            <input
                                                type="button"
                                                className={styles.removeMob}
                                                value="-"
                                                onClick={() => {
                                                    setAltMobile(false);
                                                    setContactData({ ...contactData, altNumber: "" });
                                                }}
                                            />
                                        </div>
                                        <p className={styles.marketing_mobile_alert}>{contactData.altNumber && contactData.altNumber.length < 10 ? "Please Enter 10 Digit.." : isUser}</p>
                                    </div>
                                    }

                                    <div className={styles.text_start + " " + styles.mt_10}>Company Name <span className={styles.text_danger}>*</span></div>
                                    <div>
                                        <input
                                            required
                                            type="text"
                                            placeholder="Enter Company Name"
                                            className={`${switchDisplay ? styles.text_Box_Dark : styles.text_Box}`}
                                            value={contactData.companyName}
                                            onChange={(e) => setContactData({ ...contactData, companyName: e.target.value })}
                                        />
                                    </div>

                                    <div className={styles.text_start + " " + styles.mt_10}>Street Address <span className={styles.text_danger}>*</span></div>
                                    <div>
                                        <input
                                            required
                                            type="textarea"
                                            placeholder="Enter Street Address"
                                            className={`${switchDisplay ? styles.text_Box_Dark : styles.text_Box}`}
                                            value={contactData.address}
                                            onChange={(e) => setContactData({ ...contactData, address: e.target.value })}
                                        />
                                    </div>
                                    <div className={styles.text_start + " " + styles.mt_10}>Pincode <span className={styles.text_danger}>*</span></div>
                                    <div>
                                        <input
                                            // required
                                            type="number"
                                            placeholder="Enter Pincode"
                                            className={`${switchDisplay ? styles.text_Box_Dark : styles.text_Box}`}
                                            value={contactData.pincode}
                                            onChange={(e) => setContactData({ ...contactData, pincode: e.target.value })}
                                        />
                                    </div>
                                    <div className={styles.text_start + " " + styles.mt_10}>City <span className={styles.text_danger}>*</span></div>
                                    <div className={styles.cityMain}>

                                        <input
                                            // required
                                            type="text"
                                            placeholder="Enter City"
                                            className={`${switchDisplay ? styles.text_Box_Dark : styles.text_Box}`}
                                            value={contactData.city}
                                            onChange={(e) => setContactData({ ...contactData, city: e.target.value.toLocaleLowerCase().replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase()) })}
                                        />
                                    </div>
                                    <div className={styles.text_start + " " + styles.mt_10}>District <span className={styles.text_danger}>*</span></div>
                                    <div className={styles.cityMain}>
                                        <select className={`${switchDisplay ? styles.bg_dark : styles.text_Box}`} value={contactData.district} onChange={(e) => setContactData({ ...contactData, district: e.target.value })}>
                                            <option className={styles.bg_dark} value="">Select District </option>

                                            {statesData.districtData && statesData.districtData.map((item, index) => {
                                                return <option className={styles.bg_dark} key={index} value={item.Name}>{item}</option>
                                            }
                                            )}
                                        </select>
                                    </div>


                                </div>
                                <div className={styles.col_5 + " " + styles.offset_1}>
                                    <div className={styles.text_start + " " + styles.mt_10}>Name</div>
                                    <div>
                                        <input
                                            // required
                                            type="text"
                                            placeholder="Enter Name"
                                            className={`${switchDisplay ? styles.text_Box_Dark : styles.text_Box}`}
                                            value={contactData.name}
                                            onChange={(e) => setContactData({ ...contactData, name: e.target.value })}
                                        />
                                    </div>


                                    <div className={styles.text_start + " " + styles.mt_10}>Email Address</div>
                                    <div>
                                        <input
                                            // required
                                            type="text"
                                            placeholder="Enter Email Address"
                                            className={`${switchDisplay ? styles.text_Box_Dark : styles.text_Box}`}
                                            value={contactData.email}
                                            onChange={(e) => setContactData({ ...contactData, email: e.target.value })}
                                        />
                                    </div>

                                    <div className={styles.text_start + " " + styles.mt_10}>Designation</div>
                                    <div>
                                        <select className={`${switchDisplay ? styles.bg_dark : styles.text_Box}`} value={contactData.designation} onChange={(e) => setContactData({ ...contactData, designation: e.target.value })}>
                                            <option className={styles.bg_dark} value="">Select Designation </option>
                                            <option className={styles.bg_dark} value="Owner">Owner</option>
                                            <option className={styles.bg_dark} value="Manager">Manager</option>
                                            <option className={styles.bg_dark} value="Designer">Designer</option>
                                            <option className={styles.bg_dark} value="Contractor">Contractor</option>
                                            <option className={styles.bg_dark} value="Other">Other</option>
                                        </select>
                                    </div>

                                    <div className={styles.text_start + " " + styles.mt_10}>Source</div>
                                    <div>
                                        <select
                                            // required
                                            name='source'
                                            className={`${switchDisplay ? styles.text_Box_Dark : styles.text_Box}`}
                                            value={contactData.source}
                                            onChange={(e) => setContactData({ ...contactData, source: e.target.value })}
                                        >
                                            <option value="" className={styles.bg_dark}>Select Source</option>
                                            <option value="Google" className={styles.bg_dark}>Google</option>
                                            <option value="Just Dial" className={styles.bg_dark}>Just Dial</option>
                                            <option value="India Mart" className={styles.bg_dark}>India Mart</option>
                                            <option value="Facebook" className={styles.bg_dark}>Facebook</option>
                                            <option value="Instagram" className={styles.bg_dark}>Instagram</option>
                                            <option value="Linkedin" className={styles.bg_dark}>Linkedin</option>
                                            <option value="Twitter" className={styles.bg_dark}>Twitter</option>
                                            <option value="Referral" className={styles.bg_dark}>Referral</option>
                                            <option value="Direct" className={styles.bg_dark}>Direct</option>
                                            <option value="Other" className={styles.bg_dark}>Other</option>
                                        </select>
                                    </div>

                                    <div className={styles.text_start + " " + styles.mt_10}>State <span className={styles.text_danger}>*</span></div>
                                    <div className={styles.cityMain}>
                                        <select className={`${switchDisplay ? styles.bg_dark : styles.text_Box}`} value={contactData.state} onChange={(e) => setContactData({ ...contactData, state: e.target.value })}>
                                            <option className={styles.bg_dark} value="">Select State</option>

                                            {statesData.statesData && statesData.statesData.map((state, index) => {
                                                return <option className={styles.bg_dark} key={index} value={state}>{state}</option>
                                            }
                                            )}
                                        </select>
                                    </div>
                                    <div className={styles.text_start + " " + styles.mt_10}>GSTUIN (optional)</div>
                                    <div>
                                        <input
                                            type="text"
                                            placeholder="Enter GSTUIN"
                                            className={`${switchDisplay ? styles.text_Box_Dark : styles.text_Box}`}
                                            value={contactData.gst}
                                            onChange={(e) => setContactData({ ...contactData, gst: e.target.value })}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={styles.text_center + " " + styles.mt_40}>
                            <RedOutButton btnType="button" title="CANCEL" css={styles.dangerBtn + " " + styles.mouse} handleSubmit={closeModal} />
                            <GreenButton btnType="submit" title={id ? "UPDATE" : "SAVE"} css={styles.greenBtn + " " + styles.mouse + " " + styles.ms_15p} />
                        </div>
                    </div>
                </form>
            </div>
        </React.Fragment>
    )
}

export default CreateContactModal