// import React, { useState, useRef, useEffect } from "react";
// import { useNavigate, useSearchParams } from "react-router-dom";
// import {
//   GreenButton,
//   GreenOutButton,
//   RedOutButton,
//   WhiteOutButton,
// } from "../../components/Buttons";
// import { useAuth } from "../../components/context/Auth";
// import {
//   CrossCircleIcon,
//   DeleteIcon,
//   DemoImageIcon,
//   Search,
//   Spinner,
//   UpLoadFile,
// } from "../../components/icons/Icon";
// import Modal from "../../components/Modal";
// import PaymentModal from "../../components/PaymentModal";
// import VouchersTab from "../../components/VouchersTab";
// import DealerSearch from "./DealerSearch";
// import LiveSearch from "./LiveSearch";
// import styles from "./Order.module.css";
// // import States from "./States.json"

// const Estimate = () => {
//   const { managerId } = useAuth();
//   const [searchParams] = useSearchParams();
//   const navigate = useNavigate();
//   const orderId = searchParams.get("orderId")
//     ? searchParams.get("orderId")
//     : "";

//   // const [activeTab, setActiveTab] = useState("sale")
//   const [wareHouseWise, setWareHouseWise] = useState("");
//   const [results, setResults] = useState();
//   const [searchItem, setSearchItem] = useState("");
//   const [addDealer, setAddDealer] = useState(false);
//   const [showCODModal, setShowCODModal] = useState(false);
//   const [shippingInfo, setShippingInfo] = useState("");
//   const [generalInfo, setGeneralInfo] = useState("");
//   const [showAddItem, setShowAddItem] = useState(false);
//   const [showCancelOrderModal, setShowCancelOrderModal] = useState(false);
//   const [loading, setLoading] = useState(false);
//   const [loadingMoveToSale, setLoadingMoveToSale] = useState(false);
//   const [moveToSaleModal, setMoveToSaleModal] = useState(false);
//   const [loadingLocation, setLoadingLocation] = useState(false);
//   const [showPaymentModal, setShowPaymentModal] = useState(false);
//   const [reciptsInfo, setReciptsInfo] = useState([]);
//   const [reload, setReload] = useState(false);
//   const [accounts, setAccounts] = useState([]);
//   const [loadingReceipt, setLoadingReceipt] = useState(false);
//   const [reciptData, setReciptData] = useState({
//     paymentMethod: "",
//     paymentType: "",
//     paymentAmount: "",
//     transactionId: "",
//     accountNumber: "",
//   });
//   // const [ImgData, setImgData] = useState()
//   const [isImage, setisImage] = useState();
//   // const [msgShow, setMsgShow] = useState("");

//   const qtyFocus = useRef();

//   const [itemsdetails, setItemsdetails] = useState([
//     {
//       accessories: [],
//       itemNo: "",
//       product_name: "",
//       collection_name: "",
//       warehouse: "",
//       cartType: "",
//       qty: "",
//       dp: "",
//       unit: "",
//       amount: "",
//       totalQty: "",
//       desc: "",
//       descKey: "",
//     },
//   ]);

//   const [error] = useState({
//     dp: "",
//     index: "",
//   });
//   // const [error, setErrors] = useState({
//   //     dp: "",
//   //     index: ""
//   // })

//   // handle input change
//   const handleInputChange = (e, index) => {
//     const { name, value } = e.target;
//     const list = [...itemsdetails];
//     list[index][name] = value;
//     setItemsdetails(list);
//   };

//   // handle click event of the Remove button
//   const handleRemoveClick = (index) => {
//     const list = [...itemsdetails];
//     list.splice(index, 1);
//     setItemsdetails(list);
//   };

//   // handle click event of the Add button
//   const handleAddClick = () => {
//     if (error.dp) {
//       alert(error.dp);
//     } else {
//       setSearchItem("");
//       setItemsdetails([
//         ...itemsdetails,
//         {
//           itemNo: "",
//           product_name: "",
//           collection_name: "",
//           qty: "",
//           price: "",
//           unit: "",
//           amount: "",
//         },
//       ]);
//     }
//   };

//   useEffect(() => {
//     if (!searchItem) {
//       setResults([]);
//     }

//     if (searchItem) {
//       var myHeaders = new Headers();
//       myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
//       myHeaders.append("Content-Type", "application/json");

//       var raw = JSON.stringify({
//         managerId: managerId,
//         keyword: searchItem,
//         // "cartType": generalInfo.cartType,
//         warehousename: wareHouseWise,
//         orderType: generalInfo.orderType,
//       });

//       var requestOptions = {
//         method: "POST",
//         headers: myHeaders,
//         body: raw,
//         redirect: "follow",
//       };

//       fetch(
//         process.env.REACT_APP_URL + "order/searchitems?page=",
//         requestOptions
//       )
//         .then((response) => response.json())
//         .then((result) => {
//           if (result.status === "success") {
//             setResults(result.data);
//           }
//         })
//         .catch((error) => console.log("error", error));
//     }
//   }, [wareHouseWise, searchItem, managerId, generalInfo.orderType]);

//   function enforce_maxlength(e) {
//     var t = e.target;
//     if (t.hasAttribute("maxlength")) {
//       t.value = t.value.slice(0, t.getAttribute("maxlength"));
//     }
//   }

//   const unitRef = useRef();
//   const priceRef = useRef();
//   const descRef = useRef();

//   // const searchItems = results && results.filter(item => !itemsdetails.some(obj => item.itemNo === obj.itemNo && item.warehouse === obj.warehouse))

//   // const billingAddress = generalInfo.street && generalInfo.street + " " + generalInfo.city && generalInfo.street + " " + generalInfo.city + " " +
//   //     generalInfo.state && generalInfo.street + " " + generalInfo.city + " " + generalInfo.state + " " + generalInfo.pincode &&
//   //     generalInfo.street + " " + generalInfo.city + " " + generalInfo.state + " " + generalInfo.pincode

//   const [warehouses, setWarehouses] = useState([]);
//   // const [dispatchManagers, setDispatchManagers] = useState([])
//   useEffect(() => {
//     if (!orderId) {
//       setGeneralInfo("");
//     }
//   }, [orderId]);

//   useEffect(() => {
//     if (managerId) {
//       const fetchOrder = async () => {
//         var myHeaders = new Headers();
//         myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
//         myHeaders.append("Content-Type", "application/json");

//         var raw = JSON.stringify({
//           managerId: managerId,
//           estimateId: orderId,
//           // "warehousename": ""
//         });

//         var requestOptions = {
//           method: "POST",
//           headers: myHeaders,
//           body: raw,
//           redirect: "follow",
//         };

//         await fetch(
//           process.env.REACT_APP_URL + "order/estimate",
//           requestOptions
//         )
//           // fetch("http://192.168.0.117:5055/order/estimate", requestOptions)
//           .then((response) => response.json())
//           .then((result) => {
//             if (result.status === "success") {
//               if (result.data.order) {
//                 setGeneralInfo((prevState) => ({
//                   ...prevState,
//                   dealerId: result.data.order.dealerId,
//                   storeName: result.data.order.storeName,
//                   dealerName: result.data.order.name,
//                   billingAddress: result.data.order.billingAddress,

//                   shippingType: result.data.order.shippingInfo.shippingType,
//                   shippingCharge: result.data.order.shippingInfo.shippingCharge,
//                   courierName: result.data.order.shippingInfo.courierName,
//                   partnerName: result.data.order.shippingInfo.partnerName,

//                   warehousename: result.data.order.warehousename
//                     ? result.data.order.warehousename
//                     : "",
//                   dmId: result.data.order.dmId,
//                   cartType:
//                     result.data.order.cartType === "Cart" ? "ocart" : "pcart",
//                   orderDate: result.data.order.orderDate,
//                   estimateId: result.data.order.estimateId,

//                   transactionScreenshot:
//                     result.data.order.transactionScreenshot,
//                   paymentMethod: result.data.order.paymentMethod,
//                   paymentType: result.data.order.paymentType,
//                   paymentStatus: result.data.order.paymentStatus,
//                   paymentAmount: result.data.order.paymentAmount,
//                   transferTransId: result.data.order.transferTransId,

//                   status: result.data.order.status,
//                   manageName: result.data.order.manageName,
//                   manageValue: result.data.order.manageValue,
//                   orderType: result.data.order.orderType,
//                 }));
//                 setShippingInfo(result.data.order.shippingInfo);
//                 setItemsdetails(result.data.order.items);
//                 setReciptsInfo(result.data.receipts);
//               }
//             }
//           })
//           .catch((error) => console.log("error", error));
//       };
//       fetchOrder();
//     }
//   }, [orderId, managerId, reload]);

//   useEffect(() => {
//     if (managerId) {
//       var myHeaders = new Headers();
//       myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
//       myHeaders.append("Content-Type", "application/json");

//       var raw = JSON.stringify({
//         managerId: managerId,
//         estimateId: orderId,
//         // "warehousename": generalInfo.warehousename
//       });

//       var requestOptions = {
//         method: "POST",
//         headers: myHeaders,
//         body: raw,
//         redirect: "follow",
//       };

//       fetch(process.env.REACT_APP_URL + "order/estimate", requestOptions)
//         // fetch("http://192.168.0.117:5055/order/estimate", requestOptions)
//         .then((response) => response.json())
//         .then((result) => {
//           if (result.status === "success") {
//             if (result.data.warehouses) {
//               setWarehouses(result.data.warehouses);
//             }
//             if (
//               result.data.dispatchmanagers &&
//               result.data.dispatchmanagers.length > 0
//             ) {
//               // setDispatchManagers(result.data.dispatchmanagers)
//               setAccounts(result.data.accounts);
//               setGeneralInfo((prevState) => ({
//                 ...prevState,
//                 dmId: result.data.dispatchmanagers[0].managerId,
//               }));
//             }
//           }
//         })
//         .catch((error) => console.log("error", error));
//     }
//   }, [orderId, managerId, generalInfo.warehousename]);

//   useEffect(() => {
//     if (shippingInfo.pincode) {
//       setLoadingLocation(true);
//       var myHeaders = new Headers();
//       myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
//       myHeaders.append("Content-Type", "application/json");

//       var raw = JSON.stringify({
//         keyword: shippingInfo.pincode,
//       });

//       var requestOptions = {
//         method: "POST",
//         headers: myHeaders,
//         body: raw,
//         redirect: "follow",
//       };

//       fetch(process.env.REACT_APP_URL + "locations/locations", requestOptions)
//         .then((response) => response.json())
//         .then((result) => {
//           if (result.status === "success") {
//             setShippingInfo((prevState) => ({
//               ...prevState,
//               city: result.SubDistName[0]
//                 ? result.SubDistName[0].toUpperCase()
//                 : "",
//               district: result.districts[0] ? result.districts[0] : "",
//               state: result.states[0] ? result.states[0] : "",
//             }));
//             setLoadingLocation(false);
//           }
//         })
//         .catch((error) => console.log("error", error));
//     }
//   }, [shippingInfo.pincode]);

//   const [couriers, setCouriers] = useState([]);
//   const [partners, setPartners] = useState("");

//   useEffect(() => {
//     if (managerId) {
//       var myHeaders = new Headers();
//       myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
//       myHeaders.append("Content-Type", "application/json");

//       var raw = JSON.stringify({
//         courierName: generalInfo.courierName,
//       });

//       var requestOptions = {
//         method: "POST",
//         headers: myHeaders,
//         body: raw,
//         redirect: "follow",
//       };

//       fetch(
//         process.env.REACT_APP_URL + "deliverymethod/getDeliveryMethod",
//         requestOptions
//       )
//         .then((response) => response.json())
//         .then((result) => {
//           if (result.status === "success") {
//             if (!generalInfo.courierName) {
//               setCouriers(result.data);
//               // if( result.data.courierName)
//               setGeneralInfo((prevState) => ({
//                 ...prevState,
//                 shippingType: "Standard",
//                 courierName: result.data[0].courierName,
//               }));
//             } else {
//               setPartners(result.data);
//               // setGeneralInfo(prevState => ({
//               //     ...prevState,
//               //     partnerName: result.data.partners[0].partnerName
//               // }))
//             }
//           }
//         })
//         .catch((error) => console.log("error", error));
//     }
//   }, [managerId, generalInfo.courierName]);

//   const cartTypeRef = useRef(null);
//   const itemseacrhRef = useRef(null);
//   const paymentRef = useRef(null);

//   const handleSubmit = (event) => {
//     event.preventDefault();
//     const form = event.currentTarget;
//     if (form.checkValidity() === false) {
//       event.preventDefault();
//       event.stopPropagation();
//     } else {
//       handleSaleOrder("success");
//     }
//   };

//   // const qty = itemsdetails.map(obj => parseFloat(obj.qty)).reduce((a, b) => a + b)
//   // const totalItems = itemsdetails.length

//   let cartPriceSum =
//     itemsdetails &&
//     itemsdetails.reduce((sum, currentItem) => {
//       return (
//         sum +
//         parseFloat(currentItem.price ? currentItem.price : 0) *
//           parseFloat(currentItem.qty ? currentItem.qty : 0)
//       );
//     }, 0);

//   var cartTotal =
//     itemsdetails &&
//     itemsdetails
//       .map((x) =>
//         !x.accessories
//           ? 0
//           : x.accessories
//               .map(
//                 (y) =>
//                   (y.accessoriesQty ? y.accessoriesQty : 0) * y.accessoriesPrice
//               )
//               .reduce((a, b) => a + b, 0)
//       )
//       .reduce((a, b) => a + b, 0);
//   let subtotal =
//     (cartPriceSum ? cartPriceSum : 0) + (cartTotal ? cartTotal : 0);
//   let gettotalAmount =
//     subtotal +
//     parseFloat(generalInfo.shippingCharge ? generalInfo.shippingCharge : 0) +
//     parseFloat(generalInfo.manageValue ? generalInfo.manageValue : 0);

//   let totalAmount = Math.round(gettotalAmount);
//   let roundedAmt = totalAmount ? (totalAmount - gettotalAmount).toFixed(2) : 0;

//   const handleSaleOrder = (status) => {
//     if (error.dp) {
//       alert(error.dp);
//     }
//     // else if (status === "success" && (parseFloat(totalAmount).toFixed(2) !== parseFloat(generalInfo.paymentAmount).toFixed(2))) {
//     //     alert("Payment amount is not matching with total amount")
//     // }
//     else {
//       setLoading(true);
//       var myHeaders = new Headers();
//       myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
//       myHeaders.append("Content-Type", "application/json");

//       var raw = JSON.stringify({
//         managerId: managerId,
//         estimateId: orderId, //estimateId
//         warehousename: generalInfo.warehousename,
//         // "dmId": generalInfo.dmId,
//         cartType: generalInfo.cartType,
//         dealerId: generalInfo.dealerId,
//         name: shippingInfo.name,
//         street: shippingInfo.street,
//         mobile: shippingInfo.mobile,
//         pincode: shippingInfo.pincode,
//         city: shippingInfo.city,
//         district: shippingInfo.district,
//         state: shippingInfo.state,
//         shippingType: generalInfo.shippingType,
//         shippingCharge: generalInfo.shippingCharge,
//         courierName: generalInfo.courierName,
//         partnerName: generalInfo.partnerName,
//         manageName: generalInfo.manageName,
//         manageValue: generalInfo.manageValue,
//         estimateItems: itemsdetails, //estimateItems
//         status: status,
//       });

//       var requestOptions = {
//         method: "POST",
//         headers: myHeaders,
//         body: raw,
//         redirect: "follow",
//       };

//       fetch(
//         process.env.REACT_APP_URL + "order/addupdateEstimate",
//         requestOptions
//       )
//         // fetch("http://192.168.0.117:5055/order/estimateOrder", requestOptions)
//         .then((response) => response.json())
//         .then((result) => {
//           if (result.status === "success") {
//             alert(result.msg);
//             if (status === "success") {
//               navigate(
//                 "/orders/estimateinvoice?orderId=" + result.data.estimateId
//               );
//               setShowPaymentModal(true);
//               // handleOfllinePayment({ orderId: result.data.orderId, status })
//             }
//             if (status === "draft") {
//               navigate("/orders");
//             }
//           } else {
//             alert(result.msg);
//           }
//         })
//         .catch((error) => console.log("error", error))
//         .finally(() => setLoading(false));
//     }
//   };

//   // const handleOfllinePayment = ({ orderId, status }) => {
//   //     if (parseFloat(totalAmount).toFixed(2) !== parseFloat(generalInfo.paymentAmount).toFixed(2)) {
//   //         alert("Payment amount is not matching with total amount")
//   //     } else {
//   //         var myHeaders = new Headers();
//   //         myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);

//   //         var formdata = new FormData();
//   //         formdata.append("transactionScreenshot", generalInfo.transactionScreenshot);
//   //         formdata.append("paymentMethod", generalInfo.paymentMethod);
//   //         formdata.append("paymentType", generalInfo.paymentType);
//   //         formdata.append("paymentStatus", generalInfo.paymentStatus);
//   //         formdata.append("paymentAmount", generalInfo.paymentAmount);
//   //         formdata.append("transferTransId", generalInfo.transferTransId);

//   //         var requestOptions = {
//   //             method: 'POST',
//   //             headers: myHeaders,
//   //             body: formdata,
//   //             redirect: 'follow'
//   //         };

//   //         fetch(process.env.REACT_APP_URL + "order/offlinePayment/" + orderId, requestOptions)
//   //             .then(response => response.json())
//   //             .then(result => {
//   //                 if (result.status === "success") {
//   //                     if (status === "success") {
//   //                         navigate("/orders/saleinvoice?orderId=" + orderId)
//   //                     } else {
//   //                         navigate("/orders")
//   //                     }
//   //                 }
//   //             })
//   //             .catch(error => console.log('error', error));
//   //     }
//   // }
//   let typeStatus = !generalInfo.status
//     ? true
//     : generalInfo.status === "In Progress"
//     ? true
//     : generalInfo.status === "Draft"
//     ? true
//     : false;

//   const resultArray = [];

//   itemsdetails.length > 0 &&
//     itemsdetails.forEach((item) => {
//       //for each item in arrayOfObjects check if the object exists in the resulting array
//       if (
//         resultArray.find((object) => {
//           if (object.warehouse === item.warehouse) {
//             //if the object exists iterate times
//             object.times++;
//             return true;
//             //if it does not return false
//           } else {
//             return false;
//           }
//         })
//       ) {
//       } else {
//         //if the object does not exists push it to the resulting array and set the times count to 1
//         item.times = 1;
//         resultArray.push(item);
//       }
//     });

//   // useEffect(()=>{
//   //     if(resultArray.length > 0){
//   //         resultArray.forEach(obj=>{
//   //             if(obj.times > obj.times){
//   //                 console.log(obj.times)
//   //             }
//   //         })
//   //     }
//   // }, [resultArray])
//   const closest =
//     resultArray.length > 0
//       ? resultArray.reduce((acc, loc) => (acc.times > loc.times ? acc : loc))
//       : [];

//   useEffect(() => {
//     setGeneralInfo((prevState) => ({
//       ...prevState,
//       warehousename: closest.warehouse,
//     }));
//   }, [closest.warehouse]);

//   // console.log(closest);

//   const handleCancelOrder = () => {
//     // setMsgShow("")
//     var myHeaders = new Headers();
//     myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
//     myHeaders.append("Content-Type", "application/json");

//     var raw = JSON.stringify({
//       managerId: managerId,
//       orderId: orderId,
//     });

//     var requestOptions = {
//       method: "POST",
//       headers: myHeaders,
//       body: raw,
//       redirect: "follow",
//     };

//     fetch(process.env.REACT_APP_URL + "order/cancelOrder", requestOptions)
//       .then((response) => response.json())
//       .then((result) => {
//         alert(result.msg);
//         if (result.status === "success") {
//           setShowCancelOrderModal(false);
//           navigate("/orders");
//         }
//       })
//       .catch((error) => console.log("error", error));
//   };

//   const MoveToSale = () => {
//     setLoadingMoveToSale(true);
//     var myHeaders = new Headers();
//     myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
//     myHeaders.append("Content-Type", "application/json");

//     var raw = JSON.stringify({
//       managerId: managerId,
//       estimateId: orderId,
//     });

//     var requestOptions = {
//       method: "POST",
//       headers: myHeaders,
//       body: raw,
//       redirect: "follow",
//     };

//     fetch(
//       process.env.REACT_APP_URL + "order/moveToSaleEstimate",
//       requestOptions
//     )
//       // fetch("http://192.168.0.117:5055/order/moveToSaleEstimate", requestOptions)
//       .then((response) => response.json())
//       .then((result) => {
//         if (result.status === "success") {
//           setMoveToSaleModal(false);
//           navigate("/estimateorders");
//         } else {
//           alert(result.msg);
//         }
//       })
//       .catch((error) => console.log("error", error))
//       .finally(() => setLoadingMoveToSale(false));
//   };

//   const handleRecipt = () => {
//     setLoadingReceipt(true);
//     setReload(false);
//     var myHeaders = new Headers();
//     myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);

//     var formdata = new FormData();
//     formdata.append("transactionScreenshot", isImage ? [...isImage][0] : "");
//     formdata.append(
//       "paymentMethod",
//       reciptData.paymentMethod ? reciptData.paymentMethod : ""
//     );
//     formdata.append(
//       "paymentType",
//       reciptData.paymentType ? reciptData.paymentType : ""
//     );
//     formdata.append(
//       "paymentAmount",
//       reciptData.paymentAmount ? reciptData.paymentAmount : ""
//     );
//     formdata.append(
//       "transactionId",
//       reciptData.transactionId ? reciptData.transactionId : ""
//     );
//     formdata.append(
//       "accountNumber",
//       reciptData.accountNumber ? reciptData.accountNumber : ""
//     );
//     formdata.append("totalAmount", totalAmount ? totalAmount : "");

//     var requestOptions = {
//       method: "POST",
//       headers: myHeaders,
//       body: formdata,
//       redirect: "follow",
//     };

//     fetch(
//       process.env.REACT_APP_URL +
//         "receipt/offlinePay/" +
//         managerId +
//         "/" +
//         orderId +
//         "?receiptId=",
//       requestOptions
//     )
//       .then((response) => response.json())
//       .then((result) => {
//         if (result.status === "success") {
//           setShowPaymentModal(false);
//           setReload(true);
//         }
//         alert(result.msg);
//       })
//       .catch((error) => console.log("error", error))
//       .finally(() => setLoadingReceipt(false));
//   };

//   // let payStatus = !generalInfo.status ? false : generalInfo.status === "In Progress" ? false : generalInfo.status === "Cancelled" ? false : true

//   // console.log(resultArray.map(obj=> obj.warehouse))
//   return (
//     <React.Fragment>
//       {addDealer && <AddDealerModal setGeneralInfo={setGeneralInfo} />}
//       <AddItemModal
//         showAddItem={showAddItem}
//         setShowAddItem={setShowAddItem}
//         setItemsdetails={setItemsdetails}
//         index={itemsdetails.length - 1}
//       />
//       <div className={styles.main_div_dark}>
//         <div className={styles.main_container}>
//           <div className={styles.tabs_div}>
//             <h2 className={styles.orderHeading}>Estimate Order</h2>
//             <div className={styles.orderHeading + " d-flex"}>
//               <li>{generalInfo.status}</li>
//               <li className="ms-3">{generalInfo.orderType}</li>
//             </div>
//             <VouchersTab styles={styles} />
//           </div>

//           <form onSubmit={handleSubmit} className={styles.ordersScroll}>
//             <div className={styles.d_flex}>
//               <div className={styles.orderContent_div}>
//                 <div className={styles.orderInput_div}>
//                   <label className={styles.order_txt}>
//                     Company/Dealer Name
//                   </label>
//                   <DealerSearch
//                     name={
//                       generalInfo.storeName &&
//                       generalInfo.dealerName &&
//                       generalInfo.storeName + " / " + generalInfo.dealerName
//                     }
//                     setAddDealer={setAddDealer}
//                     onSelect={(item) => {
//                       if (item.name === "Cash") {
//                         setGeneralInfo((prevState) => ({
//                           ...prevState,
//                           dealerId: item.dealerId,
//                           dealerName: item.name,
//                           storeName: item.storeName,
//                           shippingType: "Standard",
//                           courierName: "Pick Up",
//                           partnerName: "Self",
//                         }));
//                       } else {
//                         setGeneralInfo((prevState) => ({
//                           ...prevState,
//                           dealerId: item.dealerId,
//                           dealerName: item.name,
//                           storeName: item.storeName,
//                           billingAddress:
//                             (item.street ||
//                               item.city ||
//                               item.district ||
//                               item.state ||
//                               item.pincode) &&
//                             item.street +
//                               " " +
//                               item.city +
//                               " " +
//                               item.district +
//                               " " +
//                               item.state +
//                               " " +
//                               item.pincode,
//                         }));
//                         setShippingInfo((prevState) => ({
//                           ...prevState,
//                           mobile: item.mobile,
//                           name: item.storeName,
//                           pincode: item.pincode,
//                           street: item.street,
//                           city: item.city,
//                           district: item.district,
//                           state: item.state,
//                         }));
//                       }
//                     }}
//                   />
//                   <span className={styles.search_icon}>
//                     <Search color="#FFFFFF" />
//                   </span>
//                 </div>
//                 <div className={styles.orderInput_div}>
//                   <label className={styles.order_txt}>Billing Address</label>
//                   <textarea
//                     type="text"
//                     placeholder="Enter Billing Address"
//                     className={styles.inputField_textArea}
//                     value={generalInfo.billingAddress}
//                     required
//                     readOnly
//                   />
//                 </div>

//                 <div className={styles.orderInput_div}>
//                   <label className={styles.devliveryAddress}>
//                     Delivery Address
//                   </label>
//                 </div>
//                 <div className={styles.orderInput_div}>
//                   <input
//                     type="text"
//                     placeholder="Enter Name"
//                     className={styles.inputField}
//                     value={shippingInfo.name}
//                     onChange={(e) =>
//                       setShippingInfo((prevState) => ({
//                         ...prevState,
//                         name: e.target.value,
//                       }))
//                     }
//                     onKeyPress={(e) => {
//                       e.key === "Enter" && e.preventDefault();
//                     }}
//                     required={generalInfo.dealerName === "Cash" ? false : true}
//                   />
//                   <label className={styles.lable91}>+91</label>
//                   <input
//                     type="text"
//                     placeholder="Enter Mobile"
//                     className={styles.inputField + " " + styles.lable91input}
//                     value={shippingInfo.mobile}
//                     onChange={(e) =>
//                       setShippingInfo((prevState) => ({
//                         ...prevState,
//                         mobile: e.target.value,
//                       }))
//                     }
//                     onKeyPress={(e) => {
//                       e.key === "Enter" && e.preventDefault();
//                     }}
//                     onInput={(e) =>
//                       (e.target.value = e.target.value.slice(0, 10))
//                     }
//                     required={generalInfo.dealerName === "Cash" ? false : true}
//                   />
//                 </div>

//                 <div className={styles.orderInput_div}>
//                   <input
//                     type="text"
//                     placeholder="Enter Street"
//                     className={styles.inputField + " " + styles.w100}
//                     value={shippingInfo.street}
//                     onChange={(e) =>
//                       setShippingInfo((prevState) => ({
//                         ...prevState,
//                         street: e.target.value,
//                       }))
//                     }
//                     onKeyPress={(e) => {
//                       e.key === "Enter" && e.preventDefault();
//                     }}
//                     required={generalInfo.dealerName === "Cash" ? false : true}
//                   />
//                 </div>
//                 <div className={styles.orderInput_div}>
//                   <input
//                     type="number"
//                     onWheel={(e) => e.target.blur()}
//                     placeholder="Enter Pincode"
//                     className={styles.inputField}
//                     value={shippingInfo.pincode}
//                     onChange={(e) =>
//                       setShippingInfo((prevState) => ({
//                         ...prevState,
//                         pincode: e.target.value,
//                       }))
//                     }
//                     onKeyPress={(e) => {
//                       e.key === "Enter" && e.preventDefault();
//                     }}
//                     required={generalInfo.dealerName === "Cash" ? false : true}
//                   />
//                   <input
//                     type="text"
//                     placeholder="Enter City"
//                     className={styles.inputField + " " + styles.mx_10}
//                     value={loadingLocation ? "loading..." : shippingInfo.city}
//                     onChange={(e) =>
//                       setShippingInfo((prevState) => ({
//                         ...prevState,
//                         city: e.target.value,
//                       }))
//                     }
//                     onKeyPress={(e) => {
//                       e.key === "Enter" && e.preventDefault();
//                     }}
//                     required={generalInfo.dealerName === "Cash" ? false : true}
//                   />
//                   <input
//                     type="text"
//                     placeholder="Enter District"
//                     className={styles.inputField + " me-2"}
//                     value={
//                       loadingLocation ? "loading..." : shippingInfo.district
//                     }
//                     onChange={(e) =>
//                       setShippingInfo((prevState) => ({
//                         ...prevState,
//                         district: e.target.value,
//                       }))
//                     }
//                     onKeyPress={(e) => {
//                       e.key === "Enter" && e.preventDefault();
//                     }}
//                     // required={generalInfo.dealerName === "Cash" ? false : true}
//                   />
//                   <input
//                     type="text"
//                     placeholder="Enter State"
//                     className={styles.inputField}
//                     value={loadingLocation ? "loading..." : shippingInfo.state}
//                     onChange={(e) =>
//                       setShippingInfo((prevState) => ({
//                         ...prevState,
//                         state: e.target.value,
//                       }))
//                     }
//                     onKeyPress={(e) => {
//                       e.key === "Enter" && e.preventDefault();
//                     }}
//                     required={generalInfo.dealerName === "Cash" ? false : true}
//                   />
//                 </div>
//               </div>
//               <div className={styles.orderContent_div}>
//                 <div className={styles.orderInput_div}>
//                   {/* <label className={styles.order_txt}>Voucher No.</label> */}
//                   <div className={styles.voucherDate_div + " w-100"}>
//                     <span>Voucher No.</span>
//                     <span>
//                       <b>{generalInfo.estimateId}</b>
//                     </span>
//                     <span>Voucher Date</span>
//                     <span>
//                       <b>{generalInfo.orderDate}</b>
//                     </span>
//                     <span>Source</span>
//                     <span>
//                       <b>{generalInfo.orderType}</b>
//                     </span>
//                   </div>
//                 </div>

//                 <div className={styles.orderInput_div}>
//                   <label className={styles.order_txt}>Dispatch Warehouse</label>
//                   <select
//                     className={styles.whereHouse_select_box}
//                     value={generalInfo.warehousename}
//                     onChange={(e) =>
//                       setGeneralInfo((prevState) => ({
//                         ...prevState,
//                         warehousename: e.target.value,
//                       }))
//                     }
//                     required
//                   >
//                     <option value="">All Warehouse</option>
//                     {warehouses &&
//                       warehouses.map((item, index) => (
//                         <option key={index} value={item.warehousename}>
//                           {item.warehousename}
//                         </option>
//                       ))}
//                   </select>
//                 </div>
//                 {/* <div className={styles.orderInput_div}>
//                                     <label className={styles.order_txt}>Dispatch Manager</label>
//                                     <select
//                                         className={styles.whereHouse_select_box}
//                                         value={generalInfo.dmId}
//                                         onChange={(e) => setGeneralInfo(prevState => ({
//                                             ...prevState,
//                                             dmId: e.target.value
//                                         }))}
//                                         required
//                                     >
//                                         <option value="" hidden>Select Dispatch Manager</option>
//                                         {dispatchManagers && dispatchManagers.map((item, index) => (
//                                             <option key={index} value={item.managerId}>{item.name}</option>
//                                         ))}
//                                     </select>
//                                 </div> */}

//                 <div className={styles.orderInput_div}>
//                   <label className={styles.order_txt}>Delivery Type</label>
//                   <select
//                     className={styles.whereHouse_select_box}
//                     value={generalInfo.shippingType}
//                     onChange={(e) =>
//                       setGeneralInfo((prevState) => ({
//                         ...prevState,
//                         shippingType: e.target.value,
//                       }))
//                     }
//                     required
//                   >
//                     <option value="" hidden>
//                       Choose Delivery Type
//                     </option>
//                     <option>Standard</option>
//                     <option>Express</option>
//                   </select>
//                 </div>
//                 <div className={styles.orderInput_div}>
//                   <label className={styles.order_txt}>Delivery Method</label>
//                   <select
//                     className={styles.whereHouse_select_box}
//                     value={generalInfo.courierName}
//                     onChange={(e) =>
//                       setGeneralInfo((prevState) => ({
//                         ...prevState,
//                         courierName: e.target.value,
//                       }))
//                     }
//                     required
//                   >
//                     <option value="" hidden>
//                       Choose Delivery Method
//                     </option>
//                     {couriers &&
//                       couriers.map((obj, index) => (
//                         <option key={index} value={obj.courierName}>
//                           {obj.courierName}
//                         </option>
//                       ))}
//                   </select>
//                 </div>
//                 <div className={styles.orderInput_div}>
//                   <label className={styles.order_txt}>Delivery Partner</label>
//                   <select
//                     className={styles.whereHouse_select_box}
//                     value={generalInfo.partnerName}
//                     onChange={(e) =>
//                       setGeneralInfo((prevState) => ({
//                         ...prevState,
//                         partnerName: e.target.value,
//                       }))
//                     }
//                     required
//                   >
//                     <option value="" hidden>
//                       Choose Delivery Partner
//                     </option>
//                     {partners.partners &&
//                       partners.partners.map((obj, index) => (
//                         <option key={index} value={obj.partnerName}>
//                           {obj.partnerName}
//                         </option>
//                       ))}
//                   </select>
//                 </div>
//                 <div className={styles.orderInput_div}>
//                   <label className={styles.order_txt}>Product Type</label>
//                   <select
//                     className={styles.whereHouse_select_box}
//                     value={generalInfo.cartType}
//                     onChange={(e) => {
//                       setGeneralInfo((prevState) => ({
//                         ...prevState,
//                         cartType: e.target.value,
//                       }));
//                       itemseacrhRef.current.focus();
//                     }}
//                     required
//                     ref={cartTypeRef}
//                     // disabled
//                   >
//                     <option value="" hidden>
//                       Choose Product Type
//                     </option>
//                     <option value="ocart">Cart</option>
//                     <option value="pcart">Partner Cart</option>
//                   </select>
//                 </div>
//               </div>
//             </div>

//             <table className={styles.table_main}>
//               <thead>
//                 <tr>
//                   <th className={styles.table_th_Sno}>S. No</th>
//                   <th className={styles.table_th}>
//                     <div className={styles.orderInput_div}>
//                       <label className={styles.order_txt}>
//                         Item Description
//                       </label>
//                       <select
//                         className={styles.whereHouse_select_box}
//                         value={wareHouseWise}
//                         onChange={(e) => setWareHouseWise(e.target.value)}
//                       >
//                         <option value="">All Warehouse</option>
//                         {warehouses &&
//                           warehouses.map((item, index) => (
//                             <option key={index} value={item.warehousename}>
//                               {item.warehousename}
//                             </option>
//                           ))}
//                       </select>
//                     </div>
//                   </th>
//                   <th className={styles.table_th_details}>Qty </th>
//                   <th className={styles.table_th_details}>Per</th>
//                   <th className={styles.table_th_details}>Rate</th>
//                   <th className={styles.table_th_details}>Amount</th>
//                 </tr>
//               </thead>
//               {itemsdetails &&
//                 itemsdetails.map((x, index) => (
//                   <tbody key={index}>
//                     <tr
//                       onKeyDown={(e) => {
//                         // console.log(e.keyCode)
//                         if (e.ctrlKey && e.keyCode === 88) {
//                           handleRemoveClick(index + 1);
//                           paymentRef.current.focus();
//                         }
//                       }}
//                     >
//                       <td className={styles.table_th_Sno}>{index + 1}</td>
//                       <td className={styles.table_th + " " + styles.w_50}>
//                         <div className={styles.table_th_div1}>
//                           <LiveSearch
//                             results={results}
//                             itemsdetails={itemsdetails}
//                             cartType={generalInfo.cartType}
//                             value={x.itemNo ? x.itemNo : searchItem}
//                             renderItem={(item) => <>{item.name}</>}
//                             onChange={(e) => setSearchItem(e.target.value)}
//                             setSearchItem={setSearchItem}
//                             itemseacrhRef={itemseacrhRef}
//                             cartTypeRef={cartTypeRef}
//                             handleRemoveClick={() =>
//                               handleRemoveClick(index + 1)
//                             }
//                             removeIndex={index + 1}
//                             setShowAddItem={setShowAddItem}
//                             onSelect={(item) => {
//                               if (!generalInfo.cartType) {
//                                 setGeneralInfo((prevState) => ({
//                                   ...prevState,
//                                   cartType: item.cartType,
//                                 }));
//                                 setItemsdetails([
//                                   ...itemsdetails.slice(0, index),
//                                   item,
//                                   ...itemsdetails.slice(index + 1),
//                                 ]);
//                                 qtyFocus.current.focus();
//                               }
//                               // if (generalInfo.cartType &&(generalInfo.cartType !== item.cartType)) {
//                               //     alert("You cann't select anthor product type")
//                               // }
//                               else {
//                                 setItemsdetails([
//                                   ...itemsdetails.slice(0, index),
//                                   item,
//                                   ...itemsdetails.slice(index + 1),
//                                 ]);
//                                 qtyFocus.current.focus();
//                               }

//                               // if (item.accessories.length > 0) {
//                               //     setItemsdetails([
//                               //         ...itemsdetails.slice(0, index),
//                               //         {
//                               //             itemNo: item.itemNo,
//                               //             product_name: item.product_name,
//                               //             qty: "",
//                               //             unit: item.unit,
//                               //             price: item.price,
//                               //         },
//                               //         ...itemsdetails.slice(index + 1),

//                               //         ...item.accessories.map((xj, ix) =>
//                               //         ({
//                               //             itemNo: xj.accessoriesName,
//                               //             qty: parseFloat(xj.accessoriesQty),
//                               //             price: parseFloat(xj.accessoriesPrice),
//                               //         })
//                               //         )
//                               //     ],

//                               //     )
//                               //     // setItemsdetails(
//                               //     //     ...itemsdetails.slice(0, index),

//                               //     //     item.accessories.map((xj, ix) =>

//                               //     //     ({
//                               //     //         itemNo: xj.accessoriesName,
//                               //     //         qty: parseFloat(xj.accessoriesQty),
//                               //     //         price: parseFloat(xj.accessoriesPrice),
//                               //     //     })
//                               //     //     ),
//                               //     //     ...itemsdetails.slice(index + 2)
//                               //     // )
//                               // }
//                               // // else {
//                               // //     itemsArr.push({
//                               // //         itemNo: item.itemNo,
//                               // //         product_name: item.product_name,
//                               // //         qty: item.qty,
//                               // //         unit: item.unit,
//                               // //         price: item.price,
//                               // //     })
//                               // // }
//                             }}
//                           />
//                           {x.product_name}{" "}
//                           <span className={styles.ms_5}>{x.warehouse}</span>
//                         </div>
//                         {x.accessories &&
//                           x.accessories.length > 0 &&
//                           x.accessories.map((item, idx) => (
//                             <div key={idx} className={styles.table_th_div1}>
//                               <input
//                                 type="text"
//                                 defaultValue={item.accessoriesName}
//                                 readOnly
//                                 className={styles.acc_div_input}
//                               />
//                               <input
//                                 type="number"
//                                 defaultValue={item.accessoriesPrice}
//                                 readOnly
//                                 className={styles.acc_div_input}
//                               />
//                               <input
//                                 type="number"
//                                 onWheel={(e) => e.target.blur()}
//                                 placeholder="Enter Qty"
//                                 className={styles.acc_div_input}
//                                 value={item.accessoriesQty}
//                                 onChange={(e) => {
//                                   setItemsdetails([
//                                     ...itemsdetails.slice(0, index),
//                                     {
//                                       ...itemsdetails[index],
//                                       accessories: [
//                                         ...itemsdetails[
//                                           index
//                                         ].accessories.slice(0, idx),
//                                         {
//                                           ...itemsdetails[index].accessories[
//                                             idx
//                                           ],
//                                           accessoriesQty: e.target.value,
//                                         },
//                                         ...itemsdetails[
//                                           index
//                                         ].accessories.slice(idx + 1),
//                                       ],
//                                     },
//                                     ...itemsdetails.slice(index + 1),
//                                   ]);
//                                 }}
//                                 onKeyPress={(e) => {
//                                   if (e.key === "Enter") {
//                                     e.preventDefault();
//                                     unitRef.current.focus();
//                                   }
//                                 }}
//                               />
//                               <input
//                                 type="number"
//                                 readOnly
//                                 className={styles.acc_div_input}
//                                 value={
//                                   item.accessoriesPrice *
//                                   parseFloat(item.accessoriesQty)
//                                 }
//                               />
//                             </div>
//                           ))}
//                         {/* <div className={styles.item_div}>
//                                                     <span>U-Clip</span>
//                                                     <input
//                                                         type="number"
//                                                         defaultValue={20}
//                                                         disabled
//                                                     />
//                                                     <input
//                                                         type="number"
//                                                         placeholder='Enter Qty'
//                                                     />
//                                                     <input
//                                                         type="number"

//                                                         disabled
//                                                     />
//                                                 </div> */}
//                       </td>
//                       <td className={styles.table_th_details}>
//                         <input
//                           type="number"
//                           ref={qtyFocus}
//                           placeholder="Enter Qty"
//                           name="qty"
//                           onWheel={(e) => e.target.blur()}
//                           value={x.qty}
//                           onChange={(e) => {
//                             if (!x.cartType) {
//                               enforce_maxlength(e, index);
//                               handleInputChange(e, index);
//                               const list = [...itemsdetails];
//                               list[index]["amount"] = x.qty * x.price;
//                               setItemsdetails(list);
//                             } else if (
//                               !e.target.value ||
//                               parseFloat(e.target.value) <=
//                                 parseFloat(x.totalQty)
//                             ) {
//                               enforce_maxlength(e, index);
//                               handleInputChange(e, index);
//                               const list = [...itemsdetails];
//                               list[index]["amount"] = x.qty * x.price;
//                               setItemsdetails(list);
//                             } else {
//                               alert("Please Enter Valid Quantity");
//                             }
//                           }}
//                           // onKeyPress={(e) => { e.key === 'Enter' return (unitRef.current.focus() && e.preventDefault()) }}
//                           onKeyPress={(e) => {
//                             if (e.key === "Enter") {
//                               e.preventDefault();
//                               unitRef.current.focus();
//                             }
//                           }}
//                           onInput={(e) =>
//                             (e.target.value = e.target.value.slice(
//                               0,
//                               x.totalQty.length
//                             ))
//                           }
//                           required
//                           className={styles.inputFocus}
//                         />
//                       </td>
//                       <td className={styles.table_th_details}>
//                         <select
//                           name="unit"
//                           value={x.unit}
//                           onChange={(e) => {
//                             handleInputChange(e, index);
//                           }}
//                           ref={unitRef}
//                           onKeyPress={(e) => {
//                             if (e.key === "Enter") {
//                               e.preventDefault();
//                               priceRef.current.focus();
//                             }
//                           }}
//                           required
//                           className={styles.inputFocus}
//                         >
//                           <option value="" hidden>
//                             Select
//                           </option>
//                           <option value="Roll">Roll</option>
//                           <option value="Pcs">Pcs</option>
//                           <option value="Box">Box</option>
//                           <option value="Sq Meter">Sq Meter</option>
//                           <option value="Meter">Meter</option>
//                           <option value="Sq Feet">Sq Feet</option>
//                           <option value="Feet">Feet</option>
//                           <option value="Kg">Kg</option>
//                         </select>
//                       </td>
//                       <td className={styles.table_th_details}>
//                         <input
//                           type="number"
//                           onWheel={(e) => e.target.blur()}
//                           value={x.price}
//                           // readOnly={x.price ? true : false}
//                           name="price"
//                           // onChange={e => {
//                           //     if (x.dp > parseFloat(e.target.value ? e.target.value : 0)) {
//                           //         setErrors(prevState => ({ ...prevState, dp: "Please Enter Price Above " + x.dp, index: index }))
//                           //     } else {
//                           //         setErrors(prevState => ({ ...prevState, dp: "", index: "" }))
//                           //     }

//                           //     handleInputChange(e, index);
//                           // }}
//                           ref={priceRef}
//                           disabled
//                           onKeyPress={(e) => {
//                             if (e.key === "Enter") {
//                               e.preventDefault();
//                               const list = [...itemsdetails];
//                               list[index]["descKey"] = index;
//                               setItemsdetails(list);
//                               descRef.current.focus();
//                             }
//                           }}
//                           className={styles.inputFocus}
//                         />
//                         {error.index === index && (
//                           <div className="fs_10 text_danger">{error.dp}</div>
//                         )}
//                       </td>
//                       <td className={styles.table_th_details}>
//                         <input
//                           type="number"
//                           value={(x.qty * x.price).toFixed(2)}
//                           disabled
//                           readOnly
//                           className={styles.inputFocus}
//                         />
//                       </td>
//                       <td
//                         className={styles.crossIcon}
//                         onClick={() => handleRemoveClick(index)}
//                       >
//                         <CrossCircleIcon />
//                       </td>
//                     </tr>
//                     {(index === x.descKey || x.desc) && (
//                       <tr>
//                         <td colSpan={6} className={styles.table_th_details}>
//                           <input
//                             type={"text"}
//                             className={styles.inputFocus}
//                             style={{ height: "30px", textAlign: "left" }}
//                             ref={descRef}
//                             name="desc"
//                             autoFocus
//                             value={x.desc}
//                             onChange={(e) => handleInputChange(e, index)}
//                             onKeyPress={(e) => {
//                               if (e.key === "Enter") {
//                                 if (!x.desc) {
//                                   const list = [...itemsdetails];
//                                   list[index]["descKey"] = "";
//                                   setItemsdetails(list);
//                                 }
//                                 e.preventDefault();
//                                 handleAddClick();
//                               }
//                             }}
//                           />
//                         </td>
//                       </tr>
//                     )}
//                   </tbody>
//                 ))}
//             </table>

//             <div className="text_center">
//               <WhiteOutButton
//                 btnType="button"
//                 handleSubmit={() => handleAddClick()}
//                 title="Add More Item"
//               />
//             </div>

//             <div className={styles.d_flex}>
//               <div className={styles.addRecipt_div}>
//                 <div className={styles.addRecipt_btn_div}>
//                   {/* {payStatus &&
//                                         <button
//                                             type='button'
//                                             className={styles.addRecipt_btn}
//                                             onClick={() => {
//                                                 if (!orderId) {
//                                                     alert("Please Create Voucher Before")
//                                                 } else {
//                                                     setShowPaymentModal(true)
//                                                     setReciptData(prevState => ({
//                                                         ...prevState,
//                                                         paymentMethod: '',
//                                                         paymentType: '',
//                                                         paymentAmount: '',
//                                                         transactionId: '',
//                                                         accountNumber: '',
//                                                         transactionScreenshot: '',
//                                                         receiptId: ''
//                                                     }))
//                                                 }
//                                             }}
//                                         >
//                                             Add Receipts
//                                         </button>
//                                     } */}
//                 </div>
//                 <div className={styles.receipt_scroll}>
//                   {reciptsInfo &&
//                     reciptsInfo.map((item, index) => (
//                       <div
//                         key={index}
//                         className={styles.payment_detailsDiv}
//                         onClick={() => {
//                           setReciptData((prevState) => ({
//                             ...prevState,
//                             paymentMethod: item.paymentMethod,
//                             accountNumber: item.accountNumber,
//                             paymentAmount: item.paymentAmount,
//                             transactionScreenshot: item.transactionScreenshot,
//                             paymentType: item.paymentType,
//                           }));
//                         }}
//                       >
//                         <span>
//                           Receipt Id : <b>{item.receiptId}</b>
//                         </span>
//                         <span>
//                           Method : <b>{item.paymentMethod}</b>
//                         </span>
//                         <span>
//                           Amount : <b>{item.paymentAmount}</b>
//                         </span>
//                         <span className={styles.recipt_statusWidth}>
//                           Status :{" "}
//                           <b
//                             role="button"
//                             className={styles.recipt_status_green}
//                             onClick={() => {
//                               setShowPaymentModal(true);
//                             }}
//                           >
//                             <u>{item.status}</u>
//                           </b>
//                         </span>
//                       </div>
//                     ))}
//                 </div>
//               </div>

//               {/* <div className={styles.paymentMethod_div}>
//                                 <div className={styles.paymentMethod_cod_div}>
//                                     <div className={styles.payment_cod}>
//                                         <span className={styles.payment}>Payment Method</span>
//                                         <select
//                                             className={styles.cod}
//                                             ref={paymentRef}
//                                             // required
//                                             value={generalInfo.paymentMethod}
//                                             onChange={(e) => {
//                                                 setGeneralInfo(prevState =>
//                                                 ({
//                                                     ...prevState,
//                                                     paymentMethod: e.target.value
//                                                 }));
//                                                 if (e.target.value === "COD") {
//                                                     setGeneralInfo(prevState =>
//                                                     ({
//                                                         ...prevState,
//                                                         paymentType: "Transfer",
//                                                     }))
//                                                 }
//                                                 else if (e.target.value === "Any Transfer Photo or Text") {
//                                                     setGeneralInfo(prevState =>
//                                                     ({
//                                                         ...prevState,
//                                                         paymentType: "Screenshot",
//                                                     }))
//                                                 }
//                                                 else if (e.target.value === "Cash") {
//                                                     setGeneralInfo(prevState =>
//                                                     ({
//                                                         ...prevState,
//                                                         paymentType: "Cash",
//                                                     }))
//                                                 }
//                                             }}
//                                         >
//                                             <option value="" hidden>Select</option>
//                                             <option value="Pay Now">Pay Now</option>
//                                             <option value="Cash">Cash</option>
//                                             <option value="COD">COD</option>
//                                             <option value="Any Transfer Photo or Text">Any Transfer Photo or Text</option>
//                                         </select>
//                                     </div>
//                                     <div className={styles.status_received}>
//                                         <span className={styles.status}>Status</span>
//                                         <span
//                                             className={generalInfo.paymentStatus === "Pending For Verification" ?
//                                                 styles.paymentPending : styles.recieved
//                                             }
//                                             onClick={() => {

//                                                 setGeneralInfo(prevState =>
//                                                 ({
//                                                     ...prevState,
//                                                     paymentMethod: "Pending For Verification"
//                                                 }));
//                                                 setShowCODModal(true)

//                                             }}
//                                         >

//                                             {generalInfo.paymentStatus}
//                                         </span>
//                                     </div>
//                                 </div>
//                                 <div className={styles.addPayment_div}>
//                                     <button
//                                         type='button'
//                                         className={styles.addPament_btn}
//                                         onClick={() => {
//                                             if (generalInfo.paymentMethod !== "Pay Now") {
//                                                 setShowCODModal(true)
//                                             }
//                                         }}
//                                     >Add Payment</button>
//                                 </div>
//                             </div> */}

//               <div className={styles.subTotal_flex_col}>
//                 <div className={styles.subTotal_div}>
//                   <span>Sub Total</span>
//                   <span>
//                     {subtotal.toLocaleString("en-IN", {
//                       style: "currency",
//                       currency: "INR",
//                     })}
//                   </span>
//                 </div>
//                 <div className={styles.subTotal_div}>
//                   <span>Shipping Charges</span>
//                   <input
//                     type="number"
//                     onWheel={(e) => e.target.blur()}
//                     className={styles.shipingCharges_input}
//                     value={generalInfo.shippingCharge}
//                     onChange={(e) =>
//                       setGeneralInfo((prevState) => ({
//                         ...prevState,
//                         shippingCharge: e.target.value,
//                       }))
//                     }
//                     maxLength="10"
//                     onInput={(e) =>
//                       (e.target.value = e.target.value.slice(0, 10))
//                     }
//                   />
//                   <span className={styles.fixSize_input}>
//                     {generalInfo.shippingCharge
//                       ? generalInfo.shippingCharge
//                       : 0}
//                   </span>
//                 </div>
//                 <div className={styles.subTotal_div}>
//                   <input
//                     type="text"
//                     className={styles.adjustment}
//                     value={generalInfo.manageName}
//                     placeholder="Enter Manage"
//                     onChange={(e) =>
//                       setGeneralInfo((prevState) => ({
//                         ...prevState,
//                         manageName: e.target.value,
//                       }))
//                     }
//                   />
//                   <input
//                     type="number"
//                     className={styles.shipingCharges_input}
//                     value={generalInfo.manageValue}
//                     onChange={(e) =>
//                       setGeneralInfo((prevState) => ({
//                         ...prevState,
//                         manageValue: e.target.value,
//                         // manageValue: e.target.value.replace(/[^-?\d*?\d+$]/g, '')
//                       }))
//                     }
//                     maxLength="10"
//                     onInput={(e) =>
//                       (e.target.value = e.target.value.slice(0, 10))
//                     }
//                     onWheel={(e) => e.target.blur()}
//                   />
//                   <span className={styles.subTotal_txtRed}>
//                     {generalInfo.manageValue ? generalInfo.manageValue : 0}
//                   </span>
//                 </div>
//                 <div className={styles.subTotal_div}>
//                   <span>Round Off</span>
//                   <span className={styles.fixSize_input}>{roundedAmt}</span>
//                 </div>
//                 <div className={styles.totalINR_div}>
//                   <span>Total (INR)</span>
//                   <span>
//                     {totalAmount.toLocaleString("en-IN", {
//                       style: "currency",
//                       currency: "INR",
//                     })}
//                   </span>
//                 </div>
//               </div>
//             </div>

//             <div className={styles.orders_btn_div}>
//               <RedOutButton
//                 btnType="button"
//                 title="CANCEL"
//                 handleSubmit={() => window.history.back()}
//               />
//               {typeStatus && (
//                 <>
//                   {orderId && (
//                     <GreenOutButton
//                       btnType="button"
//                       title="SAVE AS DRAFT"
//                       handleSubmit={() => handleSaleOrder("draft")}
//                     />
//                   )}
//                 </>
//               )}
//               {orderId &&
//                 generalInfo.status !== "Cancelled" &&
//                 generalInfo.status !== "Sale Done" && (
//                   <GreenButton
//                     btnType="button"
//                     title="CANCEL ORDER"
//                     handleSubmit={() => {
//                       setShowCancelOrderModal(true);
//                     }}
//                   />
//                 )}
//               {generalInfo.status && (
//                 <GreenOutButton
//                   btnType="button"
//                   title="VIEW INOVICE"
//                   handleSubmit={() =>
//                     navigate("/orders/estimateinvoice?orderId=" + orderId)
//                   }
//                 />
//               )}
//               {(!orderId || generalInfo.status === "Payment Pending") && (
//                 <>
//                   <GreenButton
//                     btnType="submit"
//                     disabled={loading ? true : false}
//                     title={
//                       loading ? (
//                         <Spinner size="15" />
//                       ) : generalInfo.status ? (
//                         "UPDATE"
//                       ) : (
//                         "SAVE & SEND"
//                       )
//                     }
//                   />
//                   {orderId && (
//                     <GreenOutButton
//                       btnType="button"
//                       title="MOVE TO SALE"
//                       handleSubmit={() => {
//                         setMoveToSaleModal(true);
//                       }}
//                     />
//                   )}
//                 </>
//               )}
//             </div>
//           </form>
//         </div>
//       </div>

//       <Modal
//         show={showCODModal}
//         close={setShowCODModal}
//         closeBtn={true}
//         heading=" "
//         content={
//           <div className={styles.text_center}>
//             <form
//               onSubmit={(e) => {
//                 e.preventDefault();
//                 setShowCODModal(false);
//               }}
//               className={styles.addItem_Container}
//             >
//               {generalInfo.paymentMethod !== "Pending For Verification" ? (
//                 <>
//                   <h2 className={styles.modalMain_heading}>Payment Method </h2>
//                   <div className={styles.uploadRecieve_div}>
//                     <label>Upload Recieving</label>
//                     <select
//                       className={styles.selectBox_payment}
//                       value={generalInfo.paymentType}
//                       onChange={(e) => {
//                         setGeneralInfo((prevState) => ({
//                           ...prevState,
//                           paymentType: e.target.value,
//                         }));
//                       }}
//                     >
//                       {generalInfo.paymentMethod === "COD" ? (
//                         <>
//                           <option value={""} hidden>
//                             Select
//                           </option>
//                           <option>Transfer</option>
//                           <option>Cash</option>
//                         </>
//                       ) : generalInfo.paymentMethod === "Cash" ? (
//                         <option hidden>Cash</option>
//                       ) : (
//                         <>
//                           <option value={""} hidden>
//                             Select
//                           </option>
//                           <option>Screenshot</option>
//                           <option>Transaction Id</option>
//                         </>
//                       )}
//                     </select>
//                   </div>
//                   {generalInfo.paymentType === "Transfer" ||
//                   generalInfo.paymentType === "Screenshot" ? (
//                     <div className={styles.uploadImage_div}>
//                       {generalInfo.transactionScreenshot ? (
//                         <div className={styles.demoImage_div}>
//                           {generalInfo.transactionScreenshot.name ? (
//                             <img
//                               src={URL.createObjectURL(
//                                 generalInfo.transactionScreenshot
//                               )}
//                               alt=""
//                               className={styles.modalItem_Image}
//                             />
//                           ) : (
//                             <img
//                               src={
//                                 process.env.REACT_APP_S3URL +
//                                 generalInfo.transactionScreenshot
//                               }
//                               alt=""
//                               className={styles.modalItem_Image}
//                             />
//                           )}
//                         </div>
//                       ) : (
//                         <div className={styles.demoImage_div}>
//                           <div className={styles.demoImage_bgc}>
//                             <DemoImageIcon />
//                           </div>
//                         </div>
//                       )}
//                       <label className={styles.uploadImage_btn}>
//                         <input
//                           name=""
//                           type="file"
//                           hidden
//                           onChange={(e) => {
//                             setGeneralInfo((prevState) => ({
//                               ...prevState,
//                               transactionScreenshot: e.target.files[0],
//                             }));
//                           }}
//                         />
//                         <UpLoadFile />
//                         &nbsp;&nbsp;Upload Screenshot
//                       </label>
//                       <div className={styles.delete_div} onClick={() => {}}>
//                         <span>
//                           <DeleteIcon />
//                         </span>
//                         <span>Delete</span>
//                       </div>
//                     </div>
//                   ) : (
//                     <>
//                       {generalInfo.paymentType === "Transaction Id" && (
//                         <input
//                           type="text"
//                           placeholder="Enter Text Message/Transaction Id of money transfer"
//                           className={styles.inputField_enterId}
//                           value={generalInfo.transferTransId}
//                           onChange={(e) =>
//                             setGeneralInfo((prevState) => ({
//                               ...prevState,
//                               transferTransId: e.target.value,
//                             }))
//                           }
//                         />
//                       )}
//                     </>
//                   )}
//                 </>
//               ) : (
//                 <div className={styles.text_center}>
//                   {generalInfo.viewScreenshot === "viewImage" ? (
//                     <div className={styles.modalButton_cod}>
//                       <img
//                         src="/assets/viewImage.png"
//                         alt="payment_image"
//                         className={styles.viewImage}
//                       />

//                       <GreenOutButton
//                         btnType="button"
//                         title="BACK"
//                         handleSubmit={() => {
//                           setGeneralInfo((prevState) => ({
//                             ...prevState,
//                             viewScreenshot: "",
//                           }));
//                         }}
//                       />
//                     </div>
//                   ) : (
//                     <div className={styles.addItem_Container}>
//                       <h2 className={styles.modalMain_heading}>
//                         Verify Payment
//                       </h2>
//                       <div
//                         className={styles.payment_image_div}
//                         onClick={() => {
//                           setGeneralInfo((prevState) => ({
//                             ...prevState,
//                             viewScreenshot: "viewImage",
//                           }));
//                         }}
//                       >
//                         <img
//                           src={
//                             process.env.REACT_APP_URL +
//                             generalInfo.transactionScreenshot
//                           }
//                           alt="payment_image"
//                           className={styles.payment_image}
//                         />
//                         <label className={styles.viewScreenshot}>
//                           *Click to View Screenshot
//                         </label>
//                       </div>
//                     </div>
//                   )}
//                 </div>
//               )}
//               {generalInfo.viewScreenshot !== "viewImage" && (
//                 <>
//                   <input
//                     type="text"
//                     placeholder="Enter Full Amount (in Rs)"
//                     className={styles.inputField_enterAmount}
//                     value={generalInfo.paymentAmount}
//                     onChange={(e) =>
//                       setGeneralInfo((prevState) => ({
//                         ...prevState,
//                         paymentAmount: e.target.value,
//                       }))
//                     }
//                     required
//                   />
//                   <p className={styles.amountErr}>
//                     {parseFloat(totalAmount).toFixed(2) !==
//                     parseFloat(generalInfo.paymentAmount).toFixed(2)
//                       ? "Please Enter Same Amount Of Total Amount"
//                       : ""}
//                   </p>
//                   <div className={styles.modalButton_cod}>
//                     <GreenButton
//                       btnType="submit"
//                       title="APPROVE"
//                       handleSubmit={() => {}}
//                     />
//                   </div>
//                 </>
//               )}
//             </form>
//           </div>
//         }
//       />

//       <Modal
//         show={showCancelOrderModal}
//         close={setShowCancelOrderModal}
//         closeBtn={true}
//         heading="CANCEL ORDER"
//         content={
//           <div className={styles.text_center}>
//             <p className={styles.message}>
//               Are You Sure Want To Cancel This Order
//             </p>
//             <div className={styles.massageModal_btn}>
//               <RedOutButton
//                 title="NO"
//                 handleSubmit={() => setShowCancelOrderModal(false)}
//               />
//               <GreenButton
//                 title="YES"
//                 handleSubmit={() => {
//                   handleCancelOrder();
//                 }}
//               />
//             </div>
//           </div>
//         }
//       />

//       <Modal
//         show={moveToSaleModal}
//         close={setMoveToSaleModal}
//         closeBtn={true}
//         heading="Move To Sale"
//         content={
//           <div className={styles.text_center}>
//             <p className={styles.message}>Are You Sure Move To Sale</p>
//             <div className={styles.moveToSaleModal_btn}>
//               <GreenButton
//                 disabled={loadingMoveToSale ? true : false}
//                 title={loadingMoveToSale ? <Spinner size="15" /> : "YES"}
//                 handleSubmit={() => {
//                   MoveToSale();
//                 }}
//               />
//               <GreenOutButton
//                 title="NO"
//                 handleSubmit={() => {
//                   setMoveToSaleModal(false);
//                 }}
//               />
//             </div>
//           </div>
//         }
//       />

//       <PaymentModal
//         show={showPaymentModal}
//         close={setShowPaymentModal}
//         handleRecipt={handleRecipt}
//         setReciptData={setReciptData}
//         reciptData={reciptData}
//         isImage={isImage}
//         setisImage={setisImage}
//         // ImgData={ImgData}
//         // setImgData={setImgData}
//         totalAmount={totalAmount}
//         accounts={accounts}
//         setLoadingReceipt={setLoadingReceipt}
//         loadingReceipt={loadingReceipt}
//       />
//     </React.Fragment>
//   );
// };

// export default Estimate;

// export const AddDealerModal = ({ setGeneralInfo }) => {
//   const { managerId } = useAuth();
//   const [showAddDealer, setShowAddDealer] = useState(true);
//   const [showAcceptModal, setShowAcceptModal] = useState(false);
//   const [msgShow, setMsgShow] = useState("");
//   const [addNewDealerData, setAddNewDealerData] = useState({
//     name: "",
//     designation: "",
//     storeName: "",
//     mobile: "",
//     pincode: "",
//     address: "",
//     city: "",
//     state: "",
//     gst: "",
//   });
//   const handleSubmitAddDealer = (event) => {
//     event.preventDefault();
//     const form = event.currentTarget;
//     if (form.checkValidity() === false) {
//       event.preventDefault();
//       event.stopPropagation();
//     } else {
//       handleAddDealerApi();
//     }
//   };

//   useEffect(() => {
//     if (addNewDealerData.pincode.length === 6) {
//       var myHeaders = new Headers();
//       myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
//       myHeaders.append("Content-Type", "application/json");

//       var raw = JSON.stringify({
//         keyword: addNewDealerData.pincode,
//       });

//       var requestOptions = {
//         method: "POST",
//         headers: myHeaders,
//         body: raw,
//         redirect: "follow",
//       };

//       fetch(process.env.REACT_APP_URL + "locations/locations", requestOptions)
//         .then((response) => response.json())
//         .then((result) => {
//           if (result.status === "success") {
//             setAddNewDealerData((prevState) => ({
//               ...prevState,
//               city: result.districts[0] ? result.districts[0] : "",
//               state: result.states[0] ? result.states[0] : "",
//             }));
//           }
//         })
//         .catch((error) => console.log("error", error));
//     }
//   }, [addNewDealerData.pincode]);

//   const handleAddDealerApi = () => {
//     setMsgShow("");
//     var myHeaders = new Headers();
//     myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
//     myHeaders.append("Content-Type", "application/json");

//     var raw = JSON.stringify({
//       mobile: addNewDealerData.mobile,
//       refcode: managerId,
//       name: addNewDealerData.name,
//       street: addNewDealerData.address,
//       pincode: addNewDealerData.pincode,
//       city: addNewDealerData.city,
//       state: addNewDealerData.state,
//       designation: addNewDealerData.designation,
//       gstuin: addNewDealerData.gst,
//       storeName: addNewDealerData.storeName,
//     });

//     var requestOptions = {
//       method: "POST",
//       headers: myHeaders,
//       body: raw,
//       redirect: "follow",
//     };

//     fetch(process.env.REACT_APP_URL + "order/addDealer", requestOptions)
//       .then((response) => response.json())
//       .then((result) => {
//         if (result.status === "success") {
//           setGeneralInfo((prevState) => ({
//             ...prevState,
//             dealerId: result.data.dealerId,
//             mobile: result.data.mobile,
//             name: result.data.name,
//             pincode: result.data.pincode,
//             street: result.data.street,
//             city: result.data.city,
//             state: result.data.state,
//           }));
//           setMsgShow(result.msg);
//           setShowAcceptModal(true);
//           setShowAddDealer(false);
//         } else {
//           setMsgShow(result.msg);
//           setShowAcceptModal(true);
//         }
//       })
//       .catch((error) => console.log("error", error));
//   };
//   return (
//     <React.Fragment>
//       <Modal
//         show={showAddDealer}
//         close={setShowAddDealer}
//         closeBtn={true}
//         heading=" "
//         content={
//           <div className={styles.text_center}>
//             <div className={styles.addItem_Container}>
//               <h2 className={styles.modalMain_heading}>Add Dealer</h2>
//               <form onSubmit={handleSubmitAddDealer}>
//                 <div className={styles.nameAndDesignation}>
//                   <div className={styles.inputFieldName}>
//                     <label className={styles.inputFields_heading}>Name</label>
//                     <input
//                       type="text"
//                       className={styles.inputField_name}
//                       placeholder="Enter Name"
//                       value={addNewDealerData.name}
//                       onChange={(e) =>
//                         setAddNewDealerData({
//                           ...addNewDealerData,
//                           name: e.target.value
//                             .toLocaleLowerCase()
//                             .replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
//                               letter.toUpperCase()
//                             ),
//                         })
//                       }
//                       required
//                     />
//                   </div>

//                   <div className={styles.DesignationFields_div}>
//                     <label className={styles.inputFields_heading}>
//                       Designation
//                     </label>
//                     <select
//                       className={styles.inputField_designation}
//                       value={addNewDealerData.designation}
//                       onChange={(e) =>
//                         setAddNewDealerData({
//                           ...addNewDealerData,
//                           designation: e.target.value,
//                         })
//                       }
//                       required
//                     >
//                       <option value="">Select Designation</option>
//                       <option value="Interior Designer">
//                         Interior Designer
//                       </option>
//                       <option value="Store Designer">Store Owner</option>
//                       <option value="Contractor">Contractor</option>
//                       <option value="Architect">Architect</option>
//                       <option value="Others">Others</option>
//                     </select>
//                   </div>
//                 </div>

//                 <div className={styles.nameAndDesignation}>
//                   <div className={styles.inputFieldName}>
//                     <label className={styles.inputFields_heading}>
//                       Store Name
//                     </label>
//                     <input
//                       type="text"
//                       className={styles.inputField_name}
//                       placeholder="Enter Store Name"
//                       value={addNewDealerData.storeName}
//                       onChange={(e) =>
//                         setAddNewDealerData({
//                           ...addNewDealerData,
//                           storeName: e.target.value
//                             .toLocaleLowerCase()
//                             .replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
//                               letter.toUpperCase()
//                             ),
//                         })
//                       }
//                       required
//                     />
//                   </div>
//                   <div className={styles.DesignationFields_div}>
//                     <label className={styles.inputFields_heading}>
//                       Mobile Number
//                     </label>
//                     <input
//                       type="Number"
//                       className={styles.inputField_name}
//                       placeholder="Enter Mobile Number"
//                       value={addNewDealerData.mobile}
//                       onChange={(e) =>
//                         setAddNewDealerData({
//                           ...addNewDealerData,
//                           mobile: e.target.value,
//                         })
//                       }
//                       maxLength="10"
//                       onInput={(e) =>
//                         (e.target.value = e.target.value.slice(0, 10))
//                       }
//                       required
//                     />
//                   </div>
//                 </div>

//                 <div className={styles.nameAndDesignation}>
//                   <div className={styles.inputFieldName}>
//                     <label className={styles.inputFields_heading}>
//                       Street Address
//                     </label>
//                     <textarea
//                       type="text"
//                       className={styles.inputField_name}
//                       placeholder="Enter Street Address"
//                       rows="2"
//                       value={addNewDealerData.address}
//                       onChange={(e) =>
//                         setAddNewDealerData({
//                           ...addNewDealerData,
//                           address: e.target.value
//                             .toLocaleLowerCase()
//                             .replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
//                               letter.toUpperCase()
//                             ),
//                         })
//                       }
//                       required
//                     />
//                   </div>
//                 </div>

//                 <div className={styles.nameAndDesignation}>
//                   <div className={styles.inputFieldName}>
//                     <label className={styles.inputFields_heading}>
//                       Pincode
//                     </label>
//                     <input
//                       type="Number"
//                       className={styles.inputField_name}
//                       placeholder="Enter Pincode"
//                       value={addNewDealerData.pincode}
//                       onChange={(e) =>
//                         setAddNewDealerData({
//                           ...addNewDealerData,
//                           pincode: e.target.value,
//                         })
//                       }
//                       maxLength="6"
//                       onInput={(e) =>
//                         (e.target.value = e.target.value.slice(0, 6))
//                       }
//                       required
//                     />
//                   </div>

//                   <div className={styles.DesignationFields_div}>
//                     <label className={styles.inputFields_heading}>City</label>
//                     <input
//                       type="text"
//                       className={styles.inputField_name}
//                       placeholder="Enter City"
//                       value={addNewDealerData.city}
//                       onChange={(e) =>
//                         setAddNewDealerData({
//                           ...addNewDealerData,
//                           city: e.target.value
//                             .toLocaleLowerCase()
//                             .replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
//                               letter.toUpperCase()
//                             ),
//                         })
//                       }
//                       required
//                     />
//                   </div>
//                 </div>

//                 <div className={styles.nameAndDesignation}>
//                   <div className={styles.inputFieldName}>
//                     <label className={styles.inputFields_heading}>State</label>
//                     <input
//                       type="text"
//                       className={styles.inputField_name}
//                       placeholder="Enter State"
//                       value={addNewDealerData.state}
//                       onChange={(e) =>
//                         setAddNewDealerData({
//                           ...addNewDealerData,
//                           state: e.target.value
//                             .toLocaleLowerCase()
//                             .replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
//                               letter.toUpperCase()
//                             ),
//                         })
//                       }
//                       required
//                     />
//                   </div>
//                   <div className={styles.DesignationFields_div}>
//                     <label className={styles.inputFields_heading}>
//                       GSTUIN{" "}
//                       <span className={styles.unbold_text}>(optional)</span>
//                     </label>
//                     <input
//                       type="text"
//                       className={styles.inputField_name}
//                       placeholder="Enter GSTUIN Number"
//                       value={addNewDealerData.gst}
//                       onChange={(e) =>
//                         setAddNewDealerData({
//                           ...addNewDealerData,
//                           gst: e.target.value,
//                         })
//                       }
//                     />
//                   </div>
//                 </div>
//                 <div className={styles.modalButton_div}>
//                   <RedOutButton
//                     btnType="button"
//                     title="CANCEL"
//                     handleSubmit={() => {
//                       setShowAddDealer(false);
//                     }}
//                   />
//                   <GreenButton btnType="submit" title="ADD" />
//                 </div>
//               </form>
//             </div>
//           </div>
//         }
//       />

//       <Modal
//         show={showAcceptModal}
//         close={setShowAcceptModal}
//         closeBtn={true}
//         heading="ADD DEALER STATUS"
//         content={
//           <div className={styles.text_center}>
//             <p className={styles.message}>{msgShow}</p>
//             <div className={styles.massageModal_btn}>
//               <RedOutButton
//                 title="CANCEL"
//                 handleSubmit={() => setShowAcceptModal(false)}
//               />
//               <GreenButton
//                 title="OK"
//                 handleSubmit={() => {
//                   setShowAcceptModal(false);
//                 }}
//               />
//             </div>
//           </div>
//         }
//       />
//     </React.Fragment>
//   );
// };

// export const AddItemModal = ({
//   showAddItem,
//   setShowAddItem,
//   setItemsdetails,
//   index,
// }) => {
//   const { managerId } = useAuth();
//   const [showAcceptModal, setShowAcceptModal] = useState(false);
//   const [msgShow, setMsgShow] = useState("");
//   const [file, setFile] = useState();
//   function handleChange(e) {
//     setFile(URL.createObjectURL(e.target.files[0]));
//   }
//   // const [products, setProducts] = useState([])
//   // const [collections, setCollections] = useState([])
//   const [addNewItemData, setAddNewItemData] = useState({
//     product_name: "",
//     product_Id: "",
//     collection_name: "",
//     collection_id: "",
//     itemNo: "",
//     unit: "",
//     description: "",
//     products: [],
//     collections: [],
//   });

//   const handleSubmitAddItem = (event) => {
//     event.preventDefault();
//     const form = event.currentTarget;
//     if (form.checkValidity() === false) {
//       event.preventDefault();
//       event.stopPropagation();
//     } else {
//       handleAddItemApi();
//     }
//   };

//   const handleAddItemApi = () => {
//     setMsgShow("");
//     var myHeaders = new Headers();
//     myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);

//     var formdata = new FormData();
//     formdata.append("image", file ? file : "");
//     formdata.append("product_Id", addNewItemData.product_Id);
//     formdata.append("collection_id", addNewItemData.collection_id);
//     formdata.append("unit", addNewItemData.unit);
//     formdata.append("price", addNewItemData.price);
//     formdata.append("description", addNewItemData.description);

//     var requestOptions = {
//       method: "POST",
//       headers: myHeaders,
//       body: formdata,
//       redirect: "follow",
//     };

//     fetch(
//       process.env.REACT_APP_URL +
//         `inventory/addUpdateOutsideItem/${addNewItemData.itemNo}`,
//       requestOptions
//     )
//       .then((response) => response.json())
//       .then((result) => {
//         if (result.status === "success") {
//           setMsgShow(result.msg);
//           setShowAcceptModal(true);
//           setShowAddItem(false);
//           setItemsdetails((prevState) => [
//             ...prevState.slice(0, index),
//             {
//               itemNo: result.data.itemNo,
//               product_name: result.data.product_name,
//               unit: result.data.unit,
//               price: result.data.price,
//               productimg: result.data.productimg,
//             },
//             ...prevState.slice(index + 1),
//           ]);
//         } else {
//           setMsgShow(result.msg);
//           setShowAcceptModal(true);
//         }
//       })
//       .catch((error) => console.log("error", error));
//   };

//   useEffect(() => {
//     if (managerId) {
//       var myHeaders = new Headers();
//       myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
//       myHeaders.append("Content-Type", "application/json");

//       var raw = JSON.stringify({
//         managerId: managerId,
//         product_name: addNewItemData.product_name,
//       });

//       var requestOptions = {
//         method: "POST",
//         headers: myHeaders,
//         body: raw,
//         redirect: "follow",
//       };

//       fetch(process.env.REACT_APP_URL + "order/productsColl", requestOptions)
//         .then((response) => response.json())
//         .then((result) => {
//           if (result.status === "success") {
//             setAddNewItemData((prevState) => ({
//               ...prevState,
//               products: result.data.products ? result.data.products : [],
//               collections: result.data.collections
//                 ? result.data.collections
//                 : [],
//             }));
//           }
//           // setproducts(result.data.products)
//         })
//         .catch((error) => console.log("error", error));
//     }
//   }, [managerId, addNewItemData.product_name]);

//   return (
//     <React.Fragment>
//       <Modal
//         show={showAddItem}
//         close={setShowAddItem}
//         closeBtn={true}
//         heading=" "
//         content={
//           <div className={styles.text_center}>
//             <div className={styles.addItem_Container}>
//               <h2 className={styles.modalMain_heading}>Add New Item</h2>
//               <form onSubmit={handleSubmitAddItem}>
//                 <div className={styles.orderInput_div}>
//                   <div className={styles.inputFields_div}>
//                     <label className={styles.inputFields_heading}>
//                       Product Name
//                     </label>
//                     {/*<select
//                                             className={styles.inputField_name}
//                                             value={addNewItemData.product_id}
//                                             onChange={(e) => setAddNewItemData({
//                                                 ...addNewItemData,
//                                                 product_id: e.target.value,
//                                             })}
//                                             required
//                                         >
//                                             <option value="" hidden>Select Your Product Name</option>
//                                             {addNewItemData.products && addNewItemData.products.map((item, index) =>
//                                                 <option key={index} value={item._id} >{item.product_name}</option>
//                                             )}
//                                         </select> */}

//                     <input
//                       type="text"
//                       list="prodcuts"
//                       className={styles.inputField_name}
//                       value={addNewItemData.product_name}
//                       onChange={(e) =>
//                         setAddNewItemData({
//                           ...addNewItemData,
//                           product_name: e.target.value,
//                         })
//                       }
//                       autoFocus
//                     />
//                     <datalist id="prodcuts">
//                       {addNewItemData.products &&
//                         addNewItemData.products.map((item, index) => (
//                           <option key={index}>{item.product_name}</option>
//                         ))}
//                     </datalist>
//                   </div>
//                   <div className={styles.inputFields_div}>
//                     <label className={styles.inputFields_heading}>
//                       Collection Name
//                     </label>
//                     {/* <select
//                                             className={styles.inputField_name}
//                                             value={addNewItemData.collection_id}
//                                             onChange={(e) => setAddNewItemData({ ...addNewItemData, collection_id: e.target.value })}
//                                             required
//                                         >
//                                             <option value="" hidden>Enter Your Collection Name</option>
//                                             {addNewItemData.collections && addNewItemData.collections.map((item, index) =>
//                                                 <option key={index} value={item._id}>{item.collection_name}</option>
//                                             )}
//                                         </select> */}
//                     <input
//                       type="text"
//                       list="collections"
//                       className={styles.inputField_name}
//                       value={addNewItemData.collection_name}
//                       onChange={(e) =>
//                         setAddNewItemData({
//                           ...addNewItemData,
//                           collection_name: e.target.value,
//                         })
//                       }
//                     />
//                     <datalist id="collections">
//                       {addNewItemData.collections &&
//                         addNewItemData.collections.map((item, index) => (
//                           <option key={index}>{item.collection_name}</option>
//                         ))}
//                     </datalist>
//                   </div>
//                 </div>
//                 <div className={styles.orderInput_div}>
//                   <div className={styles.inputFields_div}>
//                     <label className={styles.inputFields_heading}>
//                       Item Number
//                     </label>
//                     <input
//                       type="text"
//                       className={styles.inputField_name}
//                       placeholder="Enter Item Number"
//                       value={addNewItemData.itemNo}
//                       onChange={(e) =>
//                         setAddNewItemData({
//                           ...addNewItemData,
//                           itemNo: e.target.value,
//                         })
//                       }
//                       required
//                     />
//                   </div>
//                   <div className={styles.inputFields_div}>
//                     <label className={styles.inputFields_heading}>Unit</label>
//                     {/* <select
//                                             className={styles.inputField_name}
//                                             value={addNewItemData.unit}
//                                             required
//                                             onChange={(e) => setAddNewItemData({ ...addNewItemData, unit: e.target.value })}
//                                         >
//                                             <option value="" hidden>Select Unit</option>
//                                             <option value="Roll" >Roll</option>
//                                             <option value="Pcs">Pcs</option>
//                                             <option value="Box">Box</option>
//                                             <option value="Sq Meter">Sq Meter</option>
//                                             <option value="Meter">Meter</option>
//                                             <option value="Sq Feet">Sq Feet</option>
//                                             <option value="Feet">Feet</option>
//                                         </select> */}
//                     <input
//                       type="text"
//                       list="units"
//                       className={styles.inputField_name}
//                       value={addNewItemData.unit}
//                       onChange={(e) =>
//                         setAddNewItemData({
//                           ...addNewItemData,
//                           unit: e.target.value,
//                         })
//                       }
//                     />
//                     <datalist id="units">
//                       <option value="Roll">Roll</option>
//                       <option value="Pcs">Pcs</option>
//                       <option value="Box">Box</option>
//                       <option value="Sq Meter">Sq Meter</option>
//                       <option value="Meter">Meter</option>
//                       <option value="Sq Feet">Sq Feet</option>
//                       <option value="Feet">Feet</option>
//                       <option value="Kg">Kg</option>
//                     </datalist>
//                   </div>
//                 </div>
//                 <div className={styles.orderInput_div}>
//                   <div className={styles.inputFields_div}>
//                     <label className={styles.inputFields_heading}>Price</label>
//                     <input
//                       type="number"
//                       className={styles.inputField_name}
//                       placeholder="Enter Price"
//                       value={addNewItemData.price}
//                       onChange={(e) =>
//                         setAddNewItemData({
//                           ...addNewItemData,
//                           price: e.target.value,
//                         })
//                       }
//                       required
//                       onWheel={(e) => e.target.blur()}
//                     />
//                   </div>
//                   <div className={styles.inputFields_div}>
//                     <label className={styles.inputFields_heading}>
//                       Description{" "}
//                       <span className={styles.unbold_text}>(optional)</span>
//                     </label>
//                     <input
//                       type="text"
//                       className={styles.inputField_name}
//                       placeholder="Enter Description"
//                       value={addNewItemData.description}
//                       onChange={(e) =>
//                         setAddNewItemData({
//                           ...addNewItemData,
//                           description: e.target.value,
//                         })
//                       }
//                     />
//                   </div>
//                 </div>
//                 <div className={styles.uploadImage_div}>
//                   <label
//                     onChange={handleChange}
//                     className={styles.uploadImage_btn}
//                   >
//                     <input name="" type="file" id="formId" hidden />
//                     <UpLoadFile />
//                     &nbsp;&nbsp;Upload Image
//                   </label>
//                   {file ? (
//                     <div className={styles.demoImage_div}>
//                       {" "}
//                       <img
//                         src={file}
//                         alt=""
//                         className={styles.modalItem_Image}
//                       />
//                     </div>
//                   ) : (
//                     <div className={styles.demoImage_div}>
//                       <div className={styles.demoImage_bgc}>
//                         <DemoImageIcon />
//                       </div>
//                     </div>
//                   )}
//                   <div className={styles.delete_div} onClick={() => setFile()}>
//                     <span>
//                       <DeleteIcon />
//                     </span>
//                     <span>Delete</span>
//                   </div>
//                 </div>
//                 <div className={styles.modalButton_div}>
//                   <RedOutButton
//                     title="CANCEL"
//                     handleSubmit={() => {
//                       setShowAddItem(false);
//                     }}
//                   />
//                   <GreenButton
//                     btnType="submit"
//                     title="ADD"
//                     handleSubmit={() => {}}
//                   />
//                 </div>
//               </form>
//             </div>
//           </div>
//         }
//       />

//       <Modal
//         show={showAcceptModal}
//         close={setShowAcceptModal}
//         closeBtn={true}
//         heading="ADD DEALER STATUS"
//         content={
//           <div className={styles.text_center}>
//             <p className={styles.message}>{msgShow}</p>
//             <div className={styles.massageModal_btn}>
//               <RedOutButton
//                 title="CANCEL"
//                 handleSubmit={() => setShowAcceptModal(false)}
//               />
//               <GreenButton
//                 title="OK"
//                 handleSubmit={() => {
//                   setShowAcceptModal(false);
//                 }}
//               />
//             </div>
//           </div>
//         }
//       />
//     </React.Fragment>
//   );
// };

import React from "react";

const Estimate = () => {
  return <div>Estimate</div>;
};

export default Estimate;
