import React, { useEffect, useRef, useState } from 'react'
import styles from './SaleOrderMob.module.css'
import { useLocation, useNavigate } from 'react-router-dom';
import { CircleAddIcon, Close, Spinner } from '../../components/icons/Icon';
import { useAuth } from '../../components/context/Auth';
import { useSearchParams } from 'react-router-dom';
import LiveSearchMob from '../components/LiveSearchMob';
import AddItemModalMob from '../components/AddItemModalMob';

const MobPurchase = () => {

    const { pathname } = useLocation();
    const { managerId, managerName, designation } = useAuth()
    const [searchParams] = useSearchParams()
    const navigate = useNavigate()
    const orderId = searchParams.get('orderId')
    const [results, setResults] = useState()
    const [searchItem, setSearchItem] = useState("")
    const [loading, setLoading] = useState(false)

    const [showAddItem, setShowAddItem] = useState(false)
    const [step, setStep] = useState(0)
    // const [error, setErrors] = useState({
    //     dp: "",
    //     index: ""
    // })

    const [generalInfo, setGeneralInfo] = useState({
        vendorName: "",
        voucherNo: "",
        voucherDate: "",
        warehouse_id: "",
        shippingCharge: "",
        manageName: "",
        manageValue: ""
    })

    const qtyFocus = useRef()

    const [itemsdetails, setItemsdetails] = useState([{
        cartType: "",
        itemNo: "",
        price: "",
        product_name: "",
        thumbnail: "",
        totalQty: "",
        qty: "",
        unit: "",
        warehouse: "",
    }]);


    // handle input change
    const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...itemsdetails];
        list[index][name] = value;
        setItemsdetails(list);
    };

    // handle click event of the Remove button
    const handleRemoveClick = index => {
        const list = [...itemsdetails];
        list.splice(index, 1);
        setItemsdetails(list);
    };

    // handle click event of the Add button
    const handleAddClick = () => {
        setSearchItem("");
        setItemsdetails([...itemsdetails, { itemNo: "", product_name: "", collection_name: "", qty: "", price: "", unit: "", amount: "" }]);
    };

    useEffect(() => {
        if (searchItem) {
            var myHeaders = new Headers();
            myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                "managerId": managerId,
                "keyword": searchItem,
                "warehouse_id": generalInfo.warehouse_id,
            });

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            fetch(process.env.REACT_APP_URL + "inventory/searchPurchaseItems?page=", requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.status === "success") {
                        setResults(result.data)
                    }
                })
                .catch(error => console.log('error', error));
        }
    }, [generalInfo.cartType, generalInfo.warehouse_id, searchItem, managerId])

    function enforce_maxlength(e) {
        var t = e.target;
        if (t.hasAttribute('maxlength')) {
            t.value = t.value.slice(0, t.getAttribute('maxlength'));
        }
    }

    const unitRef = useRef()
    const priceRef = useRef()

    const [warehouses, setWarehouses] = useState([])

    useEffect(() => {
        if (managerId) {
            var myHeaders = new Headers();
            myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                "managerId": managerId,
                "voucherNo": orderId,
                // "warehouse_id": generalInfo.warehouse_id
            });

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            fetch(process.env.REACT_APP_URL + "inventory/singlePurchase", requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.status === "success") {
                        if (result.data.order) {
                            setGeneralInfo(prevState => ({
                                ...prevState,
                                vendorName: result.data.order.vendorName ? result.data.order.vendorName : "",
                                voucherNo: result.data.order.voucherNo ? result.data.order.voucherNo : "",
                                voucherDate: result.data.order.voucherDate ? result.data.order.voucherDate : "",
                                warehouse_id: result.data.order.warehouse_id ? result.data.order.warehouse_id : "",
                                shippingCharge: result.data.order.shippingCharge ? result.data.order.shippingCharge : "",
                                manageName: result.data.order.manageName ? result.data.order.manageName : "",
                                manageValue: result.data.order.manageValue ? result.data.order.manageValue : "",
                                status: result.data.order.status ? result.data.order.status : "",
                            }))
                            setItemsdetails(result.data.order.itemsdetails)
                        }
                        if (result.data.warehouses) {
                            setWarehouses(result.data.warehouses)
                        }
                    }
                })
                .catch(error => console.log('error', error));
        }
    }, [managerId, orderId])

    const cartTypeRef = useRef(null)
    const itemseacrhRef = useRef(null)
    const paymentRef = useRef(null)

    const handleSubmitStep0 = (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            setStep(1)
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            handlePuchaseOrder("success")
        }
    }

    // const qty = itemsdetails.map(obj => parseFloat(obj.qty)).reduce((a, b) => a + b)
    // const totalItems = itemsdetails.length

    let cartPriceSum = itemsdetails && itemsdetails.reduce((sum, currentItem) => {
        return sum + (parseFloat(currentItem.price ? currentItem.price : 0) * parseFloat(currentItem.qty ? currentItem.qty : 0));
    }, 0)

    var cartTotal = itemsdetails && itemsdetails.map(x => !x.accessories ? 0 : x.accessories.map(y => (y.accessoriesQty ? y.accessoriesQty : 0) * y.accessoriesPrice).reduce((a, b) => a + b, 0)).reduce((a, b) => a + b, 0)
    let subtotal = cartPriceSum ? cartPriceSum : 0 + cartTotal ? cartTotal : 0
    let totalAmount = subtotal + parseFloat(generalInfo.shippingCharge ? generalInfo.shippingCharge : 0) + parseFloat(generalInfo.manageValue ? generalInfo.manageValue : 0)


    const handlePuchaseOrder = (status) => {
        setLoading(true)
        var myHeaders = new Headers();
        myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "managerId": managerId,
            "voucherNo": orderId,
            "warehouse_id": generalInfo.warehouse_id,
            "vendorName": generalInfo.vendorName,
            "shippingCharge": generalInfo.shippingCharge,
            "manageName": generalInfo.manageName,
            "manageValue": generalInfo.manageValue,
            "itemsdetails": itemsdetails,
            "status": status,
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(process.env.REACT_APP_URL + "inventory/purchase", requestOptions)
            .then(response => response.json())
            .then(result => {
                alert(result.msg)
                navigate("/orders/purchaseinvoice/" + result.data.voucherNo)
            })
            .catch(error => console.log('error', error))
            .finally(() => setLoading(false))
    }

    return (
        <div>
            <AddItemModalMob
                showAddItem={showAddItem}
                setShowAddItem={setShowAddItem}
                setItemsdetails={setItemsdetails}
                index={itemsdetails.length - 1}
            />
            <div className={styles.dashboard_select_overall}>
                {(designation === 'ordermanager' || designation === 'superadmin' || designation === 'inventorymanager') &&
                    <>
                        <div
                            className={`${styles.dashboard_overall_select} ${(pathname === "/orders/saleorder") && styles.days_active}`}
                            onClick={() => { navigate("/orders/saleorder") }}>Sale Order</div>
                        <div className={`${styles.dashboard_overall_select} ${(pathname === "/orders/estimate") && styles.days_active}`}
                            onClick={() => { navigate("/orders/estimate") }}>Estimate</div>
                    </>
                }
                {(designation === 'inventorymanager' || designation === 'superadmin' ) &&
                    <>
                        <div
                            className={`${styles.dashboard_overall_select} ${pathname === "/orders/purchase" && styles.days_active}`}
                            onClick={() => { navigate("/orders/purchase") }}>Purchase
                        </div>
                        <div
                            className={`${styles.dashboard_overall_select}`}
                        >Adjustment
                        </div>
                        <div
                            className={`${styles.dashboard_overall_select}`}
                        >Transfer
                        </div>
                    </>
                }
            </div>

            <div>
                {step === 0 ?
                    <form onSubmit={handleSubmitStep0} className={styles.order_margin}>

                        <div className={`${styles.orders_voucherNo} mt-3`}>
                            <div className={styles.voucherNo}>
                                <div>Voucher No.</div>
                                <b className={styles.date_source}>{generalInfo.voucherNo}</b>
                            </div>
                            <div className={styles.voucherNo}>
                                <div>Voucher Date</div>&nbsp;
                                <b className={styles.date_source}>{generalInfo.voucherDate}</b>
                            </div>
                        </div>

                        <h1 className={`${styles.order_heading} mt-3`}>Purchase Order</h1>

                        <label className={styles.order_lebel}><b>Vendors Name</b></label>
                        <input
                            type="text"
                            className={styles.order_input}
                            placeholder='Enter Name'
                            value={generalInfo.vendorName}
                            onChange={(e) => setGeneralInfo(prevState => ({
                                ...prevState,
                                vendorName: e.target.value
                            }))}
                            onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                            required
                        />

                        <label className={styles.order_lebel}><b>Manager Name</b></label>
                        <input
                            type="text"
                            className={styles.order_input}
                            placeholder='Enter Name'
                            defaultValue={managerName}
                            disabled
                        />

                        <label className={styles.order_lebel}><b>Warehouse</b></label>
                        <select
                            className={styles.select_packer}
                            value={generalInfo.warehouse_id}
                            onChange={(e) => setGeneralInfo(prevState => ({
                                ...prevState,
                                warehouse_id: e.target.value
                            }))}
                            required
                        >
                            <option value="">All Warehouse</option>
                            {warehouses && warehouses.map((item, index) => (
                                <option key={index} value={item._id}>{item.warehousename}</option>
                            ))}
                        </select>

                        <div className={styles.saleOrders_btns}>
                            <button type='button' className={styles.order_btn} onClick={() => navigate(-1)}>CANCEL</button>
                            <button type='submit' className={styles.order_btn1}>NEXT</button>
                        </div>
                    </form>
                    :
                    step === 1 &&
                    <form onSubmit={handleSubmit} className={styles.step_2_main}>
                        <div>
                            <div className={styles.item_scroll}>
                                <div>
                                    {/* <select
                                        className={styles.whereHouse_select_box}
                                        value={wareHouseWise}
                                        onChange={(e) => setWareHouseWise(e.target.value)}
                                    >
                                        <option value="">All Warehouse</option>
                                        {warehouses && warehouses.map((item, index) => (
                                            <option key={index} value={item.warehousename}>{item.warehousename}</option>
                                        ))}
                                    </select> */}
                                    {itemsdetails && itemsdetails.map((x, index) =>
                                        <div key={index}>
                                            <div
                                                className={styles.item_card_main}
                                                onKeyDown={(e) => {
                                                    if (e.ctrlKey && e.keyCode === 88) {
                                                        handleRemoveClick(index + 1);
                                                        paymentRef.current.focus()
                                                    }
                                                }}>
                                                <div
                                                    className={styles.card_Close}
                                                    onClick={() => handleRemoveClick(index)}>
                                                    <Close />
                                                </div>
                                                <div className={styles.table_th_Sno}>
                                                    <span className={styles.item_description}>{index + 1}. Item Description</span>
                                                </div>
                                                <div className={styles.table_th + " " + styles.w_50}>
                                                    <div className={styles.live_search_input}>
                                                        <LiveSearchMob
                                                            results={results}
                                                            itemsdetails={itemsdetails}
                                                            cartType={generalInfo.cartType}
                                                            value={x.itemNo ? x.itemNo : searchItem}
                                                            renderItem={item => <>{item.name}</>}
                                                            onChange={(e) => setSearchItem(e.target.value)}
                                                            setSearchItem={setSearchItem}
                                                            itemseacrhRef={itemseacrhRef}
                                                            cartTypeRef={cartTypeRef}
                                                            searchType="purchase"
                                                            warehouse={generalInfo.warehouse_id}
                                                            handleRemoveClick={() => handleRemoveClick(index + 1)}
                                                            removeIndex={index + 1}
                                                            // setShowAddItem={setShowAddItem}
                                                            onSelect={item => {
                                                                setItemsdetails([...itemsdetails.slice(0, index),
                                                                {
                                                                    itemNo: item.itemNo,
                                                                    product_name: item.product_name,
                                                                    unit: item.unit,
                                                                    qty: "",
                                                                    price: ""
                                                                },
                                                                ...itemsdetails.slice(index + 1)])
                                                            }}
                                                        />
                                                        {/* <span>{x.product_name}</span>
                                                            <span className={styles.ms_5}>{x.warehouse}</span> */}
                                                    </div>

                                                    {x.accessories && x.accessories.length > 0 && x.accessories.map((item, idx) =>
                                                        <div key={idx} className={styles.L_U_clip}>
                                                            <div className={styles.L_clip_price}>
                                                                <b>{item.accessoriesName}</b>
                                                                <span>{item.accessoriesPrice} Rupees</span> / Pcs
                                                            </div>
                                                            <input
                                                                type="number"
                                                                placeholder='Enter Qty'
                                                                className={styles.L_clip_qty}
                                                                value={item.accessoriesQty}
                                                                onChange={e => {
                                                                    setItemsdetails([...itemsdetails.slice(0, index),
                                                                    {
                                                                        ...itemsdetails[index],
                                                                        accessories: [...itemsdetails[index].accessories.slice(0, idx),
                                                                        {
                                                                            ...itemsdetails[index].accessories[idx],
                                                                            accessoriesQty: e.target.value,
                                                                        },
                                                                        ...itemsdetails[index].accessories.slice(idx + 1)]
                                                                    },
                                                                    ...itemsdetails.slice(index + 1)]);
                                                                }}
                                                                onKeyPress={(e) => {
                                                                    if (e.key === 'Enter') {
                                                                        e.preventDefault()
                                                                        unitRef.current.focus()
                                                                    }
                                                                }}
                                                            />
                                                        </div>
                                                    )}
                                                </div>

                                                {x.unit &&
                                                    <div className={styles.card_qty_rate_per}>
                                                        <div className='d-flex flex-column me-1'>
                                                            <label className={styles.card_lebel}>Qty</label>
                                                            <input
                                                                type="number"
                                                                ref={qtyFocus}
                                                                placeholder="Qty"
                                                                name='qty'
                                                                value={x.qty}
                                                                onChange={e => {
                                                                    enforce_maxlength(e, index);
                                                                    handleInputChange(e, index);
                                                                }}
                                                                onKeyPress={(e) => {
                                                                    if (e.key === 'Enter') {
                                                                        e.preventDefault()
                                                                        unitRef.current.focus()
                                                                    }
                                                                }}
                                                                required
                                                                className={styles.card_input_qty}
                                                            />
                                                        </div>

                                                        <div className='d-flex flex-column me-1'>
                                                            <label className={styles.card_lebel}>Per</label>
                                                            <select
                                                                name='unit'
                                                                value={x.unit}
                                                                onChange={e => { handleInputChange(e, index); }}
                                                                ref={unitRef}
                                                                onKeyPress={(e) => {
                                                                    if (e.key === 'Enter') {
                                                                        e.preventDefault()
                                                                        priceRef.current.focus()
                                                                    }
                                                                }}
                                                                required
                                                                className={styles.card_input_per}
                                                            >
                                                                <option value="" hidden>Select</option>
                                                                <option value="Roll">Roll</option>
                                                                <option value="Pcs">Pcs</option>
                                                                <option value="Box">Box</option>
                                                                <option value="Sq Meter">Sq Meter</option>
                                                                <option value="Meter">Meter</option>
                                                                <option value="Sq Feet">Sq Feet</option>
                                                                <option value="Feet">Feet</option>
                                                                <option value="Kg">Kg</option>
                                                            </select>
                                                        </div>

                                                        <div className='d-flex flex-column me-1'>
                                                            <label className={styles.card_lebel}>Rate</label>
                                                            <input
                                                                type="number"
                                                                value={x.price}
                                                                name="price"
                                                                onChange={e => {
                                                                    enforce_maxlength(e, index);
                                                                    handleInputChange(e, index);
                                                                }}
                                                                ref={priceRef}
                                                                // onKeyPress={(e) => {

                                                                //     if (e.key === 'Enter') {
                                                                //         e.preventDefault()
                                                                //         const list = [...itemsdetails];
                                                                //         list[index]["descKey"] = index;
                                                                //         setItemsdetails(list);
                                                                //         // descRef.current.focus()
                                                                //     }
                                                                // }}
                                                                className={styles.card_input_Rate}
                                                                required
                                                            />

                                                        </div>

                                                        <div className='d-flex flex-column'>
                                                            <label className={styles.card_lebel}>Amount</label>
                                                            <input
                                                                type="number"
                                                                value={parseFloat(x.qty ? x.qty : 0) * parseFloat(x.price ? x.price : 0)}
                                                                disabled
                                                                readOnly
                                                                className={styles.card_input}
                                                            />
                                                        </div>

                                                    </div>
                                                }
                                                {/* {error.index === index && <div className='fs_10 text-danger text-center'>{error.dp}</div>} */}


                                                {(index === x.descKey || x.desc) && <tr>
                                                    <td colSpan={6} className={styles.table_th_details}>
                                                        <input
                                                            type={"text"}
                                                            className={styles.inputFocus}
                                                            style={{ height: "30px", textAlign: "left" }}
                                                            // ref={descRef}
                                                            name="desc"
                                                            autoFocus
                                                            value={x.desc}
                                                            onChange={(e) => handleInputChange(e, index)}
                                                            onKeyPress={(e) => {
                                                                if (e.key === 'Enter') {
                                                                    if (!x.desc) {
                                                                        const list = [...itemsdetails];
                                                                        list[index]["descKey"] = "";
                                                                        setItemsdetails(list);
                                                                    }
                                                                    e.preventDefault()
                                                                    handleAddClick()
                                                                }
                                                            }}
                                                        />
                                                    </td>
                                                </tr>
                                                }

                                            </div>
                                        </div>
                                    )}
                                </div>

                            </div>
                            <div className={styles.item_btn_main}>
                                <button type='button' className={styles.item_btn} onClick={() => handleAddClick()}>Add More</button>
                            </div>
                        </div>

                        <div className={styles.totalAmount_card}>
                            <div className='d-flex justify-content-between'>
                                <span>Sub Total</span>
                                <span className={styles.charges_txt}>{subtotal.toLocaleString("en-IN", { style: "currency", currency: "INR" })}</span>
                            </div>
                            <div className='d-flex justify-content-between align-items-center'>
                                <span className={styles.shipingCharges}>Shipping Charges</span>
                                <div className='d-flex w-50 justify-content-around align-items-center'>

                                    <input
                                        type="number"
                                        className={styles.charges_input}
                                        placeholder='0.00'
                                        value={generalInfo.shippingCharge}
                                        onChange={(e) => setGeneralInfo(prevState => ({
                                            ...prevState,
                                            shippingCharge: e.target.value
                                        }))}
                                        maxLength="10"
                                        onInput={(e) => e.target.value = e.target.value.slice(0, 10)}
                                    />

                                    <span className='d-flex align-items-center'>
                                        <span className={styles.toPay}>To Pay</span>

                                        <input
                                            type='checkbox'
                                            className={styles.toPay}
                                            value={generalInfo.toPay}
                                            onChange={(e) => setGeneralInfo(prevState => ({
                                                ...prevState,
                                                toPay: e.target.checked
                                            }))}
                                            checked={generalInfo.toPay}
                                        />

                                    </span>


                                </div>
                                <span className={styles.charges_txt}>{generalInfo.shippingCharge ? generalInfo.shippingCharge : 0}</span>
                            </div>
                            <div className='d-flex justify-content-between align-items-center my-1'>
                                <span className='d-flex align-items-center'>
                                    <CircleAddIcon />

                                    <input
                                        type="text"
                                        className={styles.adjustment}
                                        value={generalInfo.manageName}
                                        placeholder="Enter Manage"
                                        onChange={(e) => setGeneralInfo(prevState => ({
                                            ...prevState,
                                            manageName: e.target.value,
                                        }))}
                                    // required={generalInfo.manageValue ? true : false}
                                    />

                                </span>
                                <div className='d-flex w-50 justify-content-around align-items-center'>
                                    <input
                                        type="number"
                                        className={styles.charges_input}
                                        value={generalInfo.manageValue}
                                        name='manageValue'
                                        onChange={(e) => {
                                            setGeneralInfo(prevState => ({
                                                ...prevState,
                                                manageValue: e.target.value
                                            }))
                                        }}
                                        maxLength="10"
                                        onInput={(e) => e.target.value = e.target.value.slice(0, 10)}
                                    // required={generalInfo.manageName ? true : false}
                                    />

                                    <span className={styles.toPay_hide}></span>
                                </div>
                                <span className={styles.charges_txt + ' text-danger'}>{generalInfo.manageValue ? generalInfo.manageValue : 0}</span>
                            </div>
                            {/* <div className='d-flex justify-content-between'>
                                <span>Round Off</span>
                                <span className={styles.charges_txt}>{roundedAmt}</span>
                            </div> */}
                            <div className='d-flex justify-content-between'>
                                <span><b>Total (INR)</b></span>
                                <span className={styles.charges_txt}><b>{totalAmount.toLocaleString("en-IN", { style: "currency", currency: "INR" })}</b></span>
                            </div>
                        </div>

                        <div className={styles.saleStep2_btn}>
                            <button type='button' className={styles.back_btn} onClick={() => setStep(0)}>BACK</button>
                            {generalInfo.status !== "success" &&
                                <button
                                    type='button'
                                    className={`${styles.saveAsDraft}`}
                                    onClick={() => handlePuchaseOrder("draft")}
                                >SAVE AS DRAFT
                                </button>
                            }

                            {generalInfo.status === "success" ?
                                <button
                                    type='button'
                                    className={styles.save_send}
                                    onClick={() => navigate("/orders/purchaseinvoice/" + orderId)}
                                >VIEW INOVICE
                                </button>
                                :
                                <button
                                    type='submit'
                                    className={styles.save_send}
                                >{loading ? <Spinner size="15" /> : "SAVE & SEND"}
                                </button>
                            }
                        </div>
                    </form>
                }
            </div>
        </div>
    )
}

export default MobPurchase;