import React from "react";
import styles from "./globalmobilewhatsappchat.module.css";

import { useAuth } from "../../../context/Auth";

const MobileAllDealerChats = ({children}) => {
  const {mode} = useAuth()
  return (
    <React.Fragment>
    <div
            className={`${styles.whatsappMessage_list} ${
              mode ? styles.darkMode : styles.lightMode
            }`}
          >
            {children}
            </div>
    </React.Fragment>
  );
};

export default MobileAllDealerChats;
