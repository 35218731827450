import { useEffect } from "react";
import styles from "../../../pages/ordermanager/Orders.module.css";
import { ReceiptIcon2, ShareIcon } from "../../icons/Icon";
import Button from "../Dealers/Button";

const OrderSuccessModal = ({
  mode,
  handleCloseModal,
  orderSuccessKeyPress,
}) => {
  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.key.toLowerCase() === "s") {
        orderSuccessKeyPress("shareLink");
        handleCloseModal();
      }
      if (e.key.toLowerCase() === "r") {
        orderSuccessKeyPress("addReceipt");
        handleCloseModal();
      }
    };
    window.addEventListener("keydown", handleKeyPress);
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleCloseModal, orderSuccessKeyPress]);
  return (
    <div
      className={`${styles.order_success_modal} ${
        mode ? styles.dealer_address_modal_dark : ""
      }`}
    >
      <p>Choose Payment Option</p>
      <img
        src="/assets/updatedAssets/orderSuccess.png"
        alt="Order Successful"
      />
      <p>
        You’ve successfully created the Order. Please choose how to process the
        payment.
      </p>
      <div className={styles.element}>
        <div className={styles.btn_div}>
          <Button
            buttonIcon={<ShareIcon />}
            buttonText={"Share Link"}
            buttonBgColor={"#000000"}
            buttonTextColor={"#ffffff"}
            buttonBorder={"none"}
            buttonClick={() => {
              orderSuccessKeyPress("shareLink");
            }}
          />
          <p className={styles.btn_text}>Press - S</p>
        </div>

        <div className={styles.btn_div}>
          <Button
            buttonIcon={<ReceiptIcon2 />}
            buttonText={"Add Receipt"}
            buttonBgColor={"#4164E3"}
            buttonTextColor={"#ffffff"}
            buttonBorder={"none"}
            buttonClick={() => {
              orderSuccessKeyPress("addReceipt");
            }}
          />
          <p className={styles.btn_text}>Press - R</p>
        </div>
      </div>
    </div>
  );
};

export default OrderSuccessModal;
