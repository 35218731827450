import React, { useEffect, useState } from 'react'
import styles from './Templates.module.css'
import { DocumentIcon, GotoWebsiteIcon, ImageIcon, LiveLocationIcon, Phone, QuickReplyIcon, VideoIcon } from '../../components/icons/Icon';
import { useAuth } from '../../components/context/Auth';
// import { useSearchParams } from 'react-router-dom';
import TopTabBar from '../../components/updatedComponents/Tanolet/TopTabBar';
import InputTag from '../../components/updatedComponents/FormComponents/InputTag';
import InputRedio from '../../components/updatedComponents/FormComponents/InputRedio';
import SelectBox from "../../components/updatedComponents/FormComponents/SelectBox"
import InputTypeMedia from '../../components/updatedComponents/FormComponents/InputTypeMedia';
import Editer from '../../components/updatedComponents/FormComponents/Editer';
import InputTypeChack from '../../components/updatedComponents/FormComponents/InputTypeChack';
import AddBtnContner from '../../components/updatedComponents/Templet/AddBtnContner';
import fetchData from '../../APIsControll/apiControll';
import { useLocation, useNavigate } from 'react-router-dom';
const SingleTemp = () => {
    const { mode, managerId } = useAuth();
    const [selctHeaderType, setSelctHeaderType] = useState("");
    const navigation = useNavigate()

    const ensureProtocol = (url) => {
        if (!/^https?:\/\//i.test(url)) {
            return 'https://' + url;
        }
        return url;
    }



    const [typeOfMedia, setTypeOfMedia] = useState("");
    const [Fileselect, setFileselect] = useState(null)
    const [selectedValue, setSelectedValue] = useState('');
    const [footerChack, setFooterChack] = useState("");
    const [footerBtton, setFooterBtton] = useState("");
    const [buttons1, setButtons1] = useState([]);
    const [editorContent, setEditorContent] = useState("");
    const [text1, setText1] = useState("");
    const [footerText1, setFooterText1] = useState("");
    const [tampletName, setTempletName] = useState("");
    const [slectlanguage, setSlectLanguage] = useState("");
    const [DocumentNa, setDocumentNa] = useState("");
    const [err, setErrors] = useState({});
    const temp = new URLSearchParams(useLocation().search).get('temp');

    const handleChangee = (value) => {
        setSelectedValue(value);
    };

    console.log("buttons1", buttons1);

    const formattedText = editorContent && editorContent
        .replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>') // For bold
        .replace(/\*(.*?)\*/g, '<strong>$1</strong>') // For single asterisk bold
        .replace(/_(.*?)_/g, '<em>$1</em>') // For italics
        .replace(/\n/g, '<br />'); // For line breaks


    const Chackvalidation = () => {
        let chack = /^[a-zA-Z0-9]+$/.test(tampletName);
        setErrors({ ...err, chack })

    }

    const singleTemp = async () => {
        try {
            const res = await fetchData(`wallikonChat/singleTemp`, {
                managerId: managerId,
                templateName: temp
            });
            if (res?.status === "success") {
                setTempletName(res?.template?.templateName);
                setSelectedValue(res?.template?.category);
                setSlectLanguage(res?.template?.language);
                setTypeOfMedia(res?.template?.headerType);
                // setButtons1(res?.template?.buttons);
                setFooterText1(res?.template?.footer);
                setEditorContent(res?.template?.body);
                setFileselect(res?.template?.header);
                if (res?.template?.headerType === "document" || res?.template?.headerType === "image" || res?.template?.headerType === "location" || res?.template?.headerType === "video") {
                    setSelctHeaderType("Media");
                } else if (res?.template?.headerType === "text") {
                    setSelctHeaderType("Text");
                } else {
                    setSelctHeaderType("");
                }
                if (res?.template?.footer) {
                    setFooterChack(true)
                }
                if (res?.template?.buttons) {
                    setFooterBtton(true)
                }

                const filteredButtons = res?.template?.buttons.map((btn, index) => {
                    let selectedOption = '';
                    let value = '';

                    // Match button type to the correct label and value
                    switch (btn.type) {
                        case 'QUICK_REPLY':
                            selectedOption = 'Quick Reply Button';
                            value = 'QUICK_REPLY';
                            break;
                        case 'PHONE_NUMBER':
                            selectedOption = 'Call Phone Number';
                            value = 'PHONE_NUMBER';
                            break;
                        case 'URL':
                            selectedOption = 'Visit Website';
                            value = 'URL';
                            break;
                        default:
                            selectedOption = '';
                            value = '';
                            break;
                    }

                    // Return the button in the desired structure
                    return {
                        id: index + 1,
                        value: value,
                        selectedOption: selectedOption,
                        text: btn.text || '',
                        btnText: btn.text || '', // Assuming btnText should hold button text
                        selectValue: btn.phone_number ? btn.phone_number.substring(0, 2) : '' // Assuming first 2 chars are country code
                    };
                });
                setButtons1(filteredButtons);


            }
            console.log(res);

        } catch (error) {

        }
    }



    const jj = () => {
        const myHeaders = new Headers();
        myHeaders.append("wa-platform", "WAzxe01MBXU2werWrW2WAIMAdminWi342ASDADAShyIIoKvmYI");
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            managerId: managerId,
            templateName: temp
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        fetch(process.env.REACT_APP_URL + "wallikonChat/templateInsight", requestOptions)
            .then((response) => response.json())
            .then((result) => console.log("wallikonChat/templateInsight => ", result))
            .catch((error) => console.error(error));
    }

    useEffect(() => {
        Chackvalidation();
        singleTemp();
        jj()
    }, [])


    return (
        <React.Fragment>
            <div className={styles.main_continer}>
                <div className={styles.secand_main_continer}
                    style={mode ? { backgroundColor: "#2C2E33", border: "1px solid #2C2E33" } :
                        { backgroundColor: "#F8F9FC", border: "1px solid #F8F9FC" }}>
                    <form>
                        <div className={styles.Templet_container} >
                            <div className={styles.go_Back}  >
                                <TopTabBar text={"Campaigns / Create Template"} />
                            </div>
                            <div className={styles.templateName_continer} >
                                <div className={styles.template_input} >
                                    <div className={styles.inputTageDiv} >
                                        <InputTag
                                            name={"Template Name"}
                                            maxLength={50}
                                            placeholderType={"text"}
                                            placeholderText={"Template Name"}
                                            onChange={(e) => setTempletName(e.target.value)}
                                            required={true}
                                            value={tampletName}
                                        />
                                        {/* <span>Err</span> */}
                                    </div>
                                </div>
                                <div className={styles.template_btn_Continer}>
                                    {/* <div style={{ width: "100px" }}>
                                        <Button
                                            buttonBgColor={"#4164E3"}
                                            buttonText={"Done"}
                                            buttonTextColor={"#ffff"}
                                            buttonIcon={<TemplateDone />}
                                        // loading={TempLoading}
                                        />
                                    </div> */}
                                </div>
                            </div>
                        </div>
                        <div className={styles.Templet_container1} /* style={mode ? {backgroundColor:"#232529"}:{backgroundColor:"#ffff"}} */ >
                            <div className={styles.form_continer} >
                                <div className={styles.Marketing_continer} style={mode ? { border: "1px solid #232529", backgroundColor: "#232529" } : { border: "1px solid #E4E7EB", backgroundColor: "#ffffff" }} >
                                    <div className={styles.Marketing_continer_headr}
                                        style={mode ? { backgroundColor: "#1B1D21", border: "1px solid #1B1D21", color: "#ffff" } :
                                            { backgroundColor: "#E4E7EB", border: "1px solid #E4E7EB", color: "#000" }} >
                                        <span>Marketing</span>
                                        <span className={styles.changeColor}>Change</span>
                                    </div>
                                    <div className={styles.Marketing_continer_body} >
                                        <div>
                                            <InputRedio
                                                id="Marketing"
                                                lableText="Marketing"
                                                name="hello"
                                                value={selectedValue}
                                                checked={selectedValue === "MARKETING" ? true : false}
                                                onChange={() => handleChangee("Marketing")}
                                                required={true}
                                            />
                                            <div className={styles.Marketing_Text} style={mode ? { color: "#fafafa" } : { color: "#000" }}>
                                                <span >Promotions or information
                                                    about your business, products
                                                    or services. Or any message
                                                    that isn't utility or authentication.</span>
                                            </div>

                                        </div>
                                        <div style={{ marginTop: "20px" }}>
                                            <InputRedio
                                                id="Utility"
                                                lableText="Utility"
                                                name="hello"
                                                value={selectedValue}
                                                checked={selectedValue === "UTILITY" ? true : false}
                                                onChange={() => handleChangee("Utility")}
                                                required={true}
                                            />
                                            <div className={styles.Marketing_Text} style={mode ? { color: "#fafafa" } : { color: "#000" }}>
                                                <span >Messages about a specific transaction. account, order or customer request.</span>
                                            </div>
                                        </div>

                                        <div>
                                            <div style={{ marginTop: "20px" }}>
                                                <InputRedio
                                                    id="Authentication"
                                                    lableText="Authentication"
                                                    name="hello"
                                                    value="Authentication"
                                                    disabled={true}
                                                    required={true}
                                                />
                                            </div>
                                        </div>
                                        <hr style={mode ? { borderColor: "white" } : { borderColor: "black" }} />
                                        <div className={styles.select_Lng}>
                                            <span style={mode ? { color: "#ffff" } : { color: "#000" }}>Language</span>
                                            <div className={styles.select_box_tag}>
                                                <select name="" id="" className={styles.select_box}
                                                    style={mode ? { backgroundColor: "#1b1d21", border: "1px solid #232529", color: "#fff" } :
                                                        { background: "#f8f9fc", border: "1px solid #232529", color: "#000" }}
                                                    onChange={(e) => setSlectLanguage(e.target.value)}
                                                    required={true}
                                                    value={slectlanguage}
                                                >
                                                    <option value='' hidden >Select</option>
                                                    <option value='en'>English</option>
                                                    <option value='en_US'>English (US)</option>
                                                    <option value='en_UK'>English (UK)</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div style={{ height: "200px" }}>

                                        </div>
                                    </div>
                                </div>
                                <div className={styles.Header_continer} style={mode ? { border: "1px solid #232529", backgroundColor: "#232529", borderRadius: "8px" } : { border: "1px solid #E4E7EB", backgroundColor: "#ffffff", borderRadius: "8px" }} >
                                    <div className={styles.Marketing_continer_headr}
                                        style={mode ? { backgroundColor: "#1B1D21", border: "1px solid #1B1D21", color: "#ffff" } :
                                            { backgroundColor: "#E4E7EB", border: "1px solid #E4E7EB", color: "#000" }} >
                                        <span> <InputTypeChack lable={"Header"} />   </span>
                                        <span className={styles.changeColor}>Change</span>
                                    </div>
                                    <div className={styles.create_Tamplet_Continer} >
                                        <div className={styles.create_tem_selectBox} style={{ marginTop: "15px" }} >
                                            <SelectBox
                                                dropDownText={"Select"}
                                                label={"Type"}
                                                onSelect={(e) => setSelctHeaderType(e.label)}
                                                option={[{ label: "Text" }, { label: "Media" }]}
                                                star={true}
                                                value={selctHeaderType}
                                            />

                                        </div>
                                        <div>
                                            {
                                                selctHeaderType === "" ? null :
                                                    selctHeaderType === "Media" ?
                                                        <div className={styles.create_tem_TypeOfTamp}>
                                                            <div className={styles.create_tem_TypeOfTamp1} >
                                                                <InputRedio
                                                                    id="Image"
                                                                    lableText="Image"
                                                                    name="media"
                                                                    value="Image"
                                                                    checked={typeOfMedia === "image" ? true : false}
                                                                    onChange={() => setTypeOfMedia("Image")}
                                                                    required={true}
                                                                />
                                                                <InputRedio
                                                                    id="Video"
                                                                    lableText="Video"
                                                                    name="media"
                                                                    value="Video"
                                                                    checked={typeOfMedia === "video" ? true : false}
                                                                    onChange={() => setTypeOfMedia("Video")}
                                                                    required={true}
                                                                />
                                                                <InputRedio
                                                                    id="Document"
                                                                    lableText="Document"
                                                                    name="media"
                                                                    value="Document"
                                                                    checked={typeOfMedia === "document" ? true : false}
                                                                    onChange={() => setTypeOfMedia("Document")}
                                                                    required={true}
                                                                />
                                                                <InputRedio
                                                                    id="Location"
                                                                    lableText="Location"
                                                                    name="media"
                                                                    value="Location"
                                                                    checked={typeOfMedia === "location" ? true : false}
                                                                    onChange={() => setTypeOfMedia("Location")}
                                                                    required={true}
                                                                />
                                                            </div>
                                                            <div className={styles.create_tem_Text} >
                                                                <span className={styles.create_tem_Text_Span1} style={mode ? { color: "#f0f0f0" } : { color: "#000" }} >Add Simple Media File</span>
                                                                <span className={styles.create_tem_Text_Span2} style={mode ? { color: "#f0f0f0" } : { color: "#000" }}  >Provide examples of the media in the header to help Meta review your templates. Do not include any customer information.</span>
                                                            </div>
                                                            {
                                                                typeOfMedia === "Location" ? null :
                                                                    <div className={styles.create_tem_MediaFile}>
                                                                        <div style={{ width: "150px" }}>
                                                                            <InputTypeMedia
                                                                                name="mediaFile"
                                                                                id="mediaFile"
                                                                                required={true}
                                                                                accept={
                                                                                    typeOfMedia === "Image" ? "image/jpeg, image/png, image/jpg" :
                                                                                        typeOfMedia === "Video" ? "video/mp4" :
                                                                                            typeOfMedia === "Document" ? "application/pdf" : ""
                                                                                }
                                                                                onChange={(e) => setFileselect(e.target.files[0])}
                                                                            />
                                                                        </div>
                                                                        <div className={styles.create_tem_MediaFile_text}>
                                                                            <span style={mode ? { color: "#B2B5CA" } : { color: "#B2B5CA" }} >Max image size - 5 MB</span>
                                                                            <span style={mode ? { color: "#f0f0f0" } : { color: "#B2B5CA" }} >Supported file formats :
                                                                                {
                                                                                    typeOfMedia === "Image" ? " .png, .jpeg, .jpg" :
                                                                                        typeOfMedia === "Video" ? " .mp4" :
                                                                                            typeOfMedia === "Document" ? " .pdf" : ""
                                                                                }
                                                                            </span>
                                                                        </div>
                                                                        {
                                                                            typeOfMedia === "Document" ?
                                                                                <div style={{ width: "90%", marginTop: "10px" }}>
                                                                                    <InputTag
                                                                                        name={"Document Name"}
                                                                                        labelText={"Document Name"}
                                                                                        placeholderText={"Document Name"}
                                                                                        placeholderType={"text"}
                                                                                        maxLength={150}
                                                                                        required={true}
                                                                                        star={true}
                                                                                        onChange={(e) => setDocumentNa(e.target.value)}
                                                                                    />
                                                                                </div> : null
                                                                        }
                                                                    </div>
                                                            }

                                                        </div> : <>
                                                            <div className={styles.create_tem_MediaText_Con}>
                                                                <InputTag
                                                                    placeholderText={"Enter Your Text"}
                                                                    placeholderType={"text"}
                                                                    maxLength={60}
                                                                    required={true}
                                                                    star={true}
                                                                    labelText={"Text"}
                                                                    onChange={(e) => setText1(e.target.value)}
                                                                    value={text1}
                                                                />
                                                            </div>
                                                        </>
                                            }
                                        </div>
                                        <div className={styles.Marketing_continer_headr}
                                            style={mode ? { backgroundColor: "#1B1D21", border: "1px solid #1B1D21", color: "#ffff", marginTop: "15px" } :
                                                { backgroundColor: "#E4E7EB", border: "1px solid #E4E7EB", color: "#000", marginTop: "15px" }} >
                                            <span> <InputTypeChack lable={"Limited Time Offer"} />   </span>
                                            {/* <span className={styles.changeColor}>Change</span> */}
                                        </div>
                                        <div style={{ marginTop: "15px" }}>
                                            <Editer bodyText={editorContent} setBodyText={setEditorContent} />
                                        </div>
                                        <div className={styles.Marketing_continer_headr}
                                            style={mode ? { backgroundColor: "#1B1D21", border: "1px solid #1B1D21", color: "#ffff", marginTop: "15px" } :
                                                { backgroundColor: "#E4E7EB", border: "1px solid #E4E7EB", color: "#000", marginTop: "15px" }} >
                                            <span> <InputTypeChack onChange={(e) => setFooterChack(e.target.checked)} lable={"Footer"} checked={footerChack} />   </span>
                                            {/* <span className={styles.changeColor}>Change</span> */}
                                        </div>
                                        {footerChack && (
                                            <div className={styles.create_temp_footer_input}>
                                                <div className={styles.create_temp_footer_input1}>
                                                    <InputTag
                                                        labelText={"Footer Text"}
                                                        maxLength={100}
                                                        star={true}
                                                        required={true}
                                                        placeholderText={"Enter footer text"}
                                                        placeholderType={"text"}
                                                        onChange={(e) => setFooterText1(e.target.value)}
                                                        value={footerText1}
                                                    />
                                                </div>
                                            </div>
                                        )}
                                        <div className={styles.Marketing_continer_headr}
                                            style={mode ? { backgroundColor: "#1B1D21", border: "1px solid #1B1D21", color: "#ffff", marginTop: "15px" } :
                                                { backgroundColor: "#E4E7EB", border: "1px solid #E4E7EB", color: "#000", marginTop: "15px" }} >
                                            <span> <InputTypeChack onChange={(e) => setFooterBtton(e.target.checked)} lable={"Button"} checked={footerBtton} />   </span>
                                            <span style={mode ? { color: "#fff" } : { color: "#000" }}>Call To Action</span>
                                        </div>
                                        {footerBtton && <AddBtnContner buttons={buttons1} setButtons={setButtons1} key={"2"} />}
                                    </div>
                                </div>
                                <div className={styles.Preview_continer} /* style={mode ? { backgroundColor: "#232529" }
                                            : { backgroundColor: "#E4E7EB" }} */>
                                    <div className={styles.templateRight}>
                                        <div>
                                            <p className={styles.Preview_Text} style={mode ? { backgroundColor: "#1B1D21", color: "#fff" }
                                                : { backgroundColor: "#E4E7EB", color: "#000" }} > Preview</p>
                                        </div>
                                        <div className={styles.Preview_continer_main}
                                            style={mode ? { backgroundColor: "#1B1D21", border: "1px solid #1B1D21" } :
                                                { backgroundColor: "#fff", border: "1px solid #E4E7EB" }} >
                                            <div className={styles.Preview_continer_media}>
                                                {
                                                    selctHeaderType === "Text" ? <div className={styles.con} > <span style={mode ? { color: "#fff" }
                                                        : { color: "#000" }} >{text1}</span>  </div> :
                                                        selctHeaderType === "Media" ?
                                                            <div className={styles.Preview_continer_media1} style={mode ? { backgroundColor: "#2C2E33" } : { backgroundColor: "#E4E7EB" }}>
                                                                {/* {
                                                                    typeOfMedia === "Image" ? (Fileselect instanceof File ? (<img src={URL.createObjectURL(Fileselect)} alt="" className={styles.image} />) : (<ImageIcon />)) :
                                                                        typeOfMedia === "Video" ? (Fileselect instanceof File ? (<video autoPlay muted controls src={URL.createObjectURL(Fileselect)} className={styles.image} />) : (<VideoIcon />)) :
                                                                            typeOfMedia === "Document" ? (Fileselect instanceof File ? (
                                                                                <iframe onScrollCapture={false} src={URL.createObjectURL(Fileselect)} title="PDF Preview" className={styles.SelectPdf} />) : (<DocumentIcon />)) :
                                                                                typeOfMedia === "Location" ? (<LiveLocationIcon />) : null
                                                                } */}
                                                                {
                                                                    typeOfMedia === "image" ? (Fileselect ? (<img src={Fileselect} alt="" className={styles.image} />) : (<ImageIcon />)) :
                                                                        typeOfMedia === "video" ? (Fileselect ? (<video autoPlay muted controls src={Fileselect} className={styles.image} />) : (<VideoIcon />)) :
                                                                            typeOfMedia === "document" ? (Fileselect ? (
                                                                                <iframe onScrollCapture={false} src={Fileselect} title="PDF Preview" className={styles.SelectPdf} />) : (<DocumentIcon />)) :
                                                                                typeOfMedia === "location" ? (<LiveLocationIcon />) : null
                                                                }
                                                            </div> : null}

                                                <div className={styles.Preview_continer_media2}>
                                                    {/* <div dangerouslySetInnerHTML={{ __html: editorContent }} style={mode ? { color: "#fff" } : { color: "#000" }} /> */}
                                                    <div dangerouslySetInnerHTML={{ __html: formattedText }}
                                                        style={{ color: mode ? "#fff" : "#000" }}
                                                    ></div>
                                                    {footerText1 && <div className={styles.con} > <span style={mode ? { fontSize: "12px", color: "#B3B3B3" }
                                                        : { fontSize: "12px", color: "#B3B3B3" }} >{footerText1}</span>  </div>}
                                                </div>
                                                {buttons1?.map((button, index) =>
                                                    <div key={index} className='text-capitalize text-primary text-center border-top mt-2 pt-1'>
                                                        {/* {console.log("button", buttons1)} */}
                                                        <span className='me-2'>{button.value === "PHONE_NUMBER" ? <Phone color='#fff' /> :
                                                            button.value === 'QUICK_REPLY' ? <QuickReplyIcon color='#fff' /> :
                                                                <GotoWebsiteIcon />}</span><a className={styles.websiteLink}
                                                                    href={ensureProtocol(button.url)} target='_blank' rel="noopener noreferrer">
                                                            <b>{button.btnText}</b></a>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </React.Fragment>
    )
}

export default SingleTemp
