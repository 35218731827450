import styles from "./FromComponent.module.css";

const Spinner = ({
  height = 60,
  width = 60,
  borderWidth,
  color = "#4164E3",
}) => {
  const spinnerStyle = {
    borderWidth: borderWidth,
    borderColor: "#f3f3f3",
    borderTopColor: color,
    width: `${width}px`,
    height: `${height}px`,
  };

  return <div className={styles.spinner} style={spinnerStyle}></div>;
};

export default Spinner;
