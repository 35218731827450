import React from 'react'
import styles from "../globalwhatsappchat.module.css";
import { useAuth } from "../../../context/Auth";
const AllDealerChats = ({children}) => {
    const { mode } = useAuth();
  return (
    <div
    style={{
     borderLeft: mode ? '2px solid #232529' : '2px solid #E9EDEF'
    }}
    
    className={`${styles.whatsappMessage_list} ${
        mode ? styles.darkMode : styles.lightMode
      }`}
    >

        {children}


    </div>
  )
}

export default AllDealerChats