// import React from 'react';
// import styles from './Skeleton.module.css'; // Ensure this path is correct

// const Skeleton = ({ width = '100%', height = '1em', shape = 'square' }) => {
//     const style = {
//         width,
//         height,
//         borderRadius: shape === 'round' ? '50px' : '4px',
//     };

//     return <div className={styles.skeleton} style={style}></div>;
// };

// export default Skeleton;
// {[...Array(5)].map((_, index) => (
//     <>
//       <Skeleton
//         width={width < 500 ? "100%" : "16%"}
//         height="50px"
//         shape="round" // For the first line to be round
//       />
//       {[...Array(3)].map((_, index) => (
//         <Skeleton
//           key={index}
//           width={width < 500 ? "100%" : "16%"}
//           height="30px" // Thin lines
//         />
//       ))}
//       <Skeleton
//         width={width < 500 ? "100%" : "16%"}
//         height="70px" // Thick line
//       />
//     </>
//   ))}









import React from 'react';
import styles from './Skeleton.module.css';
import { useAuth } from '../../context/Auth';

const Skeleton = ({
    width = '100%',
    height = '1em',
    shape = 'square',
    lines = 1, 
    thickness = 58 
}) => {
    const skeletonStyle = {
        width: shape === 'circle' ? height : width, 
        height: height,
        borderRadius: shape === 'circle' ? '50%' : shape === 'round' ? '50px' : '4px',
    };

    const getHeightByThickness = (thickness) => {

        if (thickness === 1) {
            return '0.5em';
        }
        if (thickness === 2) {
            return '1em';
        }
        if (thickness === 3) {
            return '1.5em';
        }
        if (thickness === 4) {
            return '2em';
        }
        if (thickness === 5) {
            return '2.5em';
        }
        if (thickness === 6) {
            return '3em';
        }
        if (thickness === 7) {
            return '3.5em';
        }
        if (thickness === 8) {
            return '4em';
        }
        if (thickness === 9) {
            return '4.5em';
        }
        if (thickness === 10) {
            return '5em';
        }
        else {
            return '1em';
        }
    };

    const { mode } = useAuth()
    return (
        <>
            {Array.from({ length: lines }).map((_, index) => (
                <div
                    key={index}
                    className={styles.skeleton}
                    style={{
                        ...skeletonStyle,
                        height: getHeightByThickness(thickness),
                        marginBottom: index === lines - 1 ? 0 : '8px', // Space one
                        backgroundColor: mode ? "#000" : " #e0e0e0",
                    }}
                ></div>
            ))}
        </>
    );
};

export default Skeleton;
