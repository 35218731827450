import React, { useEffect, useState } from 'react'
import styles from "./Camp.module.css"
import { useAuth } from '../../components/context/Auth'
import TopTabBar from '../../components/updatedComponents/Tanolet/TopTabBar'
import { useParams } from 'react-router-dom'
import { useToast } from '../../components/updatedComponents/Toaster/Toaster'
import TimeOutFetch from '../../APIsControll/TimeOutFechControll'
import { changeDateFormat, changeTimeFormat } from '../../Utils/TextUtils'
const CampaignReport = () => {
    const { mode, managerId } = useAuth();
    const { tempname } = useParams();
    const { show } = useToast();
    const [data, setData] = useState()

    const getREport = async () => {
        const paload = {
            managerId,
            templateName: "golden_wallpaper_sp155"
            // templateName: tempname
        }
        try {
            const res = await TimeOutFetch(`wallikonCampaign/campaignReportStatus`, paload);
            setData(res?.data);
            // console.log(res.data);

        } catch (error) {
            show("Something went wrong.", " error");
            return
        }
    }
    useEffect(() => {
        getREport();
    }, [])

    return (
        <div className={styles.mainCintner}>
            <div className={styles.Continer} style={{ backgroundColor: mode ? "#2C2E33" : "#F8F9FC" }} >
                <div className={styles.goBackCon}  >
                    <div className={styles.gobacFristChlid}>
                        {/* <TopTabBar text={`Campaigns/Report/${tempname}`} /> */}
                        <TopTabBar text={`Campaigns/Report`} />
                    </div>
                </div>
                <div className={styles.report_mainCon} style={{ backgroundColor: mode ? "#2C2E33" : "#F8F9FC", color: mode ? "#fff" : "#000" }} >
                    <div className={styles.templetReport} >
                        <span style={{ fontSize: "16px", color: "#4164E3", fontWeight: "500" }} > Status :  </span>
                        <span style={{ fontSize: "16px", fontWeight: "400"  }} > {data?.status.toUpperCase()}  </span>
                    </div>
                    <div className={styles.templetReport} >
                        <span style={{ fontSize: "16px", color: "#4164E3", fontWeight: "500" }} > Template Name :  </span>
                        <span style={{ fontSize: "16px", fontWeight: "400"  }} > {tempname.toUpperCase()}  </span>
                    </div>
                    <div className={styles.templetReport1} >
                        <div style={{ marginTop: "14px", color: mode ? "#9C9797" : "#646B88", }}>
                            <span style={{ fontSize: "16px", fontWeight: "500" }} > Campaign Timing </span>
                        </div>
                        <div className={styles.timing} >
                            {
                                data?.scheduledDates.map((e, i) =>
                                    <div className={styles.suduleTime} style={{ backgroundColor: mode ? "#000" : "#fff" }} key={i}>
                                        <div className={styles.suduleTime1} ><div> {changeDateFormat(`${e?.date}`, "-")} , {changeTimeFormat(`${e?.date}`)}  </div> </div>
                                        <div className={styles.suduleTime2} ></div>
                                    </div>
                                )
                            }
                        </div>
                        <div className={styles.suduleTimeCart} style={{ backgroundColor: mode ? "#232529" : "#fff", color: mode ? "#fff" : "#000" }} >
                            <div className={styles.cartwidth} style={{ backgroundColor: mode ? "#1B1D21" : "#fff", border: mode ? "none" : "1px solid #E4E7EB" }} >
                                <div  >
                                    <div style={{fontSize:"14px", fontWeight:"400"}} >Sent</div>
                                    <div  style={{fontSize:"18px", fontWeight:"5    00"}} >{data?.sentCount}</div>
                                </div>
                            </div>
                            <div className={styles.cartwidth} style={{ backgroundColor: mode ? "#1B1D21" : "#fff", border: mode ? "none" : "1px solid #E4E7EB" }} >
                                <div  >
                                    <div style={{fontSize:"14px", fontWeight:"400"}} >Total Cost</div>
                                    <div  style={{fontSize:"18px", fontWeight:"5    00"}} >{data?.totalCost}</div>
                                </div>
                            </div>
                           
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default CampaignReport