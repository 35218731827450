import React, { useEffect, useRef, useState } from 'react'
import styles from "./Delivery.module.css";
import { GreenButton, RedOutButton } from '../../components/Buttons';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { CheckGreenIcon, CheckIcon, DropDownIcon, DropUpIcon } from '../../components/icons/Icon';

const AddPartner = () => {
    const navigate = useNavigate();
    const [searchparams] = useSearchParams()
    const partnerId = searchparams.get("partnerId")
    const courierId = searchparams.get("courierId")
    const stateName = searchparams.get("stateName")
    const cityName = searchparams.get("cityName")
    const edit = searchparams.get("edit")
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    const [warehouses, setWarehouses] = useState([]);
    const [showStateOption, setShowStateOption] = useState();
    const [showCityOption, setShowCityOption] = useState();
    const [showWarehouseOption, setShowWarehouseOption] = useState();
    const [partnerData, setPartnerData] = useState({
        partnerName: '',
        contactNumber: '',
        password: '',
        confirmPassword: '',
        vehicleNumber: '',
        trackingUrl: '',
        partnerStatus: '',
        logInStatus: '',
        // otpVerification: '',
    });

    // const [methods, setMethods] = useState([]);
    const [couriers, setCouriers] = useState([]);
    const [selectedCouriers, setSelectedCouriers] = useState([]);
    const [methodsData, setMethodsData] = useState([]);
    // const [selectedSubMethod, setSelectedSubMethod] = useState([]);

    const handleCouriers = (courierId, courierName) => {
        // Find the index of the clicked courier in the selectedCouriers array
        const courierIndex = selectedCouriers.findIndex((courier) => courier.courierId === courierId);

        // If courier is not in the array, add it; otherwise, remove it
        if (courierIndex === -1) {
            setSelectedCouriers([...selectedCouriers, { courierId, courierName, selectedSubMethod: [] }]);
        } else {
            const updatedCouriers = [...selectedCouriers];
            updatedCouriers.splice(courierIndex, 1);
            setSelectedCouriers(updatedCouriers);
        }
    };

    const handleSubMethod = (courierId, methodName, methodStatus) => {
        const updatedCouriers = selectedCouriers.map((courier) => {
            if (courier.courierId === courierId) {
                const selectedSubMethod = courier.selectedSubMethod || [];
                const methodIndex = selectedSubMethod.findIndex((method) => method.methodName === methodName);

                if (methodIndex === -1) {
                    return {
                        ...courier,
                        selectedSubMethod: [...selectedSubMethod, { methodName, methodStatus }],
                    };
                } else {
                    const updatedSubMethod = [...selectedSubMethod];
                    updatedSubMethod.splice(methodIndex, 1);
                    return {
                        ...courier,
                        selectedSubMethod: updatedSubMethod,
                    };
                }
            }
            return courier;
        });

        setSelectedCouriers(updatedCouriers);
    };

    console.log('selectedCouriers', selectedCouriers);
    // console.log('selectedMethods', selectedMethods);

    const [selectedStates, setSelectedStates] = useState([]);
    const handleStateClick = (state) => {
        if (selectedStates.includes(state)) {
            setSelectedStates(selectedStates.filter((selectedStates) => selectedStates !== state));
        } else {
            setSelectedStates([...selectedStates, state]);
        }
    };
    const newSelectedStates = selectedStates.map(stateName => ({ "stateName": stateName }));


    const [selectedCities, setSelectedCities] = useState([]);
    const handleCityClick = (city) => {
        // Check if the city is already in the selectedCities array
        if (selectedCities.includes(city)) {
            // Remove the city if it's already selected
            setSelectedCities(selectedCities.filter((selectedCities) => selectedCities !== city));
        } else {
            // Add the city if it's not selected
            setSelectedCities([...selectedCities, city]);
        }
    };
    const newSelectedCities = selectedCities.map(cityName => ({ "cityName": cityName }));


    const [selectedWarehouses, setSelectedWarehouses] = useState([]);
    const handleWarehouseClick = (warehouse) => {
        if (selectedWarehouses.includes(warehouse)) {
            setSelectedWarehouses(selectedWarehouses.filter((selectedWarehouses) => selectedWarehouses !== warehouse));
        } else {
            setSelectedWarehouses([...selectedWarehouses, warehouse]);
        }
    };
    const newSelectedWarehouse = selectedWarehouses.map(warehouseName => ({ "warehouseName": warehouseName }));


    const handleSelectAllWarehouse = () => {
        setSelectedWarehouses(warehouses);
    };

    const validationPartnerDetail = (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            submitPartnerDetail()
        }
        // setValidated(true);
    };

    const submitPartnerDetail = () => {
        // setReload(false)
        var myHeaders = new Headers();
        myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "partnerId": partnerId,
            "partnerName": partnerData.partnerName,
            "contactNumber": partnerData.contactNumber,
            'password': partnerData.password,
            "selectedState": newSelectedStates,
            "selectedCity": newSelectedCities,
            "selectedWarehouse": newSelectedWarehouse,
            "vehicleNumber": partnerData.vehicleNumber,
            "trackingUrl": partnerData.trackingUrl,
            "partnerStatus": partnerData.partnerStatus,
            "logInStatus": partnerData.logInStatus,
            "selectedMethods": selectedCouriers
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(process.env.REACT_APP_URL + "deliverymethod/addUpDeliveryPartners", requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status === 'success') {
                    navigate('/delivery')
                    // setReload(true)
                    alert(result.msg)
                } else alert(result.msg)
            })
            .catch(error => console.log('error', error));
    }

    useEffect(() => {
        const fetchState = async () => {
            var myHeaders = new Headers();
            myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
            myHeaders.append("Content-Type", "application/json");

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                redirect: 'follow'
            };

            await fetch(process.env.REACT_APP_URL + "deliverymethod/getState", requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.status === 'success') {
                        setStates(result.data)
                    }
                })
                .catch(error => console.log('error', error));
        }
        fetchState()
    }, [])

    useEffect(() => {
        if (selectedStates.length !== 0) {
            var myHeaders = new Headers();
            myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                "stateName": selectedStates
            });

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            fetch(process.env.REACT_APP_URL + "deliverymethod/getCity", requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.status === 'success') {
                        setCities(result.data)
                    }
                })
                .catch(error => console.log('error', error));
        }
    }, [selectedStates])

    useEffect(() => {
        if ((selectedStates.length !== 0) && (selectedCities.length !== 0)) {
            var myHeaders = new Headers();
            myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                "stateName": selectedStates,
                "cityName": selectedCities,
            });

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            fetch(process.env.REACT_APP_URL + "deliverymethod/getWarehouse", requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.status === 'success') {
                        setWarehouses(result.data)
                    }
                })
                .catch(error => console.log('error', error));
        }
    }, [selectedStates, selectedCities])

    useEffect(() => {
        if ((selectedStates.length !== 0) && (selectedCities.length !== 0)) {
            var myHeaders = new Headers();
            myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                "stateName": selectedStates,
                "cityName": selectedCities,
            });

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            fetch(process.env.REACT_APP_URL + "deliverymethod/allDeliveryMethod", requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.status === 'success') {
                        setCouriers(result.data)
                    }
                })
                .catch(error => console.log('error', error));
        }
    }, [selectedStates, selectedCities])

    useEffect(() => {
        if ((selectedStates.length !== 0) && (selectedCities.length !== 0) && selectedCouriers.length !== 0) {
            var myHeaders = new Headers();
            myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                "stateName": selectedStates,
                "cityName": selectedCities,
                "courierId": selectedCouriers.map((item) => item.courierId)
            });

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            fetch(process.env.REACT_APP_URL + "deliverymethod/getSubMethod", requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.status === 'success') {
                        setMethodsData(result.data)

                    }
                })
                .catch(error => console.log('error', error));
        }
    }, [selectedStates, selectedCities, selectedCouriers])

    useEffect(() => {
        if (partnerId) {
            var myHeaders = new Headers();
            myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                "partnerId": partnerId,
                "courierId": courierId,
                "stateName": stateName,
                "cityName": cityName,
            });

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            fetch(process.env.REACT_APP_URL + "deliverymethod/getPartner", requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.status === 'success') {
                        setPartnerData(prevState => ({
                            ...prevState,
                            partnerName: result.data.partnerName ? result.data.partnerName : '',
                            methodName: result.data.methodName ? result.data.methodName : '',
                            courierName: result.data.courierName ? result.data.courierName : '',
                            cityName: result.data.cityName ? result.data.cityName : '',
                            partnerStatus: result.data.partnerStatus ? result.data.partnerStatus : '',
                            contactNumber: result.data.contactNumber ? result.data.contactNumber : '',
                            password: result.data.password ? result.data.password : '',
                            vehicleNumber: result.data.vehicleNumber ? result.data.vehicleNumber : '',
                            trackingUrl: result.data.trackingUrl ? result.data.trackingUrl : '',
                            logInStatus: result.data.logInStatus ? result.data.logInStatus : '',
                        }))
                        if (result.data.selectedState) {
                            const stateNames = result.data.selectedState.map(item => item.stateName);
                            setSelectedStates(stateNames)
                        }
                        if (result.data.selectedCity) {
                            const cityNames = result.data.selectedCity.map(item => item.cityName);
                            setSelectedCities(cityNames)
                        }
                        if (result.data.selectedWarehouse) {
                            const warehouseNames = result.data.selectedWarehouse.map(item => item.warehouseName);
                            setSelectedWarehouses(warehouseNames)
                        }
                        if (result.data.selectedMethods) {
                            setSelectedCouriers(result.data.selectedMethods)
                        }
                    }
                })
                .catch(error => console.log('error', error));
        }
    }, [partnerId, courierId, stateName, cityName])

    const divRef = useRef(null);
    const handleClickOutside = (event) => {
        if (divRef.current && !divRef.current.contains(event.target)) {
            setShowStateOption()
        }
    };
    useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    const divRefCity = useRef(null);
    const handleClickOutsideCity = (event) => {
        if (divRefCity.current && !divRefCity.current.contains(event.target)) {
            setShowCityOption()
        }
    };
    useEffect(() => {
        document.addEventListener('click', handleClickOutsideCity);
        return () => {
            document.removeEventListener('click', handleClickOutsideCity);
        };
    }, []);

    const divRefWarehouse = useRef(null);
    const handleClickOutsideWarehouse = (event) => {
        if (divRefWarehouse.current && !divRefWarehouse.current.contains(event.target)) {
            setShowWarehouseOption()
        }
    };
    useEffect(() => {
        document.addEventListener('click', handleClickOutsideWarehouse);
        return () => {
            document.removeEventListener('click', handleClickOutsideWarehouse);
        };
    }, []);

    console.log('partnerData', partnerData);

    return (
        <React.Fragment>
            <div className={`${'bgSale w-100 text-white cp'} ${styles.main_div}`}>
                <div className='d-flex justify-content-between align-items-center'>
                    <h3 className="pf mb-0"><u>Add Partner</u></h3>
                    <div className={`${styles.col_radio} px-4 align-items-center`}>
                        <label className='pb-2'>Login: </label>
                        <div className='pb-1'>
                            <input
                                className="me-1"
                                type="radio"
                                label="Active"
                                value="Active"
                                name="logInStatus"
                                onChange={e => setPartnerData(prevState => ({
                                    ...prevState,
                                    logInStatus: e.target.value
                                }))}
                                checked={partnerData.logInStatus === 'Active' ? true : false}
                                required
                            />
                            <span className={styles.active_btn}>Enable</span>
                        </div>
                        <div className='pb-1'>
                            <input
                                className="me-1"
                                type="radio"
                                label="Disable"
                                value="Disable"
                                name="logInStatus"
                                onChange={e => setPartnerData(prevState => ({
                                    ...prevState,
                                    logInStatus: e.target.value
                                }))}
                                checked={partnerData.logInStatus === 'Disable' ? true : false}
                                required
                            />
                            <span className={'text-danger'}>Disable</span>
                        </div>
                    </div>
                </div>
                <form onSubmit={validationPartnerDetail} className={styles.form_main_div}>
                    <div className={styles.form_partner_div}>
                        <div className={`${styles.col_input} pe-4`}>
                            <label>Partner Name</label>
                            <input
                                type="text"
                                placeholder="Enter Partner Name"
                                className="shadow p-1 bg-transparent textGray"
                                required
                                value={partnerData.partnerName}
                                onChange={e => setPartnerData(prevState => ({
                                    ...prevState,
                                    partnerName: e.target.value
                                }))}
                            // disabled={viewPartner === 'view' ? true : false}
                            />
                        </div>

                        <div className={`${styles.col_input} px-4`}>
                            <label>Contact Number</label>
                            <input
                                type="Number"
                                placeholder="Enter Contact No."
                                className="shadow p-1 bg-transparent textGray"
                                required
                                value={partnerData.contactNumber}
                                // disabled={viewPartner === 'view' ? true : false}
                                onChange={e => setPartnerData(prevState => ({
                                    ...prevState,
                                    contactNumber: e.target.value
                                }))}
                                maxLength="10"
                                onInput={(e) => e.target.value = e.target.value.slice(0, 10)}
                            />
                        </div>

                        <div className={`${styles.col_input} px-4`}>
                            <label>Login Id</label>
                            <input
                                type="Number"
                                placeholder={partnerData.logInStatus === 'Active' ? "Enter Login Id":'Not Required'}
                                className="shadow p-1 bg-transparent textGray"
                                required={partnerData.logInStatus === 'Active' ? true : false}
                                value={partnerData.logInStatus === 'Active' ? partnerData.contactNumber : ''}
                                disabled
                                onChange={e => setPartnerData(prevState => ({
                                    ...prevState,
                                    contactNumber: e.target.value
                                }))}
                                maxLength="10"
                                onInput={(e) => e.target.value = e.target.value.slice(0, 10)}
                            />
                        </div>
                        <div className={`${styles.col_input} px-4`}>
                            <label>Password</label>
                            <input
                                type="Number"
                                placeholder={partnerData.logInStatus === 'Active' ? "Enter Password":'Not Required'}
                                className="shadow p-1 bg-transparent textGray"
                                required={partnerData.logInStatus === 'Active' ? true : false}
                                disabled={partnerData.logInStatus === 'Active' ? false : true}
                                value={partnerData.password}
                                // disabled={viewPartner === 'view' ? true : false}
                                onChange={e => setPartnerData(prevState => ({
                                    ...prevState,
                                    password: e.target.value
                                }))}
                                maxLength="10"
                                onInput={(e) => e.target.value = e.target.value.slice(0, 10)}
                            />
                        </div>
                        <div className={`${styles.col_input} px-4`}>
                            <label>Confirm Password</label>
                            <input
                                type="Number"
                                placeholder={partnerData.logInStatus === 'Active' ? "Enter Confirm Password":'Not Required'}
                                className="shadow p-1 bg-transparent textGray"
                                required={partnerData.logInStatus === 'Active' ? true : false}
                                disabled={partnerData.logInStatus === 'Active' ? false : true}
                                value={partnerData.confirmPassword}
                                // disabled={viewPartner === 'view' ? true : false}
                                onChange={e => setPartnerData(prevState => ({
                                    ...prevState,
                                    confirmPassword: e.target.value
                                }))}
                                maxLength="10"
                                onInput={(e) => e.target.value = e.target.value.slice(0, 10)}
                            />
                            {partnerData.confirmPassword && (partnerData.confirmPassword !== partnerData.password) ? <p className="text-danger m-0 p-0">Confirm Password does not match</p> : null}
                        </div>
                        <div className={`${styles.col_input} pe-4`}>
                            <label>State Name</label>

                            <div className={styles.state_select_div} onClick={(e) => { e.stopPropagation(); if (showStateOption === 'states') { setShowStateOption() } else { setShowStateOption('states') } }}>
                                {selectedStates && selectedStates.length !== 0 ? selectedStates.map((item) => item + ', ') : <><span>Select State</span><span className={styles.input_dropdown}>{showStateOption === 'states' ? <DropUpIcon /> : <DropDownIcon />}</span></>}
                            </div>

                            {showStateOption === 'states' &&
                                <div className={styles.state_list} ref={divRef}>
                                    {states && states.map((state) =>
                                        <div role="button" className='bg-light text-dark d-flex justify-content-between align-items-center border-bottom px-2 py-1' key={state} onClick={() => handleStateClick(state)}>
                                            <span>{state}</span>
                                            {selectedStates.includes(state) ? <CheckIcon /> : ''}
                                        </div>
                                    )}
                                </div>
                            }

                        </div>
                        <div className={`${styles.col_input} px-4`}>
                            <label>City Name</label>
                            <div className={styles.state_select_div} onClick={(e) => { e.stopPropagation(); if (selectedStates.length !== 0) { if (showCityOption === 'cities') { setShowCityOption() } else { setShowCityOption('cities') } } else { alert('Please Select State Name First') } }}>
                                {selectedCities && selectedCities.length !== 0 ? selectedCities.map((item) => item + ', ') : <><span>Select City</span><span className={styles.input_dropdown}>{showCityOption === 'cities' ? <DropUpIcon /> : <DropDownIcon />}</span></>}
                            </div>
                            {showCityOption === 'cities' &&
                                <div className={styles.cities_list} ref={divRefCity}>
                                    {cities && cities.map((city) =>
                                        <div role="button" className='bg-light text-dark d-flex justify-content-between align-items-center border-bottom px-2 py-1' key={city} onClick={() => handleCityClick(city)}>
                                            <span>{city}</span>
                                            {selectedCities.includes(city) ? <CheckIcon /> : ''}
                                        </div>
                                    )}
                                </div>
                            }
                        </div>
                        <div className={`${styles.col_input} px-4`}>
                            <label>Warehouse</label>
                            <div className={styles.state_select_div} onClick={(e) => { e.stopPropagation(); if ((selectedStates.length !== 0) && (selectedCities.length !== 0)) { if (showWarehouseOption === 'warehouses') { setShowWarehouseOption() } else { setShowWarehouseOption('warehouses') } } else { alert('Please Select State Name And City Name First') } }}>
                                {selectedWarehouses && selectedWarehouses.length !== 0 ? selectedWarehouses.map((item) => item + ', ') : <>   <span>Select Warehouse</span><span className={styles.input_dropdown}>{showWarehouseOption === 'warehouses' ? <DropUpIcon /> : <DropDownIcon />}</span></>}
                            </div>
                            {showWarehouseOption === 'warehouses' &&
                                <div className={styles.warehouse_list} ref={divRefWarehouse}>
                                    {warehouses.length !== 0 && <div role="button" className='bg-light text-dark d-flex justify-content-between align-items-center border-bottom px-2 py-1' onClick={() => { if (selectedWarehouses === warehouses) { setSelectedWarehouses([]) } else { handleSelectAllWarehouse() } }}>
                                        <span>Select All</span>
                                        {selectedWarehouses === warehouses ? <CheckIcon /> : ''}
                                    </div>}
                                    {warehouses && warehouses.map((warehouse) =>
                                        <div role="button" className='bg-light text-dark d-flex justify-content-between align-items-center border-bottom px-2 py-1' key={warehouse} onClick={() => handleWarehouseClick(warehouse)}>
                                            <span>{warehouse}</span>
                                            {selectedWarehouses.includes(warehouse) ? <CheckIcon /> : ''}
                                        </div>
                                    )}
                                </div>
                            }
                        </div>

                        <div className={`${styles.col_input} px-4`}>
                            <label>Vehicle No (Optional)</label>
                            <input
                                type="Number"
                                placeholder="Enter Tracking No."
                                className="shadow p-1 bg-transparent textGray"
                                // disabled={viewPartner === 'view' ? true : false}
                                // required
                                value={partnerData.vehicleNumber}
                                onChange={e => setPartnerData(prevState => ({
                                    ...prevState,
                                    vehicleNumber: e.target.value
                                }))}
                            // onClick={() => setShowSelectOption()}
                            />
                        </div>
                        <div className={`${styles.col_input} px-4`}>
                            <label>Tracking URL (Optional)</label>
                            <input
                                type="text"
                                placeholder="Enter Tracking URL"
                                className="shadow p-1 bg-transparent textGray"
                                // disabled={viewPartner === 'view' ? true : false}
                                // required
                                value={partnerData.trackingUrl}
                                onChange={e => setPartnerData(prevState => ({
                                    ...prevState,
                                    trackingUrl: e.target.value
                                }))}
                            />
                        </div>
                        <div className={`${styles.col_radio_status} pe-4`}>
                            <label>Status</label>
                            <div className='pb-1'>
                                <input
                                    className="me-1"
                                    type="radio"
                                    label="Active"
                                    value="Active"
                                    name="status"
                                    onChange={e => setPartnerData(prevState => ({
                                        ...prevState,
                                        partnerStatus: e.target.value
                                    }))}
                                    checked={partnerData.partnerStatus === 'Active' ? true : false}
                                    required
                                />
                                <span className={styles.active_btn}>Active</span>
                            </div>
                            <div className='pb-1'>
                                <input
                                    className="me-1"
                                    type="radio"
                                    label="Disable"
                                    value="Disable"
                                    name="status"
                                    onChange={e => setPartnerData(prevState => ({
                                        ...prevState,
                                        partnerStatus: e.target.value
                                    }))}
                                    checked={partnerData.partnerStatus === 'Disable' ? true : false}
                                    required
                                />
                                <span className={'text-danger'}>Disable</span>
                            </div>
                            <div className='pb-1'>
                                <input
                                    className="me-1"
                                    type="radio"
                                    label="Blacklisted"
                                    value="Blacklisted"
                                    name="status"
                                    onChange={e => setPartnerData(prevState => ({
                                        ...prevState,
                                        partnerStatus: e.target.value
                                    }))}
                                    checked={partnerData.partnerStatus === 'Blacklisted' ? true : false}
                                    required
                                />
                                <span className={'text-danger'}>Blacklisted</span>
                            </div>
                        </div>

                    </div>

                    {couriers && couriers.length !== 0 &&
                        <div className='mt-4'>
                            <h3><u>Select Method</u></h3>
                            <div className={styles.select_method_div}>
                                {couriers.map((item, index) => (
                                    <div key={index} className={`${styles.select_method_col} me-5 pe-5 mb-3`}>
                                        <label className={`${styles.courierName} me-4 mb-0`}>{item.courierName}</label>
                                        <div role='button' className='border rounded p-1 d-flex justify-content-center align-items-center' onClick={() => handleCouriers(item._id, item.courierName)}>
                                            {selectedCouriers.some(courier => courier.courierId === item._id) ? <CheckGreenIcon /> : <span className='p-2'></span>}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    }

                    {methodsData && (methodsData.length !== 0) &&
                        <>
                            <h3><u>Select Sub-Method</u></h3>
                            <div className={styles.subMethods_scroll_div}>
                                {methodsData.map((item, index) =>
                                    <div key={index} className={styles.select_Sub_method}>
                                        <label className='mb-1'>{item.courierName}:</label>
                                        <div className='d-flex'>
                                            {item.methods &&
                                                item.methods.length !== 0 &&
                                                item.methods.map((i, idx) => (
                                                    <div key={idx} className={`${styles.select_method_col} ${idx === 0 ? 'me-4' : 'mx-4'}`}>
                                                        <label className='me-3 mb-0'> {i.methodName}</label>
                                                        <div
                                                            role='button'
                                                            className='border rounded p-1 d-flex justify-content-center align-items-center'
                                                            onClick={() => handleSubMethod(item._id, i.methodName, i.methodStatus)}
                                                        >
                                                            {selectedCouriers.some(courier => courier.courierId === item._id && courier.selectedSubMethod.some(method => method.methodName === i.methodName)) ? (
                                                                <CheckGreenIcon />
                                                            ) : (
                                                                <span className='p-2'></span>
                                                            )}
                                                        </div>
                                                    </div>
                                                ))}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </>
                    }

                    <div className={styles.partner_footer}>
                        <RedOutButton title="BACK" btnType='button' css="me-5" handleSubmit={() => navigate('/delivery')} />
                        {partnerId ?
                            edit ?
                                <GreenButton btnType='submit' title={"UPDATE"} css="ms-5" />
                                : ''
                            :
                            <GreenButton btnType='submit' title={"CREATE"} css="ms-5" />
                        }
                    </div>
                </form>
            </div>,
        </React.Fragment>
    )
}

export default AddPartner