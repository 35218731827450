
// import React, { useEffect, useRef, useState } from "react";
// import styles from "./progress.module.css";
// import { AddBtnicon } from "../../icons/Icon2";
// import { useAuth } from "../../context/Auth";

// export const Accordion = ({ children, type = "single", collapsible = true, className }) => {
//   const [openItem, setOpenItem] = useState(null);


//   const handleToggle = (value) => {
//     if (collapsible && openItem === value) {
//       setOpenItem(null);
//     } else if (type === "single") {
//       setOpenItem(value);
//     } else {
//       setOpenItem((prev) => (prev === value ? null : value));
//     }
//   };

//   return (
//     <div className={`${styles.accordion} ${className} `}>
//       {React.Children.map(children, (child) =>
//         React.cloneElement(child, {
//           openItem,
//           onToggle: handleToggle,
//         })
//       )}
//     </div>
//   );
// };

// export const AccordionItem = ({ value, children, openItem, onToggle, style, className, ref }) => {
//   const isOpen = openItem === value;
//   return (
//     <div className={`${styles.accordionItem} ${className} `} style={style} ref={ref}>
//       {React.Children.map(children, (child) =>
//         React.cloneElement(child, {
//           isOpen,
//           value,
//           onToggle,
//           style,
//         })
//       )}
//     </div>
//   );
// };


// export const AccordionTrigger = ({ children, value, onToggle, isOpen, style, className, pop }) => {
//   const { mode } = useAuth();
//   return (
//     <div className={`${styles.accordionTrigger} ${className} `} onClick={() => onToggle(value)} style={style}>
//       <div style={{ position: "absolute", right: "30px", top: "38%" }}> {pop ? null : <AddBtnicon mode={mode} rotate={isOpen ? 180 : 0} />}  </div>
//       <div>  {children} </div>
//     </div>
//   );
// };






// export const AccordionContent = ({ children, isOpen, style, className }) => {
//   const contentRef = useRef(null);
//   const [height, setHeight] = useState("0px");

//   useEffect(() => {
//     if (isOpen) {
//       setHeight(`${contentRef.current.scrollHeight}px`);
//     } else {
//       setHeight("0px");
//     }
//   }, [isOpen]);

//   return (
//     <div
//       ref={contentRef}
//       style={{
//         ...style,
//         maxHeight: height,
//         zIndex: isOpen ? 10 : 1,  // Higher z-index when open
//       }}
//       className={`${styles.accordionContent} ${className}  ${isOpen ? styles.active : ""}`}
//     >
//       <div>{children}</div>
//     </div>
//   );
// };



import React, { useEffect, useRef, useState } from "react";
import styles from "./progress.module.css";
import { AddBtnicon } from "../../icons/Icon2";

// Accordion Component
export const Accordion = ({ children, type = "single", collapsible = true, className }) => {
  const [openItem, setOpenItem] = useState(null);

  // Toggle the accordion item open or closed
  const handleToggle = (value) => {
    if (collapsible && openItem === value) {
      setOpenItem(null); // Close the item if it's already open and collapsible
    } else if (type === "single") {
      setOpenItem(value); // Open only one item at a time
    } else {
      setOpenItem((prev) => (prev === value ? null : value)); // Open/close the item in multiple mode
    }
  };

  return (
    <div className={`${styles.accordion} ${className}`}>
      {React.Children.map(children, (child) =>
        React.cloneElement(child, {
          openItem,
          onToggle: handleToggle,
        })
      )}
    </div>
  );
};

// AccordionItem Component
export const AccordionItem = ({ value, children, openItem, onToggle, style, className, ref }) => {
  const isOpen = openItem === value;
  return (
    <div className={`${styles.accordionItem} ${className}`} style={style} ref={ref}>
      {React.Children.map(children, (child) =>
        React.cloneElement(child, {
          isOpen,
          value,
          onToggle,
          style,
        })
      )}
    </div>
  );
};

// AccordionTrigger Component
export const AccordionTrigger = ({ children, value, onToggle, isOpen, style, className, pop }) => {
  return (
    <div className={`${styles.accordionTrigger} ${className}`} onClick={() => onToggle(value)} style={style}>
      <div style={{ position: "absolute", right: "30px", top: "38%" }}>
        {pop ? null : <AddBtnicon rotate={isOpen ? 180 : 0} />}
      </div>
      <div>{children}</div>
    </div>
  );
};

// AccordionContent Component
export const AccordionContent = ({ children, isOpen, style, className }) => {
  const contentRef = useRef(null);
  const [height, setHeight] = useState("0px");
// console.log("height",height);

  useEffect(() => {
    const updateHeight = () => {
      if (contentRef.current) {
        setHeight(`${contentRef.current.scrollHeight}px`);
      }
    };

    if (isOpen) {
      updateHeight(); // Open the item, calculate its height
    } else {
      setHeight("0px"); // Close the item, reset the height
    }

    // ResizeObserver to adjust height if the content changes dynamically
    const resizeObserver = new ResizeObserver(updateHeight);
    if (contentRef.current) {
      resizeObserver.observe(contentRef.current);
    }

    return () => {
      if (contentRef.current) {
        resizeObserver.disconnect();
      }
    };
  }, [isOpen]); // Update height when the item is toggled open/closed

  return (
    <div
      ref={contentRef}
      style={{
        ...style,
        maxHeight: isOpen ? height : "0px", // Only set height if open
        zIndex: isOpen ? 10 : 1, // Higher z-index when open
        overflow: "hidden", // Ensure content doesn't overflow the accordion container
        transition: "max-height 0.3s ease-in-out", // Smooth transition for the height change
        // border:"1px solid"
      }}
      className={`${styles.accordionContent} ${className} ${isOpen ? styles.active : ""}`}
    >
      <div>{children}</div>
    </div>
  );
};
