import React from 'react'
import styles from "../../../pages/kyc/Dealer.module.css"
import { AllCount, Deleted, FilterIcon, Verified } from '../../icons/Icon2'
import { useAuth } from '../../context/Auth'
import { DealerInfoCardActiveStatusIcon, DealerInfoCardBlackListedStatusIcon, DealerInfoCardNewStatusIcon, DealerInfoCardPendingStatusIcon, DealerInfoCardRejectedStatusIcon } from '../../icons/Icon'
const FilterCard = ({ name, number }) => {
    const { mode } = useAuth();

    const renderStatusIcon = () => {
        switch (name) {
            case "Blacklisted":
                return <DealerInfoCardBlackListedStatusIcon />;
            case "New":
                return <DealerInfoCardNewStatusIcon />;
            case "Pending":
                return <DealerInfoCardPendingStatusIcon />;
            case "Rejected":
                return <DealerInfoCardRejectedStatusIcon />;
            case "All":
                return <AllCount />;
            case "Verified":
                return <Verified />;
            case "Update":
                return <DealerInfoCardActiveStatusIcon />;
            case "Deleted":
                return <Deleted />;
            default:
                return null;
        }
    };


    return (
        <div className={mode ? styles.md_filterCard : styles.md_filterCardDark} >
            <div className={mode ? styles.md_filterIcon_dark : styles.md_filterIcon}>
                {renderStatusIcon()}
            </div>
            <div className={styles.md_Filtertext}>
                <span className={mode ? styles.md_textFiltername_dark : styles.md_textFiltername}>{name}</span>
                <span className={mode ? styles.md_textFilternumber_dark : styles.md_textFilternumber} >{number}</span>
            </div>
        </div>
    )
}

export default FilterCard