import React, { useEffect, useState } from 'react'
import { useAuth } from '../../components/context/Auth';
import styles from '../dispatchmanager/DishpatchManager.module.css'
import { useNavigate } from 'react-router-dom';
import Pagination from '../../components/Paginantion';
import OrdersTab from '../../components/OrdersTab';

const AdjustmentOrders = () => {
    const navigate = useNavigate();
    const { switchDisplay, managerId } = useAuth();
    const [orders, setOrders] = useState("")
    const [allData, setAllData] = useState([])
    const [page2, setPage2] = useState(1)
    const [pages2, setPages2] = useState(1)
    const [loading, setLoading] = useState(false)
    const [sortKey, setSortKey] = useState('')
    // const currentDate = new Date('Wed Jul 22 2020 10:57:59 GMT+0300 (East Africa Time)');
    // const currentDayOfMonth = currentDate.getDate();
    // const currentMonth = currentDate.getMonth(); // Be careful! January is 0, not 1
    // const currentYear = currentDate.getFullYear();
    // const dateString = currentDayOfMonth + "-" + (currentMonth + 1) + "-" + currentYear;



    useEffect(() => {
        if (managerId) {
            setLoading(true)
            var myHeaders = new Headers();
            myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                "managerId": managerId,
                "sortKey": sortKey
            });

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            fetch(process.env.REACT_APP_URL + "inventory/allAdjustment?page=" + page2, requestOptions)
            // fetch("http://192.168.0.109:5052/inventory/allAdjustment?page=" + page2, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.status === "success") {
                        setLoading(false)
                        setPages2(result.pages)
                        setAllData(result.data)
                        setOrders(result)
                    }
                })
                .catch(error => console.log('error', error));
        }
    }, [managerId, page2, sortKey])



    return (
        <React.Fragment>
            <div className={`${!switchDisplay ? styles.bg_dark : styles.bg_white} ${styles.ff}`}>

                <OrdersTab styles={styles} orders={orders} sortKey={sortKey} setSortKey={setSortKey} loading={loading} />
                {loading ? <div className={'order_loadingMain'}>
                    <img src='/wallicon.gif' alt='walliconGIF' />
                </div> :
                    <>
                        <div className={styles.order_heightFix_div}>
                            <table className={styles.itemTable}>
                                <thead>
                                    <tr className={styles.item}>
                                        <th>Voucher No</th>
                                        <th>Warehouse</th>
                                        <th>Date</th>
                                        <th>Amount</th>
                                        <th>Status</th>
                                        <th>Items</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {allData && allData.map((val, index) =>
                                        <tr key={index}
                                            className={styles.item}
                                            onClick={() => {
                                                navigate('/orders/adjustment?orderId=' + val.voucherNo);
                                            }}
                                        >
                                            <td>{val.voucherNo} </td>
                                            <td>{val.warehouse} </td>
                                            <td>{val.voucherDate} </td>
                                            <td>{val.totalAmount} </td>
                                            <td className={`${val.status === 'draft' ? styles.table_txt_yellow : styles.table_txt_green}`}>{val.status} </td>
                                            <td>{val.totalItems} </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                        <Pagination
                            total={pages2}
                            current={page2}
                            pagination={(crPage) => setPage2(crPage)}
                        />
                    </>
                }
            </div>
        </React.Fragment>
    )
}
export default AdjustmentOrders;