import { useRef, useEffect, useCallback } from 'react'; 

const useDebounce = (callback, delay, dependencies = []) => {
    const debounceTimeout = useRef(null);

    // Ensure dependencies is an array
    const debouncedCallback = useCallback(callback, Array.isArray(dependencies) ? dependencies : []);  

    useEffect(() => {
        if (debounceTimeout.current) {
            clearTimeout(debounceTimeout.current); 
        }

        debounceTimeout.current = setTimeout(() => {
            debouncedCallback();
        }, delay);

        return () => clearTimeout(debounceTimeout.current);
    }, [debouncedCallback, delay, ...dependencies]); 
};

export default useDebounce;

