import React, { useEffect, useState } from 'react'
import { Search, ThreeDots, } from '../../components/icons/Icon';
import { useAuth } from '../../components/context/Auth';
import CreateContactModal from '../marketing/CreateContactModal';
import styles from './Marketing.module.css';
import { GreenButton, GreenOutButton } from '../../components/Buttons';

const Contacts = () => {
    const [data, setData] = useState([]);
    const { switchDisplay, managerId } = useAuth();
    const [showModal, setShowModal] = useState(false)
    const [id, setId] = useState("")
    const [success, setSuccess] = useState()
    const [action, setAction] = useState({
        show: false,
        index: 0
    })

    const selectModal = () => {
        setShowModal(!showModal); // true/false toggle
    }

    useEffect(() => {

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);

        var raw = JSON.stringify({
            "managerId": managerId,
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(process.env.REACT_APP_URL + "marketing/contacts", requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status === "success") {
                    if (result.data) {
                        setData(result.data)
                    }
                }
            })
            .catch(error => console.log('error', error));
    }, [id, managerId, success])


    return (
        <React.Fragment>
            <CreateContactModal
                displayModal={showModal}
                closeModal={selectModal}
                id={id}
                setId={setId}
                setSuccess={setSuccess}
            />
            <div className={`${switchDisplay ? styles.bg_white : styles.bg_dark} ${styles.marketingMain}`}>
                <h2>Marketing &#62; Contacts</h2>
                <div className={styles.marketing}>
                    <div className={styles.marketingRow}>
                        <div className={styles.marketingCol}>
                            <div className={styles.search}>
                                <input type="search" placeholder='Search' className={`${switchDisplay ? styles.bg_light : styles.bg_grey}  ${styles.search_input}`} />
                                <Search color="#ffffff" css={styles.searchIcon} />
                            </div>

                            <span className={styles.ml30}>
                                Sort By
                                <select className={`${switchDisplay ? styles.bg_white : styles.bg_grey} ${styles.sortSelect}`}>
                                    <option>Newest First</option>
                                    <option>Oldest First</option>
                                    <option>Ascending</option>
                                    <option>Decending</option>
                                    <option>Payment Pending</option>
                                    <option>Approved</option>
                                    <option>Dispatched</option>
                                    <option>Packing In Progress</option>
                                    <option>Check Stock &amp; Approve</option>
                                    <option>Waiting For Dealer Approval</option>
                                    <option>COD (Payment Pending)</option>
                                    <option>Completed</option>
                                </select>
                            </span>
                        </div>
                        <div >
                            <GreenOutButton title="Import Contact" handleSubmit={selectModal} />
                            <GreenButton title="Create Contact" handleSubmit={selectModal}  css={styles.ms_10}/>
                        </div>
                    </div>
                    <div className={styles.marketingScroll}>
                        <div className={`${switchDisplay ? styles.bg_white : styles.bg_grey} ${styles.tableRow}  ${styles.tableCss}`}>
                            <div className={styles.col2}>Name</div>
                            <div className={styles.col}>Company</div>
                            <div className={styles.col}>City</div>
                            <div className={styles.col2}>Address</div>
                            <div className={styles.col}>Contact No.</div>
                            <div className={styles.col2}>Email</div>
                            <div className={styles.col}>Source</div>
                            <div className={styles.col}>Action</div>
                        </div>
                        {data && data.map((item, index) =>
                            <div key={index} className={styles.position_relative} onMouseLeave={() => setAction({ show: false, index: 0 })}>
                                <div className={`${switchDisplay ? styles.bg_white : styles.bg_grey} ${styles.tableRow}  ${styles.tableCss}`} >
                                    <div className={styles.col2}>{item.name}</div>
                                    <div className={styles.col}>{item.companyName}</div>
                                    <div className={styles.col}>{item.city}</div>
                                    <div className={styles.col2}>{item.address}</div>
                                    <div className={styles.col}>{item.mobile}</div>
                                    <div className={styles.col2}>{item.email}</div>
                                    <div className={styles.col}>{item.source}</div>
                                    <div className={styles.col}>
                                        <span className={styles.w_100} onMouseOver={() => setAction({ show: true, index: index })} ><ThreeDots color="#FFFFFF" css={styles.mouse} /></span>
                                    </div>
                                </div>


                                {(action.show && action.index === index) &&
                                    <div className={styles.threeDots_div_position}>
                                        <div className={`${switchDisplay ? styles.bg_light : styles.bg_dark} ${styles.threeDotBP}`}>
                                            <div className={styles.mb5} onClick={() => { selectModal(); setId(item.contactId) }}>
                                                Edit Contact
                                            </div>
                                            {/* <div className={switchDisplay ? styles.threeDotBL : styles.threeDotBB}>
                                        <div className={styles.mt5}>
                                            Mark As Lead Lost
                                        </div>
                                    </div> */}
                                        </div>
                                    </div>
                                }
                            </div>
                        )}
                    </div>
                </div>
            </div>


        </React.Fragment>
    )
}


export default Contacts