import styles from "./FromComponent.module.css";
import React, { useEffect, useState } from "react";
import InputTag from "./InputTag";
import Button from "../Dealers/Button";
import fetchData from "../../../APIsControll/apiControll";
import { useAuth } from "../../context/Auth";

const AddDesignation = ({ handleCloseModal }) => {
  const { mode } = useAuth();
  const [designation, setDesignation] = useState("");
  const [status, setStatus] = useState(null);
  const [allDesignations, setAllDesignations] = useState([]);

  useEffect(() => {
    const getAllDesignations = async () => {
      const data = await fetchData("managers/addDesignation");
      setAllDesignations(data.data);
    };
    getAllDesignations();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!designation) {
      setStatus({ type: "error", msg: "Designation is required" });
      return;
    }

    const res = await fetchData("managers/addDesignation", { designation });

    if (res.status === "success") {
      setStatus({ type: "success", msg: "Designation added successfully" });
      alert("Designation added successfully");
      setDesignation("");
      handleCloseModal(true);
    } else {
      setStatus({ type: "error", msg: res.msg });
    }
  };

  return (
    <div>
      <form
        className={styles.add_des}
        style={{ backgroundColor: mode && "#232529" }}
      >
        <p style={{ color: mode && "#ffffff" }}>Add Designation</p>

        <InputTag
          name="designation"
          value={designation}
          labelText={"Designation"}
          placeholderText={"Enter a designation"}
          placeholderType={"text"}
          required={true}
          star
          maxLength={20}
          onChange={(e) => setDesignation(e.target.value)}
        />

        <Button
          buttonText={"Add"}
          buttonBgColor={"#4164E3"}
          buttonTextColor={"#ffffff"}
          buttonBorder={"none"}
          buttonClick={(e) => {
            handleSubmit(e);
          }}
        />
      </form>

      {status && (
        <div style={{ color: status.type === "error" ? "red" : "green" }}>
          {status.msg}
        </div>
      )}
    </div>
  );
};

export default AddDesignation;
