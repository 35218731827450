import { DragNDropIcon } from "../../icons/Icon";
import styles from "../../../pages/ordermanager/Orders.module.css";

const FileUpload = ({ mode }) => {
  return (
    <div
      className={styles.file_upload}
      style={mode ? { backgroundColor: "#1B1D21", borderColor: "#646B88" } : {}}
    >
      <DragNDropIcon />
      <p>
        Drag & Drop <br /> any file
      </p>
      <p style={mode ? { color: "#fff" } : {}}>
        or{" "}
        <span style={{ color: "#4164E3", textDecoration: "underline" }}>
          browse files
        </span>{" "}
        on your computer
      </p>
    </div>
  );
};

export default FileUpload;
