import React from "react";
import styles from "../globalwhatsappchat.module.css";
import { useAuth } from "../../../context/Auth";
const OneToOneChat = ({ children }) => {
    const { mode } = useAuth();
  return (
    <div
      className={`${styles.whatsappMessage_chats} ${
        mode ? styles.darkMode : styles.lightMode
      }`}
    >
        {children}
    </div>
  );
};

export default OneToOneChat;
