import React, { useEffect, useState } from "react";
import styles from "./FromComponent.module.css";
import { useAuth } from "../../context/Auth";

const ToggleBtn = ({ status, placeholder, onClick }) => {
  const [isToggled, setIsToggled] = useState(false);
  const { mode } = useAuth();
  useEffect(() => {
    if (status === "active") {
      setIsToggled(true);
    } else {
      setIsToggled(false);
    }
  }, [status]);

  return (
    <div className={styles.toggle_btn}>
      <div style={{ display: "flex", flexDirection: "row", gap: "1rem" }}>
        <p
          className={styles.toggle_btn_p}
          style={{
            fontWeight: "500",
            color: mode ? "#ffffff" : "#646B88",
            fontSize: "14px",
          }}
        >
          {placeholder ? placeholder : isToggled ? "ON" : "OFF"}
        </p>
        <div className={styles.toggle_container} onClick={onClick}>
          <div
            className={`${styles.toggle_button} ${
              isToggled ? styles.active : ""
            }`}
          >
            <div
              className={styles.slider}
              style={mode ? { backgroundColor: "#1B1D21" } : {}}
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ToggleBtn;
