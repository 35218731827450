import React, { useState, useRef, useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom';
import { GreenButton, GreenOutButton, RedOutButton, WhiteOutButton } from '../../components/Buttons';
import { useAuth } from '../../components/context/Auth';
import { CrossCircleIcon, Search, Spinner, } from '../../components/icons/Icon';
import VouchersTab from '../../components/VouchersTab';

import LiveSearch from '../ordermanager/LiveSearch';
import styles from '../ordermanager/Order.module.css'
// import States from "./States.json"

const Purchase = () => {
    const { managerId, managerName } = useAuth()
    const [searchParams] = useSearchParams()
    const navigate = useNavigate()
    const orderId = searchParams.get('orderId')
    const [results, setResults] = useState()
    const [searchItem, setSearchItem] = useState("")
    const [loading, setLoading] = useState(false)

    const [generalInfo, setGeneralInfo] = useState({
        vendorName: "",
        voucherNo: "",
        voucherDate: "",
        warehouse_id: "",
        shippingCharge: "",
        manageName: "",
        manageValue: ""
    })

    const qtyFocus = useRef()

    const [itemsdetails, setItemsdetails] = useState([{
        cartType: "",
        itemNo: "",
        price: "",
        product_name: "",
        thumbnail: "",
        totalQty: "",
        qty: "",
        unit: "",
        warehouse: "",
    }]);


    // handle input change
    const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...itemsdetails];
        list[index][name] = value;
        setItemsdetails(list);
    };

    // handle click event of the Remove button
    const handleRemoveClick = index => {
        const list = [...itemsdetails];
        list.splice(index, 1);
        setItemsdetails(list);
    };

    // handle click event of the Add button
    const handleAddClick = () => {
        setSearchItem("");
        setItemsdetails([...itemsdetails, { itemNo: "", product_name: "", collection_name: "", qty: "", price: "", unit: "", amount: "" }]);
    };

    useEffect(() => {
        if (searchItem) {
            var myHeaders = new Headers();
            myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                "managerId": managerId,
                "keyword": searchItem,
                "warehouse_id": generalInfo.warehouse_id,
            });

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            fetch(process.env.REACT_APP_URL + "inventory/searchPurchaseItems?page=", requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.status === "success") {
                        setResults(result.data)
                    }
                })
                .catch(error => console.log('error', error));
        }
    }, [generalInfo.cartType, generalInfo.warehouse_id, searchItem, managerId])

    function enforce_maxlength(e) {
        var t = e.target;
        if (t.hasAttribute('maxlength')) {
            t.value = t.value.slice(0, t.getAttribute('maxlength'));
        }
    }

    const unitRef = useRef()
    const priceRef = useRef()

    const [warehouses, setWarehouses] = useState([])

    useEffect(() => {
        if (managerId) {
            var myHeaders = new Headers();
            myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                "managerId": managerId,
                "voucherNo": orderId,
                // "warehouse_id": generalInfo.warehouse_id
            });

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            fetch(process.env.REACT_APP_URL + "inventory/singlePurchase", requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.status === "success") {
                        if (result.data.order) {
                            setGeneralInfo(prevState => ({
                                ...prevState,
                                vendorName: result.data.order.vendorName ? result.data.order.vendorName : "",
                                voucherNo: result.data.order.voucherNo ? result.data.order.voucherNo : "",
                                voucherDate: result.data.order.voucherDate ? result.data.order.voucherDate : "",
                                warehouse_id: result.data.order.warehouse_id ? result.data.order.warehouse_id : "",
                                shippingCharge: result.data.order.shippingCharge ? result.data.order.shippingCharge : "",
                                manageName: result.data.order.manageName ? result.data.order.manageName : "",
                                manageValue: result.data.order.manageValue ? result.data.order.manageValue : "",
                                status: result.data.order.status ? result.data.order.status : "",
                            }))
                            setItemsdetails(result.data.order.itemsdetails)
                        }
                        if (result.data.warehouses) {
                            setWarehouses(result.data.warehouses)
                        }
                    }
                })
                .catch(error => console.log('error', error));
        }
    }, [managerId, orderId])

    const cartTypeRef = useRef(null)
    const itemseacrhRef = useRef(null)
    const paymentRef = useRef(null)


    const handleSubmit = (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            handlePuchaseOrder("success")
        }
    }

    // const qty = itemsdetails.map(obj => parseFloat(obj.qty)).reduce((a, b) => a + b)
    // const totalItems = itemsdetails.length

    let cartPriceSum = itemsdetails && itemsdetails.reduce((sum, currentItem) => {
        return sum + (parseFloat(currentItem.price ? currentItem.price : 0) * parseFloat(currentItem.qty ? currentItem.qty : 0));
    }, 0)

    var cartTotal = itemsdetails && itemsdetails.map(x => !x.accessories ? 0 : x.accessories.map(y => (y.accessoriesQty ? y.accessoriesQty : 0) * y.accessoriesPrice).reduce((a, b) => a + b, 0)).reduce((a, b) => a + b, 0)
    let subtotal = cartPriceSum ? cartPriceSum : 0 + cartTotal ? cartTotal : 0
    let totalAmount = subtotal + parseFloat(generalInfo.shippingCharge ? generalInfo.shippingCharge : 0) + parseFloat(generalInfo.manageValue ? generalInfo.manageValue : 0)


    const handlePuchaseOrder = (status) => {
        setLoading(true)
        var myHeaders = new Headers();
        myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "managerId": managerId,
            "voucherNo": orderId,
            "warehouse_id": generalInfo.warehouse_id,
            "vendorName": generalInfo.vendorName,
            "shippingCharge": generalInfo.shippingCharge,
            "manageName": generalInfo.manageName,
            "manageValue": generalInfo.manageValue,
            "itemsdetails": itemsdetails,
            "status": status,
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(process.env.REACT_APP_URL + "inventory/purchase", requestOptions)
            .then(response => response.json())
            .then(result => {
                alert(result.msg)
                navigate("/orders/purchaseinvoice/" + result.data.voucherNo)
            })
            .catch(error => console.log('error', error))
            .finally(()=>   setLoading(false))
    }


    return (
        <React.Fragment>

            <div className={styles.main_div_dark}>
                <div className={styles.main_container}>

                    <div className={styles.tabs_div}>
                        <h2 className={styles.orderHeading}>Purchase Order</h2>
                        <VouchersTab styles={styles} />
                    </div>

                    <form onSubmit={handleSubmit} className={styles.ordersScroll}>
                        <div className={styles.d_flex}>
                            <div className={styles.orderContent_div}>
                                <div className={styles.orderInput_div}>
                                    <label className={styles.order_txt}>Vendors Name</label>
                                    <input
                                        type="text"
                                        placeholder='Enter Name'
                                        className={styles.inputField}
                                        value={generalInfo.vendorName}
                                        onChange={(e) => setGeneralInfo(prevState => ({
                                            ...prevState,
                                            vendorName: e.target.value
                                        }))}
                                        onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                        required
                                    />
                                    <span className={styles.search_icon}><Search color="#FFFFFF" /></span>
                                </div>

                                <div className={styles.orderInput_div}>
                                    <label className={styles.order_txt}>Manager Name</label>
                                    <input
                                        type="text"
                                        placeholder='Enter Name'
                                        className={styles.inputField}
                                        defaultValue={managerName}
                                        disabled
                                    />
                                </div>




                            </div>
                            <div className={styles.orderContent_div}>
                                <div className={styles.orderInput_div}>
                                    <label className={styles.order_txt}>Voucher No.</label>
                                    <div className={styles.voucherDate_div}>
                                        <span><b>{generalInfo.voucherNo}</b></span>
                                        <span>Voucher Date</span>
                                        <span><b>{generalInfo.voucherDate}</b></span>
                                    </div>
                                </div>

                                <div className={styles.orderInput_div}>
                                    <label className={styles.order_txt}>Warehouse</label>
                                    <select
                                        className={styles.whereHouse_select_box}
                                        value={generalInfo.warehouse_id}
                                        onChange={(e) => setGeneralInfo(prevState => ({
                                            ...prevState,
                                            warehouse_id: e.target.value
                                        }))}
                                        required
                                    >
                                        <option value="">All Warehouse</option>
                                        {warehouses && warehouses.map((item, index) => (
                                            <option key={index} value={item._id}>{item.warehousename}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </div>

                        <table className={styles.table_main2} >
                            <thead>
                                <tr>
                                    <th className={styles.table_th_Sno}>S. No</th>
                                    <th className={styles.table_th}>Item Description </th>
                                    <th className={styles.table_th_details}>Qty </th>
                                    <th className={styles.table_th_details}>Per</th>
                                    <th className={styles.table_th_details}>Rate</th>
                                    <th className={styles.table_th_details}>Amount</th>
                                </tr>
                            </thead>

                            <tbody>

                                {itemsdetails && itemsdetails.map((x, index) =>
                                    <tr key={index} onKeyDown={(e) => {
                                        if (e.ctrlKey && e.keyCode === 88) {
                                            handleRemoveClick(index + 1);
                                            paymentRef.current.focus()
                                        }
                                    }}
                                        className={styles.table_main2tr2}
                                    >
                                        <td className={styles.table_th_Sno}>{index + 1}</td>
                                        <td className={styles.table_th + " " + styles.w_50}>
                                            <div className={styles.table_th_div1}>
                                                <LiveSearch
                                                    results={results}
                                                    itemsdetails={itemsdetails}
                                                    cartType={generalInfo.cartType}
                                                    value={x.itemNo ? x.itemNo : searchItem}
                                                    renderItem={item => <>{item.name}</>}
                                                    onChange={(e) => setSearchItem(e.target.value)}
                                                    setSearchItem={setSearchItem}
                                                    itemseacrhRef={itemseacrhRef}
                                                    cartTypeRef={cartTypeRef}
                                                    searchType="purchase"
                                                    warehouse={generalInfo.warehouse_id}
                                                    handleRemoveClick={() => handleRemoveClick(index + 1)}
                                                    removeIndex={index + 1}
                                                    onSelect={item => {
                                                        // if (!generalInfo.cartType) {
                                                        //     setGeneralInfo(prevState => ({ ...prevState, cartType: item.cartType }))
                                                        // }
                                                        setItemsdetails([...itemsdetails.slice(0, index),
                                                        {
                                                            itemNo: item.itemNo,
                                                            product_name: item.product_name,
                                                            unit: item.unit,
                                                            qty: "",
                                                            price: ""
                                                        },
                                                        ...itemsdetails.slice(index + 1)])

                                                    }}
                                                    qtyFocus={qtyFocus}
                                                />

                                                {x.product_name} <span className={styles.ms_5}>{x.warehouse}</span>
                                            </div>
                                        </td>
                                        <td className={styles.table_th_details} >
                                            <input
                                                type="number"
                                                ref={qtyFocus}
                                                placeholder="Enter Qty"
                                                name='qty'
                                                value={x.qty}
                                                onChange={e => {
                                                    enforce_maxlength(e, index);
                                                    handleInputChange(e, index);
                                                }}
                                                // onKeyPress={(e) => { e.key === 'Enter' return (unitRef.current.focus() && e.preventDefault()) }}
                                                onKeyPress={(e) => {
                                                    if (e.key === 'Enter') {
                                                        e.preventDefault()
                                                        unitRef.current.focus()
                                                    }
                                                }}
                                                required
                                            />
                                        </td>
                                        <td className={styles.table_th_details}>
                                            <select
                                                name='unit'
                                                value={x.unit}
                                                onChange={e => { handleInputChange(e, index); }}
                                                ref={unitRef}
                                                onKeyPress={(e) => {
                                                    if (e.key === 'Enter') {
                                                        e.preventDefault()
                                                        priceRef.current.focus()
                                                    }
                                                }}
                                                required
                                            >
                                                <option value="" hidden>Select</option>
                                                <option value="Roll">Roll</option>
                                                <option value="Pcs">Pcs</option>
                                                <option value="Box">Box</option>
                                                <option value="Sq Meter">Sq Meter</option>
                                                <option value="Meter">Meter</option>
                                                <option value="Sq Feet">Sq Feet</option>
                                                <option value="Feet">Feet</option>
                                                <option value="Kg">Kg</option>
                                            </select>
                                        </td>
                                        <td className={styles.table_th_details} >
                                            <input
                                                type="number"
                                                name='price'
                                                value={x.price}
                                                ref={priceRef}
                                                onChange={e => {
                                                    enforce_maxlength(e, index);
                                                    handleInputChange(e, index);
                                                }}
                                                onKeyPress={(e) => {
                                                    if (e.key === 'Enter') {
                                                        e.preventDefault()
                                                        handleAddClick()
                                                    }
                                                }}
                                            />
                                        </td>
                                        <td className={styles.table_th_details}>
                                            <input
                                                type="number"
                                                value={parseFloat(x.qty ? x.qty : 0) * parseFloat(x.price ? x.price : 0)}
                                                disabled
                                                readOnly
                                            />
                                        </td>
                                        <td className={styles.crossIcon} onClick={() => handleRemoveClick(index)}><CrossCircleIcon /></td>
                                    </tr>
                                )}
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            </tbody>
                        </table>

                        <div className='text_center'>
                            <WhiteOutButton btnType="button" handleSubmit={() => handleAddClick()} title="Add More Item" />
                        </div>

                        <div className={styles.d_flex}>
                            <div></div>

                            <div className={styles.subTotal_flex_col}>
                                <div className={styles.subTotal_div}>
                                    <span>Sub Total</span>
                                    <span>{subtotal.toLocaleString("en-IN", { style: "currency", currency: "INR" })}</span>
                                </div>
                                <div className={styles.subTotal_div}>
                                    <span>Shipping Charges</span>
                                    <input
                                        type="number"
                                        className={styles.shipingCharges_input}
                                        value={generalInfo.shippingCharge}
                                        onChange={(e) => setGeneralInfo(prevState => ({
                                            ...prevState,
                                            shippingCharge: e.target.value
                                        }))}
                                        maxLength="10"
                                        onInput={(e) => e.target.value = e.target.value.slice(0, 10)}
                                    />
                                    <span className={styles.fixSize_input}>{generalInfo.shippingCharge ? generalInfo.shippingCharge : 0}</span>
                                </div>
                                <div className={styles.subTotal_div}>
                                    <input
                                        type="text"
                                        className={styles.adjustment}
                                        value={generalInfo.manageName}
                                        placeholder="Enter Manage"
                                        onChange={(e) => setGeneralInfo(prevState => ({
                                            ...prevState,
                                            manageName: e.target.value
                                        }))}
                                    />
                                    <input
                                        type="number"
                                        className={styles.shipingCharges_input}
                                        value={generalInfo.manageValue}
                                        onChange={(e) => setGeneralInfo(prevState => ({
                                            ...prevState,
                                            manageValue: e.target.value
                                        }))}
                                        maxLength="10"
                                        onInput={(e) => e.target.value = e.target.value.slice(0, 10)}
                                    />
                                    <span className={styles.subTotal_txtRed}>
                                        {generalInfo.manageValue ? generalInfo.manageValue : 0}
                                    </span>
                                </div>
                                <div className={styles.totalINR_div}>
                                    <span>Total (INR)</span>
                                    <span>{totalAmount.toLocaleString("en-IN", { style: "currency", currency: "INR" })}</span>
                                </div>
                            </div>

                        </div>

                        <div className={styles.orders_btn_div}>
                            <RedOutButton btnType="button" title="CANCEL" handleSubmit={() => window.history.back()} />
                            {generalInfo.status !== "success" && <GreenOutButton btnType="button" title="SAVE AS DRAFT" handleSubmit={() => handlePuchaseOrder("draft")} />}
                            {generalInfo.status === "success" ?
                                <GreenButton btnType="button" title="VIEW INOVICE" handleSubmit={() => navigate("/orders/purchaseinvoice/" + orderId)} />
                                :
                                <GreenButton btnType="submit" title={loading ? <Spinner size="15" /> : "SAVE & SEND"} />
                            }
                        </div>
                    </form>

                </div>
            </div>
        </React.Fragment>
    )
}

export default Purchase

