import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../components/context/Auth';
import { DownArrowCircle, Search, ThreeDots, UpArrowCircle } from '../../components/icons/Icon';
import CreateLeadModal from './CreateLeadModal';
import styles from './MarketingDashboard.module.css';

const MarketingDashboard = () => {
    const navigate = useNavigate();
    const { switchDisplay, managerId } = useAuth();
    const [steps, setSteps] = useState(0);
    const [value, setValue] = useState(false)
    const [leadId, setLeadId] = useState("")
    const [action, setAction] = useState({
        show: false,
        index: 0
    });

    // const date = new Date();

    const [showModal1, setShowModal1] = useState(false);

    const selectModal1 = () => {
        setShowModal1(!showModal1) // true/false toggle
    }
    const [data, setData] = useState("")

    useEffect(() => {
        var myHeaders = new Headers();
        myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "managerId": managerId,
            "flage": steps === 1 ? "w" : steps === 2 ? "m" : "",
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

       fetch(process.env.REACT_APP_URL + "marketing/dashboard", requestOptions)
            // fetch("http://localhost:5052/marketing/dashboard", requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status === 'success') {
                    setData(result);

                }
            })
            .catch(error => console.log('error', error));
    }, [managerId, steps])

// console.log(new ISODate().toISOString())
    return (
        <React.Fragment>
            <CreateLeadModal
                displayModal={showModal1}
                closeModal={selectModal1}
                leadId={leadId}
                setLeadId={setLeadId}
            />

            <div className={`${switchDisplay ? styles.bg_white : styles.bg_dark} ${styles.row} ${styles.w_100}`}>

                <div className={`${styles.col} ${styles.p_10}`}>
                    <div className={`${styles.row} ${styles.mx_10} ${styles.align_items_center}`}>
                        <div className={`${styles.col_9} ${styles.fs_30} ${styles.pf}`}>Dashboard</div>
                        <div className={styles.col_3}>
                            <div className={`${styles.bg_white} ${styles.border_05} ${styles.text_center} ${styles.w_96} ${styles.p_5} ${styles.br_30}`}>
                                <button className={`${steps === 0 ? styles.light_gray_clr : styles.bg_white} ${styles.btnHW}`} onClick={() => setSteps(0)}>Today</button>
                                <button className={`${steps === 1 ? styles.light_gray_clr : styles.bg_white} ${styles.btnHW}`} onClick={() => setSteps(1)}>Weekly</button>
                                <button className={`${steps === 2 ? styles.light_gray_clr : styles.bg_white} ${styles.btnHW}`} onClick={() => setSteps(2)}>Monthly</button>
                            </div>
                        </div>
                    </div>
                    <div className={styles.row}>
                        <div className={styles.col}>
                            <div className={`${switchDisplay ? styles.bg_white_gray_Light : styles.bg_gray_dark} ${styles.text_center} ${styles.m_10} ${styles.pb_15} ${styles.border_05} ${styles.br_5} ${styles.cpm}`}>
                                <div className={`${styles.text_warning} ${styles.fs_50}`}>
                                    {data.contactCreated}
                                </div>
                                <div className={styles.fs_18}>Contacts Created</div>
                            </div>
                        </div>
                        <div className={styles.col}>
                            <div className={`${switchDisplay ? styles.bg_white_gray_Light : styles.bg_gray_dark} ${styles.text_center} ${styles.m_10} ${styles.pb_15} ${styles.border_05} ${styles.br_5} ${styles.cpm}`}>
                                <div className={`${styles.orangeclr} ${styles.fs_50}`}>
                                    {data.leadsOpen}
                                </div>
                                <div className={styles.fs_18}>Leads Open</div>
                            </div>
                        </div>
                        <div className={styles.col}>
                            <div className={`${switchDisplay ? styles.bg_white_gray_Light : styles.bg_gray_dark} ${styles.text_center} ${styles.m_10} ${styles.pb_15} ${styles.border_05} ${styles.br_5} ${styles.cpm}`}>
                                <div className={`${styles.text_info} ${styles.fs_50}`}>
                                    {data.callComp}
                                </div>
                                <div className={styles.fs_18}>Calls Completed </div>
                            </div>
                        </div>
                        <div className={styles.col}>
                            <div className={`${switchDisplay ? styles.bg_white_gray_Light : styles.bg_gray_dark} ${styles.text_center} ${styles.m_10} ${styles.pb_15} ${styles.border_05} ${styles.br_5} ${styles.cpm}`}>
                                <div className={`${styles.text_danger} ${styles.fs_50}`}>
                                    {data.dealsLost}
                                </div>
                                <div className={styles.fs_18}>Deals Lost </div>
                            </div>
                        </div>
                        <div className={styles.col}>
                            <div className={`${switchDisplay ? styles.bg_white_gray_Light : styles.bg_gray_dark} ${styles.text_center} ${styles.m_10} ${styles.pb_15} ${styles.border_05} ${styles.br_5} ${styles.cpm}`}>
                                <div className={`${styles.text_success} ${styles.fs_50}`}>
                                    {data.dealsWon}
                                </div>
                                <div className={styles.fs_18}>Leads </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.m_10}>
                        <div className={`${styles.row} ${styles.justify_content_between}`}>
                            <div className={styles.dealer_search_div}>
                                <input type="search" placeholder='Search' className={`${switchDisplay ? styles.bg_white_gray_Light : styles.bg_gray_dark}  ${styles.dealer_search}`} />
                                <Search color="#ffffff" css={styles.dealer_search_icon} />
                            </div>

                            <div>
                                <span className={`${styles.ms_20} ${styles.fs_20} ${styles.float_end}`}>
                                    Sort By
                                    <select className={`${switchDisplay ? styles.bg_white : styles.bg_dark} ${styles.dropdown} ${styles.border_05} ${styles.ms_5}`}>
                                        <option>Newest First</option>
                                        <option>Oldest First</option>
                                        <option>Ascending</option>
                                        <option>Decending</option>
                                        <option>Payment Pending</option>
                                        <option>Approved</option>
                                        <option>Dispatched</option>
                                        <option>Packing In Progress</option>
                                        <option>Check Stock &amp; Approve</option>
                                        <option>Waiting For Dealer Approval</option>
                                        <option>COD (Payment Pending)</option>
                                        <option>Completed</option>
                                    </select>
                                </span>
                            </div>
                        </div>

                        {data.data && data.data.map((item, index) =>
                            <div onMouseLeave={() => setAction({ show: false, index: 0 })} key={index} className={`${switchDisplay ? styles.bg_white_gray_Light : styles.bg_gray_dark} ${styles.tableCss} ${styles.position_relative} ${styles.mt_10}`} >
                                <div className={`${styles.row} ${styles.align_items_center} ${styles.market_fs_16} ${styles.mouse}`} onClick={() => { navigate("/marketing-lead/" + item.leadId) }}>
                                    <div className={styles.col_1}>
                                        {item.createdAt && new Date(item.createdAt).toDateString().slice(4, 15)}
                                    </div>
                                    <div className={`${styles.col_1} ${styles.text_success}`}>{item.name}</div>
                                    <div className={styles.col_1}>{item.companyName}</div>
                                    <div className={styles.col_1}>{item.address}</div>
                                    <div className={`${styles.col_1} ${styles.text_underline}`}>{item.mobile}</div>
                                    <div className={`${styles.col_2} ${styles.text_info}`}>{item.email}</div>
                                    <div className={styles.col_1}>{item.source}</div>
                                    <div className={styles.col_1}>{item.payment}</div>
                                    <div className={styles.col_1}><div className={item.priority === 'High' ? styles.priority_1 : item.priority === 'Low' ? styles.priority_2 : styles.priority_3}>{item.priority}</div></div>
                                    <div className={`${styles.col_1} ${styles.text_center}`}>{item.contact}</div>
                                </div>
                                <div className={styles.marketingOrder_lead_div} >
                                    <span className={styles.marketingOrder_lead}>
                                        <span className={styles.mouse}>
                                            {value === index ?
                                                <span onClick={() => setValue(!index)} >
                                                    <UpArrowCircle color="#ffffff" size={20} />
                                                </span>
                                                :
                                                <span onClick={() => setValue(index)}>
                                                    <DownArrowCircle color="#ffffff" size={20} />
                                                </span>
                                            }
                                        </span>
                                    </span>
                                    <span className={`${styles.mouse} ${styles.fs_26} ${styles.mt_5}`} onMouseOver={() => setAction({ show: true, index: index })}>
                                        <ThreeDots color="#FFFFFF" />
                                    </span>
                                </div>
                                {(action.show && action.index === index) &&
                                    <div className={styles.threeDots_div_position} onMouseLeave={() => setAction({ show: false, index: 0 })} >
                                        <div className={`${switchDisplay ? styles.bg_white_gray_Light : styles.bg_dark} ${styles.border_05} ${styles.mouse} ${styles.p_5}`}>
                                            <div className={styles.mb_5} onClick={() => { selectModal1(); setLeadId(item.leadId) }}>
                                                Edit Contact
                                            </div>
                                            <div className={`${switchDisplay ? styles.bg_white_gray_Light + " " + styles.borderTopDark : styles.bg_dark + " " + styles.borderTop} ${styles.text_danger} ${styles.mouse}`}>
                                                <div className={styles.mt_5}>
                                                    Mark As Lead Lost
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {value === index ?
                                    <div className={`${styles.ms_2_6p} ${styles.text_start} ${styles.mb_5}`}>
                                        <div className="cpm">
                                            Last Note:
                                        </div>
                                        Will be available after 3 days, Person is out of station. Interested in Wallpapers, Panels &amp; Flooring. Call after 2 pm..
                                    </div>
                                    :
                                    ""
                                }

                            </div>
                        )}
                    </div >
                </div >
            </div >
        </React.Fragment >
    )
}

export default MarketingDashboard
