import React from "react";
import styles from "../globalwhatsappchat.module.css";
import { useAuth } from "../../../context/Auth";
const ChatHeader = ({ children }) => {
  const { mode } = useAuth();

  const headerStyle = {
    backgroundColor: mode ? "#2C2E33" : "#F0F2F5",
    color: mode ? "#fff" : "#646B88",
  };
  return (
    <div
      className={`${styles.whatsapp_chats_header} ${
        mode ? styles.darkMode : styles.lightMode
      }`}
      style={headerStyle}
    >
      {children}
    </div>
  );
};

export default ChatHeader;
