

export const truncateText = (text, charLimit) => {
    if (!text) return '';
    return text.length > charLimit ? text.slice(0, charLimit) + '...' : text;
};
export const changeDateFormat = (dateString , splitForment) => {
    const date = new Date(dateString); // Parse the ISO string into a Date object
    const day = String(date.getDate()).padStart(2, '0'); // Ensure day is two digits
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Ensure month is two digits
    const year = date.getFullYear(); // Get the full year

    return `${day}${splitForment || "/"}${month}${splitForment || "/"}${year}`;
};
export const changeTimeFormat = (dateString = new Date(), splitForment = ":") => {
    const date = new Date(dateString); // Parse the date string or use the current date
    if (isNaN(date)) return "Invalid Date"; // Check if date is valid

    const hours = String(date.getHours()).padStart(2, '0');   // Ensure hours are two digits
    const minutes = String(date.getMinutes()).padStart(2, '0'); // Ensure minutes are two digits    

    return `${hours}${splitForment}${minutes}`;
};


export const transformButtons = (buttons) => {
    return buttons.map(button => {
        switch (button.value) {
            case "URL":
                return {
                    type: "URL",
                    text: button.btnText,
                    url: button.text // Assuming 'text' contains the URL
                };
            case "QUICK_REPLY":
                return {
                    type: "QUICK_REPLY",
                    text: button.btnText
                };
            case "PHONE_NUMBER":
                return {
                    type: "PHONE_NUMBER",
                    text: button.btnText,
                    phone_number: button.text // Assuming 'text' contains the phone number
                };
            default:
                return null; // or handle unexpected types
        }
    }).filter(Boolean); // Remove any null entries
};



export function debounce(func, wait) {
    let timeout;
    return function (...args) {
        const later = () => {
            clearTimeout(timeout);
            func(...args);
        };
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
    };
}


//   export function convertHtmlToMarkdown(html) {
//     // Replace <b> and </b> with * for bold
//     let markdown = html.replace(/<b>(.*?)<\/b>/g, '*$1*');

//     // Replace <i> and </i> with _ for italic
//     markdown = markdown.replace(/<i.*?>(.*?)<\/i>/g, '_$1_');

//     // Replace &nbsp; with space
//     markdown = markdown.replace(/&nbsp;/g, ' ');

//     // Replace <br> or <br /> with new line (\n)
//     markdown = markdown.replace(/<br\s*\/?>/g, '\n');

//     // Replace <div> with new line (\n)
//     markdown = markdown.replace(/<div>/g, '').replace(/<\/div>/g, '\n');

//     return markdown;
// }


export function convertHtmlToMarkdown(html) {
    // Replace <b> and </b> with * for bold
    let markdown = html.replace(/<b>(.*?)<\/b>/g, '*$1*');

    // Replace <i> and </i> with _ for italic and trim spaces
    markdown = markdown.replace(/<i.*?>(.*?)<\/i>/g, '_$1_');

    // Replace <span> (with style) and get only the text content
    markdown = markdown.replace(/<span[^>]*>(.*?)<\/span>/g, '$1');

    // Replace &nbsp; with space
    markdown = markdown.replace(/&nbsp;/g, ' ');

    // Replace <br> or <br /> with new line (\n)
    markdown = markdown.replace(/<br\s*\/?>/g, '\n');

    // Replace <div> with new line (\n) and trim spaces
    markdown = markdown.replace(/<div>/g, '\n').replace(/<\/div>/g, '');

    // Trim spaces around italic text and other characters
    markdown = markdown.replace(/_+\s+/g, '_'); // Remove spaces after _
    markdown = markdown.replace(/\s+_+/g, '_'); // Remove spaces before _

    // Handle any other spaces around the content
    markdown = markdown.replace(/\s+/g, ' ').trim(); // Remove extra spaces

    return markdown;
}



export function convertMarkdownToHtml(markdown) {
    // Replace * for bold with <b> and </b>
    let html = markdown.replace(/\*(.*?)\*/g, '<b>$1</b>');

    // Replace _ for italic with <i> and </i>
    html = html.replace(/_(.*?)_/g, '<i>$1</i>');

    // Replace new line (\n) with <br />
    html = html.replace(/\n/g, '<br />');


    return html;
}


// Example usage



export const TextColorCode = (status) => {
    switch (status) {
        case "All":
            return "#F93E3E";
        case "New":
            return "#4164E3";
        case "Rejected":
            return "#D74A3A";
        case "Deleted":
            return "#D74A3A";
        case "Verified":
            return "#34A853";
        case "Pending":
            return "#FF9900";
        case "Update":
            return "#9747FF";
        case "Blacklisted":
            return "#D74A3A";
        case "Disabled":
            return "#D74A3A";
        case "Active":
            return "#34A853";
        case "running":
            return "#34A853";
        case "draft":
            return "#4164E3";
        case "stopped":
            return "#F93E3E";
        default:
            return "#c2c2c2";
    }
};
