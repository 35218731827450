import React, { useState } from 'react'
import styles from "./pagnation.module.css"
import { useAuth } from '../../context/Auth';
const GoToPage = ({ totalPages, setCurrentPage }) => {

    const { mode } = useAuth()

    const [pageno, setPagenno] = useState(1);
    const [err, setError] = useState("")

    const handleGoTo = () => {
        // setCurrentPage(pageno)
        if (pageno > 0 && pageno <= totalPages) {
            setCurrentPage(Number(pageno)); // Convert input to number to avoid issues
          } else {
            setError("Invalid page number"); // Handle invalid cases gracefully
          }

    }
    const handelChack = (e) => {
        let number = e.target.value;
        if (number > totalPages) {
            setError("Invalid page number ")
            setPagenno(1)
        } else {
            setError("");
            setPagenno(number)
        }

    }


    return (
        <div className={styles.Go_to_page_continer} >
            <span className={mode ? styles.pageText_dark : styles.pageText}>Page</span>
            <div className={styles.gotoInput} >
                <input type="number" onChange={handelChack} className={mode ? styles.gotoInputinput_dark : styles.gotoInputinput} />
            </div>
            <span className={mode ? styles.pageText_dark : styles.pageText} >Of {totalPages} </span>
            <button disabled={err ? true : false} onClick={handleGoTo} style={err ? { backgroundColor: "#395691",
             cursor:"no-drop",
             color:"#808692"
            } 
            : {}} >Go</button>
        </div>
    )
}

export default GoToPage