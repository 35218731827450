// import React, { useEffect, useState } from "react";
// import { useNavigate, useParams, useSearchParams } from "react-router-dom";
// import { useAuth } from "../../components/context/Auth";
// import { LargeEyeIcon } from "../../components/icons/Icon";
// import styles from "../inventorymanager/Inventories.module.css";

// const InventoryCollections = () => {
//   const navigate = useNavigate();
//   const { managerId } = useAuth();
//   const params = useParams();
//   const [searchparams] = useSearchParams();
//   const product_id = searchparams.get("product_id");
//   const product_name = params.product;

//   const [loading, setLoading] = useState(false);
//   const [data, setData] = useState([]);

//   useEffect(() => {

//     if (managerId) {
//       setLoading(true);
//       var myHeaders = new Headers();
//       myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
//       myHeaders.append("Content-Type", "application/json");

//       var raw = JSON.stringify({
//         managerId: managerId,
//         product_id: product_id,
//         searchKeyword: "",
//       });

//       var requestOptions = {
//         method: "POST",
//         headers: myHeaders,
//         body: raw,
//         redirect: "follow",
//       };

//       fetch(process.env.REACT_APP_URL + "inventory/collections", requestOptions)
//         .then((response) => response.json())
//         .then((result) => {
//           if (result.status === "success") {
//             setLoading(false);
//             setData(result.data);
//           }
//         })
//         .catch((error) => console.log("error", error));
//     }
//   }, [managerId, product_id]);

//   return (
//     <React.Fragment>
//       <div className={styles.main_Container}>
//         <div>
//           <span onClick={() => navigate(-1)}>
//             &lt;&nbsp;&nbsp;<span className={styles.goBack}>Go Back</span>
//           </span>
//           <h1>{product_name.toUpperCase()}</h1>
//         </div>
//         <div className={styles.inventory_scroll_div}>
//           {loading ? (
//             <div className={"loadingMain"}>
//               <img src="/wallicon.gif" alt="walliconGIF" />
//             </div>
//           ) : (
//             data &&
//             data.map((item, index) => (
//               <div
//                 key={index}
//                 className={styles.InventoryCard}
//                 onClick={() =>
//                   navigate(
//                     "/inventory/" +
//                       product_name.replace(/\s/g, "-").toLowerCase() +
//                       "/" +
//                       item.collection_name.replace(/\s/g, "-").toLowerCase() +
//                       "?product_id=" +
//                       product_id +
//                       "&collection_id=" +
//                       item._id
//                   )
//                 }
//               >
//                 <div className={styles.productName_div}>
//                   <div className={styles.activeRadioBtn}>
//                     <input
//                       type="radio"
//                       defaultChecked={item.status === "Active" ? true : false}
//                     />
//                     <span className={styles.status_active}>Active</span>
//                   </div>
//                   <h2 className={styles.productName}>{item.collection_name}</h2>
//                 </div>
//                 <div className={styles.collections_details_div}>
//                   <div className={styles.view_div}>
//                     <span className={styles.qty}>{item.itemCount}</span>
//                     <span className={styles.qty_txt}>Items</span>
//                   </div>
//                   <div className={styles.view_div}>
//                     <span className={styles.qty}>{item.qty}</span>
//                     <span className={styles.qty_txt}>Qty</span>
//                   </div>
//                 </div>
//                 <div className={styles.view_div}>
//                   <span>
//                     <LargeEyeIcon color="#FFFFFF" />
//                   </span>
//                   <span className={styles.qty_txt}>View</span>
//                 </div>
//               </div>
//             ))
//           )}
//         </div>
//       </div>
//     </React.Fragment>
//   );
// };

// export default InventoryCollections;

import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useAuth } from "../../components/context/Auth";
import {
  ArrowRightIcon,
  EyeBtn,
  TestTopBackArrowIcon,
  TestTopDotIcon,
} from "../../components/icons/Icon";
import styles from "./Inventory.module.css";
import fetchData from "../../APIsControll/apiControll";
import SearchBar from "../../components/updatedComponents/FormComponents/SearchBar";
import Spinner from "../../components/updatedComponents/FormComponents/Spinner";

const InventoryCollections = () => {
  const navigate = useNavigate();
  const { managerId, mode } = useAuth();
  const params = useParams();
  const [searchparams] = useSearchParams();
  const product_id = searchparams.get("product_id");
  const product_name = params.product;

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [itemSearch, setItemSearch] = useState("");

  const getProductData = useCallback(async () => {
    const apiData = {
      managerId: managerId,
      product_id: product_id,
      searchKeyword: itemSearch,
    };
    try {
      setLoading(true);
      const res = await fetchData("inventory/collections", apiData);
      if (res.status === "success") {
        setLoading(false);
        setData(res.data);
      }
    } catch (error) {
      setLoading(false);
      console.log("Error while getting product data ", error);
    }
  }, [managerId, product_id, itemSearch]);

  useEffect(() => {
    if (!managerId) return;

    const delayDebounceFn = setTimeout(() => {
      getProductData();
    }, 700);

    return () => clearTimeout(delayDebounceFn);
  }, [getProductData, itemSearch, managerId, product_id]);

  return (
    <React.Fragment>
      <div
        className={styles.inventory}
        style={mode ? { backgroundColor: "#2C2E33", border: "none" } : {}}
      >
        <div className={styles.inventory_top}>
          <div className={styles.inventory_top_left}>
            <div
              className={styles.inventory_top_left_go_back}
              onClick={() => navigate(-1)}
            >
              <TestTopBackArrowIcon color={mode ? "#ffffff" : "#0100E4"} />
              <p
                style={{
                  fontSize: "16px",
                  fontWeight: "500",
                  lineHeight: "24px",
                  margin: 0,
                  paddingTop: "0.21rem",
                  color: mode ? "#FFFFFF" : "#0100E4",
                }}
              >
                Go Back
              </p>
            </div>
            <TestTopDotIcon color={mode ? "#E4E7EB" : "#646B88"} />
            <p
              className={styles.inventory_top_left_path_text}
              style={{
                color: mode ? "#9C9797" : "#646B88",
                display: "flex",
                alignItems: "center",
              }}
            >
              Product/{product_name}
              <ArrowRightIcon color={mode ? "#9C9797" : "#646B88"} />
            </p>
          </div>
        </div>
        <div className={styles.inventory_search_bar}>
          <SearchBar
            placeholder={"Search by Collection"}
            value={itemSearch}
            onChange={(e) => setItemSearch(e.target.value)}
          />
        </div>
        <ul className={styles.responsiveTable}>
          <li
            className={styles.tableHeader}
            style={
              mode
                ? {
                    backgroundColor: "#232529",
                    color: "#fafafa",
                    fontWeight: "500",
                  }
                : {
                    backgroundColor: "#D9DCE5",
                    color: "#646B88",
                    fontWeight: "500",
                  }
            }
          >
            <div className="col">Status</div>
            <div className="col">Collections</div>
            <div className="col">Items</div>
            <div className="col">Quantity</div>
            <div className="col" style={{ visibility: "hidden" }}>
              Icon
            </div>
          </li>

          <div className={styles.inventory_data_container}>
            {loading ? (
              <div className={styles.inventory_loader}>
                <Spinner />
              </div>
            ) : (
              data &&
              data.map((item, index) => (
                <li
                  key={index}
                  className={styles.tableHeader}
                  style={
                    mode
                      ? {
                          backgroundColor: "#1B1D21",
                          color: "#fafafa",
                          cursor: "pointer",
                        }
                      : { backgroundColor: "#FFFFFF", cursor: "pointer" }
                  }
                  onClick={() =>
                    navigate(
                      "/inventory/" +
                        product_name.replace(/\s/g, "-").toLowerCase() +
                        "/" +
                        item.collection_name.replace(/\s/g, "-").toLowerCase() +
                        "?product_id=" +
                        product_id +
                        "&collection_id=" +
                        item._id
                    )
                  }
                >
                  <div
                    className="col"
                    style={{
                      color: item.status === "Active" ? "#34A853" : "#F93E3E",
                    }}
                  >
                    {item.status === "Active" ? "Active" : "Inactive"}
                  </div>
                  <div className="col">{item.collection_name}</div>
                  <div className="col">{item.itemCount}</div>
                  <div className="col">{item.qty.toFixed(2)}</div>
                  <div className="col">
                    <EyeBtn color={mode ? "#ffffff" : "#000000"} />
                  </div>
                </li>
              ))
            )}
          </div>
        </ul>
      </div>
    </React.Fragment>
  );
};

export default InventoryCollections;
