import React from 'react'
import { useAuth } from '../../context/Auth'
import { Link } from 'react-router-dom'

const NoDataFound = ({ icon, textTitle, linkname, to }) => {
    const { mode } = useAuth()
    return (
        <div style={{
            background: mode ? "#232529" : "#fff",
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column"
        }} >
            <div> {icon} </div>
            <div style={{ display: "flex", flexDirection: "column", marginTop:"10px", justifyContent:"center", alignItems:"center"}}>
                <p style={{color:mode?"#fff":"#000"}}>{textTitle}</p>
                <Link to={`${to}`}> {linkname} </Link>
            </div>


        </div>
    )
}

export default NoDataFound