import React, { lazy, Suspense, useEffect, useRef, useState } from 'react'
import { useNavigate, } from 'react-router-dom'
import { useAuth } from '../../components/context/Auth'
import { CrossRedIcon, DoubleCheckIcon, Spinner,  TestTopBackArrowIcon1, TestTopDotIcon, } from '../../components/icons/Icon'
import styles from "./Dealer.module.css"
import ModalWrapper from '../../components/updatedComponents/layoutWrapper/ModalWrapper'
import { ArroIcon, WhatAppIcon, } from '../../components/icons/Icon2'
import SlidingFilter from '../../components/updatedComponents/Dealers/SlidingFilter'
import DealerStatusCard from '../../components/updatedComponents/Dealers/DealerStatusCard'
import SearchBar from '../../components/updatedComponents/FormComponents/SearchBar'
import DropDown from "../../components/updatedComponents/FormComponents/DropDown"
import AdduserModel from '../../components/updatedComponents/models/AdduserModel'
import FilterCard from '../../components/updatedComponents/MobilCard/FilterCard'
import DealerCartMobil from '../../components/updatedComponents/MobilCard/DealerCartMobil'
import Pagenation from "../../components/updatedComponents/Pagnation/Pagenation"
import GoToPage from '../../components/updatedComponents/Pagnation/GoToPage'
import Skeleton from '../../components/updatedComponents/Skeleton/Skeleton'
import fetchData from '../../APIsControll/apiControll'
import { useToast } from '../../components/updatedComponents/Toaster/Toaster'
import InputTag from '../../components/updatedComponents/FormComponents/InputTag'
import Button from '../../components/updatedComponents/Dealers/Button'
const DealerInfoCard = lazy(() => import("../../components/updatedComponents/Dealers/DealerInfoCard"));
const TempletSent = lazy(() => import("../../components/updatedComponents/Dealers/TempletSent"));
const Dealers = () => {
  const { mode } = useAuth()
  const { show } = useToast()
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [isloading, setIsLoading] = useState({
    Isloading1: false
  });
  const [chackState, setChackState] = useState({
    chackStates: false
  });

  const [selectTemp, setSelectTemp] = useState("Select Template");
  const [showSelectTemp, setShowSelectTemp] = useState(false);

  const [dealerData, setDealerData] = useState([])
  const [dealerNumber, setDealerNumber] = useState([])
  const { managerId } = useAuth()
  const [loading, setLoading] = useState(false)
  const [width, setWidth] = useState(window.innerWidth);
  const [showMessengerModal, setShowMessengerModal] = useState(false)
  const [sendTemplateModal, setSendTemplateModal] = useState(false)
  const [sendTemplateText, setSendTemplateText] = useState('')
  const [allTemplate, setAllTemplate] = useState([]);
  const [singleTemplate, setSingleTemplate] = useState({});
  const [messengerTemplate, setMessengerTemplate] = useState();
  const [dealerNames, setDealerNames] = useState([]);
  const [showDealerNames, setShowDealerNames] = useState(false);
  const [selectedNumber, setSelectedNumber] = useState([]);
  const [templateError, setTemplateError] = useState();
  const [templateDataLoading, setTemplateDataLoading] = useState(false);
  const [sendStatus, setSendStatus] = useState({});
  const [templatePage, setTemplatePage] = useState(1);
  const [totalPage, setTotalPage] = useState(3);
  const [allfilter, setAllFilter] = useState([]);
  const [page1, setPage1] = useState(1);
  const [pages1, setPages1] = useState(1);


  const sliceData = (data, page) => {
    const pageSize = dealerNumber.length;
    // const pageSize = 2; 
    // const pageSize = 1000; 
    if (page === 1) {
      return data.slice(0, pageSize);
    } else if (page === 2) {
      return data.slice(pageSize, pageSize * 2);
    } else if (page === 3) {
      return data.slice(pageSize * 2, pageSize * 3);
    } else if (page === 4) {
      return data.slice(pageSize * 3);
    } else {
      return [];
    }
  };
  const slicedData = sliceData(dealerNumber, templatePage);
  console.log("slicedData=>", dealerNumber);

  useEffect(() => {
    window.addEventListener('resize', () => {
      return setWidth(window.innerWidth);
    })
    window.removeEventListener('resize', () => {
      return setWidth(window.innerWidth);
    })
  }, [])

  const [searchDealerData, setSearchDealerData] = useState({
    searchDealerName: '',
    sortByStatus: '',
    sortKey: '',
    dateFilter: '',
    district: '',
    status: '',
  });
  const [pages, setPages] = useState(1);


  useEffect(() => {
    if (managerId) {
      const getDealers = async () => {
        setLoading(true);
        const res = await fetchData(`kyc/dealers?page=${page}&limit=${28}`, {
          managerId,
          keyword: searchDealerData.searchDealerName,
          sortKey: searchDealerData.sortByStatus,
          dateFilter: searchDealerData.dateFilter,
          status: searchDealerData.status
        });
        if (res.status === "success") {
          if (res?.data) {
            setLoading(false);
            setAllFilter(res?.statusCounts);
            setDealerData((prevDealerData) => {
              let newData = res?.data;
              if (searchDealerData.dateFilter || searchDealerData.searchDealerName || searchDealerData.sortByStatus || searchDealerData.status) {
                return newData;
              }
              const mergedData = [...prevDealerData, ...newData];
              const uniqueData = mergedData.filter(
                (item, index, self) => index === self.findIndex((t) => t.dealerId === item.dealerId)
              );
              setLoading(false);
              return uniqueData;
            });
            setPages(res?.pages || 1);
            setLoading(false);
          }
          return
        }
        if (res.status === "failed") {
          show(`${res.msg}`, "error");
          setLoading(false);
          return
        } else {
          show(`Error`, "error");
          setLoading(false);
        }
      };
      getDealers();
    }
  }, [managerId, page, searchDealerData]);


  const dealerNumberData = async () => {
    const res = await fetchData(`kyc/dealerWhatsapp`, { "managerId": managerId });
    if (res.status === "success") {
      setDealerNumber(res.dealerWhatsapp);

      // setDealerNumber([{
      //   "dealerId": 9161048556,
      //   "name": "Mohit",
      //   "whatsapp": 9161048556
      // },
      // {
      //   "dealerId": 9984920907,
      //   "name": "Akhnad Sir",
      //   "whatsapp": 9984920907
      // },
      // {
      //   "dealerId": 6392457271,
      //   "name": "Anshu Bhai",
      //   "whatsapp": 6392457271
      // },
      // {
      //   "dealerId": 7007236955,
      //   "name": "Rakesh Sir",
      //   "whatsapp": 7007236955
      // },
      // {
      //   "dealerId": 9151637187,
      //   "name": "Asmit Bhai",
      //   "whatsapp": 9151637187
      // },
      // {
      //   "dealerId": 9451121702,
      //   "name": "Manish Sir",
      //   "whatsapp": 9451121702
      // },
      // ]);
      return
    }
    if (res.status === "failed") {
      show(`${res.msg}`, "error");
      return
    } else {
      show(`Error`, "error");
    }
  }

  useEffect(() => {
    if (managerId) {
      dealerNumberData()
    }
  }, [managerId])

  useEffect(() => {
    if (dealerData.dealerId) {
      navigate("/dealers/" + dealerData.dealerId)
    }
  }, [dealerData.dealerId, navigate])

  const sendMessage = async () => {
    setTemplateDataLoading(true);
    const myHeaders = new Headers();
    myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
    myHeaders.append("Content-Type", "application/json");

    const rawData = {
      managerId: managerId,
      dealerPhoneNumber: selectedNumber,
      templateName: singleTemplate.templateName,
      languageCode: singleTemplate.language,
      headerType: singleTemplate.headerType,
      headerId: singleTemplate.headerId,
    };
    if (singleTemplate.headerType === 'document') {
      rawData.documentFilename = singleTemplate.documentName;
    }
    if (singleTemplate.headerType === 'location') {
      rawData.latitude = "26.8428008";
      rawData.longitude = "80.9359821";
      rawData.locationName = "Wallicon Private Limited";
      rawData.locationAddress = "Wallicon, Burlington, Aminabad Road, Lucknow";
      // rawData.buttonIndex = "1";
      // rawData.buttonDataPayload = "https://dealer.wallicon.in/payment-order/Wal54LhjZqiN14";
    }

    // Convert the object to a JSON string
    const raw = JSON.stringify(rawData);
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    const controller = new AbortController();
    const signal = controller.signal;
    // 5 minutes timeout (300000 milliseconds)
    const timeoutId = setTimeout(() => controller.abort(), 300000);

    fetch(process.env.REACT_APP_URL + "wallikonChat/sendTemplate?page=" + templatePage, {
      ...requestOptions,
      signal: signal
    })
      .then((response) => response.json())
      .then(result => {
        clearTimeout(timeoutId);
        if (result.status === 'failed') {
          alert(result.msg);
          setTemplateError(result.msg);
        }
        setTemplateDataLoading(false);
        processResult(result?.results);
        setSendStatus(result.response);
        // console.log("processResult");
        if (templatePage < totalPage) {
          setTemplatePage(prevPage => prevPage + 1);
        }

        setTotalPage(result.response.totalPages);
      })
      .catch((error) => {
        clearTimeout(timeoutId); // Clear the timeout on error
        if (error.name === 'AbortError') {
          console.error("Request timeout, it took too long");
        } else {
          console.error(error);
        }
      });
  };

  const processResult = (results) => {
    if (!slicedData || !Array.isArray(slicedData)) {
      console.error("slicedData is undefined or not an array");
      return;
    }

    const newStatusArray = slicedData.map(dealer => {
      const foundResult = results.find(result => {
        const input = result?.data?.contacts?.[0]?.input || result?.data?.to;
        return input && (input.toString() === dealer.whatsapp.toString());
      });

      if (foundResult) {
        return {
          whatsapp: dealer.whatsapp,
          name: dealer.name,
          status: foundResult.status
        };
      } else {
        return {
          whatsapp: dealer.whatsapp,
          name: dealer.name,
          status: 'failed'
        };
      }
    });

    setDealerNames(newStatusArray);
    handleAddTemplateRecord(newStatusArray);
  };

  const handleAddTemplateRecord = (statusInfo) => {
    const myHeaders = new Headers();
    myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      "managerId": managerId,
      "templateName": singleTemplate.templateName,
      "statusInfo": statusInfo
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };

    fetch(process.env.REACT_APP_URL + "wallikonChat/addUpTempstatus", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === 'success') {
          // setTemplateData(result.data)
        }
      })
      .catch((error) => console.error(error));
  }



  const [addDealerModelOpen, setAddDealerModelOpen] = useState(false)
  const addDelalerModel = () => {
    setAddDealerModelOpen(!addDealerModelOpen)
  }
  const TempletSentModel = () => {
    setShowMessengerModal(!showMessengerModal)
  }
  const itemList = [" All Dealer", "Today", "Yesterday", "This Month", "Last 30 Days"];
  const routeName = "Dealers";
  const totalCount = Object.values(allfilter).reduce((sum, count) => sum + count, 0);
  const dealerStatusData = [
    { statusText: "All", statusItemCount: totalCount || 0 },
    { statusText: "New", statusItemCount: allfilter["New"] || 0 },
    { statusText: "Verified", statusItemCount: allfilter["Verified"] || 0 },
    { statusText: "Pending", statusItemCount: allfilter["Pending"] || 0 },
    { statusText: "Update", statusItemCount: allfilter["Update"] || 0 },
    { statusText: "Rejected", statusItemCount: allfilter["Rejected"] || 0 },
    { statusText: "Deleted", statusItemCount: allfilter["Deleted"] || 0 },
    { statusText: "Blacklisted", statusItemCount: allfilter["Blacklisted"] || 0 },
  ];

  const updateDateFilter = (selectedDate) => {
    setSearchDealerData((prevData) => ({
      ...prevData,
      dateFilter: selectedDate,
    }));
  };
  const containerRef = useRef(null);

  const handleScroll = () => {
    const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
    // Allow a small tolerance (e.g., 5px) for the scroll comparison
    const bottom = Math.abs(scrollHeight - scrollTop - clientHeight) <= 5;

    if (bottom && !loading) {
      if (page >= pages) {
        return;
      } else {
        setPage((prevPage) => Number(prevPage) + 1);
      }
    }
  };

  useEffect(() => {
    const container = containerRef.current;

    if (container) {
      container.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll);
      }
    };
  }, [loading, page]);



  async function getTemplates() {
    if (managerId) {
      if (page1 <= pages1) {
        setIsLoading({ ...isloading, Isloading1: true });
        const res = await fetchData(`wallikonChat/templateList?page=${page1 <= pages1 ? page1 : pages1}&&limit=10`, {
          "managerId": managerId
        });
        if (res?.status === 'success') {
          // setAllTemplate(res?.data);
          const newTemplates = res?.data || [];

          // Filter out templates that already exist in allTemplate
          const filteredTemplates = newTemplates.filter(newTemplate =>
            !allTemplate.some(existingTemplate =>
              existingTemplate.templateName === newTemplate.templateName
            )
          );

          setAllTemplate([...allTemplate, ...filteredTemplates]);
          // setPage1(res.page);
          setPages1(res?.pages);
          setIsLoading({ ...isloading, Isloading1: false });
          return
        }
        if (res?.status === 'failed') {
          show(`${res?.msg}`, "error");
          setIsLoading({ ...isloading, Isloading1: false });
          return
        } else {
          show(`Error`, "error");
          setIsLoading({ ...isloading, Isloading1: false });
        }
      }
    }

  }
  useEffect(() => {
    getTemplates();
  }, [page1]);

  const handleSelectAllNumber = () => {
    setSelectedNumber(dealerNumber.map(number => number?.whatsapp));
  };
  useEffect(() => {
    setMessengerTemplate(selectTemp);
    const selectedTemplate = allTemplate.find(item => item?.templateName === selectTemp);
    if (selectedTemplate) {
      setSingleTemplate(selectedTemplate);
    } else if (selectTemp === 'custom_value') {
      setSingleTemplate({ templateName: 'custom_value' });
    }
  }, [selectTemp]);


  const handleChack = (e) => {
    if (e === true && selectTemp !== "Select Template") {
      handleSelectAllNumber();
      setChackState({ ...chackState, chackStates: true });
      return
    }
    else {
      setChackState({ ...chackState, chackStates: false })
    }
  }
  const handleSendTemplate = () => {
    setShowDealerNames(true);
    sendMessage();
    setShowMessengerModal(false);
    // setSendTemplateModal(false);
  }
  const handeclose = () => {
    setShowDealerNames(false);
    // setSelectedNumber([]);
    // setMessengerTemplate();
    setSendTemplateText('');
  }

  return (
    <React.Fragment>
      <ModalWrapper
        closeBtn={showMessengerModal}
        text={"Hello"}
        isOpen={showMessengerModal} onClose={TempletSentModel}>
        <Suspense fallback={
          <div className={styles.TempContiner} style={{ backgroundColor: mode ? "#232529" : "#fff" }}>
            <div style={{ width: "100%", display: "flex", flexDirection: "column" }}>
              <div style={{ width: "30%" }}>
                <Skeleton lines={1} thickness={3} />
              </div>
              <div style={{ width: "90%", alignSelf: "center", marginTop: "10px" }}>
                <Skeleton lines={1} thickness={5} />
                <div style={{ display: "flex", justifyContent: "space-between", marginTop: "10px" }}>
                  <div style={{ width: "30%" }} > <Skeleton lines={1} thickness={3} />  </div>
                  <div style={{ width: "30%" }} > <Skeleton lines={1} thickness={3} />  </div>
                </div>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <div style={{ width: "48%" }} > <Skeleton lines={1} thickness={5} />  </div>
                  <div style={{ width: "48%" }} > <Skeleton lines={1} thickness={5} />  </div>
                </div>
              </div>
            </div>
          </div>
        }>
          <TempletSent
            selectTemp={selectTemp}
            setSelectTemp={setSelectTemp}
            setShowSelectTemp={setShowSelectTemp}
            showSelectTemp={showSelectTemp}
            templist={allTemplate}
            page={page1}
            pages={pages1}
            setPage={setPage1}
            loading={isloading?.Isloading1}
            closeBtn={TempletSentModel}
            SendTemplateModal={setSendTemplateModal}
            chackValue={handleChack}
            disabledValue={chackState.chackStates}
            selectedNumber={selectedNumber}
            key={"1"}
          />
        </Suspense>
      </ModalWrapper>

      <ModalWrapper isOpen={sendTemplateModal} onClose={() => setSendTemplateModal(!sendTemplateModal)}>
        <div className={styles.text_center} style={{ backgroundColor: mode ? "#2C2E33" : "#fff", color: mode ? "#fff" : "#000" }}   >
          <div className={styles.textContiner}>
            <span className={styles.TempContinerText}>Send Template </span>
            <p className={`${styles.message}`}>Price of Template sending is <span style={{ color: "#F93E3E" }} >{selectedNumber.length * (singleTemplate.category === 'MARKETING' ? 0.73 : singleTemplate.category === 'UTILITY' ? 0.30 : 0)}</span></p>
            <InputTag
              labelText={"Type Send For Send"}
              name={"temp"}
              maxLength={4}
              required={true}
              star={true}
              placeholderText={"Type Send"}
              placeholderType={"text"}
              onChange={(e) => setSendTemplateText(e.target.value)}
              value={sendTemplateText}
            />
            <div className={styles.massageModal_btn}>
              <Button buttonBgColor={"#000"} buttonTextColor={"#fff"} buttonText={"No"} buttonClick={() => { setSendTemplateModal(false); setSelectedNumber([]); setMessengerTemplate() }} />
              <Button
                buttonText={"Yes"}
                buttonTextColor={"#fff"}
                buttonBgColor={"#4164E3"}
                disabledButtonBgColor={"#2f4697"}
                disabledButtonTextColor={"#646B88"}
                disabled={sendTemplateText.toUpperCase() === 'SEND' ? false : true}
                buttonClick={handleSendTemplate}
              />
            </div>
          </div>
        </div>
      </ModalWrapper>

      <ModalWrapper isOpen={showDealerNames} closeBtn={() => setShowDealerNames(!showDealerNames)} >
        <div className={styles.text_center} style={{ backgroundColor: mode ? "#2C2E33" : "#fff", color: mode ? "#fff" : "#000" }}   >
          <div className={styles.AbortControllerCon}>
            <span className={styles.template_name}>{messengerTemplate + ' Template Sent'}</span>
          </div>
          <div className={styles.dealerNames_div}>
            {dealerNames && dealerNames.map((item, index) => 
            <div key={index} className={styles.dealerNames}><span>{index + 1}&nbsp;</span>
            <span className='me-1'>{item.name}-{item.whatsapp}</span>
            <span>{item.status === 'success' ? <DoubleCheckIcon /> : 
            item.status === 'not send' ? 'not send' : <CrossRedIcon />}</span></div>)}
          </div>
          {templateError ?
            <p className='text-center'>{templateError}</p>
            :
            <>
              {templateDataLoading ? <Spinner css={styles.spinnerWhite} size="24" /> :
               <div className='d-flex justify-content-between'>
                <div style={{ color: "#4164E3" }}>Total - {sendStatus.total && sendStatus.total}</div>
                <div style={{ color: "green" }}>Success - {sendStatus.successful && sendStatus.successful}</div>
                <div style={{ color: "red" }}>Failed - {sendStatus.failed && sendStatus.failed}</div>
              </div>}
            </>
          }
          <div className={styles.dealerNameModal_btn}>
            <Button buttonBgColor={"#000"} buttonText={"CLOSE"} buttonTextColor={"#fff"} buttonClick={handeclose} />
            {/* <GreenOutButton title="CLOSE" handleSubmit={() => { setShowDealerNames(false); setSelectedNumber([]); setMessengerTemplate(); setSendTemplateText('') }} /> */}
          </div>
        </div>
      </ModalWrapper>

      <ModalWrapper isOpen={addDealerModelOpen} onClose={addDelalerModel} text={"Add Dealer"} closeBtn={true} >
        <div  >
          <div style={{ height: "10px" }}></div>
          <AdduserModel close={addDelalerModel} />
        </div>
      </ModalWrapper>

      <div className={styles.right_main}>
        <div className={width > 380 ? (mode ? styles.Dealer_main_container : styles.Dealer_main_container_white) : 'default-class'}>
          <div className={styles.addDealers}>
            {width < 1130 ? null :
              <div className={mode ? styles.dealers : styles.dealers_white} >
                <div className={styles.test_top}>
                  <TestTopBackArrowIcon1 />
                  <p style={{ fontSize: "16px", fontWeight: "500", lineHeight: "24px", color: mode ? "#fff" :"#4164E3", cursor: "pointer" }}
                    onClick={() => navigate(-1)}
                  > Go Back </p>
                  <TestTopDotIcon color={"#646B88"} />
                  <p style={{ fontSize: "18px", fontWeight: "500", lineHeight: "24px", color: mode ? "#646B88" : "#646B88", }}>
                    {routeName}
                  </p>
                </div>
              </div>
            }
            <div className={styles.btnContiner} style={{ display: "flex", flexDirection: "column", justifyContent: "center" }} >
              <div style={{ display: "flex", alignItems: "center", gap: "6px", justifyContent: "flex-start", marginLeft: "6px" }} >
                <div>
                  <SlidingFilter itemList={itemList} setFilter={updateDateFilter} />
                </div>
                <div style={{ display: "flex", gap: "8px" }} >
                  {
                    width > 947 ?
                      <div >
                        <button className={styles.btnAdddelaer1} onClick={() => { setShowMessengerModal(true); dealerNumberData() }}>
                          <span><WhatAppIcon /> </span> <span> Messenger</span>
                        </button>
                      </div> : null
                  }
                  <div>
                    <button className={styles.btnAdddelaer} onClick={addDelalerModel} >{"+"} Add Dealer</button>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <div style={{ height: "100vh", overflowY: "scroll", position: "relative" }}>
            <div className={styles.test_middle}
              style={mode ? { backgroundColor: "#232529", border: "none", position: "relative", height: "150px", overflow: "hidden" } :
                { border: "none", position: "relative", height: "150px", overflow: "hidden" }}
            >
              <Suspense fallback={<>
                {Array.from({ length: 5 }).map((_ ,i) =>
                  <div style={{ height: "100%", width: "150px", }} key={i}>
                    <div style={{ height: "2rem", width: "50%" }}>
                      <Skeleton thickness={2} shape='circle' />
                    </div>
                    <Skeleton thickness={4} lines={2} />
                  </div>
                )}
              </>}>
                <div style={{ width: "100%", display: "flex", overflowX: "scroll",  }} className={styles.scrollable}>
                  {dealerStatusData.map((status, index) => (
                    <div style={{ display: "flex", justifyContent: "center",  minWidth: "160px", cursor: "pointer", marginLeft:"10px"  }}
                      key={index}
                      onClick={() => setSearchDealerData((pre) => ({
                        ...pre, status: status.statusText === "All" ? "" : status.statusText
                      }))}
                    >
                      <DealerStatusCard
                        dealerStatus={status.statusText}
                        statusText={status.statusText}
                        statusItemCount={status.statusItemCount}
                      />
                    </div>
                  ))}
                </div>
              </Suspense>
            </div>

            <div className={styles.test_bottom}
              style={mode ? { backgroundColor: "#232529", border: "none", height: "100%" } :
                { border: "none", height: "100%" , backgroundColor: "#fff" }}  >
              {width < 669 ? null :
                <div className={styles.test_bottom_top}>
                  <Suspense fallback={<div>Loading..</div>}>
                    <div className={styles.filterContiner}>
                      <div className={styles.filterserch} >
                        <SearchBar onChange={(e) => setSearchDealerData({ ...searchDealerData, searchDealerName: e.target.value })} />
                      </div>
                      <div className={styles.test_bottom_top_right}>
                        <DropDown label={"Pin"} options={[{ label: "name" }, { label: "Age" }]} />
                        <DropDown label={"State"} options={[{ label: "name" }, { label: "Age" }]} />
                        <DropDown label={"District"} options={[{ label: "name" }, { label: "Age" }]} />
                        <DropDown label={"City"} options={[{ label: "name" }, { label: "Age" }]} />
                        <DropDown label={"Sort By"} options={[
                          { label: "Referral" },
                          { label: "Widthout OTP" },
                          { label: "Managers Created" },
                          { label: "Self Created" },
                          { label: "New" },
                          { label: "newestfirst" },
                          { label: "Pending" },
                          { label: "Verified" },
                          { label: "Ascending" },
                          { label: "Descending" },
                        ]} />
                      </div>
                    </div>
                  </Suspense>
                </div>
              }
              <div className={styles.test_bottom_bottom} ref={containerRef} >
                <Suspense fallback={
                  <div style={{ width: "100%", display: "flex", flexWrap: "wrap", gap: "10px", }} >
                    {Array.from({ length: 28 }).map(( _ , i) =>
                      <div style={{ width: "19%" }} key={i} >
                        <Skeleton thickness={6} shape='circle' width='50px' height='50px' />
                        <div style={{ width: "75%" }}> <Skeleton lines={3} thickness={1} />  </div>
                        <Skeleton lines={1} thickness={3} />
                      </div>
                    )}
                  </div>}>
                  {dealerData.length === 0 ? (
                    loading ? (
                      Array.from({ length: 28 }).map((_, index) => (
                        <div key={index} style={{ width: "100%" }}>
                          <div style={{ width: "100%", display: "flex", flexWrap: "wrap", gap: "10px" }}>
                            <div style={{ width: "100%" }}>
                              <Skeleton thickness={6} shape="circle" width="50px" height="50px" />
                              <div style={{ width: "75%" }}>
                                <Skeleton lines={3} thickness={1} />
                              </div>
                              <Skeleton lines={1} thickness={3} />
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <span style={mode ? { color: "white" } : { color: "black" }}>No Data Found.</span>
                    )
                  )
                    :
                    dealerData.map((dealer, index) => (
                      <div key={index}  >
                        <DealerInfoCard
                          whatappNo={dealer.whatsapp}
                          dealerName={dealer.name}
                          // imageUri={dealer.profileImage}
                          dealerStatus={dealer.status}
                          dealerDesignation={dealer.designation}
                          dealerLocation={dealer.storeName}
                        />
                      </div>
                    ))}


                </Suspense>
              </div>
              {loading &&
                <div className={styles.Spinner} >
                  <div> <Spinner size='30' /> </div>
                </div>
              }
            </div>

            <div className={styles.pageContiner} style={{ backgroundColor: mode ? "#232529" : "#fff" }}>
              <div style={{ width: "50%", }} >
                <div style={{ width: "50%", display: "flex", justifyContent: "flex-start", marginLeft: "10px" }} >
                  <GoToPage currentPage={page} setCurrentPage={setPage} totalPages={pages} key={"1"} />
                </div>
              </div>
              <div style={{ width: "50%", display: "flex", justifyContent: "flex-end", marginRight: "10px" }} >
                <Pagenation currentPage={page} setCurrentPage={setPage} totalPages={pages} key={"1"} /></div>
            </div>
          </div>
        </div>
      </div>


      <div className={mode ? styles.md_Main_continer : styles.md_Main_continer_dark}>
        <div className={mode ? styles.Md_continer : styles.Md_continer_dark}>
          {
            dealerStatusData.map((item, i) => {
              return (<div style={{
                display: "flex",
                width: "32%",
              }}
                onClick={() => setSearchDealerData((pre) => ({
                  ...pre, status: item.statusText === "All" ? "" : item.statusText
                }))} key={i}>
                <FilterCard name={item.statusText} number={item.statusItemCount} key={i} />
              </div>
              )
            })
          }
        </div>
        <div className={styles.SearchFilter} >
          <div className={styles.SearchCon}>
            <div className={mode ? styles.SearchInput_dark : styles.SearchInput} >
              <input type="text" placeholder='Search..' className={mode ? styles.inptage_dark : styles.inptage} onChange={(e) => setSearchDealerData({ ...searchDealerData, searchDealerName: e.target.value })} />
            </div>
            <div style={{ minWidth: "40px", minHeight: "40px", border: "1px solid #EEEEE", justifyContent: "center", display: "flex", borderRadius: "6px", background: "#E4E7EB", width: "15%", height: "43px", }}>
              <span className={styles.arroIcon}>
                <ArroIcon mode={mode} />
              </span>
            </div>
            <button className={styles.addbtnmd} onClick={addDelalerModel} >{"+ Add Dealer"}</button>
          </div>
          <div>
          </div>
        </div>

        <div className={styles.md_card_Continer} >
          {dealerData.length === 0 ? <div>
            {loading ?
              <span style={mode ? { color: "white" } : { color: "black" }}>loading..</span> :
              <span style={mode ? { color: "white" } : { color: "black" }}>  No Data Found.</span>}

          </div> :
            dealerData.map((dealer, index) => (
              <div key={index} style={{ width: "100%", marginBottom: "10px", minWidth: "360px" }}>
                <DealerCartMobil
                  dealerName={dealer.name}
                  dealerStatus={
                    dealer.status === "New" ? "new" :
                      dealer.status === "Update" ? "active" :
                        dealer.status === "Pending" ? "pending" :
                          dealer.status === "Verified" ? "active" :
                            dealer.status === "Rejected" ? "rejected" :
                              dealer.status === "Deleted" ? "rejected" : null
                  }
                  dealerDesignation={dealer.designation}
                  dealerLocation={dealer.storeName}
                  imgUri={dealer.profileImage}
                />
              </div>
            ))}
          <div>
          </div>
        </div>


      </div>
    </React.Fragment>
  )
}

export default Dealers

