import React, { useEffect, useRef, useState } from 'react'
import styles from './Camp.module.css'
import TopTabBar from '../../components/updatedComponents/Tanolet/TopTabBar'
import Button from '../../components/updatedComponents/Dealers/Button'
import { Groups, Temp } from '../../components/icons/Icon3'
import { CreateTem, TemplateNotFound, ThreeDot, } from '../../components/icons/Icon2'
import { useAuth } from '../../components/context/Auth'
import SearchBar from '../../components/updatedComponents/FormComponents/SearchBar'
import SlidingFilter from '../../components/updatedComponents/Dealers/SlidingFilter'
import InputTypedate from '../../components/updatedComponents/FormComponents/InputTypedate'
import GoToPage from '../../components/updatedComponents/Pagnation/GoToPage'
import Pagenation from '../../components/updatedComponents/Pagnation/Pagenation'
import { useNavigate } from 'react-router-dom'
import TimeOutFetch from '../../APIsControll/TimeOutFechControll'
import { useToast } from '../../components/updatedComponents/Toaster/Toaster'
import Skeleton from '../../components/updatedComponents/Skeleton/Skeleton'
import NoDataFound from '../../components/updatedComponents/NoDataFound/NoDataFound'
import { TextColorCode, truncateText } from '../../Utils/TextUtils'
import ModalWrapper from '../../components/updatedComponents/layoutWrapper/ModalWrapper'
const Campaign = () => {
    const { mode, managerId } = useAuth();
    const [subBox, setSubBox] = useState(null);
    const { show } = useToast();
    const boxRef = useRef(null);
    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);
    const [pages, setPages] = useState(1);
    const [loading, setLoading] = useState({
        loading1: false,
        loading2: false,
    });
    const [stopData, setStopData] = useState(null)
    const [filtering, setFiltering] = useState({
        sarchKeyWord: "",
        tabeSarch: "",
        startDate: "",
        endDate: "",
    });
    const [testinsModalStatus, setTestinsModalStatus] = useState(false)
    const navigate = useNavigate();
    const handeltogle = (index) => {
        setSubBox(subBox === index ? null : index)
    }
    const itemList = ["All", "Draft", "Stopped", "Running", "Scheduled", /* "Awaiting Next Run", */ "Completed",];

    // console.log(stopData);

    const getDasshBoardData = async () => {
        try {
            setLoading((pre) => ({
                ...pre, loading1: true
            }))
            const payload = {
                managerId: managerId,
                keyword: filtering.sarchKeyWord,
                status: filtering.tabeSarch.toLocaleLowerCase(),
                category: "",
                day: "",
                startDate: filtering.startDate,
                endDate: filtering.endDate
            }
            const res = await TimeOutFetch(`wallikonCampaign/campaignDashboard?page=${page}`, payload);
            if (res.status === "success") {
                setData(res?.data);
                setPages(res?.pages);
                setPage(res?.page);
                setLoading((pre) => ({
                    ...pre, loading1: false
                }));
                return
            }
            if (res.status === "failed") {
                setData(res?.data);
                setPages(res?.pages);
                setPage(res?.page);
                setLoading((pre) => ({
                    ...pre, loading1: false
                }));
                return
            } else {
                setLoading((pre) => ({
                    ...pre, loading1: false
                }));
                return show("Something went wrong", "error");
            }
        } catch (error) {
            return show("Something went wrong", "error");
        }
    }
    useEffect(() => {
        getDasshBoardData()
    }, [page, filtering]);

    const handelTogleViewStatus = (item) => {
        setStopData(item)
        setTestinsModalStatus(!testinsModalStatus);
    }
    const ChangeStatus = async () => {
        const payload = {
            managerId: managerId,
            templateName: stopData?.templateName
        }
        try {
            setLoading((pre) => ({
                ...pre, loading2: true
            }))
            const res = await TimeOutFetch(`wallikonChat/stopScheduledCampaign`, payload);
            if (res.status === "success") {
                setLoading((pre) => ({
                    ...pre, loading2: false
                }));
                show(`${res.msg}`, "success");
                return
            }
            if (res.status === "failed") {
                setLoading((pre) => ({
                    ...pre, loading2: false
                }));
                show(`${res.msg}`, "error");
                return
            } else {
                setLoading((pre) => ({
                    ...pre, loading2: false
                }));
                return show("Something went wrong", "error");
            }

        } catch (error) {
            return show("Something went wrong", "error");
        }
    }

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (boxRef.current && !boxRef.current.contains(event.target)) {
                setSubBox(null);  // Reset the subBox state when clicking outside
            }
        };

        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    // console.log(filtering.tabeSarch);


    return (
        <div className={styles.mainCintner}>
            <div className={styles.Continer} style={{ backgroundColor: mode ? "#2C2E33" : "#F8F9FC" }} >
                <ModalWrapper closeBtn={true} isOpen={testinsModalStatus} onClose={handelTogleViewStatus} style={{ width: "800px", }} >
                    <div style={{
                        // minWidth: "350px",
                        maxWidth: "500px",
                        justifyContent: "center",
                        display: "flex",
                        alignItems: "center",
                        // border: "1px solid",
                        paddingBottom: "20px",
                        backgroundColor: mode ? "#2C2E33" : "#fff",
                        borderRadius: "9px",
                        margin: "0 auto"
                    }} >
                        <div className={styles.containermode}  >
                            <div className={styles.model_text} style={{ marginTop: "10px", marginBottom: "10px" }}>
                                <span style={{ color: mode ? "#fff" : "#000" }}>Are you sure you want to stop this template?</span>
                            </div>

                            <div style={{ display: "flex", width: "100%", gap: "10px" }}  >
                                <div className={styles.mode_btn} style={{ width: "100%" }} >
                                    <Button
                                        buttonText={"NO"}
                                        buttonBgColor={"#000000"}
                                        buttonBorder={"2px"}
                                        buttonTextColor={"#ffff"}
                                        buttonClick={handelTogleViewStatus}
                                    />
                                </div>
                                <div className={styles.mode_btn} style={{ width: "100%" }}>
                                    <Button
                                        buttonText={"YES"}
                                        buttonBgColor={"#4164E3"}
                                        buttonBorder={"2px"}
                                        buttonTextColor={"#ffff"}
                                        buttonClick={ChangeStatus}
                                        loading={loading.loading2}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </ModalWrapper>


                <div className={styles.goBackCon}  >

                    <div className={styles.gobacFristChlid}>
                        <TopTabBar text={"Campaigns"} />
                    </div>
                    <div className={styles.goBackScandChild}>
                        <div className={styles.goBackBtnCon}>
                            <Button
                                buttonBgColor={"#000000"}
                                buttonText={"Groups"}
                                buttonIcon={<Groups />}
                                buttonTextColor={"#fff"}
                                buttonClick={() => navigate("/groups")}
                            />
                        </div>
                        <div className={styles.goBackBtnCon} >
                            <Button
                                buttonBgColor={"#000000"}
                                buttonText={"Template"}
                                buttonIcon={<Temp />}
                                buttonTextColor={"#fff"}
                                buttonClick={() => navigate("/templates")}
                            />
                        </div>
                        <div className={styles.goBackBtnCon} >
                            <Button
                                buttonBgColor={"#4164E3"}
                                buttonText={"Campaign"}
                                buttonIcon={<CreateTem />}
                                buttonTextColor={"#fff"}
                                buttonClick={() => navigate("/newcampaign")}

                            />
                        </div>
                    </div>
                </div>
                <div className={styles.sarchBarConinnter}>
                    <div className={styles.sarchBarConinnterFristChild}>
                        <div className={styles.sarchcon1}>
                            <SearchBar placeholder={"Search"}
                                onChange={(event) => setFiltering({ ...filtering, sarchKeyWord: event.target.value })}
                                value={filtering.sarchKeyWord}
                            />
                        </div>
                        {/* <div className={styles.AroBtn}
                            style={mode ? { backgroundColor: "#1B1D21" } :
                                { backgroundColor: "#fafafafa", border: "1px solid #E4E7EB" }} >
                            <TemplateIcon1 mode={mode} />
                        </div> */}
                        <div className={styles.sarchcon}>
                            <SlidingFilter itemList={itemList} setFilter={(evnt) => setFiltering({ ...filtering, tabeSarch: evnt })} />
                        </div>
                        <div className={styles.sarchcon3}>
                            <div>
                                <InputTypedate
                                    text={"From"}
                                    onChange={(event) => setFiltering({ ...filtering, startDate: event.target.value })}
                                    value={filtering.startDate}
                                />
                            </div>
                            <div>
                                <InputTypedate
                                    text={"To"}
                                    onChange={(event) => setFiltering({ ...filtering, endDate: event.target.value })}
                                    value={filtering.endDate}
                                />
                            </div>
                        </div>
                    </div>
                    {/* <div className={styles.sarchBarConinnterSecandtChild}>
                       
                    </div> */}
                </div>

                <div className={styles.tableContiner}>
                    <div className={styles.container} style={{ height: "100%" }}>
                        <ul className={styles.responsiveTable} style={{ height: "100%" }}>
                            <li className={styles.tableHeader} style={mode ? { backgroundColor: "#232529", color: "#fafafa" }
                                : { backgroundColor: "#D9DCE5" }} >
                                <div className={`${styles.col} ${styles.large}`} style={{ width: "800px" }} >
                                    {/* <div className={styles.TableHeadChackBox} >
                                        <input type="checkbox" className={styles.inputTypeckack} /> </div> */}
                                    Campaign Details </div>
                                <div className={`${styles.col}`} > Start Time </div>
                                <div className={styles.col} >Sent</div>
                                <div className={styles.col} >Engaged</div>
                                <div className={styles.col} >Rate</div>
                                <div className={styles.col} >Status</div>
                                {/* <div className={styles.col} >Status</div> */}
                            </li>
                            {
                                loading.loading1 ? <>
                                    <Skeleton lines={8} width="100%" thickness={8} height='400px' key={"0"} />
                                </> : <>
                                    {
                                        data?.length === 0 ? <>
                                            <NoDataFound
                                                icon={<TemplateNotFound />}
                                                linkname={"add a template."}
                                                textTitle={`No Dealers found. To run a WhatsApp campaign`}
                                                to={"/newgroup"}
                                                key={"data"}
                                            />
                                        </> : <>
                                            {
                                                data?.map((e, i) =>
                                                    <li className={styles.tablerow} style={mode ? { backgroundColor: "#1B1D21", color: "#fafafa" } : { backgroundColor: "#ffff" }}
                                                        key={i}
                                                    // onClick={()=>navigate(`/singlecampaign/${e?.campaignName}`)}
                                                    >
                                                        <div className={`${styles.col} ${styles.large}`} >
                                                            {/* <div className={styles.TableHeadChackBox} >
                                                                 <input type="checkbox" className={styles.inputTypeckack} /> </div> */}
                                                            <div className={styles.colOneConintner}>
                                                                <div className={styles.blueText} ><span> {truncateText(`${e?.label}`, 25)}</span></div>
                                                                <div className={styles.boxText}> <div className={styles.boxtextCon} >{`${e?.campaignName?.[0].toUpperCase() || ""}${e?.createdBy?.name?.[0].toUpperCase()  || ""}`}
                                                                </div> <span style={{ color: mode ? "#FFFFFF" : "#000" }} >NAME : {truncateText(`${e?.campaignName}`, 20)} </span> </div>
                                                                <div> <span style={{ color: mode ? "#FFFFFF" : "#000" }}>Created by:{truncateText(`${e?.createdBy?.designation}`, 18)} / {truncateText(`${e?.createdBy?.name}`, 18)}  </span> </div>
                                                            </div>

                                                        </div>
                                                        <div className={styles.col}  style={{ position: "relative" }}  ref={boxRef} >
                                                           <span onClick={(e) => { e.stopPropagation(); handeltogle(i); }}  >  <ThreeDot mode={mode} /> </span>
                                                           {
                                                            subBox === null ? null : 
                                                            <div className={`${styles.subBox1} ${subBox === i ? styles.active : ''}`}
                                                                style={{
                                                                    backgroundColor: mode ? "#2C2E33" : "#fff",
                                                                    // border: "1px solid #000",
                                                                    top: "-200%",
                                                                    position: "absolute",
                                                                    right: "100%",
                                                                    borderRadius: "10px",
                                                                    width: "180px"
                                                                }}
                                                            >
                                                                {
                                                                    e.status === "draft" &&
                                                                    <div className={mode ? styles.sub_box_tab1 : styles.sub_box_tab1_light} onClick={() => navigate(`/singlecampaign/${e?.campaignName}`)}  >
                                                                        <span>Edit</span>
                                                                    </div>
                                                                }
                                                                {
                                                                    (e.status === "running" || e.status === "completed" || e.status === "scheduled") &&
                                                                    <div className={mode ? styles.sub_box_tab1 : styles.sub_box_tab1_light} onClick={() => handelTogleViewStatus(e)} >
                                                                        <span>Stop</span>
                                                                    </div>
                                                                }
                                                                {
                                                                    (e.status === "draft") ? null :
                                                                        <div className={mode ? styles.sub_box_tab1 : styles.sub_box_tab1_light} onClick={() => navigate(`/campaign-report/${e?.templateName}`)} >
                                                                            <span> Report   </span>
                                                                        </div>
                                                                }
                                                                {/* {
                                                                    (e.status === "stopped") &&
                                                                    <div className={mode ? styles.sub_box_tab1 : styles.sub_box_tab1_light} onClick={() => navigate(`/singlecampaign/${e?.campaignName}`)}  >
                                                                        <span> Rescheduled </span>
                                                                    </div>
                                                                } */}
                                                            </div>
                                                           }
                                                           

                                                        </div>
                                                        <div className={styles.col}  >{truncateText(`${e?.sentCount}`, 6)}  </div>
                                                        <div className={styles.col}  > {truncateText(`Comming `, 7)}  </div>
                                                        <div className={styles.col}  > {truncateText(`${e?.totalCost}`, 6)}  </div>
                                                        <div className={`${styles.col} ${styles.uperCase}`} style={{ color: TextColorCode(e?.status) }}  > {truncateText(`${e?.status}`, 18)}  </div>
                                                    </li>
                                                )
                                            }
                                        </>
                                    }
                                </>
                            }
                            <li style={{ height: "20px" }}></li>

                        </ul>
                    </div>
                </div>
                <div className={styles.pagnationConintner} style={{ backgroundColor: mode ? "#232529" : "#fff" }}>
                    <div className={styles.gotToPage1} >
                        {/* <div style={{ width: "50%", display: "flex", justifyContent: "flex-start", marginLeft: "10px" }} > */}
                        <GoToPage currentPage={page} setCurrentPage={setPage} totalPages={pages} key={"1"} />
                        {/* </div> */}
                    </div>
                    <div className={styles.gotToPage2}  >
                        <Pagenation currentPage={page} setCurrentPage={setPage} totalPages={pages} key={"1"} />
                    </div>
                </div>




            </div>

        </div>
    )
}

export default Campaign