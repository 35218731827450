import { useEffect, useRef } from "react";
import styles from "../../../pages/ordermanager/Orders.module.css";
import Spinner from "../FormComponents/Spinner";

const SearchItem = ({ mode, results = [], currentIndex, loading }) => {
  const rowRefs = useRef([]);
  const sortResults = (a, b) => {
    if (a.totalQty === 0 && b.totalQty === 0) {
      return a.warehouse.localeCompare(b.warehouse);
    } else if (a.totalQty === 0) {
      return 1;
    } else if (b.totalQty === 0) {
      return -1;
    } else {
      return a.totalQty - b.totalQty;
    }
  };
  const sortedResults = results.sort(sortResults);
  useEffect(() => {
    if (rowRefs.current[currentIndex]) {
      rowRefs.current[currentIndex].scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [currentIndex]);

  return (
    <div
      className={`${styles.search_item} ${mode ? styles.search_item_dark : ""}`}
    >
      <div className={styles.search_item_header}>
        <p style={{ color: mode ? "#FFFFFF" : "#0100E4" }}>Create New Item</p>
        <p>ALT + C</p>
      </div>
      <div className={styles.search_item_header}>
        <p style={{ color: mode ? "#FFFFFF" : "#0100E4" }}>End of List</p>
        <p>CTRL + X</p>
      </div>

      {loading ? (
        <div className={styles.item_loader}>
          <Spinner
            height={60}
            width={60}
            color={mode ? "#000000" : "#4164E3"}
          />
        </div>
      ) : (
        <div className={styles.si_table_container}>
          <table className={styles.si_table}>
            <thead>
              <tr>
                <th>Item No.</th>
                <th>Product</th>
                <th>Warehouse</th>
                <th>Quantity</th>
              </tr>
            </thead>

            <tbody>
              {sortedResults.map((r, index) => (
                <tr
                  key={index}
                  ref={(el) => (rowRefs.current[index] = el)}
                  className={index === currentIndex ? styles.selected_row : ""}
                >
                  <td>{r.itemNo}</td>
                  <td>{r.product_name}</td>
                  <td>{r.warehouse}</td>
                  <td>{r.totalQty}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default SearchItem;

// eslint-disable-next-line no-lone-blocks
{
  /* <div className={styles.table_header_row}>
        <div>Item</div>
        <div>Product</div>
        <div>Warehouse</div>
        <div>Quantity</div>
      </div>

      <div className={styles.item_list}>
        {results.map((r, index) => (
          <div className={styles.table_row} key={index}>
            <div>{r.itemNo}</div>
            <div>{r.product_name}</div>
            <div>{r.warehouse}</div>
            <div>{r.totalQty}</div>
          </div>
        ))}
      </div> */
}
