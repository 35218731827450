import React, { useCallback, useEffect, useRef, useState } from "react";
import styles from "./Orders.module.css";
import {
  TestTopBackArrowIcon,
  TestTopDotIcon,
  ChatIcon,
  DividerIcon,
  SaveAsDraftIcon,
  TestTopAddDealerIcon,
  LeftArrowIcon,
  SideBarOrdersIcon,
  ShareIcon,
  SaveIcon,
  CancelIcon2,
} from "../../components/icons/Icon";
import { useAuth } from "../../components/context/Auth";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import SlidingFilter from "../../components/updatedComponents/Dealers/SlidingFilter";
import InputTag from "../../components/updatedComponents/FormComponents/InputTag";
import VerticalDropDown from "../../components/updatedComponents/FormComponents/VerticalDropDown";
import Button from "../../components/updatedComponents/Dealers/Button";
import TextElement from "../../components/updatedComponents/FormComponents/TextElement";
import ModalWrapper from "../../components/updatedComponents/layoutWrapper/ModalWrapper";
import ShareLinkModal from "../../components/updatedComponents/OrderManager/ShareLinkModal";
import AddReceiptModal from "../../components/updatedComponents/OrderManager/AddReceiptModal";
import SearchDealer from "../../components/updatedComponents/OrderManager/SearchDealer";
import ItemList from "../../components/updatedComponents/OrderManager/ItemList";
import NewItemList from "../../components/updatedComponents/OrderManager/NewItemList";
import ShippingChargesModal from "../../components/updatedComponents/OrderManager/ShippingChargesModal";
import ManagerModal from "../../components/updatedComponents/Manager/ManagerModal";
import DealerAddressModal from "../../components/updatedComponents/OrderManager/DealerAddressModal";
import fetchData from "../../APIsControll/apiControll";
import OrderSuccessModal from "../../components/updatedComponents/OrderManager/OrderSuccessModal";
import VerticalDropDown2 from "../../components/updatedComponents/FormComponents/VerticalDropDown2";
import AdduserModel from "../../components/updatedComponents/models/AdduserModel";

const SaleOrder = () => {
  const { mode, managerId } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const orderId = searchParams.get("orderId")
    ? searchParams.get("orderId")
    : "";
  const [loading, setLoading] = useState(false);
  const [shippingInfo, setShippingInfo] = useState("");
  const [generalInfo, setGeneralInfo] = useState({});
  const [reciptsInfo, setReciptsInfo] = useState([]);
  const [warehouses, setWarehouses] = useState([]);
  const [dispatchManagers, setDispatchManagers] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [methodPartners, setMethodPartners] = useState([]);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [nextBtn, setNextBtn] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  // dealer search
  const [showDealerSearch, setShowDealerSearch] = useState(true);
  const [searchDealer, setSearchDealer] = useState("");
  const [dealerResults, setDealerResults] = useState([]);
  const [dealerLoading, setDealerLoading] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  // item search
  const [showItemSearch, setShowItemSearch] = useState(false);
  const [showDescriptionInput, setShowDescriptionInput] = useState(false);
  const [itemsdetails, setItemsdetails] = useState([
    {
      itemNo: "",
      product_name: "",
      collection_name: "",
      warehouse: "",
      qty: "",
      dp: "",
      unit: "",
      amount: "",
      totalQty: "",
      desc: "",
      descKey: "",
    },
  ]);

  const [error_state] = useState({
    dp: "",
    index: "",
  });
  // const [reload, setReload] = useState(false);
  const [selectedWarehouse, setSelectedWarehouse] = useState("");
  const [updatedAddress, setUpdatedAddress] = useState(null);

  const [paymentLink, setPaymentLink] = useState("");
  const [stateUpdated, setStateUpdated] = useState(false); //for skip case
  const [dropDownOpen, setDropDownOpen] = useState(false); //for no case
  const [selectedTab, setSelectedTab] = useState("Sales");

  const dealerInpRef = useRef(null);
  const locationRef = useRef(null);
  const newItemListRefs = useRef([]);
  const quantityRefs = useRef([]);
  const shippingRef = useRef(null);
  const checkboxRef = useRef(null);
  const formRef = useRef(null);

  let isNew = false;
  if (location.search === "") {
    isNew = true;
  } else {
    isNew = false;
  }

  //?this keeps the dealer input focused on page load
  useEffect(() => {
    if (!isModalOpen) {
      if (dealerInpRef.current) {
        dealerInpRef.current.focus();
      }
    }
  }, [isModalOpen]);

  //?this focuses the item search of every new item list
  useEffect(() => {
    if (itemsdetails.length > 1 && !isModalOpen) {
      if (newItemListRefs.current.length) {
        const lastItemRef =
          newItemListRefs.current[newItemListRefs.current.length - 1];
        lastItemRef?.focus();
      }
    }
  }, [isModalOpen, itemsdetails]);

  //?this gets the data in the edit order scenario
  useEffect(() => {
    const fetchOrder = async () => {
      setLoading(true);
      try {
        const apiData = {
          managerId: managerId,
          orderId: orderId,
          warehousename: "",
        };
        const result = await fetchData("order/order", apiData);
        if (result.status === "success") {
          if (result.data.order) {
            setGeneralInfo((prevState) => ({
              ...prevState,
              dealerId: result.data.order.dealerId,
              storeName: result.data.order.storeName,
              dealerName: result.data.order.name,
              billingAddress: result.data.order.billingAddress,

              shippingType: result.data.order.shippingInfo.shippingType,
              shippingCharge: result.data.order.shippingInfo.shippingCharge,
              courierName: result.data.order.shippingInfo.courierName,
              partnerName: result.data.order.shippingInfo.methodName
                ? result.data.order.shippingInfo.methodName
                : result.data.order.shippingInfo.partnerName,
              deliveryPartner: result.data.order.shippingInfo.methodName
                ? result.data.order.shippingInfo.methodName
                : result.data.order.shippingInfo.partnerName,
              mobile: result.data.order.shippingInfo.mobile
                ? result.data.order.shippingInfo.mobile
                : result.data.order.DealerInfo.whatsapp,
              whatsapp: result.data.order.DealerInfo.whatsapp
                ? result.data.order.DealerInfo.whatsapp
                : result.data.order.shippingInfo.mobile,
              email: result.data.order.DealerInfo.email
                ? result.data.order.DealerInfo.email
                : "",
              warehousename: result.data.order.warehousename
                ? result.data.order.warehousename
                : "",
              dmId: result.data.order.dmId,
              dmName: result.data.order.dmName,
              cartType:
                result.data.order.cartType === "Cart" ? "ocart" : "pcart",
              orderDate: result.data.order.orderDate,
              orderId: result.data.order.orderId,

              transactionScreenshot: result.data.order.transactionScreenshot,
              paymentMethod: result.data.order.paymentMethod,
              paymentType: result.data.order.paymentType,
              paymentStatus: result.data.order.paymentStatus,
              paymentAmount: result.data.order.paymentAmount,
              transferTransId: result.data.order.transferTransId,

              status: result.data.order.status,
              manageName: result.data.order.manageName,
              manageValue: result.data.order.manageValue,
              orderType: result.data.order.orderType,
              toPay: result.data.order.toPay,
              dispatchStatus: result.data.order.dispatchStatus,
              reqStatus: result.data.order.reqStatus,
              reqType: result.data.order.reqType,
            }));
            setShippingInfo(result.data.order.shippingInfo);
            setItemsdetails(result.data.order.items);
            setReciptsInfo(result.data.receipts);
          }
        }
      } catch (error) {
        setLoading(false);
        console.log("error", error);
      }
    };
    if (managerId) {
      fetchOrder();
    }
  }, [orderId, managerId]);

  //?this updates detalis when item gets sele cted (working)
  useEffect(() => {
    const fetchDMs = async () => {
      try {
        const apiData = {
          managerId: managerId,
          orderId: orderId,
          warehousename: generalInfo.warehousename,
        };
        const result = await fetchData("order/order", apiData);
        if (result.status === "success") {
          if (result.data.warehouses) {
            setWarehouses(result.data.warehouses);
          }
          if (result.data.dispatchmanagers.length > 0) {
            setDispatchManagers(result.data.dispatchmanagers);
            setGeneralInfo((prevState) => ({
              ...prevState,
              dmId: result.data.dispatchmanagers[0].managerId,
            }));
          }
          setAccounts(result.data.accounts);
        }
      } catch (error) {
        console.log("error", error);
      }
    };
    if (managerId) {
      fetchDMs();
    }
  }, [orderId, managerId, generalInfo.warehousename]);

  const fetchDealer = useCallback(async () => {
    setDealerLoading(true);
    try {
      const apiData = {
        managerId: 1,
        keyword: searchDealer,
      };

      const result = await fetchData("order/finddealer", apiData);

      if (result.status === "success") {
        setDealerResults(result.data);
      }
      setDealerLoading(false);
    } catch (error) {
      console.log("error", error);
      setDealerLoading(false);
    }
  }, [searchDealer]);

  //?this triggers fetchDealer
  useEffect(() => {
    if (searchDealer) {
      const delayDebounceFn = setTimeout(() => {
        fetchDealer();
      }, 700);

      return () => clearTimeout(delayDebounceFn);
    }
  }, [searchDealer, fetchDealer]);

  //?this method sets up the delivery method details
  const orderDeliveryMethod = async () => {
    if (generalInfo.warehousename.trim() === "") {
      alert("Please select a warehouse before next button.");
      return;
    }
    setLoadingBtn(true);
    try {
      if (shippingInfo.pincode) {
        setMethodPartners([]);
        const apiData = {
          courierId: "",
          courierName: "",
          shipPincode: shippingInfo.pincode,
          warehousename: generalInfo.warehousename,
          methodName: generalInfo.deliveryPartner,
          streetAddress:
            shippingInfo.street +
            ", " +
            shippingInfo.city +
            ", " +
            shippingInfo.district +
            ", " +
            shippingInfo.state,
          selectedItems: itemsdetails,
        };
        const result = await fetchData(
          "deliverymethod/orderDeliveryMethod",
          apiData
        );
        if (result.status === "success") {
          if (result.methodSelection === "Active") {
            setMethodPartners(result.deliveryMethod);
            setGeneralInfo((prevState) => ({
              ...prevState,
              methodSelection: result.methodSelection
                ? result.methodSelection
                : "",
              courierName: result.courierName ? result.courierName : "",
              deliveryPartner: result.data.methodName
                ? result.data.methodName
                : "",
            }));
          } else if (result.methodSelection === "Disable") {
            setGeneralInfo((prevState) => ({
              ...prevState,
              shippingType: "Standard",
              cartType: "ocart",
              courierName: result.data.courierName,
              partnerName: result.data.methodName,
              deliveryPartner: result.data.methodName
                ? result.data.methodName
                : "Self",
              methodSelection: result.methodSelection
                ? result.methodSelection
                : "",
            }));
          }
          setNextBtn(true);
          setLoadingBtn(false);
        } else {
          alert(result.msg);
          setNextBtn(false);
          setLoadingBtn(false);
        }
      }
    } catch (error) {
      console.log("error", error);
      setNextBtn(false);
      setLoadingBtn(false);
    }
  };

  //?this method calculates the shipping charges
  const calculateCharges = async () => {
    if (generalInfo.warehousename.trim() === "") {
      alert("Please select a warehouse before next button.");
      return;
    }
    try {
      if (shippingInfo.pincode) {
        setMethodPartners([]);
        const apiData = {
          courierId: "",
          courierName: "",
          shipPincode: shippingInfo.pincode,
          warehousename: generalInfo.warehousename,
          methodName: generalInfo.deliveryPartner,
          streetAddress:
            shippingInfo.street +
            ", " +
            shippingInfo.city +
            ", " +
            shippingInfo.district +
            ", " +
            shippingInfo.state,
          selectedItems: itemsdetails,
        };
        const result = await fetchData(
          "deliverymethod/orderDeliveryMethod",
          apiData
        );
        if (result.status === "success") {
          if (result.methodSelection === "Active") {
            setGeneralInfo((prevState) => ({
              ...prevState,
              deliveryCharge: result.data.deliveryCharge,
            }));
          } else if (result.methodSelection === "Disable") {
            setGeneralInfo((prevState) => ({
              ...prevState,
              deliveryCharge: result.data.deliveryCharge,
            }));
          }
        } else {
          alert(result.msg);
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const orderSuccessKeyPress = (action, link) => {
    const whatsappNo = generalInfo?.whatsapp || "Not Provided";
    const mobileNo = generalInfo?.mobile || "Not Provided";
    const email = generalInfo?.email || "Not Provided";
    const generatedLink = link || paymentLink || "No Link Available";

    if (action === "shareLink") {
      setTimeout(() => {
        handleOpenModal(
          <ShareLinkModal
            mode={mode}
            managerId={managerId}
            handleCloseModal={handleCloseModal}
            generatedLink={generatedLink}
            whatsappNo={whatsappNo}
            mobileNo={mobileNo}
            email={email}
            isModalOpen={true}
          />
        );
      }, 0);
    } else if (action === "addReceipt") {
      setTimeout(() => {
        handleOpenModal(
          <AddReceiptModal
            handleCloseModal={handleCloseModal}
            mode={mode}
            totalAmount={totalAmount}
            orderId={orderId && orderId}
            managerId={managerId && managerId}
            accounts={accounts}
          />
        );
      }, 0);
    }
  };

  //?this places the order
  const handleSaleOrder = async (status) => {
    if (error_state.dp) {
      alert(error_state.dp);
      return;
    }

    if (totalAmount < 0) {
      alert("Total amount must be greater than zero");
      return;
    }

    try {
      setLoading(true);

      const validSaleItems = itemsdetails.filter((item) =>
        Object.values(item).some(
          (value) => value !== "" && value !== null && value !== undefined
        )
      );

      const shippingCharge =
        generalInfo.deliveryPartner === "Self"
          ? 0
          : generalInfo.deliveryCharge || generalInfo.shippingCharge;

      const apiData = {
        managerId: managerId,
        orderId: orderId,
        gstuin: generalInfo.gstuin,
        warehousename: generalInfo.warehousename,
        dmId: generalInfo.dmId,
        cartType: generalInfo.cartType,
        dealerId: generalInfo.dealerId,
        name: shippingInfo.name,
        street: shippingInfo.street,
        mobile: shippingInfo.mobile,
        pincode: shippingInfo.pincode,
        city: shippingInfo.city,
        district: shippingInfo.district,
        state: shippingInfo.state,
        shippingType: generalInfo.shippingType,
        shippingCharge: shippingCharge,
        toPay: generalInfo.toPay,
        courierName:
          generalInfo.deliveryPartner === "Self"
            ? "Pick Up"
            : generalInfo.courierName,
        methodName:
          generalInfo.courierName === "Pick Up"
            ? "Self"
            : generalInfo.methodName || generalInfo.deliveryPartner,
        partnerName: generalInfo.deliveryPartner || generalInfo.partnerName,
        manageName: generalInfo.manageName,
        manageValue: generalInfo.manageValue,
        saleItems: validSaleItems,
        status: status,
      };

      const result = await fetchData("order/saleOrder", apiData);

      if (result.status === "success") {
        setLoading(false);
        setLoadingBtn(false);

        if (status === "success") {
          if (!orderId) {
            navigate(`/orders/saleorder?orderId=${result.data.orderId}`);
          }
          const newOrderId = result.data.orderId;
          const link = await generatePaymentLink(newOrderId);
          handleOpenModal(
            <OrderSuccessModal
              mode={mode}
              handleCloseModal={handleCloseModal}
              orderSuccessKeyPress={(action) =>
                orderSuccessKeyPress(action, link)
              }
            />
          );
        } else if (status === "draft") {
          navigate("/orders");
        }
      } else {
        alert(result.msg);
      }
    } catch (error) {
      console.error("Error in handleSaleOrder:", error);
      setLoading(false);
      setLoadingBtn(false);
    }
  };

  //?this opens up an confirmation modal then calls handleSaleOrder
  const handleSubmit = () => {
    handleOpenModal(
      <ManagerModal
        heading={"Confirm Order"}
        centerHeading={"Are you sure want to place this Order?"}
        targetWord={"order"}
        handleCloseModal={() => handleCloseModal()}
        handleSubmit={() => handleSaleOrder("success")}
      />
    );
  };

  const generatePaymentLink = useCallback(
    async (oId) => {
      setPaymentLink("");
      const currentOrderId = oId || orderId;
      if (!currentOrderId) {
        console.error("Order ID is not available for generating payment link");
        return;
      }
      try {
        const apiData = { orderId: currentOrderId };
        const result = await fetchData("receipt/generatePaymentLink", apiData);
        if (result.status === "success" && result.shortUrl) {
          const link = `https://dealer.wallicon.in/${result.shortUrl}`;
          setPaymentLink(link);
          return link;
        } else {
          console.error("Failed to generate payment link:", result.msg);
        }
      } catch (error) {
        console.error("Error generating payment link:", error);
      }
    },
    [orderId]
  );

  useEffect(() => {
    if (orderId) {
      generatePaymentLink(orderId);
    }
  }, [generatePaymentLink, orderId]);

  //?this triggers orderDeliveryMethod and changes the next button to create order button
  const handleNextButtonClick = (value) => {
    if (!generalInfo.warehousename) {
      if (formRef.current) {
        if (formRef.current.checkValidity()) {
          if (
            generalInfo.deliveryPartner !== "Self" &&
            generalInfo.partnerName !== "Self"
          ) {
            console.log("Ordering delivery method1");
            setGeneralInfo((prevState) => ({
              ...prevState,
              warehousename: selectedWarehouse,
            }));
            checkboxRef.current.blur();
            handleSubmit();
          } else {
            console.log("Setting nextBtn to true");
            setNextBtn(true);
          }
        } else {
          console.log("Form is invalid");
          formRef.current.reportValidity();
        }
      } else {
        console.log("formRef.current is null");
      }
    } else {
      if (
        generalInfo.deliveryPartner !== "Self" &&
        generalInfo.partnerName !== "Self"
      ) {
        console.log("Ordering delivery method2");
        checkboxRef.current.blur();
        handleSubmit();
      } else {
        console.log("Setting nextBtn to true ?????");
        setNextBtn(true);
        handleSubmit();
      }
    }
  };

  const handleOpenModal = (content) => {
    setIsModalOpen(true);
    setModalContent(content);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setModalContent(null);
  };

  const openShippingModal = () => {
    handleOpenModal(
      <ShippingChargesModal
        mode={mode}
        close={() => handleCloseModal()}
        shippingRef={shippingRef}
        checkboxRef={checkboxRef}
        calculateCharges={() => {
          calculateCharges();
        }}
        orderDeliveryMethod={() => {
          orderDeliveryMethod();
        }}
        skipShippingCharges={handleSkipShippingCharges}
        noShippingCharges={() => setDropDownOpen(true)}
      />
    );
  };

  const handleSkipShippingCharges = () => {
    setGeneralInfo((prevState) => ({
      ...prevState,
      deliveryPartner: "Self",
      courierName: "Pick Up",
      toPay: false,
    }));
    setStateUpdated(true);
  };
  if (stateUpdated) {
    setTimeout(() => {
      handleNextButtonClick();
    }, 700);
    setStateUpdated(false);
  }

  //?adds up new item in the list
  const handleAddClick = () => {
    if (error_state.dp) {
      alert(error_state.dp);
    } else {
      setItemsdetails([
        ...itemsdetails,
        {
          itemNo: "",
          product_name: "",
          collection_name: "",
          qty: "",
          price: "",
          unit: "",
          amount: "",
        },
      ]);
    }
  };

  //?removes the last item from the list
  const handleRemoveClick = (index) => {
    if (itemsdetails.length > 1) {
      const list = [...itemsdetails];
      list.splice(index, 1);
      setItemsdetails(list);
    }
  };

  //?After selecting dealer this is updated
  const updateGeneralInfo = (d) => {
    if (d.name === "Cash") {
      setGeneralInfo((prevState) => ({
        ...prevState,
        dealerId: d.dealerId,
        dealerName: d.name,
        storeName: d.storeName,
        mobile: d.mobile,
        whatsapp: d.whatsapp,
        email: d.email,
        gstuin: d.gstuin ? d.gstuin : "",
        shippingType: "Standard",
        courierName: "Pick Up",
        partnerName: "Self",
      }));
    } else {
      setGeneralInfo((prevState) => ({
        ...prevState,
        dealerId: d.dealerId,
        dealerName: d.name,
        storeName: d.storeName,
        mobile: d.mobile,
        whatsapp: d.whatsapp,
        email: d.email,
        gstuin: d.gstuin,
        billingAddress:
          (d.street || d.city || d.district || d.state || d.pincode) &&
          [d.street, d.city, d.district, d.state, d.pincode]
            .filter(Boolean)
            .join(" "),
      }));
    }
    newItemListRefs.current[0]?.focus();
  };

  //?After selecting dealer or selecting dealer with an updated shipping address
  const updateShippingInfo = (dealer, address) => {
    setShippingInfo((prevState) => ({
      ...prevState,
      mobile: dealer.mobile,
      name: dealer.storeName,
      pincode: address?.pinCode || dealer.pincode,
      street: address?.streetAddress || dealer.street,
      city: address?.city || dealer.city,
      district: address?.district || dealer.district,
      state: address?.state || dealer.state,
    }));
  };

  //?handles selection of dealer after dealer search
  const handleSelectDealer = (d) => {
    dealerInpRef.current.blur();
    dealerInpRef.current = null;
    locationRef.current?.focus();

    handleOpenModal(
      <DealerAddressModal
        shippingAddress={
          d.street || d.city || d.district || d.state || d.pincode
            ? [d.street, d.city, d.district, d.state, d.pincode]
                .filter(Boolean)
                .join(" ")
            : undefined
        }
        ref={locationRef}
        handleCloseModal={() => handleCloseModal()}
        onAddressUpdate={(data) => {
          setUpdatedAddress(data);
          updateGeneralInfo(d);
          updateShippingInfo(d, data);
        }}
        mode={mode}
        onKeyPress={(e) => {
          e.preventDefault();
          e.stopPropagation();
          updateGeneralInfo(d);
          updateShippingInfo(d, updatedAddress);
        }}
      />
    );
  };

  //?handles selection of item after item search
  const handleSelectItem = (item, index) => {
    if (!generalInfo.cartType) {
      setGeneralInfo((prevState) => ({
        ...prevState,
        cartType: item.cartType,
        shippingType: "Standard",
        warehousename: item.warehouse,
      }));
      setItemsdetails([
        ...itemsdetails.slice(0, index),
        item,
        ...itemsdetails.slice(index + 1),
      ]);
    } else {
      setItemsdetails([
        ...itemsdetails.slice(0, index),
        item,
        ...itemsdetails.slice(index + 1),
      ]);
    }
  };

  const handleDealerKeyDown = (e) => {
    if (showDealerSearch) {
      if (e.key === "ArrowDown") {
        setCurrentIndex((prevIndex) =>
          prevIndex < dealerResults.length - 1 ? prevIndex + 1 : prevIndex
        );
        e.preventDefault();
      }

      if (e.key === "ArrowUp") {
        setCurrentIndex((prevIndex) =>
          prevIndex > 0 ? prevIndex - 1 : prevIndex
        );
        e.preventDefault();
      }

      if (e.key === "Escape") {
        setShowDealerSearch(false);
      }

      if (e.altKey && e.key.toLowerCase() === "c") {
        handleOpenModal(
          <AdduserModel mode={mode} close={() => handleCloseModal()} />
        );
      }

      if (e.key === "Enter") {
        e.preventDefault();
        handleSelectDealer(dealerResults && dealerResults[currentIndex]);
      }
    }
  };

  // //?calculates the total amount (before adding shipping charges)
  const amount = itemsdetails.reduce((sum, item) => {
    if (item.amount && !isNaN(item.amount)) {
      return sum + parseFloat(item.amount);
    }
    return sum;
  }, 0);

  let cartPriceSum =
    itemsdetails &&
    itemsdetails.reduce((sum, currentItem) => {
      return (
        sum +
        parseFloat(currentItem.price ? currentItem.price : 0) *
          parseFloat(currentItem.qty ? currentItem.qty : 0)
      );
    }, 0);

  var cartTotal =
    itemsdetails &&
    itemsdetails
      .map((x) =>
        !x.accessories
          ? 0
          : x.accessories
              .map(
                (y) =>
                  (y.accessoriesQty ? y.accessoriesQty : 0) * y.accessoriesPrice
              )
              .reduce((a, b) => a + b, 0)
      )
      .reduce((a, b) => a + b, 0);

  let subtotal =
    (cartPriceSum ? cartPriceSum : 0) + (cartTotal ? cartTotal : 0);

  let gettotalAmount =
    subtotal +
    parseFloat(
      generalInfo.toPay
        ? 0
        : generalInfo.deliveryPartner === "Self"
        ? 0
        : generalInfo.deliveryCharge
        ? generalInfo.deliveryCharge
        : generalInfo.shippingCharge
        ? generalInfo.shippingCharge
        : 0
    );

  let totalAmount = Math.round(gettotalAmount);

  const routeName = isNew ? "Sale Order" : "Sale Order/" + orderId;
  const slidingFilter = [
    "Sales",
    "Purchase",
    "Adjustment",
    "Transfer",
    "Production",
    "Production & Sale",
    "Production & Transfer",
  ];

  const headerData = [
    "S.No",
    "Item Description",
    "Product",
    "Warehouse",
    "Quantity",
    "Unit",
    "Rate",
    "Amount",
  ];

  console.log("IS LOADING ", loading);

  return (
    <>
      <div
        className={styles.order}
        style={mode ? { backgroundColor: "#2C2E33", border: "none" } : {}}
      >
        <ModalWrapper isOpen={isModalOpen} onClose={handleCloseModal} noBtn>
          {modalContent}
        </ModalWrapper>

        <div className={styles.order_top}>
          <div className={styles.order_top_left}>
            <div
              className={styles.order_top_left_go_back}
              onClick={() => navigate(-1)}
            >
              <TestTopBackArrowIcon color={mode ? "#ffffff" : "#0100E4"} />
              <p
                className={styles.order_go_back_text}
                style={mode ? { color: "#FFFFFF" } : {}}
              >
                Go Back
              </p>
            </div>
            <TestTopDotIcon color={mode ? "#E4E7EB" : "#646B88"} />
            <p
              className={styles.order_top_left_path_text}
              style={{
                color: mode ? "#9C9797" : "#646B88",
              }}
            >
              {routeName}
            </p>
          </div>
          <div className={styles.order_right}>
            {isNew ? (
              <div>
                <SlidingFilter
                  itemList={slidingFilter}
                  selectedItem={selectedTab}
                  setSelectedItem={setSelectedTab}
                />
              </div>
            ) : (
              <div className={styles.so_right}>
                <p style={{ color: mode && "#9C9797" }}>
                  {generalInfo.orderType}
                </p>
                <div>
                  <SideBarOrdersIcon color={mode ? "#fff" : "#646B88"} />
                  <p style={{ color: mode && "#fff" }}>{generalInfo.status}</p>
                </div>
              </div>
            )}

            <div>
              <Button
                buttonIcon={<ChatIcon />}
                buttonText={"Chat"}
                buttonBgColor={"#4164E3"}
                buttonTextColor={"#ffffff"}
                buttonBorder={"none"}
                buttonClick={() => {
                  navigate("/whatsappchats");
                }}
              />
            </div>
          </div>
        </div>

        <div className={styles.so_scroll_wrapper}>
          <form
            className={styles.so_form}
            ref={formRef}
            onSubmit={(e) => handleSubmit(e)}
          >
            <div className={styles.so_form_1}>
              <div style={{ position: "relative" }}>
                {!isNew && (
                  <div className={styles.voucher_det}>
                    <TextElement
                      label={"Voucher No."}
                      labelColor={mode ? "#fff" : "#646B88"}
                      value={generalInfo.orderId}
                      valueColor={mode ? "#fff" : "#646B88"}
                      fontWeight={"400"}
                    />
                    <TextElement
                      label={"Voucher Date"}
                      labelColor={mode ? "#fff" : "#646B88"}
                      value={generalInfo.orderDate}
                      valueColor={mode ? "#fff" : "#646B88"}
                      fontWeight={"400"}
                    />
                  </div>
                )}

                <InputTag
                  ref={dealerInpRef}
                  labelText={"Dealers Name / Dealer / Dealer ID"}
                  placeholderText={"Search Dealer"}
                  padding={"8px"}
                  fontSize={"12px"}
                  value={
                    (generalInfo.storeName &&
                      generalInfo.dealerName &&
                      generalInfo.storeName + " / " + generalInfo.dealerName) ||
                    searchDealer
                  }
                  onChange={(e) => {
                    setSearchDealer(e.target.value);
                  }}
                  onFocus={() => {
                    setShowDealerSearch(true);
                  }}
                  onBlur={() => {
                    setShowDealerSearch(false);
                  }}
                  onKeyDown={(e) => handleDealerKeyDown(e)}
                  maxLength={100}
                  required
                />

                {showDealerSearch && (
                  <SearchDealer
                    mode={mode}
                    results={dealerResults}
                    loading={dealerLoading}
                    currentIndex={currentIndex}
                    onDealerSelect={handleSelectDealer}
                  />
                )}
              </div>
              <div>
                <InputTag
                  labelText={"Shipping Address"}
                  placeholderText={"Enter Dealer Address"}
                  value={
                    (shippingInfo.street ||
                      shippingInfo.city ||
                      shippingInfo.district ||
                      shippingInfo.state ||
                      shippingInfo.pincode) &&
                    [
                      shippingInfo.street,
                      shippingInfo.city,
                      shippingInfo.district,
                      shippingInfo.state,
                      shippingInfo.pincode,
                    ]
                      .filter(Boolean)
                      .join(" ")
                  }
                  padding={"8px"}
                  fontSize={"12px"}
                  required
                />
              </div>
            </div>
            <div className={styles.so_form_1}>
              <div>
                <div style={{ width: "50%" }}>
                  <VerticalDropDown
                    height={"37px"}
                    fontSize={"12px"}
                    dropDownText={"Auto"}
                    label={"Warehouse"}
                    value={{
                      label: generalInfo.warehousename || "Select",
                      value: generalInfo.warehousename,
                    }}
                    options={
                      warehouses &&
                      warehouses?.map((w) => ({
                        label: w.warehousename,
                        value: w.warehousename,
                      }))
                    }
                    onchange={(option) => {
                      setSelectedWarehouse(option.value);
                      setGeneralInfo((prevState) => ({
                        ...prevState,
                        warehousename: option.value,
                      }));
                    }}
                  />
                </div>
                <div style={{ width: "50%" }}>
                  <VerticalDropDown
                    height={"37px"}
                    fontSize={"12px"}
                    dropDownText={"Select"}
                    label={"Dispatch Manager"}
                    value={{
                      label:
                        dispatchManagers?.find(
                          (manager) => manager.managerId === generalInfo.dmId
                        )?.name || "Select",
                      value: generalInfo.dmId,
                    }}
                    options={
                      dispatchManagers &&
                      dispatchManagers?.map((d) => ({
                        label: d.name || "Select",
                        value: d.managerId,
                      }))
                    }
                    onchange={(option) =>
                      setGeneralInfo((prevState) => ({
                        ...prevState,
                        dmId: option.value,
                      }))
                    }
                  />
                </div>
              </div>
              <div>
                <div style={{ width: "100%", position: "relative" }}>
                  <div className={styles.voucher_det}>
                    {isNew ? (
                      <TextElement
                        labelColor={mode ? "#fff" : "#646B88"}
                        value={
                          generalInfo.length === 0
                            ? "NA"
                            : generalInfo.deliveryPartner === "Self"
                            ? "Pick Up"
                            : "Lucknow City Delivery"
                        }
                        valueColor={mode ? "#fff" : "#646B88"}
                        fontWeight={"400"}
                      />
                    ) : (
                      <TextElement
                        labelColor={mode ? "#fff" : "#646B88"}
                        value={
                          shippingInfo.length === 0
                            ? "NA"
                            : shippingInfo.methodName === "Self"
                            ? "Pick Up"
                            : "Lucknow City Delivery"
                        }
                        valueColor={mode ? "#fff" : "#646B88"}
                        fontWeight={"400"}
                      />
                    )}
                  </div>
                  <VerticalDropDown2
                    height={"37px"}
                    fontSize={"12px"}
                    label={"Delivery Method"}
                    dropDownOpen={dropDownOpen}
                    shippingRef={shippingRef}
                    dropDownText={
                      generalInfo.deliveryPartner
                        ? generalInfo.deliveryPartner
                        : "Select"
                    }
                    value={
                      generalInfo.deliveryPartner
                        ? {
                            label: generalInfo.deliveryPartner,
                            value: generalInfo.deliveryPartner,
                          }
                        : null
                    }
                    options={[
                      { label: "Self", value: "Self" },
                      ...methodPartners.map((m) => ({
                        label: m.methodName || m.partnerName,
                        value: m.methodName || m.partnerName,
                      })),
                    ]}
                    onchange={(option) => {
                      const selectedValue = option.value;
                      setGeneralInfo((prevState) => ({
                        ...prevState,
                        deliveryPartner: selectedValue,
                      }));
                    }}
                  />
                </div>
              </div>
            </div>
          </form>

          <ul className={styles.so_data_list}>
            <div className={styles.so_data_list_container}>
              <table className={styles.order_table}>
                <thead>
                  <tr>
                    {headerData.map((d, index) => (
                      <th
                        key={index}
                        className={`${styles.table_header} ${
                          mode ? styles.table_header_dark : ""
                        }`}
                      >
                        {d}
                      </th>
                    ))}
                  </tr>
                </thead>
                {isNew
                  ? itemsdetails.map((item, index) => (
                      <React.Fragment key={index}>
                        <NewItemList
                          newItemListRef={(el) =>
                            (newItemListRefs.current[index] = el)
                          }
                          key={index}
                          item={item}
                          index={index}
                          mode={mode}
                          showItemSearch={showItemSearch}
                          setShowItemSearch={setShowItemSearch}
                          managerId={managerId}
                          orderType={generalInfo.orderType}
                          onSelectItem={(item) => handleSelectItem(item, index)}
                          itemsdetailslength={itemsdetails.length}
                          removeItem={() => handleRemoveClick(index)}
                          addItem={() => handleAddClick()}
                          onShowDescriptionInputChange={(value) =>
                            setShowDescriptionInput(value)
                          }
                          onCompletion={() => openShippingModal()}
                          quantityRefs={quantityRefs.current}
                        />
                        <div style={{ height: "5px" }}></div>
                        {error_state.index === index && (
                          <div className="fs_10 text_danger">
                            {error_state.dp}
                          </div>
                        )}
                      </React.Fragment>
                    ))
                  : itemsdetails.map((d, index) => (
                      <ItemList
                        key={d._id || index}
                        d={d}
                        index={index}
                        mode={mode}
                        itemsdetailslength={itemsdetails.length}
                        removeItem={() => handleRemoveClick(index)}
                      />
                    ))}
              </table>
            </div>
            <div
              style={{
                alignSelf: "flex-end",
                border: "2px solid #4164E3",
                borderRadius: "50%",
                cursor: "pointer",
              }}
              onClick={() => {
                handleAddClick();
              }}
            >
              <TestTopAddDealerIcon color="#4164E3" />
            </div>
            {showDescriptionInput && (
              <div className={styles.so_instc}>
                <p>Press Enter to Add Item</p>
                <DividerIcon />
                <p>Double Enter for Next Option</p>
                <DividerIcon />
                <p>Ctrl + Backspace for Remove Item</p>
              </div>
            )}
          </ul>
        </div>

        <div
          className={`${styles.so_footer} ${mode ? styles.so_footer_dark : ""}`}
        >
          <div className={styles.so_footer_top}>
            {!isNew && (
              <div className={styles.so_footer_top_left}>
                <div
                  className={styles.so_receipt_data}
                  style={mode ? { backgroundColor: "#1B1D21" } : {}}
                >
                  <ul
                    style={
                      mode ? { backgroundColor: "#2C2E33", color: "#fff" } : {}
                    }
                  >
                    <li className="col">Receipt Number</li>
                    <li className="col">Payment Method</li>
                    <li className="col">Amount</li>
                    <li className="col">Status</li>
                  </ul>
                  {reciptsInfo &&
                    reciptsInfo.map((r, index) => (
                      <div className={styles.scroll_con}>
                        <ul
                          style={
                            mode
                              ? { backgroundColor: "#232529", color: "#fff" }
                              : {}
                          }
                          key={index}
                        >
                          <li className="col" key={index + r.receiptId}>
                            {r.receiptId}
                          </li>
                          <li className="col" key={index + r.paymentMethod}>
                            {r.paymentMethod}
                          </li>
                          <li className="col" key={index + r.paymentAmount}>
                            {r.paymentAmount}
                          </li>
                          <li
                            className="col"
                            key={index + r.status}
                            style={{ color: "#FB9600" }}
                          >
                            {r.status}
                          </li>
                        </ul>
                      </div>
                    ))}
                </div>
                <div style={{ marginLeft: "auto" }}>
                  <Button
                    buttonIcon={<TestTopAddDealerIcon />}
                    buttonBgColor={"#000000"}
                    buttonTextColor={"#ffffff"}
                    buttonBorder={"none"}
                    buttonClick={() => orderSuccessKeyPress("addReceipt")}
                  />
                </div>
              </div>
            )}

            <div className={styles.so_footer_top_right}>
              <div className={styles.so_footer_top_right_2}>
                {isNew ? (
                  <div>
                    <Button
                      buttonIcon={<SaveAsDraftIcon />}
                      buttonText={"Save as Draft"}
                      buttonBgColor={"#000000"}
                      buttonTextColor={"#ffffff"}
                      buttonBorder={"none"}
                    />
                  </div>
                ) : (
                  <div>
                    <Button
                      buttonIcon={<CancelIcon2 />}
                      buttonText={"Cancel Order"}
                      buttonBgColor={"#000000"}
                      buttonTextColor={"#ffffff"}
                      buttonBorder={"none"}
                    />
                  </div>
                )}

                {orderId && (
                  <div>
                    <Button
                      buttonIcon={<ShareIcon />}
                      buttonText={"Share"}
                      buttonBgColor={"#4164E3"}
                      buttonTextColor={"#ffffff"}
                      buttonBorder={"none"}
                      buttonClick={() => orderSuccessKeyPress("shareLink")}
                    />
                  </div>
                )}
              </div>

              <div
                className={`${styles.so_footer_top_right_1} ${
                  mode ? styles.so_footer_top_right_1_dark : ""
                }`}
              >
                <div>
                  <p>Sub Total:</p>
                  <p>
                    ₹
                    {isNew
                      ? amount === 0
                        ? "0.00"
                        : amount
                      : itemsdetails.reduce(
                          (total, item) => total + item.qty * item.price,
                          0
                        )}
                  </p>
                </div>
                <div className={styles.so_shipping}>
                  <p>Shipping Charges:</p>
                  <input
                    type="number"
                    value={
                      generalInfo.deliveryPartner === "Self"
                        ? 0
                        : generalInfo.deliveryCharge
                        ? generalInfo.deliveryCharge
                        : generalInfo.shippingCharge
                    }
                    onChange={(e) =>
                      setGeneralInfo((prevState) => ({
                        ...prevState,
                        shippingCharge: e.target.value,
                      }))
                    }
                    maxLength="10"
                    onInput={(e) =>
                      (e.target.value = e.target.value.slice(0, 10))
                    }
                    style={mode ? { color: "#fff" } : {}}
                    ref={shippingRef}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        checkboxRef.current?.focus();
                      }
                    }}
                  />
                  <div className={styles.so_footer_top_right_1_check}>
                    <p>To Pay</p>
                    <input
                      type="checkbox"
                      value={generalInfo.toPay}
                      onChange={(e) =>
                        setGeneralInfo((prevState) => ({
                          ...prevState,
                          toPay: e.target.checked,
                        }))
                      }
                      checked={generalInfo.toPay}
                      ref={checkboxRef}
                      disabled={
                        generalInfo.deliveryPartner === "Self" ? true : false
                      }
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          handleNextButtonClick();
                        }
                      }}
                    />
                  </div>
                  <p>
                    ₹
                    {generalInfo.deliveryPartner === "Self"
                      ? 0
                      : generalInfo.deliveryCharge ||
                        generalInfo.shippingCharge ||
                        "0.00"}
                  </p>
                </div>
                <div>
                  <p>Total Amount(INR):</p>
                  <p>₹{totalAmount === 0 ? "0.00" : totalAmount}</p>
                </div>
              </div>

              <div className={styles.so_footer_top_right_2}>
                {isNew ? (
                  <div>
                    {!orderId && nextBtn ? (
                      <Button
                        buttonIcon={<SaveIcon />}
                        buttonText={"Save"}
                        buttonBgColor={"#4164E3"}
                        buttonTextColor={"#FFFFFF"}
                        buttonBorder={"none"}
                        buttonClick={() => handleSubmit()}
                      />
                    ) : (
                      <Button
                        buttonIcon={<LeftArrowIcon />}
                        buttonText={"Next"}
                        buttonBgColor={"#4164E3"}
                        buttonTextColor={"#ffffff"}
                        buttonBorder={"none"}
                        buttonClick={() => handleNextButtonClick()}
                        loading={loadingBtn}
                      />
                    )}
                  </div>
                ) : (
                  <>
                    <Button
                      buttonIcon={<SaveIcon />}
                      buttonText={"Update"}
                      buttonBgColor={"#4164E3"}
                      buttonTextColor={"#ffffff"}
                      buttonBorder={"none"}
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SaleOrder;
