import React, { useEffect, useState } from 'react'
import styles from './Camp.module.css'
import TopTabBar from '../../components/updatedComponents/Tanolet/TopTabBar'
import { useAuth } from '../../components/context/Auth'
import SearchBar from '../../components/updatedComponents/FormComponents/SearchBar'
import GoToPage from '../../components/updatedComponents/Pagnation/GoToPage'
import Pagenation from '../../components/updatedComponents/Pagnation/Pagenation'
import Button from '../../components/updatedComponents/Dealers/Button'
import { Pluse } from '../../components/icons/Icon3'
import { useNavigate } from 'react-router-dom'
import TimeOutFetch from '../../APIsControll/TimeOutFechControll'
import { useToast } from '../../components/updatedComponents/Toaster/Toaster'
import Skeleton from '../../components/updatedComponents/Skeleton/Skeleton'
import NoDataFound from '../../components/updatedComponents/NoDataFound/NoDataFound'
import { TemplateNotFound } from '../../components/icons/Icon2'
import { changeDateFormat, TextColorCode, truncateText } from '../../Utils/TextUtils'
import useDebounce from '../../Utils/Debounce'
const Groups = () => {
    const { mode, managerId } = useAuth();
    const { show } = useToast();
    const navigation = useNavigate();
    const [groupData, setGroupDsts] = useState([]);
    const [page, setPage] = useState(0);
    const [pages, setPages] = useState(0);

    const [sarchKyewords, setsarchKeywords] = useState("")

    const [loading, setloading] = useState({
        loading1: false
    })

    
    const getGroups = async () => {
        setloading(prve => ({
            ...prve, loading1: true
        }))
        try {
            const payload = {
                managerId: managerId,
                keyword: sarchKyewords
            }
       
            const res = await TimeOutFetch(`wallikonCampaign/groupList`, payload);
            setloading(prve => ({
                ...prve, loading1: false
            }))
            if (res.status === "success") {
                // show(`${res.msg}`, "success");
                setGroupDsts(res?.data);
                setPages(res?.pages)
                setPage(res?.page)
                return
            }
            if (res.status === "failed") {
                setGroupDsts(res?.data);
                setPages(0)
                setPage(0)
                if (res.msg === "Group list not found!") {
                    return
                } else {
                    show(`${res.msg}`, "error")
                }
            } else {
                return show("Something went wrong", "error");

            };

        } catch (error) {
            return show("Something went wrong", "error");
        }
    }

    useDebounce(getGroups, 700, [sarchKyewords])

    // useEffect(() => {
    //     getGroups();
    // }, [managerId]);


    return (
        <div className={styles.mainCintner}>
            <div className={styles.Continer} style={{ backgroundColor: mode ? "#2C2E33" : "#F8F9FC" }}>
                <div className={styles.goBackCon}  >
                    <div className={styles.gobacFristChlid1}>
                        <div>
                            <TopTabBar text={"Groups"} />
                        </div>
                        <div style={{ width: "160px" }}>
                            <Button
                                buttonBgColor={"#4164E3"}
                                buttonText={"Create Group"}
                                buttonIcon={<Pluse />}
                                buttonTextColor={"#fff"}
                                buttonClick={() => navigation("/newgroup")}
                            // buttonClick={toggleDrawer}
                            />
                        </div>
                    </div>
                </div>
                <div className={styles.sarchBarConinnter}>
                    <div className={styles.sarchBarConinnterFristChild}>
                        <div className={styles.sarchconGrop}>
                            <div className={styles.sacrchGroup} >
                                <SearchBar placeholder={"Search Groups...."}
                                    onChange={(event) => setsarchKeywords(event.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.tableContiner}>
                    <div className={styles.container} style={{ height: "100%" }}>
                        <ul className={styles.responsiveTable} style={{ height: "100%" }}>
                            <li className={styles.tableHeader} style={mode ? { backgroundColor: "#232529", color: "#fafafa" }
                                : { backgroundColor: "#D9DCE5" }} >
                                <div className={`${styles.col} `} style={{ width: "800px" }} >
                                    <div className={styles.TableHeadChackBox} >
                                        {/* <label className={styles.cl_checkbox} >
                                            <input type="checkbox" />
                                            <span></span>
                                        </label> */}
                                    </div>
                                    Group Name </div>
                                <div className={`${styles.col}`} > <span>Description </span>  </div>
                                <div className={styles.col} >Member</div>
                                <div className={styles.col} >Creation Date</div>
                                <div className={styles.col} >Status</div>
                                <div className={styles.col} ></div>
                            </li>
                            {
                                loading?.loading1 ? <>  <Skeleton lines={8} width="100%" thickness={8} height='400px' /> </>
                                    : <>
                                        {
                                            groupData.length === 0 ? <>
                                                <div style={{ height: "90%" }}>
                                                    <NoDataFound
                                                        icon={<TemplateNotFound />}
                                                        linkname={"add a template."}
                                                        textTitle={`No Dealers found. To run a WhatsApp campaign`}
                                                        to={"/newgroup"} /> </div>
                                            </> : <>
                                                {
                                                    groupData?.map((e, i) =>
                                                        <li className={styles.tablerow}
                                                            style={mode ? { backgroundColor: "#1B1D21", color: "#fafafa" } : { backgroundColor: "#ffff" }} key={i}
                                                            onClick={() => navigation(`/newgroup/${e?.groupName}`)}
                                                        >
                                                            <div className={`${styles.col}  `} >
                                                                <div className={styles.TableHeadChackBox} >
                                                                    {/* <label className={styles.cl_checkbox} >
                                                                        <input type="checkbox" />
                                                                        <span></span>
                                                                    </label> */}
                                                                </div>
                                                                {truncateText(`${e?.groupName}`, 15)}
                                                            </div>
                                                            <div className={`${styles.col} `}  >{truncateText(`${e?.description}`, 25)}</div>
                                                            <div className={styles.col}  >{e?.member}</div>
                                                            <div className={styles.col}  > {changeDateFormat(e?.createdAt, "-")}</div>
                                                            <div className={styles.col} style={{ color: TextColorCode(e?.status) }} >{e?.status} </div>
                                                            <div className={styles.col}  >
                                                                ddd
                                                                 </div>
                                                        </li>
                                                    )
                                                }
                                            </>
                                        }
                                    </>}
                            <li style={{ height: "20px" }}></li>
                        </ul>
                    </div>
                </div>
                <div>
                </div>

                <div className={styles.pagnationConintner} style={{ backgroundColor: mode ? "#232529" : "#fff" }}>
                    <div className={styles.gotToPage1} >
                        <GoToPage currentPage={page} setCurrentPage={setPage} totalPages={pages} key={"1"} />
                    </div>
                    <div className={styles.gotToPage2}  >
                        <Pagenation currentPage={page} setCurrentPage={setPage} totalPages={pages} key={"1"} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Groups