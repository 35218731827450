import React, { useEffect, useState } from 'react'
import styles from './Camp.module.css'
import TopTabBar from '../../components/updatedComponents/Tanolet/TopTabBar'
import { useAuth } from '../../components/context/Auth'
import SearchBar from '../../components/updatedComponents/FormComponents/SearchBar'
import GoToPage from '../../components/updatedComponents/Pagnation/GoToPage'
import Pagenation from '../../components/updatedComponents/Pagnation/Pagenation'
import Button from '../../components/updatedComponents/Dealers/Button'
import { AddBtnicon, TemplateDone, TemplateNotFound } from '../../components/icons/Icon2'
import DrawerWrapper from '../../components/updatedComponents/layoutWrapper/DrawerWrapper'
import InputTag from '../../components/updatedComponents/FormComponents/InputTag'
import { Back, Pluse } from '../../components/icons/Icon3'
import SlidingFilter from '../../components/updatedComponents/Dealers/SlidingFilter'
import useWindowWidth from '../../components/updatedComponents/Hooks/WidthSize'
import { LocationsFilter } from '../../components/updatedComponents/Filter/LocationsFilter'
import TimeOutFetch from '../../APIsControll/TimeOutFechControll'
import { useToast } from '../../components/updatedComponents/Toaster/Toaster'
import Skeleton from '../../components/updatedComponents/Skeleton/Skeleton'
import NoDataFound from '../../components/updatedComponents/NoDataFound/NoDataFound'
import { TextColorCode, truncateText } from '../../Utils/TextUtils'
import { useParams } from 'react-router-dom'
import SrarchPin from '../../components/updatedComponents/FormComponents/SrarchPin'
const DealerList = () => {
    const { mode, managerId } = useAuth();
    const { show } = useToast()
    const { tempname } = useParams();
    const [isOpen, setIsOpen] = useState(false);
    const [toggelList, setToggelList] = useState("Added");
    const width = useWindowWidth();
    const [showMenu, setShowMenu] = useState(false);
    const [groupDealer, setGroupDealer] = useState([]);
    const [isOpenSelectSegment, setIsOpenSelectSegment] = useState(false);
    const [activeSubMenu, setActiveSubMenu] = useState(null);
    const [locations0, setlocations0] = useState([]);
    const [locations1, setlocations1] = useState([]);
    const [locations2, setlocations2] = useState([]);
    const [activeSubMenu1, setActiveSubMenu1] = useState(null);
    const [pin, setPin] = useState("")
    const [state, settate] = useState([]);
    const [state1, settate1] = useState([]);
    const [state2, settate2] = useState([]);
    const [sarchLocation, setSarchLocation] = useState({
        stateSearchLocation: "",
        DistricsSearchLocation: "",
        CitySearchLocation: "",
        pincodeSarch: ""
    });



    const [loading, setloading] = useState({
        loading1: false,
        loading2: false,
        loading3: false,
        loading4: false,
    });

    const [inputBoxSarch, setInputBoxSarch] = useState({
        listSarch: "",
        addedSarch: ""
    })

    const [addedData, setAddedData] = useState([]);
    const [addedPage, setAddedPage] = useState(0);
    const [addedPages, setAddedPages] = useState(0);
    const [Descpction, setDescpction] = useState("");

    const [listData, setlistData] = useState([]);
    const [listPage, setlistPage] = useState(0);
    const [listPages, setlistPages] = useState(0);

    const toggleDrawer = () => {
        setIsOpen(true);
    };


    const closeDrawer = () => {
        setIsOpen(false);
    };

    const itemList = ["Added", "List"];

    const getLoactionFilter = async () => {
        const payload = {
            managerId: managerId,
            stateSearch: sarchLocation.stateSearchLocation,
            districtSearch: sarchLocation.DistricsSearchLocation,
            citySearch: sarchLocation.CitySearchLocation,
            pincodeSearch: sarchLocation.pincodeSarch,
            locations: {
                states: state.length > 0 ? state : [],
                districts: state1.length > 0 ? state1 : [],
                cities: state2.length > 0 ? state2 : [],
                pincodes: sarchLocation.pincodeSarch ? [sarchLocation.pincodeSarch] : []
            }
        };
        try {
            const res = await TimeOutFetch(`wallikonCampaign/filterLocation`, payload);
            // setlocations0(prev => [...prev, ...res?.data?.states]);
            setlocations0(res?.data?.states);
            setlocations1(res?.data?.districts);
            setlocations2(res?.data?.cities);
        } catch (error) {
            console.log(error);
        }
    };

    const getLoactionFilterRset = async (click) => {
        const payload = {
            managerId: managerId,
            stateSearch: sarchLocation.stateSearchLocation,
            districtSearch: sarchLocation.DistricsSearchLocation,
            citySearch: sarchLocation.CitySearchLocation,
            pincodeSearch: sarchLocation.pincodeSarch,
            locations: {
                states: click === "State" ? [] : state,
                districts: click === "District" ? [] : state1,
                cities: click === "City" ? [] : state2,
                pincodes: sarchLocation.pincodeSarch ? [sarchLocation.pincodeSarch] : []
            }
        };
        try {
            const res = await TimeOutFetch(`wallikonCampaign/filterLocation`, payload);
            // setlocations0(prev => [...prev, ...res?.data?.states]);
            getDealserreset(click)
            setlocations0(res?.data?.states);
            setlocations1(res?.data?.districts);
            setlocations2(res?.data?.cities);
        } catch (error) {
            console.log(error);
        }
    };

    const getDealser = async () => {
        try {
            setloading(prve => ({
                ...prve, loading2: true
            }));
            const payload = {
                managerId: managerId,
                keyword: inputBoxSarch.listSarch,
                status: "",
                locations: {
                    states: state.length > 0 ? state : [],
                    districts: state1.length > 0 ? state1 : [],
                    cities: state2.length > 0 ? state2 : [],
                    pincodes: pin === "" ? [] : [pin]
                }
            };
            const res = await TimeOutFetch(`wallikonCampaign/findGroupDealers?page=${listPage}&limit=${28}`, payload);
            setloading(prve => ({
                ...prve, loading2: false
            }));

            if (res.status === "success") {
                // const filterData = res.data.filter(data => data.dealerId !== addedData[0]?.dealerInfo?.dealerId)
                setlistData(res?.data);
                // setlistData(filterData);
                setlistPage(res?.page);
                setlistPages(res?.pages);
                setloading(prve => ({
                    ...prve, loading2: false
                }))
                return
            }
            if (res.status === "failed") {
                setlistData(res?.data);
                setloading(prve => ({
                    ...prve, loading2: false
                }))
                return
            } else {
                setloading(prve => ({
                    ...prve, loading2: false
                }))
                return show("Something went wrong", "error");
            };

        } catch (error) {
            console.log(error);
            setloading(prve => ({
                ...prve, loading2: false
            }))
        }
    }
    const getDealserreset = async (click) => {
        try {
            setloading(prve => ({
                ...prve, loading2: true
            }));
            const payload = {
                managerId: managerId,
                keyword: inputBoxSarch.listSarch,
                status: "",
                locations: {
                    states: click === "State" ? [] : state,
                    districts: click === "District" ? [] : state1,
                    cities: click === "City" ? [] : state2,
                    pincodes: []
                }
            };
            const res = await TimeOutFetch(`wallikonCampaign/findGroupDealers?page=${listPage}&limit=${28}`, payload);
            setloading(prve => ({
                ...prve, loading2: false
            }));

            if (res.status === "success") {
                // const filterData = res.data.filter(data => data.dealerId !== addedData[0]?.dealerInfo?.dealerId)
                setlistData(res?.data);
                // setlistData(filterData);
                setlistPage(res?.page);
                setlistPages(res?.pages);
                setloading(prve => ({
                    ...prve, loading2: false
                }))
                return
            }
            if (res.status === "failed") {
                setlistData(res?.data);
                setloading(prve => ({
                    ...prve, loading2: false
                }))
                return
            } else {
                setloading(prve => ({
                    ...prve, loading2: false
                }))
                return show("Something went wrong", "error");
            };

        } catch (error) {
            setloading(prve => ({
                ...prve, loading2: false
            }))
        }
    }

    useEffect(() => {
        getLoactionFilter();
    }, []);


    const getSingleGroup = async () => {
        try {
            setloading(prve => ({
                ...prve, loading3: true
            }))
            const payload = {
                managerId: managerId,
                groupName: tempname,
                keyword: inputBoxSarch.addedSarch,
                status: "",
                locations: {
                    states: state.length > 0 ? state : [],
                    districts: state1.length > 0 ? state1 : [],
                    cities: state2.length > 0 ? state2 : [],
                    pincodes: pin === "" ? [] : [pin]
                }
            }
            const res = await TimeOutFetch(`wallikonCampaign/getSingleGroup`, payload);
            setloading(prve => ({
                ...prve, loading3: false
            }))
            if (res.status === "success") {
                setAddedData(res?.data);
                setAddedPage(res?.page)
                setAddedPages(res?.pages)
                setloading(prve => ({
                    ...prve, loading3: false
                }))
                return
            }
            if (res.status === "failed") {
                setAddedData(res?.data);
                setloading(prve => ({
                    ...prve, loading3: false
                }))
                return
            } else {
                setloading(prve => ({
                    ...prve, loading3: false
                }))
                return show("Something went wrong", "error");
            };
        } catch (error) {
            return show("Something went wrong", "error");
        }
    }

    // console.log(groupDealer);


    useEffect(() => {
        getSingleGroup();
    }, [addedPage, inputBoxSarch.addedSarch,]);
    useEffect(() => {
        if (pin.length === 6) {
            getSingleGroup();
        }
        if (pin.length === 0) {
            getSingleGroup();
        }

    }, [pin,]);
    useEffect(() => {
        if (pin.length === 6) {
            getDealser();
        }
        if (pin.length === 0) {
            getDealser();
        }
    }, [pin]);

    useEffect(() => {
        // Ensure it always triggers when listPage or inputBoxSarch.listSarch changes
        getDealser();
    }, [listPage, inputBoxSarch.listSarch]);


    const handelChack = (event, item) => {
        const { dealerId } = item;
        if (event.target.checked === true) {
            setGroupDealer(prevGroupDealer => {
                const haveExect = prevGroupDealer.some(dealers => dealers.dealerId === dealerId);
                if (!haveExect) {
                    return [...prevGroupDealer, item]; // Add item to the groupDealer
                }
                return prevGroupDealer;
            });
        } else {
            setGroupDealer(prevGroupDealer => {
                // Remove the dealerId from groupDealer if unchecked
                return prevGroupDealer.filter(dealers => dealers.dealerId !== dealerId);
            });
        }
    };



    const CreateGroup = async (e) => {
        e.preventDefault()
        try {
            if (groupDealer.length <= 0) return show("Please select dealers", "error");
            const payload = {
                managerId: managerId,
                groupName: tempname,
                description: Descpction === "" ? null : Descpction,
                dealerInfo: groupDealer
            };

            setloading(prve => ({
                ...prve, loading4: true
            }))
            const res = await TimeOutFetch(`wallikonCampaign/createGroup`, payload);
            setloading(prve => ({
                ...prve, loading4: false
            }))
            if (res.status === "success") {
                closeDrawer();
                getSingleGroup();
                show(`${res.msg}`, "success");
                return
            }
            if (res.status === "failed") {
                return show(`${res.msg}`, "error")
            } else {
                return show("Something went wrong", "error");

            };
        } catch (error) {
            setloading(prve => ({
                ...prve, loading4: false
            }))
            return show("Something went wrong", "error");
        }
    }

    return (
        <div className={styles.mainCintner}>
            <div className={styles.Continer} style={{ backgroundColor: mode ? "#2C2E33" : "#F8F9FC" }}>
                <DrawerWrapper isOpen={isOpen} onClose={closeDrawer} >
                    <div className={mode ? styles.DrawerWrapperContinetr : styles.DrawerWrapperContinetr_light} >
                        <div className={styles.DrawerWrapperText} >
                            <span>Selected: {groupDealer.length}</span>
                        </div>
                        <div className={styles.DrawerWrapperMainCointner} >
                            <div className={styles.DrawerWrapperInputConintner1} >
                                {/* <div style={{ width: "40%" }} >
                                    <InputTag
                                        placeholderText={"New Group..."}
                                        labelText={"Group Name"}
                                        placeholderType={"text"}
                                    />
                                </div> */}
                                <div style={{ width: "40%" }} >
                                    <InputTag
                                        placeholderText={"Lucknow Trader..."}
                                        labelText={"Description"}
                                        placeholderType={"text"}
                                        onChange={(e) => setDescpction(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className={styles.DrawerWrapperInputConintner} >
                                <div style={{ width: "20%" }} >
                                    <Button
                                        buttonBgColor={"#000000"}
                                        buttonIcon={<Back />}
                                        buttonTextColor={"#fff"}
                                        buttonText={"Back"}
                                        buttonClick={closeDrawer}
                                    />
                                </div>
                                <div style={{ width: "20%" }}>
                                    <Button
                                        buttonBgColor={"#4164E3"}
                                        buttonIcon={<TemplateDone />}
                                        buttonTextColor={"#fff"}
                                        buttonText={"Create"}
                                        buttonClick={CreateGroup}
                                        loading={loading.loading4}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </DrawerWrapper>

                <div className={styles.goBackCon}  >
                    <div className={styles.gobacFristChlid1}>
                        <div>
                            <TopTabBar text={"Campaigns"} />

                        </div>
                        <div style={{ width: "160px" }}>
                            {
                                toggelList === "List" &&
                                <Button
                                    buttonBgColor={"#4164E3"}
                                    buttonText={"Add Member"}
                                    // buttonIcon={<CreateTem />}
                                    buttonIcon={<Pluse />}
                                    buttonTextColor={"#fff"}
                                    buttonClick={toggleDrawer}
                                />
                            }
                        </div>
                    </div>
                </div>
                <div className={styles.sarchBarConinnter}>
                    <div className={styles.sarchBarConinnterFristChild}>
                        <div className={styles.sarchconGrop}>
                            <div className={styles.sacrchGroup} >
                                {toggelList === "List" && <SearchBar placeholder={"Search"} onChange={(event) => setInputBoxSarch((parv) => ({
                                    ...parv, listSarch: event.target.value
                                }))} />}
                                {toggelList === "Added" && <SearchBar placeholder={"Search"} onChange={(event) => setInputBoxSarch((parv) => ({
                                    ...parv, addedSarch: event.target.value
                                }))} />}

                            </div>
                            <div className={styles.sacrchGroup} style={{ marginLeft: "20px" }} >
                                <SlidingFilter itemList={itemList} setFilter={setToggelList} />
                            </div>
                            <div className={styles.groupFilter}>
                                {
                                    width > 700 ? <>
                                        <div className={styles.iconWrapper0}
                                            onMouseEnter={() => setShowMenu(true)}
                                            onMouseLeave={() => {
                                                setShowMenu(false);
                                            }}
                                            style={{ color: mode ? "white" : "black" }}
                                        >
                                            <div>
                                                <span style={{ fontSize: "12px", fontWeight: "500", color: mode ? "#fff" : "#000" }} > Location </span>
                                            </div>
                                            <div className={styles.Who_Select3} style={{
                                                background: mode ? "#1B1D21" : "#fff",
                                                border: mode ? "none" : "1px solid #E4E7EB",
                                            }}
                                                onMouseEnter={() => setIsOpenSelectSegment(true)}
                                                onMouseLeave={() => setIsOpenSelectSegment(false)}
                                            // onClick={()=>setIsOpenSelectSegment(!isOpenSelectSegment)}
                                            >
                                                <div>
                                                    <span style={{ fontSize: "14px" }}> select</span>
                                                </div>
                                                <div>
                                                    <span>
                                                        <AddBtnicon mode={mode} rotate={isOpenSelectSegment ? 180 : 0} />
                                                    </span>
                                                </div>

                                            </div>
                                            {
                                                showMenu &&
                                                <div className={styles.mainMenu0}
                                                    style={{ backgroundColor: mode ? "#000" : "#fff", color: mode ? "#fff" : "#000" }}
                                                >
                                                    <div className={mode ? styles.menuItem_dark0 : styles.menuItem0}
                                                        onMouseEnter={() => setActiveSubMenu('category')}
                                                        onMouseLeave={() => setActiveSubMenu(null)}
                                                    >
                                                        <LocationsFilter
                                                            title={"State"}
                                                            locations={locations0}
                                                            /* loading={true}  */
                                                            mode={true}
                                                            setSelectedLocations={settate}
                                                            selectedLocations={state}
                                                            sareshOnchange={(value) => setSarchLocation({ stateSearchLocation: value })}
                                                            sarchValue={sarchLocation.stateSearchLocation}
                                                            ApplyOnclick={() => {
                                                                getLoactionFilter();
                                                                getDealser();
                                                            }}
                                                            ResetOnclick={() => {
                                                                settate([]); // Clear selected locations
                                                                getLoactionFilterRset("State")
                                                            }}

                                                        />
                                                        {activeSubMenu === 'category' && (
                                                            <div className={styles.mainMenu0}
                                                                style={{ backgroundColor: mode ? "#000" : "#fff", color: mode ? "#fff" : "#000", right: "100%" }}
                                                            >
                                                                <div className={mode ? styles.menuItem_dark0 : styles.menuItem0}
                                                                    onMouseEnter={() => setActiveSubMenu1("toggle")}
                                                                    onMouseLeave={() => setActiveSubMenu1(null)}
                                                                >
                                                                    <LocationsFilter
                                                                        title={"District"}
                                                                        locations={locations1}
                                                                        setSelectedLocations={settate1}
                                                                        /* loading={true}  */
                                                                        selectedLocations={state1}
                                                                        sareshOnchange={(value) => setSarchLocation({ DistricsSearchLocation: value })}
                                                                        sarchValue={sarchLocation.DistricsSearchLocation}
                                                                        ApplyOnclick={() => {
                                                                            getLoactionFilter();
                                                                            getDealser();
                                                                        }}
                                                                        ResetOnclick={() => {
                                                                            settate1([]);
                                                                            getLoactionFilterRset("District")

                                                                        }}
                                                                    />
                                                                    {
                                                                        activeSubMenu1 === "toggle" && (
                                                                            <div className={styles.mainMenu0}
                                                                                style={{ backgroundColor: mode ? "#000" : "#fff", color: mode ? "#fff" : "#000", right: "100%" }}
                                                                            >
                                                                                <div className={mode ? styles.menuItem_dark0 : styles.menuItem0} >
                                                                                    <LocationsFilter
                                                                                        title={"City"}
                                                                                        locations={locations2}
                                                                                        setSelectedLocations={settate2}
                                                                                        selectedLocations={state2}
                                                                                        sareshOnchange={(value) => setSarchLocation({ CitySearchLocation: value })}
                                                                                        sarchValue={sarchLocation.CitySearchLocation}
                                                                                        ApplyOnclick={() => {
                                                                                            getLoactionFilter();
                                                                                            getDealser();
                                                                                        }}
                                                                                        ResetOnclick={() => {
                                                                                            settate2([]);
                                                                                            getLoactionFilterRset("City")
                                                                                        }}
                                                                                    />

                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    }
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                        <div className={styles.cont} style={{ color: mode ? "white" : "black" }} >
                                            <div>
                                                <span style={{ fontSize: "12px", fontWeight: "500", color: mode ? "#fff" : "#000" }} > Pin  </span>
                                            </div>
                                            <div style={{ fontSize: "12px", fontWeight: "400" }}>
                                                <SrarchPin
                                                    placeholder={"Sarch"}
                                                    type={"number"}
                                                    maxLength={6}
                                                    onChange={(event) => setPin(event)}
                                                />

                                            </div>
                                        </div>
                                    </> : null
                                }
                            </div>
                        </div>
                    </div>
                </div>
                {
                    toggelList === "List" &&
                    <div className={styles.tableContiner}>
                        <div className={styles.container} style={{ height: "100%" }}>
                            <ul className={styles.responsiveTable} style={{ height: "100%" }}>
                                <li className={styles.tableHeader} style={mode ? { backgroundColor: "#232529", color: "#fafafa" }
                                    : { backgroundColor: "#D9DCE5" }} >
                                    <div className={`${styles.col} `} style={{ width: "800px" }} >
                                        <div className={styles.TableHeadChackBox} >
                                            {/* <label className={styles.cl_checkbox} >
                                                <input type="checkbox" />
                                                <span></span>
                                            </label> */}
                                        </div>
                                        Dealer Name </div>
                                    <div className={`${styles.col} ${styles.large}`} > <span>Address </span>  </div>
                                    <div className={styles.col} >State</div>
                                    <div className={styles.col} >District</div>
                                    <div className={styles.col} >City</div>
                                    <div className={styles.col} >PIN Code</div>
                                    <div className={styles.col} >Status</div>
                                </li>
                                {
                                    loading?.loading2 ? <Skeleton lines={8} width="100%" thickness={8} height='400px' /> :
                                        <> {
                                            listData?.length === 0 ? <div style={{ height: "90%" }}>
                                                <NoDataFound
                                                    icon={<TemplateNotFound />}
                                                    linkname={"add a template."}
                                                    textTitle={`No Dealers found. To run a WhatsApp campaign`}
                                                    to={"/newgroup"} />
                                            </div> : <>
                                                {
                                                    listData.map((e, i) =>
                                                        <li className={styles.tablerow} style={mode ? { backgroundColor: "#1B1D21", color: "#fafafa" } : { backgroundColor: "#ffff" }} key={i} >
                                                            <div className={`${styles.col} `} >
                                                                <div className={styles.TableHeadChackBox} >
                                                                    <label className={styles.cl_checkbox} >
                                                                        <input type="checkbox" onChange={(event) => handelChack(event, e)}
                                                                            checked={groupDealer.some(dealer => dealer.dealerId === e.dealerId)}
                                                                        />
                                                                        <span></span>
                                                                    </label>
                                                                </div>
                                                                {truncateText(`${e?.dealerName}`, 20)}
                                                            </div>
                                                            <div className={`${styles.col} ${styles.large}`}  > {truncateText(`${e?.address}`, 27)} </div>
                                                            <div className={styles.col}  > {truncateText(`${e?.state}`, 20)}</div>
                                                            <div className={styles.col}  >{truncateText(`${e?.district}`, 20)}</div>
                                                            <div className={styles.col}  >{truncateText(`${e?.city}`, 20)}</div>
                                                            <div className={styles.col}  >{truncateText(`${e?.pincode}`, 20)}</div>
                                                            <div className={styles.col} style={{ color: TextColorCode(e?.status) }} >  {truncateText(`${e?.status}`, 20)}  </div>
                                                        </li>
                                                    )
                                                }
                                            </>
                                        }
                                        </>
                                }
                                <li style={{ height: "20px" }}></li>
                            </ul>
                        </div>
                    </div>
                }

                {
                    toggelList === "List" &&
                    <div className={styles.pagnationConintner} style={{ backgroundColor: mode ? "#232529" : "#fff" }}>
                        <div className={styles.gotToPage1} >
                            <GoToPage currentPage={listPage} setCurrentPage={setlistPage} totalPages={listPages} key={"1"} />
                        </div>
                        <div className={styles.gotToPage2}  >
                            <Pagenation currentPage={listPage} setCurrentPage={setlistPage} totalPages={listPages} key={"1"} />
                        </div>
                    </div>
                }
                {
                    toggelList === "Added" &&
                    <div className={styles.tableContiner}>
                        <div className={styles.container} style={{ height: "100%" }}>
                            <ul className={styles.responsiveTable} style={{ height: "100%" }}>
                                <li className={styles.tableHeader} style={mode ? { backgroundColor: "#232529", color: "#fafafa" }
                                    : { backgroundColor: "#D9DCE5" }} >
                                    <div className={`${styles.col} `} style={{ width: "800px" }} >
                                        <div className={styles.TableHeadChackBox} >
                                            {/* <label className={styles.cl_checkbox} >
                                                <input type="checkbox" />
                                                <span></span>
                                            </label> */}
                                        </div>
                                        Dealer Name </div>
                                    <div className={`${styles.col} ${styles.large}`} > <span>Address </span>  </div>
                                    <div className={styles.col} >State</div>
                                    <div className={styles.col} >District</div>
                                    <div className={styles.col} >City</div>
                                    <div className={styles.col} >PIN Code</div>
                                    <div className={styles.col} >Status</div>
                                </li>
                                {
                                    addedData?.length === 0 ? <> {loading?.loading1 ? <Skeleton lines={8} width="100%" thickness={8} height='400px' /> : <>
                                        <div style={{ height: "90%" }}>
                                            <NoDataFound
                                                icon={<TemplateNotFound />}
                                                linkname={"add a template."}
                                                textTitle={`No Dealers found. To run a WhatsApp campaign`}
                                                to={"/newgroup"} /> </div>
                                    </>}  </> :
                                        addedData[0]?.dealerInfo?.map((e, i) =>
                                            <li className={styles.tablerow} style={mode ? { backgroundColor: "#1B1D21", color: "#fafafa" } : { backgroundColor: "#ffff" }} key={i} >
                                                <div className={`${styles.col} `} >
                                                    <div className={styles.TableHeadChackBox} >
                                                        {/* <label className={styles.cl_checkbox} >
                                                        <input type="checkbox" />
                                                        <span></span>
                                                    </label> */}
                                                    </div>
                                                    {truncateText(`${e?.dealerName}`, 20)}
                                                </div>
                                                <div className={`${styles.col} ${styles.large}`}  > {truncateText(`${e?.address}`, 27)}  </div>
                                                <div className={styles.col}  > {truncateText(`${e?.state}`, 20)} </div>
                                                <div className={styles.col}  > {truncateText(`${e?.district}`, 20)} </div>
                                                <div className={styles.col}  > {truncateText(`${e?.city}`, 20)} </div>
                                                <div className={styles.col}  > {truncateText(`${e?.pincode}`, 20)} </div>
                                                <div className={styles.col} style={{ color: TextColorCode(e?.status) }} > {truncateText(`${e?.status}`, 20)} </div>
                                            </li>
                                        )
                                }
                                <li style={{ height: "20px" }}></li>
                            </ul>
                        </div>
                    </div>
                }

                {
                    toggelList === "Added" &&
                    <div className={styles.pagnationConintner} style={{ backgroundColor: mode ? "#232529" : "#fff" }}>
                        <div className={styles.gotToPage1} >
                            <GoToPage currentPage={addedPage} setCurrentPage={setAddedPage} totalPages={addedPages} key={"1"} />
                        </div>
                        <div className={styles.gotToPage2}  >
                            <Pagenation currentPage={addedPage} setCurrentPage={setAddedPage} totalPages={addedPages} key={"1"} />
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}

export default DealerList