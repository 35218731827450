// import React, { useState } from 'react';
// import Navbar from './Navbar';
// import { Outlet } from 'react-router-dom';
// import AdminMenu from '../../../leftMeuns/AdminMenu';
// import styles from "./Navbar.module.css";
// import { useAuth } from '../../context/Auth';
// import { SideBarArrowLeft, SideBarArrowRight } from '../../icons/Icon';
// import useWindowWidth from '../Hooks/WidthSize';

// const NavLayout = () => {
//     const [isClosed, setIsClosed] = useState(false);
//     const { mode } = useAuth();
//     const width =  useWindowWidth();

//     console.log(width);
//     if (width < 1366) {
//         setIsClosed(true)
//     }else{
//         setIsClosed(false)
//     }

//     const contentWidth = isClosed ? "calc(100% - 100px)" : "calc(100% - 230px)";

//     return (
//         <div style={{ display: "flex", maxWidth: "1920px", width: "100%" }}>
//             <div  className={ isClosed ?  styles.con : styles.con1 }>
//                 <div
//                     className={
//                         isClosed
//                             ? `${styles.leftMenu} ${styles.leftMenuToggled}`
//                             : styles.leftMenu
//                     }
//                     style={mode ? { backgroundColor: "#2C2E33" } : {}}
//                 >
//                     <button
//                         className={styles.toggle_Btn}
//                         onClick={() => setIsClosed(!isClosed)}
//                     >
//                         {isClosed ? <SideBarArrowLeft /> : <SideBarArrowRight />}
//                     </button>

//                     <AdminMenu isClosed={isClosed} />
//                 </div>
//             </div>
//             <div
//                 style={{
//                     width: contentWidth,
//                     transition: 'width 0.3s ease',
//                     display: 'flex',
//                     flexDirection: 'column',
//                     flex: 1
//                 }}
//             >
//                 <Navbar isTogle={isClosed} />
//                 <main>
//                     <Outlet />
//                 </main>
//             </div>
//         </div>
//     );
// };

// export default NavLayout;

// import React, { useState, useEffect } from 'react';
// import Navbar from './Navbar';
// import { Outlet } from 'react-router-dom';
// import AdminMenu from '../../../leftMeuns/AdminMenu';
// import styles from "./Navbar.module.css";
// import { useAuth } from '../../context/Auth';
// import { SideBarArrowLeft, SideBarArrowRight } from '../../icons/Icon';
// import useWindowWidth from '../Hooks/WidthSize';

// const NavLayout = () => {
//     const [isClosed, setIsClosed] = useState(false);
//     const { mode } = useAuth();
//     const width = useWindowWidth();

//     useEffect(() => {
//         if (width < 1366) {
//             setIsClosed(true);
//         } else {
//             setIsClosed(false);
//         }
//     }, [width]); // Dependency array includes `width` so effect runs when `width` changes

//     const contentWidth = isClosed ? "calc(100% - 100px)" : "calc(100% - 230px)";

//     // console.log("hiii   =>" + contentWidth);

//     return (
//         <div style={{ display: "flex", maxWidth: "1920px", width: "100%" }}>
//             <div className={isClosed ? styles.con : styles.con1}  >
//                 <div
//                     className={
//                         isClosed
//                             ? `${styles.leftMenu} ${styles.leftMenuToggled}`
//                             : styles.leftMenu
//                     }
//                     style={mode ? { backgroundColor: "#2C2E33" } : {}}
//                 >
//                     <button
//                         className={styles.toggle_Btn}
//                         onClick={() => setIsClosed(!isClosed)}
//                     >
//                         {isClosed ? <SideBarArrowLeft /> : <SideBarArrowRight />}
//                     </button>

//                     <AdminMenu isClosed={isClosed} />
//                 </div>
//             </div>
//             <div
//                 style={{
//                     width: contentWidth,
//                     transition: 'width 0.3s ease',
//                     display: 'flex',
//                     flexDirection: 'column',
//                     flex: 1

//                 }}
//             >
//                 <Navbar isTogle={isClosed} />
//                 <main>
//                     <Outlet />
//                 </main>
//             </div>
//         </div>
//     );
// };

// export default NavLayout;

import React, { useState, useEffect } from "react";
import Navbar from "./Navbar";
import { Outlet } from "react-router-dom";
import AdminMenu from "../../../leftMeuns/AdminMenu";
import styles from "./Navbar.module.css";
import { useAuth } from "../../context/Auth";
import {
  SideBarAccountIcon,
  SideBarApplicationIcon,
  SideBarArrowLeft,
  SideBarArrowRight,
  SideBarComplaintsIcon,
  SideBarDashBoardIcon,
  SideBarDealersIcon,
  SideBarDeliveryIcon,
  SideBarDispatchIcon,
  SideBarEditDeleteIcon,
  SideBarGreetingIcon,
  SideBarInventoryIcon,
  SideBarInvoiceIcon,
  SideBarJobsIcon,
  SideBarManagerIcon,
  SideBarMarketingIcon,
  SideBarNotificationIcon,
  SideBarOrdersIcon,
  SideBarOutsideInventoryIcon,
  SideBarPackingOrderIcon,
  SideBarProductIcon,
  SideBarReceiptIcon,
  SideBarTemplateIcon,
  SideBarWareHousetIcon,
} from "../../icons/Icon";
import useWindowWidth from "../Hooks/WidthSize";

const NavLayout = () => {
  const [isClosed, setIsClosed] = useState(true);
  const { mode } = useAuth();
  const width = useWindowWidth();

  useEffect(() => {
    if (width < 1366) {
      setIsClosed(true);
    } else {
      setIsClosed(false);
    }
  }, [width]); // Dependency array includes `width` so effect runs when `width` c hanges

  // Calculate the width of the sidebar dynamically
  const sidebarWidth = isClosed ? "80px" : "190px";
  const contentWidth = `calc(100% - ${sidebarWidth})`;
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };
  const chacktoggleDrawer = () => {
    if (isDrawerOpen) {
      toggleDrawer();
    }
  };
  const handelClose = () => {
    if (width < 1250) {
      return;
    } else {
      setIsClosed(!isClosed);
    }
  };

  const menuItems = [
    { label: "DASHBOARD", path: "/dashboard", Icon: SideBarDashBoardIcon },
    { label: "DEALERS", path: "/dealers", Icon: SideBarDealersIcon },
    { label: "ORDERS", path: "/orders", Icon: SideBarOrdersIcon },
    { label: "RECIPT", path: "/recipts", Icon: SideBarReceiptIcon },
    {
      label: "DISPATCH",
      path: "/dispatchdashboard",
      Icon: SideBarDispatchIcon,
    },
    {
      label: "PACKING ORDER",
      path: "/packingorder",
      Icon: SideBarPackingOrderIcon,
    },
    { label: "INVENTORY", path: "/inventory", Icon: SideBarInventoryIcon },
    // {
    //   label: "OUTSIDE INVENTORY",
    //   path: "/outsideinventory",
    //   Icon: SideBarOutsideInventoryIcon,
    // },
    { label: "INVOICE", path: "/invoices", Icon: SideBarInvoiceIcon },
    // { label: "TEMPLATE", path: "/templates", Icon: SideBarTemplateIcon },
    { label: "CAMPAIGN", path: "/campaign", Icon: SideBarTemplateIcon },
    { label: "COMPLAINTS", path: "/complaints", Icon: SideBarComplaintsIcon },
    { label: "PRODUCTS", path: "/products", Icon: SideBarProductIcon },
    { label: "MARKETING", path: "/marketing", Icon: SideBarMarketingIcon },
    { label: "MANAGER", path: "/managers", Icon: SideBarManagerIcon },
    { label: "WAREHOUSE", path: "/warehouses", Icon: SideBarWareHousetIcon },
    { label: "JOBS", path: "/jobs", Icon: SideBarJobsIcon },
    { label: "APPLICATION", path: "/applicants", Icon: SideBarApplicationIcon },
    { label: "DELIVERY", path: "/delivery", Icon: SideBarDeliveryIcon },
    { label: "GREETING", path: "/greetings", Icon: SideBarGreetingIcon },
    {
      label: "NOTIFICATION",
      path: "/createnotification",
      Icon: SideBarNotificationIcon,
    },
    {
      label: "DELETE / EDIT",
      path: "/deleteoredit",
      Icon: SideBarEditDeleteIcon,
    },
    { label: "ACCOUNT", path: "/accounts", Icon: SideBarAccountIcon },
  ];

  return (
    <div
      style={{
        display: "flex",
        maxWidth: "1920px",
        width: "100%",
        height: "100vh",
      }}
    >
      {width < 700 ? null : (
        <div
          className={isClosed ? styles.con : styles.con1}
          style={{ width: sidebarWidth, transition: "width 0.3s ease" }}
        >
          <div
            className={
              isClosed
                ? `${styles.leftMenu} ${styles.leftMenuToggled}`
                : styles.leftMenu
            }
            style={mode ? { backgroundColor: "#2C2E33" } : {}}
          >
            {/* {
              width < 1250 ? null :
                <button
                  className={styles.toggle_Btn}
                  onClick={handelClose}
                  style={{zIndex:"999"}}
                >
                  {isClosed ? <SideBarArrowLeft /> : <SideBarArrowRight />}
                </button>
            } */}

            <AdminMenu
              isClosed={isClosed}
              menuItems={menuItems}
              onClick={handelClose}
            />
          </div>
        </div>
      )}
      <div
        style={{
          width: contentWidth,
          transition: "width 0.3s ease",
          display: "flex",
          flexDirection: "column",
          height: "100vh",
          flex: 1,
        }}
      >
        <Navbar
          isTogle={isClosed}
          isDrawerOpen={isDrawerOpen}
          toggleDrawer={toggleDrawer}
          menuItems={menuItems}
        />
        {
          width  <  700 ? 
        <main onClick={chacktoggleDrawer} className={styles.mdSccreen}>
          <Outlet />
        </main> :

        <main className={styles.lg_screen}>
          <Outlet />
        </main>
        }
      </div>
    </div>
  );
};

export default NavLayout;
