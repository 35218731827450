// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, " .TabSwitcher_tabs__huLjW {\n    display: flex;\n    flex-direction: column;\n    width: 100%;\n    margin: 0 auto;\n  }\n  \n  .TabSwitcher_tabsList__lDkGJ {\n    width: 100%;\n    display: flex;\n    gap: 8px;\n  }\n  \n  .TabSwitcher_tabsTrigger__5ZgUz {\n    border: none;\n    cursor: pointer;\n    width: 100%;\n    transition: all 0.2s ease-in-out;\n    padding: 10px;\n    font-size: 12px;\n  }\n  \n  \n  .TabSwitcher_tabsTrigger__5ZgUz.TabSwitcher_active__N97dQ {\n    background-color: #000000;\n    color: white;\n  }\n  \n  .TabSwitcher_tabsTrigger__5ZgUz.TabSwitcher_active1__vvR0Y {\n    background-color: #14181f;\n    color: white;\n  }\n  .TabSwitcher_tabsTrigger__5ZgUz {\n    border: none;\n    cursor: pointer;\n    width: 100%;\n    transition: all 0.2s ease-in-out;\n}\n  \n \n\n\n  ", "",{"version":3,"sources":["webpack://./src/components/updatedComponents/TabSwitcher/TabSwitcher.module.css"],"names":[],"mappings":"CAAC;IACG,aAAa;IACb,sBAAsB;IACtB,WAAW;IACX,cAAc;EAChB;;EAEA;IACE,WAAW;IACX,aAAa;IACb,QAAQ;EACV;;EAEA;IACE,YAAY;IACZ,eAAe;IACf,WAAW;IACX,gCAAgC;IAChC,aAAa;IACb,eAAe;EACjB;;;EAGA;IACE,yBAAyB;IACzB,YAAY;EACd;;EAEA;IACE,yBAAyB;IACzB,YAAY;EACd;EACA;IACE,YAAY;IACZ,eAAe;IACf,WAAW;IACX,gCAAgC;AACpC","sourcesContent":[" .tabs {\n    display: flex;\n    flex-direction: column;\n    width: 100%;\n    margin: 0 auto;\n  }\n  \n  .tabsList {\n    width: 100%;\n    display: flex;\n    gap: 8px;\n  }\n  \n  .tabsTrigger {\n    border: none;\n    cursor: pointer;\n    width: 100%;\n    transition: all 0.2s ease-in-out;\n    padding: 10px;\n    font-size: 12px;\n  }\n  \n  \n  .tabsTrigger.active {\n    background-color: #000000;\n    color: white;\n  }\n  \n  .tabsTrigger.active1 {\n    background-color: #14181f;\n    color: white;\n  }\n  .tabsTrigger {\n    border: none;\n    cursor: pointer;\n    width: 100%;\n    transition: all 0.2s ease-in-out;\n}\n  \n \n\n\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabs": "TabSwitcher_tabs__huLjW",
	"tabsList": "TabSwitcher_tabsList__lDkGJ",
	"tabsTrigger": "TabSwitcher_tabsTrigger__5ZgUz",
	"active": "TabSwitcher_active__N97dQ",
	"active1": "TabSwitcher_active1__vvR0Y"
};
export default ___CSS_LOADER_EXPORT___;
