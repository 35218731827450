import { useAuth } from "../../context/Auth";
import { useNavigate, useParams } from "react-router-dom";
import {
  AddDesignationIcon,
  ManagerCameraIcon,
  // ManagerDeleteIcon,
  ManagerTickIcon,
  CancelIcon2,
  NewManagerCreateIcon,
  RemoveTabIcon,
  TestTopBackArrowIcon,
  TestTopDotIcon,
} from "../../icons/Icon";
import { useEffect, useRef, useState } from "react";
import styles from "./NewManager.module.css";
import states from "../../../components/context/IndianState.json";
import fetchData from "../../../APIsControll/apiControll";

import InputTag from "../FormComponents/InputTag";
import ToggleBtn from "../FormComponents/ToggleBtn";
import Button from "../Dealers/Button";
import SlidingFilter from "../Dealers/SlidingFilter";
import RadioButtonGroup from "../FormComponents/RadioButtonGroup";
import ModalWrapper from "../layoutWrapper/ModalWrapper";
import ManagerDevicesTable from "./ManagerDevicesTable";
import ManagerModal from "./ManagerModal";
import VerticalDropDown from "../FormComponents/VerticalDropDown";
import AddDesignation from "../FormComponents/AddDesignation";
import Spinner from "../FormComponents/Spinner";

const NewManager = () => {
  const { mode, managerId } = useAuth();
  const { manager_Id } = useParams();
  const isEdit = Boolean(manager_Id);
  const navigate = useNavigate();

  const [activeToggles, setActiveToggles] = useState({
    Web: true,
    App: false,
  });

  const [remainingRoles, setRemainingRoles] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState([]);

  const [remainingWarehouses, setRemainingWarehouses] = useState();
  const [selectedWarehouses, setSelectedWarehouses] = useState([]);

  const [selectedStatus, setSelectedStatus] = useState(null);

  const [name, setName] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [loginID, setLoginID] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isDealerAssigned, setIsDealerAssigned] = useState("");
  const [selectedStates, setSelectedStates] = useState([]);
  const [dispatcher, setDispatcher] = useState();

  const [modalContent, setModalContent] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [loading, setLoading] = useState(false);
  const [previewImage, setPreviewImage] = useState(
    "/assets/updatedAssets/manager_img.png"
  );

  const handleOpenModal = (content) => {
    setModalContent(content);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setModalContent(null);
  };

  const fileInputRef = useRef(null);

  const handlePhotoUploadClick = () => {
    fileInputRef.current.click();
  };

  const handlePhotoChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setPreviewImage(imageUrl);
    }
  };

  const handleRolesChange = (tab) => {
    if (tab === "superadmin") {
      if (selectedRoles.includes(tab)) {
        setSelectedRoles((prevAddedTabs) =>
          prevAddedTabs.filter((t) => t !== "superadmin")
        );
        setRemainingRoles((prevRemainingTabs) => [...prevRemainingTabs, tab]);
        return;
      } else {
        if (selectedRoles.length > 0) {
          window.alert(
            "You cannot select 'superadmin' when other roles are already selected."
          );
          return;
        }
      }
    } else {
      if (selectedRoles.includes("superadmin")) {
        window.alert(
          "You cannot select other roles when 'superadmin' is already selected."
        );
        return;
      }
    }

    setSelectedRoles((prevAddedTabs) => {
      const isTabAdded = prevAddedTabs.includes(tab);
      const updatedAddedTabs = isTabAdded
        ? prevAddedTabs.filter((t) => t !== tab)
        : [...prevAddedTabs, tab];

      setRemainingRoles((prevRemainingTabs) => {
        const updatedRemainingTabs = isTabAdded
          ? [...prevRemainingTabs, tab]
          : prevRemainingTabs.filter((t) => t !== tab);

        return updatedRemainingTabs;
      });

      return updatedAddedTabs;
    });
  };

  const handleWarehouseChange = (tab) => {
    setSelectedWarehouses((prevAddedTabs) => {
      const isTabAdded = prevAddedTabs.includes(tab);
      const updatedAddedTabs = isTabAdded
        ? prevAddedTabs.filter((t) => t !== tab)
        : [...prevAddedTabs, tab];

      setRemainingWarehouses((prevRemainingTabs) => {
        const updatedRemainingTabs = isTabAdded
          ? [...prevRemainingTabs, tab]
          : prevRemainingTabs.filter((t) => t !== tab);

        return updatedRemainingTabs;
      });

      return updatedAddedTabs;
    });
  };

  const handleToggle = (tab) => {
    setActiveToggles((prevState) => ({
      ...prevState,
      [tab]: !prevState[tab],
    }));
  };

  const stateOptions = states.map((state) => ({
    key: state.key,
    label: state.label,
    value: state.value,
  }));

  const handleStatusChange = (status) => {
    setSelectedStatus(status);
  };

  const hasOrderManager = selectedRoles?.some(
    (role) => role === "ordermanager"
  );

  const hasOtherRoles = selectedRoles?.some(
    (role) =>
      role !== "ordermanager" &&
      role !== "contentmanager" &&
      role !== "superadmin"
  );

  const formatTabText = (tab) => {
    if (typeof tab !== "string") {
      return tab;
    }

    if (tab === "superadmin") {
      return "Super Admin";
    }

    const isManager = tab.endsWith("manager");
    let formatted = tab;

    if (isManager) {
      const baseName = tab.slice(0, -7);
      const capitalizedBaseName = baseName
        .replace(/([a-z])([A-Z])/g, "$1 $2")
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
      formatted = `${capitalizedBaseName} Manager`;
    }
    return formatted;
  };

  useEffect(() => {
    const getAllWarehouse = async () => {
      const data = await fetchData("warehouses/getAllWarehouse");
      const warehouses = data.data.map((item) => item.warehousename);
      setRemainingWarehouses(warehouses);
    };

    const getAllDesignations = async () => {
      const data = await fetchData("managers/getAllDesignations");
      const designations = data.data.map((item) => item.designation);
      setRemainingRoles(designations);
    };

    getAllWarehouse();
    getAllDesignations();
  }, []);

  useEffect(() => {
    const updatedRemainingRoles = remainingRoles.filter(
      (role) => !selectedRoles.includes(role)
    );
    setRemainingRoles(updatedRemainingRoles);

    const updatedRemainingWarehouses =
      remainingWarehouses &&
      remainingWarehouses.filter(
        (warehouse) => !selectedWarehouses.includes(warehouse)
      );
    setRemainingWarehouses(updatedRemainingWarehouses);
  }, [selectedRoles, selectedWarehouses]);

  useEffect(() => {
    const getManagerData = async () => {
      try {
        const data = await fetchData("managers/getManager", {
          managerId: managerId,
          id: manager_Id,
        });
        setName(data.data.name);
        setMobileNumber(data.data.mobile);
        setLoginID(data.data.loginId);
        setSelectedStatus(data.data.status);
        setSelectedRoles(data.data.designation);
        setSelectedStates(
          data.data.selectedState.map((state) => ({
            ...state,
            value: state.stateName,
            label: state.stateName,
          }))
        );
        setSelectedWarehouses(
          data.data.selectedWarehouse.map(
            (warehouse) => warehouse.warehousename
          )
        );
        if (data.data.selectedDM) {
          setDispatcher(
            data.data.selectedDM.map((obj) => ({
              label: obj.dmName,
              value: obj.dmId,
            }))
          );
        }
      } catch (error) {
        console.error("Error fetching manager data:", error);
      }
    };

    if (isEdit) {
      getManagerData();
    }
  }, [manager_Id, isEdit, managerId]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    switch (name) {
      case "name":
        setName(value);
        break;
      case "mobileNumber":
        setMobileNumber(value);
        break;
      case "loginID":
        setLoginID(value);
        break;
      case "password":
        setPassword(value);
        break;
      case "confirmPassword":
        setConfirmPassword(value);
        break;
      default:
        break;
    }
  };

  const validateForm = ({
    name,
    mobileNumber,
    selectedStatus,
    loginID,
    password,
    confirmPassword,
    selectedRoles,
    selectedStates,
    selectedWarehouses,
  }) => {
    if (
      !name ||
      !mobileNumber ||
      !selectedStatus ||
      !loginID ||
      !password ||
      !confirmPassword
    ) {
      alert("All fields are required");
      return false;
    }

    const mobileNumberRegex = /^[0-9]{10}$/;
    if (!mobileNumberRegex.test(mobileNumber)) {
      alert("Please enter a valid 10-digit mobile number");
      return false;
    }

    const strongPasswordRegex = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{16,})"
    );
    if (!strongPasswordRegex.test(password)) {
      alert(
        "Password must be at least 16 characters long, contain at least one lowercase letter, one uppercase letter, one digit, and one special character (!@#$%^&*)."
      );
      return false;
    }

    if (password !== confirmPassword) {
      alert("Passwords do not match");
      return false;
    }

    if (selectedRoles.length === 0) {
      alert("Please select at least one role");
      return false;
    }

    if (
      selectedRoles.includes("ordermanager") &&
      (!selectedStates || selectedStates.length === 0)
    ) {
      alert("Please select at least one state for Order Manager role");
      return false;
    }

    const warehouseDependentRoles = [
      "dispatchmanager",
      "packingmanager",
      "invoicemanager",
      "inventorymanager",
      "paymentmanager",
    ];

    const hasWarehouseDependentRole = selectedRoles.some((role) =>
      warehouseDependentRoles.includes(role)
    );
    if (
      hasWarehouseDependentRole &&
      (!selectedWarehouses || selectedWarehouses.length === 0)
    ) {
      alert("Please select at least one warehouse for the selected roles");
      return false;
    }

    return true;
  };

  const selectedDM =
    dispatcher &&
    dispatcher.map((item) => ({ dmName: item.value, dmId: item.key }));

  const formData = {
    name,
    mobileNumber,
    selectedStatus,
    loginID,
    password,
    confirmPassword,
    selectedRoles,
    selectedStates,
    selectedWarehouses,
  };

  const handleCreateManager = async () => {
    const apiData = {
      name: name,
      mobile: mobileNumber,
      loginId: loginID,
      password:
        password === confirmPassword
          ? password
          : alert("Passwords do not match"),
      status: selectedStatus,
      isDealerAssigned: isDealerAssigned,
      designation: selectedRoles,
      selectedWarehouse: selectedWarehouses.map((tab) => ({
        warehousename: tab,
      })),
      selectedState:
        selectedStates &&
        selectedStates.map((state) => ({
          stateName: state.value,
        })),
      profileImage: previewImage,
      selectedDM: selectedDM,
    };

    try {
      setLoading(true);
      const res = await fetchData("managers/register", apiData);
      if (res.msg) {
        setLoading(false);
        navigate("/managers");
      }
    } catch (error) {
      setLoading(false);
      alert(
        `Creation failed: ${error.message || "An unexpected error occurred."}`
      );
    }
  };

  const handleEditManager = async () => {
    const apiData = {
      managerId: managerId,
      id: manager_Id,
      name: name,
      mobile: mobileNumber,
      loginId: loginID,
      password:
        password === confirmPassword
          ? password
          : alert("Passwords do not match"),
      status: selectedStatus,
      isDealerAssigned: isDealerAssigned,
      designation: selectedRoles,
      selectedWarehouse: selectedWarehouses.map((tab) => ({
        warehousename: tab,
      })),
      selectedState:
        selectedStates &&
        selectedStates.map((state) => ({
          stateName: state.value,
        })),
      profileImage: previewImage,
      selectedDM: selectedDM,
    };

    try {
      setLoading(true);
      const res = await fetchData("managers/updateManager", apiData);

      if (res.status === "success") {
        setLoading(false);
        navigate("/managers");
      } else {
        alert(
          `Error: ${res.msg || "An error occurred while updating the manager."}`
        );
      }
    } catch (error) {
      setLoading(false);
      alert(
        `Update failed: ${error.message || "An unexpected error occurred."}`
      );
    }
  };

  // const handleDeleteManager = async () => {
  //   try {
  //     setLoading(true);
  //     const res = await fetchData("managers/deleteManager", {
  //       managerId: manager_Id,
  //     });

  //     if (res.status === "success") {
  //       setLoading(false);
  //       alert(res.msg);
  //       navigate("/managers");
  //     }
  //   } catch (error) {
  //     setLoading(false);
  //     console.error("Error deleting manager:", error);
  //     alert(
  //       `Delete failed: ${error.message || "An unexpected error occurred."}`
  //     );
  //   }
  // };

  return (
    <>
      {loading ? (
        <div className={styles.nm_loader}>
          <Spinner
            height={120}
            width={120}
            borderWidth={"5px"}
            color={mode ? "#000000" : "#4164E3"}
          />
        </div>
      ) : (
        <div
          style={mode ? { backgroundColor: "#2C2E33", border: "none" } : {}}
          className={styles.nm}
        >
          <div className={styles.nm_top}>
            <div className={styles.nm_top_left}>
              <div
                className={styles.nm_top_left_go_back}
                onClick={() => navigate(-1)}
              >
                <TestTopBackArrowIcon color={mode ? "#ffffff" : "#0100E4"} />
                <p
                  className={styles.nm_top_left_go_back_text}
                  style={{
                    color: mode ? "#FFFFFF" : "#0100E4",
                  }}
                >
                  Go Back
                </p>
              </div>
              <TestTopDotIcon color={mode ? "#E4E7EB" : "#646B88"} />
              <p
                className={styles.nm_top_left_path_text}
                style={{
                  color: mode ? "#9C9797" : "#646B88",
                }}
              >
                {isEdit
                  ? "Managers/Edit Manager/" + name
                  : "Managers/New Manager"}
              </p>
            </div>

            <div>
              {/* {isEdit ? (
                <Button
                  buttonIcon={<ManagerDeleteIcon />}
                  buttonText={"Delete"}
                  buttonBgColor={"#F93E3E"}
                  buttonTextColor={"#ffffff"}
                  buttonBorder={"none"}
                  buttonClick={handleDeleteManager}
                />
              ) : ( */}
              <p
                className={styles.nm_top_right}
                style={{ color: mode && "#D9D9D9" }}
              >
                Last Online Status:{" "}
                <span style={{ fontSize: "12px" }}>
                  Create New Manager Today at 01.00 PM 12-05-2024, Window App{" "}
                  <span
                    style={{
                      textDecoration: "underline",
                      cursor: "pointer",
                      color: mode ? "#749AFF" : "#0100E4",
                    }}
                  >
                    History
                  </span>
                </span>
              </p>
              {/* )} */}
            </div>
          </div>

          <div className={styles.nm_form}>
            <div className={styles.nm_form_section}>
              <div className={styles.nm_photo} onClick={handlePhotoUploadClick}>
                <img src={previewImage} alt="Manager" />
                <div className={styles.nm_camera_icon}>
                  <ManagerCameraIcon />
                </div>
                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  onChange={handlePhotoChange}
                  accept="image/*"
                />
              </div>

              <div
                className={`${styles.inp_con} ${
                  mode ? styles.inp_con_dark : ""
                }`}
              >
                <div>
                  <InputTag
                    name="name"
                    value={name}
                    labelText={"Name"}
                    placeholderText={"Enter name"}
                    placeholderType={"text"}
                    required={true}
                    star
                    maxLength={15}
                    onChange={handleChange}
                  />
                </div>
                <InputTag
                  name="mobileNumber"
                  value={mobileNumber}
                  labelText={"Mobile Number"}
                  placeholderText={"Enter mobile number"}
                  placeholderType={"number"}
                  required={true}
                  star
                  maxLength={10}
                  onChange={handleChange}
                />
                <div>
                  <InputTag
                    labelText={"Attendance"}
                    placeholderType={"number"}
                    placeholderText={"Coming Soon"}
                    required={true}
                    disabled
                  />
                </div>
              </div>

              <div
                className={`${styles.inp_con} ${
                  mode ? styles.inp_con_dark : ""
                }`}
              >
                {remainingRoles ? (
                  <>
                    <p
                      className={styles.tabs_section_label}
                      style={
                        mode ? { color: mode ? "#ffffff" : "#646B88" } : {}
                      }
                    >
                      Assign Role <span style={{ color: "#F93E3E" }}>*</span>
                    </p>
                    <div className={styles.tabs_section}>
                      <div>
                        <div className={styles.tabs}>
                          {selectedRoles?.map((tab) => (
                            <div
                              key={tab}
                              className={styles.tab}
                              style={{
                                color: mode && "#9c9797",
                                backgroundColor: mode && "#2C2E33",
                              }}
                              onClick={() => handleRolesChange(tab)}
                            >
                              {formatTabText(tab)}
                              <RemoveTabIcon
                                color={mode ? "#9C9797" : "#4164E3"}
                              />
                            </div>
                          ))}
                        </div>
                      </div>

                      <div
                        className={styles.section_border}
                        style={{ backgroundColor: mode && "#2C2E33" }}
                      ></div>

                      <div>
                        <div className={styles.tabs}>
                          {remainingRoles?.map((tab) => (
                            <div
                              key={tab}
                              className={styles.tab}
                              style={{
                                color: mode && "#9c9797",
                                backgroundColor: mode && "#2C2E33",
                              }}
                              onClick={() => handleRolesChange(tab)}
                            >
                              {formatTabText(tab)}
                              <ManagerTickIcon
                                color={mode ? "#9C9797" : "#4164E3"}
                              />
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <p>Loading remaining roles...</p>
                )}
              </div>
            </div>

            <div className={`${styles.nm_form_section}`}>
              {activeToggles.Web && (
                <RenderWebSection
                  loginID={loginID}
                  password={password}
                  confirmPassword={confirmPassword}
                  onChange={handleChange}
                />
              )}

              {activeToggles.App && <RenderAppSection mode={mode} />}

              {hasOrderManager && (
                <div
                  className={`${styles.nm_order_manager} ${styles.inp_con} ${
                    mode ? styles.inp_con_dark : ""
                  }`}
                >
                  <p
                    style={{
                      color: mode ? "#ffffff" : undefined,
                      marginBottom: "-0.5rem",
                    }}
                  >
                    Order Manager
                  </p>

                  <VerticalDropDown
                    options={dealerOptions}
                    label={"Assigned Dealer"}
                    star
                    dropDownText={"Select"}
                    value={isDealerAssigned}
                    onchange={setIsDealerAssigned}
                  />
                  <VerticalDropDown
                    options={stateOptions}
                    label={"Assigned State"}
                    star
                    dropDownText={"Select"}
                    value={selectedStates}
                    onchange={setSelectedStates}
                    multiple={true}
                  />
                </div>
              )}

              {hasOtherRoles && (
                <div
                  className={`${styles.nm_order_manager} ${styles.inp_con} ${
                    mode ? styles.inp_con_dark : ""
                  }`}
                >
                  <p
                    style={{
                      color: mode ? "#ffffff" : undefined,
                      marginBottom: "-0.5rem",
                    }}
                  >
                    Assign Warehouse
                  </p>
                  <>
                    <p
                      className={styles.tabs_section_label}
                      style={
                        mode ? { color: mode ? "#ffffff" : "#646B88" } : {}
                      }
                    >
                      Select Warehouse
                      <span style={{ color: "#F93E3E" }}>*</span>
                    </p>
                    <div className={styles.tabs_section}>
                      <div style={{ backgroundColor: mode && "transparent" }}>
                        <div className={styles.tabs}>
                          {selectedWarehouses?.map((tab) => (
                            <div
                              key={tab._id}
                              className={styles.tab}
                              style={{
                                color: mode && "#9c9797",
                                backgroundColor: mode && "#2C2E33",
                              }}
                              onClick={() => handleWarehouseChange(tab)}
                            >
                              {formatTabText(tab)}
                              <RemoveTabIcon
                                color={mode ? "#9C9797" : "#4164E3"}
                              />
                            </div>
                          ))}
                        </div>
                      </div>

                      <div
                        className={styles.section_border}
                        style={{ backgroundColor: mode && "#2C2E33" }}
                      ></div>

                      <div>
                        <div className={styles.tabs}>
                          {remainingWarehouses?.map((tab) => (
                            <div
                              key={tab._id}
                              className={styles.tab}
                              style={{
                                color: mode && "#9c9797",
                                backgroundColor: mode && "#2C2E33",
                              }}
                              onClick={() => handleWarehouseChange(tab)}
                            >
                              {formatTabText(tab)}
                              <ManagerTickIcon
                                color={mode ? "#9C9797" : "#4164E3"}
                              />
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </>
                </div>
              )}
            </div>
          </div>

          <ModalWrapper isOpen={isModalOpen} onClose={handleCloseModal} noBtn>
            {modalContent}
          </ModalWrapper>

          <div
            className={`${styles.nm_footer} ${
              mode ? styles.nm_footer_dark : ""
            }`}
          >
            <div className={styles.nm_footer_1}>
              <div>
                <div className={styles.radio_section}>
                  <p
                    style={{
                      color: mode ? "#9C9797" : "#646B88",
                      margin: "0",
                    }}
                  >
                    Status<span style={{ color: "#F93E3E" }}>*</span>
                  </p>
                  <RadioButtonGroup
                    key={selectedStatus}
                    options={radioOptions}
                    selectedOption={selectedStatus && selectedStatus}
                    onChange={handleStatusChange}
                  />
                </div>
              </div>

              <div>
                <p
                  style={{
                    color: mode ? "#9C9797" : "#646B88",
                  }}
                >
                  Platform Access<span style={{ color: "#F93E3E" }}>*</span>
                </p>
                <div className={styles.toggle_section}>
                  <ToggleBtn
                    status={activeToggles.Web ? "active" : ""}
                    placeholder={"Web"}
                    onClick={() => handleToggle("Web")}
                  />
                  <ToggleBtn
                    status={activeToggles.App ? "active" : ""}
                    placeholder={"App"}
                    onClick={() => handleToggle("App")}
                  />
                </div>
              </div>

              <div>
                <p
                  style={{
                    color: mode ? "#9C9797" : "#646B88",
                  }}
                >
                  Work Status<span style={{ color: "#F93E3E" }}>*</span>
                </p>
                <ToggleBtn labelText="Work Status" status={"active"} />
              </div>
              <SlidingFilter itemList={itemList} bgcolor={"#4164E3"} />
            </div>

            <div className={styles.nm_footer_2}>
              {!isEdit && (
                <>
                  <Button
                    buttonIcon={<AddDesignationIcon />}
                    buttonText={"Designation"}
                    buttonBgColor={"transparent"}
                    buttonTextColor={"#4164E3"}
                    buttonBorder={"1px solid #4164E3"}
                    buttonClick={() => {
                      handleOpenModal(
                        <AddDesignation handleCloseModal={handleCloseModal} />
                      );
                    }}
                  />
                </>
              )}
              <Button
                buttonIcon={<CancelIcon2 />}
                buttonText={"CANCEL"}
                buttonBgColor={"#000000"}
                buttonTextColor={"#ffffff"}
                buttonBorder={"none"}
                buttonClick={() => navigate(-1)}
              />
              {isEdit ? (
                <Button
                  buttonIcon={<NewManagerCreateIcon />}
                  buttonText={"Update"}
                  buttonBgColor={"#4164E3"}
                  buttonTextColor={"#ffffff"}
                  buttonBorder={"none"}
                  buttonClick={() => {
                    if (validateForm(formData)) {
                      handleOpenModal(
                        <ManagerModal
                          heading={"Update Manager"}
                          centerHeading={"Are you sure you want to Update?"}
                          subHeading={
                            "To confirm! type update in the text field."
                          }
                          targetWord={"update"}
                          handleSubmit={() => {
                            handleEditManager();
                          }}
                          handleCloseModal={handleCloseModal}
                        />
                      );
                    }
                  }}
                />
              ) : (
                <Button
                  buttonIcon={<NewManagerCreateIcon />}
                  buttonText={"Create"}
                  buttonBgColor={"#4164E3"}
                  buttonTextColor={"#ffffff"}
                  buttonBorder={"none"}
                  buttonClick={() => {
                    if (validateForm(formData)) {
                      handleOpenModal(
                        <ManagerModal
                          heading={"Create Manager"}
                          centerHeading={"Are you sure you want to Create?"}
                          subHeading={
                            "To confirm! type create in the text field."
                          }
                          targetWord={"create"}
                          handleSubmit={() => {
                            handleCreateManager();
                          }}
                          handleCloseModal={handleCloseModal}
                        />
                      );
                    }
                  }}
                />
              )}
            </div>
          </div>

          <div className={styles.nm_footer_mob}>
            {isEdit ? (
              <Button
                buttonIcon={<NewManagerCreateIcon />}
                buttonText={"Update"}
                buttonBgColor={"#4164E3"}
                buttonTextColor={"#ffffff"}
                buttonBorder={"none"}
                buttonClick={handleEditManager}
              />
            ) : (
              <Button
                buttonIcon={<NewManagerCreateIcon />}
                buttonText={"Create"}
                buttonBgColor={"#4164E3"}
                buttonTextColor={"#ffffff"}
                buttonBorder={"none"}
                buttonClick={handleCreateManager}
              />
            )}
            <Button
              buttonIcon={<CancelIcon2 />}
              buttonText={"CANCEL"}
              buttonBgColor={"#000000"}
              buttonTextColor={"#ffffff"}
              buttonBorder={"none"}
              buttonClick={() => navigate(-1)}
            />
          </div>
        </div>
      )}
    </>
  );
};

const RenderWebSection = ({ loginID, password, confirmPassword, onChange }) => {
  const { mode } = useAuth();
  const [modalContent, setModalContent] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = (content) => {
    setModalContent(content);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setModalContent(null);
  };

  return (
    <form className={`${styles.inp_con} ${mode ? styles.inp_con_dark : ""}`}>
      <p
        className={styles.wa_heading}
        style={{
          color: mode ? "#ffffff" : "#000000",
        }}
      >
        Web
      </p>
      <div className={styles.wa_form}>
        <p
          className={styles.wa_logout_text}
          onClick={() => {
            handleOpenModal(
              <ManagerModal
                heading={"Logout Device"}
                centerHeading={"Are you sure want to Logout?"}
                subHeading={"To confirm! type logout in the text field."}
                targetWord={"logout"}
                handleSubmit={() => {
                  alert("SERVICE YET TO COME");
                }}
                handleCloseModal={handleCloseModal}
              />
            );
          }}
        >
          Logout from all Devices
        </p>
        <ModalWrapper isOpen={isModalOpen} onClose={handleCloseModal} noBtn>
          {modalContent}
        </ModalWrapper>
      </div>
      <div>
        <InputTag
          labelText={"Devices"}
          placeholderText={"Coming Soon"}
          required={true}
          star
          disabled={true}
        />
      </div>
      <div className={styles.wa_device_list}>
        <span
          className={styles.wa_device_list_text}
          onClick={() => {
            handleOpenModal(<ManagerDevicesTable />);
          }}
        >
          Device List
        </span>
      </div>
      <InputTag
        name="loginID"
        value={loginID}
        labelText={"Login ID / Username"}
        placeholderText={"Enter a Login ID / Username"}
        placeholderType={"text"}
        required={true}
        star
        maxLength={36}
        onChange={onChange}
      />
      <InputTag
        name="password"
        value={password}
        labelText={"Password"}
        placeholderText={"Create a Password"}
        placeholderType={"password"}
        required={true}
        star
        maxLength={30}
        onChange={onChange}
        autoComplete={"false"}
      />
      <InputTag
        name="confirmPassword"
        value={confirmPassword}
        labelText={"Confirm Password"}
        placeholderText={"Re-Enter Password"}
        placeholderType={"password"}
        required={true}
        star
        maxLength={30}
        onChange={onChange}
        autoComplete={"false"}
      />
    </form>
  );
};

const RenderAppSection = ({ mode }) => {
  const [modalContent, setModalContent] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = (content) => {
    setModalContent(content);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setModalContent(null);
  };

  return (
    <div className={`${styles.inp_con} ${mode ? styles.inp_con_dark : ""}`}>
      <p
        className={styles.wa_heading}
        style={{
          color: mode ? "#ffffff" : "#000000",
        }}
      >
        App
      </p>
      <div className={styles.wa_form}>
        <p
          className={styles.wa_logout_text}
          onClick={() => {
            handleOpenModal(
              <ManagerModal
                heading={"Logout Device"}
                centerHeading={"Are you sure want to Logout?"}
                subHeading={"To confirm! type logout in the text field."}
                targetWord={"logout"}
                handleSubmit={() => {
                  alert("SERVICE YET TO COME");
                }}
                handleCloseModal={handleCloseModal}
              />
            );
          }}
        >
          Logout from all Devices
        </p>
        <ModalWrapper isOpen={isModalOpen} onClose={handleCloseModal} noBtn>
          {modalContent}
        </ModalWrapper>
      </div>
      <div>
        <InputTag
          labelText={"Devices"}
          placeholderText={"Coming Soon"}
          required={true}
          star
          disabled={true}
        />
      </div>
      <div className={styles.wa_device_list}>
        <span
          className={styles.wa_device_list_text}
          onClick={() => {
            handleOpenModal(<ManagerDevicesTable />);
          }}
        >
          Device List
        </span>
      </div>
      <InputTag
        name="loginID"
        labelText={"Login ID / Username"}
        placeholderText={"Enter a Login ID / Username"}
        placeholderType={"text"}
        required={true}
        star
        maxLength={36}
      />
      <InputTag
        name="password"
        labelText={"Password"}
        placeholderText={"Create a Password"}
        placeholderType={"password"}
        required={true}
        star
        maxLength={30}
      />
      <InputTag
        name="confirmPassword"
        labelText={"Confirm Password"}
        placeholderText={"Re-Enter Password"}
        placeholderType={"password"}
        required={true}
        star
        maxLength={30}
      />
    </div>
  );
};

export default NewManager;

const itemList = ["Flexible", "Fixed"];

const radioOptions = [
  { label: "Active", value: "active" },
  { label: "Inactive", value: "disable" },
];

const dealerOptions = [
  { id: 1, label: "Yes" },
  { id: 2, label: "No" },
];
