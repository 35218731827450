// import React from "react";
// import { useAuth } from "../../context/Auth";
// import styles from "./FromComponent.module.css";

// const InputTypetime = ({ text, onChange, value }) => {
//     const { mode } = useAuth();

//     const inputStyle = {
//         padding: '12px 6px',
//         color: mode ? '#fff' : '#1B1D21',
//         fontSize: '13px',
//         outline: 'none',
//         borderRadius: '5px',
//         position: 'relative',
//         backgroundColor: mode ? '#1B1D21' : '#fff',
//         cursor: 'pointer',
//         appearance: 'none',
//         WebkitAppearance: 'none',
//         border: mode ? "none" : "1px solid #E4E7EB"
//     };

//     return (
//         <div className={styles.dateInputWrapper} style={{ position: 'relative' }}>
//             <span style={{ color: mode ? '#fafafa' : '#1B1D21', marginRight: '10px', fontSize: "12px" }}>{text}</span>
//             <input
//                 type="time"
//                 className={styles.inputTypeDate1}
//                 onChange={onChange}
//                 style={inputStyle}
//                 value={value } // Set current time as default if no value is provided
//             />
//             <div 
//                 className={styles.backgroundIcon}
//                 style={{
//                     content: '',
//                     position: 'absolute',
//                     top: '50%',
//                     right: '6px',
//                     transform: 'translateY(-50%)',
//                     width: '18px', // Adjust size as necessary
//                     height: '18px',
//                     backgroundImage: mode
//                         ? "url('./assets/updatedAssets/ .png')" // Adjust for time icon
//                         : "url('./assets/updatedAssets/time.png')", // Adjust for time icon
//                     backgroundRepeat: 'no-repeat',
//                     backgroundSize: 'contain',
//                     zIndex: 999,
//                     pointerEvents: 'none',
//                     cursor: "pointer"
//                 }}
//             />
//         </div>
//     );
// };

// export default InputTypetime;











import React, { useState, useEffect } from "react";
import { useAuth } from "../../context/Auth";
import styles from "./FromComponent.module.css";

const InputTypetime = ({ text, onChange, value }) => {
    const { mode } = useAuth();
    const [currentTime, setCurrentTime] = useState("");

    useEffect(() => {
        // Get the current time in HH:mm format
        const now = new Date();
        const hours = now.getHours().toString().padStart(2, "0");
        const minutes = now.getMinutes().toString().padStart(2, "0");
        setCurrentTime(`${hours}:${minutes}`);
    }, []);

    const inputStyle = {
        padding: '12px 6px',
        color: mode ? '#fff' : '#1B1D21',
        fontSize: '13px',
        outline: 'none',
        borderRadius: '5px',
        position: 'relative',
        backgroundColor: mode ? '#1B1D21' : '#fff',
        cursor: 'pointer',
        appearance: 'none',
        WebkitAppearance: 'none',
        border: mode ? "none" : "1px solid #E4E7EB"
    };

    return (
        <div className={styles.dateInputWrapper} style={{ position: 'relative' }}>
            <span style={{ color: mode ? '#fafafa' : '#1B1D21', marginRight: '10px', fontSize: "12px" }}>{text}</span>
            <input
                type="time"
                className={styles.inputTypeDate1}
                onChange={onChange}
                style={inputStyle}
                value={value || currentTime} // Use current time if no value is provided
                min={currentTime} // Set minimum time as current time
            />
            <div 
                className={styles.backgroundIcon}
                style={{
                    content: '',
                    position: 'absolute',
                    top: '50%',
                    right: '6px',
                    transform: 'translateY(-50%)',
                    width: '18px',
                    height: '18px',
                    backgroundImage: mode
                        ? "url('./assets/updatedAssets/timeblack2.png')" // Adjust for time icon
                        : "url('./assets/updatedAssets/time.png')", // Adjust for time icon
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'contain',
                    zIndex: 9993,
                    pointerEvents: 'none',
                    cursor: "pointer"
                }}
            />
        </div>
    );
};

export default InputTypetime;
