// import React from "react";
// import styles from "../globalwhatsappchat.module.css";
// import { useAuth } from "../../../context/Auth";
// const ProfileChat = ({ dealerData }) => {
//     const { mode } = useAuth();
//   return (
//     <div>
//       {!dealerData.profileImage ? (
//         <img
//           src="/assets/dealerprofile.png"
//           alt="profile_Image"
//           className={styles.whatsappMessage_image}
//         />
//       ) : (
//         <img
//           src={process.env.REACT_APP_S3URL + dealerData.profileImage}
//           className={styles.whatsappMessage_image}
//           alt="dealer_image"
//         />
//       )}
//     </div>
//   );
// };

// export default ProfileChat;



import React from "react";
import styles from "../globalwhatsappchat.module.css";
import { useAuth } from "../../../context/Auth";

const ProfileChat = ({ dealerData }) => {
  const { mode } = useAuth();
  
  // Conditional border color based on mode (light/dark)
  const borderColor = mode ? '2px solid #F1F1F1' : '2px solid #82889F';
  
  // Define the image source (default or dealer's image)
  const imageUrl = dealerData.profileImage
  ?  `${process.env.REACT_APP_S3URL}${dealerData.profileImage}`
     : "/assets/whatsapp_profile.png";

  return (
    <div>
      <img
        src={imageUrl}
        alt="dealer profile"
        className={styles.whatsappMessage_image}
        style={{ border: borderColor }}
      />
    </div>
  );
};

export default ProfileChat;

