import React, { useEffect, useRef, useState } from 'react'
import { ArrowIcon, CarIcon, CartIcon, DateIcon, DownloadIcongrey, IndustryIcon, Search, SettingIcon, } from '../../components/icons/Icon';
import ModalMob from './ModalMob';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../../components/context/Auth';

const MobOrdersTab = (props) => {

    const {
        styles,
        daysTab,
        startDate,
        setStartDate,
        setDaysTab,
        setEndDate,
        endDate,
        orders,
        orderStatus,
        setOrderStatus,
        setKeyword,
        keyword,
        // cartTab,
        // setCartTab,
        sortKey,
        setSortKey,
        orderType,
        setOrderType,
        managers,
        setManagerName,
        managerName,
        setWarehouseName,
        warehouses,
        warehouseName,
        setShowAcceptModal3,
        showAcceptModal3,
        loading
    } = props
    const navigate = useNavigate();
    const location = useLocation().pathname
    const { designation } = useAuth();
    const [showAcceptModal, setShowAcceptModal] = useState(false);
    const [showAcceptModal1, setShowAcceptModal1] = useState(false);
    const [showAcceptModal2, setShowAcceptModal2] = useState(false);
    const [showAcceptModal4, setShowAcceptModal4] = useState(false);
    const [showAcceptModal5, setShowAcceptModal5] = useState(false);

    const data = !orders || orders === undefined || orders === "undefined" ? "" : orders

    function useOutsideAlerter(ref) {
        useEffect(() => {
            /**
             * Alert if clicked on outside of element
             */
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setShowAcceptModal3(false);
                }
            }
            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);

    const data1 = [
        {
            key: "1",
            sort: "All"
        },
        {
            key: "2",
            sort: "New Order"
        },
        {
            key: "3",
            sort: "Cancelled"
        },
        {
            key: "4",
            sort: "In progress"
        },
        {
            key: "5",
            sort: "Packed"
        },
        {
            key: "6",
            sort: "Pending"
        },
        {
            key: "7",
            sort: "Dispatched"
        },
        {
            key: "8",
            sort: "Descending"
        },
        {
            key: "9",
            sort: "Ascending"
        },
        {
            key: "10",
            sort: "Last Action"
        },
    ]

    return (
        <React.Fragment>
            <div className={styles.dispatch_div}>
                <div onClick={() => setShowAcceptModal(true)} className={styles.tab_dropdown}>{daysTab ? daysTab : 'SelectDay'}{showAcceptModal ? <span>&#9650;</span> : <span>&#9660;</span>}</div>
                <DateIcon />
                <label className={styles.dispatch_div1}>
                    <div className={styles.date_text}>Start date:</div>
                    <input
                        type="date"
                        className={styles.date_select}
                        value={startDate}
                        onChange={(e) => { setStartDate(e.target.value); setDaysTab('') }}
                    />
                </label>
                <label className={styles.dispatch_div1}>
                    <div className={styles.date_text}>End date:</div>
                    <input
                        type="date"
                        className={styles.date_select}
                        value={endDate}
                        onChange={(e) => { setEndDate(e.target.value); setDaysTab('') }}
                    />
                </label>
            </div>
            <div className={data.orders&&data.orders.length < 4 ? styles.statusCard_Three : styles.statusCard_div}>
                {loading ?
                    <>
                        {
                            Array.from({ length: location === '/purchaseorders' ? 3 : 9 }).map((_, idx) =>
                                <div key={idx} className='Mob_skeleton_order'>
                                    <span className={styles.orders_status_filter}>0</span>
                                    <span className={styles.statusTxt}>status</span>
                                </div>
                            )
                        }
                    </>
                    : data.orders && data.orders.map((item, index) =>
                        <div key={index} className={styles.statuscard1}>
                            <div className={`${styles.statusCard} shadow 
                        ${orderStatus === item.status && item.status === 'All' ? styles.all_active :
                                    orderStatus === item.status && item.status === 'New Order' ? styles.new_active :
                                        orderStatus === item.status && item.status === 'Cancelled' ? styles.cancelled_active :
                                            orderStatus === item.status && item.status === 'In Progress' ? styles.inProgress_active :
                                            orderStatus === item.status && item.status === 'draft' ? styles.inProgress_active :
                                                orderStatus === item.status && item.status === 'Payment Pending' ? styles.payment_active :
                                                    orderStatus === item.status && item.status === 'Payment Under Verification' ? styles.payment_active :
                                                        orderStatus === item.status && item.status === 'Packing In Progress' ? styles.inProgress_active :
                                                            orderStatus === item.status && item.status === 'Dispatched Pending' ? styles.inProgress_active :
                                                                orderStatus === item.status && item.status === 'Dispatched' ? styles.Dispatched_active :
                                                                orderStatus === item.status && item.status === 'success' ? styles.Dispatched_active :
                                                                    ''}`}
                                onClick={() => {
                                    setOrderStatus(item.status);
                                }}
                            >
                                <div className={`${styles.orders_status_filter} ` + (
                                    item.status === 'All' ? styles.status_all :
                                        item.status === 'New Order' ? styles.new_status :
                                            item.status === 'Cancelled' ? styles.cancelled_status :
                                                item.status === 'In Progress' ? styles.inProgress_status :
                                                item.status === 'draft' ? styles.inProgress_status :
                                                    item.status === 'Payment Pending' ? styles.payment_status :
                                                        item.status === 'Payment Under Verification' ? styles.payment_status :
                                                            item.status === 'Packing In Progress' ? styles.inProgress_status :
                                                                item.status === 'Dispatched Pending' ? styles.inProgress_status :
                                                                    item.status === 'Dispatched' ? styles.Dispatched_status :
                                                                    item.status === 'success' ? styles.Dispatched_status :
                                                                        '')}>
                                    {item.count}
                                    <div className={styles.statusTxt}>{item.status}</div>
                                </div>

                            </div>
                        </div>
                    )}
            </div>
            <div className={styles.flex}>
                <span className={styles.dashboard_search_div}>
                    <input type="text"
                        placeholder='Search'
                        className={styles.dashboard_search}
                        value={keyword}
                        onChange={(e) => setKeyword(e.target.value)}
                    />
                    <Search color="#000" css={styles.dashboard_search_icon} />
                </span>
                <span className={styles.icons_css} onClick={() => setShowAcceptModal2(true)}>
                    <CarIcon />
                </span>
                <span className={styles.icons_css} onClick={() => setShowAcceptModal1(true)}>
                    <CartIcon />
                </span>

                {designation === "superadmin" &&
                    <>
                        <span className={styles.icons_css} onClick={() => setShowAcceptModal4(true)}>
                            <SettingIcon />
                        </span>
                        <span className={styles.icons_css} onClick={() => setShowAcceptModal5(true)}>
                            <IndustryIcon />
                        </span>
                    </>
                }
                <span className={styles.icons_css} onClick={() => setShowAcceptModal3(!showAcceptModal3)}>
                    <ArrowIcon />
                </span>
                <span className={styles.icons_css} >
                    <DownloadIcongrey color="#D3D3D3" />
                </span>
                {showAcceptModal3 &&
                    <div className={styles.short_div} ref={wrapperRef} onClick={(e) => e.stopPropagation()}>
                        <h3>Short By</h3>
                        {data1.map((item, index) =>
                            <h6 key={index} className={`${sortKey === item.sort ? styles.days_active : ''}`}
                                onClick={(e) => {
                                    setShowAcceptModal3(false);
                                    if (item.sort === 'Ascending' || item.sort === 'Descending' || item.sort === 'lastAction') {
                                        setSortKey(item.sort)
                                    } else {
                                        setOrderStatus(item.sort)
                                        setSortKey("")
                                    }
                                }}>{item.sort}</h6>
                        )}
                    </div>
                }
            </div>

            <ModalMob
                show={showAcceptModal}
                close={setShowAcceptModal}
                // closeBtn={true}
                // heading="Order Status"
                content={
                    <div className={styles.dispatch_select}>
                        <h2 className={`${styles.dashboard_days_select} ${daysTab === 'today' ? styles.days_active : ''}`} onClick={() => { setDaysTab('today'); setShowAcceptModal(false) }}>Today</h2>
                        <h2 className={`${styles.dashboard_days_select} ${daysTab === 'yesterday' ? styles.days_active : ''}`} onClick={() => { setDaysTab('yesterday'); setShowAcceptModal(false) }}>Yesterday</h2>
                        <h2 className={`${styles.dashboard_days_select} ${daysTab === 'month' ? styles.days_active : ''}`} onClick={() => { setDaysTab('month'); setShowAcceptModal(false) }}>This Month</h2>
                    </div>
                }
            />

            <ModalMob
                show={showAcceptModal2}
                close={setShowAcceptModal2}
                // closeBtn={true}
                // heading="Order Status"
                css={styles.date_margin_top}
                content={
                    <div className={styles.dispatch_select}>
                        <h2 >Choose Order Type</h2>
                        <div className={styles.flex} onClick={() => { setOrderType(''); setShowAcceptModal2(false) }}>
                            <h2 className={styles.dashboard_days_select1} >All</h2>
                            <input
                                defaultChecked={orderType === 'all' ? true : false}
                                type='checkbox'
                                className={styles.largerCheckbox}
                            />
                        </div>
                        <div className={styles.flex} onClick={() => { setOrderType('online'); setShowAcceptModal2(false) }}>
                            <h2 className={styles.dashboard_days_select1} >Online</h2>
                            <input
                                defaultChecked={orderType === 'online' ? true : false}
                                type='checkbox'
                                className={styles.largerCheckbox}
                            />
                        </div>
                        <div className={styles.flex} onClick={() => { setOrderType('offline'); setShowAcceptModal2(false) }}>
                            <h2 className={styles.dashboard_days_select1} >Offline</h2>
                            <input
                                defaultChecked={orderType === 'offline' ? true : false}
                                type='checkbox'
                                className={styles.largerCheckbox}
                            />
                        </div>
                    </div>
                }
            />

            <ModalMob
                show={showAcceptModal1}
                close={setShowAcceptModal1}
                // closeBtn={true}
                // heading="Order Status"
                css={styles.date_margin_top}
                content={
                    <div className={styles.dispatch_select}>
                        <h2 >Select Cart Type</h2>
                        <div className={styles.flex} onClick={() => { if (designation === "superadmin" || designation === "inventorymanager" || designation === "ordermanager") navigate("/orders"); setShowAcceptModal1(false) }}>
                            <h2 className={styles.dashboard_days_select1}>Orders</h2>
                            <input defaultChecked={location === "/orders" ? true : false} type='checkbox' className={styles.largerCheckbox} />
                        </div>
                        <div className={styles.flex} onClick={() => { if (designation === "superadmin" || designation === "inventorymanager" || designation === "ordermanager") navigate("/estimateorders"); setShowAcceptModal1(false) }}>
                            <h2 className={styles.dashboard_days_select1}>Estimates</h2>
                            <input defaultChecked={location === "/estimateorders" ? true : false} type='checkbox' className={styles.largerCheckbox} />
                        </div>
                        <div className={styles.flex} onClick={() => { if (designation === "superadmin" || designation === "inventorymanager" || designation === "ordermanager") navigate("/purchaseorders"); setShowAcceptModal1(false) }}>
                            <h2 className={styles.dashboard_days_select1}>Purchase</h2>
                            <input defaultChecked={location === "/purchaseorders" ? true : false} type='checkbox' className={styles.largerCheckbox} />
                        </div>
                        
                    </div>
                }

            />



            <ModalMob
                show={showAcceptModal4}
                close={setShowAcceptModal4}
                // closeBtn={true}
                // heading="Order Status"
                css={styles.date_margin_top}
                content={
                    <div className={styles.dispatch_select}>
                        <h2 >Select Managers</h2>
                        {managers && managers.map((item, index) =>
                            <div key={index} className={styles.flex} onClick={() => { setManagerName(item.name); setShowAcceptModal4(false) }}>
                                <h2 className={styles.dashboard_days_select1}>{item.name}</h2>
                                <input checked={managerName === item.name ? true : false} type='checkbox' className={styles.largerCheckbox}
                                />
                            </div>
                        )}
                    </div>
                }
            />
            <ModalMob
                show={showAcceptModal5}
                close={setShowAcceptModal5}
                css={styles.date_margin_top}
                content={
                    <div className={styles.dispatch_select}>
                        <h2 >Select Managers</h2>
                        {warehouses && warehouses.map((item, index) =>
                            <div className={styles.flex} key={index} onClick={() => { setWarehouseName(item.warehousename); setShowAcceptModal5(false); }}>
                                <h2 className={styles.dashboard_days_select1}>{item.warehousename}</h2>
                                <input checked={warehouseName === item.warehousename ? true : false} type='checkbox' className={styles.largerCheckbox}
                                />
                            </div>
                        )}
                    </div>
                }
            />

        </React.Fragment>
    )
}

export default MobOrdersTab