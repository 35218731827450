const fetchDataParem = async (endpoint,  params, apiData) => {
    console.log(apiData);
    
    try {
      const url = new URL(`${process.env.REACT_APP_URL}${endpoint}`);
      url.search = new URLSearchParams(params).toString();

      const response = await fetch(url, {
          method: "POST",
          headers: {
              "wa-platform": process.env.REACT_APP_ADMIN_SECRET,
          },
          body: apiData,  // Pass FormData directly
      });

  
      const result = await response.json();
      return result;
    } catch (error) {
      return error;
    }
  };
  export default fetchDataParem;
  