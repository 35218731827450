import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { GreenButton, RedOutButton } from '../../components/Buttons'
import { DeleteFile } from '../../components/icons/Icon';
import styles from './Product.module.css'

const AddFaq = () => {
    const navigate = useNavigate()
    const product_name = window.location.pathname.split("/")[window.location.pathname.split("/").length - 1]
    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get('product_id');
    console.log(myParam)
    const [faq, setFaq] = useState([{ question: '', answer: '', }]);
    const [checkFaq, setCheckFaq] = useState(false)


    const handleChangeFAQ = (e, index) => {
        const { name, value } = e.target;
        const list = [...faq];
        list[index][name] = value;
        setFaq(list);
    };

    function handleAddFAQ() {
        const values = [...faq];
        values.push({ question: '', answer: '' });
        setFaq(values);
    }

    function handleRemoveFAQ(i) {
        const values = [...faq];
        values.splice(i, 1);
        setFaq(values);
    }

    useEffect(() => {

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET,);

        var raw = JSON.stringify({
            "product_name": product_name.replace(/-/g, " ")
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(process.env.REACT_APP_URL + "content/getFAQ", requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status === 'success' && result.data.faqs.length > 0) {
                    setFaq(result.data.faqs.map(item => ({ question: item.question, answer: item.answer })))
                    setCheckFaq(true)
                }
                else {
                    setFaq([{ question: '', answer: '', }])
                }
            })
    }, [product_name])

    const postFAQ = () => {
        if (!myParam) {
            alert("Something went wrong!")
        } else {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);

            var raw = JSON.stringify({
                "product_name": product_name.replace(/-/g, " "),
                product_id: myParam,
                "faqs": faq
            });

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            fetch(process.env.REACT_APP_URL + "content/addFAQ", requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.status === "success") {
                        navigate(-1)
                        alert(result.message)
                    }
                })
                .catch(error => console.log('error', error));
        }

    }

    return (
        <React.Fragment>
            <h1 className={styles.text_center}> Frequently Asked Questions</h1>
            <div className={styles.addSTC}>
                {faq && faq.map((item, index) =>
                    <div className={styles.addBannerRow} key={index}>
                        <div className={styles.faq_left}>
                            <span>Question - {index + 1}</span>
                            <textarea
                                type="text"
                                name="question"
                                value={item.question}
                                onChange={e => handleChangeFAQ(e, index)}
                                placeholder="Enter Question"
                                className={styles.faq_textArea}
                                rows="2"
                            />
                        </div>

                        <div className={styles.faq_right}>
                            <span>Answer - {index + 1}</span>
                            <textarea
                                type="text"
                                name="answer"
                                value={item.answer}
                                onChange={e => handleChangeFAQ(e, index)}
                                placeholder="Enter Question"
                                className={styles.faq_textArea}
                                rows="2"
                            />

                            <div className={styles.bannerDeleteBtn} onClick={(e) => handleRemoveFAQ(index, e)} >
                                <DeleteFile color="red" /><br />
                                Delete
                            </div>
                        </div>
                    </div>
                )}

                <div className={styles.addMoreBtn} onClick={() => handleAddFAQ()}>
                    + Add More Questions
                </div>

                <div className={styles.addProductBtmBtn}>
                    <RedOutButton type="button" title="CANCEL" css="" handleSubmit={() => navigate(-1)} />
                    <GreenButton type="submit" title={checkFaq ? "UPDATE" : "CREATE"} css="" handleSubmit={postFAQ} />
                </div>
            </div>
        </React.Fragment>
    )
}

export default AddFaq