import React from "react";
import styles from "../globalwhatsappchat.module.css";
import { useAuth } from "../../../context/Auth";

const CustomHeadingText = ({ text, spanText, customStyles }) => {
  const { mode } = useAuth();

  return (
    <>
      <label
        style={customStyles}
        className={`${styles.customText} ${
          mode ? styles.dark_text : styles.light_text
        }`}
      >
        {text}
      </label>
      {spanText && (
        <span
          style={customStyles}
          className={`${styles.customText} ${
            mode ? styles.dark_text : styles.light_text
          }`}
        >
          ({spanText})
        </span>
      )}
    </>
  );
};

export default CustomHeadingText;
