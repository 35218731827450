export const validateForm = (formData, Designation, SelectManger) => {
  const errors = {};

  if (!formData.fullName) errors.fullName = "Full Name is required.";
  else if (formData.fullName.length > 100)
    errors.fullName = "Full Name cannot exceed 100 characters.";

  if (!formData.mobileNumber)
    errors.mobileNumber = "Mobile Number is required.";
  else if (!/^\d{10}$/.test(formData.mobileNumber))
    errors.mobileNumber = "Mobile Number must be exactly 10 digits.";

  if (!formData.storeName) errors.storeName = "Store Name is required.";
  else if (formData.storeName.length > 200)
    errors.storeName = "Store Name cannot exceed 200 characters.";

  if (!Designation) errors.designation = "Designation is required.";

  if (!SelectManger) errors.selectManager = "Assign Order Manager is required.";

  if (!formData.whatsAppNumber)
    errors.whatsAppNumber = "WhatsApp Number is required.";
  else if (!/^\d{10}$/.test(formData.whatsAppNumber))
    errors.whatsAppNumber = "WhatsApp Number must be exactly 10 digits.";

  if (!formData.shippingAddress)
    errors.shippingAddress = "Shipping Address is required.";
  else if (formData.shippingAddress.length > 600)
    errors.shippingAddress = "Shipping Address cannot exceed 600 characters.";

  if (!formData.pin) errors.pin = "PIN Code is required.";
  else if (!/^\d{6}$/.test(formData.pin))
    errors.pin = "PIN Code must be exactly 6 digits.";

  if (!formData.state) errors.state = "State is required.";
  if (!formData.district) errors.district = "District is required.";
  if (!formData.city) errors.city = "City is required.";

  if (formData.gstUin.length > 15)
    errors.gstUin = "GSTUIN cannot exceed 15 characters.";

  return errors;
};
