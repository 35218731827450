import React, { useState } from 'react'
import { useAuth } from './context/Auth';
import { PoliceLightIcon, EyeIcon } from './icons/Icon';
import styles from '../pages/ordermanager/OrderManager.module.css'
import { GreenButton, RedOutButton } from './Buttons';
import { useNavigate } from 'react-router-dom';
import OrderModal from '../pages/ordermanager/OrderModal';
import Modal from '../components/Modal';

const OrderCard = ({ data, title }) => {
    const navigate = useNavigate();
    const { switchDisplay, managerId } = useAuth();
    const [msgShow, setMsgShow] = useState("");
    const [showItemModal, setShowItemModal] = useState(false);
    const [showAcceptModal, setShowAcceptModal] = useState(false);
    const [orderData, setOrderData] = useState({})
    const [itemData, setItemData] = useState("")

    const hamdleGetOrder = (orderId) => {
        setItemData("");
        setOrderData("")
        var myHeaders = new Headers();
        myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "managerId": managerId,
            "orderId": orderId
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(process.env.REACT_APP_URL + "order/order", requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status === "success") {
                    setItemData(result.data.items)
                    setOrderData(result.data)
                }
            })
            .catch(error => console.log('error', error));
    }

    const handleOrderAccept = () => {
        setMsgShow("")
        var myHeaders = new Headers();
        myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "managerId": 2,
            "orderId": "3"
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(process.env.REACT_APP_URL + "order/orderAccept", requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status === "success") {
                    setShowAcceptModal(true)
                    setMsgShow(result.msg)
                }
                else {
                    setShowAcceptModal(true)
                    setMsgShow(result.msg)
                }
            })
            .catch(error => console.log('error', error));
    }


    return (
        <React.Fragment>

            <OrderModal
                show={showItemModal}
                close={setShowItemModal}
                closeBtn={true}
                heading={orderData.storeName}
                content={
                    <div className={styles.text_center}>
                        <div className={styles.product_date}>
                            <span>Dealer ID: {orderData.dealerId}</span>
                            <span>  {orderData.orderDate}</span>
                        </div>
                        <div className={styles.product_date}>Order ID: {orderData.orderId}</div>

                        <div className={styles.itemCard_div}>
                            {itemData && itemData.map((item, index) =>
                                <div key={index} className={switchDisplay ? styles.itemCard_light : styles.itemCard}>
                                    <div className={styles.itemCard_left}>
                                        <img src={process.env.REACT_APP_S3URL + item.thumbnail} alt="item_Image" className={styles.itemImage} />
                                        <div className={styles.itemDetail_div}>
                                            <div className={styles.itemDetail}>
                                                <span className={styles.itemName_txt}>Product:</span>
                                                <span className={styles.itemName}>{item.product_name}</span>
                                            </div>
                                            <div className={styles.itemDetail}>
                                                <span className={styles.itemName_txt}>Item No:</span>
                                                <span className={styles.itemName}>{item.itemNo}</span>
                                            </div>
                                            <div className={styles.itemDetail}>
                                                <span className={styles.itemName_txt}>Price:</span>
                                                <span className={styles.itemName}>{parseFloat(item.price).toLocaleString("en-IN", { style: "currency", currency: "INR" })}/roll</span>
                                            </div>
                                            <div className={styles.itemDetail}>
                                                <span className={styles.itemName_txt}>Qty:</span>
                                                <span className={styles.itemName}>{item.qty} roll</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={styles.itemCard_right}>
                                        <span className={styles.itemName_txt}>Total:</span>
                                        <span className={styles.itemName}>&nbsp;{(parseFloat(item.price) * parseFloat(item.qty)).toLocaleString("en-IN", { style: "currency", currency: "INR" })}/-</span>
                                    </div>
                                </div>
                            )}
                        </div>

                        <div className={switchDisplay ? styles.modalFooter_div_light : styles.modalFooter_div}>
                            <div className={styles.modalFooter_btn}>
                                <RedOutButton title="CANCEL" handleSubmit={() => setShowItemModal()} />
                                <GreenButton title="ACCEPT" handleSubmit={() => handleOrderAccept()} />
                            </div>
                            <div className={styles.modalFooter_GTotal}>
                                <div className={styles.borderBottom_div}>
                                    <div className={styles.total_div}>
                                        <span className={styles.itemName_txt}>Total</span>
                                        <span className={styles.total_rs}>{parseFloat(orderData.subtotal).toLocaleString("en-IN", { style: "currency", currency: "INR" })}</span>
                                    </div>
                                    <div className={styles.total_div}>
                                        <span className={styles.itemName_txt}>Shipping Charges</span>
                                        <span className={styles.total_rs}>{parseFloat(orderData.shippingCharge).toLocaleString("en-IN", { style: "currency", currency: "INR" })}</span>
                                    </div>
                                </div>
                                <div className={styles.grandTotal}>
                                    <span>Grand Total:</span>
                                    <span>{parseFloat(orderData.totalAmount).toLocaleString("en-IN", { style: "currency", currency: "INR" })}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            />

            <Modal
                show={showAcceptModal}
                close={setShowAcceptModal}
                closeBtn={true}
                heading="Order Status"
                content={
                    <div className={styles.text_center}>
                        <p className={styles.message}>{msgShow}</p>
                        <div className={styles.massageModal_btn}>
                            <RedOutButton title="CANCEL" handleSubmit={() => setShowAcceptModal(false)} />
                            <GreenButton title="OK" handleSubmit={() => { setShowAcceptModal(false); navigate('/SaleOrder') }} />
                        </div>
                    </div>
                }
            />

            <div className={styles.col}>
                <div className={switchDisplay ? styles.orderCard_light : styles.orderCard}>
                    <h4 className={styles.orderCard_heading}>{title}</h4>
                    {/* <p className={styles.orderCard_subHeading}>Lorem ipsum dolor sit amet, consectetur</p> */}

                    <div className={styles.scroll_height}>
                        {
                            data.length !== 0 ? data.map((item, index) =>
                                <div key={index} className={switchDisplay ? styles.productCard_light : styles.productCard}

                                    onClick={() => {
                                        if (title === "NEW ORDER") {
                                            setShowItemModal(true)
                                            hamdleGetOrder(item.orderId)
                                        }
                                    }}

                                >
                                    <div className={styles.justifyContentBetween}>
                                        <div className={styles.panelCart_txt}>{item.cartType}</div>
                                        <div className={styles.orderCard_date}>
                                            <span>{item.order_date}</span>
                                            <span className={styles.ml_10px}><PoliceLightIcon color={switchDisplay ? "#000000" : "#FFFFFF"} /></span>
                                        </div>
                                    </div>

                                    <div className={styles.justifyContentBetween}>
                                        <div className={styles.productName}>
                                            {item.storeName}
                                        </div>
                                        <div>
                                            <EyeIcon color={switchDisplay ? "#000000" : "#FFFFFF"} />
                                        </div>
                                    </div>

                                    <div className={styles.justifyContentBetween}>
                                        <div className={styles.orderId}>
                                            <span>Order ID: </span>
                                            <span>{item.orderId}</span>
                                        </div>
                                        <div className={styles.orderId}>
                                            <span className={styles.view}>View</span>
                                        </div>
                                    </div>

                                    <div className={styles.justifyContentBetween}>
                                        <div className={styles.orderId}>
                                            <span>Total:</span>
                                            <span> ₹ {item.totalAmount}/-</span>
                                        </div>

                                        {item.status && <div className={
                                            item.status === 'Waiting For DealerApproval' ? styles.status_orange :
                                                item.status === 'Check Stock & Approve' ? styles.status_yellow :
                                                    item.status === 'Payment Pending' ? styles.status_red :
                                                        item.status === 'In Progress' ? styles.status_green :
                                                            item.status === 'Dispatched' ? styles.status_green :
                                                                ""
                                        }>
                                            <span>&#9679;&nbsp;</span>
                                            <span>{item.status}</span>
                                        </div>}
                                    </div>
                                </div>
                            )
                                :
                                <div className={styles.No_Data}>No Data</div>
                        }
                    </div>

                </div>
            </div>

        </React.Fragment>
    )
}

export default OrderCard