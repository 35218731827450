import React, { useEffect, useRef, useState } from 'react'
import styles from './Camp.module.css'
import TopTabBar from '../../components/updatedComponents/Tanolet/TopTabBar'
import { useAuth } from '../../components/context/Auth'
import SearchBar from '../../components/updatedComponents/FormComponents/SearchBar'
import GoToPage from '../../components/updatedComponents/Pagnation/GoToPage'
import Pagenation from '../../components/updatedComponents/Pagnation/Pagenation'
import Button from '../../components/updatedComponents/Dealers/Button'
import { AddBtnicon, TemplateDone, TemplateNotFound } from '../../components/icons/Icon2'
import DrawerWrapper from '../../components/updatedComponents/layoutWrapper/DrawerWrapper'
import InputTag from '../../components/updatedComponents/FormComponents/InputTag'
import { Back, Pluse } from '../../components/icons/Icon3'
import { LocationsFilter } from '../../components/updatedComponents/Filter/LocationsFilter'
import { TextColorCode, truncateText } from '../../Utils/TextUtils'
import useWindowWidth from '../../components/updatedComponents/Hooks/WidthSize'
import TimeOutFetch from '../../APIsControll/TimeOutFechControll'
import NoDataFound from '../../components/updatedComponents/NoDataFound/NoDataFound'
import Skeleton from '../../components/updatedComponents/Skeleton/Skeleton'
import { useToast } from '../../components/updatedComponents/Toaster/Toaster'
import useDebounce from '../../Utils/Debounce'
import SrarchPin from '../../components/updatedComponents/FormComponents/SrarchPin'
import { useNavigate } from 'react-router-dom'
const CreateGroup = () => {
    const { mode, managerId } = useAuth();
    const width = useWindowWidth()
    const [isOpen, setIsOpen] = useState(false);
    const [dealerSarch, setDealerSarch] = useState("");
    const { show } = useToast()
    const boxRef = useRef(null);
    const boxRef1 = useRef(null);
    const boxRef2 = useRef(null);
    const boxRef3 = useRef(null);
    const statusOption = [
        { label: "All" },
        { label: "New" },
        { label: "Rejected" },
        { label: "Deleted" },
        { label: "Verified" },
        { label: "Pending" },
        { label: "Update" },
        { label: "Blacklisted" },
        // { label: "Stockists" },
        // { label: "Not Active" },
        // { label: "Not Verified" },
        // { label: "Purchase" },
        // { label: "Not Purchase" }
    ]
    const toggleDrawer = () => {
        setIsOpen(true);
    };
    const closeDrawer = () => {
        setIsOpen(false);
    };

    const [loading, setloading] = useState({
        loading1: false,
        loading2: false,

    });
    const [sarchLocation, setSarchLocation] = useState({
        stateSearchLocation: "",
        DistricsSearchLocation: "",
        CitySearchLocation: "",
        pincodeSarch: ""
    });
    const Navigate = useNavigate()


    const [isOpenSelectSegment, setIsOpenSelectSegment] = useState(false);
    const [selectedOption, setSelectedOption] = useState("Select");

    const [showMenu, setShowMenu] = useState(false);
    const [showMenu1, setShowMenu1] = useState(false);
    const [showMenu2, setShowMenu2] = useState(false);

    const [findGroupDealers, setfindGroupDealers] = useState([]);
    const [page, setpage] = useState(1)
    const [pages, setpages] = useState(1)
    const [selectAll, setSelectAll] = useState(false);

    const [locations0, setlocations0] = useState([]);
    const [sarchValue, setSarchValue] = useState('');
    const [filteredLocations, setFilteredLocations] = useState(locations0);
    const [selectedLocations, setSelectedLocations] = useState([]);

    const [locations1, setlocations1] = useState([]);
    const [sarchValue1, setSarchValue1] = useState('');
    const [filteredLocations1, setFilteredLocations1] = useState(locations1);
    const [selectedLocations1, setSelectedLocations1] = useState([]);

    const [locations2, setlocations2] = useState([]);
    const [sarchValue2, setSarchValue2] = useState('');
    const [filteredLocations2, setFilteredLocations2] = useState(locations2);
    const [selectedLocations2, setSelectedLocations2] = useState([]);


    const [groupDealer, setGroupDealer] = useState([])
    const [groupDetails, setGroupDetails] = useState({
        name: "",
        description: ""
    });

    const handleSearch = (value) => {
        setSarchValue(value);
        setFilteredLocations(
            locations0.filter((loc) =>
                loc.toLowerCase().includes(value.toLowerCase())
            )
        );
    };
    const handleSearch1 = (value) => {
        setSarchValue1(value);
        setFilteredLocations1(
            locations1.filter((loc) =>
                loc.toLowerCase().includes(value.toLowerCase())
            )
        );
    };
    const handleSearch2 = (value) => {
        setSarchValue2(value);
        setFilteredLocations2(
            locations2.filter((loc) =>
                loc.toLowerCase().includes(value.toLowerCase())
            )
        );
    };

    // get location filter 
    const getLoactionFilter = async () => {
        const payload = {
            managerId: managerId,
            stateSearch: "",
            districtSearch: "",
            citySearch: "",
            pincodeSearch: sarchLocation.pincodeSarch,
            locations: {
                states: [],
                districts: [],
                cities: [],
                pincodes: []
            }
        };
        try {
            const res = await TimeOutFetch(`wallikonCampaign/filterLocation`, payload);
            // setlocations0(prev => [...prev, ...res?.data?.states]);
            setlocations0(res?.data?.states);
            setlocations1(res?.data?.districts);
            setlocations2(res?.data?.cities);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        setFilteredLocations(locations0);
    }, [locations0]);
    useEffect(() => {
        setFilteredLocations1(locations1);
    }, [locations1]);
    useEffect(() => {
        setFilteredLocations2(locations2);
    }, [locations2]);


    useEffect(() => {
        const handleClickOutside = (event) => {
            if (boxRef.current && !boxRef.current.contains(event.target)) {
                setIsOpenSelectSegment(false);
            }
            if (boxRef1.current && !boxRef1.current.contains(event.target)) {
                setShowMenu(false)
            }
            if (boxRef2.current && !boxRef2.current.contains(event.target)) {
                setShowMenu1(false)
            }
            if (boxRef3.current && !boxRef3.current.contains(event.target)) {
                setShowMenu2(false)
            }
        };
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);


    const getDealser = async () => {
        try {
            setloading(prve => ({
                ...prve, loading1: true
            }))
            const payload = {
                managerId: managerId,
                keyword: dealerSarch,
                status: selectedOption === "All" || selectedOption === "Select" ? "" : selectedOption,
                locations: {
                    states: selectedLocations.length > 0 ? selectedLocations : [],
                    districts: selectedLocations1.length > 0 ? selectedLocations1 : [],
                    cities: selectedLocations2.length > 0 ? selectedLocations2 : [],
                    pincodes: sarchLocation.pincodeSarch ? [sarchLocation.pincodeSarch] : []
                }
            };
            const res = await TimeOutFetch(`wallikonCampaign/findGroupDealers?page=${page}&limit=${10}`, payload);
            setloading(prve => ({
                ...prve, loading1: false
            }))
            setfindGroupDealers(res?.data);
            setpage(res?.page);
            setpages(res?.pages);

        } catch (error) {
            setloading(prve => ({
                ...prve, loading1: false
            }))
        }
    }


    const CreateGroup = async (e) => {
        e.preventDefault()
        try {
            if (groupDealer.length <= 0) return show("Please select dealers", "error");
            const payload = {
                managerId: managerId,
                groupName: groupDetails.name,
                description: groupDetails.description,
                dealerInfo: groupDealer
            }
            setloading(prve => ({  
                ...prve, loading2: true
            }))
            const res = await TimeOutFetch(`wallikonCampaign/createGroup`, payload);
            setloading(prve => ({
                ...prve, loading2: false
            }))
            if (res.status === "success") {
                Navigate("/groups")
                closeDrawer();

                setGroupDetails({ name: "", description: "" })
                show(`${res.msg}`, "success");
                return
            }
            if (res.status === "failed") {
                return show(`${res.msg}`, "error")
            } else {
                return show("Something went wrong", "error");

            };
        } catch (error) {
            setloading(prve => ({
                ...prve, loading2: false
            }))
            return show("Something went wrong", "error");
        }
    }


    const handleIndividualCheckbox = (event, dealer) => {
        const isChecked = event.target.checked;
        if (isChecked) {
            setGroupDealer(prev => [...prev, dealer]);
        } else {
            setGroupDealer(prev => prev.filter(d => d?.dealerId !== dealer?.dealerId));
        }
        if (!isChecked) {
            setSelectAll(false);
        } else if (findGroupDealers.every(d => groupDealer.some(g => g.dealerId === d.dealerId) || d.dealerId === dealer.dealerId)) {
            setSelectAll(true);
        }
    };


    const handleSelectAll = (event) => {
        const isChecked = event.target.checked;
        setSelectAll(isChecked);
        if (isChecked) {
            setGroupDealer(findGroupDealers.map(dealer => {
                const { _id, updatedAt, createdAt, ...cleanedItem } = dealer;
                return cleanedItem;
            }));
        } else {
            setGroupDealer([]);
        }
    };


    useDebounce(getDealser, 400, [page, selectedOption, dealerSarch, selectedLocations, selectedLocations1, selectedLocations2, sarchLocation.pincodeSarch]);

    useEffect(() => {
        getLoactionFilter();
    }, []);


    return (
        <div className={styles.mainCintner}>
            <div className={styles.Continer} style={{ backgroundColor: mode ? "#2C2E33" : "#F8F9FC" }}>
                <DrawerWrapper isOpen={isOpen} onClose={closeDrawer} >
                    <div className={mode ? styles.DrawerWrapperContinetr : styles.DrawerWrapperContinetr_light} >
                        <div className={styles.DrawerWrapperText} >
                            <span>Selected: {groupDealer.length}</span>
                        </div>
                        <form onSubmit={CreateGroup} >
                            <div className={styles.DrawerWrapperMainCointner} >
                                <div className={styles.DrawerWrapperInputConintner1} >
                                    <div className={styles.inputWidth} >
                                        <InputTag
                                            placeholderText={"New Group..."}
                                            labelText={"Group Name"}
                                            placeholderType={"text"}
                                            onChange={(e) => setGroupDetails(prv => ({
                                                ...prv, name: e.target.value
                                            }))}
                                            maxLength={50}
                                            required={true}
                                        />
                                    </div>
                                    <div className={styles.inputWidth} >
                                        <InputTag
                                            placeholderText={"Lucknow Trader..."}
                                            labelText={"Description"}
                                            placeholderType={"text"}
                                            onChange={(e) => setGroupDetails(prv => ({
                                                ...prv, description: e.target.value
                                            }))}
                                            maxLength={100}
                                            required={true}
                                        />
                                    </div>
                                </div>
                                <div className={styles.DrawerWrapperInputConintner} >
                                    <div className={styles.btnwidth} >
                                        <Button
                                            buttonBgColor={"#000000"}
                                            buttonIcon={<Back />}
                                            buttonTextColor={"#fff"}
                                            buttonText={"Back"}
                                            buttonClick={closeDrawer}
                                        />
                                    </div>
                                    <div className={styles.btnwidth} >
                                        <Button
                                            buttonBgColor={"#4164E3"}
                                            buttonIcon={<TemplateDone />}
                                            buttonTextColor={"#fff"}
                                            buttonText={"Create"}
                                            // buttonClick={CreateGroup}
                                            loading={loading.loading2}
                                        />
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </DrawerWrapper>

                <div className={styles.goBackCon}  >
                    <div className={styles.gobacFristChlid1} >
                        <div style={{ width: "100%" }}>
                            <TopTabBar text={"New Group"} />

                        </div>
                        <div style={{ maxWidth: "170px", height: "100%", display: "flex", justifyContent: "center" }} >
                            <Button
                                buttonBgColor={"#4164E3"}
                                buttonText={width > 700 ? "Create Group" : null}
                                // buttonIcon={<CreateTem />}
                                buttonIcon={<Pluse />}
                                buttonTextColor={"#fff"}
                                buttonClick={toggleDrawer}
                            />
                        </div>
                    </div>
                </div>

                <div className={styles.sarchBarConinnter}>
                    <div className={styles.sarchconGrop}>
                        <div className={styles.sacrchGroup} >
                            <SearchBar placeholder={"Search"} onChange={(e) => setDealerSarch(e.target.value)} />
                        </div>
                        <div className={styles.cont} style={{ color: mode ? "white" : "black" }} >
                            <div>
                                <span style={{ fontSize: "12px", fontWeight: "500", color: mode ? "#fff" : "#000" }} > Status </span>
                            </div>
                            <div className={styles.Who_Select3} style={{
                                background: mode ? "#1B1D21" : "#fff",
                                border: mode ? "none" : "1px solid #E4E7EB",
                            }}
                                onClick={() => setIsOpenSelectSegment(!isOpenSelectSegment)}
                                ref={boxRef}
                            >
                                <div>
                                    <span style={{ fontSize: "12px" }}>{selectedOption}</span>
                                </div>
                                <div>
                                    <span>
                                        <AddBtnicon mode={mode} rotate={isOpenSelectSegment ? 180 : 0} />
                                    </span>
                                </div>
                                {isOpenSelectSegment && (
                                    <div className={styles.Who_selectBox} style={{
                                        backgroundColor: mode ? "#000" : "#fff",
                                        border: mode ? "none" : "1px solid #E4E7EB",
                                        zIndex: "9999",
                                        top: "100%",
                                        left: "0%"
                                        // left: width > 701 ? "100%" : "-30%",
                                        // top: width > 701 ? "0%" : "100%"
                                    }} >
                                        {statusOption.map((e, i) => (
                                            <div className={mode ? styles.Who_selectBoxhover_dark : styles.Who_selectBoxhover} key={i}
                                                onClick={() => {
                                                    setSelectedOption(e?.label);
                                                    setIsOpenSelectSegment(false);
                                                }}>
                                                {e?.label}
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className={styles.iconWrapper0} style={{ color: mode ? "white" : "black" }} ref={boxRef1} >
                            <div>
                                <span style={{ fontSize: "12px", fontWeight: "500", color: mode ? "#fff" : "#000" }} > State </span>
                            </div>
                            <div className={styles.Who_Select3} style={{
                                background: mode ? "#1B1D21" : "#fff",
                                border: mode ? "none" : "1px solid #E4E7EB",
                                position: "relative"
                            }}
                                onClick={() => setShowMenu(!showMenu)}
                            >
                                <div> <span style={{ fontSize: "12px" }}> Haryana </span>  </div>
                                <div> <span> <AddBtnicon mode={mode} rotate={showMenu ? 180 : 0} />  </span>  </div>

                            </div>
                            {
                                showMenu &&
                                <div className={styles.NewLocatiionFilter} style={{ top: "100%", left: "0%", zIndex: "999", backgroundColor: mode ? "#000" : "#fff", color: mode ? "#fff" : "#000" }} >
                                    <LocationsFilter
                                        locations={filteredLocations}
                                        sarchValue={sarchValue}
                                        sareshOnchange={handleSearch}
                                        selectedLocations={selectedLocations}
                                        setSelectedLocations={setSelectedLocations}
                                    />
                                </div>
                            }
                        </div>
                        <div className={styles.iconWrapper0} style={{ color: mode ? "white" : "black" }} ref={boxRef2} >
                            <div>
                                <span style={{ fontSize: "12px", fontWeight: "500", color: mode ? "#fff" : "#000" }} > District   </span>
                            </div>
                            <div className={styles.Who_Select3} style={{
                                background: mode ? "#1B1D21" : "#fff",
                                border: mode ? "none" : "1px solid #E4E7EB",
                                position: "relative"
                            }}
                                onClick={() => setShowMenu1(!showMenu1)}
                            >
                                <div> <span style={{ fontSize: "12px" }}> Kheri </span>  </div>
                                <div> <span> <AddBtnicon mode={mode} rotate={showMenu1 ? 180 : 0} />  </span>  </div>

                            </div>
                            {
                                showMenu1 &&
                                <div className={styles.NewLocatiionFilter} style={{ top: "100%", left: "0%", zIndex: "999", backgroundColor: mode ? "#000" : "#fff", color: mode ? "#fff" : "#000" }} >
                                    <LocationsFilter
                                        locations={filteredLocations1}
                                        sarchValue={sarchValue1}
                                        sareshOnchange={handleSearch1}
                                        selectedLocations={selectedLocations1}
                                        setSelectedLocations={setSelectedLocations1}
                                    />
                                </div>
                            }

                        </div>
                        <div className={styles.iconWrapper0} style={{ color: mode ? "white" : "black" }} ref={boxRef3} >
                            <div>
                                <span style={{ fontSize: "12px", fontWeight: "500", color: mode ? "#fff" : "#000" }} > City  </span>
                            </div>
                            <div className={styles.Who_Select3} style={{
                                background: mode ? "#1B1D21" : "#fff",
                                border: mode ? "none" : "1px solid #E4E7EB",
                                position: "relative"
                            }}
                                onClick={() => setShowMenu2(!showMenu2)}
                            >
                                <div> <span style={{ fontSize: "12px" }}> Lucknow </span>  </div>
                                <div> <span> <AddBtnicon mode={mode} rotate={showMenu2 ? 180 : 0} />  </span>  </div>

                            </div>
                            {
                                showMenu2 &&
                                <div className={styles.NewLocatiionFilter} style={{ top: "100%", left: "0%", zIndex: "999", backgroundColor: mode ? "#000" : "#fff", color: mode ? "#fff" : "#000" }} >
                                    <LocationsFilter
                                        locations={filteredLocations2}
                                        sarchValue={sarchValue2}
                                        sareshOnchange={handleSearch2}
                                        selectedLocations={selectedLocations2}
                                        setSelectedLocations={setSelectedLocations2}
                                    />
                                </div>
                            }
                        </div>


                        <div className={styles.cont} style={{ color: mode ? "white" : "black" }} >
                            <div>
                                <span style={{ fontSize: "12px", fontWeight: "500", color: mode ? "#fff" : "#000" }} > Pin  </span>
                            </div>
                            <div style={{ fontSize: "12px", fontWeight: "400" }}>
                                <SrarchPin
                                    placeholder={"Search"}
                                    type={"number"}
                                    maxLength={6}
                                    onChange={(event) => setSarchLocation((prv) => ({
                                        ...prv, pincodeSarch: event
                                    }))}
                                />
                            </div>
                        </div>

                    </div>
                </div>

                <div className={styles.tableContiner}>
                    <div className={styles.container} style={{ height: "100%" }}>
                        <ul className={styles.responsiveTable} style={{ height: "100%" }}>
                            <li className={styles.tableHeader} style={mode ? { backgroundColor: "#232529", color: "#fafafa" }
                                : { backgroundColor: "#D9DCE5" }} >
                                <div className={`${styles.col} `} >
                                    <div className={styles.TableHeadChackBox} >
                                        {/* <label className={styles.cl_checkbox} >
                                            <input
                                                type="checkbox"
                                                checked={selectAll}
                                                onChange={handleSelectAll}
                                            />
                                            <span></span>
                                        </label> */}
                                    </div>
                                    Dealer Name </div>
                                <div className={`${styles.col} ${styles.large}`} > <span>Address </span>  </div>
                                <div className={styles.col} >State</div>
                                <div className={styles.col} >District</div>
                                <div className={styles.col} >City</div>
                                <div className={styles.col} >PIN Code</div>
                                <div className={styles.col} >Status</div>
                            </li>
                            <div style={{ overflowY: "scroll", height:"100%"}}>

                                {
                                    loading.loading1 ? <Skeleton lines={8} width="100%" thickness={8} height='400px' /> : findGroupDealers.length === 0 ? <> {<>
                                        <div style={{ height: "90%" }}>
                                            <NoDataFound
                                                icon={<TemplateNotFound />}
                                                linkname={"add a template."}
                                                textTitle={`No Dealers found. To run a WhatsApp campaign`}
                                                to={"/newgroup"} /> </div>
                                    </>}  </> :

                                        findGroupDealers.map((e, i) =>
                                        // Array.from({length:20}).map((e, i) =>
                                            <li className={styles.tablerow} style={mode ? { backgroundColor: "#1B1D21", color: "#fafafa" } : { backgroundColor: "#ffff" }} key={i} >
                                                <div className={`${styles.col} ${styles.coljustyfy}   `} data-label="Dealer Name" >
                                                    <div className={styles.TableHeadChackBox} >
                                                        <label className={styles.cl_checkbox} >
                                                            <input type="checkbox"
                                                                onChange={(event) => handleIndividualCheckbox(event, e)}
                                                                checked={groupDealer.some(dealer => dealer?.dealerId === e?.dealerId)}
                                                            />
                                                            <span></span>
                                                        </label>
                                                    </div>
                                                    <div>
                                                        {e?.dealerName}
                                                    </div>
                                                </div>
                                                <div className={`${styles.col} ${styles.large}`} data-label="Address" > {truncateText(`${e?.address}`, 27)}  </div>
                                                <div className={styles.col} data-label="State" > {e?.state} </div>
                                                <div className={styles.col} data-label="District" > {e?.district} </div>
                                                <div className={styles.col} data-label="City" > {e?.city} </div>
                                                <div className={styles.col} data-label="PIN Code" > {e?.pincode} </div>
                                                <div className={styles.col} data-label="Status" style={{ color: TextColorCode(e?.status) }}>
                                                    {e?.status}
                                                </div>
                                            </li>
                                        )
                                }
                            </div>
                            <li style={{ height: "20px" }}></li>
                        </ul>
                    </div>
                </div>

                <div className={styles.pagnationConintner} style={{ backgroundColor: mode ? "#232529" : "#fff" }}>
                    <div className={styles.gotToPage1} >
                        <GoToPage currentPage={page} setCurrentPage={setpage} totalPages={pages} key={"1"} />
                    </div>
                    <div className={styles.gotToPage2}  >
                        <Pagenation currentPage={page} setCurrentPage={setpage} totalPages={pages} key={"1"} />
                    </div>
                </div>

            </div>
        </div>
    )
}

export default CreateGroup


