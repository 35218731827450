// import React from 'react';
// // import styles from "./FromComponent.module.css";
// import { useAuth } from '../../context/Auth';
// import styles from "./FromComponent.module.css"
// const InputTypedate = ({ text, onChange, value }) => {
//     const { mode } = useAuth();
//     const today = new Date().toISOString().split('T')[0];

//     const inputStyle = {
//         padding: '12px 3px',
//         color: mode ? '#fff' : '#1B1D21',
//         fontSize: '13px',
//         border: 'none',
//         outline: 'none',
//         borderRadius: '5px',
//         position: 'relative',
//         backgroundColor: mode ? '#1B1D21' : '#fff',
//         backgroundImage: mode
//             ? "url('./assets/updatedAssets/dateblack2.png')"
//             : "url('./assets/updatedAssets/date.png')",
//         backgroundRepeat: 'no-repeat',
//         backgroundPosition: 'right 3px center',
//         backgroundSize: '16px', // Adjust this to control the size of the icon
//         cursor: 'pointer',
//         appearance: 'none',
//         // WebkitAppearance: 'none',
//     };

//     return (
//         <div className={styles.dateInputWrapper}>
//         <span style={{ color: mode ? '#fafafa' : '#1B1D21', marginRight: '10px' }}>{text}</span>
//         <input
//             type="date"
//             className={styles.inputTypeDate1}
//             onChange={onChange}
//             style={inputStyle}
//             value={value}
//             max={today}
//         />
//     </div>
//     );
// };

// export default InputTypedate;

// // import React, { useRef } from 'react';
// // import { useAuth } from '../../context/Auth';
// // import styles from "./FromComponent.module.css";
// // // import calendarIcon "./"
// // const InputTypedate = ({ text, onChange, value }) => {
// //     const { mode } = useAuth();
// //     const today = new Date().toISOString().split('T')[0];
// //     const dateInputRef = useRef(null);

// //     const handleIconClick = () => {
// //         if (dateInputRef.current) {
// //             dateInputRef.current.showPicker(); // Modern browsers support this
// //             dateInputRef.current.focus(); // Fallback for older browsers
// //         }
// //     };

// //     return (
// //         <div className={styles.inputContainer}>
// //             <span style={{ color: mode ? '#fafafa' : '#1B1D21', marginRight: '10px' }}>{text}</span>
// //             <div className={styles.dateWrapper} style={{ backgroundColor: mode ? "#000" : "#fff" }}>
// //                 <input
// //                     type="date"
// //                     ref={dateInputRef} // Use ref to reference the input
// //                     className={styles.inputTypeDate}
// //                     onChange={onChange}
// //                     value={value}
// //                     max={today}
// //                     style={{ cursor: 'pointer', backgroundColor: mode ? "#000" : "#fff", color:mode?"#fff":"#000" }} // Change cursor to pointer
// //                 />
// //                 <img
// //                     src={"./assets/updatedAssets/darkDate.png"}
// //                     alt="Calendar Icon"
// //                     className={styles.calendarIcon}
// //                     onClick={handleIconClick} // Focus input on icon click
// //                 />
// //             </div>
// //         </div>
// //     );
// // };

// // export default InputTypedate;

import React from "react";
import { useAuth } from "../../context/Auth";
import styles from "./FromComponent.module.css";

const InputTypedate = ({ text, onChange, value, min, max }) => {
    const { mode } = useAuth();
    const today = new Date().toISOString().split('T')[0];

    const inputStyle = {
        width: '135px', // Set a fixed width
        boxSizing: 'border-box', // Include padding and border in the width
        padding: '12px ',
        color: mode ? '#fff' : '#1B1D21',
        fontSize: '13px',
        outline: 'none',
        borderRadius: '5px',
        position: 'relative',
        backgroundColor: mode ? '#1B1D21' : '#fff',
        cursor: 'pointer',
        appearance: 'none',
        WebkitAppearance: 'none',
        border: mode ? "none" : "1px solid #E4E7EB",
        
    };

    return (
        <div className={styles.dateInputWrapper} style={{ position: 'relative', display:"flex", justifyContent:"center"}}>
            <span style={{ color: mode ? '#fafafa' : '#1B1D21', marginRight: '8px', fontSize: "12px", marginTop:"12px" }}>{text}</span>
            <input
                type="date"
                className={styles.inputTypeDate1}
                onChange={onChange}
                style={inputStyle}
                value={value}
                max={min ? null : max || today}
                min={min}
            />
            <div
                className={styles.backgroundIcon}
                style={{
                    content: '',
                    position: 'absolute',
                    top: '50%',
                    right: '10px',
                    transform: 'translateY(-50%)',
                    width: '18px', // Adjust size as necessary
                    height: '18px',
                    backgroundImage: mode
                        ? "url('./assets/updatedAssets/dateblack2.png')"
                        : "url('./assets/updatedAssets/date.png')",
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'contain',
                    zIndex: 999,
                    pointerEvents: 'none',
                    cursor: "pointer"
                }}
            />
        </div>
    );
};

export default InputTypedate;
