const fetchData = async (endpoint, apiData) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_URL}${endpoint}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "wa-platform": process.env.REACT_APP_ADMIN_SECRET,
      },
      body: JSON.stringify(apiData),
    });

    const result = await response.json();
    return result;
  } catch (error) {
    return error;
  }
};
export default fetchData;
