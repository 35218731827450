import React, { forwardRef } from "react";
import styles from "../../../pages/Home.module.css";
import { useAuth } from "../../context/Auth";
import { Spinner } from "../../icons/Icon";

const InputTag = forwardRef(
  (
    {
      id,
      labelText,
      placeholderType,
      placeholderText,
      required,
      value,
      onChange,
      star,
      disabled,
      name,
      maxLength,
      loading,
      padding,
      fontSize,
      onFocus,
      onBlur,
      onKeyDown,
      autoComplete,
      readOnly,
    },
    ref
  ) => {
    // Destructure `ref` here for forwardRef
    const { mode } = useAuth();

    const handleChange = (e) => {
      const newValue = e.target.value;
      if (newValue.length <= maxLength) {
        onChange(e);
      }
    };

    const handleKeyPress = (e) => {
      if (e.key === "Enter") {
        e.preventDefault(); // Prevent the default form submission
      }
    };

    return (
      <div className={styles.login_Form_element}>
        <label
          htmlFor={id}
          className={mode ? styles.login_Form_label1 : styles.login_Form_label}
        >
          {labelText}
          {star && <span style={{ color: "red" }}>*</span>}
        </label>
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            className={
              mode ? styles.login_Form_input1 : styles.login_Form_input
            }
          >
            <span>
              <Spinner size="15" />
            </span>
          </div>
        ) : (
          <input
            {...(maxLength && { maxLength: Number(maxLength) })}
            name={name}
            id={id}
            type={placeholderType}
            placeholder={placeholderText}
            required={required}
            value={value}
            onChange={handleChange}
            disabled={disabled}
            onKeyPress={handleKeyPress}
            onFocus={onFocus}
            onBlur={onBlur}
            onKeyDown={onKeyDown}
            ref={ref} // This attaches the forwarded ref
            className={
              mode ? styles.login_Form_input1 : styles.login_Form_input
            }
            style={{
              border: mode ? "none" : "1px solid #ccc",
              backgroundColor: disabled && "#ccc",
              cursor: disabled && "not-allowed",
              padding: padding && padding,
              fontSize: fontSize && fontSize,
            }}
            autoComplete={autoComplete}
            readOnly={readOnly}
          />
        )}
      </div>
    );
  }
);

export default InputTag;
