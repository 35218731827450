import React from "react";
import styles from "./Test.module.css";
import Button from "../Dealers/Button";
import {
  TestTopBackArrowIcon,
  TestTopDotIcon,
  NavbarSearchIcon,
  DispatchReloadIcon,
  DownloadIcon,
} from "../../icons/Icon";
import { useAuth } from "../../context/Auth";
import { useNavigate } from "react-router-dom";
import SlidingFilter from "../Dealers/SlidingFilter";
import DealerStatusCard from "../Dealers/DealerStatusCard";
import DropDown from "../FormComponents/DropDown";
import InputTypedate from "../FormComponents/InputTypedate";

const Demofile= () => {
  
  const { mode } = useAuth();
  const navigate = useNavigate();
  const routeName = "Dispatch";
  const slidingFilter1 = ["Today", "Yesterday", "This Month", "Last 30 Days"];
  const slidingFilter2 = [
    "All",
    "Sales",
    "Purchase",
    "Adjustment",
    "Transfer",
    "Production",
    "Production & Sale",
    "Production & Transfer",
  ];
  const dispatchStatusData = [
    { statusText: "Active", statusItemCount: 1 },
    { statusText: "Disabled", statusItemCount: 2 },
  ];
  const dispatchHeader = [
    "Name & City",
    "Type",
    "Date",
    "Order ID",
    "Packer",
    "Order Manager",
    "Source",
    "Sub- Method",
    "Delivery Partner",
    "Status",
    "Last Action",
  ];
  const dispatchData = [
    "Mohit, Lucknow",
    "Purchase",
    "11-07-2024",
    "123456",
    "RRA Admin",
    "Ajay Sahu",
    "Online",
    "Rapido",
    "Rapido",
    "New Order",
    "2 Days",
  ];
  return (
    <div className={`${styles.dispatch} ${mode ? styles.dispatch_dark:""}`}>
      <div className={styles.dispatch_top}>
        <div className={styles.dispatch_top_left}>
          <div
            className={styles.dispatch_top_left_go_back}
            onClick={() => navigate(-1)}
          >
            <TestTopBackArrowIcon color={mode ? "#ffffff" : "#0100E4"} />
            <p
              className={styles.dispatch_go_back_text}
              style={{ color: mode ? "#FFFFFF" : "#0100E4" }}
            >
              Go Back
            </p>
          </div>
          <TestTopDotIcon color={mode ? "#E4E7EB" : "#646B88"} />
          <p
            className={styles.dispatch_top_left_path_text}
            style={{
              color: mode ? "#9C9797" : "#646B88",
            }}
          >
            {routeName}
          </p>
        </div>
        <div className={styles.dispatch_right}>
          <div>
            <SlidingFilter itemList={slidingFilter1} />
          </div>
          <div>
            <InputTypedate text={"From"} />
          </div>
          <div>
            <InputTypedate text={"To"} />
          </div>
          <div>
            <Button
              buttonIcon={<DispatchReloadIcon />}
              buttonBgColor={"#000000"}
              buttonTextColor={"#ffffff"}
              buttonBorder={"none"}
            />
          </div>
          <div>
            <Button
              buttonIcon={<DownloadIcon />}
              buttonText={"Download"}
              buttonBgColor={"#4164E3"}
              buttonTextColor={"#ffffff"}
              buttonBorder={"none"}
            />
          </div>
        </div>
      </div>

      <div
        className={`${styles.dispatch_filters} ${mode ? styles.dispatch_filters_dark:""}`}
      >
        {dispatchStatusData?.map((s) => (
          <div key={s.statusText} className={styles.dispatch_filter_card}>
            <DealerStatusCard
              statusText={s.statusText}
              statusItemCount={s.statusItemCount}
            />
          </div>
        ))}
      </div>
      <div
        className={styles.dispatch_data}
        style={mode ? { borderColor: "transparent" } : {}}
      >
        <div className={styles.dispatch_data_filters}>
          <div>
            <div
              className={`${styles.dispatch_data_search} ${mode ? styles.dispatch_data_search_dark:""}`}
            >
              <NavbarSearchIcon color={mode ? "#FFFFFF" : "#646B88"} />
            </div>
            <SlidingFilter itemList={slidingFilter2} />
          </div>

          <div className={styles.dispatch_data_filters_dd}>
            <DropDown label={"Source"} listLabel={"All"} />
            <DropDown label={"Warehouse"} listLabel={"All"} />
            <DropDown label={"Manager"} listLabel={"All"} />
            <DropDown label={"Sort By"} listLabel={"All"} />
          </div>
        </div>

        <ul className={styles.dispatch_data_list}>
          <li
            className={`${styles.dispatch_data_list_item} ${
              styles.dispatch_item_header
            } ${mode ? styles.dispatch_item_header_dark : ""}`}
          >
            {dispatchHeader.map((h) => (
              <div className="col" key={h}>
                {h}
              </div>
            ))}
          </li>
          <div className={styles.dispatch_data_list_container}>
            {[...Array(20)].map((_, index) => (
              <li
                key={index}
                className={`${styles.dispatch_data_list_item} ${
                  styles.dispatch_item_value
                } ${mode ? styles.dispatch_item_value_dark : ""}`}
              >
                {dispatchData.map((d) => (
                  <div className="col" key={d}>
                    {d}
                  </div>
                ))}
              </li>
            ))}
          </div>
        </ul>
      </div>
    </div>
  );
};

export default Demofile;





// import React, { useEffect, useState } from "react";
// import { useAuth } from "../../context/Auth";
// import { DownloadIcon, DispatchReloadIcon, NavbarSearchIcon, TestTopBackArrowIcon, TestTopDotIcon } from "../../icons/Icon";
// import { useNavigate, useSearchParams } from "react-router-dom";
// import styles from "./Test.module.css";
// import Button from "../Dealers/Button";
// import SlidingFilter from "../Dealers/SlidingFilter";
// import DealerStatusCard from "../Dealers/DealerStatusCard";
// import DropDown from "../FormComponents/DropDown";
// import InputTypedate from "../FormComponents/InputTypedate";
// import Paginantion from "../Pagnation/Pagenation"
// const Demofile = () => {
//   const { mode, managerId, designation } = useAuth();
//   const navigate = useNavigate();
//   const [searchParams, setSearchParams] = useSearchParams();
//   const page = searchParams.get("page") ? parseInt(searchParams.get("page")) : 1;
//   const [data, setData] = useState([]);
//   const [orders, setOrders] = useState([]);
//   const [loading, setLoading] = useState(false);
//   const [keyword, setKeyword] = useState('');
//   const [sortKey, setSortKey] = useState('');
//   const [orderStatus, setOrderStatus] = useState('');
//   const [warehouses, setWarehouses] = useState([]);
//   const [warehouseName, setWarehouseName] = useState('');
//   const [managers, setManagers] = useState([]);
//   const [managerName, setManagerName] = useState('');
//   const [startDate, setStartDate] = useState('');
//   const [endDate, setEndDate] = useState('');
//   const [daysTab, setDaysTab] = useState('today');

//   const [state, setState] = useState({
//     totalPages: "",
//     currentPage: 1
//   });

//   const { totalPages } = state;

//   useEffect(() => {
//     if (managerId) {
//       setLoading(true);
//       var myHeaders = new Headers();
//       myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
//       myHeaders.append("Content-Type", "application/json");

//       var raw = JSON.stringify({
//         managerId: parseInt(managerId),
//         keyword,
//         orderStatus,
//         sortKey,
//         shippingType: 'all', 
//         cartType: 'all', 
//         warehousename: warehouseName,
//         disPatchManagerId: managerName,
//         day: daysTab,
//         startDate,
//         endDate
//       });

//       var requestOptions = {
//         method: 'POST',
//         headers: myHeaders,
//         body: raw,
//         redirect: 'follow'
//       };

//       fetch(`${process.env.REACT_APP_URL}dispatch/allOrders?page=${page}&limit=8`, requestOptions)
//         .then(response => response.json())
//         .then(result => {
//           if (result.status === 'success') {
//             setLoading(false);
//             setData(result.data);
//             setOrders(result.orders);
//             if (result.warehouses) setWarehouses(result.warehouses);
//             if (result.managers) setManagers(result.managers);
//           }
//           if (result.pages) {
//             setState(prevState => ({
//               ...prevState,
//               totalPages: result.pages || "",
//             }));
//           }
//         })
//         .catch(error => console.log('error', error));
//     }
//   }, [managerId, sortKey, keyword, page, orderStatus, warehouseName, managerName, daysTab, startDate, endDate]);

//   const slidingFilter1 = ["Today", "Yesterday", "This Month", "Last 30 Days"];
//   const slidingFilter2 = ["All", "Sales", "Purchase", "Adjustment", "Transfer", "Production", "Production & Sale", "Production & Transfer"];
//   const dispatchStatusData = [
//     { statusText: "New Order", statusItemCount: orders.filter(o => o.status === "New Order").length },
//     { statusText: "In Progress", statusItemCount: orders.filter(o => o.status === "In Progress").length },
//     { statusText: "Dispatched", statusItemCount: orders.filter(o => o.status === "Dispatched").length },
//     { statusText: "Cancelled", statusItemCount: orders.filter(o => o.status === "Cancelled").length },
//   ];
//   const dispatchHeader = ["Name & City", "Type", "Date", "Order ID", "Packer", "Order Manager", "Source", "Sub- Method", "Delivery Partner", "Status", "Last Action"];

//   return (
//     <div className={`${styles.dispatch} ${mode ? styles.dispatch_dark : ""}`}>
//       <div className={styles.dispatch_top}>
//         <div className={styles.dispatch_top_left}>
//           <div
//             className={styles.dispatch_top_left_go_back}
//             onClick={() => navigate(-1)}
//           >
//             <TestTopBackArrowIcon color={mode ? "#ffffff" : "#0100E4"} />
//             <p
//               className={styles.dispatch_go_back_text}
//               style={{ color: mode ? "#FFFFFF" : "#0100E4" }}
//             >
//               Go Back
//             </p>
//           </div>
//           <TestTopDotIcon color={mode ? "#E4E7EB" : "#646B88"} />
//           <p
//             className={styles.dispatch_top_left_path_text}
//             style={{
//               color: mode ? "#9C9797" : "#646B88",
//             }}
//           >
//             Dispatch Orders
//           </p>
//         </div>
//         <div className={styles.dispatch_right}>
//           <div>
//             <SlidingFilter itemList={slidingFilter1} onItemSelect={setDaysTab} />
//           </div>
//           <div>
//             <InputTypedate text={"From"} value={startDate} onChange={setStartDate} />
//           </div>
//           <div>
//             <InputTypedate text={"To"} value={endDate} onChange={setEndDate} />
//           </div>
//           <div>
//             <Button
//               buttonIcon={<DispatchReloadIcon />}
//               buttonBgColor={"#000000"}
//               buttonTextColor={"#ffffff"}
//               buttonBorder={"none"}
//               onClick={() => window.location.reload()}
//             />
//           </div>
//           <div>
//             <Button
//               buttonIcon={<DownloadIcon />}
//               buttonText={"Download"}
//               buttonBgColor={"#4164E3"}
//               buttonTextColor={"#ffffff"}
//               buttonBorder={"none"}
//             />
//           </div>
//         </div>
//       </div>

//       <div
//         className={`${styles.dispatch_filters} ${mode ? styles.dispatch_filters_dark : ""}`}
//       >
//         {dispatchStatusData.map((s) => (
//           <div key={s.statusText} className={styles.dispatch_filter_card}>
//             <DealerStatusCard
//               statusText={s.statusText}
//               statusItemCount={s.statusItemCount}
//             />
//           </div>
//         ))}
//       </div>

//       <div className={styles.dispatch_data}>
//         <div className={styles.dispatch_data_filters}>
//           <div>
//             <div
//               className={`${styles.dispatch_data_search} ${mode ? styles.dispatch_data_search_dark : ""}`}
//             >
//               <NavbarSearchIcon color={mode ? "#FFFFFF" : "#646B88"} />
//             </div>
//             <SlidingFilter itemList={slidingFilter2} onItemSelect={() => {}} />
//           </div>

//           <div className={styles.dispatch_data_filters_dd}>
//             <DropDown label={"Source"} listLabel={"All"} />
//             <DropDown label={"Warehouse"} listLabel={"All"} />
//             <DropDown label={"Manager"} listLabel={"All"} />
//             <DropDown label={"Sort By"} listLabel={"All"} />
//           </div>
//         </div>

//         <ul className={styles.dispatch_data_list}>
//           <li
//             className={`${styles.dispatch_data_list_item} ${styles.dispatch_item_header} ${mode ? styles.dispatch_item_header_dark : ""}`}
//           >
//             {dispatchHeader.map((h) => (
//               <div className="col" key={h}>
//                 {h}
//               </div>
//             ))}
//           </li>
//           <div className={styles.dispatch_data_list_container}>
//             {data.map((val, index) => (
//               <li
//                 key={index}
//                 className={`${styles.dispatch_data_list_item} ${styles.dispatch_item_value} ${mode ? styles.dispatch_item_value_dark : ""}`}
//               >
//                 <div className="col">{val.name}</div>
//                 <div className="col">{val.city}</div>
//                 <div className="col">{val.orderId}</div>
//                 <div className="col">{val.packer}</div>
//                 <div className="col">{val.orderManager}</div>
//                 <div className="col">{val.source}</div>
//                 <div className="col">{val.subMethod}</div>
//                 <div className="col">{val.deliveryPartner}</div>
//                 <div className="col">{val.status}</div>
//                 <div className="col">{val.lastAction}</div>
//               </li>
//             ))}
//           </div>
//         </ul>

//         <Paginantion
//           currentPage={page}
//           totalPages={totalPages}
//         //   setCurrentPage={setp}
//         //   onPageChange={(newPage) => setSearchParams({ page: newPage })}
//         />
//       </div>
//     </div>
//   );
// };

// export default Demofile;
