import { DragNDropIcon } from "../../icons/Icon";
import styles from "../../../pages/ordermanager/Orders.module.css";
import { useState } from "react";

const FileUpload = ({ mode, isImage = true }) => {
  const [uploadedFile, setUploadedFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      const file = e.dataTransfer.files[0];
      handleFileUpload(file);
    }
  };

  const handleFileUpload = (file) => {
    if (file && file.type.startsWith("image/")) {
      setUploadedFile(file);
      const fileReader = new FileReader();
      fileReader.onload = () => {
        setPreviewUrl(fileReader.result);
      };
      fileReader.readAsDataURL(file);
    } else {
      alert("Please upload a valid image file.");
    }
  };

  const handleBrowseFiles = (e) => {
    const file = e.target.files[0];
    handleFileUpload(file);
  };

  const handleDelete = () => {
    setUploadedFile(null);
    setPreviewUrl(null);
  };

  const handlePreview = () => {
    if (previewUrl) {
      const newWindow = window.open();
      newWindow.document.write(
        `<head><title>Image Preview</title></head>
         <body style="margin:0;">
           <img src="${previewUrl}" style="width:90%;height:90%;object-fit:contain" alt="Preview" />
         </body>`
      );
      newWindow.document.close();
    } else {
      alert("No preview available.");
    }
  };

  return (
    <>
      <div
        className={styles.file_upload}
        style={
          mode ? { backgroundColor: "#1B1D21", borderColor: "#646B88" } : {}
        }
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      >
        {previewUrl ? (
          <img src={previewUrl} alt="Preview" className={styles.file_preview} />
        ) : (
          <div className={styles.file_upload_text}>
            <DragNDropIcon />
            <p>
              Drag & Drop <br /> any file
            </p>
            <p style={mode ? { color: "#fff" } : {}}>
              or{" "}
              <span
                onClick={() => document.getElementById("fileInput").click()}
              >
                browse files
              </span>{" "}
              on your computer
            </p>
            <input
              type="file"
              id="fileInput"
              style={{ display: "none" }}
              onChange={handleBrowseFiles}
            />
          </div>
        )}
      </div>

      <div className={styles.file_upload_footer}>
        <div>
          <span>Max Size - 1920 x 680</span>
        </div>
        {uploadedFile && (
          <div>
            <span
              style={{ cursor: "pointer", marginRight: "1rem" }}
              onClick={() => {
                handlePreview();
              }}
            >
              Preview
            </span>
            <span
              style={{ color: "#F93E3E", cursor: "pointer" }}
              onClick={handleDelete}
            >
              Delete
            </span>
          </div>
        )}
      </div>
    </>
  );
};

export default FileUpload;

// import { DragNDropIcon } from "../../icons/Icon";
// import styles from "../../../pages/ordermanager/Orders.module.css";

// const FileUpload = ({ mode }) => {
//   return (
//     <>
//       <div
//         className={styles.file_upload}
//         style={
//           mode ? { backgroundColor: "#1B1D21", borderColor: "#646B88" } : {}
//         }
//       >
//         <DragNDropIcon />
//         <p>
//           Drag & Drop <br /> any file
//         </p>
//         <p style={mode ? { color: "#fff" } : {}}>
//           or{" "}
//           <span style={{ color: "#4164E3", textDecoration: "underline" }}>
//             browse files
//           </span>{" "}
//           on your computer
//         </p>
//       </div>
//       <div className={styles.file_upload_footer}>
//         <div>
//           <span>Max Size - 1920 x 680</span>
//         </div>
//         <div>
//           <span style={{ cursor: "pointer", marginRight: "1rem" }}>
//             Preview
//           </span>
//           <span style={{ color: "#F93E3E", cursor: "pointer" }}>Delete</span>
//         </div>
//       </div>
//     </>
//   );
// };

// export default FileUpload;
