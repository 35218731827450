import React from "react";
import styles from "./Navbar.module.css"; // Make sure this CSS module exists
import { useAuth } from "../../context/Auth";
import {
  SideBarDashBoardIcon,
  SideBarDealersIcon,
  SideBarDispatchIcon,
  SideBarInventoryIcon,
  SideBarOrdersIcon,
  SideBarOutsideInventoryIcon,
  SideBarPackingOrderIcon,
  SideBarProductIcon,
  SideBarReceiptIcon,
  SideBarWalliconLogo,
} from "../../icons/Icon";
import MdNavLInks from "./MdNavLInks";

const Drawer = ({ isOpen, onClose, menuItems }) => {
  const { mode, designation } = useAuth();
  // Determine base and conditional class names
  const drawerClass = mode
    ? `${styles.drawer} ${isOpen ? styles.open : ""}`
    : `${styles.drawer_dark} ${isOpen ? styles.open : ""}`;

  const marketingMenuItems = [
    { label: "DASHBOARD", path: "/dashboard", Icon: SideBarDashBoardIcon },
    { label: "CONTACTS", path: "/contacts", Icon: SideBarDealersIcon },
    { label: "LEADS", path: "/leads", Icon: SideBarOrdersIcon },
  ];

  const contentManagerMenuItems = [
    { label: "DASHBOARD", path: "/dashboard", Icon: SideBarDashBoardIcon },
    { label: "PRODUCTS", path: "/products", Icon: SideBarProductIcon },
    { label: "DEALERS", path: "/dealers", Icon: SideBarDealersIcon },
  ];

  const orderManagerMenuItems = [
    { label: "DASHBOARD", path: "/dashboard", Icon: SideBarDashBoardIcon },
    { label: "ORDER", path: "/orders", Icon: SideBarInventoryIcon },
    // { label: "DEALERS", path: "/dealers", Icon: SideBarDealersIcon },
    { label: "DEALERS", path: "/orders/dealers", Icon: SideBarDealersIcon },
  ];

  const inventoryManagerMenuItems = [
    { label: "DASHBOARD", path: "/dashboard", Icon: SideBarDashBoardIcon },
    { label: "INVENTORY", path: "/inventory", Icon: SideBarDashBoardIcon },
    {
      label: "OUTSIDE INVENTORY",
      path: "/outsideinventory",
      Icon: SideBarOutsideInventoryIcon,
    },
    { label: "ORDERS", path: "/orders", Icon: SideBarOrdersIcon },
  ];

  const dispatchManagerMenuItems = [
    { label: "DASHBOARD", path: "/dashboard", Icon: SideBarDashBoardIcon },
    { label: "DISPATCH", path: "/dispatchorders", Icon: SideBarInventoryIcon },
    { label: "PACKERS", path: "/packers", Icon: SideBarInventoryIcon },
  ];

  const packingManagerMenuItems = [
    { label: "DASHBOARD", path: "/dashboard", Icon: SideBarDashBoardIcon },
    { label: "ORDERS", path: "/orders", Icon: SideBarOrdersIcon },
  ];

  const paymentManagerMenuItems = [
    { label: "RECEIPTS", path: "/receipts", Icon: SideBarReceiptIcon },
  ];

  let filteredMenuItems = menuItems;
  if (designation === "marketing") {
    filteredMenuItems = marketingMenuItems;
  } else if (designation === "contentmanager") {
    filteredMenuItems = contentManagerMenuItems;
  } else if (designation === "ordermanager") {
    filteredMenuItems = orderManagerMenuItems;
  } else if (designation === "inventorymanager") {
    filteredMenuItems = inventoryManagerMenuItems;
  } else if (designation === "dispatchmanager") {
    filteredMenuItems = dispatchManagerMenuItems;
  } else if (designation === "packingmanager") {
    filteredMenuItems = packingManagerMenuItems;
  } else if (designation === "paymentmanager") {
    filteredMenuItems = paymentManagerMenuItems;
  } else if (designation === "superadmin") {
    filteredMenuItems = menuItems;
  }

  return (
    <div className={drawerClass}>
      <div className={styles.drawerContent}>
        <div className={styles.drawerContentMain}>
          <div className={styles.md_logoContiner}>
            <SideBarWalliconLogo
              backgroundColor={mode ? "#232529" : "#F5F6FA"}
              textColor={mode ? "#FFFFFF" : "#231F20"}
            />
          </div>
          <div style={{ border: "1px solid #d0d1d4" }}></div>
          <div className={styles.md_linkContiner}>
            {filteredMenuItems.map((menuItem, i) => (
              <MdNavLInks
                key={i}
                Icon={menuItem.Icon}
                label={menuItem.label}
                path={menuItem.path}
                onClose={onClose}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Drawer;
