import React from 'react';
import styles from "../../../pages/template/Templates.module.css";
import { useAuth } from '../../context/Auth';
import { useNavigate } from 'react-router-dom';
import { TestTopBackArrowIcon, TestTopDotIcon } from '../../icons/Icon';
import { truncateText } from '../../../Utils/TextUtils';
import useWindowWidth from '../Hooks/WidthSize';

const TopTabBar = ({ text }) => {
  const { mode } = useAuth();
  const navigation = useNavigate();
  const width = useWindowWidth();

  return (
    <div className={styles.order_top_left}>
      <div
        className={styles.order_top_left_go_back}
        onClick={() => navigation(-1)}
      >
        <TestTopBackArrowIcon color={mode ? "#ffffff" : "#0100E4"} />
        <p
          style={{
            fontSize: "16px",
            fontWeight: "500",
            lineHeight: "24px",
            margin: 0,
            paddingTop: "0.21rem",
            color: mode ? "#FFFFFF" : "#4164E3",
          }}
        >
          Go Back
        </p>
      </div>
      <TestTopDotIcon color={mode ? "#E4E7EB" : "#646B88"} />
      <p
        className={styles.order_top_left_path_text}
        style={{
          color: mode ? "#9C9797" : "#646B88",
        }}
      >
        {width < 1155 ? truncateText(text, 10) : text}
      </p>
    </div>
  );
}

export default TopTabBar;
