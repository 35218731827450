import { useState } from "react";
import { NewManagerCancelIcon, SaveTaxRateIcon } from "../../icons/Icon";
import { CloseIcon } from "../../icons/Icon2";
import Button from "../Dealers/Button";
import InputTag from "../FormComponents/InputTag";
import VerticalDropDown from "../FormComponents/VerticalDropDown";
import styles from "../../../pages/ordermanager/Orders.module.css";
import FileUpload from "./FileUpload";

const AddReceiptModal = ({ handleCloseModal, mode }) => {
  const [selectedMethod, setSelectedMethod] = useState({
    label: "Cash",
    value: "Cash",
  });
  return (
    <div
      className={styles.add_reciept_modal}
      style={mode ? { backgroundColor: "#232529" } : {}}
    >
      <div>
        <p style={{ color: mode ? "#fff" : "#000" }}>Receipt</p>
        <div
          style={{ cursor: "pointer" }}
          onClick={() => {
            handleCloseModal();
          }}
        >
          <CloseIcon mode={mode} />
        </div>
      </div>
      <div>
        <VerticalDropDown
          label={"Method"}
          dropDownText={"Cash"}
          options={[
            { label: "Cash", value: "Cash" },
            { label: "Photo", value: "Photo" },
            { label: "Pay on Delivery", value: "Pay on Delivery" },
          ]}
          value={selectedMethod}
          onchange={(option) => setSelectedMethod(option)}
        />
      </div>

      <div>
        <InputTag labelText={"Total Amount"} placeholderText={"000.00"} />
      </div>

      {selectedMethod.value === "Photo" && (
        <>
          <div>
            <VerticalDropDown label={"Account"} dropDownText={"Select"} />
          </div>
          <FileUpload mode={mode} />
          <div>
            {" "}
            <InputTag
              labelText={"Transtaction ID"}
              placeholderText={"Ref / UTR Number / Transaction ID"}
            />
          </div>
        </>
      )}

      {selectedMethod.value === "Pay on Delivery" && (
        <>
          <div style={{ gap: "1rem" }}>
            <div style={{ width: "30%" }}>
              <VerticalDropDown label={"Sub Method"} dropDownText={"Select"} />
            </div>
            <div style={{ width: "70%" }}>
              <VerticalDropDown label={"Account"} dropDownText={"Select"} />
            </div>
          </div>
          <FileUpload mode={mode} />
          <div>
            <InputTag
              labelText={"Transtaction ID"}
              placeholderText={"Ref / UTR Number / Transaction ID"}
            />
          </div>
        </>
      )}

      <div>
        <InputTag
          labelText={"Transtaction"}
          placeholderText={"Enter Transaction Amount (In INR)"}
        />
      </div>
      <div
        style={{ gap: "1rem", justifyContent: "center", marginTop: "0.5rem" }}
      >
        <div>
          <Button
            buttonBgColor={"#000"}
            buttonText={"CANCEL"}
            buttonTextColor={"#fff"}
            buttonIcon={<NewManagerCancelIcon />}
            buttonClick={handleCloseModal}
          />
        </div>
        <div>
          <Button
            buttonBgColor={"#4164E3"}
            buttonText={"SAVE"}
            buttonTextColor={"#fff"}
            buttonIcon={<SaveTaxRateIcon />}
          />
        </div>
      </div>
    </div>
  );
};

export default AddReceiptModal;
