import React, {
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import styles from "../../../pages/ordermanager/Orders.module.css";
import fetchData from "../../../APIsControll/apiControll";
import ModalWrapper from "../layoutWrapper/ModalWrapper";
import AdduserModel from "../models/AdduserModel";
import Spinner from "../FormComponents/Spinner";

const SearchDealer = forwardRef(
  (
    {
      mode,
      name,
      onSelect,
      setShowDealerSearch,
      defaultIndex,
      defaultDealer,
    },
    ref
  ) => {
    const resultContainer = useRef(null);
    const [results, setResults] = useState([]);
    const [defaultValue, setDefaultValue] = useState("");
    const [focusedIndex, setFocusedIndex] = useState(defaultIndex);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
      if (name) setDefaultValue(name);
    }, [name]);

    useEffect(() => {
      if (defaultDealer && results.length > 0) {
        setFocusedIndex(defaultIndex);
      }
    }, [defaultDealer, defaultIndex, results.length]);

    useEffect(() => {
      if (!resultContainer.current) return;

      resultContainer.current.scrollIntoView({
        block: "center",
      });
    }, [focusedIndex]);

    const fetchDealer = useCallback(async () => {
      try {
        setLoading(true);
        const apiData = {
          managerId: 1,
          keyword: defaultValue,
        };

        const result = await fetchData("order/finddealer", apiData);

        if (result.status === "success") {
          setResults(result.data);
        }
        setLoading(false);
      } catch (error) {
        console.log("error", error);
        setLoading(false);
      }
    }, [defaultValue]);

    useEffect(() => {
      if (defaultValue) {
        const delayDebounceFn = setTimeout(() => {
          fetchDealer();
        }, 700);

        return () => clearTimeout(delayDebounceFn);
      }
    }, [defaultValue, fetchDealer]);

    const resetSearchComplete = useCallback(() => {
      setFocusedIndex(-1);
    }, []);

    const handleSelection = useCallback(
      (selectedIndex) => {
        const selectedItem = defaultDealer
          ? results[defaultIndex]
          : results[selectedIndex];

        if (!selectedItem) return resetSearchComplete();

        setDefaultValue(selectedItem.name);
        onSelect && onSelect(selectedItem);

        setShowDealerSearch(false);
        resetSearchComplete();
      },
      [
        defaultDealer,
        results,
        defaultIndex,
        onSelect,
        setShowDealerSearch,
        resetSearchComplete,
      ]
    );
    useEffect(() => {
      if (defaultDealer && results.length > 0) {
        handleSelection(defaultIndex);
      }
    }, [defaultDealer, results.length, handleSelection, defaultIndex]);

    const handleKeyDown = (e) => {
      const { key } = e;
      let nextIndexCount = 0;

      if (key === "ArrowDown") {
        nextIndexCount = (focusedIndex + 1) % results.length;
        setFocusedIndex(nextIndexCount);
        e.preventDefault();
      }

      if (key === "ArrowUp") {
        nextIndexCount = (focusedIndex + results.length - 1) % results.length;
        setFocusedIndex(nextIndexCount);
        e.preventDefault();
      }

      if (key === "Escape") {
        setShowDealerSearch(false);
        resetSearchComplete();
      }

      if (e.altKey && e.key.toLowerCase() === "c") {
        setIsModalOpen(true);
      }

      if (key === "Enter") {
        e.preventDefault();
        handleSelection(focusedIndex);
      }
    };

    useEffect(() => {
      const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          setShowDealerSearch(false);
        }
      };

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref, setShowDealerSearch]);

    return (
      <>
        <ModalWrapper
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          noBtn
        >
          <AdduserModel close={() => setIsModalOpen(false)} />
        </ModalWrapper>
        <div
          ref={ref}
          tabIndex="0"
          className={`${styles.search_dealer} ${
            mode ? styles.search_dealer_dark : ""
          }`}
          onClick={(e) => e.stopPropagation()}
          onKeyDown={handleKeyDown}
        >
          <div>
            <p style={{ color: mode ? "#fff" : "#0100E4" }}>
              Create New Dealer
            </p>
            <p>ALT + C</p>
          </div>
          <div className={styles.search_list}>
            {loading ? (
              <Spinner
                height={60}
                width={60}
                color={mode ? "#000000" : "#4164E3"}
              />
            ) : (
              <>
                {results &&
                  results.map((r, index) => (
                    <div
                      className={`${
                        index === focusedIndex
                          ? styles.focused_search_list_item
                          : ""
                      } ${styles.search_list_item}`}
                      key={index}
                      ref={index === focusedIndex ? resultContainer : null}
                      onMouseDown={() => {
                        handleSelection(index);
                      }}
                    >
                      {r.storeName + " / " + r.name}
                    </div>
                  ))}
              </>
            )}
          </div>
        </div>
      </>
    );
  }
);

export default SearchDealer;
