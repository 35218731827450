import React, { useEffect } from 'react'
// import modalcss from './Modal.module.css'
import modalcss from './bottomDrawer.module.css'
import { useAuth } from "../../../context/Auth";

const Modal = ({ show, close, content, heading, closeBtn }) => {
    const { mode } = useAuth();
    useEffect(() => {
        if (show) {
            document.body.style.overflow = 'hidden'
        } else {
            document.body.style.overflow = 'auto'
        }
    }, [show,])
    return show && (
        <div className={modalcss.modal_backdrop}  onClick={() => { close(); }}
        >
            <div className={modalcss.modal_content} onClick={e => { e.stopPropagation(); }} style={{backgroundColor: mode ? "#2C2E33" : "#fff" ,color : mode ? "#fff" : "#000"}} >
                {(heading || closeBtn) &&
                    <div className={modalcss.modal_header} >
                        {heading && <h3 style={{color : mode ? "#fff" : "#000"}}>{heading}</h3>}
                        {closeBtn &&
                            <button
                                type="button"
                                className={modalcss.modal_close_button}
                                onClick={() => close()}
                            >
                                <span style={{color : mode ? "#fff" : "#000"}}>&times;</span>
                            </button>}
                    </div>
                }

                {content}
            </div>
        </div >
    )
}

export default Modal